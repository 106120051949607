import { CloseButton, Icon, NavigationHeader, ProductCard, RedeemProgressBar, Screen } from '@components';
import IconButton from '@components/IconButton';
import Text from '@components/Text';
import appConstants from '@config/appConstants';
import { assets } from '@config/assets';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useCancelToken } from '@hooks';
import { AppStackRoutes, AppStackScreenProps, RewardsStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useOffersStore, useShopStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { ProductList, getProductPromotions, getProductsfromCommerceToolsWithHistory } from '@utils/productHelper';
import { getOffersAndCouponsByProductList } from '@utils/promotionHelper';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { useCustomModalContext } from '../../contexts';

type SEOfferDetailsScreenProps = AppStackScreenProps<AppStackRoutes.OfferDetails>;

const SEOfferDetailsScreen: FC<SEOfferDetailsScreenProps> = ({ navigation, route }) => {
  const { offerId, percentComplete = 0, calledFrom } = route.params;
  const { selectedStore } = useShopStore();
  const [showDetails, setShowDetails] = useState(true);
  const [clipping, setClipping] = useState(false);
  const [dates, setDates] = useState({
    expires: '',
    expiresMessage: '',
  });
  const {
    acceptedSomethingExtraOffers,
    acceptedWeeklyExclusiveOffers,
    acceptedDigitalCoupons,
    acceptOffer,
    unacceptOffer,
    acceptCoupon,
    fetchOfferByOfferId,
    fetchDigitalCoupons,
  } = useOffersStore();
  const acceptedOffers = acceptedSomethingExtraOffers.concat(acceptedWeeklyExclusiveOffers).concat(acceptedDigitalCoupons);
  const [offer, setOffer] = useState<Promotion>();
  const [qualifyingProduct, setQualifyingProduct] = useState<ProductList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nextStart, setNextStart] = useState<number>(0);
  const { showAlertModal } = useCustomModalContext();
  const { t } = useTranslation('promotions');

  useEffect(() => {
    const fetchOffer = async () => {
      try {
        setQualifyingProduct([]);
        const promo = await fetchOfferByOfferId(offerId);
        setOffer(promo);
      } catch {
        // Navigate back to Offers page if offer does not exist for customer
        navigation.navigate(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Rewards,
          params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Personalized' } },
        });
      }
    };

    fetchOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, acceptedSomethingExtraOffers, acceptedWeeklyExclusiveOffers, acceptedDigitalCoupons]);

  const loadQualifyingProducts = async (start: number = 0, end: number = appConstants.PAGE_SIZE) => {
    const productList = offer?.ProductList ?? [];

    let updatedQualifyingProductList: ProductList[] = [];

    if (productList && productList.length > 0) {
      setIsLoading(true);

      const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE, productList.slice(start, end));
      const qualifyingProductList = await getProductsfromCommerceToolsWithHistory(productList.slice(start, end));
      updatedQualifyingProductList = getProductPromotions(promotions, qualifyingProductList);

      setNextStart(end);
      setQualifyingProduct([...qualifyingProduct, ...updatedQualifyingProductList]);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadQualifyingProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  const handleLoadMore = async () => {
    if (offer && offer.ProductList && offer.ProductList?.length >= nextStart + appConstants.PAGE_SIZE) {
      loadQualifyingProducts(nextStart, nextStart + appConstants.PAGE_SIZE);
    }
  };

  const TODAY = dayjs();
  // var options: Intl.DateTimeFormatOptions = {
  //   month: 'numeric',
  // };

  const EXPIRATION_DATE = new Date(offer?.EndDate ?? '1/1/1970');
  // const EXP_MONTH = new Intl.DateTimeFormat('en-US', options).format(EXPIRATION_DATE);
  // const EXP_DAY = EXPIRATION_DATE.getDate();
  // const EXP_YEAR = EXPIRATION_DATE.getFullYear().toString().substring(2, 4);

  // const PARSED_EXP_DATE = dayjs(`${EXP_MONTH}/${EXP_DAY}/${EXP_YEAR}`);

  const renderDate = () => {
    let daysRemaining = dayjs(EXPIRATION_DATE).diff(TODAY, 'day').toString();
    if (Number(daysRemaining) === 0) {
      daysRemaining = 'Expires today';
    } else if (Number(daysRemaining) === 1) {
      daysRemaining = `${daysRemaining} day left`;
    } else if (Number(daysRemaining) <= 5) {
      daysRemaining = `${daysRemaining} days left`;
    } else {
      daysRemaining = '';
    }

    if (offer && offer.EndDate) {
      setDates({
        expires: `Expires ${dayjs(EXPIRATION_DATE).format('MM/DD/YY')}`,
        expiresMessage: daysRemaining,
      });
    }
  };

  const showOfferUnavailableModal = () => {
    showAlertModal({
      title: t('offerUnavailableTitle', { ns: 'promotions' }),
      message: t('offerUnavailableMessage', { ns: 'promotions' }),
    });
  };

  const cancelToken = useCancelToken();
  const isNotAllowUnclip = offer?.ExtPromotionTypeCode === 'DFEE' || offer?.ExtPromotionTypeCode === 'SFEE';
  const handleClip = async () => {
    if (!offer) {
      return;
    }

    setClipping(true);
    const isDigitalCoupon = (offer?.PromotionCode ?? '').toUpperCase().startsWith('INMAR');
    if (acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId)) {
      if (!isNotAllowUnclip) {
        if (!(isDigitalCoupon || calledFrom?.toLowerCase() === 'digitalcoupons')) {
          await unacceptOffer(+offer.ExtPromotionId);
        }
      }
    } else {
      if (calledFrom?.toLowerCase() === 'digitalcoupons' || isDigitalCoupon) {
        await acceptCoupon(+offer.ExtPromotionId)
          .catch((ex: any) => {
            ex.message.toLowerCase().includes('no longer valid') && showOfferUnavailableModal();
          })
          .finally(() => fetchDigitalCoupons(cancelToken));
      } else {
        await acceptOffer(+offer.ExtPromotionId);
      }
    }

    setClipping(false);
  };

  const renderItemSeperator = () => {
    return <View style={{ backgroundColor: colors.sectionBorder, height: 1, width: '95%', alignSelf: 'center', marginBottom: 16 }} />;
  };

  useEffect(() => {
    if (offer && offer.ExtPromotionId) {
      renderDate();
    }
    // removing error to include functions on successful data load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  let bookMarkIcon = (
    <>
      {(calledFrom === 'topDeals' || calledFrom === 'WeeklyAds') && (
        <TouchableOpacity onPress={() => showAlertModal()} style={{ top: 8, transform: [{ translateY: 4 }] }} activeOpacity={0.7}>
          <Icon
            name="bookmark-icon"
            stroke={colors.red}
            fill={false ? colors.secondary : 'none'}
            style={appStyles.largeIcon}
            size={24}
            testID="bookmark-icon"
          />
        </TouchableOpacity>
      )}
    </>
  );

  const showAsAccepted = (p?: Promotion) => {
    if (p?.AutoApply || (p?.IsPromoCodePromo && p?.IsAccepted)) {
      return true;
    }
    return false;
  };

  const fallbackImage = assets.imageComingSoon;
  const getImageSource = () => {
    let url;
    if (offer && offer.QualifiedImageUrl && offer.QualifiedImageUrl.length > 0) {
      url = { cache: 'default', uri: `${offer.QualifiedImageUrl.indexOf('//') === 0 ? 'https:' + offer.QualifiedImageUrl : offer.QualifiedImageUrl}` };
    } else {
      url = fallbackImage;
    }
    return url;
  };

  const renderHeader = () => {
    return offer ? (
      <>
        <View style={styles.itemInfoWrapper}>
          <View style={styles.itemInfoTop}>
            <View style={styles.imgWrapper} testID="imgWrapper">
              <Image testID="productImage" source={getImageSource()} style={{ width: 140, height: 140 }} resizeMode="contain" />
            </View>
            <View style={styles.itemInfoContainer}>
              <Text numberOfLines={2} style={[styles.offerText]} testID="consumerDescription">
                {offer.Headline}
              </Text>
              {offer.SubHeadline && (
                <Text numberOfLines={2} style={[styles.itemDetailText]} testID="consumerDetails">
                  {offer.SubHeadline}
                </Text>
              )}
              {offer.EndDate && (
                <Text style={[appStyles.tinyLight, { textAlign: 'left' }]} testID="expiresText">
                  {dates?.expires}
                </Text>
              )}
              {dates.expiresMessage.length > 0 ? (
                <Text style={[appStyles.fontMobileBodyXSBoldRed]} testID="daysLeft">
                  {dates.expiresMessage}
                </Text>
              ) : null}
            </View>
          </View>
          {calledFrom !== 'topDeals' && calledFrom !== 'WeeklyAds' && (
            <>
              {(isNotAllowUnclip && offer?.IsAccepted) || showAsAccepted(offer) ? (
                <View style={styles.clipButtonWrapper} />
              ) : (
                <View style={styles.clipButtonWrapper}>
                  {clipping ? (
                    <View style={{ transform: [{ translateY: 22 }] }}>
                      <ActivityIndicator />
                    </View>
                  ) : (
                    <IconButton
                      loading={clipping}
                      disabled={calledFrom?.toLowerCase() === 'digitalcoupons' && acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId)}
                      header={!acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? 'Clip Offer' : 'Clipped'}
                      onPress={handleClip}
                      iconProps={{
                        name: !acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? 'scissor' : 'circle-check',
                        strokeSecondary: !acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? 'transparent' : colors.white,
                        stroke: !acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? 'none' : 'transparent',
                        size: !acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? 44 : 28,
                        style: !acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? { marginBottom: 18, marginRight: 20 } : null,
                      }}
                      buttonStyle={!acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? styles.clipButton : styles.clippedButton}
                      headerStyle={{
                        color: !acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? colors.red : colors.white,
                        marginRight: !acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? 8 : 16,
                        marginLeft: !acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? -8 : -4,
                      }}
                    />
                  )}
                </View>
              )}
            </>
          )}
          {calledFrom === 'SavingReminder' && (
            <View style={styles.cardProgressBarView}>
              {Number(offer.PercentComplete ?? percentComplete ?? 0) >= 100 ? (
                <RedeemProgressBar progress={100} maxValue={100}>
                  <Text testID="completed" style={[appStyles.fontMobileBodyXS, styles.font12, { textAlign: 'center' }]}>
                    {offer.PercentCompleteMessage ?? 'Add more items to Complete!'}
                  </Text>
                </RedeemProgressBar>
              ) : (
                <RedeemProgressBar progress={Number(offer.PercentComplete ?? percentComplete ?? 0)} maxValue={100}>
                  <Text testID="percentCompleteMessage" style={[appStyles.fontMobileBodyXS, styles.font12]} numberOfLines={1}>
                    {offer.PercentCompleteMessage ?? 'Add more items to Complete!'}
                  </Text>
                </RedeemProgressBar>
              )}
            </View>
          )}
          <View style={[styles.viewOfferDetails, { marginTop: calledFrom ? scale(64) : '10%' }]}>
            <TouchableOpacity onPress={() => setShowDetails(!showDetails)} style={styles.offerDetailsButton}>
              <Text style={styles.offerDetailsHeader} testID="offerDetails">
                Offer Details
              </Text>
              {!showDetails ? (
                <Icon size={18} fill={colors.text} name={'triangle-down'} testID="triangleDownIcon" />
              ) : (
                <Icon size={18} fill={colors.text} name={'triangle-up'} testID="triangleUpIcon" />
              )}
            </TouchableOpacity>
            {showDetails && (
              <View style={styles.itemDetailsWrapper}>
                <Text style={styles.offerDetailsText} testID="detailDesc">
                  {offer.Description}
                </Text>
              </View>
            )}
          </View>
          <View style={{ backgroundColor: colors.sectionBorder, height: 1, width: '100%', alignSelf: 'center', marginBottom: 16 }} />
        </View>
        {qualifyingProduct.length > 0 && (
          <View style={styles.qiSection}>
            <Text style={styles.qualifyingItemsHeader}>Qualifying Items ({qualifyingProduct?.length ?? 0})</Text>
          </View>
        )}
      </>
    ) : (
      <></>
    );
  };

  return (
    <Screen style={[utilityStyles.pb0, { paddingTop: 16, backgroundColor: colors.white }]}>
      <NavigationHeader
        showShadow={false}
        style={[styles.chekoutHeaderStyle]}
        subStyle={{ backgroundColor: colors.white, justifyContent: 'center', flexDirection: 'row' }}
        titleStyle={[appStyles.fontMobileH6, { alignSelf: 'center' }]}
        title={''}
        icon={<CloseButton style={{ transform: [{ translateY: 4 }] }} />}
        next={bookMarkIcon}
      />
      <FlatList
        data={qualifyingProduct}
        keyExtractor={(_, i) => i.toString()}
        renderItem={({ item, index }) => <ProductCard product={item.product} index={index} productPromotion={item.promotion} />}
        numColumns={2}
        ItemSeparatorComponent={renderItemSeperator}
        onEndReachedThreshold={0.3}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 75 }}
        ListHeaderComponent={renderHeader}
        onEndReached={handleLoadMore}
        ListFooterComponent={isLoading ? <ActivityIndicator /> : <></>}
      />
    </Screen>
  );
};

const styles = StyleSheet.create({
  chekoutHeaderStyle: {
    borderBottomColor: 'transparent',
    backgroundColor: colors.white,
    shadowColor: 'transparent',
    marginBottom: 8,
    marginTop: 8,
  },
  itemInfoWrapper: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  itemInfoTop: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  offerText: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.primary,
    fontSize: scale(22),
    lineHeight: lineHeight(22),
    textAlign: 'left',
    marginRight: scale(20),
  },
  imgWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0.4,
  },
  itemInfoContainer: {
    height: 150,
    justifyContent: 'space-evenly',
    flex: 0.55,
    paddingLeft: scale(16),
  },
  clipButtonWrapper: {
    width: '95%',
    alignItems: 'center',
    alignSelf: 'center',
    height: 70,
  },
  clipButton: {
    backgroundColor: 'white',
    borderColor: colors.red,
    borderRadius: 30,
    borderWidth: 1.5,
    minWidth: Platform.select({ ios: scale(140), android: scale(150) }),
    width: Platform.select({ ios: scale(140), android: scale(150) }),
    height: scale(35),
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
    paddingRight: 24,
    shadowOpacity: Platform.select({ android: 0 }),
    elevation: Platform.select({ android: 0 }),
  },
  clippedButton: {
    backgroundColor: colors.clippedGreen,
    borderColor: 'transparent',
    borderRadius: 30,
    borderWidth: 1.5,
    minWidth: 140,
    width: 140,
    height: scale(35),
    justifyContent: 'center',
    paddingVertical: 0,
    paddingRight: 24,
    shadowOpacity: Platform.select({ android: 0 }),
    elevation: Platform.select({ android: 0 }),
  },
  offerDetailsButton: {
    width: '100%',
    height: 50,
    alignItems: 'center',
    paddingTop: 4,
    flexDirection: 'row',
  },
  offerDetailsHeader: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    color: colors.text,
    marginRight: 24,
  },
  itemDetailsWrapper: {
    paddingBottom: 16,
  },
  offerDetailsText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(14),
    color: colors.text,
    lineHeight: lineHeight(16),
  },
  qiSection: {
    width: '90%',
    padding: 0,
    alignSelf: 'center',
  },
  qualifyingItemsHeader: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    color: colors.text,
    marginBottom: -5,
  },
  contentContainer: {
    width: screenWidth,
    alignSelf: 'center',
  },
  font12: { fontSize: scale(12), lineHeight: lineHeight(10), width: screenWidth * 0.57, height: scale(24), marginTop: 5 },
  clipOfferView: {
    width: containerWidth * 0.57,
    top: 220,
    position: 'absolute',
    marginLeft: Platform.select({
      ios: 10,
    }),
    justifyContent: 'center',
    alignContent: 'center',
  },
  viewOfferDetails: {
    borderTopColor: colors.sectionBorder,
    borderTopWidth: 1,
    width: '90%',
    alignSelf: 'center',
  },
  viewQualifyingItems: {
    backgroundColor: colors.cream,
    width: '100%',
  },
  bookmarkButtonView: {
    top: 5,
    width: screenWidth / 9,
  },
  itemDetailText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(12),
    textAlign: 'left',
    lineHeight: lineHeight(16),
    marginTop: -3,
  },
  cardProgressBarView: {
    width: containerWidth * 0.57,
    height: screenHeight * 0.032,
  },
});

export default SEOfferDetailsScreen;

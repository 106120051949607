/* eslint-disable react-hooks/exhaustive-deps */

import { Icon, Image, LoadingScreen, QuantityBox, Switch } from '@components';
import CustomizeFooter from '@components/CustomizeFooter';
import Text from '@components/Text';
import { assets } from '@config/assets';
import colors from '@config/colors';
import { ChosenItem, Product } from '@fieldera-raleys/client-commercetools/schema';
import { CustomizeStackRoutes, CustomizeStackScreenProps } from '@navigation/routes';
import { productService } from '@services/commerceTools';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { FlatList, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import { filterUnavailableProducts, formatValue } from '../../utils/helpers';

interface ISelectionScreenProps extends CustomizeStackScreenProps<CustomizeStackRoutes.Selection> {}

//Interface for flatlist items
interface IListItemProps {
  item: Product;
  index: number;
  selectedItems: ChosenItem[];
  setSelectedItems: Dispatch<SetStateAction<ChosenItem[]>>;
  selectionOption?: string;
  limit?: number;
  itemCount: number;
  setItemCount: Dispatch<SetStateAction<number>>;
  calculateSubtotal: () => void;
}

//Interface for checkbox / quantity box
interface IActionBoxProps {
  item: Product;
  selectedItems: ChosenItem[];
  setSelectedItems: Dispatch<SetStateAction<ChosenItem[]>>;
  limit?: number;
  itemCount: number;
  setItemCount: Dispatch<SetStateAction<number>>;
  calculateSubtotal: () => void;
  setSelection?: () => void;
}

const CheckBox: FC<IActionBoxProps> = ({ item, selectedItems, setSelection }): JSX.Element => {
  var price: number | null = null;
  var priceDigits: number | null = null;
  if (item?.masterData?.current?.masterVariant?.price) {
    price = item?.masterData?.current?.masterVariant?.price?.value.centAmount;
    priceDigits = item?.masterData?.current?.masterVariant?.price?.value.fractionDigits;
  }

  return (
    <View style={[styles.checkBoxWrapper, { opacity: selectedItems[0]?.itemName === 'None' ? 0.5 : 1 }]}>
      <View>
        <TouchableOpacity
          disabled={selectedItems[0]?.itemName === 'None'}
          activeOpacity={0.7}
          style={styles.checkButton}
          onPress={setSelection}
          testID="selectItemIconButton">
          {selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name) ? (
            <Icon
              testID="checkedCircleIcon"
              name="checked-circle"
              fill={colors.secondary}
              stroke={'none'}
              strokeSecondary={colors.white}
              fillSecondary={'none'}
              style={appStyles.mediumIcon}
              size={25}
            />
          ) : (
            <Icon testID="uncheckedCircleIcon" name="unchecked-circle" fill={colors.white} stroke={colors.darkText} style={appStyles.mediumIcon} size={25} />
          )}
        </TouchableOpacity>
      </View>
      <TouchableOpacity disabled={selectedItems[0]?.itemName === 'None'} activeOpacity={0.7} onPress={setSelection} testID="selectItemNameButton">
        <Text
          testID="itemName"
          style={[
            styles.checkBoxTitle,
            appStyles.customizeStepLabel,
            {
              fontFamily: selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name) ? 'Larsseit-Bold' : 'Larsseit-Light',
            },
          ]}>
          {item?.masterData?.current?.name}
          {price && price !== 0 ? (
            <Text testID="itemPrice" style={[styles.checkBoxTitle]}>
              {'\n'}(+{price / 10 ** (priceDigits ?? 0)})
            </Text>
          ) : null}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const QuantityContainer: FC<IActionBoxProps> = ({ item, selectedItems, setSelectedItems, limit, itemCount, setItemCount, calculateSubtotal }): JSX.Element => {
  var price: number | null = null;
  var priceDigits: number | null = null;
  if (item?.masterData?.current?.masterVariant?.price) {
    price = item?.masterData?.current?.masterVariant?.price?.value.centAmount;
    priceDigits = item?.masterData?.current?.masterVariant?.price?.value.fractionDigits;
  }
  //The quantity count of the list item
  var individualCount: any = selectedItems?.find((i) => i.itemName === item?.masterData?.current?.name)?.description || 0;

  const changeQty = (qty: number) => {
    //QTY IS ZERO
    // console.log('change qty', 'ind:', individualCount, 'limit:', limit, 'itemCount:', itemCount, 'qty:', qty);
    if (qty === individualCount) {
      return;
    }
    if (qty < 0) {
      return;
    } else if (qty - individualCount + itemCount > (limit ?? 0)) {
      //SHOULD NOT INCREASE ABOVE PAGE LIMIT
      return;
    } else if (selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name)) {
      var tempArr = selectedItems;
      var itemIndex = tempArr.findIndex((i) => i.itemName === item?.masterData?.current?.name);

      //REMOVE ITEM FROM ARRAY
      if (qty === 0) {
        tempArr = tempArr.filter((i) => i.itemName !== item?.masterData?.current?.name);
        setSelectedItems(tempArr);
        setItemCount(itemCount - individualCount);
      }
      //DECREASE
      if (qty < individualCount && qty !== 0) {
        tempArr[itemIndex] = {
          ...tempArr[itemIndex],
          description: qty,
        };
        setSelectedItems(tempArr);
        setItemCount(itemCount - (individualCount - qty));
      }
      //INCREASE
      if (qty > individualCount) {
        tempArr[itemIndex] = {
          ...tempArr[itemIndex],
          description: qty,
        };
        setSelectedItems(tempArr);
        setItemCount(itemCount + (qty - individualCount));
      }
    } else {
      //ADD ITEM TO LIST
      setSelectedItems([
        ...selectedItems,
        {
          id: item?.id,
          sku: item?.masterData?.current?.masterVariant?.sku ?? '',
          itemName: item?.masterData?.current?.name ?? '',
          cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
          description: qty,
          product: item,
        },
      ]);
      // console.log(itemCount);
      setItemCount(itemCount + qty);
    }
    calculateSubtotal();
  };

  return (
    <View style={[styles.quantityContainer]}>
      <Text
        testID="itemName-Qtycontainer"
        style={[
          styles.itemLabel,
          appStyles.customizeStepLabel,
          {
            fontFamily: selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name) ? 'Larsseit-Bold' : 'Larsseit-Light',
          },
        ]}>
        {item?.masterData?.current?.name}
        {price && price !== 0 ? (
          <Text testID="itemPrice-QtyContainer" style={[styles.checkBoxTitle]}>
            {'\n'}(+{formatValue(price / 10 ** (priceDigits ?? 0))})
          </Text>
        ) : null}
      </Text>
      <QuantityBox
        disabled={selectedItems[0]?.itemName === 'None'}
        initialValue={individualCount}
        borderStyle={{ height: 30, alignSelf: 'center', marginBottom: -10 }}
        countStyle={[styles.countText, appStyles.pdpQtyControlBtnNumberSm]}
        containerStyle={[
          styles.quantityBoxWrapper,
          appStyles.prodListControlBtnSetSm,
          {
            opacity: selectedItems[0]?.itemName === 'None' ? 0.5 : 1.0,
            backgroundColor: selectedItems[0]?.itemName === 'None' ? 'rgba(251,247,243,0.5)' : colors.white,
            marginLeft: 0,
            width: '100%',
          },
        ]}
        incrementBy={1}
        maxLimit={Promise.resolve(limit ? limit - (itemCount - individualCount) : 1)}
        onChange={changeQty}
      />
    </View>
  );
};

const ListItem: FC<IListItemProps> = ({
  item,
  index,
  selectedItems,
  setSelectedItems,
  selectionOption,
  limit,
  itemCount,
  setItemCount,
  calculateSubtotal,
}): JSX.Element => {
  var price: number | null = null;
  var priceDigits: number | null = null;
  if (item?.masterData?.current?.masterVariant?.price) {
    price = item?.masterData?.current?.masterVariant?.price?.value.centAmount;
    priceDigits = item?.masterData?.current?.masterVariant?.price?.value.fractionDigits;
  }

  const setSelection = () => {
    if (selectionOption !== 'select') {
      return;
    }
    if (selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name)) {
      setSelectedItems(selectedItems.filter((selection) => selection.itemName !== item?.masterData?.current?.name));
      setItemCount(itemCount - 1);
    } else if (selectedItems?.length === limit) {
      if (limit === 1) {
        setSelectedItems([
          {
            id: item?.id,
            sku: item?.masterData?.current?.masterVariant?.sku ?? '',
            itemName: item?.masterData?.current?.name ?? '',
            cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
            product: item,
          },
        ]);
      }
      return;
    } else {
      if (item?.masterData?.current?.name === 'None') {
        setSelectedItems([
          {
            id: item?.id,
            sku: item?.masterData?.current?.masterVariant?.sku ?? '',
            itemName: item?.masterData?.current?.name ?? '',
            cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
            product: item,
          },
        ]);
        setItemCount(limit ?? 1);
      } else {
        setSelectedItems([
          ...selectedItems,
          {
            id: item?.id,
            sku: item?.masterData?.current?.masterVariant?.sku ?? '',
            itemName: item?.masterData?.current?.name ?? '',
            cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
            product: item,
          },
        ]);
        setItemCount(itemCount + 1);
      }
    }
  };

  const fallbackImage = assets.imageComingSoon;
  const getImageSource = () => {
    let url;
    if (item?.masterData?.current?.masterVariant?.images[0]?.url) {
      url = {
        cache: 'web',
        uri: `${
          item?.masterData?.current?.masterVariant?.images[0]?.url.indexOf('//') === 0
            ? 'https:' + item?.masterData?.current?.masterVariant?.images[0]?.url
            : item?.masterData?.current?.masterVariant?.images[0]?.url
        }`,
      };
    } else {
      url = fallbackImage;
    }

    return url;
  };

  return item?.masterData?.current?.name === 'None' ? (
    <></>
  ) : (
    <TouchableOpacity activeOpacity={1} onPress={setSelection} style={[styles.listItem]} testID={`listItem-${index}`}>
      <View style={styles.itemImageWrapper}>
        <Image testID={`itemImage-${index}`} resizeMode={'contain'} source={getImageSource()} style={styles.image} />
      </View>
      <View style={styles.actionWrapper}>
        {selectionOption === 'select' ? (
          <CheckBox
            item={item}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            limit={limit}
            itemCount={itemCount}
            setItemCount={setItemCount}
            calculateSubtotal={calculateSubtotal}
            setSelection={setSelection}
          />
        ) : (
          <QuantityContainer
            item={item}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            limit={limit}
            itemCount={itemCount}
            setItemCount={setItemCount}
            calculateSubtotal={calculateSubtotal}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

const SelectionScreen: FC<ISelectionScreenProps> = ({ route, navigation }): JSX.Element => {
  const { pageItem = '', selectionOption, catIds, limit, min } = route.params;
  const { selectedIngredients, setSelectedIngredients, calculateSubtotal, currStep } = useCustomizeStore();
  const [selectedItems, setSelectedItems] = useState<ChosenItem[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  // const [choices, setChoices] = useState<Product[]>([]);
  const listRef = useRef<any>(null);
  const {
    data: choices,
    status,
    refetch,
  } = useQuery(`${pageItem.toLowerCase().replace(/\s/g, '')}-selections-list`, () => productService.getProducts({ catId: catIds }), {
    staleTime: 0,
    cacheTime: 0,
    initialData: undefined,
    select: (d) => {
      d?.sort(
        (a, b) =>
          +(a?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catIds?.[0])?.orderHint || 0.8) -
          +(b?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catIds?.[0])?.orderHint || 0.8),
      );
      return filterUnavailableProducts(d);
    },
  });
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  useEffect(() => {
    setIsLoadingProducts(true);
    if (status === 'success') {
      setIsLoadingProducts(false);
    } else {
      setIsLoadingProducts(true);
    }
  }, [choices, status]);

  //Since we are using a single screen for all item selections, we set the local selectedItems to the proper values
  useEffect(() => {
    listRef?.current?.scrollToOffset({ animated: true, offset: 0 });
    refetch();
    setSelected();
  }, [pageItem]);

  //Function updates the context state as local selectedItems change
  useEffect(() => {
    if (selectedIngredients?.some((ing) => ing.ingredientName === pageItem)) {
      var tempArr = selectedIngredients;
      var ingredientIndex = selectedIngredients?.findIndex((ing) => ing.ingredientName === pageItem);

      tempArr[ingredientIndex] = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients(tempArr);
    } else if (selectedItems.length) {
      var newIngredient = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients([...(selectedIngredients ?? []), newIngredient]);
    } else if (selectedItems.length === 0) {
      var filteredArr = selectedIngredients?.filter((ingr) => ingr.ingredientName !== pageItem);
      setSelectedIngredients(filteredArr);
    }
    calculateSubtotal();
  }, [selectedItems]);

  useEffect(() => {
    var forwardEnabled = !selectedItems[0] || itemCount < +(limit ?? 1) ? false : true;
    navigation.setParams({ forwardNavEnabled: forwardEnabled });
  }, [itemCount, pageItem, currStep]);

  //Sets the local selectedItems
  const setSelected = () => {
    if ((selectedIngredients?.length ?? 0) > 0) {
      if (selectedIngredients?.some((ingr) => ingr.ingredientName === pageItem)) {
        var tempArr = selectedIngredients;
        var ingredientIndex = selectedIngredients?.findIndex((ing) => ing.ingredientName === pageItem);

        setSelectedItems(tempArr[ingredientIndex].selections);

        if (selectionOption === 'select') {
          if (tempArr[ingredientIndex].selections[0].itemName === 'None') {
            setItemCount(+(limit ?? 1));
          } else {
            setItemCount(tempArr[ingredientIndex].selections.length);
          }
        } else if (selectionOption === 'quantity') {
          if (tempArr[ingredientIndex]?.selections[0]?.itemName === 'None') {
            setItemCount(+(limit ?? 1));
          } else {
            tempArr[ingredientIndex].selections.forEach((i: any) => {
              setItemCount((prevCount) => prevCount + i.description);
            });
          }
        }
      }
    }
  };

  const setNone = (item: Product) => {
    var price: number | null = null;
    var priceDigits: number | null = null;
    if (item?.masterData?.current?.masterVariant?.price) {
      price = item?.masterData?.current?.masterVariant?.price?.value.centAmount;
      priceDigits = item?.masterData?.current?.masterVariant?.price?.value.fractionDigits;
    }
    if (selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name)) {
      setSelectedItems(selectedItems.filter((selection) => selection.itemName !== item?.masterData?.current?.name));
      setItemCount(0);
    } else {
      setSelectedItems([
        {
          id: item?.id,
          sku: item?.masterData?.current?.masterVariant?.sku ?? '',
          itemName: item?.masterData?.current?.name ?? '',
          cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
          product: item,
        },
      ]);
      setItemCount(+(limit ?? 1));
    }
  };

  const renderHeader = () => {
    return (
      <>
        <Text testID="selectPageItem" style={[styles.selectText, utilityStyles.px3, appStyles.customizeH2]}>
          Select {pageItem}
          {+(limit ?? 0) > 1 ? (
            <Text testID="limit" style={[styles.pickLimitText, appStyles.bodySmallLight]}>
              {'    '}Pick {(limit ?? 1) > (min ?? 1) ? `up to ${limit}` : limit}
            </Text>
          ) : null}
        </Text>
        <Text testID="productWarning" style={[appStyles.fontInputInstructions, { marginLeft: 16, marginVertical: 5 }]}>
          {pageItem.toLowerCase().includes('decoration') ? 'Design' : 'Product'} may vary slightly from what is shown on screen.
        </Text>
      </>
    );
  };

  const renderFooter = () => {
    const item = choices?.find((ch) => ch.masterData.current?.name?.toLowerCase() === 'none');

    return item ? (
      <>
        <View style={styles.none}>
          <Switch
            testID="itemSwitch"
            style={{ flex: 0 }}
            value={selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name)}
            onValueChange={() => setNone(item)}
          />
          <Text testID="itemNameFooter" style={[appStyles.bodyLargeLeft, { marginTop: 6, marginLeft: 8 }]}>
            {item?.masterData?.current?.name}
          </Text>
        </View>
      </>
    ) : null;
  };

  return isLoadingProducts ? (
    <LoadingScreen />
  ) : (
    <View style={styles.container}>
      <View style={styles.body}>
        <FlatList
          ref={listRef}
          style={styles.listContainer}
          contentContainerStyle={[styles.contentContainer, utilityStyles.px3]}
          ListHeaderComponent={renderHeader}
          ListHeaderComponentStyle={{ marginLeft: -16 }}
          ListFooterComponent={renderFooter}
          numColumns={2}
          bounces={false}
          data={choices?.filter((ch) => ch.masterData.current?.name !== 'None')}
          extraData={choices?.filter((ch) => ch.masterData.current?.name !== 'None')}
          keyExtractor={(_, i) => i.toString()}
          renderItem={({ item, index }) => {
            return (
              <ListItem
                item={item}
                index={index}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                selectionOption={selectionOption}
                limit={limit ? +limit : 1}
                itemCount={itemCount}
                setItemCount={setItemCount}
                calculateSubtotal={calculateSubtotal}
              />
            );
          }}
          // ListFooterComponent={() => <CustomizeFooter disabled={!selectedItems[0] || itemCount < limit ? true : false} />}
          // ListFooterComponentStyle={styles.footerWrapper}
        />
      </View>
      <View style={styles.footerWrapper}>
        <CustomizeFooter disabled={!selectedItems[0] || itemCount < +(min ?? 1) ? true : false} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: screenWidth,
    flex: 1,
    paddingVertical: 16,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 12,
  },
  body: {
    flex: 1,
    width: screenWidth,
    paddingHorizontal: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  none: {
    width: screenWidth,
    marginTop: 16,
    marginBottom: 48,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  pickLimitText: {
    fontSize: scale(13),
    fontFamily: 'Aleo-Bold',
    color: 'rgba(0,0,0,0.4)',
  },
  itemText: {
    fontSize: scale(14),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 15,
  },
  selectText: {
    marginBottom: 6,
    paddingTop: Platform.select({
      android: 15,
    }),
  },
  listContainer: {
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingBottom: 30,
  },
  listItem: {
    width: (screenWidth - 32) * 0.4,
    minHeight: screenWidth * 0.45,
    alignItems: 'center',
    margin: 15,
  },
  itemImageWrapper: {
    backgroundColor: colors.white,
    width: screenWidth * 0.37,
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.16 : screenHeight * 0.2) : screenHeight > 640 ? screenHeight * 0.19 : screenHeight * 0.21,
    // flex: 0.85,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    shadowColor: colors.black,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2.22,

    elevation: 3,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  actionWrapper: {
    paddingTop: 5,
    flex: 1,
    // height: ,
    width: '100%',
    justifyContent: 'space-between',
  },
  checkBoxWrapper: {
    flexDirection: 'row',
    alignContent: 'center',
    width: '80%',
  },
  checkButton: {
    marginRight: 8,
    justifyContent: 'center',
    marginLeft: -8,
  },
  checkBoxTitle: {
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: lineHeight(15),
    alignSelf: 'center',
    paddingTop: 4,
  },
  itemLabel: {
    fontSize: scale(15),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 10,
    marginTop: 3,
  },
  quantityContainer: {
    height: '100%',
    justifyContent: 'space-between',
  },
  quantityBoxWrapper: {
    flexDirection: 'row',
    width: '100%',
    height: 30,
    justifyContent: 'center',
    backgroundColor: colors.white,
    padding: 0,
    marginBottom: 5,
    borderRadius: 100,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1,
  },
  decQuantityButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  incQuantityButton: {
    justifyContent: 'center',
    alignItems: 'center',
    transform: [{ translateY: 2 }],
    width: '30%',
  },
  quantityButtonText: {
    fontSize: scale(22),
    fontFamily: 'Larsseit',
    color: colors.darkText,
  },
  quantityCounterTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: '#c69480',
  },
  countText: {
    fontSize: scale(15),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
  },
  footerWrapper: {
    // backgroundColor: 'gold',
    // paddingTop: 45,
    paddingTop: 0,
    alignItems: 'center',
    // justifyContent: 'center',
    flex: 0.5,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 32,
    marginBottom: -95,
  },
});

export default SelectionScreen;

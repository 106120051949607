import { CloseButton, LoadingScreen, ProductCard, Screen } from '@components';
import Text from '@components/Text';
import appConstants from '@config/appConstants';
import { assets } from '@config/assets';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Product, ProductData, ProductVariant } from '@fieldera-raleys/client-commercetools/schema';
import { FacetType, Filter, PagedArray } from '@fieldera-raleys/client-common';
import { ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { useIsFocused } from '@react-navigation/native';
import { brsmService } from '@services';
import { productService } from '@services/brandywine';
import { useAnalyticsStore, useSearchStore, useShopStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import { ProductList, getProductPromotions } from '@utils/productHelper';
import { getOffersAndCouponsByProductList } from '@utils/promotionHelper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Image, StyleSheet, View } from 'react-native';
import Config from 'react-native-config';
import { useQuery } from 'react-query';
interface ProductTabState {
  isLoading: boolean;
  isRefreshing: boolean;
  filterBadgeCount: number;
  productImage?: string;
  productSearchResults: PagedArray<ProductList>;
}

type WeeklyAdsCollectionScreenProps = ShopStackScreenProps<ShopStackRoutes.WeeklyAdsCollection>;

const WeeklyAdsCollectionScreen = ({ route }: WeeklyAdsCollectionScreenProps) => {
  const { t } = useTranslation('weeklyAdsScreen');
  const { sortQuery, searchType, setAvailableFilters } = useSearchStore();
  const { selectedStore } = useShopStore();
  const isFocused = useIsFocused();
  const { previousRoute } = useAnalyticsStore();
  const [state, setState] = useState<ProductTabState>({
    filterBadgeCount: 0,
    isRefreshing: false,
    isLoading: true,
    productSearchResults: {
      data: [],
      offset: 0,
      limit: appConstants.PAGE_SIZE,
      total: 0,
    },
  });

  const { productCollectionId } = route.params ?? {};

  const { data: productCollection, status } = useQuery(
    ['productCollection', productCollectionId],
    async () => await productService.getProductCollectionById(productCollectionId),
    { enabled: isFocused },
  );
  const fallbackImage = assets.imageComingSoon;

  useEffect(() => {
    const loadProducts = async (offset: number, collectionImage: string | undefined, productIds: string[]) => {
      let products: Product[] = [];
      const filters = [
        {
          id: 'pid',
          children: [
            {
              default: false,
              id: 'favorites',
              selected: false,
              title: FacetType.Favorites,
              controlType: 'radio',
              value: () => productIds,
            },
          ],
        },
      ] as Filter[];

      const res = await brsmService.searchProduct(offset, productIds.length, {
        pathname: route.name,
        ref_url: previousRoute?.name,
        searchQuery: '*',
        selectedFilters: filters,
        sortQuery: sortQuery,
        searchType: searchType,
        storeKey: Config.BRSM_DEFAULT_STORE,
      });

      if (productIds && productIds.length > 0) {
        productIds.forEach((pid) => {
          const product = res.docs.data.find((p) => p.masterData.current?.masterVariant.sku === pid);
          if (product) {
            products.push(product);
          }
        });
      }

      if (!collectionImage && products && products.length > 0) {
        const productData: ProductData = products[0].masterData.current as ProductData;
        const masterVariant: ProductVariant = productData.masterVariant as ProductVariant;
        collectionImage = masterVariant.images.length > 0 ? masterVariant.images[0].url ?? '' : '';
      }

      let updatedProductList: ProductList[] = [];
      if (products.length > 0) {
        const skus = products.map((p) => p.masterData.current?.masterVariant.sku ?? '') ?? [];
        const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE ?? '1', skus);
        updatedProductList = getProductPromotions(promotions, res?.docs.data);
      }

      setState((prev) => ({
        ...prev,
        isRefreshing: false,
        isLoading: false,
        filterBadgeCount: 0,
        productImage: collectionImage,
        productSearchResults: {
          ...prev.productSearchResults,
          total: products.length,
          offset: offset,
          data: [...updatedProductList],
        },
      }));
    };

    if (status === 'success') {
      let collectionImage =
        (productCollection.ProductCollectionFiles &&
          productCollection.ProductCollectionFiles.map((pi) => {
            return pi.FileUrl;
          })[0]) ??
        '';
      let productIds =
        (productCollection.ProductCollectionItems &&
          productCollection.ProductCollectionItems.filter((x) => !x.ItemCount).map((product) => product.ExtProductId)) ??
        [];

      loadProducts(0, collectionImage, productIds);
    }
  }, [previousRoute?.name, productCollection, route.name, sortQuery, searchType, status, setAvailableFilters, selectedStore?.number]);

  const getImageSource = () => {
    let url;
    if (state.productImage) {
      url = { cache: 'default', uri: `${state.productImage.indexOf('//') === 0 ? 'https:' + state.productImage : state.productImage}` };
    } else {
      url = fallbackImage;
    }

    return url;
  };

  const renderItemSeperator = () => {
    return <View style={[styles.itemSeperator, productCollection?.Description ? { marginBottom: 15 } : null]} />;
  };

  const renderHeader = () => {
    return (
      <>
        <View style={styles.itemInfoWrapper}>
          <View style={styles.imgWrapper}>
            <Image source={getImageSource()} style={styles.image} resizeMode="contain" />
          </View>
          <View style={styles.itemInfoContainer}>
            <Text numberOfLines={2} style={[styles.itemNameText, appStyles.bodySmallLeft]}>
              {productCollection?.DisplayName}
            </Text>
          </View>
        </View>
        {renderItemSeperator()}
        {productCollection?.Description && (
          <View style={styles.viewOfferDetails}>
            <Text style={styles.offerDetailsText}>{productCollection.Description}</Text>
          </View>
        )}
        <View style={styles.qiSection}>
          <Text style={styles.qualifyingItemsHeader}>
            {t('qualifyingItems')}({state.productSearchResults.data.length})
          </Text>
        </View>
      </>
    );
  };

  return (
    <Screen headerVisible={true}>
      <View style={[appStyles.container, styles.container]}>
        <CloseButton style={{ top: 16, left: 16 }} />
      </View>
      {state.isLoading ? (
        <LoadingScreen />
      ) : (
        <View>
          <FlatList
            data={state.productSearchResults.data}
            keyExtractor={(_, i) => i.toString()}
            renderItem={({ item, index }) => <ProductCard product={item.product} index={index} productPromotion={item.promotion} />}
            numColumns={2}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: 75 }}
            ListHeaderComponent={renderHeader}
          />
        </View>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 50,
  },
  itemInfoWrapper: {
    minHeight: 200,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  imgWrapper: {
    width: '50%',
    height: 200,
    marginLeft: 20,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  itemInfoContainer: {
    paddingLeft: 5,
    paddingRight: 20,
    height: 160,
    justifyContent: 'space-evenly',
    width: '50%',
  },
  itemNameText: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    color: colors.text,
  },
  itemSeperator: {
    backgroundColor: colors.sectionBorder,
    height: 1,
    width: '95%',
    alignSelf: 'center',
  },
  viewOfferDetails: {
    width: '95%',
    alignSelf: 'center',
  },
  itemDetailsWrapper: {
    paddingBottom: 16,
  },
  offerDetailsHeader: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    color: colors.text,
    marginRight: 24,
  },
  offerDetailsText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(14),
    color: colors.text,
    lineHeight: lineHeight(16),
  },
  qiSection: {
    width: '95%',
    padding: 0,
    paddingTop: 32,
    alignSelf: 'center',
  },
  qualifyingItemsHeader: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    color: colors.text,
    marginBottom: 16,
  },
});

export default WeeklyAdsCollectionScreen;

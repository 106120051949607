import { userService } from '@services/brandywine';
import { screenHeight, screenWidth } from '@styles/constants';
import React, { createRef, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ActivityIndicator, Keyboard, KeyboardAvoidingView, Platform, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import Config from 'react-native-config';
import WebView, { WebViewMessageEvent } from 'react-native-webview';
import { useCustomModalContext } from '../../../contexts';

type AddPaymentProfileProps = {
  onCancel: () => void;
  onSuccessfulSave: () => void;
};

const AddPaymentProfile = ({ onCancel, onSuccessfulSave }: AddPaymentProfileProps) => {
  const webViewRef = createRef<WebView>();
  const [state, setState] = useState<{ loading: boolean; loadingError: boolean }>({ loading: true, loadingError: false });
  const [formHtml, setFormHtml] = useState<string>();
  const { showAlertModal } = useCustomModalContext();
  const handleError = useErrorHandler();

  const fetchToken = async () => {
    return userService.getPaymentProfileToken({
      hostedProfileIFrameCommunicatorUrl: `${Config.BASE_WEB_URL}authorizenet/communicator.html`,
      hostedProfilePageBorderVisible: 'false',
      hostedProfilePaymentOptions: 'showCreditCard',
      hostedProfileBillingAddressRequired: 'true',
      hostedProfileCardCodeRequired: 'true',
      hostedProfileHeadingBgColor: '#d72d38',
      hostedProfileSaveButtonText: 'Add',
      hostedProfileValidationMode: 'liveMode',
      hostedProfileBillingAddressOptions: 'showBillingAddress',
      hostedProfileReturnUrl: `${Config.BASE_WEB_URL}authorizenet/return.html`,
    });
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    fetchToken().then((token) => {
      if (token) {
        fetch(`${Config.BASE_WEB_URL}customer/add-payment-method`, {
          method: 'POST',
          body: JSON.stringify(token),
          headers: { 'Content-Type': 'application/json' },
        })
          .then((res) => res.text())
          .then((text) => {
            setFormHtml(text);
          })
          .catch((ex) => handleError(ex));
      }
      return () => {
        setFormHtml(undefined);
      };
    });
  }, [handleError]);

  const runBeforeFirst = `window.onerror = function(event, source, lineno, colno, error) {
    window.ReactNativeWebView.postMessage(JSON.stringify({event, source, lineno, colno, error}));
    return true;
  };
  window.isNativeApp = true;
  window.name = "add-payment-method";
  true; // note: this is required, or you'll sometimes get silent failures
`;

  const handleLoadStart = () => {
    setState((prev) => ({ ...prev, loading: true }));
  };

  const handleLoadEnd = () => {
    setState((prev) => ({ ...prev, loading: false }));
  };

  const handleLoadError = () => {
    setState((prev) => ({ ...prev, loading: false, loadingError: true }));
  };

  const syncProfile = () => {
    setFormHtml(undefined);
    userService
      .syncPaymentProfile()
      .then(() => onSuccessfulSave())
      .catch(() => {
        showAlertModal({
          title: 'Error',
          message: 'Failed to add Payment Method. Please try again or contact customer service.',
          closeButtonText: 'Close',
          onClose: () => {
            fetchToken().then((token) => {
              token && setFormHtml(token);
            });
          },
        });
      });
  };

  const onWebViewMessage = (event: WebViewMessageEvent) => {
    const data = JSON.parse(event.nativeEvent.data) as { action: 'cancel' | 'close' | 'successfulSave' | 'transactResponse' | 'resizeWindow' } & Record<
      string,
      string
    >;
    switch (data.action) {
      case 'cancel':
      case 'close':
        onCancel();
        return;
      case 'successfulSave':
        syncProfile();
        return;
      case 'resizeWindow':
        handleLoadEnd();
        return;
    }
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={Platform.select({ ios: 0, android: 50 })} behavior="padding">
      <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? () => Keyboard.dismiss() : undefined}>
        <>
          {state.loading && <ActivityIndicator size={'large'} style={styles.activityIndicator} />}
          {formHtml && (
            <WebView
              ref={webViewRef}
              source={{
                baseUrl: `${Config.BASE_WEB_URL}`,
                html: `${formHtml}`,
              }}
              style={state.loading ? { display: 'none' } : { flex: 1 }}
              originWhitelist={['*']}
              onMessage={onWebViewMessage}
              onLoadStart={handleLoadStart}
              onLoadEnd={handleLoadEnd}
              onError={handleLoadError}
              startInLoadingState={true}
              setSupportMultipleWindows={false}
              injectedJavaScriptBeforeContentLoaded={runBeforeFirst}
              contentMode="mobile"
              sharedCookiesEnabled={false}
              webviewDebuggingEnabled={false}
            />
          )}
        </>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};
const styles = StyleSheet.create({
  activityIndicator: {
    alignSelf: 'center',
    justifyContent: 'center',
    width: screenWidth,
    height: screenHeight,
  },
});

export default AddPaymentProfile;

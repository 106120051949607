import { userService } from '@services/brandywine';
import logger from '@utils/logger';
import { create } from 'zustand';
import { UserProfileStore } from './storeTypes';

const useUserProfileStore = create<UserProfileStore>()((set, _) => ({
  userProfile: undefined,
  loadUserProfile: async () => {
    const profile = await userService.getProfile();
    if (profile) {
      set((state) => ({ ...state, userProfile: profile }));
      return profile;
    } else {
      logger.warn('failed to get user profile');
    }
    return undefined;
  },
  setUserProfile: (profile) => {
    set((state) => ({ ...state, userProfile: profile }));
  },
  clearUserProfile: () => {
    set((state) => ({ ...state, userProfile: undefined }));
  },
}));

export default useUserProfileStore;

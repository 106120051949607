import { create } from 'zustand';
import { DeepLinkStore } from './storeTypes';

const useDeeplinkStore = create<DeepLinkStore>()((set) => ({
  deeplinks: [],
  addDeepLink: (link) => {
    set((prev) => ({ ...prev, deeplinks: [...prev.deeplinks, link] }));
  },
  removeDeepLink: (id) => {
    set((prev) => ({ ...prev, deeplinks: prev.deeplinks.filter((x) => x.id !== id) }));
  },
}));

export default useDeeplinkStore;

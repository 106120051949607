import { DropShadow, NavigationHeader, Screen } from '@components';
import HeaderTitle from '@components/HeaderTitle';
import Tabbar, { TabItem } from '@components/Tabbar';
import Tombstone from '@components/Tombstone';
import OffersList from '@components/somethingExtra/OffersList';
import WeeklyExclusives from '@components/somethingExtra/WeeklyExclusives';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { RewardsStackRoutes, RewardsStackScreenProps } from '@navigation/routes';
import { utilityStyles } from '@styles';
import { lineHeight, scale } from '@styles/constants';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { DigitalCouponsScreen } from '../shop';

type SEOffersScreenProps = RewardsStackScreenProps<RewardsStackRoutes.SEOffers>;
const SEOffersScreen = ({ route }: SEOffersScreenProps) => {
  const { currTab: initialTab = 'Personalized' } = route.params ?? { currTab: undefined };
  const [currTab, setCurrTab] = useState(initialTab);
  const tabItems: TabItem[] = [
    {
      name: 'Personalized',
      displayName: 'Personalized',
      onPress: () => {
        setCurrTab('Personalized');
      },
    },
    {
      name: 'Member Deals',
      displayName: 'Member Deals',
      onPress: () => {
        setCurrTab('Member Deals');
      },
    },
    {
      name: 'Digital Coupons',
      displayName: 'Digital Coupons',
      onPress: () => {
        setCurrTab('Digital Coupons');
      },
    },
  ];

  useEffect(() => {
    setCurrTab(initialTab);
  }, [initialTab]);

  return (
    <Screen style={utilityStyles.pb0}>
      <NavigationHeader subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }} showShadow={false}>
        <HeaderTitle>Offers & Savings</HeaderTitle>
      </NavigationHeader>
      <View style={styles.container}>
        <DropShadow>
          <Tabbar tabs={tabItems} selectedTabName={currTab} />
        </DropShadow>
        {currTab === 'Personalized' && <OffersList />}
        {currTab === 'Member Deals' && <WeeklyExclusives />}
        {currTab === 'Digital Coupons' && <DigitalCouponsScreen />}
      </View>
      {currTab === 'Weekly Ad' && <Tombstone />}
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: 60,
  },
  noShadow: {
    shadowOpacity: 0,
  },
  tabWrapper: {
    marginBottom: 0,
    flexDirection: 'row',
    minWidth: '100%',
    minHeight: 32,
    maxHeight: 50,
    justifyContent: 'space-evenly',
    backgroundColor: colors.white,
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingHorizontal: 4,
    top: Platform.select({ android: scale(2.5) }),
  },
  tabTitle: {
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    fontFamily: FontFamily.LarsseitBold,
  },
  scrollView: {
    flexGrow: 1,
  },
  toggleBar: {
    marginTop: 65,
    alignSelf: 'center',
    width: 240,
    height: 25,
    borderRadius: 5,
    backgroundColor: colors.darkCream,
    flexDirection: 'row',
    padding: 1,
  },
  toggleWrapper: {
    flexDirection: 'row',
  },
  toggle: {
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
});

export default SEOffersScreen;

import { Address as CTAddress, StoreAddress as CTStoreAddress, FulfillmentStore } from '@fieldera-raleys/client-commercetools/schema';
import { Address as BWAddress, ShopType, Store } from '@fieldera-raleys/client-common';
import { useEffectOnce } from '@hooks';
import { useDeepLinks } from '@hooks/useDeepLinks';
import { getLastKnownLocation } from '@hooks/useLocation';
import { updateNamedUser } from '@hooks/useNotifications';
import { createStackNavigator } from '@react-navigation/stack';
import { AccordionListScreen, LiveAgentChatScreen, PermissionsScreen, ProductDetailsScreen, SearchFilterScreen, WebBrowserScreen } from '@screens';
import ProductCarouselListScreen from '@screens/ProductCarouselListScreen';
import { ShelfGuideDefinitionsScreen } from '@screens/shop';
import { SEOfferDetailsScreen } from '@screens/somethingExtra';
import { storeService } from '@services/brandywine';
import {
  useAppConfigStore,
  useAuthStore,
  useCartStore,
  useCommerceToolsStore,
  useDeviceInfoStore,
  useOffersStore,
  useShopStore,
  useShoppingListsStore,
  useStoreFinderFilterStore,
  useUserProfileStore,
  useUserSettingsStore,
} from '@store';
import { formatPhone } from '@utils/helpers';
import { getFulfilmentStore, getShippingAddress, getShippingMethod } from '@utils/orderHelpers';
import { CancelToken } from 'apisauce';
import dayjs from 'dayjs';
import { parse } from 'expo-linking';
import React, { useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import Config from 'react-native-config';
import CheckoutStackNavigator from './CheckoutNavigator';
import CustomizeStackNavigator from './CustomizeNavigator';
import RootTabNavigator from './RootTabNavigator';
import { NavigationProps } from './declarations';
import { AppStackParamList, AppStackRoutes, CheckoutStackRoutes } from './routes';

const getshopType = (shop_type: string): ShopType | undefined => {
  switch (shop_type.trim().toLowerCase()) {
    case 'pickup':
      return ShopType.PICKUP;
    case 'delivery':
      return ShopType.DELIVERY;
    case 'in-store':
      return ShopType.IN_STORE;
    default:
      return undefined;
  }
};

const AppStack = createStackNavigator<AppStackParamList>();

const AppStackNavigation = ({ queryParams, navigationRef }: NavigationProps) => {
  const { deviceInfo } = useDeviceInfoStore();
  const { setCartData, cartData, cart, initialize: initializeCart, setShippingAddress, setTimeSlot, initializeCartContact, validateCart } = useCartStore();
  const { initialize: initializeOffers } = useOffersStore();
  const { initialize: initializeDepartments } = useStoreFinderFilterStore();
  const {
    selectedShopType,
    setDeliveryAddress,
    deliveryAddress,
    selectedStore,
    selectedTimeSlot,
    setSelectedShopType,
    setSelectedStore,
    setSelectedTimeSlot,
    loadPreviouslyShoppedStores,
    setPreviousShopType,
    setCurrPage,
    loadFavoriteStores,
  } = useShopStore();
  const { initialize: initializeLists } = useShoppingListsStore();
  const [cartInitialized, setCartInitialized] = useState(false);
  const [storeInitialized, setStoreInitialized] = useState(false);
  const { loadUserProfile, userProfile } = useUserProfileStore();
  const { isAuthenticated } = useAuthStore();
  const { initialize: initializeCommerceToolsStore, loadStore: loadCommerceToolsStore } = useCommerceToolsStore();
  const { t } = useTranslation('cart');
  const handleError = useErrorHandler();
  const { appConfig, getConfigValue, upgradeMode } = useAppConfigStore();
  const [showAppUpgradeModal, setShowAppUpgradeModal] = useState(false);
  const { userSettings, updateSetting } = useUserSettingsStore();
  useDeepLinks();

  //TODO: for optimization do we really need to load store Favorites and Previous here? can this be delayed to on access?
  useEffectOnce(() => {
    const cts = CancelToken.source();
    const init = async () => {
      if (isAuthenticated()) {
        const locationData = await getLastKnownLocation();
        await loadUserProfile();
        if (Platform.OS !== 'web') {
          updateNamedUser();
        }
        loadPreviouslyShoppedStores(locationData?.coords.latitude, locationData?.coords.longitude, cts.token);
        loadFavoriteStores(locationData?.coords.latitude, locationData?.coords.longitude, cts.token);
        initializeOffers(cts.token);
        initializeDepartments();
      }
    };

    init();
    return () => {
      cts.cancel('unmount');
    };
  });

  useEffect(() => {
    if (!cart) {
      setCartInitialized(false);
    }
  }, [cart]);

  useEffectOnce(() => {
    // Load commercetools Store
    const initialize = async () => {
      await initializeCommerceToolsStore();
      await loadCommerceToolsStore(selectedStore?.number);
      setStoreInitialized(true);
    };

    try {
      initialize();
    } catch (ex) {
      setStoreInitialized(false);
      handleError(ex);
    }
  });

  useEffect(() => {
    //initialize cart
    const initialize = async () => {
      var serverCart = await initializeCart();
      // reset shopping context from cart
      const cartShippingMethod = getShippingMethod(serverCart);
      if (cartShippingMethod && (!selectedShopType || (cartData.cartReset ?? false))) {
        const fStore = getFulfilmentStore(serverCart);
        const sAddr: CTAddress | undefined = getShippingAddress(serverCart);
        if (fStore) {
          let store;
          if (cartShippingMethod === ShopType.DELIVERY) {
            if (sAddr?.postalCode) {
              const searchResults = await storeService.searchStores(0, 10, {
                shippingMethod: 'delivery',
                postalCode: sAddr?.postalCode,
              });
              if (!(store = searchResults.data?.find((s) => s.number === fStore.number))) {
                store = searchResults.data?.[0];
              }
            }
            setDeliveryAddress(sAddr as BWAddress);
          } else if (cartShippingMethod === ShopType.PICKUP || cartShippingMethod === ShopType.IN_STORE) {
            setDeliveryAddress(undefined);
          }
          if (!store) {
            store = await storeService.getStore(fStore.number);
          }
          setSelectedShopType(cartShippingMethod);
          setSelectedStore(store);
        }
        if (cartData.cartReset ?? false) {
          setCartData({ cartReset: false });
        }
      }
      setCartInitialized(true);
    };

    if (storeInitialized && !cartInitialized) {
      try {
        initialize();
      } catch (ex) {
        setCartInitialized(false);
        handleError(ex);
      }
    }
  }, [
    storeInitialized,
    cartInitialized,
    setSelectedStore,
    initializeCart,
    handleError,
    selectedShopType,
    setSelectedShopType,
    setDeliveryAddress,
    cartData.cartReset,
    setCartData,
  ]);

  useEffect(() => {
    // update selected Store and reload favorites
    const abortController = new AbortController(); // the dom one, not the npm one
    const updateStoreAndReloadFavorites = async () => {
      const store = await loadCommerceToolsStore(selectedStore?.number);
      const priceChannelId = store?.distributionChannels.map((x) => x.id)[0];
      const availablityChannelIds = store?.supplyChannels.map((x) => x.id);
      await initializeLists(priceChannelId, availablityChannelIds, abortController.signal);
    };

    if (cartInitialized) {
      try {
        updateStoreAndReloadFavorites();
      } catch (ex) {
        // TODO: add logic to retry??
        handleError(ex);
      }
    }
    () => {
      abortController.abort();
    };
  }, [cartInitialized, handleError, loadCommerceToolsStore, selectedStore, initializeLists]);

  useEffect(() => {
    if (!cartInitialized || !appConfig) {
      return;
    }

    // update shipping based on selected shop type and selected store
    const getStorePhone = (): string | undefined => {
      const phone =
        (selectedStore?.departments ?? []).find((x) => x && x.departmentId === +(Config.ONLINE_DEPARTMENT_ID ?? '11'))?.phoneNumber ?? selectedStore?.phone;
      return phone && formatPhone(phone);
    };
    const updateShipping = async () => {
      if (selectedStore && selectedStore.id) {
        const fulfilmentStore = {
          brand: selectedStore.brand.name,
          name: selectedStore.name,
          number: selectedStore.number,
          logo: (selectedStore.brand.name ?? 'raleys').replace(/[^A-Z0-9]/gi, '').toLowerCase(),
          address: {
            address1: selectedStore.address.street,
            city: selectedStore.address.city,
            state: selectedStore.address.state,
            postalCode: selectedStore.address.zip,
            country: 'US',
            companyName: selectedStore.name,
            phone: getStorePhone(),
            email: selectedStore.email,
            addressType: 'business',
            isValidated: true,
          } as CTStoreAddress,
        } as FulfillmentStore;

        if (selectedShopType === ShopType.PICKUP) {
          const address = {
            address1: selectedStore.address.street,
            city: selectedStore.address.city,
            state: selectedStore.address.state,
            postalCode: selectedStore.address.zip,
            businessType: true,
            company: selectedStore.name,
            defaultAddress: false,
            phone: getStorePhone(),
            country: 'US',
            email: selectedStore.email,
            addressType: 'business',
            isValidated: true,
          } as BWAddress;

          await setShippingAddress(address, 'pickup', fulfilmentStore, getConfigValue<string>('PickupInstructions'));
        } else if (selectedShopType === ShopType.DELIVERY) {
          await setShippingAddress(deliveryAddress, 'delivery', fulfilmentStore, undefined);
        }
      } else {
        await setShippingAddress(undefined, selectedShopType === ShopType.PICKUP ? 'pickup' : 'delivery', undefined, undefined);
      }
    };

    try {
      updateShipping();
    } catch (ex) {
      // TODO: add logic to retry??
      handleError(ex);
    }
  }, [cartInitialized, selectedShopType, deliveryAddress, selectedStore, setShippingAddress, t, handleError, appConfig, getConfigValue]);

  useEffect(() => {
    // updated timeslot in cart to selected timeslot
    if (cartInitialized) {
      setTimeSlot(selectedTimeSlot);
    }
  }, [cartInitialized, selectedTimeSlot, setTimeSlot]);

  useEffect(() => {
    // initialize Customer Contact incase one is missing
    if (cartInitialized && userProfile) {
      initializeCartContact();
    }
  }, [cartInitialized, initializeCartContact, userProfile]);

  const updateShoppingContext = useCallback(
    (shopType: ShopType, store?: Store, postalCode?: string) => {
      setPreviousShopType(shopType);
      setSelectedShopType(shopType);
      setSelectedTimeSlot();
      setSelectedStore(store);
      setDeliveryAddress(shopType === ShopType.PICKUP ? undefined : store ? ({ state: store.address.state, postalCode: postalCode } as BWAddress) : undefined);
      setCurrPage(shopType === ShopType.PICKUP ? 'FindAStore' : 'Delivery');
    },
    [setPreviousShopType, setSelectedShopType, setSelectedTimeSlot, setSelectedStore, setDeliveryAddress, setCurrPage],
  );

  const setShoppingContext = useCallback(
    async (shop_type?: string, store_id?: string, postal_code?: string) => {
      if (shop_type) {
        const incomingShopType = getshopType(shop_type);
        if (!incomingShopType) {
          return updateShoppingContext(ShopType.PICKUP, undefined, undefined);
        }
        // no context set or cart is empty
        if (!selectedStore || (selectedStore && cart?.lineItems.length === 0)) {
          if (incomingShopType === ShopType.PICKUP && store_id) {
            const store = await storeService.getStore(store_id);
            if (store) {
              updateShoppingContext(incomingShopType, store);
            }
          } else if (incomingShopType === ShopType.DELIVERY && postal_code) {
            var locationData = await getLastKnownLocation();
            const searchResults = await storeService.searchStores(0, 1, {
              shippingMethod: 'delivery',
              postalCode: postal_code,
              latitude: locationData?.coords.latitude,
              longitude: locationData?.coords.longitude,
            });
            const store = searchResults.data[0];
            updateShoppingContext(incomingShopType, store, postal_code);
          }
        } else {
          var store: Store | undefined;
          if (incomingShopType === ShopType.PICKUP && store_id) {
            store = await storeService.getStore(store_id);
          } else if (incomingShopType === ShopType.DELIVERY && postal_code) {
            var locationData = await getLastKnownLocation();
            const searchResults = await storeService.searchStores(0, 1, {
              shippingMethod: 'delivery',
              postalCode: postal_code,
              latitude: locationData?.coords.latitude,
              longitude: locationData?.coords.longitude,
            });
            store = searchResults.data[0];
          }
          if (store?.number) {
            const validationResult = await validateCart(store.number);
            if (validationResult.isValid) {
              if (incomingShopType === ShopType.PICKUP && store_id) {
                updateShoppingContext(incomingShopType, store);
              } else if (incomingShopType === ShopType.DELIVERY && postal_code) {
                updateShoppingContext(incomingShopType, store, postal_code);
              }
            } else {
              navigationRef?.current &&
                navigationRef.current.navigate(AppStackRoutes.CartAndCheckout, {
                  screen: CheckoutStackRoutes.CartLanding,
                  params: { shopType: incomingShopType, storeNumber: store.number },
                });
            }
          }
        }
      }
    },
    [cart?.lineItems.length, navigationRef, selectedStore, updateShoppingContext, validateCart],
  );

  useEffect(() => {
    if (queryParams) {
      const { shop_type, store_id, postal_code, returnUrl } = queryParams as {
        shop_type?: string;
        store_id?: string;
        postal_code?: string;
        returnUrl?: string;
      };
      if (returnUrl) {
        const parsedUrl = parse(returnUrl);
        console.log('returnUrl: ', returnUrl, parsedUrl);
      } else if (shop_type || store_id || postal_code) {
        setShoppingContext(shop_type, store_id, postal_code);
      }
    }
  }, [queryParams, setShoppingContext]);

  useEffect(() => {
    if (
      !showAppUpgradeModal &&
      (upgradeMode ?? '') === 'SuggestUpgrade' &&
      (userSettings?.lastCkecked === undefined || dayjs().diff(userSettings.lastCkecked, 'days') > 0)
    ) {
      setShowAppUpgradeModal(true);
    } else if (showAppUpgradeModal && upgradeMode !== 'SuggestUpgrade') {
      setShowAppUpgradeModal(false);
    }
  }, [showAppUpgradeModal, updateSetting, upgradeMode, userSettings?.lastCkecked]);

  return (
    <AppStack.Navigator screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
      {deviceInfo?.requestPermissions && <AppStack.Screen name={AppStackRoutes.Permissions} component={PermissionsScreen} initialParams={queryParams ?? {}} />}
      <AppStack.Screen name={AppStackRoutes.RootTabs} component={RootTabNavigator} initialParams={queryParams ?? {}} />
      <AppStack.Screen name={AppStackRoutes.LiveAgent} component={LiveAgentChatScreen} initialParams={{}} />
      <AppStack.Screen
        name={AppStackRoutes.Customize}
        component={CustomizeStackNavigator}
        options={{
          presentation: 'modal',
        }}
      />
      <AppStack.Screen
        name={AppStackRoutes.ProductDetails}
        component={ProductDetailsScreen}
        options={{
          presentation: 'modal',
        }}
      />
      <AppStack.Screen
        name={AppStackRoutes.ShelfGuideDefinitions}
        component={ShelfGuideDefinitionsScreen}
        options={{
          presentation: 'modal',
        }}
      />
      <AppStack.Screen
        name={AppStackRoutes.ProductCarouselList}
        component={ProductCarouselListScreen}
        options={{
          presentation: 'modal',
        }}
      />
      <AppStack.Screen name={AppStackRoutes.OfferDetails} component={SEOfferDetailsScreen} options={{ presentation: 'modal' }} />
      <AppStack.Screen name={AppStackRoutes.CartAndCheckout} component={CheckoutStackNavigator} />
      <AppStack.Screen name={AppStackRoutes.AccordionScreen} component={AccordionListScreen} />
      <AppStack.Screen name={AppStackRoutes.SearchFilter} component={SearchFilterScreen} />
      <AppStack.Screen name={AppStackRoutes.WebBrowser} component={WebBrowserScreen} options={{ presentation: 'modal' }} />
    </AppStack.Navigator>
  );
};

export default AppStackNavigation;

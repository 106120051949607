import { Button, DropShadow, Icon, ListAccordionItem, ListHeader, Screen, SearchHeader } from '@components';
import Text from '@components/Text';
import ProductCarousel from '@components/brsm/ProductCarousel';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import { Product, ProductData, ShoppingList, ShoppingListLineItem, TextLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { getDisplayPriceBySellType } from '@fieldera-raleys/client-common/utils';
import { AccountStackRoutes, AccountStackScreenProps } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useAppConfigStore, useShoppingListsStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import { getProductQuantityValue } from '@utils/orderHelpers';
import { getProductAisleBayBin, getProductsfromCommerceTools, getRootCategory } from '@utils/productHelper';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../../contexts';

//Store attributes needed:
//name, aisle_bay, price, leadtime, image, unit, qty, sku
type ListData = Record<string, { listItem: ShoppingListLineItem | TextLineItem | undefined; product?: Product; sortOrder?: string }[]>;
const MISC_ITEMS = 'Misc Items';
type ViewListScreenProps = AccountStackScreenProps<AccountStackRoutes.ViewList>;
type ViewListScreenState = {
  shoppingList?: ShoppingList;
  productData: Product[];
  isLoading: boolean;
  searchText?: string;
  listData?: ListData;
  itemCount: number;
  checkedItemCount: number;
  estimatedAmount: number;
};

const ViewListScreen = ({ route }: ViewListScreenProps) => {
  const { title, listName, listId } = route.params;
  const { showModal } = useCustomModalContext();
  const [state, setState] = useState<ViewListScreenState>({ isLoading: true, productData: [], checkedItemCount: 0, estimatedAmount: 0, itemCount: 0 });
  const [showChecked, setShowChecked] = useState(true);
  const [searchText, setSearchText] = useState('');
  const { shoppingLists, unCheckAll, clearList, removeItemsFromList, groupBy } = useShoppingListsStore();
  const { getConfigGroup } = useAppConfigStore();
  const pageConfig = getConfigGroup('ShoppingList');
  const tabBarHeight = useBottomTabBarHeight();

  const displayPrice = useCallback((item: ShoppingListLineItem, product?: ProductData) => {
    let sellType = getProductAttributeValue('unitSellType', item.variant?.attributesRaw ?? []) ?? { key: 'byEach', label: 'By Each' };
    const unitAverageBuyWeight = getProductAttributeValue('unitAverageBuyWeight', item.variant?.attributesRaw ?? []);
    const priceCustomValues = item.variant?.price?.custom?.customFieldsRaw ?? [];
    let onSale =
      (item.variant?.price?.value.centAmount ?? 0) < (priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount ?? 0) ? true : false;
    let qty = 1;
    if (product) {
      qty = getProductQuantityValue(product, item.quantity);
    }
    return onSale
      ? getDisplayPriceBySellType(sellType.key, item.variant?.price?.value, unitAverageBuyWeight, qty, false)
      : priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value && priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount
        ? getDisplayPriceBySellType(sellType.key, priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value, unitAverageBuyWeight, qty, false)
        : getDisplayPriceBySellType(sellType.key, item.variant?.price?.value, unitAverageBuyWeight, qty, false);
  }, []);

  const initializeData = useCallback(
    async (shoppingList: ShoppingList) => {
      if (shoppingList && shoppingList.lineItems.some((li) => !li.variant)) {
        let emptyItems = shoppingList.lineItems.filter((li) => !li.variant);
        let itemIds = emptyItems?.map((fli) => fli.id);
        let itemNames = emptyItems?.map((fli) => fli.name);
        await removeItemsFromList(shoppingList.id, itemIds, []);

        showModal({
          title: 'Missing Items',
          children: (
            <>
              <Text style={appStyles.bodyLargeCenter} testID="problemMsg">
                Sorry, there was a problem loading the following list items:
              </Text>
              {itemNames.map((name) => {
                return (
                  <Text style={[appStyles.bodyLargeCenter, { marginVertical: 8, fontFamily: FontFamily.LarsseitBold }]} testID="name">
                    {name}
                  </Text>
                );
              })}
              <Text style={appStyles.bodyLargeCenter} testID="removeMsg">
                They have been removed from your list. Please re-add where applicable.
              </Text>
            </>
          ),
          // cancelButtonOnPress: () => logger.log('back'),
          cancelButtonText: 'OK',
          location: 'top',
          showCloseIcon: false,
          contentStyle: {
            paddingTop: 8,
          },
        });
      }
      let skus = shoppingList?.lineItems?.map((x) => x.variant?.sku as string);
      const productList: Product[] = await getProductsfromCommerceTools(skus);
      let lineItemData: ListData = {};
      let itemCount = 0;
      let checkedItemCount = 0;
      let estimatedAmount = 0;
      if (productList.length) {
        let groupedData: ListData = {
          ...shoppingList?.lineItems?.reduce((list, li) => {
            let sellType = getProductAttributeValue('unitSellType', li.variant?.attributesRaw ?? []) ?? { key: 'byEach', label: 'By Each' };
            if (sellType.key === 'byWeight') {
              itemCount += 1;
            } else {
              itemCount += li?.quantity;
            }
            checkedItemCount += li?.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value ? 1 : 0;
            let product = productList.find(
              (p) =>
                p.masterData.current?.masterVariant.sku === (li.variant?.sku ?? '') ||
                p.masterData.current?.variants.some((v) => v.sku === (li.variant?.sku ?? '')),
            );
            estimatedAmount += Number(displayPrice(li, product?.masterData.current ?? undefined) ?? 0) ?? 0;
            if (product && groupBy === 'category') {
              const category = getRootCategory(product.masterData?.current?.categories);
              const categoryName = category?.name ?? 'Other';
              const sortOrder = category?.orderHint ?? '1';
              if (categoryName in list) {
                list[categoryName].push({ listItem: li, product, sortOrder });
              } else {
                list[categoryName] = [{ listItem: li, product, sortOrder }];
              }
            } else {
              const areaBayShelfBin = getProductAisleBayBin(li);
              const locationName = areaBayShelfBin ?? 'Other';
              if (locationName in list) {
                list[locationName].push({ listItem: li, product });
              } else {
                list[locationName] = [{ listItem: li, product }];
              }
            }
            return list;
          }, {} as ListData),
        };

        const sortedList = Object.entries(groupedData)?.sort((a, b) => a[0].localeCompare(b[0], 'en', { numeric: true }));
        lineItemData = Object.fromEntries(sortedList);
      }
      const textLineItems =
        shoppingList?.textLineItems && shoppingList?.textLineItems?.length > 0
          ? {
              [MISC_ITEMS]: shoppingList?.textLineItems?.map((li) => {
                itemCount += li?.quantity;
                checkedItemCount += li?.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value ? 1 : 0;
                return { listItem: li };
              }),
            }
          : undefined;

      setState((prev) => ({
        ...prev,
        isLoading: false,
        productData: productList ?? [],
        shoppingList: shoppingList,
        listData: { ...lineItemData, ...textLineItems },
        itemCount,
        estimatedAmount,
        checkedItemCount,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupBy],
  );

  useEffect(() => {
    const shoppingList = shoppingLists.find((l) => l.name === listName);
    if (shoppingList) {
      initializeData(shoppingList);
    }

    () => {
      setState((prev) => ({ ...prev, isLoading: true, productData: [], shoppingList: undefined, listData: undefined, searchText: undefined }));
    };
  }, [initializeData, listName, shoppingLists]);

  const unCheckAllItems = async () => {
    const lineItemIds = state.shoppingList?.lineItems
      .filter((li) => li.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true)
      .map((li) => {
        return li.id;
      });
    const textLineItemIds = state.shoppingList?.textLineItems
      .filter((ti) => ti.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true)
      .map((ti) => {
        return ti.id;
      });
    await unCheckAll(listId, lineItemIds ?? [], textLineItemIds ?? []);
  };

  const clearListItems = async () => {
    await clearList(listId);
  };

  const handleReuseList = () => {
    showModal({
      title: 'Uncheck Your Items?',
      children: (
        <Text style={appStyles.bodyLargeCenter} testID="uncheckMsg">
          Are you sure you want to uncheck your list items?
        </Text>
      ),
      cancelButtonText: 'Back',
      okButtonOnPress: unCheckAllItems,
      okButtonText: 'Yes',
      location: 'top',
      showCloseIcon: false,
      contentStyle: {
        paddingTop: 8,
      },
    });
  };

  const handleClearAll = () => {
    showModal({
      title: 'Clear List?',
      children: (
        <Text style={appStyles.bodyLargeCenter} testID="removeAllMsg">
          Are you sure that you want to remove all items on your list?
        </Text>
      ),
      // cancelButtonOnPress: () => logger.log('back'),
      cancelButtonText: 'Back',
      okButtonOnPress: clearListItems,
      okButtonText: 'Yes',
      location: 'top',
      showCloseIcon: false,
      contentStyle: {
        paddingTop: 8,
      },
    });
  };
  const handleRefresh = () => {};

  const renderCategory = (
    mode: 'checked' | 'unchecked',
    category: string,
    value: { listItem: ShoppingListLineItem | TextLineItem | undefined; product?: Product }[],
  ) => {
    const shouldRender = value.some(({ listItem: li }) => {
      const fieldValue = li?.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value;
      return mode === 'unchecked' ? !fieldValue : fieldValue;
    });

    return shouldRender ? (
      <View key={`${category}_container`} style={styles.catContainer}>
        {category && (
          <View style={styles.catHeader} key={`${category}_header`}>
            <Text style={appStyles.bodySmallLight}>{category}</Text>
          </View>
        )}
        {value
          .filter(({ listItem: li }) => {
            const fieldValue = li?.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value;
            return mode === 'unchecked' ? !fieldValue : fieldValue;
          })
          .map((x, idx, arr) => {
            return x.listItem ? (
              <Fragment key={`fragment-${x.listItem.id}`}>
                <ListAccordionItem
                  listId={listId}
                  item={x.listItem}
                  productInfo={x.product}
                  listName={listName ?? ''}
                  checked={false}
                  linkAction={() => findItems(x.listItem?.name ?? '')}
                />
                {idx + 1 !== arr.length && <View style={styles.itemSeparator} />}
              </Fragment>
            ) : (
              <></>
            );
          })}
      </View>
    ) : (
      <></>
    );
  };

  const findItems = (searchTerm: string | undefined) => {
    if (!searchTerm) {
      return;
    }
    setSearchText(searchTerm);
  };

  return (
    <Screen style={utilityStyles.pb0}>
      <SearchHeader
        searchText={searchText}
        setSearchText={setSearchText}
        showAddToList={true}
        shoppingListId={listId}
        shoppingListName={listName}
        showBackButton
        placeHolder="+ Add List Items"
        tabBarHeight={tabBarHeight}
      />
      <DropShadow>
        <ListHeader count={state.itemCount} listName={title} listData={state.shoppingList} />
      </DropShadow>
      {state.isLoading ? (
        <View style={styles.loadingWrapper}>
          <ActivityIndicator color={appStyles.primaryButton.color} size={'large'} />
        </View>
      ) : (
        <ScrollView refreshControl={<RefreshControl refreshing={false} onRefresh={handleRefresh} />}>
          {!state.listData ? (
            <View style={styles.emptyContainer}>
              <Text style={appStyles.bodySmallBold} testID="addingItemsHeader">
                Start Adding Items to this List!
              </Text>
              <Text style={[appStyles.bodySmallLight, styles.emptySubText]} testID="instructions">
                Tap the search bar or barcode icon at the top of the screen to add items to this list.
              </Text>
              {pageConfig?.PastPurchases && (
                <ProductCarousel
                  title="Buy It Again"
                  titleStyle={utilityStyles.mt4}
                  widgetId={pageConfig.PastPurchases}
                  widgetType={'PastPurchases'}
                  addCartButtonStyle={styles.addCartButtonStyle}
                />
              )}
            </View>
          ) : (
            <>
              {state.itemCount === state.checkedItemCount ? (
                <View style={styles.everythingContainer}>
                  <Text style={appStyles.bodySmall}>You've Got Everything On Your List!</Text>
                  <Button
                    buttonStyle={styles.useAgainButton}
                    type={'secondary'}
                    title={'Use List Again'}
                    textStyle={styles.useAgainButtonText}
                    onPress={handleReuseList}
                  />
                  <TouchableOpacity onPress={handleClearAll}>
                    <Text style={appStyles.bodySmallLightLinkCenter}>Clear List</Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <View style={styles.uncheckedSection}>
                  {/* Un-Checked List */}
                  {!!state?.listData && Object.entries(state.listData)?.map(([cat, value]) => renderCategory('unchecked', cat, value))}
                </View>
              )}
              <View style={styles.checkedSection}>
                <View style={styles.checkedHeader}>
                  <Text style={appStyles.bodySmallLight}>
                    <Text style={[appStyles.bodySmallLeft]}>Checked</Text> {` (${state.checkedItemCount} item${state.checkedItemCount > 1 ? 's' : ''})`}
                  </Text>
                  <View style={styles.checkedRightHeader}>
                    <TouchableOpacity onPress={unCheckAllItems} style={{ marginRight: 24 }}>
                      <Text style={styles.uncheckLink}>Uncheck All</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => setShowChecked(!showChecked)}>
                      <Icon size={18} name={showChecked ? 'arrow-up' : 'arrow-down'} />
                    </TouchableOpacity>
                  </View>
                </View>
                {showChecked && (
                  <View>
                    {/* Checked List */}
                    {!!state?.listData && Object.entries(state.listData)?.map(([cat, value]) => renderCategory('checked', cat, value))}
                  </View>
                )}
              </View>
              {pageConfig?.PastPurchases && (
                <ProductCarousel
                  title="Buy It Again"
                  titleStyle={utilityStyles.mt4}
                  widgetId={pageConfig.PastPurchases}
                  widgetType={'PastPurchases'}
                  addCartButtonStyle={[styles.addCartButtonStyle, { width: screenWidth / 2.8 }]}
                />
              )}
              <View style={styles.estimateBar}>
                <Text style={[appStyles.smallBoldCenterRed, { color: colors.text }]}>Estimated Subtotal ${state.estimatedAmount.toFixed(2)}</Text>
              </View>
            </>
          )}
        </ScrollView>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  loadingWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemSeparator: {
    width: '92%',
    height: 1.5,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'center',
  },
  everythingContainer: {
    width: '100%',
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
  },
  useAgainButton: {
    marginVertical: 16,
    height: 30,
    minWidth: 140,
    width: 140,
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  useAgainButtonText: {
    fontSize: scale(16),
  },
  catContainer: {
    flexGrow: 1,
    width: '100%',
    paddingHorizontal: 0,
  },
  catHeader: {
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 16,
    backgroundColor: colors.tableLightRow,
    height: 35,
    justifyContent: 'center',
  },
  checkedRightHeader: {
    flexDirection: 'row',
  },
  uncheckLink: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(14),
    textDecorationLine: 'underline',
    color: colors.text,
    marginTop: 1,
  },
  uncheckedSection: {
    width: '100%',
  },
  checkedSection: {
    width: '100%',
  },
  checkedHeader: {
    backgroundColor: colors.darkCream,
    width: screenWidth,
    height: 45,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 48,
  },
  emptySubText: {
    width: '70%',
    textAlign: 'center',
    marginTop: 16,
    marginBottom: 64,
  },
  estimateBar: {
    height: 50,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  addCartButtonStyle: {
    backgroundColor: colors.darkCream,
  },
});

export default ViewListScreen;

import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { addEventListener, NetInfoState } from '@react-native-community/netinfo';
import { useUserProfileStore } from '@store';
import useAuthStore from '@store/authStore';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Barcode from './Barcode';
import Image from './image/Image';
import Text from './Text';

const UPCA_NUM_LENGTH = 11;

const OfflineNotice = (): JSX.Element => {
  const [isConnected, setIsConnected] = useState<boolean | null>(null);
  const { isAuthenticated } = useAuthStore();
  const { userProfile } = useUserProfileStore();

  const getBarcodeValue = () => {
    if (userProfile?.amsCardId) {
      return String(userProfile.amsCardId).padStart(UPCA_NUM_LENGTH, '0');
    }

    return '';
  };
  const isValid = userProfile?.amsCardId && getBarcodeValue().length === UPCA_NUM_LENGTH;

  useEffect(() => {
    const unsubscribe = addEventListener((state: NetInfoState) => {
      setIsConnected(state.isConnected);
    });
    return () => {
      unsubscribe();
    };
  }, [isConnected]);

  return !isConnected ? (
    <View style={[styles.container]}>
      <Text style={styles.messageHeader}>No Internet Connection</Text>
      {isAuthenticated() ? (
        <View>
          <View style={[styles.messageBodyView]}>
            <Text style={styles.messageBody}>
              You are currently offline, but you can still present this barcode to your cashier at checkout to earn points and redeem your already clipped
              offers.
            </Text>
          </View>
          <View style={styles.somethingExtraCard}>
            <View style={[styles.somethingExtraCardView]}>
              <Text style={styles.somethingExtraText}>My Something Extra Barcode</Text>
              {isValid && (
                <Barcode value={getBarcodeValue()} text={userProfile?.loyaltyId ?? ''} height={50} maxWidth={screenWidth} textStyle={styles.barcodeTextStyle} />
              )}
            </View>
          </View>
        </View>
      ) : (
        <View style={[styles.messageBodyView]}>
          <Text style={styles.messageBody}>You are currently offline. Please check your internet connection.</Text>
        </View>
      )}
      <Image resizeMode={'contain'} source={require('../assets/images/quad-logo.png')} style={[styles.image]} />
    </View>
  ) : (
    <></>
  );
};
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignContent: 'center',
    width: screenWidth,
    height: screenHeight,
  },
  somethingExtraCardView: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingVertical: scale(20),
    width: screenWidth * 0.86,
    marginTop: scale(30),
    borderWidth: 0.5,
    borderColor: colors.sectionBorder,
  },

  messageHeader: {
    ...appStyles.h2,
    width: screenWidth * 0.8,
  },
  messageBody: {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(18),
    lineHeight: lineHeight(20),
    textAlign: 'center',
  },
  messageBodyView: {
    ...appStyles.bodyLargeCenter,
    marginTop: scale(30),
    paddingHorizontal: scale(20),
  },
  image: {
    marginTop: scale(30),
    width: screenWidth * 0.9,
    height: scale(25),
    justifyContent: 'center',
    alignItems: 'center',
  },
  barcodeTextStyle: {
    margin: 20,
    color: colors.darkText,
  },
  somethingExtraText: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(18),
    color: colors.text,
    textAlign: 'center',
    paddingBottom: 5,
    lineHeight: lineHeight(28),
    width: screenWidth * 0.68,
  },
  somethingExtraCard: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default OfflineNotice;

import Text from '@components/Text';
import colors from '@config/colors';
import { AvailablityResult } from '@fieldera-raleys/client-commercetools';
import { ShopType } from '@fieldera-raleys/client-common';
import { AppStackRoutes, CustomizeStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useShopStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { screenHeight, screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useCustomModalContext } from '../contexts';
import Button from './Button';
import Icon from './Icon';
import AddToListOptions from './listManagement/AddToListOptions';

interface CustomizeButtonProps {
  productKey: string;
  prevPageData?: any;
  size?: 'regular' | 'small';
  title?: string;
  type?: 'primary' | 'secondary';
  buttonStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  productLeadTime?: string;
  onCustomize?: (cbValue: string) => void;
  testID?: string;
  availabilityResult?: AvailablityResult;
  showAddToList?: boolean;
}

const CustomizeButton: FC<CustomizeButtonProps> = ({
  size,
  title = 'Customize',
  type,
  buttonStyle,
  productKey,
  prevPageData,
  disabled,
  productLeadTime,
  textStyle,
  onCustomize,
  testID,
  availabilityResult,
  showAddToList = false,
}) => {
  const navigation = useNavigation();
  const { showModal, hideModal } = useCustomModalContext();

  const { selectedShopType } = useShopStore();
  const unAvailable = ['Unavailable', 'OutOfStock', 'Discontinued'].some((i) => i === availabilityResult?.availability);

  const handlePress = () => {
    if (productLeadTime) {
      showModal({
        title: 'Longer Lead Time Required',
        location: 'top',
        cancelButtonText: 'Back',
        okButtonOnPress: handleCustomize,
        okButtonText: 'Yes',
        children: <Text style={appStyles.bodyLargeCenter}>{productLeadTime}</Text>,
      });
    } else {
      handleCustomize();
    }
  };

  const customHeader = () => (
    <View style={styles.handle}>
      <TouchableOpacity
        testID="closeClick"
        style={styles.closeButton}
        onPress={() => {
          hideModal();
        }}>
        <Icon name={'x-close'} size={20} />
      </TouchableOpacity>
      <Text style={appStyles.h6} testID="AddToList">
        Add to List
      </Text>
      <Text style={[appStyles.bodyLargeCenter, utilityStyles.my3]} testID="instruction">
        Which list would you like to use?
      </Text>
    </View>
  );

  const openList = () => {
    showModal({
      style: { borderTopLeftRadius: 25, borderTopRightRadius: 25, backgroundColor: colors.cream },
      header: customHeader(),
      children: <AddToListOptions productSku={productKey} searchText={''} containerStyle={styles.alertBox} navigation={navigation} />,
      buttonContainerStyle: { display: 'none' },
      contentStyle: { width: screenWidth, ...utilityStyles.px3 },
    });
  };

  const handleCustomize = async () => {
    onCustomize && onCustomize(productKey);
    navigation.navigate(AppStackRoutes.Customize, {
      screen: CustomizeStackRoutes.Initial,
      params: {
        productKey: productKey,
        prevPageData: prevPageData,
        editCustomize: false,
      },
    });
  };

  const buttonTitle = () => {
    if (unAvailable) {
      if (availabilityResult?.availability === 'Discontinued') {
        // Show Discontinued for all In-Store, Pickup and Delivery type shopping
        return 'Discontinued';
      } else if (selectedShopType === ShopType.IN_STORE || showAddToList) {
        // For all other statuses, show In-Store for in store type shopping
        return 'Add to List';
      } else if (availabilityResult?.availability === 'OutOfStock') {
        // Show Out of Stock for Pickup and Devlivery type shopping
        return 'Out Of Stock';
      } else if (availabilityResult?.availability === 'Unavailable') {
        // Show Unavailable for Pickup and Devlivery type shopping
        return 'Unavailable';
      } else {
        return title;
      }
    } else if (selectedShopType === ShopType.IN_STORE || showAddToList) {
      return 'Add to List';
    } else {
      return title;
    }
  };

  return (
    <Button
      testID={testID ?? 'productAddToCartBtn'}
      disabled={disabled || unAvailable}
      size={size}
      title={buttonTitle()}
      type={type}
      onPress={selectedShopType === ShopType.IN_STORE ? openList : handlePress}
      buttonStyle={buttonStyle}
      textStyle={textStyle}
    />
  );
};

const styles = StyleSheet.create({
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    // height: 50,
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  alertBox: {
    bottom:
      screenHeight < 736 ? screenHeight * 0.17 : screenHeight > 812 ? screenHeight * 0.165 : screenHeight < 740 ? screenHeight * 0.165 : screenHeight * 0.175,
  },
});

export default CustomizeButton;

import Text from '@components/Text';
import colors from '@config/colors';
import { ShoppingList, ShoppingListLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { AccountStackRoutes, AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { screenWidth } from '@styles/constants';
import React, { FC, useCallback } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import Icon, { IconName } from '../Icon';
// import * as MediaLibrary from "expo-media-library";
import { getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import { getDisplayPriceBySellType } from '@fieldera-raleys/client-common/utils';
import { getProductVariantQuantityValue } from '@utils/orderHelpers';
import ListOptions from './ListOptions';

type ListCardProps = {
  iconName?: IconName | undefined;
  showValue?: boolean;
  list: ShoppingList;
};

const ListCard: FC<ListCardProps> = ({ iconName, showValue, list }) => {
  const navigation = useNavigation();
  const { showModal, hideModal } = useCustomModalContext();
  var value = 0;
  var numItems = 0;

  const displayPrice = useCallback((item: ShoppingListLineItem) => {
    let sellType = getProductAttributeValue('unitSellType', item.variant?.attributesRaw ?? []) ?? { key: 'byEach', label: 'By Each' };
    const unitAverageBuyWeight = getProductAttributeValue('unitAverageBuyWeight', item.variant?.attributesRaw ?? []);
    const priceCustomValues = item.variant?.price?.custom?.customFieldsRaw ?? [];
    let onSale =
      (item.variant?.price?.value.centAmount ?? 0) < (priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount ?? 0) ? true : false;
    let qty = 1;
    if (item) {
      qty = item.variant ? getProductVariantQuantityValue(item.variant, item.quantity) : item.quantity;
    }
    return onSale
      ? getDisplayPriceBySellType(sellType.key, item.variant?.price?.value, unitAverageBuyWeight, qty, false)
      : priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value && priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount
        ? getDisplayPriceBySellType(sellType.key, priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value, unitAverageBuyWeight, qty, false)
        : getDisplayPriceBySellType(sellType.key, item.variant?.price?.value, unitAverageBuyWeight, qty, false);
  }, []);

  list.lineItems.forEach((li) => {
    let sellType: { key: string; label: string } = getProductAttributeValue('unitSellType', li.variant?.attributesRaw ?? []) ?? {
      key: 'byEach',
      label: 'By Each',
    };
    if (sellType.key === 'byWeight') {
      numItems += 1;
    } else {
      numItems += li.quantity;
    }

    value += Number(displayPrice(li) ?? 0) ?? 0;
  });

  list.textLineItems.forEach((ti) => {
    numItems += ti.quantity;
  });

  const handleNav = () => {
    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Account,
      params: { screen: AccountStackRoutes.ViewList, params: { title: list.name ?? '', listName: list.name ?? '', listId: list.id } },
    });
  };

  const customHandle = () => (
    <View style={styles.handle}>
      <TouchableOpacity
        testID="closeIconClick"
        style={styles.closeButton}
        onPress={() => {
          hideModal();
        }}>
        <Icon name={'x-close'} size={20} testID="closeIcon" />
      </TouchableOpacity>
      <Text style={appStyles.h6} testID="listOptions">
        List Options
      </Text>
      <Text style={[appStyles.bodyLargeCenter, utilityStyles.my3]} testID="name">
        {list.name}
      </Text>
    </View>
  );

  const openList = () => {
    showModal({
      style: { borderTopLeftRadius: 25, borderTopRightRadius: 25, backgroundColor: colors.cream },
      header: customHandle(),
      children: (
        <>
          <ListOptions list={list} navigation={navigation} />
        </>
      ),
      buttonContainerStyle: { display: 'none' },
      contentStyle: { width: screenWidth, ...utilityStyles.px3, paddingBottom: 16, marginTop: -16 },
    });
  };

  return (
    <>
      <TouchableOpacity onPress={handleNav} style={styles.container} testID="listCardItemClick">
        <View style={styles.topCardContainer} testID="topCardContainer">
          <View style={styles.titleContainer} testID="titleContainer">
            {iconName && <Icon name={iconName} fill={colors.darkText} stroke={colors.darkText} size={15} style={styles.nameIcon} testID="icon" />}
            <Text numberOfLines={3} style={appStyles.bodySmallLeft} testID="listName">
              {list.name}
            </Text>
          </View>
          <TouchableOpacity style={styles.ellipsisContainer} onPress={openList} testID="ellipsisiconClick">
            <Icon name={'ellipsis-icon'} fill={colors.darkText} stroke={'none'} testID="ellipsisIcon" />
          </TouchableOpacity>
        </View>
        <View testID="numOfItemsview">
          <Text style={appStyles.bodySmallLeftRegular} testID="numOfItems">
            {numItems} items {showValue && `| Est. $${value.toFixed(2)}`}
          </Text>
        </View>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: screenWidth * 0.9,
    height: 100,
    marginVertical: 16,
    alignSelf: 'center',
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderColor: colors.greyBorder,
    borderRadius: 5,
    borderWidth: 1,
    justifyContent: 'space-between',
  },
  topCardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: -2,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ellipsisContainer: {
    width: 50,
    height: 30,
    alignItems: 'flex-end',
  },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  nameIcon: {
    marginRight: 4,
    marginTop: 2,
  },
});

export default ListCard;

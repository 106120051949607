import { createStackNavigator } from '@react-navigation/stack';
import { ContactCustomerScreen, HelpCenterScreen, HelpTopicsDetailScreen, HelpTopicsListScreen } from '@screens/account';
import React from 'react';
import { cardStyleInterpolator } from './helpers';
import { HelpCenterStackParamList, HelpCenterStackRoutes } from './routes';

const HelpCenterStack = createStackNavigator<HelpCenterStackParamList>();

const HelpCenterStackNavigator = () => {
  return (
    <HelpCenterStack.Navigator initialRouteName={HelpCenterStackRoutes.HelpCenterScreen} screenOptions={{ headerShown: false, cardOverlayEnabled: true }}>
      <HelpCenterStack.Screen name={HelpCenterStackRoutes.HelpCenterScreen} component={HelpCenterScreen} />
      <HelpCenterStack.Screen
        name={HelpCenterStackRoutes.ContactCustomerScreen}
        component={ContactCustomerScreen}
        options={{ cardStyleInterpolator: cardStyleInterpolator }}
      />
      <HelpCenterStack.Screen name={HelpCenterStackRoutes.HelpTopicsList} component={HelpTopicsListScreen} />
      <HelpCenterStack.Screen name={HelpCenterStackRoutes.HelpTopicsDetailScreen} component={HelpTopicsDetailScreen} />
    </HelpCenterStack.Navigator>
  );
};

export default HelpCenterStackNavigator;

import { Button, CloseButton, NavigationHeader, RadioButton, Screen } from '@components';
import Text from '@components/Text';
import colors from '@config/colors';
import { DateRange, Filter, FilterItem, FulfillmentType, PurchaseType } from '@fieldera-raleys/client-common/types';
import { OrderHistoryStackRoutes, OrderHistoryStackScreenProps } from '@navigation/routes';
import { usePurchaseHistoryFilterStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, scale, screenWidth } from '@styles/constants';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

type PurchaseFilter = Filter & { type: PurchaseType | 'Common' };
interface PurchaseFilters extends Record<string, PurchaseFilter> {
  time: PurchaseFilter;
  fulfillment: PurchaseFilter;
}

const defaultFilters: PurchaseFilters = Object.freeze({
  time: {
    id: 'time',
    title: 'Time',
    type: 'Common',
    children: [
      {
        id: 't1',
        title: 'Last 30 days',
        value: { startDate: dayjs().day(-30).toDate(), endDate: dayjs().toDate() },
        selected: false,
        default: false,
      },
      {
        id: 't2',
        title: 'Last 3 months',
        value: { startDate: dayjs().month(-3).toDate(), endDate: dayjs().toDate() },
        selected: true,
        default: true,
      },
      {
        id: 't3',
        title: 'Last 6 months',
        value: { startDate: dayjs().month(-6).toDate(), endDate: dayjs().toDate() },
        selected: false,
        default: false,
      },
      {
        id: 't4',
        title: 'Last 12 months',
        value: { startDate: dayjs().month(-12).toDate(), endDate: dayjs().toDate() },
        selected: false,
        default: false,
      },
      // {
      //   id: 't5',
      //   title: 'Last 24 months',
      //   value: { startDate: dayjs().month(-24).toDate(), endDate: dayjs().toDate() },
      //   selected: false,
      //   default: false,
      // },
    ],
  },
  fulfillment: {
    id: 'fulfillment',
    title: 'Order Type',
    type: 'Online',
    children: [
      {
        id: 'f1',
        title: 'All',
        selected: true,
        value: 'All',
        default: true,
      },
      {
        id: 'f2',
        title: 'Pickup',
        selected: false,
        value: 'Pickup',
        default: false,
      },
      {
        id: 'f3',
        title: 'Delivery',
        selected: false,
        value: 'Delivery',
        default: false,
      },
    ],
  },
});

const getFilters = (type: PurchaseType | 'Common') => {
  return Object.entries(defaultFilters).reduce<PurchaseFilters>((acc, current) => {
    const [key, value] = current;
    if ((value as PurchaseFilter).type === type) {
      acc = { ...acc, [key]: value as PurchaseFilter };
    }
    return acc;
  }, {} as PurchaseFilters);
};

const FilterListItem = ({
  filterKey,
  filter,
  onSelect,
}: {
  filterKey: string;
  filter: PurchaseFilter;
  onSelect: (filterKey: string, filter: PurchaseFilter, selectedItem: FilterItem) => void;
}) => {
  return (
    <View style={styles.subFilterContainer} key={filter.id} testID="filterItemView">
      <View style={styles.filterTitle}>
        <Text style={appStyles.bodySmallLeft} testID="title">
          {filter.title}
        </Text>
      </View>
      <View style={styles.list}>
        {filter.children.map((filterItem) => (
          <View style={styles.itemUnderline} key={filterItem.id} testID="childView">
            <RadioButton
              textStyle={appStyles.bodySmallLeftRegular}
              text={filterItem.title}
              value={filterItem.selected}
              id={filterItem.id}
              toggle={() => onSelect(filterKey, filter, filterItem)}
              key={filterItem.id}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

type PurchaseFilterScreenProps = OrderHistoryStackScreenProps<OrderHistoryStackRoutes.PurchaseFilter>;

const PurchaseFilterScreen = ({ navigation }: PurchaseFilterScreenProps) => {
  const purchaseHistoryFilterStore = usePurchaseHistoryFilterStore();
  const [displayFilters, setDisplayFilters] = useState<PurchaseFilters>({ ...defaultFilters });

  useEffect(() => {
    const initialize = () => {
      const commonFilters = getFilters('Common');
      setDisplayFilters({ ...commonFilters, ...getFilters(purchaseHistoryFilterStore.purchaseType) });
    };

    initialize();
  }, [purchaseHistoryFilterStore.purchaseType]);

  const toggleFilterValue = useCallback((filterName: string, selectedFilter: PurchaseFilter, selectedItem: FilterItem) => {
    if (selectedItem.selected) {
      return;
    }
    const updatedFilter: PurchaseFilter = {
      ...selectedFilter,
      children: selectedFilter.children.map((filterItem) => {
        if (filterItem.id === selectedItem.id) {
          filterItem.selected = !filterItem.selected;
        } else {
          filterItem.selected = false;
        }
        return filterItem;
      }),
    };

    setDisplayFilters((prev) => ({ ...prev, [filterName]: updatedFilter }));
  }, []);

  const resetFilters = useCallback(() => {
    for (var key in displayFilters) {
      displayFilters[key].children.forEach((c) => {
        c.selected = c.default;
      });
    }
    setDisplayFilters((prev) => ({ ...prev, ...displayFilters }));
  }, [displayFilters]);

  const applyFilters = () => {
    for (var filterName in displayFilters) {
      const currentFilter = displayFilters[filterName];
      const selected = currentFilter.children.find((x) => x.selected) ?? currentFilter.children.find((x) => x.default);
      if (selected) {
        if (filterName === 'time') {
          purchaseHistoryFilterStore.setDateRange(selected.value as DateRange, selected.default);
        } else if (filterName === 'fulfillment') {
          purchaseHistoryFilterStore.setFulfillmentType(selected.value as FulfillmentType);
        }
      }
    }
    navigation.goBack();
  };

  // const setLocation = (location: Address) => {
  //   console.log(location);
  //   setShowPicker(false);
  // };

  return (
    <Screen style={[styles.screenBackground, utilityStyles.pb0]}>
      <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(100)} style={{ ...styles.container }}>
        <NavigationHeader
          testID="FilterScreen"
          style={[styles.chekoutHeaderStyle]}
          subStyle={{ backgroundColor: colors.cream, justifyContent: 'center', flexDirection: 'row' }}
          titleStyle={[appStyles.fontMobileH6, { alignSelf: 'center' }]}
          title={'Filter'}
          icon={<CloseButton />}
        />
        <ScrollView contentContainerStyle={styles.listContainer} showsVerticalScrollIndicator={true}>
          {displayFilters && (
            <>
              {Object.entries(displayFilters)
                .sort((i1, i2) => {
                  return i1[0] < i2[0] ? 1 : -1;
                })
                .map((value) => {
                  return <FilterListItem filterKey={value[0]} filter={value[1]} onSelect={toggleFilterValue} key={`filter-${value[0]}`} />;
                })}
            </>
          )}
          {/* {purchaseHistoryFilterStore.purchaseType === 'In-store' ? (
            <>
              <Text style={[appStyles.h5, { marginTop: 24 }]}>Location</Text>
              <View style={styles.locationContainer}>
                <View>
                  <Text style={[appStyles.fontMobileSmallLeftRegular, { lineHeight: lineHeight(18) }]}>54321 Boardwalk Blvd</Text>
                  <Text style={[appStyles.fontMobileSmallLeftRegular, { lineHeight: lineHeight(18) }]}>Sacramento 00000</Text>
                </View>
                <Text onPress={() => setShowPicker(!showPicker)} style={[appStyles.h5, styles.editLink]}>
                  Edit
                </Text>
              </View>
            </>
          ) : null} */}
        </ScrollView>
        {/* <LocationPicker initialValue={purchaseHistoryFilterStore.location} onChange={setLocation} showToggle={showPicker} /> */}
        <View style={[styles.atBottom]}>
          <TouchableOpacity activeOpacity={0.7} onPress={() => resetFilters()} testID="resetButton">
            <Text style={[appStyles.fontShowMoreLink, styles.resetLink]} testID="resetText">
              Reset
            </Text>
          </TouchableOpacity>
          <Button
            testID="applyBtn"
            size={'small'}
            buttonStyle={[styles.button]}
            textStyle={[appStyles.primaryButtonSmallText]}
            onPress={() => {
              applyFilters();
            }}
            title={'Apply'}
          />
        </View>
      </Animated.View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  screenBackground: {
    backgroundColor: colors.cream,
  },
  container: {
    backgroundColor: colors.cream,
    alignItems: 'center',
    flex: 1,
  },
  listContainer: {
    width: containerWidth,
    paddingHorizontal: 10,
  },
  list: {
    paddingLeft: 15,
  },
  filterTitle: {
    width: screenWidth,
    marginTop: 20,
    marginBottom: 12,
    borderColor: colors.navBorder,
  },
  subFilterContainer: {
    marginVertical: 15,
  },
  chekoutHeaderStyle: {
    borderBottomColor: '#B4B4B4',
  },
  locationContainer: {
    width: '100%',
    height: 75,
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  editLink: {
    textDecorationLine: 'underline',
    fontSize: scale(18),
    marginTop: -3,
  },
  resetLink: {
    color: colors.text,
  },
  atBottom: {
    width: screenWidth,
    justifyContent: 'space-around',
    paddingLeft: 20,
    alignItems: 'center',
    borderTopWidth: 1,
    borderColor: colors.navBorder,
    flexDirection: 'row',
    backgroundColor: colors.white,
    paddingVertical: 5,
    bottom: 0,
  },
  button: {
    minWidth: '65%',
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconWrapper: {
    position: 'absolute',
    left: 0,
  },
  itemUnderline: {
    borderBottomWidth: 1,
    borderColor: colors.navBorder,
    marginVertical: 5,
  },
});

export default PurchaseFilterScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { productService } from '@services/commerceTools';
import { useShopStore } from '@store';
import { useCartStore } from '@store/cartStore';
import { appStyles, utilityStyles } from '@styles';
import { scale } from '@styles/constants';
import React, { FC, useEffect, useState } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import LinkButton from './LinkButton';

interface ICustomizeCartInfoProps {
  boxStyle?: StyleProp<ViewStyle>;
  textSize?: number;
  productKey: string;
  isButton?: boolean;
}

const CustomizeCartInfo: FC<ICustomizeCartInfoProps> = ({ boxStyle, textSize = 18, productKey = '', isButton = false }): JSX.Element => {
  const navigation = useNavigation();
  const { getLineItemQuantity, cart } = useCartStore();
  const [quantity, setQuantity] = useState<number | undefined>();
  const { selectedStore } = useShopStore();

  const handleClick = () => {
    navigation.dispatch(
      StackActions.push(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.CartLanding,
        params: {
          store: undefined,
          storeNumber: selectedStore?.number ?? undefined,
        },
      }),
    );
  };
  // const { data: product, status } = useQuery('product', () => getProductBySku(productKey));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCartQty = async () => {
    if (!productKey) {
      return undefined;
    }

    const productData = await productService.getProductBySku(productKey);
    var variants = [];

    if (!productData.masterData?.current?.variants.some((v) => v.sku === productKey)) {
      variants.push(productKey);
    } else if (productData.masterData?.current?.masterVariant.sku && !((productData.masterData?.current?.masterVariant.sku ?? '') === productKey)) {
      variants.push(productData.masterData?.current?.masterVariant.sku);
    }

    if (productData?.masterData?.current && productData?.masterData?.current?.variants?.length > 0) {
      for (let i = 0; i < productData.masterData.current?.variants.length; i++) {
        if (!variants.some((x) => x === productData.masterData.current?.variants[i].sku)) {
          variants.push(productData.masterData.current?.variants[i].sku);
        }
      }
    }
    if (variants.length > 1) {
      var qtyCount = 0;
      for (let i = 0; i < variants.length; i++) {
        var productQty = getLineItemQuantity({ sku: variants[i] ?? undefined });
        qtyCount += productQty;
      }
      return qtyCount;
    } else {
      return getLineItemQuantity({ sku: productKey });
    }
  };

  useEffect(() => {
    const setQty = async () => {
      const qty = await getCartQty();
      setQuantity(qty);
    };

    setQty();
  }, [cart?.lineItems, getCartQty, setQuantity]);

  return quantity ? (
    isButton ? (
      <View style={[styles.customizeInCartWrapper, boxStyle]}>
        <LinkButton onPress={handleClick} style={[appStyles.bodySmallLight, utilityStyles.my1, { textAlign: 'center' }]}>
          View Cart
        </LinkButton>
      </View>
    ) : (
      <View style={[styles.customizeInCartWrapper, boxStyle]}>
        <Text testID="quantityInCartText" style={[styles.customizeInCartText, { fontSize: scale(textSize) }]}>
          {quantity} in Cart{' '}
        </Text>
        <TouchableOpacity onPress={handleClick}>
          <Text testID="editText" style={[styles.customizeInCartLink, { fontSize: scale(textSize) }]}>
            {' '}
            Edit
          </Text>
        </TouchableOpacity>
      </View>
    )
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  customizeInCartWrapper: {
    width: '100%',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  customizeInCartText: {
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
    textAlign: 'center',
  },
  customizeInCartLink: {
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.darkText,
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
});

export default CustomizeCartInfo;

import { Button, CloseButton, DropShadow, NavigationHeader, Screen } from '@components';
import CheckBox from '@components/Checkbox';
import Text from '@components/Text';
import ItemizedOrder from '@components/cart/ItemizedOrder';
import colors from '@config/colors';
import { AddLineItem, Product } from '@fieldera-raleys/client-commercetools/schema';
import { OrderItem } from '@fieldera-raleys/client-common/types/order';
import { AppStackRoutes, CheckoutStackRoutes, OrderHistoryStackRoutes, OrderHistoryStackScreenProps } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useCartStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight } from '@styles/constants';
import { getProductQuantityIndex } from '@utils/orderHelpers';
import { AvailablityResult, getProductAvailablity, getProductsfromCommerceTools } from '@utils/productHelper';
import { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

type ReorderScreenProps = OrderHistoryStackScreenProps<OrderHistoryStackRoutes.Reorder>;

type AvailabilityType = {
  product: AvailablityResult[];
  unavailableCount: number;
};

type ProdDataType = {
  [key: string]: Product;
};

const ReorderScreen = ({ route }: ReorderScreenProps) => {
  const { orderDetails } = route.params;
  const [availability, setAvailability] = useState<AvailabilityType>({ product: [], unavailableCount: 0 });
  const [checkedItems, setCheckedItems] = useState<OrderItem[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(true);
  const { addStandardLineItems, addCustomizableLineItem } = useCartStore();
  const navigation = useNavigation();
  const [productData, setProductData] = useState<ProdDataType | undefined>();

  const validateAvailability = useCallback(async (products: ProdDataType, skus: string[], orderItems: OrderItem[]) => {
    const customizeSkus = orderItems
      .filter((oi) => oi.orderItemSubItems && oi.orderItemSubItems.length > 0)
      .map((oi) => oi.orderItemSubItems?.map((oisi) => oisi.extSubItemId) ?? '')
      .flat(1)
      .filter((x) => !!x);

    const customizationItems = customizeSkus ? await getProductsfromCommerceTools(customizeSkus) : [];

    const productAvailability = Object.entries(products).map(([sku, product]) => {
      return getProductAvailablity(product.masterData, sku);
    });

    let customizationAvailability: AvailablityResult[] = [];
    customizationItems.forEach((prod) => {
      customizationAvailability.push(getProductAvailablity(prod.masterData, prod.masterData.current?.masterVariant.sku ?? undefined));
    });

    orderItems
      .filter((oi) => oi.orderItemSubItems && oi.orderItemSubItems.length > 0)
      .forEach((oi) => {
        oi.orderItemSubItems?.forEach((oisi) => {
          let currAvailability = customizationAvailability.find((ca) => ca.productSku === oisi.extSubItemId);
          if (
            !currAvailability ||
            currAvailability.availability === 'Unavailable' ||
            currAvailability.availability === 'OutOfStock' ||
            currAvailability.availability === 'Discontinued'
          ) {
            let oiAvailability = productAvailability.find((pa) => pa.productSku === oi.productListing.customProperties!.ExtProductId);
            if (oiAvailability) {
              oiAvailability.availability = currAvailability?.availability ?? 'Unavailable';
              return;
            }
          }
        });
      });

    // If product doesn't exist anymore, consider it as unavailable
    skus.forEach((sku) => {
      if (!products[sku]) {
        productAvailability.push({
          availability: 'Unavailable',
          quantity: 0,
          productSku: sku,
        } as AvailablityResult);
      }
    });

    const filteredItems = orderItems.filter((oi) => {
      let oiSku = oi.productListing.customProperties!.ExtProductId;
      let productAvailabilityInfo = productAvailability.find((avail) => avail.productSku === oiSku);
      return productAvailabilityInfo?.availability === 'Available';
    });

    const unavailableCount = productAvailability.reduce((sum, curr) => {
      if (curr?.availability === 'Unavailable' || curr?.availability === 'OutOfStock' || curr?.availability === 'Discontinued') {
        sum++;
      }
      return sum;
    }, 0);

    setAvailability({ product: productAvailability, unavailableCount });
    setCheckedItems(filteredItems);
  }, []);

  useEffect(() => {
    const getProdSet = async (skus: string[]): Promise<Product[]> => {
      return await getProductsfromCommerceTools(skus);
    };
    if (orderDetails) {
      const skus: string[] = orderDetails.orderItems.reduce<string[]>((acc: string[], i: OrderItem) => {
        if (i.productListing.customProperties?.ExtProductId && acc.findIndex((o) => o === i.productListing.customProperties?.ExtProductId)) {
          acc.push(i.productListing.customProperties?.ExtProductId);
        }
        if (i.orderItemSubItems) {
          i.orderItemSubItems.forEach((osi) => {
            if (osi.extSubItemId && acc.findIndex((o) => o === osi.extSubItemId)) {
              acc.push(osi.extSubItemId);
            }
          });
        }
        return acc;
      }, []);
      getProdSet(skus)
        .then((pd) => {
          const pData: ProdDataType = pd.reduce((acc, p: Product) => {
            acc[p.masterData?.current?.masterVariant.sku ?? ''] = p;
            p.masterData?.current?.variants.forEach((vi) => {
              acc[vi?.sku ?? ''] = p;
            });

            return acc;
          }, {} as ProdDataType);
          validateAvailability(pData, skus, orderDetails.orderItems);
          setProductData(pData);
        })
        .catch(() => {
          setProductData({});
        });
    }
  }, [orderDetails, validateAvailability]);

  const handleSubmit = async () => {
    setIsButtonLoading(true);

    if (!checkedItems.length) {
      setIsButtonLoading(false);
      return;
    }

    // Add Items to the cart
    let standardItems: AddLineItem[] = checkedItems
      .filter((ci) => ci.orderItemSubItems?.length === 0)
      .filter((ci) => !ci.excludeForReorder)
      .map((ci) => ({
        sku: ci.productListing!.customProperties!.ExtProductId,
        quantity: productData?.[ci.productListing!.customProperties!.ExtProductId]?.masterData?.current
          ? getProductQuantityIndex(productData[ci.productListing!.customProperties!.ExtProductId].masterData.current!, ci.qty, ci.estimatedTotalWeight)
          : ci.qty,
        itemNote: ci.orderItemNote,
      }));

    if (standardItems.length) {
      await addStandardLineItems(standardItems);
    }

    if (checkedItems.some((ci) => !!ci.orderItemSubItems?.length)) {
      checkedItems
        .filter((ci) => ci.orderItemSubItems?.length !== 0)
        .filter((ci) => !ci.excludeForReorder)
        .forEach(async (ci) => {
          let customizableItems: AddLineItem[] = [];
          customizableItems.push({
            sku: ci.productListing!.customProperties!.ExtProductId,
            quantity: ci.qty,
            itemNote: ci.orderItemNote,
            product: productData?.[ci.productListing!.customProperties!.ExtProductId ?? '']
              ? productData[ci.productListing!.customProperties!.ExtProductId]
              : undefined,
          });
          ci.orderItemSubItems?.forEach((oisi) => {
            let subItemCustomStep = oisi.productListing.value?.split('-')[0].trim().split(':').join('|');
            customizableItems.push({
              sku: oisi.extProductId,
              quantity: oisi.qty,
              itemNote: oisi.orderItemSubItemNote,
              parentLineItemId: ci.productListing!.customProperties!.ExtProductId,
              customStepSort: subItemCustomStep,
              product: productData?.[oisi.extProductId ?? ''] ? productData[oisi.extProductId] : undefined,
            });
          });

          await addCustomizableLineItem(customizableItems);
        });
    }

    // Navigate to Cart
    navigation.dispatch(StackActions.pop(1));
    navigation.navigate(AppStackRoutes.CartAndCheckout, {
      screen: CheckoutStackRoutes.Cart,
    });

    setIsButtonLoading(false);
  };

  const handleToggle = useCallback(
    (oi: OrderItem) => {
      if (checkedItems.some((ci) => ci.extOrderItemId === oi.extOrderItemId)) {
        let filteredItems = checkedItems.filter((ci) => ci.extOrderItemId !== oi.extOrderItemId);
        if (orderDetails.orderItems.length - availability.unavailableCount !== filteredItems.length) {
          setCheckAll(false);
        }
        setCheckedItems && setCheckedItems(filteredItems);
        return;
      }

      if (orderDetails.orderItems.length - availability.unavailableCount === [...checkedItems, oi].length) {
        setCheckAll(true);
      }
      setCheckedItems && setCheckedItems([...checkedItems, oi]);
    },
    [availability.unavailableCount, checkedItems, orderDetails.orderItems.length],
  );

  const handleToggleAll = useCallback(() => {
    // Toggle all items when not all items are checked
    if (orderDetails.orderItems.length - availability.unavailableCount !== checkedItems.length) {
      let uncheckedItems = orderDetails.orderItems.filter((oi) => {
        return (
          !checkedItems.some((ci) => ci.orderItemId === oi.orderItemId) &&
          // Exclude unavailable items
          availability.product.find((p) => p.productSku === oi.productListing.customProperties?.ExtProductId)?.availability === 'Available'
        );
      });

      setCheckedItems((prev) => [...prev, ...uncheckedItems]);
      setCheckAll(true);
    } else {
      // Uncheck all the items
      setCheckedItems([]);
      setCheckAll(false);
    }
  }, [availability.product, availability.unavailableCount, checkedItems, orderDetails.orderItems]);

  return (
    <Screen style={styles.reorderModalStyle}>
      <NavigationHeader
        style={[styles.reorderHeaderStyle]}
        subStyle={styles.navigatorSubStyle}
        titleStyle={[appStyles.fontMobileH6, styles.titleStyle]}
        icon={<CloseButton />}
        title={'Reorder Items'}
      />
      <DropShadow>
        <View style={[styles.flexRow, styles.selectAllContainer]}>
          <View style={[styles.flexRow, styles.checkboxView]}>
            <CheckBox value={checkAll} onValueChange={() => handleToggleAll()} />
            <Text style={[appStyles.fontMobileBodySmallLeft]}>Select All</Text>
          </View>
          <Text style={[appStyles.fontMobileBodyLeftRegular, styles.selectAllText]}>You'll be able to edit any item after adding it to the cart</Text>
        </View>
      </DropShadow>
      <ScrollView>
        <ItemizedOrder order={orderDetails} reorder={true} checkedItems={checkedItems} setCheckedItems={handleToggle} availability={availability} />
      </ScrollView>
      <View style={styles.buttonContainer}>
        <Text>{checkedItems.length} item(s) selected</Text>
        <Button isButtonLoading={isButtonLoading} title="Add to Cart" type="primary" onPress={() => handleSubmit()} />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  reorderModalStyle: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  reorderHeaderStyle: {
    borderBottomColor: '#B4B4B4',
  },
  navigatorSubStyle: {
    backgroundColor: colors.cream,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  titleStyle: {
    alignSelf: 'center',
    marginLeft: 20,
  },
  selectAllContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    backgroundColor: colors.sectionPad,
  },
  checkboxView: {
    marginLeft: 15,
    alignItems: 'center',
  },
  selectAllText: {
    flex: 0.9,
    lineHeight: lineHeight(16),
  },
  buttonContainer: {
    bottom: 0,
    borderTopWidth: 1,
    borderColor: '#707070',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
});

export default ReorderScreen;

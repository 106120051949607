import { Button, CloseButton, ListItem, NavigationHeader, Screen } from '@components';
import Picker from '@components/Picker';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AddLineItem, Product, ShoppingList, ShoppingListLineItem, TextLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { getCustomFieldValue } from '@fieldera-raleys/client-common/utils';
import { useAnalytics } from '@hooks';
import { AccountStackRoutes, AccountStackScreenProps, AppStackRoutes, CheckoutStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { StackActions, useNavigation } from '@react-navigation/native';
import { shoppingListService } from '@services/commerceTools/me';
import { useCartStore, useShoppingListsStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { scale, screenHeight, screenWidth, verticalScale } from '@styles/constants';
import logger from '@utils/logger';
import { getProductAvailablity, getProductsfromCommerceTools, getRootCategory } from '@utils/productHelper';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, Platform, ScrollView, StyleSheet, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useCustomModalContext } from '../../../contexts';

type ListToCartScreenProps = AccountStackScreenProps<AccountStackRoutes.ListToCart>;

const ListToCartScreen = ({ route }: ListToCartScreenProps) => {
  const { trackAddToCartEvent } = useAnalytics();
  const { action, listName, toCart = false, listId } = route.params;
  const { shoppingLists, addItemsToList, removeItemsFromList } = useShoppingListsStore();
  const { addStandardLineItems } = useCartStore();
  const { hideModal, showModal, showAlertPopup } = useCustomModalContext();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const [data, setData] = useState<ShoppingList>();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [unchecked, setUnchecked] = useState<(ShoppingListLineItem | TextLineItem)[]>([]);
  const [checked, setChecked] = useState<(ShoppingListLineItem | TextLineItem)[]>([]);
  const [productData, setProductData] = useState<Product[] | undefined>();
  const [selectedList, setSelectedList] = useState(shoppingLists ? shoppingLists?.filter((l) => l.name !== listName)[0]?.id : '');
  const [prodCategories, setProdCategories] = useState<{ prodSku: string; category: string }[]>([]);
  const [prodAvailabilities, setProdAvailabilities] = useState<{ prodSku: string; availability: string }[]>([]);
  const tabBarHeight = useBottomTabBarHeight();

  var pickerOptions = shoppingLists
    .filter((li) => li.id !== listId)
    .map((li) => {
      return { id: li.id, title: li.name as string, value: li.name as string, text: li.name as string };
    });

  const getListData = useCallback(async () => {
    setLoading(true);
    var productList: any[];
    const listData: ShoppingList = await shoppingListService.getShoppingList({ listName: listName });
    if (listData) {
      productList = await getProductsfromCommerceTools(listData.lineItems.map((x) => x.variant?.sku as string));
      setProductData(productList);
    }
    var uncheckedItems: (ShoppingListLineItem | TextLineItem)[] = listData.lineItems;
    uncheckedItems = uncheckedItems.concat(listData?.textLineItems);
    var checkedItems: (ShoppingListLineItem | TextLineItem)[] = [];
    var prodCats: { prodSku: string; category: string }[] = [];
    var prodAvails: { prodSku: string; availability: string }[] = [];
    var cats: string[] = [];
    uncheckedItems.forEach((s: any) => {
      // let category = s.variant?.attributesRaw.find((att: CustomFieldInput) => att.name === 'fulfillmentDepartment')?.value.label ?? '';
      let prod = 'variant' in s ? productList?.find((p) => p.key === s.variant?.sku) : undefined;
      let category = prod ? getRootCategory(prod?.masterData?.current?.categories)?.name : '';
      let avail = prod ? getProductAvailablity(prod?.masterData) : null;
      if (category) {
        prodCats.push({ prodSku: prod?.key ?? '', category: category });
      }
      if (avail) {
        prodAvails.push({ prodSku: prod?.key, availability: avail.availability });
      }
      // let category = s.variant?.attributesRaw.find((att: CustomFieldInput) => att.name === 'fulfillmentDepartment')?.value.label ?? '';
      if (!cats.includes(category as string)) {
        cats.push(category as string);
      }
    });
    checkedItems.forEach((s: any) => {
      // let category = s.variant?.attributesRaw.find((att: CustomFieldInput) => att.name === 'fulfillmentDepartment')?.value.label ?? '';
      let prod = 'variant' in s ? productList?.find((p) => p.key === s.variant?.sku) : undefined;
      let category = prod ? getRootCategory(prod?.masterData?.current?.categories)?.name : '';
      if (category) {
        prodCats.push({ prodSku: prod?.key ?? '', category: category });
      }

      // let category = s.variant?.attributesRaw.find((att: CustomFieldInput) => att.name === 'fulfillmentDepartment')?.value.label ?? '';
      if (!cats.includes(category as string)) {
        cats.push(category as string);
      }
    });
    setProdAvailabilities(prodAvails);
    setProdCategories(prodCats);
    setCategories(cats);
    // setCheckedCategories(cats);
    setData(listData);
    setUnchecked(uncheckedItems);
    setChecked(checkedItems);
    setLoading(false);
  }, [listName]);

  const onPickerSelect = (option: any) => {
    setSelectedList(option.id);
    logger.log(option);
  };

  useEffect(() => {
    getListData();
  }, [getListData, listName]);

  const navToCustomizeNeeded = () => {
    const customItemKeys = checked
      .filter((ci) => {
        if ('productType' in ci) {
          if (ci.productType.name === 'configurable') {
            return ci;
          }
        }
      })
      .map((chi) => chi.id);
    const standardItemSkus = checked
      .filter((ci) => {
        if ('productType' in ci) {
          if (ci.productType.name !== 'configurable') {
            return ci;
          }
        }
      })
      .map((chi) => {
        if ('variant' in chi) {
          return chi.variant?.sku;
        }
      })
      .filter((fchi) => fchi !== undefined);
    const lineItemIds: string[] = checked
      .filter((ci) => ci.name !== 'Select All' && 'variant' in ci)
      .map((fci) => fci.id)
      .filter((mapped) => mapped !== undefined);
    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Account,
      params: {
        screen: AccountStackRoutes.CustomizeNeeded,
        params: {
          listId: data?.id ?? '',
          customItemIds: customItemKeys ?? [],
          listName: listName,
          standardItemSkus: standardItemSkus,
          action: action,
          listVersion: data?.version,
          lineItemIds: lineItemIds,
        },
      },
    });
    hideModal();
  };

  const viewList = useCallback(
    (list: ShoppingList | undefined) => {
      if (!list) {
        return;
      }
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: { screen: AccountStackRoutes.ViewList, params: { title: list?.name ?? '', listName: list?.name ?? '', listId: list?.id ?? '' } },
      });
    },
    [navigation],
  );

  const handleSubmit = async () => {
    if (!checked) {
      return;
    }
    const list = shoppingLists?.find((li) => li.id === selectedList);
    const currList = shoppingLists?.find((li) => li.id === listId);
    var skus: string[] = checked
      .filter((ci) => ci.name !== 'Select All')
      .map((fci) => {
        if ('variant' in fci) {
          return fci.variant?.sku;
        }
      })
      .filter((mapped) => mapped !== undefined) as string[];

    const skusQuantity: [{ sku: string; quantity: number; itemNote: string }] = checked
      .filter((ci) => ci.name !== 'Select All')
      .map((fci) => {
        if ('variant' in fci) {
          return { sku: fci.variant?.sku, quantity: fci.quantity, itemNote: getCustomFieldValue('itemNote', fci.custom?.customFieldsRaw ?? []) };
        }
      })
      .filter((mapped) => mapped !== undefined) as [{ sku: string; quantity: number; itemNote: string }];

    var lineItemIds: string[] = checked
      .filter((ci) => ci.name !== 'Select All')
      .map((fci) => {
        if ('variant' in fci) {
          return fci.id;
        }
      })
      .filter((mapped) => mapped !== undefined) as string[];
    var titles: string[] = checked
      .filter((ci) => ci.name !== 'Select All')
      .map((fci) => {
        if (!('variant' in fci)) {
          return fci.name;
        }
      })
      .filter((mapped) => mapped !== undefined) as string[];
    var textLineItemIds: string[] = checked
      .filter((ci) => ci.name !== 'Select All')
      .map((fci) => {
        if (!('variant' in fci)) {
          return fci.id;
        }
      })
      .filter((mapped) => mapped !== undefined) as string[];
    if (
      toCart &&
      checked.some((ci) => {
        if ('productType' in ci) {
          if (ci.productType.name === 'configurable') {
            return ci;
          }
        }
      })
    ) {
      showModal({
        title: 'Customizable Item',
        children: (
          <Text style={appStyles.bodyLargeCenter} testID="customizableItem">
            Customizing your item now saves your preferences to the list.
          </Text>
        ),
        // cancelButtonOnPress: () => logger.log('back'),
        cancelButtonText: 'No, Later',
        okButtonOnPress: navToCustomizeNeeded,
        okButtonText: 'Yes, Now',
        location: 'top',
        showCloseIcon: false,
        style: {},
        header: undefined,
        buttonContainerStyle: {},
        // contentContainer: {},
      });
    } else if (action === 'Copy to List') {
      if (!selectedList || !list) {
        return;
      }
      await addItemsToList(
        list.id,
        skusQuantity.map((s) => {
          return {
            sku: s.sku,
            quantity: s.quantity,
            itemNote: s.itemNote,
          };
        }),
        titles,
      );
      if (skus.length || titles.length) {
        showAlertPopup({
          message: `Item(s) Copied to "${list?.name}"`,
          containerStyle: { bottom: tabBarHeight },
          linkText: 'View',
          linkAction: () => viewList(list),
        });
      }
      navigation.goBack();
    } else if (action === 'Move to List') {
      if (!selectedList || !list) {
        return;
      }
      await addItemsToList(
        list.id,
        skusQuantity.map((s) => {
          return {
            sku: s.sku,
            quantity: s.quantity,
            itemNote: s.itemNote,
          };
        }),
        titles,
      );
      if (!currList) {
        return;
      }
      await removeItemsFromList(currList.id, lineItemIds, textLineItemIds);
      if (skus.length || titles.length) {
        showAlertPopup({
          message: `Item(s) Moved to "${list?.name}"`,
          containerStyle: { bottom: tabBarHeight },
          linkText: 'View',
          linkAction: () => viewList(list),
        });
      }
      navigation.goBack();
    } else if (action === 'Copy to Cart') {
      let addItems: AddLineItem[] = checked
        .filter((li) => 'variant' in li && li.productType.name !== 'configurable')
        ?.map((si) => {
          return {
            sku: 'variant' in si && si.variant?.sku,
            quantity: si.quantity,
            itemNote: getCustomFieldValue('itemNote', si.custom?.customFieldsRaw ?? []),
          };
        })
        .filter((ai) => ai !== undefined) as AddLineItem[];
      checked.forEach((ci) => {
        if ('variant' in ci && ci.productType.name !== 'configurable') {
          trackAddToCartEvent({ sku: ci.variant?.sku ?? '', prod_name: ci.name ?? '', prod_id: ci.variant?.sku ?? '' });
        }
      });
      addStandardLineItems(addItems);

      if (addItems.length) {
        showAlertPopup({
          message: 'Item(s) Copied to Cart',
          containerStyle: { bottom: tabBarHeight },
          linkText: 'View',
          linkAction: navToCart,
        });
      }
      navigation.goBack();
    } else if (action === 'Move to Cart') {
      if (currList) {
        let addItems: AddLineItem[] = checked
          .filter((li) => 'variant' in li && li.productType.name !== 'configurable')
          ?.map((si) => {
            return {
              sku: 'variant' in si && si.variant?.sku,
              quantity: si.quantity,
              itemNote: getCustomFieldValue('itemNote', si.custom?.customFieldsRaw ?? []),
            };
          })
          .filter((ai) => ai !== undefined) as AddLineItem[];
        checked.forEach((ci) => {
          if ('variant' in ci && ci.productType.name !== 'configurable') {
            trackAddToCartEvent({ sku: ci.variant?.sku ?? '', prod_name: ci.name ?? '', prod_id: ci.variant?.sku ?? '' });
          }
        });
        addStandardLineItems(addItems);
        await removeItemsFromList(currList.id, lineItemIds, textLineItemIds);

        if (addItems.length) {
          showAlertPopup({
            message: 'Item(s) Moved to Cart',
            containerStyle: { bottom: tabBarHeight },
            linkText: 'View',
            linkAction: navToCart,
          });
        }
        navigation.goBack();
      }
    }
    // logger.log(action, selectedList);
  };

  const navToCart = () => {
    navigation.dispatch(
      StackActions.push(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.CartLanding,
        params: {
          store: undefined,
        },
      }),
    );
  };

  const toggleChecked = (item: ShoppingListLineItem | TextLineItem) => {
    var currChecked = checked;
    var currUnchecked = unchecked;
    var tempChecked;
    var tempUnchecked;

    if (item.name === 'Select All') {
      if (checked.some((ci) => ci.name === 'Select All')) {
        var newUnchecked: any = data?.lineItems ?? [];
        newUnchecked = newUnchecked.concat(data?.textLineItems);
        setUnchecked(newUnchecked);
        setChecked([]);
      } else {
        var newChecked: any = data?.lineItems ?? [];
        if (!toCart) {
          newChecked = newChecked.concat(data?.textLineItems);
        } else {
          newChecked = newChecked.filter((nci: ShoppingListLineItem) =>
            prodAvailabilities.some((pa) => nci.variant?.sku === pa.prodSku && (pa.availability === 'Available' || pa.availability === 'LowStock')),
          );
        }
        newChecked.push(item);
        setChecked(newChecked);
        toCart ? setUnchecked(unchecked.concat(data?.textLineItems ?? [])) : setUnchecked([]);
      }
      return;
    }

    if (!checked.some((i) => i.name === item.name)) {
      currChecked.push(item);
      tempUnchecked = currUnchecked.filter((uc) => uc.id !== item.id && uc.name !== 'Select All');
      setUnchecked(tempUnchecked);
      setChecked(currChecked);
      // logger.log('checked', JSON.stringify(currChecked, null, 2));
    } else {
      currUnchecked.push(item);
      tempChecked = currChecked.filter((uc) => uc.id !== item.id && uc.name !== 'Select All');
      // logger.log('checked', JSON.stringify(tempChecked, null, 2));
      // logger.log('unchecked', JSON.stringify(currUnchecked, null, 2));
      setUnchecked(currUnchecked);
      setChecked(tempChecked);
    }
  };

  return (
    <Screen style={[styles.screenBackground, utilityStyles.pb0]}>
      <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(100)} style={{ ...styles.container }}>
        <NavigationHeader
          testID={`${action}`}
          style={[styles.chekoutHeaderStyle]}
          subStyle={{ backgroundColor: colors.cream, justifyContent: 'center', flexDirection: 'row' }}
          titleStyle={[appStyles.fontMobileH6, { alignSelf: 'center' }]}
          title={`${action}`}
          icon={<CloseButton />}
        />
        {loading ? (
          <View style={styles.loadingWrapper}>
            <ActivityIndicator color={appStyles.primaryButton.color} size={'large'} />
          </View>
        ) : (
          <>
            <View style={styles.instructionsText}>
              <Text style={appStyles.bodyLargeCenter} testID="selectItem">
                Select items to {action.includes('Move') ? 'move' : 'copy'}
              </Text>
            </View>
            <ScrollView contentContainerStyle={styles.listContainer} showsVerticalScrollIndicator={true}>
              <ListItem
                showUnderline={false}
                value={checked.some((c) => c.name === 'Select All')}
                item={{ name: 'Select All' }}
                toggleChecked={() =>
                  toggleChecked({
                    name: 'Select All',
                    id: '',
                    quantity: 1,
                    addedAt: undefined,
                    nameAllLocales: [],
                  })
                }
              />
              {categories.map((cat) => {
                var numItemsInCat = prodCategories.reduce((prev, curr) => {
                  if (curr.category === cat) {
                    prev += 1;
                  }
                  return prev;
                }, 0);
                return (
                  <View style={styles.catContainer}>
                    {cat && (
                      <View style={styles.catHeader}>
                        <Text style={appStyles.bodySmallLeftRegular} testID="category">
                          {cat}
                        </Text>
                      </View>
                    )}
                    {data?.lineItems
                      ?.filter((uci) => {
                        let product = 'variant' in uci && productData?.find((p) => p.key === uci.variant?.sku);
                        let productCategory = 'variant' in uci ? prodCategories.find((pc) => pc.prodSku === uci?.variant?.sku ?? '')?.category : '';
                        if (productCategory === cat && product) {
                          return uci;
                        }
                      })
                      ?.map((prod, idx) => {
                        return (
                          <>
                            <ListItem
                              showUnderline={false}
                              productInfo={productData?.find((pr: Product) => pr.key === prod.variant?.sku)}
                              value={checked.some((c) => c.id === prod.id)}
                              item={prod}
                              toCart={toCart}
                              toggleChecked={() => toggleChecked(prod)}
                            />
                            {idx + 1 !== numItemsInCat && <View style={styles.itemSeparator} />}
                          </>
                        );
                      })}
                  </View>
                );
              })}
              <View style={styles.catContainer}>
                {data?.textLineItems.length ? (
                  <View style={styles.catHeader}>
                    <Text testID="miscItems" style={appStyles.bodySmallLeftRegular}>
                      Misc Items
                    </Text>
                  </View>
                ) : null}
                {data?.textLineItems.map((prod) => {
                  return (
                    <ListItem
                      showUnderline={false}
                      toCart={toCart}
                      value={checked.some((c) => c.id === prod.id) && !toCart}
                      item={prod}
                      toggleChecked={() => toggleChecked(prod)}
                      isTextLineItem
                    />
                  );
                })}
              </View>
            </ScrollView>
            <View style={[styles.atBottom, { paddingBottom: insets.bottom }]}>
              {!toCart && (
                <>
                  <Text style={styles.pickerTitle} testID="destinationList">
                    Destination List
                  </Text>
                  <Picker
                    testID="destinationListPicker"
                    // initialValue={pickerOptions ? pickerOptions[0] : undefined}
                    onValueChange={(selection) => onPickerSelect(selection)}
                    textStyle={[styles.pickerTitle, { marginTop: 0, marginBottom: 0, marginLeft: 0 }]}
                    style={styles.picker}
                    placeholder={`${pickerOptions ? pickerOptions[0]?.value : ''}`}
                    headerTitle="Destination List"
                    options={pickerOptions ?? []}
                  />
                </>
              )}
              <View style={styles.actionInfo}>
                <Text style={[appStyles.bodySmallLight, styles.resetLink]} testID="selectedItemName">
                  <Text testID="itemName" style={{ fontFamily: FontFamily.LarsseitBold }}>
                    {checked.filter((c) => c.name !== 'Select All').length}
                  </Text>{' '}
                  item(s) selected
                </Text>
                <Button
                  testID={`${action}`}
                  size={'small'}
                  buttonStyle={[styles.button]}
                  textStyle={[appStyles.primaryButtonSmallText]}
                  onPress={() => {
                    handleSubmit();
                  }}
                  title={`${action}`}
                />
              </View>
            </View>
          </>
        )}
      </Animated.View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  screenBackground: {
    backgroundColor: colors.cream,
  },
  pickerTitle: {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    fontSize: scale(18),
    textAlign: 'left',
    marginBottom: 12,
    marginTop: 16,
    marginLeft: 16,
  },
  itemSeparator: {
    width: screenWidth * 0.95,
    height: 1.5,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'center',
  },
  picker: {
    borderWidth: 1.5,
    alignSelf: 'center',
    borderColor: colors.sectionBorder,
    borderRadius: 15,
    height: 45,
  },
  pickerText: {},
  instructionsText: {
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.sectionBorder,
    borderBottomWidth: 1.5,
    width: screenWidth * 0.9,
  },
  container: {
    backgroundColor: colors.cream,
    alignItems: 'center',
    flex: 1,
  },
  listContainer: {
    width: screenWidth,
    paddingHorizontal: 16,
    paddingBottom: 40,
  },
  list: {
    paddingLeft: 15,
  },
  catContainer: {
    flexGrow: 1,
    width: '100%',
    paddingHorizontal: 16,
  },
  catHeader: {
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 16,
    backgroundColor: colors.tableLightRow,
    height: 35,
    justifyContent: 'center',
  },
  filterTitle: {
    width: screenWidth,
    marginTop: 20,
    marginBottom: 12,
    borderColor: colors.navBorder,
  },
  subFilterContainer: {
    marginVertical: 15,
  },
  chekoutHeaderStyle: {
    borderBottomColor: '#B4B4B4',
  },
  locationContainer: {
    width: '100%',
    height: 75,
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  editLink: {
    textDecorationLine: 'underline',
    fontSize: scale(18),
    marginTop: -3,
  },
  resetLink: {
    // color: '#4B5358',
    // paddingTop: 2,
  },
  atBottom: {
    width: screenWidth,
    // justifyContent: 'space-around',
    paddingHorizontal: 16,
    // alignItems: 'center',
    borderTopWidth: 1,
    borderColor: colors.navBorder,
    // flexDirection: 'row',
    backgroundColor: colors.white,
    paddingBottom: 5,
    bottom: screenHeight > 844 ? -screenHeight * 0.035 : screenHeight > 811 ? screenHeight * 0.006 : Platform.OS === 'ios' ? 0 : screenHeight * 0.03,
  },
  actionInfo: {
    flexDirection: 'row',
    width: screenWidth,
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingBottom: Platform.select({ android: screenHeight > 844 ? verticalScale(25) : 0 }),
  },
  button: {
    minWidth: '45%',
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconWrapper: {
    position: 'absolute',
    left: 0,
  },
  itemUnderline: {
    borderBottomWidth: 1,
    borderColor: colors.navBorder,
    marginVertical: 5,
  },
  loadingWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ListToCartScreen;

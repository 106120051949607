import i18next, { Callback, LanguageDetectorModule } from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { NativeModules, Platform } from 'react-native';
import Config from 'react-native-config';
import logger from '../../utils/logger';
import * as i18nConfig from './config';

const defaultLang = 'en';

const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    const supportedLanguages = ['en', 'es'];
    const locale =
      Platform.OS === 'ios'
        ? NativeModules.SettingsManager?.settings?.AppleLocale || NativeModules.SettingsManager?.settings?.AppleLanguages[0] || ''
        : NativeModules.I18nManager?.localeIdentifier || '';

    const [lowerCaseLocale] = locale.split('_');

    if (supportedLanguages.includes(lowerCaseLocale)) {
      return lowerCaseLocale;
    }
    logger.warn(`locale ${lowerCaseLocale} from ${locale} is not supported, defaulting to ${defaultLang}`);
    return defaultLang;
  },
  init: () => {},
  cacheUserLanguage: () => {},
};
const localResources = {
  en: require('./locales/en.json'),
};

const initI18next = (cb: Callback | undefined) => {
  const i18n = i18next.createInstance();
  i18n
    .use(ChainedBackend)
    .use(languageDetector)
    .use(initReactI18next)
    .init(
      {
        // debug: __DEV__ ? true : false,
        compatibilityJSON: 'v3',
        fallbackLng: 'en',
        preload: ['en'],
        ns: i18nConfig.namespaces,
        defaultNS: i18nConfig.defaultNamespace,
        backend: {
          backends: [HttpBackend, resourcesToBackend(localResources)],
          backendOptions: [
            {
              loadPath: (lngs: string[], namespaces: string[]) => {
                return `${Config.BRXM_ASSETS_URL}/locales/${lngs.join('+')}/${namespaces.join('+').toLowerCase()}.json`;
              },
              requestOptions: {
                cache: 'no-cache',
              },
            },
          ],
        },
      },
      cb,
    );
};

export default initI18next;

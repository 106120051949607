const colors = {
  primary: '#4B5358',
  secondary: '#B43B3C',
  black: '#000',
  white: '#fff',
  medium: '#ccc6c6',
  light: '#f8f4f4',
  dark: '#5f6569',
  danger: '#ff5252',
  darkText: '#4B5358',
  cream: '#FFFCF9',
  promoCream: '#F6EFEA',
  darkCream: '#EDDCD0',
  red: '#B43B3C',
  sectionPad: '#F9F4F1',
  sectionBorder: '#d8bfac',
  navBorder: '#cccccc',
  selectedOutline: '#e02020',
  enabledOutline: '#6d7278',
  sectionHeader: '#FDF9F6',
  greyBorder: '#817163',
  productBorder: '#EFE3D8',
  text: '#4B5358',
  seRed: '#e12e22',
  raleysRed: '#e02020',
  raleysOneLightGreen: '#85961d',
  raleysOneDarkGreen: '#344f22',
  nobHillRed: '#e7172f',
  belAirRed: '#ee323f',
  belAirBrown: '#d7bfac',
  notification: '#FFD537',
  teal: '#328D80',
  green: '#3cb680',
  offerBorder: '#C9947E',
  clippedGreen: '#1BB680',
  tableHeaderRed: '#DA291C',
  tableLightRow: '#F5EEE9',
  lineSeparator: '#D8BFAC',
  slateGray: '#708090',
  darkGray: '#8f8d8c',
  alertBoxGreen: '#2F8275',
  saleRed: '#D72D38',
  backdropColor: '#000000b3',
  grey44: '#707070',
  dustyGray: '#999999',
  doveGray: '#717171',
  westar: '#e2deda',
  gold: '#ffd500',
  sponsoredGray: '#E8EBED',
  borderGray: '#cccac7bf',
  alertRed: '#DA3744',
  alertOrange: '#E27343',
  alertYellow: '#FAD347',
  alertCloseGray: '#777777',
};
export default colors;

import appConstants from '@config/appConstants';
import { Product, ProductVariant } from '@fieldera-raleys/client-commercetools/schema';
import {
  CategoryWidgetParams,
  ItemWidgetParams,
  KeywordWidgetParams,
  PagedArray,
  PersonalizedWidgetParams,
  ProductType,
  RecomendationResult,
  Widget,
  WidgetEventParams,
  WidgetParams,
  WidgetType,
} from '@fieldera-raleys/client-common';
import { useAnalytics } from '@hooks';
import { useIsFocused } from '@react-navigation/native';
import { brsmService } from '@services';
import logger from '@utils/logger';
import { getProductsfromCommerceTools } from '@utils/productHelper';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Carousel, { ProductCarouselProps as CarouselProps } from '../ProductCarousel';

type ProductCarouselProps = Omit<CarouselProps, 'data'> & {
  skus?: string[];
  widgetType: keyof typeof WidgetType;
  widgetId: string;
  widgetParams?: WidgetParams | ItemWidgetParams | KeywordWidgetParams | CategoryWidgetParams | PersonalizedWidgetParams;
};

const ProductCarousel = memo(
  ({
    title,
    titleStyle,
    linkText,
    linkTextStyle,
    linkAction,
    contentContainerStyle,
    addCartButtonStyle,
    backgroundStyle = {},
    skus,
    widgetType,
    widgetId,
    widgetParams,
    actionType,
    itemId,
    emptyListMsg,
  }: ProductCarouselProps): JSX.Element => {
    const isFocused = useIsFocused();
    const { data, status } = useQuery<RecomendationResult>(
      [widgetType, widgetId],
      async () =>
        await brsmService
          .getWidgetData(widgetType, widgetId, widgetParams, 0, appConstants.PAGE_SIZE)
          .then((result) => {
            return result;
          })
          .catch((error) => {
            logger.warn(error);
            return {
              products: { data: [], offset: 0, limit: 0, total: 0 } as PagedArray<Product>,
              widgetData: {} as Widget,
            } as RecomendationResult;
          }),
      {
        enabled: isFocused,
      },
    );
    const [availableItems, setAvailableItems] = useState<ProductVariant[]>();
    const [widgetData, setWidgetdata] = useState<WidgetEventParams>();

    const { trackWidgetViewEvent } = useAnalytics();
    const trackPageView = useRef<boolean>(true);

    type ProdDataType = {
      [key: string]: Product;
    };

    useEffect(() => {
      const getProdSet = async (s: string[]): Promise<Product[]> => {
        return await getProductsfromCommerceTools(s);
      };
      if (status === 'success' && data) {
        const { products, widgetData: wd } = data;
        setWidgetdata({ wrid: wd.rid, wid: widgetId, wty: wd.type });
        const prodSet = products.data.map((product: Product): ProductVariant => {
          const item = product.masterData.current?.variants.find((variant) => skus?.find((sku) => sku === variant.sku));
          if (item) {
            return item;
          }
          return product.masterData.current!.masterVariant;
        });
        if (actionType === 'substitute') {
          const ctProducts = getProdSet(
            prodSet.reduce((acc, p) => {
              if (p.sku) {
                acc.push(p.sku);
              }
              return acc;
            }, [] as string[]),
          );
          ctProducts.then((pd) => {
            const pData: ProdDataType = pd.reduce((acc, p: Product) => {
              acc[p.masterData?.current?.masterVariant.sku ?? ''] = p;
              p.masterData?.current?.variants.forEach((vi) => {
                acc[vi?.sku ?? ''] = p;
              });
              return acc;
            }, {} as ProdDataType);
            setAvailableItems(
              prodSet.filter((p) => {
                return p.sku && (pData[p.sku]?.productType?.name === ProductType.STANDARD ?? false);
              }),
            );
          });
        } else {
          setAvailableItems(prodSet);
        }
      }
    }, [status, skus, data, widgetId, actionType]);

    useEffect(() => {
      if (trackPageView.current && widgetData) {
        trackWidgetViewEvent({ wrid: widgetData?.wrid ?? '', wq: '', wid: widgetId, wty: widgetData?.wty ?? '' });
        trackPageView.current = false;
      }
      return () => {
        trackPageView.current = true;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetData, widgetId]);

    return (
      <Carousel
        title={title}
        {...{
          titleStyle,
          linkText,
          linkTextStyle,
          linkAction,
          contentContainerStyle,
          addCartButtonStyle,
          backgroundStyle,
          actionType,
          itemId,
          widgetEventParams: widgetData,
          widgetType,
          widgetId,
          widgetParams,
        }}
        data={availableItems ?? []}
        count={data?.products.total}
        emptyListMsg={emptyListMsg}
      />
    );
  },
);

export default ProductCarousel;

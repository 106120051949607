import { HeaderTitle, NavigationHeader, Screen } from '@components';
import { MessageCenterStackRoutes, MessageCenterStackScreenProps } from '@navigation/routes';
import { appStyles, utilityStyles } from '@styles';
import { scale } from '@styles/constants';
import React from 'react';
import { View } from 'react-native';
import styles from './styles';
type InboxScreenProps = MessageCenterStackScreenProps<MessageCenterStackRoutes.Inbox>;

const InboxScreen = ({ route: _route }: InboxScreenProps) => {
  return (
    <Screen>
      <NavigationHeader subStyle={[styles.navigationHeader, utilityStyles.pt2]}>
        <HeaderTitle style={{ fontSize: scale(26) }}>Inbox</HeaderTitle>
      </NavigationHeader>

      <View style={[appStyles.container, styles.container]}>{'Not Supported'}</View>
    </Screen>
  );
};

export default InboxScreen;

import BackButton from '@components/BackButton';
import Text from '@components/Text';
import colors from '@config/colors';
import { QuerySuggestion } from '@fieldera-raleys/client-common';
import { CheckoutStackRoutes, ShopStackRoutes } from '@navigation/routes';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { appStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import React, { useEffect, useState } from 'react';
import { Platform, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import CartButton from './CartButton';
import CloseButton from './CloseButton';
import SearchBar from './SearchBar';

interface SearchHeaderProps {
  useInsets?: boolean;
  setSearchText?: (searchText: string) => void;
  searchText?: string;
  showBackButton?: boolean;
  onSearchSubmit?: (searchText: string) => void;
  showAutoSuggest?: boolean;
  style?: StyleProp<ViewStyle>;
  onBackButtonPress?: (e: any) => void;
  placeHolder?: string;
  showAddToList?: boolean;
  shoppingListId?: string;
  shoppingListName?: string;
  onClose?: (e: any) => void;
  showRecentOnly?: boolean;
  tabBarHeight?: number;
  showCloseButton?: boolean;
}

const SearchHeader = ({
  useInsets = false,
  setSearchText,
  searchText,
  showBackButton = false,
  showAutoSuggest,
  onSearchSubmit,
  onBackButtonPress,
  placeHolder,
  showAddToList = false,
  shoppingListId,
  shoppingListName,
  showRecentOnly = false,
  onClose,
  tabBarHeight,
  showCloseButton = false,
}: SearchHeaderProps) => {
  const insets = useSafeAreaInsets();
  const route = useRoute();

  const [textToSearch, setTextToSearch] = useState(searchText ?? '');
  const [showCartIcon, setShowCartIcon] = useState(false);
  const [showPoints, setShowPoints] = useState(true);

  useEffect(() => {
    searchText && setTextToSearch(searchText);
  }, [route.name, searchText]);

  const [rightButton, setRightButton] = useState(<></>);
  useEffect(() => {
    if (onClose && showPoints && !showCartIcon) {
      if (showCloseButton) {
        setRightButton(
          <View style={[styles.cartButtonView, { height: scale(50) }]}>
            <CloseButton style={styles.closeBtn} size={22} onPress={onClose} />
          </View>,
        );
      } else {
        setRightButton(
          <View style={styles.cartButtonView}>
            <TouchableOpacity style={[styles.cancelBtnView]} onPress={onClose} testID="arrow-btn-dwn">
              <Text numberOfLines={1} style={[appStyles.bodySmallLeft, { textAlign: 'center', color: colors.secondary }]} testID="closeBtnText">
                Close
              </Text>
            </TouchableOpacity>
          </View>,
        );
      }
    } else if (showPoints && showCartIcon) {
      setRightButton(
        <View style={styles.cartButtonView} testID="cartBtnView">
          <CartButton />
        </View>,
      );
    } else {
      setRightButton(<></>);
    }
  }, [showCartIcon, showPoints, onClose, showCloseButton]);

  useFocusEffect(
    React.useCallback(() => {
      if (
        route.name === ShopStackRoutes.Categories ||
        route.name === ShopStackRoutes.Category ||
        route.name === ShopStackRoutes.ProductList ||
        route.name === ShopStackRoutes.ShopLanding ||
        route.name === ShopStackRoutes.SearchLanding
      ) {
        setShowCartIcon(true);
      } else if (route.name === CheckoutStackRoutes.BackupScreen) {
        setShowCartIcon(false);
      } else {
        setShowCartIcon(true);
      }
    }, [route.name]),
  );

  const callProductSearch = (suggestion: QuerySuggestion) => {
    setSearchText && setSearchText(suggestion.query);
  };

  const getSearchbarWidth = () => {
    var width = screenWidth - 16; //padding
    width -= onClose || showCartIcon ? 56 : 0; // cart
    width -= showBackButton ? 40 : 0; //backbutton
    return width;
  };

  return (
    <View
      style={[
        styles.container,
        {
          marginTop: useInsets ? insets.top : 5,
          paddingHorizontal: 8,
          ...Platform.select({
            ios: {
              zIndex: 150,
            },
          }),
        },
      ]}>
      {showBackButton && (
        <View style={styles.backButton} testID="backBtnView">
          <BackButton onPress={onBackButtonPress} size={24} />
        </View>
      )}
      <View style={{ width: getSearchbarWidth() }}>
        <SearchBar
          // containerStyles={{ justifyContent: 'center' }}
          shoppingListId={shoppingListId}
          shoppingListName={shoppingListName}
          placeHolder={placeHolder}
          showAutoSuggest={showAutoSuggest}
          onAutoSuggestSelect={callProductSearch}
          searchText={textToSearch}
          onSearchSubmit={onSearchSubmit}
          hasBackButton={showBackButton}
          setShowPointsHeader={setShowPoints}
          isFromSearchHeader={true}
          showAddToList={showAddToList}
          showRecentOnly={showRecentOnly}
          tabBarHeight={tabBarHeight}
        />
      </View>
      {rightButton}
    </View>
  );
};

const styles = StyleSheet.create({
  backButton: { width: 40 },
  container: {
    ...appStyles.headerContainer,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 54,
  },
  cartButtonView: {
    width: 56,
    alignItems: 'center',
    zIndex: -1,
  },
  cancelBtnView: {
    height: 48,
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  closeBtn: {
    left: scale(16),
    top: scale(13),
    justifyContent: 'center',
  },
});

export default SearchHeader;

import Text from '@components/Text';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { ShopType, TourKey } from '@fieldera-raleys/client-common';
import { useEffectOnce } from '@hooks';
import { RootTabRoutes } from '@navigation/routes';
import { useRoute } from '@react-navigation/native';
import InitialShopOptions from '@screens/shoppingContext/InitialShopOptions';
import useCartStore from '@store/cartStore';
import useShopStore from '@store/shopStore';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import dayjs from 'dayjs';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Dimensions, Easing, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTourGuideController } from 'rn-tourguide';
import Icon from './Icon';

const { height } = Dimensions.get('window');

export type OpenTombstoneRequest = {
  ts: number; // to re-trigger state update
  page?: string; // start on page
};

interface TombstoneProps {
  openRequest?: OpenTombstoneRequest;
}

//Root tombstone component
const Tombstone: FC<TombstoneProps> = ({ openRequest = undefined }): JSX.Element => {
  //values used to open/close/navigate the modal
  const { selectedStore, setSelectedShopType, setSearchText, setSearchResult, currPage, setCurrPage, selectedShopType } = useShopStore();
  const { tombOpen } = useCartStore();
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const animatedValue = useRef(new Animated.Value(0)).current;
  //Animation function for tombstone
  const openTombstone = useCallback(
    (toValue: number) => {
      return Animated.timing(animatedValue, {
        toValue,
        duration: 600,
        useNativeDriver: appConstants.USE_NATIVE_DRIVER,
        easing: Easing.elastic(0.7),
      });
    },
    [animatedValue],
  );

  useEffect(() => {
    setCurrentPage(currPage ?? '');
  }, [currPage]);

  useEffect(() => {
    tombOpen(open);
  }, [open, tombOpen]);

  const toggleTombstone = () => {
    openTombstone(open ? 0 : 1).start();
    setOpen(!open);
    if (open) {
      setCurrPage('');
    }
    setCurrentPage('');
    if (currPage) {
      setCurrentPage(currPage);
    }
    if (selectedShopType === ShopType.IN_STORE) {
      setCurrPage('In-Store');
    }
    setSearchText('');
    setSearchResult([]);
    // setFavorites([]);
    if (!selectedStore) {
      setSelectedShopType(undefined);
    }
  };

  useEffect(() => {
    openTombstone(openRequest ? 1 : 0).start();
    setOpen(!!openRequest);
    if (openRequest) {
      setCurrentPage(openRequest.page ?? '');
    }
    setSearchText('');
    setSearchResult([]);
    // setFavorites([]);
    if (!selectedStore) {
      setSelectedShopType(undefined);
    }
    // next line intended
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRequest]);

  useEffectOnce(() => {
    if (!selectedStore?.address?.street || currPage) {
      if (currPage !== 'In-Store') {
        toggleTombstone();
      }
    }
  });

  const translateY = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: screenHeight < 740 ? [screenHeight * 0.7, 25] : [screenHeight * 0.64, 25],
  });
  //58
  const overlayOpacity = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });
  //, { transform: [{ translateY: translateY }] }
  return (
    <>
      {open && (
        <Animated.View
          style={[
            styles.overlay,
            {
              opacity: overlayOpacity,
            },
          ]}
        />
      )}
      <Animated.View style={[currentPage === 'Map' ? styles.mapTombstone : styles.tombstone, { transform: [{ translateY: translateY }] }]}>
        {!open ? (
          <View style={{ flexDirection: 'row', width: '95%' }}>
            <ClosedStone
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              toggleTombstone={toggleTombstone}
              animatedValue={animatedValue}
              open={open}
            />
            <TouchableOpacity style={styles.icon} onPress={toggleTombstone} testID="arrow-btn-up">
              {/* <UpArrow /> */}
              <Icon name="arrow-circle-up" fill={colors.white} stroke={colors.red} fillSecondary={colors.red} style={styles.icon} size={35} />
            </TouchableOpacity>
          </View>
        ) : (
          <View style={{ width: '95%' }}>
            {currentPage === 'Map' ? (
              <TouchableOpacity
                style={styles.mapCrossIcon}
                testID="x-close"
                onPress={() => {
                  if (!open) {
                    toggleTombstone();
                  }
                  setCurrentPage('FindAStore');
                }}>
                <Icon
                  name="x-close"
                  fill={colors.white}
                  stroke={colors.sectionBorder}
                  fillSecondary={colors.red}
                  style={[styles.icon, { marginRight: 15 }]}
                  size={20}
                />
              </TouchableOpacity>
            ) : (
              <View style={{ flexDirection: 'row' }}>
                {/* {selectedShopType && !selectedLocation ? (
                <TouchableOpacity
                  onPress={() => setSelectedShopType('')}
                  style={styles.backArrow}>
                  <IconI name="arrow-back" size={28} color={colors.red} />
                </TouchableOpacity>
              ) : null} */}
                <ClosedStone
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  toggleTombstone={toggleTombstone}
                  animatedValue={animatedValue}
                  open={open}
                />
                <TouchableOpacity style={styles.icon} onPress={toggleTombstone} testID="arrow-btn-dwn">
                  <Icon name="arrow-circle-down" fill={colors.white} stroke={colors.red} fillSecondary={colors.red} style={styles.icon} size={35} />
                </TouchableOpacity>
              </View>
            )}
            <InitialShopOptions currentPage={currentPage} setCurrentPage={setCurrentPage} toggleTombstone={toggleTombstone} />
          </View>
        )}
      </Animated.View>
    </>
  );
};

//View rendered while the tombstone is closed.
interface IClosedStoneProps {
  animatedValue: Animated.Value;
  toggleTombstone: () => void;
  currentPage?: string;
  setCurrentPage: Dispatch<SetStateAction<string>>;
  open: boolean;
}

const ClosedStone: FC<IClosedStoneProps> = ({ animatedValue, toggleTombstone, setCurrentPage, open, currentPage }: IClosedStoneProps): JSX.Element => {
  const { selectedStore, selectedShopType, selectedTimeSlot, deliveryAddress } = useShopStore();
  const { t } = useTranslation(['tombstone', 'tourGuide']);
  const { TourGuideZone } = useTourGuideController(TourKey.HomeScreen);
  const route = useRoute();

  var deliveryAddr = `${deliveryAddress?.address1}`;
  if (deliveryAddr.length > 18) {
    deliveryAddr = deliveryAddr.slice(0, 18).concat('...');
  }
  var day: string = '';
  var date: number = 0;
  var month: string = '';
  var startTime = selectedTimeSlot?.timeSlots?.[0].timeSlotHourStart ?? 11;
  var endTime = selectedTimeSlot?.timeSlots?.[0].timeSlotHourEnd ?? 12;
  var startMeridiem = startTime === 12 ? ' PM' : ' AM';
  var endMeridiem = endTime === 12 ? ' PM' : ' AM';

  var shortenedLocation = selectedStore?.address?.street;
  if (shortenedLocation && shortenedLocation.length > 18) {
    shortenedLocation = shortenedLocation.slice(0, 18).concat('...');
  }
  if (selectedTimeSlot?.timeSlotDate) {
    const dateTime = dayjs(selectedTimeSlot.timeSlotDate);
    day = dateTime.format('ddd');
    date = +dateTime.date();
    month = dateTime.format('MMM');

    if (startTime && startTime >= 13) {
      startTime = startTime - 12;
      startMeridiem = ' PM';
    }
    if (endTime && endTime >= 13) {
      endTime = endTime - 12;
      endMeridiem = ' PM';
    }
  }

  const opacity = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  });

  const openLink = (page?: string) => {
    if (!open) {
      toggleTombstone();
    }
    if (page) {
      setCurrentPage(page);
    }
  };

  return (
    <Animated.View
      needsOffscreenAlphaCompositing={true}
      style={[
        styles.closedStone,
        {
          maxHeight: screenHeight * 0.8,
          opacity: !selectedStore?.address?.street ? opacity : 1,
        },
      ]}>
      {selectedStore?.address?.street && currentPage !== 'AddressBook' ? (
        <View style={styles.setHeader}>
          <TourGuideZone
            text={t('tgTombstoneShopIconTxt', { ns: 'tourGuide' })}
            zone={3}
            shape={'rectangle'}
            style={styles.shopIcon}
            isTourGuide={route.name === RootTabRoutes.Home}>
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => {
                if (!open) {
                  toggleTombstone();
                }
                setCurrentPage('InitialShopOptions');
              }}
              style={[styles.shopIcon, { marginBottom: -5, marginLeft: selectedShopType === 'In-Store' ? 2 : -7.5 }]}>
              {selectedShopType === 'Pickup' && (
                <Icon stroke={'none'} size={30} name="pickup-icon" fill={colors.darkText} style={[styles.center, { marginBottom: -2 }]} testID='"pickup-icon' />
              )}
              {selectedShopType === 'Delivery' && (
                <Icon
                  testID="delivery-icon"
                  stroke={'none'}
                  size={30}
                  name="delivery-icon"
                  fill={'#4b5358'}
                  strokeSecondary={'#4b5358'}
                  style={[styles.center, { marginBottom: -2 }]}
                />
              )}
              {selectedShopType === 'In-Store' && (
                <Icon stroke={'none'} size={22} name="list-icon" fill={colors.darkText} style={[styles.center, { paddingBottom: 4 }]} testID="list-icon" />
              )}

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Text style={styles.shopType} testID="selectedShopType">
                  {selectedShopType}
                </Text>
                <Icon
                  testID="triangle-down"
                  name={'triangle-down'}
                  fill={colors.darkText}
                  stroke={'transparent'}
                  size={15}
                  style={{ position: 'relative', transform: [{ translateY: Platform.OS === 'ios' ? 2 : 3 }, { translateX: 2 }] }}
                />
              </View>
              {/* <View style={{ position: 'absolute', bottom: -7, right: -8 }}>
              <Icon name={'triangle-down'} fill={colors.darkText} stroke={'transparent'} size={15} />
            </View> */}
            </TouchableOpacity>
          </TourGuideZone>
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
              if (selectedShopType === ShopType.PICKUP) {
                openLink('FindAStore');
              } else if (selectedShopType === ShopType.DELIVERY) {
                openLink('Delivery');
              } else if (selectedShopType === ShopType.IN_STORE) {
                openLink('In-Store');
              }
            }}
            style={[
              styles.address,
              {
                width: selectedShopType === ShopType.IN_STORE ? screenWidth / 1.5 : screenWidth / 2.5,
                marginLeft: selectedShopType === ShopType.IN_STORE ? 8 : 0,
              },
            ]}>
            {deliveryAddress?.postalCode ? (
              <>
                {deliveryAddress.address1 ? (
                  <Text numberOfLines={1} style={[styles.topAddress, styles.shiftDown, { height: 23 }]} testID="deliveryAddr">
                    {deliveryAddr}
                  </Text>
                ) : (
                  <Text numberOfLines={1} style={[styles.selectDestination, styles.shiftDown]} testID="Select destination">
                    {'Select destination'}
                  </Text>
                )}
                <Text style={styles.topAddress} numberOfLines={1} testID="deliveryAddressPostalCode">
                  {deliveryAddress.postalCode}
                </Text>
              </>
            ) : (
              <>
                {selectedStore?.brand.logo === 'raleys' && (
                  <Icon name="raleys" fill={colors.darkText} stroke={'none'} size={45} style={styles.storeLogo} testID="raleysStoreLogo" />
                )}
                {selectedStore?.brand.logo.includes('one') && (
                  <Icon
                    fillSecondary={colors.darkText}
                    fill={colors.darkText}
                    name="raleysonemarket"
                    stroke={'none'}
                    size={45}
                    style={styles.storeLogo}
                    testID="raleysonemarket"
                  />
                )}
                {selectedStore?.brand.logo === 'belair' && (
                  <Icon
                    testID="belair"
                    name="belair"
                    fill={colors.darkText}
                    fillSecondary={colors.darkText}
                    stroke={'none'}
                    size={50}
                    style={[styles.storeLogo, { paddingLeft: 6 }]}
                  />
                )}
                {selectedStore?.brand.logo === 'nobhillfoods' && (
                  <Icon
                    name="nobhillfoods"
                    fill={colors.darkText}
                    stroke={'none'}
                    size={65}
                    style={[styles.storeLogo, { paddingLeft: 21 }]}
                    testID="nobhillfoods"
                  />
                )}
                <Text numberOfLines={1} style={styles.topAddress} testID="selectedStoreAddress">
                  {selectedStore?.address?.street}
                </Text>
              </>
            )}
          </TouchableOpacity>
          {selectedShopType !== ShopType.IN_STORE ? (
            <View style={styles.timeSlotWrapper}>
              {selectedTimeSlot?.timeSlotDate && !dayjs(selectedTimeSlot?.timeSlotDate).isBefore(dayjs(), 'day') ? (
                <Text
                  testID="selectedTimeSlot"
                  onPress={() => {
                    openLink('TimeSlot');
                  }}
                  style={[
                    styles.timeSlot,
                    { color: colors.darkText },
                  ]}>{`${day} ${month} ${date}\n${startTime}${startMeridiem} - ${endTime}${endMeridiem}`}</Text>
              ) : (
                <TouchableOpacity
                  activeOpacity={1}
                  onPress={() => {
                    openLink('TimeSlot');
                  }}>
                  <Text style={styles.timeSlot} testID="selectedTimeSlot">
                    {'Select\nTime Slot'}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          ) : (
            <View style={styles.timeSlotWrapper} />
          )}
        </View>
      ) : (
        <>
          {currentPage === 'AddressBook' ? null : (
            <TourGuideZone
              text={t('tgTombstoneShopIconTxt', { ns: 'tourGuide' })}
              zone={3}
              shape={'rectangle'}
              isTourGuide={!open && route.name === RootTabRoutes.Home}>
              <TouchableOpacity activeOpacity={1} onPress={toggleTombstone} style={styles.closedTombstoneInitial}>
                <View style={styles.startShoppingButton}>
                  <Text style={styles.startShoppingText} testID="startShopping">
                    Start Shopping
                  </Text>
                </View>
                {/* <Text style={styles.chooseTypesText}>Choose In-Store, Delivery or Pickup</Text> */}
                <Text style={styles.chooseTypesText} testID="titleText">
                  {t('titleText')}
                </Text>
              </TouchableOpacity>
            </TourGuideZone>
          )}
        </>
      )}
    </Animated.View>
  );
};

//Some inline styles were used in order to work with animations
const styles = StyleSheet.create({
  overlay: {
    width: screenWidth,
    height: screenHeight * 1.5,
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 151,
  },
  tombstone: {
    width: screenWidth,
    flexDirection: 'row',
    backgroundColor: colors.cream,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    // borderWidth: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: screenHeight < 740 ? screenHeight * 0.8 : screenHeight * 0.72,
    zIndex: 152,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3.84,

    elevation: 5,
  },
  closedStone: {
    width: '86%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  setHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '93%',
  },
  address: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: colors.darkGray,
    marginRight: 12,
    // width: screenWidth / 2.5,
    paddingLeft: 8,
    // backgroundColor: 'gold',
  },
  closedTombstoneInitial: {
    flexDirection: 'row',
    width: '100%',
  },
  chooseTypesText: {
    color: colors.dark,
    fontSize: scale(14),
    width: '45%',
    marginTop: Platform.OS === 'ios' ? height * 0.007 : 5,
    marginLeft: 15,
  },
  shopType: {
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(13),
    textAlign: 'center',
    color: colors.dark,
    transform: [{ translateX: 2 }],
  },
  icon: {
    marginRight: 0,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'gold',
    width: 55,
    height: 45,
  },
  shopIcon: {
    marginRight: Platform.OS === 'web' ? 25 : 0,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'gold',
    width: 60,
    height: 40,
    marginLeft: -7.5,
  },
  downArrowWrapper: {
    width: '100%',
    alignItems: 'flex-end',
    paddingRight: 25,
    marginTop: 5,
  },
  timeSlotWrapper: {
    width: screenWidth * 0.18,
    marginRight: Platform.OS === 'ios' ? 0 : 15,
    paddingLeft: 10,
    // backgroundColor: 'blue',
  },
  timeSlot: {
    color: colors.red,
    fontFamily: 'Larsseit-Light',
    // fontSize: responsiveFontSize(1.9),
    fontSize: scale(14),
    lineHeight: Platform.select({ android: 21, default: 18 }),
    textAlign: 'left',
    width: '140%',
    marginLeft: Platform.OS === 'ios' ? -15 : 0,
  },
  selectDestination: {
    color: colors.red,
    fontFamily: 'Larsseit-Light',
    fontSize: scale(15),
    lineHeight: lineHeight(18),
    textAlign: 'left',
  },
  topAddress: {
    fontFamily: 'Larsseit-Light',
    // fontSize: responsiveFontSize(2),
    fontSize: scale(15),
    lineHeight: 25,
    color: colors.dark,
    textAlign: 'left',
    width: '95%',
  },
  startShoppingButton: {
    width: screenWidth * 0.38,
    height: screenHeight < 740 ? 40 : 45,
    marginLeft: 0,
    justifyContent: 'center',
    backgroundColor: '#ecdcd1',
    borderRadius: 100,
    alignItems: 'center',
    padding: Platform.OS === 'ios' ? 5 : 0,
    shadowColor: colors.black,
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.22,
    // shadowRadius: 2.22,

    elevation: 3,
  },
  startShoppingText: {
    // fontWeight: '700',
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
    fontSize: scale(15),
    marginTop: Platform.OS === 'ios' ? 2 : 0,
  },
  backArrow: {
    position: 'absolute',
    top: 15,
    left: 15,
    zIndex: 999,
  },
  storeLogo: {
    paddingTop: 5,
    marginBottom: 2,
    minWidth: 45,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'gold',
  },
  center: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mapTombstone: {
    width: screenWidth,
    flexDirection: 'row',
    backgroundColor: '#fcf7f3',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: screenHeight * 0.85,
    zIndex: 152,
    elevation: 5,
  },
  mapCrossIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: 45,
    paddingTop: 30,
  },
  shiftDown: {
    transform: [{ translateY: 2 }],
  },
});

export { ClosedStone };

export default Tombstone;

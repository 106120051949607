import { ReactNativePlugin } from '@microsoft/applicationinsights-react-native';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { Platform } from 'react-native';
import Config from 'react-native-config';
import DeviceInfo from 'react-native-device-info';

class AppInsightsService {
  private appInsights: ApplicationInsights;
  constructor() {
    const RNPlugin = new ReactNativePlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: Config.APPINSIGHTS_INSTRUMENTATION_KEY,
        extensions: [RNPlugin],
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
      env.tags = env.tags || [];
      env.tags['ai.cloud.role'] = Config.APPINSIGHTS_ROLE_NAME;
      env.tags['ai.cloud.roleInstance'] = `${Platform.OS}-${DeviceInfo.getVersion()}(${DeviceInfo.getBuildNumber()})`;
    });
  }

  logEvent(name: string, properties?: { [k: string]: unknown }) {
    this.appInsights.trackEvent({ name, properties });
  }
  logException(exception: Error, data?: Record<string, string>) {
    this.appInsights.trackException({ exception }, data);
  }
  logDebug(message: string, data?: Record<string, string>) {
    this.appInsights.trackTrace({ message, severityLevel: 1 /*Information*/ }, data);
  }
  logTrace(message: string, data?: Record<string, string>) {
    this.appInsights.trackTrace({ message, severityLevel: 2 /*Warning*/ }, data);
  }
  trackPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({ name, uri: url });
  }
}

export const appInsightsService = new AppInsightsService();

import { IconName } from '@components/Icon';
import colors from '@config/colors';
import {
  Address as CTAddress,
  Cart,
  CustomerContact,
  FulfillmentStore,
  LineItem,
  Money,
  OfflinePaymentType,
  Order,
  ProductData,
  ProductVariant,
  RawCustomField,
  RawProductAttribute,
  ShoppingListLineItem,
  SubstitutionType,
} from '@fieldera-raleys/client-commercetools/schema';
import { defaultMoney } from '@fieldera-raleys/client-commercetools/utils';
import { Address as BWAddress, Store as BWStore, ProductType, ShopType, TimeSlot } from '@fieldera-raleys/client-common';
import { Order as BWOrder, OrderItem } from '@fieldera-raleys/client-common/types/order';
import { getCustomFieldValue, getEndAvailabilityDate } from '@fieldera-raleys/client-common/utils';
import useShopStore from '@store/shopStore';
import dayjs from 'dayjs';
import Config from 'react-native-config';
import { formatDate, formatPhone, formatValue } from './helpers';
import { getfulfillmentLeadTimeMinutes } from './productHelper';

export type DeliveryTip = {
  tipAmount: number;
  tipType: '$' | '%';
};

export const moneyValue = (money?: Money, qty: number = 1, defaultValue?: string) => {
  if (!money) {
    return defaultValue;
  }

  return formatValue(money ? (money.centAmount / (10 ** money.fractionDigits || 1)) * qty : 0);
};

export const pointsValue = (money?: Money, defaultValue?: string) => {
  return money ? (money.centAmount / (10 ** money.fractionDigits || 1)).toFixed(0) : defaultValue;
};

// returns null if same price or null if not present
export const getRegularItemPrice = (lineItem: LineItem | ShoppingListLineItem): Money | null => {
  let rp: Money | null = null;
  if ('price' in lineItem) {
    rp = lineItem.price.custom?.customFieldsRaw?.find((x) => x.name === 'regularPrice')?.value ?? null;
    if (rp) {
      return rp.centAmount === 0 || rp.centAmount === lineItem.price?.value.centAmount ? null : rp;
    } else {
      rp = lineItem.variant?.price?.custom?.customFieldsRaw?.find((x) => x.name === 'regularPrice')?.value ?? null;
    }
    if (rp) {
      return rp.centAmount === 0 || rp.centAmount === lineItem.variant?.price?.value.centAmount ? null : rp;
    } else {
      rp = lineItem.custom?.customFieldsRaw?.find((x) => x.name === 'regularPrice')?.value ?? null;
    }
    return rp && (rp.centAmount === 0 || rp.centAmount === lineItem.price.value.centAmount) ? null : rp;
  } else {
    rp = lineItem.variant?.price?.custom?.customFieldsRaw?.find((x) => x.name === 'regularPrice')?.value ?? null;
    return rp && (rp.centAmount === 0 || rp.centAmount === lineItem.variant?.price?.value.centAmount) ? null : rp;
  }
};

// returns null if not present
export const getItemSaleDescription = (lineItem: LineItem): string | null => {
  let saleDescription = null;
  const priceMessage = lineItem?.price?.custom?.customFieldsRaw?.find((x) => x.name === 'PriceMessage')?.value ?? null;
  if (priceMessage) {
    if (/ for \$?[0-9]*(\.[0-9]{2})?$/.test(priceMessage)) {
      saleDescription = priceMessage;
    } else {
      saleDescription = [moneyValue(lineItem.price.value), priceMessage].join(' ');
    }
  }
  return saleDescription;
};

export const getSubTotalItemPrice = (obj?: Cart | Order) => {
  if (!obj) {
    return undefined;
  }

  const itemsTotal = (obj.lineItems ?? []).reduce((total: number, i: LineItem): number => {
    return total + (i.price?.value.centAmount ?? 0) * i.quantity;
  }, 0);

  const p: Money = {
    ...defaultMoney,
    centAmount: itemsTotal,
  };

  return p;
};

export const getBWPostalCode = (order?: BWOrder, defultValue?: string) => {
  if (order?.shippingMethod?.toUpperCase() === 'DELIVERY' ?? true) {
    // pull postalcode from destination address for delivery
    var found: RegExpMatchArray | null = (order?.deliverySite?.facility?.value ?? '')?.match(/^(\d)\w+/gi);
    if (found && found.length) {
      return '' + found[0];
    }
    return defultValue;
  } else {
    // and store address for pickup
    return order?.facility?.customProperties?.postal_code ?? defultValue;
  }
};

export const getBWOrderStoreNumber = (order?: BWOrder, defultValue: string = '1') => {
  if (order?.shippingMethod?.toUpperCase() !== 'DELIVERY' ?? true) {
    var found: RegExpMatchArray | null = (order?.deliverySite?.facility?.value ?? '')?.match(/^(\d)\w+/gi);
    if (found && found.length) {
      return '' + found[0];
    }
    return defultValue;
  } else {
    return order?.facility?.customProperties?.StoreNumber ?? defultValue;
  }
};

export const getSubTotalPriceBWOrder = (obj?: BWOrder) => {
  // take total price, subtract shipping (also timeslot fee and tip when it is included)
  if (!obj) {
    return undefined;
  }

  const itemsTotal = (obj.orderItems ?? [])
    .filter((oi) => oi.merchandisingCategory?.value?.toLowerCase() !== 'donations')
    .reduce((total: number, i: OrderItem): number => {
      const tp: number | null = i.unitPrice ?? null;
      return total + (tp ? tp : i.unitPrice) * i.qty;
    }, 0);

  const p: Money = {
    ...defaultMoney,
    centAmount: itemsTotal,
  };

  return p;
};

export const getCRVAmountMoneyBWOrder = (obj: BWOrder | undefined) => {
  // take total price, subtract shipping (also timeslot fee and tip when it is included)
  if (!obj) {
    return null;
  }

  const crvTotal = (obj.orderItems ?? []).reduce((total: number, i: OrderItem): number => {
    total += Number(i.orderItemDepositTransaction?.value ?? '0') * i.qty;
    return total;
  }, 0);

  return crvTotal;
};

export const taxedGrossValue = (obj?: Cart | Order, defaultValue?: string): string | undefined => {
  return moneyValue(obj?.taxedPrice?.totalGross, 1) ?? defaultValue;
};

export const preAuthMonayValue = (obj?: Cart | Order, authAmountMultiplier?: number, estimatedSavings?: number): Money => {
  const preAuthMoneyVal = {
    ...(obj?.taxedPrice?.totalGross ?? defaultMoney),
    centAmount: (obj?.taxedPrice?.totalGross.centAmount + estimatedSavings) * (authAmountMultiplier ?? 1.15),
  } as Money;
  return preAuthMoneyVal;
};

export const preAuthValue = (obj?: Cart | Order, authAmountMultiplier?: number, estimatedSavings?: number, defaultValue?: string): string | undefined => {
  const preAuthMoneyVal = preAuthMonayValue(obj, authAmountMultiplier, estimatedSavings);
  return moneyValue(preAuthMoneyVal, 1) ?? defaultValue;
};

export const getOrderNote = (obj?: Cart | Order, defaultValue?: string): string | undefined => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'orderNote')?.value ?? defaultValue;
};

export const getLineItemNote = (lineItem?: LineItem | null, defaultValue?: string): string | undefined => {
  return lineItem?.custom?.customFieldsRaw?.find((x) => x.name === 'itemNote')?.value ?? defaultValue;
};

export const getOfflinePayment = (obj: Cart | Order | undefined, defaultValue?: OfflinePaymentType) => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'offlinePaymentMethodType')?.value ?? defaultValue;
};

export const setOfflinePayment = (obj: Cart | Order, value: OfflinePaymentType): void => {
  const f = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'offlinePaymentMethodType');
  if (f) {
    f.value = value;
  }
};

export const getSelectedPayment = (obj?: Cart | Order): number | undefined => {
  let val = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'customerPaymentMethodId')?.value;
  return val ? parseInt('' + val, 10) : undefined;
};

export const getPaymentAuthorization = (obj?: Cart | Order): string | undefined => {
  let val = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'customerPaymentMethodAuthCode')?.value;
  return val;
};

export const getCalculatedTipMoney = (obj?: Cart | Order, tip?: DeliveryTip, exclude: string[] = []): Money => {
  if (!obj) {
    return { ...defaultMoney, centAmount: 0 };
  }
  if (!tip) {
    tip = { tipAmount: 0, tipType: '%' };
  }

  if (tip.tipType === '$') {
    return {
      ...defaultMoney,
      centAmount: Math.round(tip.tipAmount * 100),
    };
  }

  let itemsTotal = (obj.lineItems ?? []).reduce((total: number, i: LineItem): number => {
    if (!exclude.find((x) => x === i.id)) {
      return total + (i.totalPrice?.centAmount ?? 0);
    } else {
      return total;
    }
  }, 0);

  if (isDelivery(obj)) {
    itemsTotal += (obj.customLineItems ?? []).find((i) => i.slug === 'delivery-fee')?.totalPrice.centAmount ?? 0;
  }

  itemsTotal += (obj.customLineItems ?? []).find((i) => i.slug === 'service-fee')?.totalPrice.centAmount ?? 0;

  itemsTotal += obj.taxedPrice?.taxPortions.reduce((amt, tx) => amt + tx.amount.centAmount, 0) ?? 0;

  return {
    ...defaultMoney,
    centAmount: Math.round(itemsTotal * (tip.tipAmount / 100.0)),
  };
};

export const getCalculatedTip = (obj?: Cart | Order, tip?: DeliveryTip) => {
  return moneyValue(getCalculatedTipMoney(obj, tip), 1);
};

export const parseDeliveryTip = (val: string): DeliveryTip => {
  const tipSet = val.split('|');
  const tipType = tipSet[1] && tipSet[1] === '$' ? '$' : '%';
  let value: number = parseFloat(tipSet[0]);
  value = isNaN(value) ? 0 : value;
  const deliveryTip: DeliveryTip = {
    tipAmount: value,
    tipType: tipType,
  };
  return deliveryTip;
};

export const getDeliveryTip = (obj?: Cart | Order): DeliveryTip => {
  let p = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'deliveryTipPercent')?.value;
  if (p) {
    return parseDeliveryTip(`${p}|%`);
  } else {
    const v = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'deliveryTip')?.value;
    if (v) {
      return parseDeliveryTip(`${v.centAmount / 100}|$`);
    } else {
      return parseDeliveryTip('0|%');
    }
  }
};

export const getDonationAmount = (obj?: Cart | Order): string | undefined => {
  const money = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'donationAmount')?.value;
  if (!money) {
    return undefined;
  }

  return (money.centAmount / 10 ** money.fractionDigits).toFixed(2);
};

export const getCRVAmountMoney = (obj?: Cart | Order): Money | undefined => {
  // this comes from custom item from server
  const crvAmount = (obj?.customLineItems ?? []).find((i) => i.slug === 'crv-amount');
  return crvAmount && typeof crvAmount.totalPrice !== 'undefined' ? crvAmount.totalPrice : undefined;
};

export const getDonationAmountMoney = (obj?: Cart | Order): Money | undefined => {
  // this comes from custom item from server
  const donationAmount = (obj?.customLineItems ?? []).find((i) => i.slug === 'donation-amount');
  return donationAmount && typeof donationAmount.totalPrice !== 'undefined' ? donationAmount.totalPrice : undefined;
};

export const getDonationAmountValue = (obj?: Cart | Order): string => {
  // this comes from custom item from server
  const donationAmount = (obj?.customLineItems ?? []).find((i) => i.slug === 'donation-amount');
  return (donationAmount && typeof donationAmount.totalPrice !== 'undefined' ? moneyValue(donationAmount.totalPrice) : moneyValue({ ...defaultMoney })) ?? '';
};

export const getDeliveryTipValue = (obj?: Cart | Order, defaultValue?: string): string | undefined => {
  const deliveryTip = (obj?.customLineItems ?? []).find((i) => i.slug === 'delivery-tip');
  return deliveryTip && typeof deliveryTip.totalPrice !== 'undefined' ? moneyValue(deliveryTip.totalPrice) : defaultValue;
};

export const setDeliveryTipValue = (obj: Cart | Order, value: Money): void => {
  (obj?.customLineItems ?? []).some((i) => {
    if (i.slug === 'delivery-tip') {
      i.totalPrice = value;
      i.money = value;
      return true;
    }
    return false;
  });
};

export const getOrderNumber = (obj?: Order, defaultValue?: string | undefined): string | undefined => {
  return obj?.orderNumber ? obj.orderNumber : defaultValue;
};

export const getOrderDate = (obj?: Order, defaultValue?: string): string | undefined => {
  let date = defaultValue;
  try {
    if (obj?.createdAt) {
      date = formatDate(new Date(obj?.createdAt));
    }
  } catch (e) {
    /* empty */
  }
  return date;
};

// this is set to 0 in commerce tools, use deliveryFee instead
export const getShippingPriceValue = (obj?: Cart | Order, defaultValue?: string) => {
  if (!obj) {
    return defaultValue;
  }
  const p: Money = {
    ...obj.totalPrice,
    centAmount: obj.shippingInfo?.price.centAmount ?? 0,
  };
  return moneyValue(p, 1, defaultValue);
};

export const isOrderObject = (obj?: Cart | Order): boolean => {
  return (obj && (obj as Order))?.orderState ? true : false;
};

export const mapStoreToBWAddress = (store: BWStore): BWAddress | undefined => {
  return {
    address1: store.address.street,
    city: store.address.city,
    state: store.address.state,
    postalCode: store.address.zip,
    companyName: store.name,
    isDefaultShipping: false,
    phone: formatPhone(store.phone) ?? '',
    country: 'US',
    email: store.email,
    addressType: 'business' as 'business' | 'home',
    isValidated: true,
  };
};

export const mapBWStoreToFulfilmentStore = (store: BWStore): FulfillmentStore => {
  let st = store.address.state?.toLowerCase();
  switch (st) {
    case 'nevada':
      st = 'NV';
      break;
    case 'arizona':
      st = 'AZ';
      break;
    case 'california':
    default:
      st = 'CA';
  }

  return {
    number: store.number,
    name: store.name,
    brand: store.brand.name,
    logo: (store.brand.name ?? 'raleys').replace(/[^A-Z0-9]/gi, '').toLowerCase(),
    address: {
      address1: store!.address.street,
      city: store!.address.city,
      state: store!.address.state,
      postalCode: store!.address.zip,
      country: 'US',
      companyName: store!.name,
      phone: formatPhone(store!.phone) ?? '',
      email: store!.email,
      addressType: 'business' as 'business' | 'home',
      isValidated: true,
    },
  };
};

export const getQuantityTotal = (obj: Cart | Order, defaultValue?: number): number | undefined => {
  if (!obj) {
    return defaultValue;
  }
  return invidLineItems(obj.lineItems).reduce((qty, li) => {
    return qty + li.quantity;
  }, 0);
};

export const getTaxTotal = (obj: Cart | Order, defaultValue?: string): string | undefined => {
  if (!obj) {
    return defaultValue;
  }
  return moneyValue(
    {
      ...defaultMoney,
      centAmount: obj.taxedPrice?.taxPortions.reduce((amt, tx) => amt + tx.amount.centAmount, 0) ?? 0,
    },
    1,
  );
};

export const getTaxRate = (obj?: Cart | Order, defaultValue?: string) => {
  if (!obj) {
    return defaultValue;
  }

  return obj.taxedPrice?.taxPortions.reduce((amt, tx) => amt + tx.rate * 100.0, 0).toFixed(2);
};

export const getTimeSlot = (obj: Order | Cart | LineItem | undefined, defaultValue?: TimeSlot): TimeSlot | undefined => {
  try {
    return JSON.parse(obj?.custom?.customFieldsRaw?.find((x) => x.name === 'timeSlot')?.value);
  } catch (e) {
    return defaultValue;
  }
};

export const getCTShippingAddressFromOrder = (order: BWOrder) => {
  if (!order || !order.shippingAddress) {
    return undefined;
  }
  let st = order.shippingAddress.state.toLowerCase();
  switch (st) {
    case 'nevada':
      st = 'NV';
      break;
    case 'arizona':
      st = 'AZ';
      break;
    case 'california':
    default:
      st = 'CA';
  }
  const sAddr = {
    firstName: order.shippingAddress.firstName,
    lastName: order.shippingAddress.lastName,
    address1: order.shippingAddress.address1,
    address2: order.shippingAddress.address2,
    city: order.shippingAddress.city ?? '',
    state: st,
    postalCode: order.shippingAddress.postalCode,
    company: order.shippingAddress.companyName,
    phone: order.shippingAddress.phone,
    country: 'US',
    email: order.shippingAddress.email,
    addressType: order.shippingAddress.addressType,
    isValidated: order.shippingAddress.isValidated,
    validatedDate: order.shippingAddress.validatedDate,
  } as CTAddress;
  return sAddr;
};

export const getShippingAddress = (obj: Order | Cart | undefined): CTAddress | undefined => {
  const addr = obj?.shippingAddress;
  if (!addr) {
    return undefined;
  }
  return {
    firstName: addr.firstName,
    lastName: addr.lastName,
    address1: addr.streetName ?? '',
    city: addr.city ?? '',
    state: addr.state,
    postalCode: addr.postalCode,
    company: addr.company ?? null,
    phone: addr.phone,
    country: addr.country,
    email: addr.email,
    addressType: getAddressType(obj),
    isValidated: getAddressValidated(obj),
    validatedDate: new Date(getAddressValidatedDate(obj)),
  } as CTAddress;
};

export const getAddressType = (obj: Order | Cart | undefined): string => {
  try {
    return obj?.shippingAddress?.custom?.customFieldsRaw?.find((x) => x.name === 'addressType')?.value;
  } catch (e) {
    return 'home';
  }
};

export const getAddressValidated = (obj: Order | Cart | undefined): boolean => {
  try {
    return obj?.shippingAddress?.custom?.customFieldsRaw?.find((x) => x.name === 'isValidated')?.value;
  } catch (e) {
    return false;
  }
};

export const getAddressValidatedDate = (obj: Order | Cart | undefined): string => {
  try {
    return obj?.shippingAddress?.custom?.customFieldsRaw?.find((x) => x.name === 'validatedDate')?.value;
  } catch (e) {
    return '';
  }
};

export const getCustomerEmail = (obj: Order | Cart | undefined, defaultValue?: string): string | undefined => {
  try {
    return (JSON.parse(obj?.custom?.customFieldsRaw?.find((x) => x.name === 'customerContact')?.value) as CustomerContact).email ?? defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const getFulfilmentStore = (obj: Order | Cart | undefined, defaultValue?: FulfillmentStore): FulfillmentStore | undefined => {
  try {
    return JSON.parse(getCustomFieldValue('fulfillmentStore', obj?.custom?.customFieldsRaw ?? []));
  } catch (e) {
    return defaultValue;
  }
};

export const getServiceFeeAttribute = (obj: Order | Cart | LineItem | undefined, defaultValue?: string): string | undefined => {
  if (!obj) {
    return defaultValue;
  }
  return obj.custom?.customFieldsRaw?.find((x) => x.name === 'serviceFee')?.value ?? defaultValue;
};

export const getServiceFee = (obj: Order | Cart | undefined, defaultValue: string = 'FREE'): string | undefined => {
  if (!obj) {
    return defaultValue;
  }

  const serviceFee = (obj.customLineItems ?? []).find((i) => i.slug === 'service-fee');
  return serviceFee && typeof serviceFee.totalPrice !== 'undefined'
    ? serviceFee.totalPrice.centAmount <= 0
      ? 'FREE'
      : moneyValue(serviceFee.totalPrice)
    : defaultValue;
};

export const getDeliveryFeeAttribute = (obj: Order | Cart | LineItem | undefined, defaultValue?: string): string | undefined => {
  if (!obj) {
    return defaultValue;
  }
  return obj.custom?.customFieldsRaw?.find((x) => x.name === 'deliveryFee')?.value ?? defaultValue;
};

export const getDeliveryFee = (obj: Order | Cart | undefined, defaultValue: string = 'FREE'): string | undefined => {
  if (!obj) {
    return defaultValue;
  }

  const deliveryFee = (obj.customLineItems ?? []).find((i) => i.slug === 'delivery-fee');
  return deliveryFee && typeof deliveryFee.totalPrice !== 'undefined'
    ? deliveryFee.totalPrice.centAmount <= 0
      ? 'FREE'
      : moneyValue(deliveryFee.totalPrice)
    : defaultValue;
};

export const isDelivery = (obj: Order | Cart | undefined, defaultValue?: boolean): boolean | undefined => {
  return obj && obj.shippingInfo && obj.shippingInfo.shippingMethod && obj.shippingInfo.shippingMethod.key
    ? obj.shippingInfo.shippingMethod.key === 'delivery'
      ? true
      : false
    : defaultValue;
};

export const getShippingMethod = (obj: Order | Cart | undefined, defaultValue?: ShopType): ShopType | undefined => {
  return obj && obj.shippingInfo?.shippingMethod && obj.shippingInfo?.shippingMethod?.key
    ? obj.shippingInfo.shippingMethod.key === 'pickup'
      ? ShopType.PICKUP
      : obj.shippingInfo.shippingMethod.key === 'delivery'
        ? ShopType.DELIVERY
        : obj.shippingInfo.shippingMethod.key === 'in-store'
          ? ShopType.IN_STORE
          : defaultValue
    : defaultValue;
};

export const getFormattedTimeSlot = (obj: Order | Cart | LineItem | undefined, defaultValue?: string) => {
  const ts = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'timeSlot');
  if (!ts?.value) {
    return defaultValue;
  }
  const timeslotTime = JSON.parse(ts?.value)?.timeSlots?.[0] ?? null;
  const toTime = (tt: number) => {
    tt = !tt || isNaN(tt) ? Date.now() : tt;
    let h = tt % 12;
    h = h ? h : 12;
    return h + (tt >= 12 ? ':00 PM' : ':00 AM');
  };
  const timeSlotStartTime = toTime(timeslotTime ? timeslotTime.timeSlotHourStart : null);
  const timeSlotEndTime = toTime(timeslotTime ? timeslotTime.timeSlotHourEnd : null);

  return timeSlotStartTime ? `${timeSlotStartTime} - ${timeSlotEndTime}` : defaultValue;
};

export const getFulfillmentInstructions = (obj: Order | Cart | LineItem | undefined, defaultValue?: string) => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'fulfillmentInstruction')?.value ?? defaultValue;
};

export const setFulfillmentInstructions = (obj: Cart | Order | LineItem, value: string): void => {
  const f = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'fulfillmentInstruction');
  if (f) {
    f.value = value;
  }
};

export const getAllowSubstitution = (obj: Cart | Order | undefined, defaultValue?: boolean) => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'allowSubstitution')?.value ?? defaultValue;
};

export const setAllowSubstitution = (obj: Cart | undefined, value: boolean = true) => {
  const f = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'allowSubstitution');
  if (f) {
    f.value = value;
  }
};

export const getSubstitutionType = (obj: LineItem | undefined, defaultValue?: SubstitutionType) => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'substitutionType')?.value ?? defaultValue;
};

export const setSubstitutionType = (obj: LineItem, value: SubstitutionType): void => {
  const f = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'substitutionType');
  if (f) {
    f.value = value;
  }
};

export const getSubstitutionItems = (obj: LineItem, defaultValue: String[] = []): string[] => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'substitutionItems')?.value ?? defaultValue;
};

export const setSubstitutionItems = (obj: LineItem, value: String[]): void => {
  const f = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'substitutionItems');
  if (f) {
    f.value = value;
  }
};

export const getOriginalOrderNumber = (obj: Cart | Order | undefined, defaultValue?: string) => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'OriginalOrderNumber')?.value ?? defaultValue;
};

export const getOriginalOrderStoreNumber = (obj: Cart | Order | undefined, defaultValue?: string) => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'OriginalStore')?.value ?? defaultValue;
};

export const setOriginalOrderNumber = (obj: Cart | undefined, value?: string) => {
  const f = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'OriginalOrderNumber');
  if (f) {
    f.value = value;
  }
};

export const getPickupPersonName = (obj: Order | Cart | LineItem | undefined, defaultValue?: string) => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'pickupPersonName')?.value ?? defaultValue;
};

export const setPickupPersonName = (obj: Cart | Order, value: string): void => {
  const f = obj?.custom?.customFieldsRaw?.find((x) => x.name === 'pickupPersonName');
  if (f) {
    f.value = value;
  }
};

export const getCartCustomerContact = (obj: Order | Cart | LineItem | undefined): CustomerContact | undefined => {
  try {
    return JSON.parse(obj?.custom?.customFieldsRaw?.find((x) => x.name === 'customerContact')?.value) as CustomerContact;
  } catch (e) {
    return undefined;
  }
};

export const getPromotions = (obj: Order | Cart | LineItem | undefined, defaultValue?: string) => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'promotions')?.value ?? defaultValue;
};

export const getAttributeName = (item?: ProductVariant, defaultValue?: string) => {
  return item ? item.attributesRaw.find((x) => x.name === 'name')?.value ?? defaultValue : defaultValue;
};

export const invidLineItems = (lis: LineItem[]): LineItem[] => {
  return lis.filter(
    (li) => !li.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId'),
    // (li.productType?.name === ProductType.STANDARD && !li.custom?.customFieldsRaw?.find((x) => x.name === 'parentLineItemId')) ||
    // li.productType?.name === ProductType.CONFIGURABLE,
  );
};

export const getOrderQuantity = (obj: Cart | Order | undefined | null): Number => {
  if (obj) {
    return invidLineItems(obj.lineItems).reduce((qty, li) => qty + li.quantity, 0);
  }
  return 0;
};

export const lineItemChildren = (lies: LineItem[] | undefined, liId: string): LineItem[] => {
  if (!lies) {
    return [];
  }
  return lies.filter(
    (x) =>
      x.id !== liId &&
      (x.productType?.name === ProductType.NON_SELLABLE || x.productType?.name === ProductType.STANDARD) &&
      (x.custom?.customFieldsRaw ?? []).findIndex((y: RawCustomField) => y.name === 'parentLineItemId' && y.value === liId) > -1,
  );
};

export const getCartEndAvailabilityDate = (obj?: Cart): dayjs.Dayjs | undefined => {
  if (!obj) {
    return undefined;
  }

  const maxEndDate: dayjs.Dayjs | undefined = invidLineItems(obj.lineItems).reduce((maxDate: dayjs.Dayjs | undefined, p: LineItem) => {
    const tmp = getEndAvailabilityDate(p);
    if (tmp && maxDate) {
      return tmp.diff(maxDate as dayjs.Dayjs, 'minutes') <= 0 ? tmp : maxDate;
    } else if (tmp && !maxDate) {
      return tmp;
    }
    return maxDate;
  }, undefined);

  return maxEndDate;
};

export const getCartLeadTimeMinutes = (obj?: Cart): number => {
  if (!obj) {
    return getfulfillmentLeadTimeMinutes();
  }

  const leadTime: number = invidLineItems(obj.lineItems).reduce((max, p?: LineItem) => {
    const tmp = getfulfillmentLeadTimeMinutes(p);
    return tmp > max ? tmp : max;
  }, 0);
  const selectedStore = useShopStore.getState().selectedStore;

  return Math.max(+(selectedStore?.fulfillmentLeadTimeMinutes ?? Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES), leadTime);
};

export const getLogoColors = (iconName?: IconName | string | undefined) => {
  if (!iconName) {
    return;
  }
  if (iconName?.includes('one')) {
    iconName = 'one';
  }

  var iconColors = {
    fill: 'none',
    fillSecondary: 'none',
    stroke: 'none',
    strokeSecondary: 'none',
  };

  switch (iconName) {
    case 'raleys':
      iconColors = {
        fill: colors.raleysRed,
        fillSecondary: 'none',
        stroke: 'none',
        strokeSecondary: 'none',
      };
      break;
    case 'one':
      iconColors = {
        fill: colors.raleysOneDarkGreen,
        fillSecondary: colors.raleysOneLightGreen,
        stroke: 'none',
        strokeSecondary: 'none',
      };
      break;
    case 'belair':
      iconColors = {
        fill: colors.belAirRed,
        fillSecondary: colors.belAirBrown,
        stroke: 'none',
        strokeSecondary: 'none',
      };
      break;
    case 'nobhillfoods':
      iconColors = {
        fill: colors.nobHillRed,
        fillSecondary: 'none',
        stroke: 'none',
        strokeSecondary: 'none',
      };
      break;
  }

  return iconColors;
};

export const getItemAttribute = (name: string, attributes: RawCustomField[]) => {
  return attributes.find((x) => x.name === name);
};

export const getItemAttributeValue = (name: string, attributes: RawCustomField[]) => getItemAttribute(name, attributes)?.value;

export const getVariantAttribute = (name: string, attributes: RawProductAttribute[]) => {
  return attributes.find((x) => x.name === name);
};

export const getVariantAttributeValue = (name: string, attributes: RawProductAttribute[]) => getVariantAttribute(name, attributes)?.value;

export const getProductQuantityIndex = (productData: ProductData, qty: number, estimatedTotalWeight?: number) => {
  let sellType: { key: string; label: string } = productData.masterVariant.attributesRaw.find((f) => f.name === 'unitSellType')?.value ?? {
    key: 'byEach',
    label: 'By Each',
  };

  if (sellType.key && sellType.key === 'byWeight') {
    if (!estimatedTotalWeight) {
      return qty;
    }
    const unitBuyMinimum = (productData.masterVariant?.attributesRaw ?? []).find((attr) => attr.name === 'unitBuyMinimum')?.value ?? 1;
    const unitBuyIncrement = (productData.masterVariant?.attributesRaw ?? []).find((attr) => attr.name === 'unitBuyIncrement')?.value ?? 1;

    return estimatedTotalWeight ? Math.ceil((Math.max(unitBuyMinimum, estimatedTotalWeight) - unitBuyMinimum) / unitBuyIncrement) + 1 : 0;
  }

  return qty;
};

export const getProductQuantityValue = (productData: ProductData, index?: number) => {
  let sellType: { key: string; label: string } = productData.masterVariant.attributesRaw.find((f) => f.name === 'unitSellType')?.value ?? {
    key: 'byEach',
    label: 'By Each',
  };

  if (sellType.key && sellType.key === 'byWeight') {
    const unitBuyMinimum = (productData.masterVariant?.attributesRaw ?? []).find((attr) => attr.name === 'unitBuyMinimum')?.value ?? 1;
    const unitBuyIncrement = (productData.masterVariant?.attributesRaw ?? []).find((attr) => attr.name === 'unitBuyIncrement')?.value ?? 1;

    return index ?? 0 ? unitBuyIncrement * --index! + unitBuyMinimum : 0;
  }

  return index;
};

export const getProductVariantQuantityIndex = (productVariant: ProductVariant, qty: number, estimatedTotalWeight?: number) => {
  if (!productVariant) {
    return qty;
  }

  let sellType: { key: string; label: string } = productVariant.attributesRaw.find((f) => f.name === 'unitSellType')?.value ?? {
    key: 'byEach',
    label: 'By Each',
  };

  if (sellType.key && sellType.key === 'byWeight') {
    if (!estimatedTotalWeight) {
      return qty;
    }
    const unitBuyMinimum = (productVariant.attributesRaw ?? []).find((attr) => attr.name === 'unitBuyMinimum')?.value ?? 1;
    const unitBuyIncrement = (productVariant.attributesRaw ?? []).find((attr) => attr.name === 'unitBuyIncrement')?.value ?? 1;

    return estimatedTotalWeight ? Math.ceil((Math.max(unitBuyMinimum, estimatedTotalWeight) - unitBuyMinimum) / unitBuyIncrement) + 1 : 0;
  }

  return qty;
};

export const getProductVariantQuantityValue = (productVariant: ProductVariant, index?: number) => {
  if (!productVariant) {
    return index;
  }

  let sellType: { key: string; label: string } = productVariant.attributesRaw.find((f) => f.name === 'unitSellType')?.value ?? {
    key: 'byEach',
    label: 'By Each',
  };

  if (sellType.key && sellType.key === 'byWeight') {
    const unitBuyMinimum = (productVariant.attributesRaw ?? []).find((attr) => attr.name === 'unitBuyMinimum')?.value ?? 1;
    const unitBuyIncrement = (productVariant.attributesRaw ?? []).find((attr) => attr.name === 'unitBuyIncrement')?.value ?? 1;

    return index ?? 0 ? unitBuyIncrement * --index! + unitBuyMinimum : 0;
  }

  return index;
};

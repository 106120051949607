import CustomizeHeader from '@components/CustomizeHeader';
import { StackHeaderProps, createStackNavigator } from '@react-navigation/stack';
import { OrderSummaryScreen } from '@screens/cartAndCheckout';
import {
  BasicSelectionScreen,
  ContainerScreen,
  FillingScreen,
  InitialCustomizeScreen,
  MessageScreen,
  NotesScreen,
  ReviewScreen,
  SelectionScreen,
  SizeScreen,
  ThemeScreen,
} from '@screens/customizeProduct';
import React from 'react';
import { CheckoutStackParamList, CheckoutStackRoutes, CustomizeStackParamList, CustomizeStackRoutes } from './routes';

const CustomizeStack = createStackNavigator<CustomizeStackParamList & CheckoutStackParamList>();

const CustomizeStackNavigator = () => {
  const renderHeader = ({ route }: StackHeaderProps) => (
    <CustomizeHeader forwardNavEnabled={route?.params && 'forwardNavEnabled' in route?.params ? (route?.params.forwardNavEnabled as boolean) : false} />
  );
  return (
    <CustomizeStack.Navigator screenOptions={{ gestureEnabled: false, header: renderHeader, headerMode: 'float' }}>
      <CustomizeStack.Screen options={{ headerShown: false }} name={CustomizeStackRoutes.Initial} component={InitialCustomizeScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Size} component={SizeScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Selection} component={SelectionScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Container} component={ContainerScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Notes} component={NotesScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Review} component={ReviewScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.BasicSelection} component={BasicSelectionScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Theme} component={ThemeScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Message} component={MessageScreen} />
      <CustomizeStack.Screen name={CustomizeStackRoutes.Filling} component={FillingScreen} />
      <CustomizeStack.Screen name={CheckoutStackRoutes.OrderSummary} component={OrderSummaryScreen} options={{ headerShown: false }} />
    </CustomizeStack.Navigator>
  );
};

export default CustomizeStackNavigator;

import { HtmlView, NavigationHeader, Screen } from '@components';
import Text from '@components/Text';
import colors from '@config/colors';
import { AppStackRoutes, HelpCenterStackRoutes, HelpCenterStackScreenProps } from '@navigation/routes';
import appStyles from '@styles/appStyles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import React from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ChatLiveAgentIcon } from './HelpCenterScreen';

type HelpTopicsDetailScreenProps = HelpCenterStackScreenProps<HelpCenterStackRoutes.HelpTopicsDetailScreen>;
const HelpTopicsDetailScreen = ({ route, navigation }: HelpTopicsDetailScreenProps) => {
  const { question, answer } = route?.params;
  const insets = useSafeAreaInsets();

  const onButtonPress = () => {
    navigation.navigate(AppStackRoutes.LiveAgent);
  };

  return (
    <Screen style={utilityStyles.pb0}>
      <NavigationHeader next={<ChatLiveAgentIcon onPress={onButtonPress} />} subStyle={[styles.navSubHeader, utilityStyles.pt2]} style={{}} />
      <ScrollView contentContainerStyle={[appStyles.container, styles.paddingHorizantal, { paddingBottom: insets.bottom }]}>
        <View style={styles.queAnsView}>
          <Text style={[appStyles.fontMobileListSmallLeft, styles.questionText]} testID="question" allowFontScaling={true} maxFontSizeMultiplier={4}>
            {question}
          </Text>

          <HtmlView text={answer ?? ''} textAlign="left" lineHeight={25} />
        </View>
      </ScrollView>
    </Screen>
  );
};

const styles = StyleSheet.create({
  navSubHeader: {
    alignItems: 'flex-start',
    minHeight: screenHeight * 0.06,
  },
  queAnsView: { marginTop: 14 },
  paddingHorizantal: {
    paddingHorizontal: 20,
  },
  questionText: { marginVertical: 10 },
  subView: {
    lineHeight: lineHeight(25),
    marginTop: 10,
  },
  bottomButton: {
    borderWidth: 1,
    height: 32,
    minWidth: 130,
    maxWidth: 350,
  },
  textStyle: {
    fontSize: scale(14),
    lineHeight: Platform.select({
      android: lineHeight(14.2),
    }),
    height: scale(16),
  },
  bottomBarView: {
    backgroundColor: colors.cream,
    height: screenHeight > 744 ? screenHeight * 0.1 : screenHeight * 0.12,
    width: screenWidth,
  },
  helpTextView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  bottomBtnView: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: 5,
  },
});

export default HelpTopicsDetailScreen;

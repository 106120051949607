import { Accordion, Icon, LinkButton, NavigationHeader, Screen } from '@components';
import { IconName } from '@components/Icon';
import Text from '@components/Text';
import Invoice from '@components/cart/Invoice';
import ItemizedPurchase from '@components/cart/ItemizedPurchase';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { FulfillmentStore, Order } from '@fieldera-raleys/client-commercetools/schema';
import { PaymentProfile } from '@fieldera-raleys/client-common/services/brandywine/types';
import { AccountStackRoutes, AppStackRoutes, CheckoutStackRoutes, CheckoutStackScreenProps, OrderHistoryStackRoutes, RootTabRoutes } from '@navigation/routes';
import { userService } from '@services/brandywine';
import { Me } from '@services/commerceTools';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenWidth } from '@styles/constants';
import dayjs from 'dayjs';
import * as Sharing from 'expo-sharing';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import ViewShot, { releaseCapture } from 'react-native-view-shot';
import logger from '../../utils/logger';
import {
  getAddressType,
  getCustomerEmail,
  getFormattedTimeSlot,
  getFulfillmentInstructions,
  getFulfilmentStore,
  getLogoColors,
  getOrderNote,
  getOrderNumber,
  getPickupPersonName,
  getSelectedPayment,
  getTimeSlot,
  isDelivery,
} from '../../utils/orderHelpers';

type OrderCompleteScreenProps = CheckoutStackScreenProps<CheckoutStackRoutes.OrderComplete>;
const OrderCompleteScreen = ({ navigation, route }: OrderCompleteScreenProps) => {
  const scrollViewRef = useRef<ScrollView>(null);
  const viewShotRef = useRef<ViewShot>(null);
  const { t } = useTranslation('cart');
  const [orderData, setOrderData] = useState<Order | undefined>(undefined);
  const [dropDownState, setDropDownState] = useState<{ items: boolean; invoice: boolean }>({
    items: false,
    invoice: false,
  });
  const [paymentMethod, setPaymentMethod] = useState<PaymentProfile | undefined>(undefined);
  const handleHelpLink = () => {
    navigation.push(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Account,
      params: {
        screen: AccountStackRoutes.ContactCustomerService,
        params: { calledFrom: orderId },
      },
    });
    return false;
  };

  const { orderId, offlinePaymentMethod } = route.params;
  const orderNumber = getOrderNumber(orderData, t('unavailable'));
  // const orderDate = getOrderDate(orderData, t('unavailable'));
  const orderDate = orderData && orderData.createdAt ? dayjs(orderData.createdAt).format('MMM DD, YYYY').toUpperCase() : 'Unavailable';

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        var res = await Me.orderService.getOrderById(orderId);
        setOrderData(res);
      } catch (err) {
        logger.error(err);
      }
    };
    fetchOrder();
  }, [orderId]);

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      try {
        const customerPaymentMethodId = getSelectedPayment(orderData);
        if (customerPaymentMethodId) {
          const paymentMethodData = await userService.getPaymentProfileByPaymentMethodId(customerPaymentMethodId);
          setPaymentMethod(paymentMethodData);
        }
      } catch (err) {
        logger.error(err);
      }
    };
    fetchPaymentMethod();
  }, [orderData]);

  const doneWithThisOrder = () => {
    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Account,
      params: {
        screen: AccountStackRoutes.OrderHistory,
        params: {
          screen: OrderHistoryStackRoutes.RecentOrders,
          params: { clearFilter: true, useRefetch: true },
        },
      },
    });
    return false;
  };

  let key = Date.now();
  let shareOrder = <Icon size={25} style={appStyles.mediumIcon} fill={colors.dark} name={'action-up'} />;
  const onShareOrder = async () => {
    try {
      if (viewShotRef?.current?.capture) {
        viewShotRef.current.capture().then(
          async (val) => {
            let url = Platform.select({ ios: 'file://' + val, android: val, web: val })!;

            if (!(await Sharing.isAvailableAsync())) {
              logger.debug('Your device does not support sharing', { method: 'onShareCart' });
              return;
            }

            const shareOptions = {
              mimeType: 'image/png',
              UTI: 'public.png',
              dialogTitle: 'Share Order Receipt', // android only
            };

            await Sharing.shareAsync(url, shareOptions);

            releaseCapture(val);
          },
          (error) => logger.error(error, { method: 'onShareCart' }),
        );
      }
    } catch (e) {
      logger.error(`Share Order exception ${e}`);
    }
  };

  const OrderNote = useCallback((): JSX.Element | null => {
    const orderNote = getOrderNote(orderData);
    return orderNote ? (
      <View style={styles.OrderNoteContainer}>
        <View style={{ flexDirection: 'column', width: containerWidth, alignSelf: 'center' }}>
          <View style={{ flexDirection: 'column', width: containerWidth }}>
            <Text style={[appStyles.body, appStyles.bodySmallRegular]}>{t('orderNote')}</Text>
            <View style={styles.notePad}>
              <Text style={appStyles.bodySmallRegular} testID="orderNote">
                {orderNote}
              </Text>
            </View>
          </View>
        </View>
      </View>
    ) : null;
  }, [orderData, t]);

  // Location
  const ts = getFormattedTimeSlot(orderData, 'Unavailable');
  const renderLocation = () => {
    let obj = orderData;
    const dateSlot = getTimeSlot(obj)?.timeSlotDate;
    const a = obj?.shippingAddress;
    // var monthOptions: Intl.DateTimeFormatOptions = { month: 'short' };
    // var dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' };
    let slotDate = t('unavailable');
    if (dateSlot) {
      try {
        // const dateTime = new Date(dateSlot);
        // const day = new Intl.DateTimeFormat('en-US', dayOptions).format(dateTime);
        // const dt = dateTime.getDate();
        // const month = new Intl.DateTimeFormat('en-US', monthOptions).format(dateTime);
        // slotDate = `${day}. ${month} ${dt}`;

        const dateTime = dayjs(dateSlot);
        const day = dateTime.format('ddd');
        const month = dateTime.format('MMM');
        const dt = dateTime.date();
        slotDate = `${day}. ${month} ${dt}`;
      } catch (e) {
        /* */
      }
    }
    const storeInfo: FulfillmentStore | undefined = getFulfilmentStore(orderData);
    const logoColors = getLogoColors(storeInfo?.logo.includes('one') ? 'raleysonemarket' : storeInfo?.logo);
    const addressType = getAddressType(obj);
    return [
      <View key={'oc_timeSlot_View1'} style={[]}>
        <Text style={[appStyles.h5, styles.textAlignCenter]} testID="slotDate">
          {slotDate}
        </Text>
        <Text style={[appStyles.h5, styles.textAlignCenter]} testID="timeSlot">
          {ts}
        </Text>
      </View>,
      <View key={'oc_timeSlot_View2'} style={[styles.infoPadSeparator, { margin: 10 }]} />,
      <View key={'oc_timeSlot_View3'} style={[styles.location]}>
        <View style={styles.iconWrapper}>
          {isDelivery(obj) ? (
            <Icon
              name={addressType === 'home' ? 'home-icon' : 'office-icon'}
              size={50}
              fill={colors.darkCream}
              stroke={colors.darkCream}
              style={styles.homeIcon}
            />
          ) : (
            <Icon
              name={storeInfo?.logo.includes('one') ? ('raleysonemarket' as IconName) : (`${storeInfo?.logo}` as IconName)}
              size={70}
              fill={logoColors?.fill || 'none'}
              fillSecondary={logoColors?.fillSecondary || 'none'}
              stroke={logoColors?.stroke || 'none'}
              style={styles.homeIcon}
            />
          )}
        </View>
        <View style={styles.locationInfo}>
          {isDelivery(obj) && (
            <Text numberOfLines={1} style={styles.locationText} testID="userNameInfo">
              {a?.firstName || a?.lastName ? `${a?.firstName ?? ''} ${a?.lastName ?? ''}` : t('unavailable')}
            </Text>
          )}
          {addressType === 'business' && a?.company ? (
            <Text numberOfLines={1} style={styles.locationText} testID="companyInfo">
              {a.company}
            </Text>
          ) : null}
          <Text numberOfLines={1} style={styles.locationText} testID="streetName">
            {a?.streetName ?? ''}
          </Text>
          <Text numberOfLines={1} style={styles.locationText} testID="cityInfo">
            {a?.city && a?.state && a.postalCode ? [a.city, ', ', a.state, ' ', a.postalCode].join('') : ''}
          </Text>
          <Text numberOfLines={1} style={[styles.locationText, { fontFamily: FontFamily.LarsseitLight }]} testID="phone">
            {a?.phone ?? ''}
          </Text>
        </View>
      </View>,
    ];
  };

  return (
    <Screen>
      <NavigationHeader iconProps={{ name: 'x-close' }} next={shareOrder} onNext={() => onShareOrder()} onPress={() => doneWithThisOrder()}>
        <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.h3B, utilityStyles.my3]} testID="weReceivedOrder">
          {t('weReceivedOrder')}
        </Text>
      </NavigationHeader>

      <View style={[styles.flex1, styles.flexrow]}>
        <ScrollView key={'checkoutScrollViewKey'} ref={scrollViewRef}>
          <ViewShot key={'main_sub_scroll' + ++key} ref={viewShotRef} style={{ ...styles.captureView, ...utilityStyles.my4 }}>
            <Text style={[appStyles.h5, styles.textAlignCenter, { marginTop: 10 }]} testID="orderThankYouTxt">
              {t('orderThankYou')}
            </Text>
            <Text style={[appStyles.h5L, styles.textAlignCenter, { marginTop: 10 }]} testID="orderEmailReceipt">
              {t('orderEmailReceipt', { email: getCustomerEmail(orderData, 'you') })}
            </Text>
            <View style={[styles.infoPadLight, utilityStyles.my3]}>
              <View style={{ flex: 1, alignSelf: 'center', width: '70%' }}>
                <Text style={[appStyles.bodySmallRegular, styles.textAlignCenter, { marginTop: 6 }]} testID="earnPtsText">
                  You’re earning Something Extra Points on this order!
                </Text>
              </View>
            </View>

            <View style={utilityStyles.my3}>
              <Text style={[appStyles.bodySmallRegular, styles.itemFrame]} testID="shippingDateTime">
                {isDelivery(orderData) ? t('deliveryDateTime') : t('pickupDateTime')}
              </Text>
              <View style={[styles.infoPad]}>{renderLocation()}</View>
            </View>
            {isDelivery(orderData) ? (
              <View style={utilityStyles.my3}>
                <View key={'oc_deliveryInstructions'}>
                  <Text style={[appStyles.bodySmallRegular, styles.itemFrame]} testID="deliveryInstructions">
                    {t('deliveryInstructions')}
                  </Text>
                  <View style={[styles.infoPadLight]}>
                    <Text style={[appStyles.bodySmallRegular]} testID="fulfillmentInstructions">
                      {getFulfillmentInstructions(orderData, '')}
                    </Text>
                  </View>
                </View>
                {/* <View key={'oc_fulfilmentStore'} style={utilityStyles.my3}>
                  <Text style={[appStyles.bodySmallRegular, styles.itemFrame]}>{t('deliveryFulfilmentStore')}</Text>
                  <View style={[styles.infoPadLight]}>{renderFulfilmentLocation()}</View>
                </View> */}
              </View>
            ) : (
              [
                <View key={'oc_pickupPersonName'} style={utilityStyles.my3}>
                  <View key={'oc_pickupPersonLabel'} style={[styles.itemFrame]}>
                    <Text style={[appStyles.bodySmallRegular]} testID="designatedPickupPerson">
                      {t('designatedPickupPerson')}
                    </Text>
                  </View>
                  <View style={[styles.infoPadLight]}>
                    <Text style={[appStyles.bodySmallRegular]} testID="pickupPersonName">
                      {getPickupPersonName(orderData)}
                    </Text>
                  </View>
                </View>,
                <View key={'oc_pickupInstructions'} style={utilityStyles.my3}>
                  <View style={[styles.itemFrame]}>
                    <Text style={[appStyles.bodySmallRegular]} testID="pickupInstructions">
                      {t('pickupInstructions')}
                    </Text>
                  </View>
                  <View style={[styles.infoPadLight]}>
                    <Text style={[appStyles.bodySmallRegular]} testID="pickupFulfillmentInstructions">
                      {getFulfillmentInstructions(orderData, '')}
                    </Text>
                  </View>
                </View>,
              ]
            )}

            {/* Order Number and Date */}
            <View style={utilityStyles.my3}>
              <View style={[styles.flexrow, styles.itemFrame]}>
                <Text style={[appStyles.bodySmall]} testID="orderNumberText">
                  Order Number
                </Text>
                <Text style={[appStyles.bodySmall, styles.marginLeftAuto]} testID="orderDateText">
                  Order Date
                </Text>
              </View>
              <View style={[styles.flexrow, styles.itemFrame]}>
                <Text style={[appStyles.bodySmallBold]} testID="orderNumberValue">
                  {orderNumber}
                </Text>
                <Text style={[appStyles.bodySmall, styles.marginLeftAuto]} testID="orderDateValue">
                  {orderDate}
                </Text>
              </View>
            </View>
            <Accordion
              iconStyle={appStyles.largeIcon}
              setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, items: isOpen })}
              title={t('invoiceItems')}
              isOpen={dropDownState.items}>
              <View style={[{ marginTop: 1 }]} />
              <ItemizedPurchase order={orderData} />
            </Accordion>
            <Accordion
              iconStyle={appStyles.largeIcon}
              setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, invoice: isOpen })}
              title={t('invoiceTotals')}
              isOpen={dropDownState.invoice}>
              <Invoice obj={orderData} title={'Subtotal'} selectedPaymentMethod={paymentMethod} offlinePaymentMethod={t(offlinePaymentMethod ?? '')} />
            </Accordion>
            <View style={[utilityStyles.my3]}>{OrderNote && OrderNote()}</View>
            <View style={[utilityStyles.my1]}>
              <TouchableOpacity style={styles.infoPad} onPress={() => handleHelpLink()}>
                <TouchableOpacity style={{ alignSelf: 'center', marginBottom: 2 }} onPress={() => handleHelpLink()}>
                  <Text style={[appStyles.bodyLeftBold]} testID="needHelpText">
                    {' '}
                    Need Help With This Order?
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ alignSelf: 'center' }} onPress={handleHelpLink}>
                  <LinkButton onPress={handleHelpLink} style={[appStyles.bodySmallRed, utilityStyles.my1, { textAlign: 'center' }]} testID="letsConnectText">
                    Let's Connect
                  </LinkButton>
                </TouchableOpacity>
              </TouchableOpacity>
            </View>
          </ViewShot>
        </ScrollView>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  vPadding: {
    marginTop: 7,
    alignSelf: 'center',
    width: containerWidth,
  },
  flex1: { flex: 1 },
  flex0: { flex: 0 },
  flexrow: { flexDirection: 'row' },
  flexcolumn: { flexDirection: 'column' },
  marginLeftAuto: { marginLeft: 'auto' },
  captureView: { backgroundColor: colors.cream, opacity: 1 },
  textAlignCenter: { textAlign: 'center' },
  productList: {
    margin: 18,
    paddingBottom: 60,
  },
  productFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
  },
  itemFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
    alignSelf: 'center',
  },
  infoPad: {
    backgroundColor: colors.white,
    padding: scale(18),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.dark,
    borderRadius: 7,
    alignSelf: 'center',
    minHeight: scale(90),
  },
  infoPadLight: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderRadius: 7,
    borderColor: colors.darkCream,
    alignSelf: 'center',
  },
  biggerHitArea: {
    width: 32,
    height: 32,
    paddingTop: 0,
    alignItems: 'flex-end',
    position: 'absolute',
    right: 0,
  },
  infoPadSeparator: {
    height: 1,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'stretch',
    margin: 20,
  },
  headSeparator: {
    height: 1,
    backgroundColor: colors.dark,
    marginTop: 15,
  },
  separator: {
    flex: 1,
    height: 1,
    backgroundColor: colors.medium,
    marginTop: 10,
    marginBottom: 10,
  },
  subSeparator: {
    flex: 1,
    height: 0.33,
    marginBottom: 2,
    marginTop: 2,
    backgroundColor: colors.medium,
  },
  price: {
    textAlign: 'right',
    flex: 0.2,
    marginRight: 20,
  },
  subtotal: {
    textAlign: 'right',
    borderRightWidth: 1,
    borderRightColor: colors.white,
    flex: 0.3,
  },
  unit: {
    flex: 0.65,
  },
  unitSeparator: {
    width: 0.33,
    marginRight: 5,
    backgroundColor: colors.medium,
  },
  subtotalsUnit: {
    flex: 0.75,
  },
  subtotalsPrice: { flex: 0.25, textAlign: 'right' },
  location: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  locationAddress: {
    alignItems: 'flex-start',
    flex: 1,
  },
  locationInfo: {
    paddingRight: 6,
    width: '60%',
  },
  locationText: {
    fontSize: scale(16),
    color: colors.darkText,
    lineHeight: lineHeight(18),
    fontFamily: FontFamily.LarsseitBold,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    paddingRight: 10,
  },
  homeIcon: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  OrderNoteContainer: {
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  notePad: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    marginBottom: 10,
    borderRadius: 7,
  },
});

export default OrderCompleteScreen;

import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { LoadingScreen, NavigationHeader, Screen } from '@components';
import { mapping } from '@components/bloomreach/mapping';
import { useBrxmPage } from '@hooks';
import { AppStackRoutes, HelpCenterStackRoutes, HelpCenterStackScreenProps } from '@navigation/routes';
import { screenHeight } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import React, { useState } from 'react';
import { RefreshControl, ScrollView, StyleSheet } from 'react-native';
import { ChatLiveAgentIcon } from './HelpCenterScreen';

type HelpTopicsListProps = HelpCenterStackScreenProps<HelpCenterStackRoutes.HelpTopicsList>;

const HelpTopicsListScreen = ({ navigation }: HelpTopicsListProps) => {
  const { brxmConfiguration, brxmPage, reloadPageAsync } = useBrxmPage();
  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = async () => {
    setRefreshing(true);
    await reloadPageAsync();
    setRefreshing(false);
  };

  const onButtonPress = () => {
    navigation.navigate(AppStackRoutes.LiveAgent);
  };

  return (
    <>
      {!brxmPage || !brxmConfiguration ? (
        <LoadingScreen />
      ) : (
        // @ts-ignore
        <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
          <Screen style={utilityStyles.pb0}>
            <NavigationHeader next={<ChatLiveAgentIcon onPress={onButtonPress} />} subStyle={[styles.navSubHeader, utilityStyles.pt2]} style={{}} />
            <ScrollView
              contentContainerStyle={styles.contentStyle}
              nestedScrollEnabled={true}
              bounces={true}
              keyboardShouldPersistTaps={'always'}
              showsVerticalScrollIndicator={false}
              refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}>
              <BrComponent path="body" />
            </ScrollView>
          </Screen>
        </BrPage>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  contentStyle: {
    height: 'auto',
    paddingBottom: 75,
  },
  navSubHeader: {
    alignItems: 'flex-start',
    minHeight: screenHeight * 0.064,
  },
});

export default HelpTopicsListScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { appStyles } from '@styles';
import { lineHeight } from '@styles/constants';
import React, { FC, useEffect, useState } from 'react';
import { StyleProp, StyleSheet, TextProps, View, ViewStyle } from 'react-native';
import truncate from 'truncate-html';
import HtmlView from './HtmlView';

interface HtmlViewTextBoxProps {
  text: string;
  fontSize?: number;
  textAlign?: string;
  color?: string;
  fontFamily?: string | FontFamily;
  textHeight?: number;
  boxStyle?: StyleProp<ViewStyle>;
  numberOfChars?: number;
}

const HtmlViewTextBox: FC<HtmlViewTextBoxProps & Pick<TextProps, 'allowFontScaling' | 'maxFontSizeMultiplier'>> = ({
  text,
  fontSize,
  textAlign = 'left',
  color = colors.text,
  fontFamily = FontFamily.LarsseitLight,
  textHeight = lineHeight(20),
  boxStyle,
  numberOfChars = 200,
  allowFontScaling = false,
  maxFontSizeMultiplier = 1,
}) => {
  const [textShown, setTextShown] = useState(false);
  const [showLink, setShowLink] = useState(false);

  truncate.setup({ stripTags: true, decodeEntities: true, reserveLastWord: false });
  const descriptionContent = truncate(text, 5000);
  const descriptionContentPartial = truncate(text, 200);
  truncate.setup({ stripTags: false, decodeEntities: true, reserveLastWord: false });
  const descriptionContentFull = truncate(text, 5000);

  const toggleNumberOfChars = () => {
    setTextShown(!textShown);
  };

  useEffect(() => {
    var numChars = descriptionContent.length;
    if (numChars > numberOfChars) {
      setShowLink(true);
    } else {
      setShowLink(false);
    }
  }, [descriptionContent.length, numberOfChars]);

  return (
    <View style={boxStyle}>
      <HtmlView
        text={textShown ? descriptionContentFull ?? '' : descriptionContentPartial ?? ''}
        fontFamily={fontFamily}
        textAlign={textAlign}
        lineHeight={textHeight}
        textSize={fontSize}
        textColor={color}
      />
      {showLink ? (
        <Text
          style={[appStyles.fontShowMoreLink, styles.link]}
          onPress={toggleNumberOfChars}
          allowFontScaling={allowFontScaling}
          maxFontSizeMultiplier={maxFontSizeMultiplier}>
          {textShown ? 'Show Less' : 'Show More'}
        </Text>
      ) : (
        <></>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  link: {
    lineHeight: lineHeight(18),
  },
});

export default HtmlViewTextBox;

import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { Button, HeaderTitle, Icon, LoadingScreen, NavigationHeader, Screen, TextInput } from '@components';
import Text from '@components/Text';
import { mapping } from '@components/bloomreach/mapping';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { useBrxmPage } from '@hooks';
import { AccountStackRoutes, AppStackRoutes, HelpCenterStackRoutes, HelpCenterStackScreenProps, RewardsStackRoutes, RootTabRoutes } from '@navigation/routes';
import { StackActions } from '@react-navigation/native';
import { appStyles, utilityStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../../contexts';

interface ISearchItemProps {
  searchText: string | undefined;
  setSearchText: (text: string) => void;
}

export const HelpCenterSearchBar: FC<ISearchItemProps> = ({ searchText, setSearchText }): JSX.Element => {
  const { t } = useTranslation('helpCenter');
  const { showAlertModal } = useCustomModalContext();
  return (
    <View style={styles.searchBarWrapper}>
      <TouchableOpacity style={styles.iconWrapper} testID="searchIcon">
        <Icon name="search-icon" size={22} style={appStyles.icon} />
      </TouchableOpacity>
      <TextInput
        testID="helpCenterTextInput"
        style={[appStyles.formFieldText, styles.searchTextInput]}
        placeholder={t('placeholder')}
        onChangeText={setSearchText}
        onSubmitEditing={({ nativeEvent: { text } }) => {
          if (!text) {
            return;
          }
        }}
        onFocus={() => showAlertModal()}
        value={searchText}
        placeholderTextColor={Platform.select({
          android: colors.darkCream,
        })}
      />
    </View>
  );
};

interface IChatLiveAgentIconProps {
  onPress: () => void;
}

export const ChatLiveAgentIcon = ({ onPress }: IChatLiveAgentIconProps): JSX.Element => {
  return (
    <TouchableOpacity testID="contactClick" style={styles.contactIconView} onPress={onPress}>
      <Icon size={35} style={[appStyles.largeIcon]} stroke={colors.red} name={'contact-icon'} />
    </TouchableOpacity>
  );
};

type HelpCenterScreenProps = HelpCenterStackScreenProps<HelpCenterStackRoutes.HelpCenterScreen>;

const HelpCenterScreen = ({ navigation }: HelpCenterScreenProps) => {
  const { brxmConfiguration, brxmPage, reloadPageAsync } = useBrxmPage();
  const [refreshing, setRefreshing] = useState(false);
  const { t } = useTranslation('helpCenter');

  const renderHeader = () => {
    return (
      <View style={{ backgroundColor: colors.sectionPad }}>
        <View style={[styles.upperView]}>
          <View style={styles.getHelpView}>
            <Text style={[appStyles.bodyCenterBold]} testID="wantToContactUs">
              {t('wantToContactUs')}
            </Text>
            <Button
              testID="getHelp"
              title={t('getHelp')}
              type="secondary"
              onPress={() => {
                navigation.navigate(HelpCenterStackRoutes.ContactCustomerScreen, {});
              }}
              textStyle={[styles.getHelpBtnText]}
              buttonStyle={[styles.getHelpBtn]}
            />
          </View>
          <View style={styles.orderView}>
            <TouchableOpacity
              testID="whereMyOrderClick"
              style={styles.myOrderView}
              onPress={() => {
                navigation.dispatch(
                  StackActions.push(AppStackRoutes.RootTabs, {
                    screen: RootTabRoutes.Account,
                    params: { screen: AccountStackRoutes.OrderHistory, params: {} },
                  }),
                );
              }}>
              <Icon name="order-location" size={21} testID="orderLocation" stroke={'none'} />
              <Text style={[appStyles.fontMobileListSmallLeftRegular, styles.firstText]} testID="whereMyOrdersText">
                {t('whereMyOrders')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              testID="cancelModifyOrderClick"
              style={styles.myOrderView}
              onPress={() => {
                navigation.dispatch(
                  StackActions.push(AppStackRoutes.RootTabs, {
                    screen: RootTabRoutes.Account,
                    params: { screen: AccountStackRoutes.OrderHistory, params: {} },
                  }),
                );
              }}>
              <Icon stroke={'none'} name="cancel-modify-icon" size={21} testID="cancelModifyOrderIcon" />
              <Text style={[appStyles.fontMobileListSmallLeftRegular, styles.firstText]} testID="cancelModifyOrder">
                {t('CancelModifyOrder')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              testID="seOffersRewardsClick"
              style={[styles.myOrderView, styles.somethingStyle]}
              onPress={() => {
                navigation.dispatch(
                  StackActions.push(AppStackRoutes.RootTabs, {
                    screen: RootTabRoutes.Rewards,
                    params: { screen: RewardsStackRoutes.SEHome, params: {} },
                  }),
                );
              }}>
              <Icon name="se-icon-tiny-red" size={21} stroke={'none'} testID="sExtraOffersRewardsIcon" />
              <Text style={[appStyles.fontMobileListSmallLeftRegular, styles.firstText]} testID="sextraOffersRewardsText">
                {t('somthingExtraOffersRewards')}
              </Text>
            </TouchableOpacity>
          </View>
          {/* <HelpCenterSearchBar searchText={searchText} setSearchText={setSearchText} /> */}

          <View style={[styles.helpTopicsView]}>
            <Text style={[appStyles.fontMobileListSmallLeft, styles.helpTopicsText]} testID="helpTopics">
              {t('helpTopics')}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await reloadPageAsync();
    setRefreshing(false);
  };

  const onButtonPress = () => {
    navigation.navigate(AppStackRoutes.LiveAgent);
  };

  return (
    <>
      {!brxmPage || !brxmConfiguration ? (
        <LoadingScreen />
      ) : (
        // @ts-ignore
        <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
          <Screen style={utilityStyles.pb0}>
            <NavigationHeader
              subStyle={[styles.alignLeft, utilityStyles.pt2]}
              next={<ChatLiveAgentIcon onPress={onButtonPress} />}
              backIconstyle={{ marginLeft: scale(-6) }}>
              <HeaderTitle testID="helpCenterTitle">{t('helpCenter')}</HeaderTitle>
            </NavigationHeader>

            <ScrollView
              contentContainerStyle={styles.contentStyle}
              nestedScrollEnabled={true}
              bounces={true}
              keyboardShouldPersistTaps={'always'}
              showsVerticalScrollIndicator={false}
              refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}>
              {renderHeader()}
              <BrComponent path="body" />
            </ScrollView>
          </Screen>
        </BrPage>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  alignLeft: {
    alignItems: 'flex-start',
  },
  contentStyle: {
    height: 'auto',
    paddingBottom: 75,
  },
  firstText: {
    textAlign: 'center',
    fontFamily: FontFamily.LarsseitBold,
    marginLeft: 10,
  },
  getHelpBtn: {
    width: 240,
    height: 43,
    backgroundColor: colors.secondary,
    borderRadius: 22,
  },
  getHelpView: {
    marginTop: 10,
  },
  upperView: {
    alignItems: 'center',
    justifyContent: 'center',
    width: screenWidth,
  },
  orderView: {
    width: '90%',
    borderRadius: 10,
    paddingHorizontal: 30,
    backgroundColor: colors.white,
    marginTop: scale(16),
    marginBottom: scale(24),
    justifyContent: 'center',
  },
  myOrderView: {
    borderBottomWidth: 0.5,
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 10,
    borderBottomColor: colors.sectionBorder,
  },

  searchBarWrapper: {
    width: Platform.select({
      ios: screenWidth * 0.88,
      android: screenWidth * 0.86,
    }),
    alignContent: 'center',
    justifyContent: 'space-between',
    marginTop: 30,
    marginBottom: 30,
    backgroundColor: colors.white,
    flexDirection: 'row',
    borderRadius: 12.5,
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    borderStyle: 'solid',
    height: 50,
  },
  searchTextInput: {
    alignSelf: 'center',
    padding: 10,
    width: '70%',
    fontSize: scale(15),
    fontFamily: FontFamily.LarsseitLight,
    flex: 1,
    color: colors.text,
  },
  iconWrapper: {
    padding: 5,
    alignSelf: 'center',
  },
  helpTopicsView: {
    borderTopWidth: 0.5,
    width: screenWidth,
    paddingVertical: 20,
    borderTopColor: colors.sectionBorder,
    paddingHorizontal: -2,
  },
  helpTopicsText: { marginLeft: 30 },
  contactIconView: { marginRight: 10 },
  somethingStyle: { borderBottomWidth: 0 },
  getHelpBtnText: {
    color: colors.white,
    fontSize: scale(18),
    fontFamily: FontFamily.LarsseitBold,
    height: scale(22),
    lineHeight: scale(22),
    textAlignVertical: 'center',
    textAlign: 'center',
  },
});

export default HelpCenterScreen;

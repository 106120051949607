import { Button, CloseButton, Icon, KeyboardAvoidingScrollView, LinkButton, NavigationHeader, Screen } from '@components';
import KeyboardCloseButton from '@components/KeyboardCloseButton';
import { PickerComponent, PickerItem } from '@components/Picker';
import Text from '@components/Text';
import { ErrorMessage, Form, FormField, FormPicker, SubmitButton } from '@components/forms';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Ticket } from '@fieldera-raleys/client-common/services/brandywine/types';
import {
  AccountStackRoutes,
  AccountStackScreenProps,
  AppStackRoutes,
  CheckoutStackRoutes,
  HelpCenterStackRoutes,
  HelpCenterStackScreenProps,
  RootTabRoutes,
} from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { ticketService } from '@services/brandywine';
import liveagentService from '@services/salesforce/liveagentService';
import { useUserProfileStore } from '@store';
import { appStyles } from '@styles';
import { defaultFontSize, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { vibrateNotify } from '@utils/helpers';
import { FormikHelpers } from 'formik';
import React, { createRef, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, StyleSheet, TextInput, View } from 'react-native';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { useCustomModalContext } from '../../../contexts';

type ContactCustomerServiceProps =
  | HelpCenterStackScreenProps<HelpCenterStackRoutes.ContactCustomerScreen>
  | AccountStackScreenProps<AccountStackRoutes.ContactCustomerService>;
type ContactFormType = {
  topic?: string;
  message?: string;
};
type Topic = { id: number; text: string };

const topics: Topic[] = [
  {
    id: 0,
    text: 'Existing Online Order',
  },
  {
    id: 1,
    text: 'New Online Order',
  },
  {
    id: 2,
    text: 'Refund Request',
  },
  {
    id: 3,
    text: 'Something Extra Rewards',
  },
  {
    id: 4,
    text: 'Other',
  },
];

const ContactCustomerServiceScreen = ({ route }: ContactCustomerServiceProps) => {
  const navigation = useNavigation();
  const { userProfile } = useUserProfileStore();

  const tabBarHeight = useBottomTabBarHeight();
  const { calledFrom } = route.params ?? {};
  const { t } = useTranslation('helpCenter');
  const { showAlertPopup } = useCustomModalContext();
  const { data: isAvailable } = useQuery(['liveagent_availablity'], () => liveagentService.getAgentAvailablity());
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const formValues = useRef<ContactFormType>({
    topic: '',
    message: '',
  });

  const topicInputRef = useRef<PickerComponent<Topic>>(null);
  const messageInputRef = createRef<TextInput>();
  const errorMessageRef = useRef<string>();

  const validationSchema = Yup.object().shape({
    topic: Yup.string().required().label('Topic'),
    message: Yup.string().trim().required().label('Message'),
  });

  const getPickerValue = useCallback((topic: string | undefined) => {
    const selected = topics.find((tp) => tp.text === topic);
    return selected
      ? ({
          id: String(selected.id),
          text: selected?.text,
          value: selected,
        } as PickerItem<Topic>)
      : undefined;
  }, []);

  const handleClose = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.OrderComplete,
        params: {
          orderId: calledFrom ?? '',
        },
      });
    }
  };

  const handleSubmit = async (formData: ContactFormType, { resetForm }: FormikHelpers<any>) => {
    errorMessageRef.current = '';
    setIsButtonLoading(true);
    let selectedTopic = topics.find((topic) => topic.id === +formData.topic!);

    try {
      const ticketData = {
        Topic: selectedTopic?.text,
        TopicOther: selectedTopic?.text,
        Message: (formData!.message && formData!.message.trim()) ?? '',
        ExtOrderId: '',
        ExtCustomerId: '' + (userProfile?.sepiNumber ?? ''),
        FirstName: userProfile?.firstName ?? '',
        LastName: userProfile?.lastName ?? '',
        EmailAddress: userProfile?.email ?? '',
        PhoneNumber: userProfile?.phone ?? '',
      } as Ticket;

      const response = await ticketService.addTicket('app', ticketData);
      if (response) {
        resetForm();
        showAlertPopup({
          message: 'Thank you! Your email has been sent.',
          containerStyle: [styles.alertBox, { bottom: tabBarHeight }],
        });
        vibrateNotify('Buzz');
      }
    } catch (error) {
      errorMessageRef.current = (error as Error).message;
    } finally {
      setIsButtonLoading(false);
    }
  };

  return (
    <Screen>
      <NavigationHeader
        testID="CCSHeader"
        style={[styles.HelpCenterHeaderStyle]}
        subStyle={styles.navigatorSubStyle}
        titleStyle={[appStyles.fontMobileH6, styles.titleStyle]}
        icon={<CloseButton onPress={calledFrom ? () => handleClose() : undefined} />}
        title={t('headerTitleCCS')}
      />
      <KeyboardAvoidingScrollView
        scrollEnabled={true}
        contentContainerStyle={[{ paddingBottom: screenHeight * 0.23 }]}
        showsVerticalScrollIndicator={true}
        style={styles.container}
        bounces={false}
        extraKeyboardOffset={Platform.OS === 'ios' && screenHeight < 740 ? -200 : -60}>
        <View style={{ paddingHorizontal: 10 }}>
          <View style={styles.headerMainView}>
            <Text style={appStyles.fontMobileBodySmallBald} testID="ccsHeaderMsg">
              {t('ccsHeaderMsg')}
            </Text>
            <View style={styles.chatPhoneView}>
              <View style={{ width: '50%' }}>
                <View style={styles.chatView}>
                  <Icon name="chat" style={[{ padding: 2 }]} stroke={'none'} testID="liveChatIcon" />
                  <Text style={[appStyles.fontMobileBodyMedium, styles.liveChatTxt]} testID="liveChatText">
                    {t('liveChatText')}
                  </Text>
                </View>
                <Button
                  testID="liveChatBtn"
                  disabled={!isAvailable ? true : false}
                  title={isAvailable ? t('start') : t('offline')}
                  type={'secondary'}
                  size="small"
                  buttonStyle={[styles.buttonView, { opacity: isAvailable ? 0.9 : 0.5 }]}
                  textStyle={styles.textStyle}
                  onPress={() => {
                    if (isAvailable) {
                      navigation.navigate(AppStackRoutes.LiveAgent);
                    }
                  }}
                />
                <Text style={[appStyles.bodySmallRegular, styles.chatMsgText]} testID="chatMsg">
                  {t('chatMsg')}
                </Text>
              </View>
              <View style={styles.phoneMainView} />
              <View style={{ width: '50%' }}>
                <View style={styles.phoneView}>
                  <Icon name="phone" style={[{ padding: 2 }]} stroke={'none'} testID="phoneIcon" />
                  <Text style={[appStyles.fontMobileBodyMedium, styles.phoneText]} testID="phoneText">
                    {t('phone')}
                  </Text>
                </View>
                <Button
                  testID="callBtn"
                  title={t('Call')}
                  type={'secondary'}
                  size="small"
                  buttonStyle={[styles.buttonView]}
                  textStyle={styles.textStyle}
                  onPress={() => {
                    Linking.openURL(`tel:${appConstants.CUSTOMER_SERVICE_PHONE}`);
                  }}
                />
                <Text style={[appStyles.bodySmallRegular, styles.chatMsgText]} testID="cspText">
                  {appConstants.CUSTOMER_SERVICE_PHONE}{' '}
                </Text>
              </View>
            </View>
            <View style={styles.helpCenterView}>
              <Text style={[appStyles.fontMobileListSmallCenterRegular]} testID="quesText">
                {t('quesText')}
              </Text>
              <Text style={[appStyles.fontMobileListSmallCenterRegular]} testID="quesText2">
                {t('quesText2')}
                <LinkButton
                  testID="helpCenterBtn"
                  style={styles.helpCenterLinkBtn}
                  onPress={() => {
                    navigation.navigate(AppStackRoutes.RootTabs, {
                      screen: RootTabRoutes.Account,
                      params: {
                        screen: AccountStackRoutes.HelpCenter,
                        params: {
                          screen: HelpCenterStackRoutes.HelpCenterScreen,
                        },
                      },
                    });
                  }}>
                  {t('helpCenterText')}
                </LinkButton>
              </Text>
            </View>
          </View>
          <View style={styles.bottomView}>
            <View style={styles.messageView}>
              <View style={[styles.emailView]}>
                <Icon name="email" style={[styles.emailIcon]} stroke={'none'} testID="emailIcon" />
                <Text style={[appStyles.fontMobileBodyMedium, styles.phoneText]} testID="emailText">
                  {t('email')}
                </Text>
              </View>
              <Text style={[appStyles.tinyLight, styles.emailTextInfo]} testID="allowText">
                {t('allowText')}
              </Text>
            </View>
            <Form initialValues={formValues.current} onSubmit={handleSubmit} validationSchema={validationSchema} onReset={() => topicInputRef.current?.reset()}>
              <ErrorMessage error={errorMessageRef.current} visible={!!errorMessageRef.current} style={styles.errorText} testID={'errorMessage'} />
              <FormPicker
                testID="topicPicker"
                ref={topicInputRef}
                label="Topic"
                name="topic"
                onSelect={() => {
                  messageInputRef.current?.focus();
                }}
                initialValue={getPickerValue(formValues.current.topic)}
                options={topics.map((topic) => ({ id: topic.id, text: topic.text, value: topic }))}
                textStyle={{ fontSize: scale(18) }}
                placeholder={'Select'}
                headerTitle={'Select Your Topic'}
              />
              <FormField
                testID="message"
                ref={messageInputRef}
                label={'Message'}
                bottomRight={'250 Character Limit'}
                placeholder={'Enter message here...'}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                keyboardType="default"
                name={'message'}
                multiline={true}
                textContentType="none"
                fieldStyleEx={[{ height: scale(defaultFontSize * 10) }]}
                maxLength={250}
                inputAccessoryViewID="Close"
              />
              <KeyboardCloseButton />
              <SubmitButton
                testID="CCSSubmitBtn"
                title={t('submit')}
                type={'secondary'}
                textStyle={styles.textStyle}
                buttonStyle={[styles.buttonView, styles.sendEmailBtn]}
                isButtonLoading={isButtonLoading}
              />
            </Form>
          </View>
        </View>
      </KeyboardAvoidingScrollView>
    </Screen>
  );
};

const styles = StyleSheet.create({
  errorText: {
    textAlign: 'center',
  },
  navigatorSubStyle: {
    backgroundColor: colors.cream,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  titleStyle: {
    alignSelf: 'center',
    marginLeft: 20,
  },
  headerMainView: {
    backgroundColor: colors.sectionPad,
    paddingTop: 20,
    width: screenWidth,
    marginLeft: -10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatPhoneView: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    height: screenHeight > 740 ? screenHeight * 0.17 : screenHeight * 0.21,
    width: screenWidth * 0.9,
    justifyContent: 'space-evenly',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  chatView: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 20,
    paddingBottom: 15,
  },
  liveChatTxt: {
    fontSize: scale(16),
    lineHeight: lineHeight(18),
  },
  phoneMainView: {
    borderWidth: 0.5,
    borderColor: colors.sectionBorder,
  },
  phoneView: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 20,
    marginBottom: 15,
    justifyContent: 'center',
  },
  phoneText: {
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    marginLeft: 8,
  },
  chatMsgText: {
    textAlign: 'center',
    marginTop: 4,
    fontSize: scale(14),
  },
  container: {
    width: screenWidth,
    height: screenHeight,
  },
  HelpCenterHeaderStyle: {
    borderBottomColor: '#B4B4B4',
  },
  helpCenterView: {
    width: screenWidth * 0.8,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 26,
    marginBottom: 30,
  },
  buttonView: {
    borderWidth: 1,
    height: 34,
    marginTop: 0,
    minWidth: 130,
    maxWidth: 350,
    paddingVertical: 0,
  },
  bottomView: {
    marginHorizontal: 10,
    marginTop: 12,
  },
  emailTextInfo: {
    paddingTop: 16,
    textAlign: 'center',
    paddingBottom: 0,
  },
  emailView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    justifyContent: 'center',
  },
  textStyle: {
    fontSize: scale(14),
    lineHeight: Platform.select({
      android: lineHeight(14.2),
    }),
  },
  helpCenterLinkBtn: {
    alignSelf: 'center',
    fontFamily: FontFamily.LarsseitMedium,
    lineHeight: lineHeight(20),
  },
  messageView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  emailIcon: { padding: 2 },
  sendEmailBtn: {
    minWidth: 130,
    maxWidth: 350,
    alignSelf: 'flex-start',
  },
  alertBox: {
    bottom: 36,
  },
});

export default ContactCustomerServiceScreen;

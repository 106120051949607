import { assets } from '@config/assets';
import React from 'react';
import { StyleProp, StyleSheet } from 'react-native';
import { ImageStyle } from 'react-native-fast-image';
import { resizeImage } from '../../utils/helpers';
import { Image } from '../image';

interface ProductImageProps {
  imageUrl?: string;
  style?: StyleProp<ImageStyle>;
  testID?: string;
}

const fallbackImage = assets.imageComingSoon;

const ProductImage = ({ imageUrl, style, testID = 'image' }: ProductImageProps) => {
  imageUrl = imageUrl ? resizeImage(imageUrl, 150, 150) : imageUrl;

  const getImageSource = () => {
    let url;

    if (imageUrl) {
      url = { cache: 'immutable', uri: `${imageUrl?.indexOf('//') === 0 ? 'https:' + imageUrl : imageUrl}` };
    } else {
      url = fallbackImage;
    }

    return url;
  };

  return <Image source={getImageSource()} style={[baseStyles.base, style]} defaultSource={fallbackImage} testID={testID} />;
};

const baseStyles = StyleSheet.create({
  base: {
    width: 50,
    height: 50,
  },
});

export default ProductImage;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import { LineItem, Product, ShoppingListLineItem, TextLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { getCustomFieldValue, getDisplayPriceBySellType, toTitleCase } from '@fieldera-raleys/client-common/utils';
import { AppStackRoutes } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { useShopStore, useShoppingListsStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { defaultFontSize, lineHeight, scale, screenWidth } from '@styles/constants';
import { getItemAttributeValue, getProductVariantQuantityIndex, getProductVariantQuantityValue } from '@utils/orderHelpers';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Animated, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { Swipeable } from 'react-native-gesture-handler';
import { useCustomModalContext } from '../../contexts';
import logger from '../../utils/logger';
import {
  getFormattedPrice,
  getMaxProductQuantity,
  getProductAisleBayBin,
  getProductAvailablity,
  getfulfillmentLeadTimeMinutes,
} from '../../utils/productHelper';
import Accordion from '../Accordion';
import Icon from '../Icon';
import QuantityBox from '../QuantityBox';
import { Form, FormField, SubmitButton } from '../forms';
import { Image } from '../image';

type ListAccordionItemProps = {
  item: LineItem | ShoppingListLineItem | TextLineItem;
  productInfo?: Product | undefined;
  listName: string;
  listId: string;
  checked: boolean;
  linkAction?: () => void;
};

const ListAccordionItem: FC<ListAccordionItemProps> = ({ item, listName, listId, checked = false, linkAction, productInfo }) => {
  const isTextItem = !('variant' in item);
  const {
    removeItemFromList,
    setLineItemCustomField,
    setLineItemQuantity,
    removeTextItemFromList,
    setTextLineItemQuantity,
    setTextLineItemCustomField,
    addItemToList,
    addTextLineItemToList,
  } = useShoppingListsStore();
  const { showAlertPopup } = useCustomModalContext();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('cart');
  const { selectedStore } = useShopStore();
  const fallbackImage = require('../../assets/images/image-coming-soon-temp.png');
  const price = !isTextItem ? (item.variant?.price?.value.centAmount / 10 ** (item.variant?.price?.value.fractionDigits ?? 0)).toFixed(2) : '';
  const salePrice = !isTextItem ? item.variant?.price?.discounted?.value && getFormattedPrice(item.variant?.price?.discounted?.value) : '';
  const unitsPerPackage = !isTextItem ? getProductAttributeValue('unitsPerPackage', item.variant?.attributesRaw ?? []) : '1';
  const unitOfMeasure = !isTextItem ? getProductAttributeValue('unitOfMeasure', item.variant?.attributesRaw ?? []) : '';
  const pricePerUnit = !isTextItem ? item.variant?.price?.value && getFormattedPrice(item.variant?.price?.value, unitsPerPackage) : '';
  const leadTime = !isTextItem ? getfulfillmentLeadTimeMinutes(item) : 0;
  const sellType = !isTextItem
    ? getProductAttributeValue('unitSellType', item.variant?.attributesRaw ?? []) ?? { key: 'byEach', label: 'By Each' }
    : { key: 'byEach', label: 'By Each' };
  const unitBuyIncrement =
    !isTextItem && (sellType?.key ?? 'byEach') === 'byWeight' ? getProductAttributeValue('unitBuyIncrement', item.variant?.attributesRaw ?? []) : 1;
  const unitBuyMinimum =
    !isTextItem && (sellType?.key ?? 'byEach') === 'byWeight' ? getProductAttributeValue('unitBuyMinimum', item.variant?.attributesRaw ?? []) : 1;
  const minBuy = !isTextItem ? getProductAttributeValue('unitBuyMinimum', item.variant?.attributesRaw ?? []) : 0;
  const lineItemNote = item.custom?.customFieldsRaw?.find((cf) => cf.name === 'itemNote')?.value ?? '';
  const tabBarHeight = useBottomTabBarHeight();
  const available = getProductAvailablity(productInfo?.masterData);
  const navigation = useNavigation();
  // const [removeId, setRemoveId] = useState<string>();
  let itemRowRefs = useMemo(() => new Map(), []);
  const [loading, setLoading] = useState(false);

  const priceCustomValues = !isTextItem ? item.variant?.price?.custom?.customFieldsRaw ?? [] : [];
  const unitAverageBuyWeight = !isTextItem ? getProductAttributeValue('unitAverageBuyWeight', item.variant?.attributesRaw ?? []) : '';

  let regularPrice =
    priceCustomValues.length &&
    priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value &&
    getFormattedPrice(priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value);

  let onSale = !isTextItem
    ? (item.variant?.price?.value.centAmount ?? 0) < (priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount ?? 0)
      ? true
      : false
    : false;

  let priceMessage = '';
  const packageCount = !isTextItem ? getProductAttributeValue('packageCount', item.variant?.attributesRaw ?? []) ?? 1 : 1;
  const unitSize = parseFloat((Number(packageCount) * Number(unitsPerPackage)).toFixed(1));
  const areaBayShelfBin = !isTextItem ? getProductAisleBayBin(item) : undefined;

  const navigateToDetailsPage = () => {
    if (!isTextItem && item.variant?.sku) {
      navigation.navigate(AppStackRoutes.ProductDetails, {
        productKey: item.variant?.sku ?? '',
      });
    }
  };

  const toggleChecked = async () => {
    var currChecked;

    if (!isTextItem) {
      currChecked = getCustomFieldValue('listChecked', item.custom?.customFieldsRaw ?? []);
      if (!currChecked) {
        await setLineItemCustomField(listId, item.id, { name: 'listChecked', value: true });
      } else {
        await setLineItemCustomField(listId, item.id, { name: 'listChecked', value: !currChecked });
      }
    } else {
      currChecked = getCustomFieldValue('listChecked', item.custom?.customFieldsRaw ?? []);
      if (!currChecked) {
        await setTextLineItemCustomField(listId, item.id, { name: 'listChecked', value: true });
      } else {
        await setTextLineItemCustomField(listId, item.id, { name: 'listChecked', value: !currChecked });
      }
    }
  };

  const saveNote = async (formData: { note: string }) => {
    if (!isTextItem) {
      await setLineItemCustomField(listId, item.id, { name: 'itemNote', value: formData.note });
    } else {
      await setTextLineItemCustomField(listId, item.id, { name: 'itemNote', value: formData.note });
    }
    setOpen(false);
  };

  const setQuantity = async (qty: number) => {
    setLoading(true);
    if (!isTextItem) {
      if (qty < (sellType === 'byWeight' ? minBuy : 1)) {
        removeItem();
        return;
      }
      if (sellType.key === 'byWeight') {
        logger.log('set lineitem weight');
        //TODO: Use cart method to set lineitem weight
        await setLineItemQuantity(listId, item.id, qty);
      } else {
        await setLineItemQuantity(listId, item.id, qty);
      }
    } else if (isTextItem) {
      if (qty < 1) {
        removeItem();
        return;
      }
      await setTextLineItemQuantity(listId, item.id, qty);
    }
    setLoading(false);
  };

  const removeItem = useCallback(async () => {
    if (isTextItem) {
      await removeTextItemFromList(listId, item.name ?? '');
    } else {
      await removeItemFromList(listId, item.id);
    }
    showAlertPopup({
      message: `Item Removed from "${listName}"`,
      containerStyle: [styles.alertBox, { bottom: tabBarHeight }],
      linkText: 'Undo',
      linkAction: () => undoRemove(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, listName]);

  const getImageSource = () => {
    var url;
    var currUrl = !isTextItem ? item.variant?.images[0]?.url : '';
    if (currUrl === '' || !currUrl) {
      url = fallbackImage;
    } else {
      url = { cache: 'web', uri: `${currUrl?.indexOf('//') === 0 ? 'https:' + currUrl : currUrl}` };
    }
    return url;
  };

  const undoRemove = useCallback(async () => {
    if (!isTextItem && item.variant?.sku) {
      await addItemToList(listId, item.variant?.sku, item.quantity);
    } else if (isTextItem && item?.name) {
      await addTextLineItemToList(listId, item?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, listId]);

  const renderRightActions = useCallback(
    (_: Animated.AnimatedInterpolation<string | number>, dragAnimatedValue: Animated.AnimatedInterpolation<string | number>) => {
      const opacity = dragAnimatedValue.interpolate({
        inputRange: [-40, 0],
        outputRange: [1, 0],
        extrapolate: 'clamp',
      });
      return (
        <TouchableOpacity onPress={removeItem} style={styles.swipedRow}>
          <Animated.View style={[styles.deleteButton, { opacity }]}>
            <Icon stroke={colors.white} name={'x-close'} size={25} />
          </Animated.View>
        </TouchableOpacity>
      );
    },
    [removeItem],
  );

  const getQuantityIndex = useCallback(
    (sku: string, quantity: number, estimatedTotalWeight?: number) => {
      return !isTextItem && item.variant ? getProductVariantQuantityIndex(item.variant!, quantity, estimatedTotalWeight) : quantity;
    },
    [isTextItem, item],
  );

  const formatQuantityFromIndex = useCallback(
    (index: number) => {
      const val = !isTextItem && item.variant ? getProductVariantQuantityValue(item.variant, index) : index;

      if (sellType.key === 'byWeight') {
        return `${val} ${unitOfMeasure}`;
      } else {
        return val;
      }
    },
    [isTextItem, item, sellType.key, unitOfMeasure],
  );

  const displayPrice = () => {
    let qty = !isTextItem && item.variant ? getProductVariantQuantityValue(item.variant, item.quantity) : 1;

    return !isTextItem
      ? onSale
        ? priceMessage.length && priceMessage.includes(' for $')
          ? priceMessage
          : getDisplayPriceBySellType(sellType.key, item.variant?.price?.value, unitAverageBuyWeight, qty)
        : priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value && priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount
          ? getDisplayPriceBySellType(sellType.key, priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value, unitAverageBuyWeight, qty)
          : getDisplayPriceBySellType(sellType.key, item.variant?.price?.value, unitAverageBuyWeight, qty)
      : '';
  };

  const getMaxLimit = useCallback(async () => {
    if (isTextItem) {
      return 1;
    } else {
      return productInfo ? getMaxProductQuantity(item?.variant?.sku ?? '', productInfo) : 1;
    }
  }, [isTextItem, item, productInfo]);

  return (
    <Swipeable
      key={item.id}
      ref={(r2) => {
        if (r2) {
          itemRowRefs.set(item.id, r2);
        }
      }}
      renderRightActions={(progress, dragAnimatedValue) => renderRightActions(progress, dragAnimatedValue)}
      overshootRight={true}
      onSwipeableOpen={() => {
        [...itemRowRefs.entries()].forEach(([k, r1]) => {
          if (k !== item.id && r1) {
            r1.close();
          }
        });
      }}>
      {item.custom?.customFieldsRaw?.find((cf) => cf.name === 'listChecked')?.value === true ? (
        <View style={styles.overlay} pointerEvents={'box-none'} />
      ) : null}
      <Accordion
        textProps={{
          numberOfLines: 1,
        }}
        style={styles.accordion}
        textStyle={styles.accordionHeaderText}
        title={item.name ?? ''}
        isOpen={open}
        setNextState={setOpen}
        showCheckBox
        showCount
        count={formatQuantityFromIndex(item.quantity)}
        showLink={isTextItem}
        linkText={'Find Items'}
        showAisleBay={available.availability === 'Available' && !isTextItem}
        aisleBay={areaBayShelfBin}
        showWarning={available.availability !== 'Available' && available.availability !== 'LowStock' && !isTextItem}
        warning={toTitleCase(available.availability).substring(1)}
        togglerValue={toggleChecked}
        headerStyle={styles.accordionHeader}
        shadowEnabled={false}
        checked={checked}
        linkAction={linkAction}
        hasNote={lineItemNote}
        arrowType={'secondary'}>
        <View style={styles.container}>
          <View style={styles.productContainer}>
            <TouchableOpacity activeOpacity={!isTextItem ? 0.4 : 1} onPress={navigateToDetailsPage} style={styles.imageContainer}>
              {isTextItem ? (
                <Icon name="generic-grocery" style={{ alignSelf: 'center', width: '100%', height: '100%' }} />
              ) : (
                <Image source={getImageSource()} style={{ alignSelf: 'center', width: '100%', height: '100%' }} resizeMode="contain" />
              )}
            </TouchableOpacity>
            {loading ? (
              <ActivityIndicator color={appStyles.primaryButton.color} size={'small'} testID="loading" />
            ) : (
              <View style={styles.infoContainer}>
                <View style={styles.topInfoContainer}>
                  <View style={styles.priceContainer}>
                    {!isTextItem && price && !isNaN(+price) ? (
                      <>
                        {sellType.key === 'byEach' ? (
                          <Text style={appStyles.bodySmallLeft}>${(Number(price) * Number(item.quantity)).toFixed(2).toString()}</Text>
                        ) : (
                          <Text style={appStyles.bodySmallLeft}>{displayPrice()}</Text>
                        )}
                        {(salePrice ?? price) && sellType.key === 'weightByEach' ? (
                          <Text style={[styles.tinyLightLeft, { transform: [{ translateY: 3 }] }]}>{'approx '}</Text>
                        ) : null}
                        {sellType.key === 'byEach' ? (
                          <Text style={[styles.tinyLightLeft, { color: onSale ? colors.red : colors.primary, transform: [{ translateY: 6 }] }]}>
                            ${price}
                            {' ea'}
                          </Text>
                        ) : sellType.key === 'byWeight' ? (
                          <Text style={[styles.tinyLightLeft, { color: onSale ? colors.red : colors.primary, transform: [{ translateY: 12 }] }]}>
                            {onSale ? `$${price}` : regularPrice} {`/ ${unitOfMeasure} `}
                          </Text>
                        ) : (
                          <Text style={[styles.tinyLightLeft, { color: onSale ? colors.red : colors.primary, transform: [{ translateY: 12 }] }]}>
                            {pricePerUnit} {sellType.key === 'weightByEach' ? ' ea' : ` / ${unitOfMeasure}`}
                          </Text>
                        )}
                        {sellType.key === 'byEach' && (
                          <Text style={[styles.tinyLightLeft, { transform: [{ translateY: 12 }] }]}>
                            {unitSize} {sellType.key === 'byEach' ? `${unitOfMeasure} ` : 'ea'}
                          </Text>
                        )}
                      </>
                    ) : (
                      <>
                        <Text style={appStyles.bodySmallLeft}>$ --</Text>
                        <Text style={[appStyles.tinyLight, { textAlign: 'left' }]}>$ -- {unitOfMeasure}</Text>
                      </>
                    )}
                  </View>
                  <View style={styles.qtyContainer}>
                    <QuantityBox
                      initialValue={
                        !isTextItem
                          ? getQuantityIndex(
                              item.variant?.sku ?? '',
                              item.quantity,
                              getItemAttributeValue('estimatedTotalWeight', item.custom?.customFieldsRaw ?? []),
                            )
                          : 1
                      }
                      onChange={setQuantity}
                      containerStyle={[styles.quantityButton, utilityStyles.py1]}
                      borderStyle={styles.quantityBorder}
                      countStyle={[
                        appStyles.primaryButtonSmallText,
                        { minWidth: scale(40), minHeight: lineHeight(18), paddingBottom: Platform.select({ android: 5 }) },
                      ]}
                      incrementBy={unitBuyIncrement}
                      minLimit={unitBuyMinimum}
                      maxLimit={getMaxLimit()}
                      qtyFormat={(idx) => formatQuantityFromIndex(idx)}
                    />
                  </View>
                </View>
                {leadTime > +(selectedStore?.fulfillmentLeadTimeMinutes ?? Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES) ? (
                  <View style={styles.leadTimeContainer}>
                    <Icon name={'flag-red-icon'} stroke={'none'} style={[appStyles.smallIcon, { transform: [{ translateY: 2 }] }]} size={12} />
                    <Text style={[styles.leadTimeWarning, utilityStyles.mx1]}>
                      {leadTime > +(Config.LEAD_TIME_MAX_HOURS_TOSHOW ?? 48) * 60 ? `${(leadTime / 60 / 24 + 1) | 0} Day` : `${(leadTime / 60) | 0}-Hour`} Lead
                      Time
                    </Text>
                  </View>
                ) : null}
                {/* : available.availability !== 'Available' && available.availability !== 'LowStock' && !isTextItem ? (
                <View style={styles.leadTimeContainer}>
                  <Icon name={'flag-red-icon'} stroke={'none'} size={11} style={{ transform: [{ translateY: 2 }] }} />
                  <Text style={[appStyles.smallRed, styles.leadTimeWarning]}>{available.availability ?? ''}</Text>
                </View>
              ) : null} */}
              </View>
            )}
          </View>
          <View style={styles.noteContainer}>
            <View style={[{ flexDirection: 'column', marginBottom: 13 }]}>
              <Form initialValues={{ note: lineItemNote }} onSubmit={saveNote}>
                <FormField
                  label={[
                    <Text key={`itemNoteLabelKey_${item.id}`} style={appStyles.bodySmallBold}>
                      {t('addItemNote')}
                    </Text>,
                  ]}
                  bottomRight={t('wordLimit')}
                  placeholder={t('addItemNoteDefault')}
                  topRight={t('optionalField')}
                  autoCapitalize="sentences"
                  autoCorrect={false}
                  onChange={() => {
                    return;
                  }}
                  keyboardType="ascii-capable"
                  name={'note'}
                  textContentType="none"
                  multiline={true}
                  fieldStyleEx={[{ height: scale(defaultFontSize * 6) }]}
                  maxLength={250}
                />
                <View style={[{ flexDirection: 'row', height: 20 }, utilityStyles.my1]}>
                  <SubmitButton type={'secondary'} size={'small'} buttonStyle={{ position: 'absolute', top: -20 }} title={t('closeItemNote')} />
                </View>
              </Form>
            </View>
          </View>
        </View>
      </Accordion>
    </Swipeable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 60,
    paddingBottom: 16,
    paddingTop: 5,
  },
  overlay: {
    backgroundColor: colors.cream,
    opacity: 0.5,
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 999,
  },
  productContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  imageContainer: {
    width: 60,
    height: 60,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.sectionBorder,
  },
  topInfoContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  priceContainer: {
    // backgroundColor: 'green',
    marginRight: 8,
    width: '30%',
  },
  qtyContainer: {
    // backgroundColor: 'blue',
    alignItems: 'center',
    justifyContent: 'center',
    width: '45%',
  },
  alertBox: {
    bottom: 36,
  },
  quantityButton: {
    height: 34,
  },
  quantityBorder: {
    height: 30,
    top: 0,
  },
  quantityCountText: {
    fontSize: scale(18),
  },
  leadTimeContainer: {
    marginTop: scale(16),
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  leadTimeWarning: {
    color: colors.red,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  noteContainer: {
    paddingTop: 10,
  },
  accordion: {
    alignSelf: 'center',
    backgroundColor: colors.cream,
  },
  accordionHeader: {
    backgroundColor: colors.cream,
  },
  accordionHeaderText: {
    width: screenWidth * 0.65,
    ...appStyles.bodySmallLeft,
  },
  checkboxContainer: {
    flex: 0.2,
  },
  infoContainer: {
    width: '100%',
  },
  swipedRow: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.sectionHeader,
  },
  deleteButton: {
    backgroundColor: colors.red,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: 20,
    marginLeft: 'auto',
  },
  tinyLightLeft: {
    ...appStyles.tinyLight,
    textAlign: 'left',
  },
});

export default ListAccordionItem;

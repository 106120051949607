import Text from '@components/Text';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { DateRange, Order, OrderStatusType } from '@fieldera-raleys/client-common';
import { AccountStackRoutes, AppStackRoutes, CheckoutStackRoutes, OrderHistoryStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { orderService } from '@services/brandywine';
import { useCartStore } from '@store';
import { scale } from '@styles/constants';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Button from '../Button';

const OrderStatus = () => {
  const navigation = useNavigation();
  const [state, setState] = useState<{
    openOrderCount: number;
    openOrderNumber?: string;
    orderStatus?: 'PendingCheckout' | 'Pending' | 'Multiple' | 'InProgress' | 'Shipped' | 'Packed' | 'Completed';
    statusHeader: string;
    statusBody?: string;
    buttonTitle: string;
  }>({
    openOrderCount: 0,
    statusHeader: 'Start Shopping',
    statusBody: '',
    buttonTitle: 'View Details',
  });

  const cartQuantity = useCartStore().getCartQuantity();

  const loadOrders = useCallback(async () => {
    try {
      if (cartQuantity > 0) {
        setState((prev) => ({
          ...prev,
          openOrderCount: 0,
          statusHeader: 'Ready to Checkout?',
          statusBody: `${cartQuantity} ${cartQuantity === 1 ? 'Item' : ' Items'} in Cart`,
          buttonTitle: 'Checkout',
          orderStatus: 'PendingCheckout',
        }));
      } else {
        const response = await orderService.fetchOrders(0, appConstants.PAGE_SIZE, {
          dateRange: { startDate: dayjs().subtract(1, 'month').toDate() } as DateRange,
          orderType: ['In-store', 'Online'],
          fulfillmentType: ['Delivery', 'Pickup'],
        });
        if (response.data) {
          const openOrders = response.data.filter(
            (order: Order) =>
              [OrderStatusType.Complete, OrderStatusType.Canceled, OrderStatusType.SoftCanceled].indexOf(parseInt(order.orderStatus.id ?? '-1', 10)) < 0,
          );
          if (openOrders.length === 0) {
            setState((prev) => ({
              ...prev,
              openOrderCount: 0,
              statusHeader: 'Build your Cart',
              statusBody: 'Add your favorite items',
              buttonTitle: 'Shop Now',
              orderStatus: undefined,
            }));
          } else if (openOrders.length > 1) {
            setState((prev) => ({
              ...prev,
              statusHeader: 'Recent Orders',
              statusBody: 'Multiple Orders in Progress',
              buttonTitle: 'View Orders',
              openOrderCount: openOrders.length,
              orderStatus: 'Multiple',
            }));
          } else if (
            [OrderStatusType.Active, OrderStatusType.Pending, OrderStatusType.Packing, OrderStatusType.Shipping].indexOf(
              parseInt(openOrders[0].orderStatus.id ?? '-1', 10),
            ) >= 0
          ) {
            setState((prev) => ({
              ...prev,
              openOrderCount: 1,
              openOrderNumber: openOrders[0].extOrderId,
              statusHeader: 'Order in Progress',
              statusBody: `Order# ${openOrders[0]?.extOrderId}`,
              buttonTitle: 'View Details',
              orderStatus: 'InProgress',
            }));
          } else if (OrderStatusType.Complete === parseInt(openOrders[0].orderStatus.id ?? '-1', 10)) {
            setState((prev) => ({
              ...prev,
              openOrderCount: 1,
              openOrderNumber: openOrders[0].extOrderId,
              statusHeader: 'Order Complete',
              statusBody: `Order# ${openOrders[0]?.extOrderId}`,
              buttonTitle: 'View Details',
              orderStatus: 'Completed',
            }));
          } else if (OrderStatusType.Packed === parseInt(openOrders[0].orderStatus.id ?? '-1', 10)) {
            setState((prev) => ({
              ...prev,
              openOrderCount: 1,
              openOrderNumber: openOrders[0].extOrderId,
              statusHeader: 'Order in Progress',
              statusBody: `Order# ${openOrders[0]?.extOrderId}`,
              buttonTitle: 'View Details',
              orderStatus: 'Packed',
            }));
          } else if (OrderStatusType.Shipped === parseInt(openOrders[0].orderStatus.id ?? '-1', 10)) {
            setState((prev) => ({
              ...prev,
              openOrderCount: 1,
              openOrderNumber: openOrders[0].extOrderId,
              statusHeader: 'Order Complete',
              statusBody: `Order# ${openOrders[0]?.extOrderId}`,
              buttonTitle: 'View Details',
              orderStatus: 'Shipped',
            }));
          } else {
            setState((prev) => ({
              ...prev,
              openOrderCount: 0,
              openOrderNumber: undefined,
              statusHeader: 'Build your Cart',
              statusBody: 'Add your favorite items',
              buttonTitle: 'Shop Now',
              orderStatus: undefined,
            }));
          }
        }
      }
    } catch {
      /* empty */
    }
  }, [cartQuantity]);

  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  const handleOnPress = () => {
    if (!state.orderStatus) {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Shop,
        params: {
          screen: ShopStackRoutes.ShopLanding,
          params: {},
        },
      });
    } else if (state.orderStatus === 'PendingCheckout') {
      navigation.navigate(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.CartLanding,
        params: {
          storeNumber: undefined,
        },
      });
    } else if (state.openOrderCount > 1) {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.OrderHistory,
          params: {
            screen: OrderHistoryStackRoutes.RecentOrders,
            params: { useRefetch: true },
          },
        },
      });
    } else if (state.openOrderCount && state.openOrderNumber) {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.OrderHistory,
          params: {
            screen: OrderHistoryStackRoutes.OrderDetails,
            params: { orderId: state.openOrderNumber },
          },
        },
      });
    } else {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Shop,
        params: {
          screen: ShopStackRoutes.ShopLanding,
          params: {},
        },
      });
    }
  };

  return (
    <View style={styles.container}>
      <View style={[state.statusBody ? styles.leftBox : styles.leftBoxWithoutBody]}>
        <Text style={[styles.statusHeader]}>{state.statusHeader}</Text>
        {(state.statusBody || state.statusBody?.length !== 0) && <Text style={[styles.statusBody]}>{state.statusBody}</Text>}
      </View>
      <View style={styles.rightBox}>
        <Button type="secondary" size="small" title={state.buttonTitle} onPress={handleOnPress} buttonStyle={styles.button} textStyle={styles.buttonText} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: scale(82),
    backgroundColor: colors.cream,
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: colors.lineSeparator,
    borderStyle: 'solid',
  },
  leftBox: {
    paddingRight: 5,
    paddingTop: 15,
    marginLeft: Platform.select({
      android: 5,
      ios: 0,
    }),
  },
  leftBoxWithoutBody: {
    paddingRight: 5,
    marginLeft: Platform.select({
      android: 5,
      ios: 0,
    }),
  },
  rightBox: {
    paddingLeft: 10,
  },
  statusHeader: {
    color: colors.text,
    fontSize: 18,
    fontFamily: FontFamily.LarsseitBold,
  },
  statusBody: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: 14,
    lineHeight: Platform.select({
      ios: 20,
      android: 15,
    }),
    textAlign: 'left',
    paddingBottom: Platform.select({
      android: 15,
      ios: 5,
    }),
  },
  button: {
    maxHeight: 50,
    minWidth: 135,
    alignSelf: 'center',
  },
  buttonText: {
    marginBottom: -2,
  },
});

export default OrderStatus;

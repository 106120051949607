import { createStackNavigator } from '@react-navigation/stack';
import { InboxScreen, MessageDetailsScreen } from '@screens/account/messageCenter';
import React from 'react';
import { MessageCenterStackParamList, MessageCenterStackRoutes } from './routes';

const MessageCenterStack = createStackNavigator<MessageCenterStackParamList>();

const MessageCenterStackNavigator = () => {
  return (
    <MessageCenterStack.Navigator
      initialRouteName={MessageCenterStackRoutes.Inbox}
      screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
      <MessageCenterStack.Screen name={MessageCenterStackRoutes.Inbox} component={InboxScreen} />
      <MessageCenterStack.Screen name={MessageCenterStackRoutes.MessageDetails} component={MessageDetailsScreen} />
    </MessageCenterStack.Navigator>
  );
};

export default MessageCenterStackNavigator;

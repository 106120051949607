import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import { LineItem, Money, Order, Product } from '@fieldera-raleys/client-commercetools/schema';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { containerWidth, lineHeight, scale, screenWidth } from '@styles/constants';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, GestureResponderEvent, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import {
  getItemAttributeValue,
  getLineItemNote,
  getOrderQuantity,
  getProductQuantityIndex,
  getProductQuantityValue,
  getRegularItemPrice,
  invidLineItems,
  moneyValue,
} from '../../utils/orderHelpers';
import { getProductsfromCommerceTools, getRootCategory } from '../../utils/productHelper';
import Icon from '../Icon';
import LinkButton from '../LinkButton';
import ProductImage from './ProductImage';

const layout = { width: 106, imageWidth: 72, imageHeight: 72 };

type ProdDataType = {
  [key: string]: Product;
};

export type ItemizedPurchaseProps = {
  order?: Order;
};

export const ItemizedPurchase = ({ order }: ItemizedPurchaseProps) => {
  const [itemState, setItemState] = useState<{
    [key: string]: {
      openNote?: boolean;
      remove?: boolean;
      save?: boolean;
      customizations?: boolean;
    };
  }>({});
  const itemStateRef = useRef(itemState);

  const { t } = useTranslation('cart');
  const cartIsEmpty = getOrderQuantity(order) === 0;
  const UNIT_SPACER = null;

  const productTouch = useMemo(() => ({ activeOpacity: 1 }), []);

  const customizationsPressed = useCallback(
    (e: GestureResponderEvent, itemId: string) => {
      setItemState({ ...itemState, [itemId]: { ...(itemState[itemId] ?? {}), customizations: !(itemState[itemId]?.customizations ?? false) } });
    },
    [itemState],
  );

  const LineHeader = useCallback((): JSX.Element => {
    return (
      <View key={'lineLineItemHeaderKey'} style={[styles.subContainerItem, styles.itemsHeader, utilityStyles.py1]}>
        <View style={[styles.itemFrame]}>
          <View style={[styles.itemLeftHeader, { justifyContent: 'flex-end' }]}>
            <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, { lineHeight: 26 }]}>
              {t('headerQty')}
            </Text>
          </View>
          <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, styles.itemRight, { lineHeight: 26 }]}>
            {t('headerItem')}
          </Text>
          <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, styles.subtotal, { marginLeft: 'auto', lineHeight: 26 }]}>
            {t('headerPrice')}
          </Text>
        </View>
      </View>
    );
  }, [t]);

  useEffect(() => {
    itemStateRef.current = itemState;
  }, [itemState]);

  const [isLoadingItems, setIsLoadingItems] = useState<boolean>(true);
  const [productData, setProductData] = useState<ProdDataType | undefined>();
  useEffect(() => {
    const getProdSet = async (skus: string[]): Promise<Product[]> => {
      return await getProductsfromCommerceTools(skus);
    };
    if (order) {
      const skus: string[] = invidLineItems(order.lineItems).reduce<string[]>((acc: string[], i: LineItem) => {
        if (i.variant?.sku && acc.findIndex((o) => o === i.variant!.sku)) {
          acc.push(i.variant.sku);
        }
        return acc;
      }, []);
      getProdSet(skus).then((pd) => {
        const pData: ProdDataType = pd.reduce((acc, p: Product) => {
          acc[p.masterData?.current?.masterVariant.sku ?? ''] = p;
          p.masterData?.current?.variants.forEach((vi) => {
            acc[vi?.sku ?? ''] = p;
          });

          return acc;
        }, {} as ProdDataType);
        setProductData(pData);
        setIsLoadingItems(false);
      });
    }
  }, [order]);

  const LineItemNote = useCallback(
    ({ item }: { item: LineItem }): JSX.Element | null => {
      const itemNote = getLineItemNote(item);
      return itemNote ? (
        <View key={'ItemNote_' + item.id} style={{ flexDirection: 'column', width: containerWidth }}>
          <Text style={[appStyles.body, appStyles.bodySmallRegular]}>{t('itemNote')}</Text>
          <View style={styles.notePad}>
            <Text style={appStyles.bodySmallRegular}>{itemNote}</Text>
          </View>
        </View>
      ) : null;
    },
    [t],
  );

  const ProductInfo = useCallback(
    ({ lineItem }: { lineItem: LineItem }): JSX.Element[] => {
      const descr: string[] = [];

      // must copy anything that will be modified for validation to not affect local state
      let item = { ...lineItem, price: { ...lineItem.price, value: { ...lineItem.price.value } } };

      const regularPrice: Money | null = getRegularItemPrice(item);
      let totalPrice: Money = { ...item.price.value };
      const attrs: { [key: string]: string } = { name: item.name ?? item.productSlug ?? item.productId };
      (item.variant?.attributesRaw ?? []).forEach((field) => {
        attrs[field.name] = field.value;
      });
      const unitOfMeasure = getProductAttributeValue(
        'unitOfMeasure',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );

      const productHeaderInfo: TextStyle[] = [];
      const productDetails: JSX.Element[] = [];
      const packageCount = getProductAttributeValue(
        'packageCount',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      const unitsPerPackage = getProductAttributeValue(
        'unitsPerPackage',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      let unitTotalCount = item.quantity;

      // additional ingreditents
      const customField = item.custom?.customFieldsRaw?.find((x) => x.name === 'childLineItems');
      if (customField) {
        const childLineItemIds = customField ? String(customField.value).split(',') : [];
        childLineItemIds.forEach((lineItemId, ctr) => {
          const childLineItem = order?.lineItems.find((x: LineItem) => x.id === lineItemId);
          const message =
            childLineItem?.custom?.customFieldsRaw?.find((cf) => cf.name === 'customStepSort')?.value.split('|')[1] === 'Message'
              ? `: "${childLineItem?.custom?.customFieldsRaw?.find((cf) => cf.name === 'itemNote')?.value}"`
              : '';
          if (childLineItem) {
            if (childLineItem.price.value.centAmount) {
              totalPrice.centAmount += childLineItem.price.value.centAmount * childLineItem.quantity;
              productDetails.push(
                <View key={`ingrKey_${item.id}_${ctr}`} style={[styles.flexrow]}>
                  <Text numberOfLines={2} ellipsizeMode={'middle'} style={[appStyles.fontMobileTinyLight, styles.unit]}>
                    {childLineItem.name}&nbsp;
                    {childLineItem.quantity > 1 ? [' (', childLineItem.quantity, ')'].join('') : null}
                  </Text>
                  <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
                  <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileTinyLight, styles.price]}>
                    {moneyValue(childLineItem.price.value, 1)} ea
                  </Text>
                  <Text style={[styles.unitSeparator]} />
                  <Text style={[appStyles.fontMobileTinyLight, { alignSelf: 'flex-end' }]}>{UNIT_SPACER}</Text>
                  <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmall, styles.subtotal]}>
                    {moneyValue(childLineItem.price.value, childLineItem.quantity * item.quantity, '')}
                  </Text>
                </View>,
              );
            } else {
              descr.push([childLineItem.name, childLineItem.quantity > 1 ? [' (', childLineItem.quantity, ')'].join('') : null, message].join(''));
            }
          }
        });
      }

      // add separator at the top
      productDetails.unshift(<Text key={`topSeparator_${item.id}`} style={[styles.subseparator]} />);
      // product Price on top of that

      let custLink = null;
      if (descr.length) {
        // if there is data for collapsible show it
        custLink = (
          <View key={`itemError_${item.id}`}>
            <LinkButton onPress={(e) => customizationsPressed(e, item.id)} style={[{ flexDirection: 'row' }]}>
              <View style={[styles.customizationsContainer]}>
                <View style={[styles.priceContainer]}>
                  <Text style={[appStyles.fontMobileBodySmallBald, { marginRight: 8 }]} key={`actionCustomizations_${item.id}`}>
                    Customizations&nbsp;
                  </Text>
                  <Icon
                    key={`customization_${item.id}`}
                    fill={colors.black}
                    stroke={'none'}
                    strokeSecondary={colors.black}
                    size={15}
                    style={[appStyles.smallIcon, { alignSelf: 'center' }]}
                    name={itemState?.[item.id]?.customizations ?? false ? 'triangle-up' : 'triangle-down'}
                  />
                </View>
              </View>
            </LinkButton>
            {(itemState?.[item.id]?.customizations ?? false) && [
              <View style={[styles.priceContainer, { flexDirection: 'column', margin: 0 }]}>
                {descr.map((tt: string, ctr2) => (
                  <Text
                    key={`itemDescription_${ctr2}`}
                    numberOfLines={2}
                    ellipsizeMode={'tail'}
                    style={[appStyles.fontMobileTinyLight, { lineHeight: lineHeight(14) }]}>
                    {tt}
                  </Text>
                ))}
              </View>,
            ]}
          </View>
        );
      }

      // Product name and description on top
      const regularPriceRow = [];
      const sellType = getProductAttributeValue(
        'unitSellType',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      ) ?? { key: 'byEach', label: 'Each' };
      let regPrice = regularPrice ? `${moneyValue(regularPrice)} ea` : '-';
      let totPrice = `${moneyValue(totalPrice)} ea`;
      const averageWeight = getProductAttributeValue(
        'unitAverageBuyWeight',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      const prodPrice = productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant.price?.value ?? totalPrice;
      if (sellType.key === 'weightByEach') {
        regPrice = regularPrice ? `${moneyValue({ ...regularPrice, centAmount: Math.round(regularPrice.centAmount * (averageWeight ?? 1)) })} ea` : '-';
      } else if (sellType.key === 'byWeight') {
        totPrice = `${moneyValue(prodPrice)}/${unitOfMeasure}`;
        regPrice = regularPrice ? `${moneyValue(regularPrice)}'/${unitOfMeasure}` : '-';
      } else {
        unitTotalCount = ((unitsPerPackage ?? 1) * (packageCount ?? 1)).toFixed(1);
      }
      if (regularPrice && regularPrice.centAmount !== prodPrice.centAmount) {
        regularPriceRow.push(
          <Text
            testID="preg3"
            key={'preg3'}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[appStyles.fontMobileTinyLight, styles.subprice, { color: colors.red }]}>
            {totPrice}
          </Text>,
          <Text
            testID="preg4"
            key={'preg4'}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[appStyles.fontMobileTinyLight, styles.subRegprice, { textDecorationLine: 'line-through', textDecorationColor: colors.black }]}>
            {regPrice}
          </Text>,
        );
      } else {
        regularPriceRow.push(
          <Text testID="totalPrice" key={'totalPrice2'} numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileTinyLight, styles.subprice]}>
            {totPrice}
          </Text>,
        );
      }
      let approxLine = null;
      if ((sellType && (sellType.key ?? 'byEach')) === 'weightByEach') {
        approxLine = (
          <Text
            testID="approxTotalPrice"
            key={'approxTotalPrice'}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[appStyles.fontMobileTinyLight, styles.approxSubtotal]}>
            approx
          </Text>
        );
      }

      productDetails.unshift(
        <View key={`title_${item.id}`} style={styles.productInfo}>
          <View style={{ width: '60%' }}>
            <TouchableOpacity {...productTouch} style={{}}>
              <Text numberOfLines={2} ellipsizeMode={'middle'} style={[appStyles.bodySmallBold, { lineHeight: lineHeight(16) }, ...productHeaderInfo]}>
                {attrs.name ?? ''}&nbsp;&nbsp;&nbsp;
              </Text>
              {sellType.key === 'weightByEach' && averageWeight ? (
                <Text
                  testID="unitOfMeasure"
                  key={'unit-of-measure'}
                  numberOfLines={1}
                  adjustsFontSizeToFit={true}
                  style={[appStyles.fontMobileTinyLight, styles.averageStyle]}>
                  {`Avg ${averageWeight} ${unitOfMeasure}`}
                </Text>
              ) : sellType.key === 'byEach' && unitsPerPackage ? (
                <Text
                  testID="unitOfMeasure"
                  key={'unit-of-measure'}
                  numberOfLines={1}
                  adjustsFontSizeToFit={true}
                  style={[appStyles.fontMobileTinyLight, styles.averageStyle]}>
                  {`${unitTotalCount} ${unitOfMeasure}`}
                </Text>
              ) : (
                <></>
              )}
            </TouchableOpacity>
            {custLink}
          </View>
          <View style={{ width: '40%', justifyContent: 'flex-start' }}>
            <View key={`productPriceDetails_${item.id}`} style={[]}>
              <Text key={`prodDetails_${item.id}`} numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, styles.subtotal]}>
                {moneyValue(totalPrice, item.quantity)}
              </Text>
            </View>
            {approxLine}
            {regularPriceRow}
          </View>
        </View>,
      );

      return productDetails;
    },
    [productData, productTouch, order, itemState, customizationsPressed],
  );

  const formatQuantity = useCallback(
    (productKey: { lineItemId: string }, qty: number, estimateProductWeight?: number) => {
      if (!productData) {
        return;
      }

      const item = order?.lineItems.find((x) => x.id === productKey.lineItemId);
      if (item) {
        const pData = productData[item.variant?.sku ?? '']?.masterData;
        if (pData && pData.current) {
          const val = getProductQuantityValue(pData.current, getProductQuantityIndex(pData.current, qty, estimateProductWeight));

          let sellType: { key: string; label: string } = pData.current.masterVariant.attributesRaw.find((f) => f.name === 'unitSellType')?.value ?? {
            key: 'byEach',
            label: 'By Each',
          };

          if (sellType.key === 'byWeight') {
            const unitOfMeasure = getProductAttributeValue('unitOfMeasure', pData.current.masterVariant?.attributesRaw ?? []);
            return `${val}${'\n'}${unitOfMeasure}`;
          } else {
            return val;
          }
        }
      }
    },
    [order?.lineItems, productData],
  );

  const RenderCartItem = useCallback(
    (item: LineItem, index: number, arr: LineItem[]) => {
      let catHeader = null;
      let addItemSeparator = false;
      if (productData) {
        const currData = getRootCategory(productData[item.variant?.sku ?? '']?.masterData?.current?.categories);
        const lastData = index ? getRootCategory(productData[arr[index - 1]?.variant?.sku ?? '']?.masterData?.current?.categories) : undefined;
        const nextData = index + 1 < arr.length ? getRootCategory(productData[arr[index + 1]?.variant?.sku ?? '']?.masterData?.current?.categories) : null;

        if (currData?.name && (lastData?.name ?? '') !== currData.name) {
          catHeader = (
            <View
              key={`catHeaderlineItem_${item.id}`}
              style={[styles.categoryHeader, { backgroundColor: colors.sectionHeader }, index ? utilityStyles.mt3 : {}]}>
              <View style={[styles.itemFrame]}>
                <Text style={[appStyles.bodySmallRegular]}>{currData.name}</Text>
              </View>
            </View>
          );
        }
        if (currData?.name && nextData?.name === currData?.name) {
          addItemSeparator = true;
        }
      }

      const itemQuantity: JSX.Element[] = [];
      itemQuantity.push(
        <Text key={'s1'} style={[appStyles.bodySmallBold, { textAlign: 'center' }]} adjustsFontSizeToFit={true} numberOfLines={2}>
          {formatQuantity({ lineItemId: item.id }, item.quantity, getItemAttributeValue('estimatedTotalWeight', item.custom?.customFieldsRaw ?? []))}
        </Text>,
      );

      const result = [
        <TouchableOpacity key={`renderROLineItemViewKey_${item.id}`} {...productTouch}>
          <View style={[styles.subContainerItem]}>
            <View style={[styles.itemFrame]}>
              <View style={[styles.itemLeft, utilityStyles.mr4]}>
                <View style={[styles.itemLeft1]}>{itemQuantity}</View>
                <View style={[styles.itemLeft2]}>
                  <ProductImage imageUrl={item?.variant?.images?.[0]?.url ?? ''} style={styles.productImage} />
                </View>
              </View>
              <View style={[styles.itemRight]}>{ProductInfo({ lineItem: item })}</View>
            </View>
            {LineItemNote({ item: item })}
            {addItemSeparator && <View style={[styles.separator]} />}
          </View>
        </TouchableOpacity>,
      ];

      if (catHeader) {
        result.unshift(catHeader);
      }
      return result;
    },
    [LineItemNote, ProductInfo, formatQuantity, productData, productTouch],
  );

  const CartItems = useCallback((): JSX.Element | undefined => {
    if (cartIsEmpty) {
      return (
        <View key={'emptyCartViewKey'} style={[styles.subContainerItem, styles.cartEmpty]} testID="emptyCart">
          <Icon
            name="empty-cart"
            size={containerWidth * 0.2}
            style={{ width: containerWidth * 0.2, height: containerWidth * 0.2 }}
            stroke="none"
            fill={colors.sectionPad}
          />
          <Text style={appStyles.bodyMediumLight}>{t('cartEmptyText')}</Text>
        </View>
      );
    } else if (productData) {
      const lItems = invidLineItems(order?.lineItems ?? []);
      // sorting by orderHint
      const sItems = lItems.sort((p1, p2) => {
        if (p1.variant?.sku) {
          const p1Data = getRootCategory(productData[p1.variant?.sku ?? '']?.masterData?.current?.categories);
          const p2Data = getRootCategory(productData[p2.variant?.sku ?? '']?.masterData?.current?.categories);
          if ((p1Data?.orderHint ?? 0.8) === (p2Data?.orderHint ?? 0.8)) {
            const c1Name = getRootCategory(productData[p1.variant?.sku ?? '']?.masterData?.current?.categories)?.name ?? '---';
            const c2Name = getRootCategory(productData[p2.variant?.sku ?? '']?.masterData?.current?.categories)?.name ?? '---';
            return c1Name < c2Name ? -1 : c1Name > c2Name ? 1 : 0;
          } else {
            return (p1Data?.orderHint ?? 0.8) > (p2Data?.orderHint ?? 0.8) ? 1 : -1;
          }
        } else {
          return 1;
        }
      });
      return <>{sItems.map((li, i, a) => RenderCartItem(li, i, a))}</>;
    }
  }, [cartIsEmpty, productData, t, order?.lineItems, RenderCartItem]);

  return (
    <TouchableOpacity key={`itemizedPurchaseKey_${order?.id}`} activeOpacity={1} style={[styles.mainContainer]}>
      <View style={[styles.flex1]}>
        <LineHeader />
        {isLoadingItems ? <ActivityIndicator key={'itemizedPurchaseActivityIndicator'} /> : CartItems()}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  separator: {
    height: 1,
    width: containerWidth,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  categoryHeader: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.cream,
    paddingTop: 10,
  },
  subContainerItem: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.cream,
    paddingTop: 8,
  },
  orderNoteContainer: {
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  orderNoteSubContainer: {
    flexDirection: 'column',
    width: containerWidth,
    alignSelf: 'center',
  },
  orderNoteSubContent: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
  },
  leadWarningContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
  },
  approxSubtotal: {
    marginTop: scale(-6),
    marginBottom: scale(2),
    textAlign: 'right',
    flex: 0.29,
    marginLeft: 'auto',
  },
  leadWarningContainerText: {
    color: colors.red,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  noteContainerItem: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.cream,
    paddingTop: 15,
  },
  itemFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
    paddingBottom: 5,
  },
  flex1: { flex: 1 },
  flexrow: { flexDirection: 'row' },
  flexcol: { flexDirection: 'column' },
  itemsHeader: {
    backgroundColor: colors.sectionPad,
    paddingBottom: 2,
    paddingTop: 6,
    marginBottom: 8,
  },
  itemLeftHeader: {
    minWidth: layout.width,
    width: layout.width,
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  empty: {
    width: containerWidth,
    textAlign: 'center',
    alignSelf: 'center',
  },
  flatPadContainer: {
    backgroundColor: colors.white,
    width: screenWidth,
    flexDirection: 'column',
    alignSelf: 'center',
  },
  flatPad: {
    backgroundColor: colors.white,
    width: containerWidth,
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: scale(12),
    marginTop: scale(12),
    minHeight: scale(60),
  },

  notePad: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    marginBottom: 10,
  },
  itemLeft: {
    minWidth: layout.width,
    width: layout.width,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    flex: 0,
    justifyContent: 'space-between',
  },
  itemLeft1: {
    alignSelf: 'stretch',
    minWidth: 40,
    paddingRight: 5,
    flex: 1,
    alignItems: 'center',
  },
  itemLeft2: {
    width: layout.imageWidth,
    minWidth: layout.imageWidth,
  },
  itemLeftCart: {
    minWidth: layout.imageWidth,
    width: layout.imageWidth,
    flexDirection: 'column',
    alignSelf: 'flex-start',
    flex: 0,
    alignItems: 'flex-start',
  },
  headerQty: {
    minWidth: layout.width,
    width: layout.width,
    textAlign: 'left',
  },
  headerQtyCart: {
    minWidth: layout.width,
    width: layout.width,
    textAlign: 'left',
  },
  itemQuantity: {
    alignSelf: 'center',
    marginTop: 10,
    width: 50,
    height: 32,
    borderColor: colors.productBorder,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 1.5 },
    shadowOpacity: 0.2,
    shadowRadius: 1.5,
  },
  actionLinksBottom: {},
  actionLinksBottomContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flex: 1,
  },
  actionLinksLeft: {
    textAlign: 'center',
    marginTop: 3,
    marginBottom: 3,
  },
  actionLinksLeftContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 0,
  },
  productImage: {
    width: layout.imageWidth,
    alignSelf: 'center',
    height: layout.imageHeight,
    borderWidth: 1,
    borderColor: colors.productBorder,
  },
  itemRight: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 10,
  },
  priceContainer: {
    flexDirection: 'row',
  },
  customizationsContainer: {
    paddingTop: 8,
    flexDirection: 'column',
  },
  errorMessage: {
    flexDirection: 'row',
    ...appStyles.formErrorText,
    textAlign: 'left',
  },
  subseparator: {
    height: 0.33,
    width: '100%',
    marginBottom: 2,
    marginTop: 2,
    backgroundColor: colors.cream,
  },
  subtotal: {
    textAlign: 'right',
    flex: 0.29,
    alignSelf: 'flex-end',
    lineHeight: 26,
  },
  price: {
    textAlign: 'right',
    flex: 0.2,
    alignSelf: 'flex-end',
    lineHeight: 26,
  },
  subprice: {
    textAlign: 'right',
    alignSelf: 'flex-end',
    lineHeight: lineHeight(12 * 1.2),
  },
  newPrice: {
    color: colors.red,
  },
  unitHeader: {
    flex: 0.55,
    marginRight: 1,
  },
  unit: {
    flex: 0.55,
    marginRight: 1,
    alignSelf: 'flex-end',
    lineHeight: 26,
  },
  unitSeparator: {
    width: 0.03,
    height: '100%',
    marginRight: 5,
    marginLeft: 8,
    backgroundColor: colors.darkCream,
    alignSelf: 'flex-end',
  },
  marginTopAuto: {
    marginTop: 'auto',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  cartEmpty: {
    marginBottom: 25,
  },
  swipedRow: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.sectionHeader,
  },
  deleteButton: {
    backgroundColor: colors.red,
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: 20,
    marginLeft: 'auto',
  },
  menuOption: {
    padding: 0,
    margin: 0,
    height: 38,
  },
  subRegprice: {
    marginTop: -4,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  menuOptions: {
    paddingTop: 5,
    paddingBottom: 5,
    width: 200,
    height: 86,
    backgroundColor: colors.white,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    borderColor: colors.darkCream,
    borderWidth: 1,
    borderRadius: 8,
  },
  menuOptionText: {
    paddingLeft: 14,
    backgroundColor: colors.white,
    lineHeight: 38,
    textAlignVertical: 'top',
  },
  menuOptionWrapper: {
    backgroundColor: colors.sectionBorder,
    color: 'white',
  },
  menuOptionsTouchable: {
    activeOpacity: 0.75,
    textAlign: 'center',
  },
  accessory: {
    width: containerWidth,
    height: 48,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    paddingHorizontal: 8,
  },
  averageStyle: {
    marginTop: 2,
  },
  productInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: scale(-18),
  },
});

export default ItemizedPurchase;

import { Address, DateRange, FulfillmentType, PurchaseType, ShippingMethodType } from '@fieldera-raleys/client-common';
import dayjs from 'dayjs';
import { create } from 'zustand';
import { PurchaseHistoryFilterStore } from './storeTypes';

const defaultValue: {
  purchaseType: PurchaseType;
  dateRange: DateRange & { default: boolean };
  fulfillmentType: FulfillmentType;
  location: Address;
} = {
  purchaseType: 'Online',
  dateRange: { startDate: dayjs().month(-3).toDate(), default: true },
  fulfillmentType: 'All',
  location: {
    address1: 'All',
    addressType: 'home',
    defaultAddress: false,
    isValidated: false,
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    phone: '',
  } as Address,
};

const usePurchaseHistoryFilterStore = create<PurchaseHistoryFilterStore>()((set, _) => ({
  ...defaultValue,
  setResetFilters: () => {
    set((state) => ({ ...state, ...defaultValue }), true);
  },
  setPurchaseType: (type: PurchaseType) => {
    set((state) => ({ ...state, purchaseType: type }), true);
  },
  setDateRange: (dateRange: DateRange, isDefault: boolean) => {
    set((state) => ({ ...state, dateRange: { ...dateRange, default: isDefault } }), true);
  },
  setFulfillmentType: (type: keyof typeof ShippingMethodType | 'All') => {
    set((state) => ({ ...state, fulfillmentType: type }), true);
  },
  setLocation: (location: Address) => {
    set((state) => ({ ...state, location: location }), true);
  },
  clearPurchasesStore: () => {
    set(
      (state) => ({
        ...state,
        ...defaultValue,
      }),
      true,
    );
  },
}));

export default usePurchaseHistoryFilterStore;

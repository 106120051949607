import { Document, Page } from '@bloomreach/spa-sdk';
import {
  AccountStackRoutes,
  AppStackRoutes,
  HelpCenterStackRoutes,
  MessageCenterStackRoutes,
  RewardsStackRoutes,
  RootTabRoutes,
  ShopStackRoutes,
  StoreFinderStackRoutes,
} from '@navigation/routes';
import { StackActions } from '@react-navigation/native';
import { linktoNavigation } from '@utils/helpers';
import logger from '@utils/logger';
import { Linking } from 'react-native';

export function getCarouselItem(page: Page, models: Record<string, any>, maxCount: number): Document[] {
  return [...Array(maxCount).keys()].map((n) => {
    const ref = models[n].$ref;
    return ref && page.getContent<Document>(ref);
  });
}

export function stripHtml(value: string): string {
  const regex = /(<([^>]+)>)/gi;
  return value && value.replace(regex, '');
}

export function brxmNavigation(url: string | undefined, navigationType: string | undefined, navigation: any, linkto: any, showAlertModal: any): any {
  if (url?.length) {
    if (url.startsWith('http')) {
      return Linking.openURL(url).catch((err) => logger.error(err, { url }));
    } else {
      linktoNavigation(url, linkto);
    }
  } else if (navigationType?.length) {
    if (navigationType === 'CategoryScreen') {
      return navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.Categories } });
    } else if (navigationType === 'FutureFeature') {
      showAlertModal();
    } else if (navigationType === 'ShelfGuideHelp') {
      return navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.ShelfGuideHelp } });
    } else if (navigationType === 'PersonalizedOffers') {
      return navigation.dispatch(
        StackActions.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Rewards,
          params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Personalized' } },
        }),
      );
    } else if (navigationType === 'WeeklyExclusives') {
      return navigation.dispatch(
        StackActions.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Rewards,
          params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Member Deals' } },
        }),
      );
    } else if (navigationType === 'DigitalCoupons') {
      return navigation.dispatch(
        StackActions.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Rewards,
          params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Digital Coupons' } },
        }),
      );
    } else if (navigationType === 'MyLists') {
      return navigation.dispatch(
        StackActions.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Account,
          params: { screen: AccountStackRoutes.ManageShoppingList },
        }),
      );
    } else if (navigationType === 'SEDollars') {
      return navigation.dispatch(
        StackActions.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Wallet,
          params: { toDollarsTab: true, showBack: true },
        }),
      );
    } else if (navigationType === 'WeekyAd') {
      return navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.WeeklyAds } });
    } else if (navigationType === 'ShelfGuide') {
      return navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.ShelfGuide } });
    } else if (navigationType === 'Collections') {
      return navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.CollectionsLanding } });
    } else if (navigationType === 'PurchaseHistory') {
      return navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.OrderHistory,
        },
      });
    } else if (navigationType === 'Inbox') {
      return navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.MessageCenter,
          params: { screen: MessageCenterStackRoutes.Inbox },
        },
      });
    } else if (navigationType === 'CommunicationPreferences') {
      return navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.CommunicationPreferences,
        },
      });
    } else if (navigationType === 'AccountProfile') {
      return navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.EditProfile,
        },
      });
    } else if (navigationType === 'AddressBook') {
      return navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.AddressBook,
          params: {},
        },
      });
    } else if (navigationType === 'AppSettings') {
      return navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.AppSettings,
        },
      });
    } else if (navigationType === 'HelpCenter') {
      return navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.HelpCenter,
          params: {
            screen: HelpCenterStackRoutes.HelpCenterScreen,
            params: {},
          },
        },
      });
    } else if (navigationType === 'StoreLocator') {
      return navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.StoreFinder,
          params: {
            screen: StoreFinderStackRoutes.StoreFinderScreen,
            params: {},
          },
        },
      });
    } else {
      return linkto('/home');
    }
  } else {
    return null;
  }
}

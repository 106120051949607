import { Cart, CustomerContact, ShippingAddress } from '@fieldera-raleys/client-commercetools/schema';
import { UserProfile } from '@fieldera-raleys/client-common';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { getLineItemsToEvaluateCart } from '@fieldera-raleys/client-common/utils';
import { offerService } from '@services/brandywine';
import useShopStore from '@store/shopStore';
import useUserProfileStore from '@store/userProfileStore';

export const getCustomerContact = (user?: UserProfile): CustomerContact | undefined => {
  if (!user) {
    user = useUserProfileStore.getState().userProfile;
    if (!user) {
      return;
    }
  }
  return {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    loyaltyId: user.loyaltyId,
    sepiNumber: user.sepiNumber,
    allowText: true,
  } as CustomerContact;
};

export const getDefaultShipping = (): ShippingAddress | undefined => {
  const location = useShopStore.getState().selectedStore;
  if (!location) {
    return;
  }
  return {
    zip: location.address.zip,
    state: location.address.state,
    city: location.address.city,
  } as ShippingAddress;
};

export const evaluateCart = async (cart: Cart, storeNumber: string, shippingMethod: 'pickup' | 'delivery'): Promise<Promotion[]> => {
  const products = getLineItemsToEvaluateCart(cart.lineItems ?? []);
  const res = await offerService.evaluateCart('app', storeNumber, shippingMethod, products);
  return res;
};

export const getCalculateSalesTax = (obj: Cart, defaultValue?: string) => {
  return obj?.custom?.customFieldsRaw?.find((x) => x.name === 'calculateSalesTax')?.value ?? defaultValue;
};

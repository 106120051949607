import { createStackNavigator } from '@react-navigation/stack';
import {
  AppBenefitsScreen,
  CreateAccountInitialScreen,
  CreateAccountScreen,
  ForgotEmailScreen,
  ForgotPasswordScreen,
  LoginScreen,
  ResetPasswordScreen,
  VerifyOTCScreen,
  WebBrowserScreen,
} from '@screens';
import UpdateExpiredPasswordScreen from '@screens/UpdateExpiredPasswordScreen';
import { useUserSettingsStore } from '@store';
import React from 'react';
import { NavigationProps } from './declarations';
import { AuthStackParamList, AuthStackRoutes } from './routes';

const AuthStack = createStackNavigator<AuthStackParamList>();

const AuthStackNavigation = ({ queryParams }: NavigationProps) => {
  const { userSettings } = useUserSettingsStore();

  return (
    <AuthStack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerShown: false,
      }}
      initialRouteName={userSettings?.showAppBenefits ? AuthStackRoutes.AppBenefits : AuthStackRoutes.SignIn}>
      <AuthStack.Screen name={AuthStackRoutes.AppBenefits} component={AppBenefitsScreen} options={{ title: 'App Benefits' }} initialParams={queryParams} />
      <AuthStack.Screen name={AuthStackRoutes.SignIn} component={LoginScreen} options={{ title: 'Log In' }} initialParams={queryParams} />
      <AuthStack.Screen
        name={AuthStackRoutes.ForgotPassword}
        component={ForgotPasswordScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
        }}
        initialParams={queryParams}
      />
      <AuthStack.Screen
        name={AuthStackRoutes.ForgotEmail}
        component={ForgotEmailScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
        }}
        initialParams={queryParams}
      />
      <AuthStack.Screen
        name={AuthStackRoutes.VerifyOTC}
        component={VerifyOTCScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
        }}
        initialParams={queryParams}
      />
      <AuthStack.Screen
        name={AuthStackRoutes.ResetPassword}
        component={ResetPasswordScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
        }}
        initialParams={queryParams}
      />
      <AuthStack.Screen
        name={AuthStackRoutes.CreateAccountInitial}
        component={CreateAccountInitialScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
        }}
        initialParams={queryParams}
      />
      <AuthStack.Screen
        name={AuthStackRoutes.CreateAccount}
        component={CreateAccountScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
        }}
        initialParams={queryParams}
      />
      <AuthStack.Screen
        name={AuthStackRoutes.WebBrowser}
        component={WebBrowserScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'modal',
        }}
      />
      <AuthStack.Screen
        name={AuthStackRoutes.UpdateExpiredPassword}
        component={UpdateExpiredPasswordScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
        }}
        initialParams={queryParams}
      />
    </AuthStack.Navigator>
  );
};

export default AuthStackNavigation;

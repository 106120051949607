import Carousel from '@components/Carousel';
import DropShadow from '@components/DropShadow';
import Text from '@components/Text';
import OfferCard from '@components/somethingExtra/OfferCard';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import { AddLineItem, LineItem, Money, Product } from '@fieldera-raleys/client-commercetools/schema';
import { defaultMoney } from '@fieldera-raleys/client-commercetools/utils';
import { ProductType } from '@fieldera-raleys/client-common';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { CartValidationResult } from '@fieldera-raleys/client-common/types/cart';
import { useEffectOnce } from '@hooks';
import { AppStackRoutes, CustomizeStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useCartStore, useShopStore, useShoppingListsStore } from '@store';
import { ProductKey } from '@store/storeTypes';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { containerWidth, defaultFontSize, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import * as Sharing from 'expo-sharing';
import _ from 'lodash';
import React, { RefObject, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Animated, GestureResponderEvent, Platform, ScrollView, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { Swipeable } from 'react-native-gesture-handler';
import { Menu, MenuOption, MenuOptions, MenuProvider, MenuTrigger } from 'react-native-popup-menu';
import ViewShot, { releaseCapture } from 'react-native-view-shot';
import { useCustomModalContext } from '../../contexts';
import logger from '../../utils/logger';
import {
  getAllowSubstitution,
  getItemAttributeValue,
  getOrderNote,
  getProductQuantityIndex,
  getProductQuantityValue,
  getRegularItemPrice,
  getSubTotalItemPrice,
  invidLineItems,
  moneyValue,
} from '../../utils/orderHelpers';
import { getProductAvailablity, getProductsfromCommerceTools, getRootCategory, getfulfillmentLeadTimeMinutes } from '../../utils/productHelper';
import Icon from '../Icon';
import KeyboardCloseButton from '../KeyboardCloseButton';
import LinkButton from '../LinkButton';
import QuantityBox from '../QuantityBox';
import { Form, FormField, FormSwitch, SubmitButton } from '../forms';
import ItemSavings from './ItemSavings';
import { ItemSubstitution } from './ItemSubstitution';
import ProductImage from './ProductImage';

const containerLayout = { width: 106, imageWidth: 72, imageHeight: 72 };

type ProdDataType = {
  [key: string]: Product;
};

type ItemizedHandles = {
  flush: () => void;
};

export type ItemizedCartProps = {
  validationResult?: CartValidationResult;
  readOnly?: boolean;
  scrollViewRef?: RefObject<ScrollView>;
  itemId?: string;
};

const CARD_WIDTH = 168;
const SLIDE_GAP = 12;
const carouselConfig = {
  slidesPerPage: 1,
  nextSlidePartial: CARD_WIDTH * 0.44,
  slideWidth: CARD_WIDTH,
  sgPerLine: 1,
};

export const ItemizedCart = forwardRef(({ validationResult, itemId, readOnly = true, scrollViewRef }: ItemizedCartProps, ref: React.Ref<ItemizedHandles>) => {
  const [itemState, setItemState] = useState<{
    [key: string]: {
      openNote?: boolean;
      remove?: boolean;
      save?: boolean;
      customizations?: boolean;
    };
  }>({});
  const itemStateRef = useRef(itemState);

  const {
    setSubstAllow,
    transitCart,
    cart,
    getMaxProductQuantity,
    setLineItemQuantity,
    setOrderNote,
    setLineItemNote,
    getLineItemNote,
    removeLineItem,
    getCartQuantity,
    cartData,
    setCartData,
    addStandardLineItems,
    addCustomizableLineItem,
  } = useCartStore();
  const { addItemsToList, saveForLaterList } = useShoppingListsStore();
  const { selectedStore } = useShopStore();
  const { t } = useTranslation('cart');
  const navigation = useNavigation();
  const { showModal, showAlertPopup, hideModal } = useCustomModalContext();
  const [layout, setLayout] = useState({ bottomOfListCoord: { height: 0, width: 0, x: 0, y: 0 } });
  const showCartSummary = cart && cart.lineItems.length && !readOnly;
  const cartIsEmpty = getCartQuantity() === 0;
  const UNIT_SPACER = null;
  const productTouch = useMemo(() => (readOnly ? { activeOpacity: 1 } : {}), [readOnly]);

  const addNotesPressed = useCallback(
    (iId: string) => {
      setItemState({ ...itemState, [iId]: { ...(itemState[iId] ?? {}), openNote: !(itemState[iId]?.openNote ?? false) } });
    },
    [itemState],
  );

  const [productData, setProductData] = useState<ProdDataType | undefined>();

  const getQuantityIndex = useCallback(
    (sku: string, quantity: number, estimatedTotalWeight?: number) => {
      if (productData) {
        if (productData[sku]?.masterData?.current) {
          return getProductQuantityIndex(productData[sku].masterData.current!, quantity, estimatedTotalWeight);
        }
      }
      return 0;
    },
    [productData],
  );

  const formatQuantity = useCallback(
    (sku: string, qty: number, estimateProductWeight?: number) => {
      if (productData) {
        let sellType: { key: string; label: string } = productData[sku]?.masterData.current?.masterVariant.attributesRaw.find((f) => f.name === 'unitSellType')
          ?.value ?? {
          key: 'byEach',
          label: 'By Each',
        };
        if (productData[sku]?.masterData?.current) {
          const val = getProductQuantityValue(
            productData[sku].masterData.current!,
            getProductQuantityIndex(productData[sku].masterData.current!, qty, estimateProductWeight),
          );
          if (sellType.key === 'byWeight') {
            const unitOfMeasure = getProductAttributeValue('unitOfMeasure', productData[sku]?.masterData?.current?.masterVariant?.attributesRaw ?? []);
            return `${val}${'\n'}${unitOfMeasure}`;
          } else {
            return val;
          }
        }
      }
    },
    [productData],
  );

  const formatQuantityFromIndex = useCallback(
    (sku: string, index: number) => {
      if (productData) {
        let sellType: { key: string; label: string } = productData[sku]?.masterData.current?.masterVariant.attributesRaw.find((f) => f.name === 'unitSellType')
          ?.value ?? {
          key: 'byEach',
          label: 'By Each',
        };
        if (productData[sku]?.masterData?.current) {
          const val = getProductQuantityValue(productData[sku].masterData.current!, index) ?? index;
          if (sellType.key === 'byWeight') {
            const unitOfMeasure = getProductAttributeValue('unitOfMeasure', productData[sku]?.masterData?.current?.masterVariant?.attributesRaw ?? []);
            return `${val} ${unitOfMeasure}`;
          } else {
            return '' + val;
          }
        }
      }
    },
    [productData],
  );

  const customizationsPressed = useCallback(
    (___: GestureResponderEvent, iId: string) => {
      setItemState({ ...itemState, [iId]: { ...(itemState[iId] ?? {}), customizations: !(itemState[iId]?.customizations ?? false) } });
    },
    [itemState],
  );

  const closeItemNote = useCallback(
    (iId: string) => {
      setItemState({ ...itemState, [iId]: { ...(itemState[iId] ?? {}), openNote: false } });
    },
    [itemState],
  );

  const debounceDelay: number = 500;
  const [lastDebounceMethod, setLastDebounceMethod] = useState<string>('');
  const _setOrderNote = useMemo(() => _.debounce(setOrderNote, debounceDelay), [setOrderNote]);
  const _setLineItemNote = useMemo(() => _.debounce(setLineItemNote, debounceDelay), [setLineItemNote]);

  const flushAll = useCallback(() => {
    _setOrderNote.flush();
    _setLineItemNote.flush();
  }, [_setLineItemNote, _setOrderNote]);

  const debounceUpdate = useCallback(
    (method: string, value: string, iId?: ProductKey | string) => {
      if (lastDebounceMethod !== method) {
        setLastDebounceMethod(method);
        flushAll();
      }
      switch (method) {
        case 'setOrderNote':
          _setOrderNote(value);
          break;
        case 'setLineItemNote':
          _setLineItemNote(iId as string, value);
          break;
      }
    },
    [_setLineItemNote, _setOrderNote, flushAll, lastDebounceMethod],
  );

  useEffectOnce(() => {
    // keep reference for flushing
    return () => {
      flushAll();
    };
  });

  useImperativeHandle(ref, () => ({
    flush() {
      flushAll();
    },
  }));

  const handleNavigation = useCallback(
    (item: LineItem): void => {
      if (!readOnly) {
        item.variant?.sku && navigation.navigate(AppStackRoutes.ProductDetails, { productKey: item.variant.sku });
      }
    },
    [navigation, readOnly],
  );

  const editCustomizedItem = useCallback(
    async (customizedItem: LineItem) => {
      navigation.dispatch(
        StackActions.push(AppStackRoutes.Customize, {
          screen: CustomizeStackRoutes.Initial,
          params: {
            productKey: '',
            prevPageData: {},
            editCustomize: true,
            lineItemId: customizedItem.id,
          },
        }),
      );
    },
    [navigation],
  );

  const LineHeader = useCallback((): JSX.Element => {
    const setAllowSubst = (allowSubst: boolean) => {
      if (!allowSubst) {
        showModal({
          title: t('areYouSure'),
          children: <Text style={appStyles.fontMobileBodySmallRegular}>{t('backupMessage')}</Text>,
          location: 'top',
          cancelButtonText: t('backButton'),
          okButtonText: t('yesButton'),
          okButtonOnPress: () => setSubstAllow(allowSubst),
        });
      } else {
        setSubstAllow(allowSubst);
      }
    };
    return readOnly ? (
      itemId ? (
        <View key={'lineLineItemHeaderKey1'} style={[styles.subContainerItem, styles.itemFrame, utilityStyles.py1]} />
      ) : (
        <View key={'lineLineItemHeaderKey2'} style={[styles.subContainerItem, styles.itemsHeader, utilityStyles.py1]}>
          <View style={[styles.itemFrame]}>
            <View style={[styles.itemLeft, utilityStyles.mr4]}>
              <Text testID="headerQty" numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileBodySmallBald, { lineHeight: scale(26) }]}>
                {t('headerQty')}
              </Text>
            </View>
            <View style={[styles.itemRightRO, styles.fexRow]}>
              <Text
                testID="headerItem"
                numberOfLines={1}
                adjustsFontSizeToFit={true}
                style={[appStyles.fontMobileBodySmallBald, styles.itemRightRO, { lineHeight: scale(26) }]}>
                {t('headerItem')}
              </Text>
              <Text
                testID="headerPrice"
                numberOfLines={1}
                adjustsFontSizeToFit={true}
                style={[appStyles.fontMobileBodySmallBald, styles.subtotal, utilityStyles.mla, { lineHeight: scale(26) }]}>
                {t('headerPrice')}
              </Text>
            </View>
          </View>
        </View>
      )
    ) : cartIsEmpty || !productData ? (
      <></>
    ) : (
      <View>
        <Form initialValues={{ allowSubstitutions: getAllowSubstitution(cart) }} onSubmit={() => Promise.resolve()}>
          <FormSwitch name={'allowSubstitutions'} preLabel={t('backupAllowToggle')} onChange={() => setAllowSubst(!getAllowSubstitution(cart))} />
        </Form>
      </View>
    );
  }, [cart, cartIsEmpty, itemId, productData, readOnly, setSubstAllow, showModal, t]);

  useEffect(() => {
    itemStateRef.current = itemState;
  }, [itemState]);

  useEffect(() => {
    if ((!readOnly && productData && !cartIsEmpty && cartData.shareCart) ?? false) {
      setCartData({ shareCart: false });
      onShareCart();
    }
  }, [cartData, setCartData, productData, cartIsEmpty, readOnly]);

  const [cartOffers, setCartOffers] = useState<JSX.Element | null>();
  useEffect(() => {
    const cartPromos = JSON.parse(cart?.custom?.customFieldsRaw?.find((f) => f.name === 'promotions')?.value ?? '[]') as Promotion[];

    const allOffers = cartPromos.filter((p) => p.ExtPromotionTypeCode !== 'PPP' && !promotionApplied(p) && okToShow(p));

    if (allOffers.length) {
      setCartOffers(
        <View style={[styles.cartCarouselWrapper, styles.flexRow, styles.alignSelfStart, utilityStyles.my2]}>
          <View style={[{ width: screenWidth }, styles.justifyContentCenter]}>
            <View style={[utilityStyles.my3, { width: containerWidth }, styles.alignSelfCenter]}>
              <Text testID="additionalOffers" style={[appStyles.fontMobileListSmallLeft, styles.alignSelfStart]}>
                Additional Offers & Savings
              </Text>
            </View>
            <Carousel
              windwoSize={allOffers.length}
              snapToInterval={carouselConfig.slideWidth + SLIDE_GAP}
              contentContainerStyle={{
                ...styles.carouselContainer,
                width: (allOffers?.length ? allOffers?.length : 0) * (carouselConfig.slideWidth + SLIDE_GAP) + SLIDE_GAP,
              }}
              entities={allOffers}
              renderItem={({ item, index }) => {
                return (
                  <View style={styles.carouselView}>
                    <OfferCard item={item} index={index} />
                  </View>
                );
              }}
              pagerStyle={styles.pagerStyle}
              pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
              pagerInactiveItemStyle={appStyles.carouselDotsSmall}
              showPager={false}
              removeClippedSubviews={false}
            />
          </View>
        </View>,
      );
    } else {
      setCartOffers(null);
    }
  }, [productData, cartIsEmpty, readOnly, cart?.custom?.customFieldsRaw]);

  useEffect(() => {
    const getProdSet = async (skus: string[]): Promise<Product[]> => {
      return await getProductsfromCommerceTools(skus);
    };
    if (cart) {
      const skus: string[] = cart.lineItems.reduce<string[]>((acc: string[], i: LineItem) => {
        if (i.variant?.sku && acc.findIndex((o) => o === i.variant!.sku)) {
          acc.push(i.variant.sku);
        }
        return acc;
      }, []);
      getProdSet(skus).then((pd) => {
        const pData: ProdDataType = pd.reduce((acc, p: Product) => {
          acc[p.masterData?.current?.masterVariant.sku ?? ''] = p;
          p.masterData?.current?.variants.forEach((vi) => {
            acc[vi?.sku ?? ''] = p;
          });

          return acc;
        }, {} as ProdDataType);
        setProductData(pData);
      });
    }
  }, [cart]);

  const viewShotRef = useRef<ViewShot>(null);
  const onShareCart = async () => {
    try {
      if (viewShotRef?.current?.capture) {
        viewShotRef.current.capture().then(
          async (val) => {
            let url = Platform.select({ ios: 'file://' + val, android: val, web: val })!;

            if (!(await Sharing.isAvailableAsync())) {
              logger.debug('Your device does not support sharing', { method: 'onShareCart' });
              return;
            }

            const shareOptions = {
              mimeType: 'image/png',
              UTI: 'public.png',
              dialogTitle: 'Share Cart', // android only
            };

            await Sharing.shareAsync(url, shareOptions);

            releaseCapture(val);
          },
          (error) => logger.error(error, { method: 'onShareCart' }),
        );
      }
    } catch (e) {
      logger.error(`Share Cart exception ${e}`);
    }
  };

  const undoRefs = useRef<string[]>([]);
  const undoRemove = useCallback(
    async (item: LineItem, originalQuantity: number, childLineItems: AddLineItem[] = [], itemNote?: string, product?: Product) => {
      if (undoRefs.current) {
        if (undoRefs.current?.find((id) => id === item.id)) {
          return;
        } else {
          undoRefs.current.push(item.id); // keep track of products already undone to no re-add twice (extra CT guard to prevent dup std products that have unique attreibutes (note, weight, subst, etc))
          if (undoRefs.current.length > 50) {
            undoRefs.current.shift();
          }
        }
        if (item.productType?.name === ProductType.CONFIGURABLE) {
          // Undo Customizable items
          let customizableItems: AddLineItem[] = [];
          customizableItems.push({
            sku: item.variant?.sku ?? '',
            quantity: originalQuantity,
            itemNote: itemNote,
            product: product,
          });
          customizableItems.push(...childLineItems);
          addCustomizableLineItem(customizableItems);
        } else {
          // Undo Standard items
          const standardLineItem: AddLineItem = {
            sku: item.variant?.sku ?? '',
            quantity: originalQuantity,
            itemNote: itemNote,
            product: product,
          };
          addStandardLineItems([standardLineItem]);
        }
      }
    },
    [addCustomizableLineItem, addStandardLineItems],
  );

  const handleRemoveLineItem = useCallback(
    (item: LineItem) => {
      if (item.variant?.sku) {
        let childLineItems: AddLineItem[] = [];
        let itemNote = getLineItemNote(item.id);
        const customField = item.custom?.customFieldsRaw?.find((x) => x.name === 'childLineItems');
        if (customField) {
          const childLineItemIds = customField ? String(customField.value).split(',') : [];
          childLineItemIds.forEach((lineItemId) => {
            const childLineItem = cart?.lineItems.find((x: LineItem) => x.id === lineItemId);
            if (childLineItem) {
              childLineItems.push({
                sku: childLineItem.variant?.sku ?? '',
                quantity:
                  getQuantityIndex(
                    childLineItem.variant?.sku ?? '',
                    childLineItem.quantity,
                    getItemAttributeValue('estimatedTotalWeight', childLineItem.custom?.customFieldsRaw ?? []),
                  ) ?? 1,
                itemNote: getLineItemNote(childLineItem.id),
                parentLineItemId: item.variant?.sku ?? '',
                customStepSort: String(childLineItem.custom?.customFieldsRaw?.find((ci) => ci.name === 'customStepSort')?.value) ?? 0,
                product: productData?.[childLineItem.variant?.sku ?? ''],
              });
            }
          });
        }

        removeLineItem(item.id).then((id) => {
          // this does not happen for customized items, not used after reoval so not yet a bug
          if (itemStateRef?.current?.[id]) {
            setItemState({ ...itemState, [item.id]: { ...itemState[item.id], remove: false } });
          }
        });
        setItemState({ ...itemState, [item.id]: { ...itemState[item.id], remove: true } });

        const originalQuantity = getQuantityIndex(
          item.variant?.sku ?? '',
          item.quantity,
          getItemAttributeValue('estimatedTotalWeight', item.custom?.customFieldsRaw ?? []),
        );

        showAlertPopup({
          message: 'Item removed from cart',
          containerStyle: styles.alertBox,
          linkText: 'Undo',
          linkAction: () => undoRemove(item, originalQuantity, childLineItems, itemNote, productData?.[item.variant?.sku ?? '']),
        });
      }
      return false;
    },
    [cart?.lineItems, getLineItemNote, getQuantityIndex, itemState, productData, removeLineItem, showAlertPopup, undoRemove],
  );

  const [removeOnSwipe, setRemoveOnSwipe] = useState<string>('');
  const renderRightActions = useCallback(
    (progress: Animated.AnimatedInterpolation<string | number>, dragAnimatedValue: Animated.AnimatedInterpolation<string | number>, item: LineItem) => {
      const opacity = dragAnimatedValue.interpolate({
        inputRange: [-40, 0],
        outputRange: [1, 0],
        extrapolate: 'clamp',
      });

      if (Number(JSON.stringify(progress)) > 3.5) {
        setRemoveOnSwipe(item.id);
      }

      return (
        <TouchableOpacity onPress={() => handleRemoveLineItem(item)} style={styles.swipedRow}>
          <Animated.View style={[styles.deleteButton, { opacity }]}>
            <Icon testID="closeIcon" stroke={colors.white} name={'x-close'} size={25} />
          </Animated.View>
        </TouchableOpacity>
      );
    },
    [handleRemoveLineItem],
  );

  const scrollToSavedForLater = useCallback(() => {
    scrollViewRef?.current?.scrollTo({
      y: layout.bottomOfListCoord.y + 400,
      animated: true,
    });
  }, [layout.bottomOfListCoord.y, scrollViewRef]);

  const saveForLater = useCallback(
    async (item: LineItem, estimatedTotalWeight?: number) => {
      try {
        const children = cart?.lineItems.filter((cli) => cli?.custom?.customFieldsRaw?.find((x) => x.name === 'parentLineItemId')?.value === item?.id);
        const prData = productData?.[item.variant?.sku ?? '']?.masterData?.current;
        var addItems: AddLineItem[] = [];
        addItems.push({
          sku: item?.variant?.sku ?? '',
          quantity: prData ? getProductQuantityIndex(prData, item?.quantity, estimatedTotalWeight) : item?.quantity ?? 1,
        });

        if (children?.length) {
          children?.forEach((ch) => {
            addItems.push({
              sku: ch?.variant?.sku ?? '',
              quantity: ch?.quantity ?? 1,
              parentLineItemId: item?.variant?.sku ?? '',
              customStepSort: String(ch.custom?.customFieldsRaw?.find((cf) => cf.name === 'customStepSort')?.value) ?? 0,
              itemNote: ch.custom?.customFieldsRaw?.find((cf) => cf.name === 'itemNote')?.value ?? null,
            });
          });
        }
        await addItemsToList(saveForLaterList?.id ?? '', addItems, []);
        handleRemoveLineItem(item);
        showAlertPopup({
          message: 'Item Moved to "Saved for Later"',
          containerStyle: styles.alertBox,
          linkText: 'Edit',
          linkAction: () => scrollToSavedForLater(),
        });
      } catch (e) {
        logger.log('Could Not Save For Later: ', e);
      }
    },
    [handleRemoveLineItem, addItemsToList, cart?.lineItems, saveForLaterList, productData, scrollToSavedForLater, showAlertPopup],
  );

  const ActionLinksBottom = useCallback(
    ({ item }: { item: LineItem }): JSX.Element => {
      const note = getLineItemNote(item.id);
      return (
        <View key={`actionLinksBottom_${item.id}`} style={[styles.actionLinksBottomContainer, utilityStyles.mt2, styles.alignItemsCenter]}>
          <ItemSubstitution itemId={item.id} readOnly={false} obj={cart!} />
          <View style={[styles.flexRow, utilityStyles.mla]}>
            <Menu>
              <MenuTrigger>
                <View style={styles.ellipsesView}>
                  <Text testID="menuTrigger" style={[appStyles.bodyBoldLarge]}>
                    . . .
                  </Text>
                </View>
              </MenuTrigger>
              <MenuOptions
                optionsContainerStyle={styles.menuOptions}
                customStyles={{ optionsWrapper: styles.menuOptionWrapper, optionTouchable: styles.menuOptionsTouchable }}>
                <MenuOption style={styles.menuOption} onSelect={() => addNotesPressed(item.id)}>
                  {note ? (
                    <View style={[styles.flex1, styles.flexRow, styles.flexContentEnd, styles.bgWhite]}>
                      <Text testID="itemNoteAdded" style={[appStyles.fontMobileListSmallLeftRegular, styles.menuOptionText]}>
                        {t('itemNoteAdded')}
                      </Text>
                      <Icon
                        testID="circleCheck"
                        fill={colors.cream}
                        stroke={colors.dark}
                        strokeSecondary={colors.black}
                        size={15}
                        style={[appStyles.smallIcon, styles.alignSelfCenter, utilityStyles.pl2]}
                        name="circle-check"
                      />
                    </View>
                  ) : (
                    <Text testID="itemNoteAdd" style={[appStyles.fontMobileListSmallLeftRegular, styles.menuOptionText]}>
                      {t('itemNoteAdd')}
                    </Text>
                  )}
                </MenuOption>
                <MenuOption
                  onSelect={() => saveForLater(item, getItemAttributeValue('estimatedTotalWeight', item.custom?.customFieldsRaw ?? []))}
                  style={styles.menuOption}>
                  <Text testID="saveForLater" style={[appStyles.fontMobileListSmallLeftRegular, styles.menuOptionText]}>
                    Save for Later
                  </Text>
                </MenuOption>
              </MenuOptions>
            </Menu>
          </View>
        </View>
      );
    },
    [addNotesPressed, cart, getLineItemNote, saveForLater, t],
  );

  const submitItemNote = useCallback(
    (lineItemId: string) => {
      return () => {
        closeItemNote(lineItemId);
      };
    },
    [closeItemNote],
  );

  const LineItemNote = useCallback(
    ({ item }: { item: LineItem }): JSX.Element | null => {
      const itemNote = getLineItemNote(item.id);
      if (readOnly) {
        return !itemId && itemNote ? (
          <View style={[styles.flexCol, { width: containerWidth }]}>
            <Text testID="itemNote" style={[appStyles.body, appStyles.bodySmallRegular]}>
              {t('itemNote')}
            </Text>
            <View style={styles.notePad}>
              <Text testID="itemNoteValue" style={appStyles.bodySmallRegular}>
                {itemNote}
              </Text>
            </View>
          </View>
        ) : null;
      } else if (itemState[item.id]?.openNote || false) {
        return (
          <View style={[styles.flexCol, { width: containerWidth }]}>
            <View style={[styles.flexCol, styles.itemNoteMargin]}>
              <Form initialValues={{ note: itemNote }} onSubmit={submitItemNote(item.id)}>
                <FormField
                  testID="addItemNote"
                  label={[
                    <Text testID="addItemNote" key={`itemNoteLabelKey_${item.id}`} style={appStyles.fontMobileBodySmallBald}>
                      {t('addItemNote')}
                    </Text>,
                  ]}
                  bottomRight={t('wordLimit')}
                  placeholder={t('addItemNoteDefault')}
                  topRight={t('optionalField')}
                  autoCapitalize="sentences"
                  autoCorrect={false}
                  onChange={(e) => debounceUpdate('setLineItemNote', e.nativeEvent.text, item.id)}
                  keyboardType="ascii-capable"
                  name={'note'}
                  textContentType="none"
                  multiline={true}
                  fieldStyleEx={[{ height: scale(defaultFontSize * 6) }]}
                  maxLength={250}
                  returnKeyType={'default'}
                  inputAccessoryViewID="Close"
                />
                <KeyboardCloseButton />
                <View style={[styles.submitNoteContainer, utilityStyles.my1]}>
                  <SubmitButton
                    type={'secondary'}
                    size={'small'}
                    buttonStyle={styles.submitNoteButton}
                    title={t('closeItemNote')}
                    testID={`LineItemNote_${item.id}`}
                  />
                </View>
              </Form>
            </View>
          </View>
        );
      }

      return null;
    },
    [debounceUpdate, getLineItemNote, itemId, itemState, readOnly, submitItemNote, t],
  );

  const itemSavingsPressed = useCallback(
    (__: GestureResponderEvent, iid: string) => {
      showModal({
        title: 'Item Savings',
        style: { backgroundColor: colors.cream },
        children: <ItemSavings key={iid + 'offers'} itemId={iid} callDismiss={hideModal} />,
        location: 'top',
        showCloseIcon: true,
        okButtonText: '',
        showCancel: false,
        buttonContainerStyle: {
          backgroundColor: colors.cream,
          justifyContent: 'center',
          width: screenWidth * 0.988,
          marginLeft: scale(2),
          paddingHorizontal: 20,
        },
      });
    },
    [hideModal, showModal],
  );

  const promotionApplied = (p: Promotion) => {
    // waiting for extension for percent complete, if PercentComplete is undefined, asume complete?
    if ((p.AutoApply || p.IsAccepted) && (p.PercentComplete ?? 100) >= 100) {
      return true;
    }
    return false;
  };

  const okToShow = (p: Promotion) => {
    if (p.IsPromoCodePromo && !p.IsAccepted) {
      return false;
    }
    return true;
  };

  const ProductInfo = useCallback(
    ({ lineItem }: { lineItem: LineItem }): JSX.Element[] => {
      const descr: string[] = [];
      let itemOffer;
      if (!validationResult) {
        const cartPromos = JSON.parse(cart?.custom?.customFieldsRaw?.find((f) => f.name === 'promotions')?.value ?? '[]') as Promotion[];
        const promos = cartPromos.filter(
          (p) =>
            (p.ExtPromotionTypeCode ?? '').toUpperCase() !== 'BCS' &&
            (p.ExtPromotionTypeCode ?? '').toUpperCase() !== 'DPT' &&
            (p.Rewards ?? []).some((o) => o.LineItemId === lineItem.id),
        );
        if (promos.length) {
          if (promos.some((p) => !promotionApplied(p))) {
            itemOffer = (
              <LinkButton onPress={(e) => itemSavingsPressed(e, lineItem.id)} style={[appStyles.fontMobileBodySmBoldRed, styles.flexRow]}>
                Savings Available
              </LinkButton>
            );
          } else {
            itemOffer = (
              <LinkButton onPress={(e) => itemSavingsPressed(e, lineItem.id)} style={[appStyles.fontMobileBodySmBoldRed, styles.promoLink]}>
                Savings Applied
              </LinkButton>
            );
          }
        }
      }
      let updatedPrice: Money | undefined; // do not default, it is only assigned if there are errors/changes to display
      // must copy anything that will be modified for validation to not affect local state
      let item = { ...lineItem, price: { ...lineItem.price, value: { ...lineItem.price.value } } };

      const regularPrice: Money | null = getRegularItemPrice(item);
      const attrs: { [key: string]: string } = { name: item.name ?? item.productSlug ?? item.productId };
      (item.variant?.attributesRaw ?? []).forEach((field) => {
        attrs[field.name] = field.value;
      });
      let totalPrice: Money = { ...item.price.value };
      const unitOfMeasure = getProductAttributeValue(
        'unitOfMeasure',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      const errMsg = [];
      const productHeaderInfo: TextStyle[] = [];
      const productDetails: JSX.Element[] = [];
      if (validationResult) {
        // just original subtotal and error details
        const errItem = validationResult.errors.find((xi) => xi.id === item.id);

        if (errItem) {
          // this should always match, since its originally filtered by it
          let inStock = errItem.isInStock;
          (errItem.children ?? []).some((c) => {
            if (!c.isInStock) {
              inStock = false;
              return true;
            }
            return false;
          });
          if (!inStock) {
            errMsg.push(<Text style={[appStyles.formErrorText, styles.errorPriceText]}>Currently Not Available</Text>);
            productHeaderInfo.push({ textDecorationLine: 'line-through' });
          } else {
            if (errItem.availableQuantity < item.quantity) {
              item.quantity = errItem.availableQuantity;
              errMsg.push(<Text style={[appStyles.formErrorText, styles.errorPriceText]}>Partial Quantity Reduction</Text>);
            }
            if (errItem.priceChanged) {
              updatedPrice = { ...defaultMoney, centAmount: errItem.updatedPrice };
              errMsg.push(<Text style={[appStyles.formErrorText, styles.errorPriceText]}>Price Change</Text>);
            }
          }
        }
      }

      if (!validationResult) {
        // additional ingreditents
        const customField = item.custom?.customFieldsRaw?.find((x) => x.name === 'childLineItems');
        if (customField) {
          const childLineItemIds = customField ? String(customField.value).split(',') : [];
          childLineItemIds.forEach((lineItemId, ctr) => {
            const childLineItem = cart?.lineItems.find((x: LineItem) => x.id === lineItemId);
            const message =
              childLineItem?.custom?.customFieldsRaw?.find((cf) => cf.name === 'customStepSort')?.value.split('|')[1] === 'Message'
                ? `: "${childLineItem?.custom?.customFieldsRaw?.find((cf) => cf.name === 'itemNote')?.value}"`
                : !childLineItem?.custom?.customFieldsRaw?.find((cf) => cf.name === 'customStepSort')?.value.includes('|') &&
                    childLineItem?.custom?.customFieldsRaw?.find((cf) => cf.name === 'itemNote')?.value
                  ? `: "${childLineItem?.custom?.customFieldsRaw?.find((cf) => cf.name === 'itemNote')?.value}"`
                  : '';
            if (childLineItem) {
              if (childLineItem.price.value.centAmount) {
                totalPrice.centAmount += childLineItem.price.value.centAmount * childLineItem.quantity;
                productDetails.push(
                  <View key={`ingrKey_${item.id}_${ctr}`} style={[styles.flexRow]}>
                    <Text testID="productName" numberOfLines={2} ellipsizeMode={'tail'} style={[appStyles.fontMobileTinyLight, styles.unit]}>
                      {childLineItem.name}&nbsp;
                      {childLineItem.quantity > 1 ? [' (', childLineItem.quantity, ')'].join('') : null}
                    </Text>
                    <Text style={[appStyles.fontMobileTinyLight, styles.alignSelfEnd]}>{UNIT_SPACER}</Text>
                    <Text testID="priceValue" numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileTinyLight, styles.price]}>
                      {moneyValue(childLineItem.price.value, 1)} ea
                    </Text>
                    <Text testID="unitSeparator" style={[styles.unitSeparator]} />
                    <Text style={[appStyles.fontMobileTinyLight, styles.alignSelfEnd]}>{UNIT_SPACER}</Text>
                    <Text testID="finalValue" numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmall, styles.subtotal]}>
                      {moneyValue(childLineItem.price.value, childLineItem.quantity * item.quantity, '')}
                    </Text>
                  </View>,
                );
              } else {
                descr.push([childLineItem.name, childLineItem.quantity > 1 ? [' (', childLineItem.quantity, ')'].join('') : null, message].join(''));
              }
            }
          });
        }
      }

      // add separator at the top when not showing substitution screen
      if (!itemId) {
        // itemId is onyl set for Substitution screens
        productDetails.unshift(<Text key={`topSeparator_${item.id}`} style={[styles.subseparator]} />);
      }

      let custLink = null;
      if (item.productType?.name === ProductType.CONFIGURABLE) {
        if (descr.length) {
          // should only be presentif details are included (not in cart validations screen)
          // if there is data for collapsible show it
          custLink = (
            <View key={`itemDescr_${item.id}`}>
              <LinkButton onPress={(e) => customizationsPressed(e, item.id)} style={[styles.flexRow]}>
                <View style={[styles.customizationsContainer]}>
                  <View style={[styles.priceContainer]}>
                    <Text testID="customizations" style={[appStyles.fontMobileBodySmallBald, styles.marginRight8]} key={`actionCustomizations_${item.id}`}>
                      Customizations&nbsp;
                    </Text>
                    <Icon
                      testID={`customization_${item.id}`}
                      key={`customization_${item.id}`}
                      fill={colors.black}
                      stroke={'none'}
                      strokeSecondary={colors.black}
                      size={15}
                      style={[appStyles.smallIcon, styles.alignSelfCenter]}
                      name={itemState?.[item.id]?.customizations ?? false ? 'triangle-up' : 'triangle-down'}
                    />
                  </View>
                </View>
              </LinkButton>
              {(itemState?.[item.id]?.customizations ?? false) && [
                <View style={[styles.priceContainer, styles.flexCol, styles.margin0]}>
                  {descr.map((tt: string, indx) => (
                    <Text
                      testID={`itemDescription_${indx}`}
                      key={`itemDescription_${indx}`}
                      numberOfLines={2}
                      ellipsizeMode={'tail'}
                      style={[appStyles.fontMobileTinyLight, { lineHeight: lineHeight(14) }]}>
                      {tt}
                    </Text>
                  ))}
                </View>,
              ]}
            </View>
          );
        } else {
          custLink = (
            <Text testID="customizationNeeded" style={[styles.fontMobileBodySmallBold, styles.marginRight8]} key={`actionCustomizations_${item.id}`}>
              Customization Needed
            </Text>
          );
        }
      }

      // lead time
      const getAvailabilityString = (val: string) => {
        switch (val) {
          case 'LowStock':
            val = 'Low Stock';
            break;
          case 'OutOfStock':
            val = 'Out Of Stock';
            break;
          default:
            break;
        }
        return val;
      };
      const leadTime = getfulfillmentLeadTimeMinutes(productData?.[item.variant?.sku ?? ''] ?? undefined);
      let leadMessage;
      if (leadTime > +(selectedStore?.fulfillmentLeadTimeMinutes ?? Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES)) {
        leadMessage =
          leadTime > +(Config.LEAD_TIME_MAX_HOURS_TOSHOW ?? 48) * 60
            ? `${(leadTime / 60 / 24 + 1) | 0} Day Lead Time`
            : `${(leadTime / 60) | 0}-Hour Lead Time`;
      }
      const availabilityData = getProductAvailablity(productData?.[item.variant?.sku ?? '']?.masterData);
      if (availabilityData.availability !== 'Available' && availabilityData.availability !== 'LowStock') {
        leadMessage = availabilityData.availabilityDate
          ? ['Available ', availabilityData.availabilityDate].join('')
          : getAvailabilityString(availabilityData.availability.toString());
      }

      const leadTimeLabel =
        !validationResult && leadMessage ? (
          <View key={`lead_${item.id}`} style={[styles.leadWarningContainer]}>
            <Icon name="flag-red-icon" stroke={'none'} style={[appStyles.smallIcon]} size={12} />
            <Text style={[styles.leadWarningContainerText, utilityStyles.mx1]}>{leadMessage}</Text>
          </View>
        ) : null;

      // Product name and description on top
      const regularPriceRow = [];
      const sellType = getProductAttributeValue(
        'unitSellType',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      ) ?? { key: 'byEach', label: 'Each' };
      let regPrice = regularPrice ? `${moneyValue(regularPrice)} ea` : '-';
      let totPrice = `${moneyValue(totalPrice)} ea`;
      const prodPrice = item.variant?.price?.value ?? totalPrice;
      const averageWeight = getProductAttributeValue(
        'unitAverageBuyWeight',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      const packageCount = getProductAttributeValue(
        'packageCount',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      const unitsPerPackage = getProductAttributeValue(
        'unitsPerPackage',
        productData?.[item.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
      );
      let unitTotalCount = item.quantity;
      if (sellType.key === 'weightByEach') {
        regPrice = regularPrice ? `${moneyValue({ ...regularPrice, centAmount: Math.round(regularPrice.centAmount * (averageWeight ?? 1)) })} ea` : '-';
      } else if (sellType.key === 'byWeight') {
        regPrice = regularPrice ? `${moneyValue(regularPrice)} / ${unitOfMeasure}` : '-';
        totPrice = `${moneyValue(prodPrice)} / ${unitOfMeasure}`;
      } else {
        unitTotalCount = ((unitsPerPackage ?? 1) * (packageCount ?? 1)).toFixed(1);
      }

      let qty = 1;
      if (productData && productData[item.variant?.sku ?? '']?.masterData?.current) {
        qty = getProductQuantityValue(productData[item.variant?.sku ?? ''].masterData.current!, item.quantity);
      }
      if (!validationResult && regularPrice) {
        regularPriceRow.push(
          <Text
            testID="preg1"
            key={'preg1'}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[appStyles.fontMobileTinyLight, styles.subprice, { color: colors.red }]}>
            {totPrice}
          </Text>,
          <Text
            testID="preg2"
            key={'preg2'}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[appStyles.fontMobileTinyLight, styles.subRegprice, styles.lineThrough]}>
            {regPrice}
          </Text>,
        );
      } else {
        regularPriceRow.push(
          <Text testID="totalPrice" key={'totalPrice'} numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.fontMobileTinyLight, styles.subprice]}>
            {totPrice}
          </Text>,
        );
      }
      let approxLine = null;
      if ((sellType && (sellType.key ?? 'byEach')) === 'weightByEach') {
        approxLine = (
          <Text
            testID="approxTotalPrice"
            key={'approxTotalPrice'}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[appStyles.fontMobileTinyLight, styles.approxSubtotal]}>
            approx
          </Text>
        );
      }
      productDetails.unshift(
        <View key={`title_${item.id}`} style={styles.flexRow}>
          <View style={[updatedPrice ? styles.priceWidth60 : styles.priceWidth78]}>
            <TouchableOpacity key={`iteminfo_${item.id}`} {...productTouch} onPress={() => handleNavigation(item)} style={{}}>
              <Text
                testID="editCustomizedItem"
                numberOfLines={2}
                ellipsizeMode={'middle'}
                style={[appStyles.fontMobileBodySmallBald, { lineHeight: lineHeight(16) }, ...productHeaderInfo]}>
                {attrs.name ?? ''}&nbsp;&nbsp;&nbsp;
                {!readOnly && item.productType?.name === ProductType.CONFIGURABLE ? (
                  <LinkButton style={[appStyles.fontMobileTinyLight, {}]} onPress={() => editCustomizedItem(item)}>
                    {t('edit')}
                  </LinkButton>
                ) : null}
              </Text>
              {sellType.key === 'weightByEach' && averageWeight ? (
                <Text
                  testID="unitOfMeasure"
                  key={'unit-of-measure'}
                  numberOfLines={1}
                  adjustsFontSizeToFit={true}
                  style={[appStyles.fontMobileTinyLight, styles.averageStyle]}>
                  {`Avg ${averageWeight} ${unitOfMeasure}`}
                </Text>
              ) : sellType.key === 'byEach' && unitsPerPackage ? (
                <Text
                  testID="unitOfMeasure"
                  key={'unit-of-measure'}
                  numberOfLines={1}
                  adjustsFontSizeToFit={true}
                  style={[appStyles.fontMobileTinyLight, styles.averageStyle]}>
                  {`${unitTotalCount} ${unitOfMeasure}`}
                </Text>
              ) : null}
              {errMsg}
            </TouchableOpacity>
            {leadTimeLabel}
            {custLink}
          </View>
          <View style={[updatedPrice ? styles.priceWidth40 : styles.priceWidth22, styles.flexJustifyContentStart]}>
            <View key={`productPriceDetails_${item.id}`}>
              <Text
                testID={`prodDetails_${item.id}`}
                key={`prodDetails_${item.id}`}
                numberOfLines={1}
                adjustsFontSizeToFit={true}
                style={[appStyles.fontMobileListSmallLeft, styles.subtotal]}>
                {moneyValue(totalPrice, item.quantity)}
              </Text>
              {approxLine}
              {regularPriceRow}
              {updatedPrice && (
                <Text
                  testID="totalPrice"
                  key={'totalPrice'}
                  numberOfLines={1}
                  adjustsFontSizeToFit={true}
                  style={[appStyles.fontMobileTinyLight, styles.subtotal, styles.priceMargin]}>
                  Was {sellType.key === 'weightByEach' && averageWeight ? `${moneyValue(updatedPrice)} / ${unitOfMeasure}` : `${moneyValue(updatedPrice)} ea`}
                </Text>
              )}
            </View>
          </View>
        </View>,
      );

      if (!validationResult && !readOnly && !itemId && itemOffer) {
        productDetails.push(
          <View key={`title2_${item.id}`} style={[styles.flexRow]}>
            <View style={[styles.width100, styles.flexJustifyContentStart]}>{itemOffer}</View>
          </View>,
        );
      }
      return productDetails;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cart?.custom?.customFieldsRaw,
      cart?.lineItems,
      itemId,
      itemState,
      productData,
      productTouch,
      readOnly,
      selectedStore?.fulfillmentLeadTimeMinutes,
      validationResult,
    ],
  );

  const handleQuantityChange = useCallback(
    (item: LineItem, q: number) => {
      if (q === 0) {
        // const nameStr = item.name ?? item.productSlug ?? item.productId;
        handleRemoveLineItem(item);
        return;
      }
      setLineItemQuantity({ lineItemId: item.id }, q);
    },
    [handleRemoveLineItem, setLineItemQuantity],
  );

  let itemRowRefs = useMemo(() => new Map(), []);
  const RenderCartItem = useCallback(
    (item: LineItem, index: number, arr: LineItem[]) => {
      let catHeader = null;
      let addItemSeparator = false;
      let unitBuyIncrement = 1,
        unitBuyMinimum = 1;
      let sellType;
      if (productData && !itemId) {
        sellType = getProductAttributeValue(
          'unitSellType',
          item.variant?.attributesRaw ?? productData[arr[index]?.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
        ) ?? { key: 'byEach', label: 'By Each' };
        if ((sellType?.key ?? 'byEach') === 'byWeight') {
          unitBuyIncrement =
            getProductAttributeValue(
              'unitBuyIncrement',
              item.variant?.attributesRaw ?? productData[arr[index]?.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
            ) ?? unitBuyIncrement;
          unitBuyMinimum =
            getProductAttributeValue(
              'unitBuyMinimum',
              item.variant?.attributesRaw ?? productData[arr[index]?.variant?.sku ?? '']?.masterData?.current?.masterVariant?.attributesRaw ?? [],
            ) ?? unitBuyMinimum;
        }
        const currData = getRootCategory(productData[item.variant?.sku ?? '']?.masterData?.current?.categories);
        const lastData = index ? getRootCategory(productData[arr[index - 1]?.variant?.sku ?? '']?.masterData?.current?.categories) : undefined;
        const nextData = index + 1 < arr.length ? getRootCategory(productData[arr[index + 1]?.variant?.sku ?? '']?.masterData?.current?.categories) : null;

        if (currData?.name && (lastData?.name ?? '') !== currData.name) {
          catHeader = (
            <View key={'cat-header'} style={[styles.categoryHeader, { backgroundColor: colors.promoCream }, index ? utilityStyles.mt3 : {}]}>
              <View style={[styles.itemFrame]}>
                <Text testID={currData.name} style={[appStyles.bodySmallRegular]}>
                  {currData.name}
                </Text>
              </View>
            </View>
          );
        }
        if (currData?.name && nextData?.name === currData?.name) {
          addItemSeparator = true;
        }
      }

      if (readOnly) {
        // in Summary, Substitution or Validation
        const itemQuantity: JSX.Element[] = [];
        const errItem = validationResult?.errors.find((xi) => xi.id === item.id) ?? null;
        if (errItem && validationResult && errItem.isInStock && errItem.availableQuantity < item.quantity) {
          // just original subtotal and error details
          itemQuantity.push(
            <Text
              testID="erravailableQuantity"
              key={'s1'}
              numberOfLines={1}
              adjustsFontSizeToFit={true}
              style={[appStyles.fontMobileBodySmallBald, { color: colors.red }]}>
              {errItem.availableQuantity}
            </Text>,
            <Text testID="quantity" key={'s2'} style={[appStyles.fontMobileBodySmallBald, styles.itemQuantityErr]}>
              &nbsp;{item.quantity}&nbsp;
            </Text>,
          );
        } else {
          // hide quantity indicator when showing Substitution items
          itemQuantity.push(
            <Text testID="estimatedTotalWeight" key={'s1'} style={(appStyles.bodySmallBold, { textAlign: 'center' })}>
              {formatQuantity(item.variant?.sku ?? '', item.quantity, getItemAttributeValue('estimatedTotalWeight', item.custom?.customFieldsRaw ?? []))}
            </Text>,
          );
        }
        const lineItemPad = itemId ? styles.subContainerItemSubst : styles.subContainerItem;
        return (
          <View key={`cartItem_${item.id}`}>
            {catHeader}
            <TouchableOpacity key={`renderROLineItemViewKey_${item.id}`} {...productTouch} onPress={() => handleNavigation(item)}>
              <View style={[lineItemPad]}>
                <View style={[styles.itemFrame]}>
                  {itemId ? ( // hide quantity when picking substitutions
                    <View style={[utilityStyles.mr2]}>
                      <View>
                        {productData && item.variant?.sku && productData[item.variant.sku] && (
                          <ProductImage imageUrl={item.variant?.images[0]?.url} style={styles.productImage} />
                        )}
                      </View>
                    </View>
                  ) : (
                    <View style={[styles.itemLeft, utilityStyles.mr4]}>
                      <View key={'ss1'} style={[styles.itemLeft1]}>
                        {itemQuantity}
                      </View>
                      <View key={'ss2'} style={[styles.itemLeft2]}>
                        {productData && item.variant?.sku && productData[item.variant.sku] && (
                          <ProductImage imageUrl={item.variant?.images[0]?.url} style={styles.productImage} />
                        )}
                      </View>
                    </View>
                  )}
                  <View style={[styles.itemRightRO]}>{ProductInfo({ lineItem: item })}</View>
                </View>
                {LineItemNote({ item: item })}
                {addItemSeparator && <View style={[styles.separator]} />}
              </View>
            </TouchableOpacity>
          </View>
        );
      } else {
        // in cart
        const key = item.id;
        return (
          <View key={`cartItem_${item.id}`}>
            <MenuProvider skipInstanceCheck={true}>
              {catHeader}
              <Swipeable
                key={key}
                ref={(r2) => {
                  if (r2) {
                    itemRowRefs.set(key, r2);
                  }
                }}
                renderRightActions={(progress, dragAnimatedValue) => renderRightActions(progress, dragAnimatedValue, item)}
                overshootRight={true}
                onSwipeableOpen={() => {
                  [...itemRowRefs.entries()].forEach(([k, r1]) => {
                    if (k !== key && r1) {
                      r1.close();
                    }
                  });
                  if (removeOnSwipe === key) {
                    handleRemoveLineItem(item);
                  }
                  setRemoveOnSwipe('');
                }}>
                <TouchableOpacity key={`renderLineItemViewKey_${item.id}`} {...productTouch} onPress={() => handleNavigation(item)}>
                  <View style={[styles.subContainerItem]}>
                    <View style={[styles.itemFrame]}>
                      <View style={[styles.itemLeftCart]}>
                        <View>
                          {productData && item.variant?.sku && productData[item.variant.sku] && (
                            <ProductImage imageUrl={item.variant?.images[0]?.url} style={styles.productImage} />
                          )}
                          <QuantityBox
                            containerStyle={[styles.itemQuantity, utilityStyles.py1, styles.qtyBox]}
                            countStyle={[appStyles.primaryButtonSmallText, styles.quantityCountText]}
                            initialValue={getQuantityIndex(
                              item.variant?.sku ?? '',
                              item.quantity,
                              getItemAttributeValue('estimatedTotalWeight', item.custom?.customFieldsRaw ?? []),
                            )}
                            //onChange={(q) => setLineItemQuantity({ lineItemId: item.id }, q)}
                            onChange={(q) => handleQuantityChange(item, q)}
                            showCartText={false}
                            borderStyle={[styles.qtyBoxBorder]}
                            showToggle={false}
                            incrementBy={unitBuyIncrement}
                            maxLimit={getMaxProductQuantity(item.variant?.sku, productData && item.variant?.sku ? productData[item.variant.sku] : undefined)}
                            minLimit={unitBuyMinimum}
                            qtyFormat={(idx) => formatQuantityFromIndex(item.variant?.sku ?? '', idx)}
                          />
                        </View>
                      </View>
                      <View style={[styles.itemRight]}>
                        {ProductInfo({ lineItem: item })}
                        <ActionLinksBottom key={`actionLinksBottomRoot_${item.id}`} item={item} />
                      </View>
                    </View>
                    {LineItemNote({ item: item })}
                  </View>
                </TouchableOpacity>
              </Swipeable>
              {addItemSeparator && <View style={[styles.itemSeparator]} />}
            </MenuProvider>
          </View>
        );
      }
    },
    [
      productData,
      itemId,
      readOnly,
      validationResult,
      productTouch,
      ProductInfo,
      LineItemNote,
      formatQuantity,
      handleNavigation,
      getQuantityIndex,
      getMaxProductQuantity,
      ActionLinksBottom,
      itemRowRefs,
      renderRightActions,
      removeOnSwipe,
      handleRemoveLineItem,
      handleQuantityChange,
      formatQuantityFromIndex,
    ],
  );

  const CartItems = useCallback((): JSX.Element | undefined => {
    if (cartIsEmpty) {
      return (
        <View key={'emptyCartViewKey'} style={[styles.subContainerItem, styles.cartEmpty]} testID="emptyCart">
          <Icon
            name="empty-cart"
            size={containerWidth * 0.2}
            style={{ width: containerWidth * 0.2, height: containerWidth * 0.2 }}
            stroke="none"
            fill={colors.sectionPad}
          />
          <Text testID="cartEmptyText" style={appStyles.bodyMediumLight}>
            {t('cartEmptyText')}
          </Text>
        </View>
      );
    } else if (productData) {
      const aItems = invidLineItems(cart?.lineItems ?? []);
      const cItems = itemId ? aItems.filter((i) => itemId === i.id) : aItems;
      const lItems = !validationResult ? cItems : cItems.filter((li) => validationResult.errors.findIndex((xi) => xi.id === li.id) !== -1);
      // sorting by orderHint
      const sItems = lItems.sort((p1, p2) => {
        if (p1.variant?.sku) {
          const p1Data = getRootCategory(productData[p1.variant?.sku ?? '']?.masterData?.current?.categories);
          const p2Data = getRootCategory(productData[p2.variant?.sku ?? '']?.masterData?.current?.categories);
          if ((p1Data?.orderHint ?? 0.8) === (p2Data?.orderHint ?? 0.8)) {
            const c1Name = getRootCategory(productData[p1.variant?.sku ?? '']?.masterData?.current?.categories)?.name ?? '---';
            const c2Name = getRootCategory(productData[p2.variant?.sku ?? '']?.masterData?.current?.categories)?.name ?? '---';
            return c1Name < c2Name ? -1 : c1Name > c2Name ? 1 : 0;
          } else {
            return (p1Data?.orderHint ?? 0.8) > (p2Data?.orderHint ?? 0.8) ? 1 : -1;
          }
        } else {
          return 1;
        }
      });
      return <>{sItems.map((li, i, a) => RenderCartItem(li, i, a))}</>;
    }
  }, [cartIsEmpty, productData, t, cart?.lineItems, itemId, validationResult, RenderCartItem]);

  const submitOrderNote = useCallback(() => {
    closeItemNote('cart_note');
  }, [closeItemNote]);

  const orderNote = useCallback((): JSX.Element | JSX.Element[] => {
    if (!cartIsEmpty && (itemState.cart_note?.openNote || false)) {
      return [
        <View key={'orderNoterKey'} style={styles.orderNoteContainer}>
          <View style={styles.orderNoteSubContainer}>
            <View style={[styles.flexCol]}>
              <Form initialValues={{ note: getOrderNote(cart) }} onSubmit={submitOrderNote}>
                <FormField
                  testID="orderNote"
                  hideLabel={true}
                  bottomRight={t('wordLimit')}
                  autoCapitalize="sentences"
                  autoCorrect={false}
                  onChange={(e) => debounceUpdate('setOrderNote', e.nativeEvent.text)}
                  placeholder={t('addOrderNoteDefault')}
                  keyboardType="ascii-capable"
                  name={'note'}
                  textContentType="none"
                  multiline={true}
                  fieldStyleEx={[{ height: scale(defaultFontSize * 6) }]}
                  maxLength={250}
                  inputAccessoryViewID="Close"
                />
                <KeyboardCloseButton />
                <View key={`OrderNoteSave_${cart?.id}`} style={[styles.flex1, styles.saveNoteContainer, utilityStyles.my1]}>
                  <SubmitButton
                    type={'secondary'}
                    size={'small'}
                    buttonStyle={[styles.submitButton]}
                    title={t('closeOrderNote')}
                    testID="OrderNoteSaveSubmit"
                  />
                </View>
              </Form>
            </View>
          </View>
        </View>,
        <View style={[styles.separator]} />,
      ];
    }
    return <View key={'orderNoteKey'} />;
  }, [cart, cartIsEmpty, debounceUpdate, itemState.cart_note?.openNote, submitOrderNote, t]);

  const cartSummary = useCallback((): JSX.Element => {
    const count = cart ? getCartQuantity() || 0 : 0;
    const itemSubtotal = getSubTotalItemPrice(cart);
    const cartPromos = JSON.parse(cart?.custom?.customFieldsRaw?.find((f) => f.name === 'promotions')?.value ?? '[]') as Promotion[];
    const promoSavings = cart?.customLineItems.filter((p) => (p.slug ?? '').split('-')[0] === 'promo') ?? [];
    const promos = promoSavings.reduce(
      (acc, pe) => {
        const pslug = pe.slug.split('-');
        const promo = cartPromos.find((p) => pslug.length === 3 && p.ExtPromotionId === pslug[2]);
        if (promo && !(promo.HasFreeDeliveryFee || promo.HasFreeServiceFee)) {
          const promoValue = pe.custom?.customFieldsRaw?.find((f) => f.name === 'promotionSavings')?.value ?? defaultMoney;
          acc.push({
            description: pe.name ?? 'Savings',
            value: promoValue,
          });
        }
        return acc;
      },
      [] as { description: string; value: Money }[],
    );
    const savingsOf = promos.reduce((sum, p) => (sum += p.value.centAmount), 0);
    const mapPromos = (pset?: { description: string; value: Money }[]) => {
      if (pset && pset.length) {
        return pset.map((p) => (
          <View style={[styles.flexRow, { width: containerWidth }, utilityStyles.my1]}>
            <Text style={[appStyles.bodySmallRegular, { color: colors.red }, utilityStyles.px2]} />
            <Text testID="buyTwoGetOneText" ellipsizeMode={'tail'} numberOfLines={1} style={[appStyles.bodySmallLeftRegular, styles.buyOneText]}>
              {p.description}
            </Text>
            <Text testID="buyTwoGetOneValue" style={[appStyles.bodySmallLeftRegular, utilityStyles.mla, styles.buyOneValue]}>
              &minus;&nbsp;{moneyValue(p.value)}
            </Text>
          </View>
        ));
      }

      return [];
    };

    return (
      <DropShadow key={'SummaryShadow'} style={[styles.summaryShadow, utilityStyles.my3]}>
        <TouchableOpacity key={`cartSummary_${cart!.id}`} activeOpacity={1} style={[styles.subContainerItem, utilityStyles.my1]}>
          {cartOffers}
          {/* <View style={[styles.itemFrame]}><Text style={[appStyles.dropShadowTitle, { alignSelf: 'flex-start' }]}>Cart Summary</Text></View> */}

          <View style={[styles.flexRow, { width: containerWidth }, utilityStyles.my1]}>
            <Text testID="items" style={appStyles.bodySmallRegular}>
              Items ({count})
            </Text>
            <Text testID="regularPriceSubtotal" style={[appStyles.bodySmallRegular, utilityStyles.mla]}>
              {cart && itemSubtotal && moneyValue(itemSubtotal)}
            </Text>
          </View>
          {!transitCart && promos.length > 0
            ? [
                <View style={[styles.flexRow, { width: containerWidth }, utilityStyles.my1]}>
                  <Text testID="estimatedSavings" style={[appStyles.fontMobileBodySmallBald, { color: colors.red }]}>
                    ESTIMATED SAVINGS
                  </Text>
                  <Text testID="estimatedSavingsValue" style={[appStyles.fontMobileBodySmallBald, styles.estimatedSavingsValue]}>
                    &minus;&nbsp;{moneyValue({ ...defaultMoney, centAmount: savingsOf })}
                  </Text>
                </View>,
                ...mapPromos(promos),
              ]
            : null}
          {!transitCart && itemSubtotal ? (
            <View style={[styles.flexRow, { width: containerWidth }, utilityStyles.mt3]}>
              <Text testID="estimatedTotal" style={[appStyles.fontMobileBodySmallBald]}>
                ESTIMATED TOTAL
              </Text>
              <Text testID="estimatedTotalValue" style={[appStyles.fontMobileBodySmallBald, utilityStyles.mla]}>
                &nbsp;{moneyValue({ ...(itemSubtotal ?? defaultMoney), centAmount: (itemSubtotal?.centAmount ?? savingsOf) - savingsOf })}
              </Text>
            </View>
          ) : null}
          <Text testID="excludesTaxText" style={[appStyles.fontMobileTinyLight, styles.textAlignLeft, { width: containerWidth }]}>
            *Excludes Taxes & Fees
          </Text>
          <View
            onLayout={(e) => {
              setLayout({ bottomOfListCoord: e.nativeEvent.layout });
            }}
          />
        </TouchableOpacity>
      </DropShadow>
    );
  }, [cart, getCartQuantity, cartOffers, transitCart]);

  return (
    <TouchableOpacity key={`itemizedCartKey_${cart?.id}`} activeOpacity={1} style={[styles.mainContainer]}>
      <View style={[styles.flex1]}>
        <LineHeader />
        {/** @ts-ignore  */}
        <ViewShot key={'main_itemized'} ref={viewShotRef} style={{ ...styles.captureView, ...utilityStyles.my2 }}>
          {CartItems()}
          {!readOnly && !cartIsEmpty && productData ? (
            [
              <View key={'order_note_container'} style={styles.subContainerItem}>
                <View style={[styles.separator]} />
                <View style={[styles.orderNoteSubContent, utilityStyles.px1]}>
                  <LinkButton onPress={() => addNotesPressed('cart_note')} style={[appStyles.fontMobileBodySmallBald, styles.fexRow, styles.flex0]}>
                    {getOrderNote(cart)
                      ? [
                          <Text testID="addedOrderNote" key={'addedOrderNoteKey'}>
                            {t('addedOrderNote')}&nbsp;
                          </Text>,
                          <Icon
                            testID="addedOrderNoteIconKey"
                            key={'addedOrderNoteIconKey'}
                            fill={colors.cream}
                            stroke={colors.dark}
                            strokeSecondary={colors.black}
                            size={15}
                            style={[appStyles.smallIcon]}
                            name="circle-check"
                          />,
                        ]
                      : t('addOrderNote')}
                  </LinkButton>
                  <Text style={[appStyles.formLabelsTopRight, {}]}>{t('optionalField')}</Text>
                </View>
              </View>,
            ]
          ) : !readOnly && !cartIsEmpty && (!productData || !productData.length) ? (
            <ActivityIndicator color={appStyles.primaryButton.color} size={'large'} testID="loading" />
          ) : null}
          {validationResult || !productData ? null : [orderNote(), showCartSummary ? cartSummary() : null]}
        </ViewShot>
      </View>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  separator: {
    height: 1,
    width: containerWidth,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  itemSeparator: {
    height: 0.5,
    width: containerWidth,
    backgroundColor: colors.productBorder,
    alignSelf: 'center',
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  categoryHeader: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.promoCream,
    paddingTop: 10,
  },
  subContainerItem: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.cream,
    paddingTop: 22,
    paddingBottom: 22,
  },
  subContainerItemSubst: {
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.white,
    paddingTop: 22,
    paddingBottom: 22,
  },
  orderNoteContainer: {
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  orderNoteSubContainer: {
    flexDirection: 'column',
    width: containerWidth,
    alignSelf: 'center',
  },
  orderNoteSubContent: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
    paddingLeft: 8,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  captureView: { backgroundColor: colors.cream, opacity: 1 },
  leadWarningContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
  },
  leadWarningContainerText: {
    color: colors.red,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  itemFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
    paddingBottom: 5,
  },
  flex1: { flex: 1 },
  flex0: { flex: 0 },
  flexRow: { flexDirection: 'row' },
  flexCol: { flexDirection: 'column' },
  itemsHeader: {
    backgroundColor: colors.sectionPad,
    paddingBottom: 2,
    paddingTop: 6,
  },
  notePad: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    marginBottom: 10,
  },
  itemLeft: {
    minWidth: containerLayout.width,
    width: containerLayout.width,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    flex: 0,
    justifyContent: 'space-between',
  },
  itemLeft1: {
    alignSelf: 'stretch',
    minWidth: 40,
    paddingRight: 5,
    flex: 1,
    alignItems: 'center',
  },
  itemLeft2: {
    width: containerLayout.imageWidth,
    minWidth: containerLayout.imageWidth,
  },
  itemLeftCart: {
    minWidth: containerLayout.imageWidth,
    width: containerLayout.imageWidth,
    flexDirection: 'column',
    alignSelf: 'flex-start',
    flex: 0,
    alignItems: 'flex-start',
  },
  itemQuantity: {
    alignSelf: 'center',
    marginTop: 10,
    width: containerLayout.imageWidth,
    height: 32,
    borderColor: colors.productBorder,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 1.5 },
    shadowOpacity: 0.2,
    shadowRadius: 1.5,
    elevation: Platform.select({ android: 2 }),
  },
  actionLinksBottomContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  carouselContainer: {
    borderWidth: 0,
    overflow: 'visible',
    marginRight: 0,
  },
  productImage: {
    width: containerLayout.imageWidth,
    alignSelf: 'center',
    height: containerLayout.imageHeight,
    borderWidth: 1,
    borderColor: colors.productBorder,
  },
  itemRightRO: {
    flex: 1,
    flexDirection: 'column',
  },
  itemRight: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 8,
  },
  priceContainer: {
    flexDirection: 'row',
  },
  customizationsContainer: {
    paddingTop: 8,
    flexDirection: 'column',
  },
  subseparator: {
    height: 0.33,
    width: '100%',
    marginBottom: 2,
    marginTop: 2,
    backgroundColor: colors.cream,
  },
  subtotal: {
    textAlign: 'right',
    flex: 0.29,
    alignSelf: 'flex-end',
  },
  approxSubtotal: {
    marginTop: scale(-6),
    marginBottom: scale(2),
    textAlign: 'right',
    flex: 0.29,
    marginLeft: 'auto',
  },
  price: {
    textAlign: 'right',
    flex: 0.2,
    alignSelf: 'flex-end',
  },
  subRegprice: {
    marginTop: -4,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  subprice: {
    marginTop: 4,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  unit: {
    flex: 0.55,
    marginRight: 1,
    alignSelf: 'flex-end',
  },
  unitSeparator: {
    width: 0.03,
    height: '100%',
    marginRight: 5,
    marginLeft: 8,
    backgroundColor: colors.darkCream,
    alignSelf: 'flex-end',
  },
  cartEmpty: {
    marginBottom: 25,
  },
  swipedRow: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.sectionHeader,
  },
  deleteButton: {
    backgroundColor: colors.red,
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: 20,
    marginLeft: 'auto',
  },
  menuOption: {
    padding: 0,
    margin: 0,
    height: 38,
  },
  menuOptions: {
    paddingTop: 5,
    paddingBottom: 5,
    width: 200,
    height: 86,
    backgroundColor: colors.white,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    borderColor: colors.darkCream,
    borderWidth: 1,
    borderRadius: 8,
  },
  menuOptionText: {
    paddingLeft: 14,
    backgroundColor: colors.white,
    lineHeight: scale(38),
    textAlignVertical: 'top',
  },
  menuOptionWrapper: {
    backgroundColor: colors.sectionBorder,
    color: 'white',
  },
  menuOptionsTouchable: {
    activeOpacity: 0.75,
    textAlign: 'center',
  },
  fexRow: {
    flexDirection: 'row',
  },
  alertBox: {
    bottom:
      screenHeight < 736
        ? screenHeight * 0.225
        : screenHeight > 812
          ? screenHeight > 896
            ? screenHeight * 0.21
            : screenHeight * 0.215
          : screenHeight < 740
            ? screenHeight * 0.225
            : screenHeight * 0.225,
  },
  carouselView: {
    marginBottom: 16,
    marginLeft: SLIDE_GAP,
  },
  cartCarouselWrapper: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: colors.promoCream,
  },
  pagerStyle: {
    marginTop: 20,
    marginBottom: 10,
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  averageStyle: {
    marginTop: 2,
  },
  alignItemsCenter: { alignItems: 'center' },
  quantityCountText: {
    width: containerLayout.imageWidth,
    color: colors.text,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    fontFamily: FontFamily.LarsseitBold,
    textAlign: 'center',
    marginTop: scale(10),
  },
  ellipsesView: {
    width: scale(40),
    height: scale(40),
    justifyContent: 'center',
    alignItems: 'center',
  },
  fontMobileBodySmallBold: {
    ...appStyles.fontMobileBodySmallBald,
    color: colors.saleRed,
  },
  priceMargin: { marginTop: 4 },
  submitButton: { position: 'absolute', top: -20 },
  flexContentEnd: { alignContent: 'flex-end' },
  bgWhite: { backgroundColor: colors.white },
  priceWidth40: { width: '40%' },
  priceWidth22: { width: '22%' },
  priceWidth78: { width: '78%' },
  priceWidth60: { width: '60%' },
  flexJustifyContentStart: { justifyContent: 'flex-start' },
  justifyContentCenter: { justifyContent: 'center' },
  lineThrough: { textDecorationLine: 'line-through', textDecorationColor: colors.black },
  itemNoteMargin: { marginBottom: 13 },
  textAlignLeft: { textAlign: 'left' },
  alignSelfCenter: { alignSelf: 'center' },
  alignSelfStart: { alignSelf: 'flex-start' },
  alignSelfEnd: { alignSelf: 'flex-end' },
  estimatedSavingsValue: { marginLeft: 'auto', color: colors.red },
  summaryShadow: { borderBottomWidth: 0, borderTopWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 },
  saveNoteContainer: { height: 45 },
  buyOneText: { color: colors.red, flex: 0.9 },
  buyOneValue: { color: colors.red },
  qtyBox: { backgroundColor: colors.white, alignContent: 'center', justifyContent: 'center' },
  qtyBoxBorder: { borderLeftWidth: 0, borderRightWidth: 0 },
  itemQuantityErr: { marginTop: 3, textDecorationLine: 'line-through' },
  width100: { width: '100%' },
  margin0: { margin: 0 },
  marginRight8: { marginRight: 8 },
  errorPriceText: { textAlign: 'left', marginTop: 9 },
  promoLink: { flexDirection: 'row', color: colors.clippedGreen },
  submitNoteContainer: { flexDirection: 'row', height: 20 },
  submitNoteButton: { position: 'absolute', top: -20 },
});

export default ItemizedCart;

import { createStackNavigator } from '@react-navigation/stack';
import { StoreFinderDeailScreen, StoreFinderFilterScreen, StoreFinderScreen } from '@screens/account';
import React from 'react';
import { cardStyleInterpolator } from './helpers';
import { StoreFinderStackParamList, StoreFinderStackRoutes } from './routes';

const StoreFinderStack = createStackNavigator<StoreFinderStackParamList>();

const StoreFinderStackNavigator = () => {
  return (
    <StoreFinderStack.Navigator
      initialRouteName={StoreFinderStackRoutes.StoreFinderScreen}
      screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
      <StoreFinderStack.Screen name={StoreFinderStackRoutes.StoreFinderScreen} component={StoreFinderScreen} />
      <StoreFinderStack.Screen
        name={StoreFinderStackRoutes.StoreFinderDetail}
        component={StoreFinderDeailScreen}
        options={{ cardStyleInterpolator: cardStyleInterpolator }}
      />
      <StoreFinderStack.Screen
        name={StoreFinderStackRoutes.StoreFinderFilter}
        component={StoreFinderFilterScreen}
        options={{ cardStyleInterpolator: cardStyleInterpolator }}
      />
    </StoreFinderStack.Navigator>
  );
};

export default StoreFinderStackNavigator;

import { OpenTombstoneRequest } from '@components/Tombstone';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { ProductList } from '@utils/productHelper';
import React, { FC } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import ProductCard from '../ProductCard';

const CARD_WIDTH = 168;

type WeeklyAdsCardProps = {
  product: ProductList;
  index: number;
  changeStore?: (reqest: OpenTombstoneRequest) => void;
};

const WeeklyAdsCard: FC<WeeklyAdsCardProps> = ({ product, index, changeStore }): JSX.Element => {
  const navigation = useNavigation();

  const navigateToProductDetailsPage = () => {
    navigation.navigate(AppStackRoutes.ProductDetails, {
      productKey: product.product.key ?? '',
    });
  };

  const renderSubView = () => {
    return (
      <ProductCard
        product={product.product}
        productCategory={{ parentId: 'weeklyAds', id: '', name: '', crumb: [], imageName: '', children: [], orderHint: 0.8 }}
        changeStore={changeStore}
        productPromotion={product.promotion}
      />
    );
  };

  return (
    <TouchableOpacity onPress={navigateToProductDetailsPage} style={[styles.cardContainer]} key={`offer${index}`}>
      <View style={[styles.cardView]}>{renderSubView()}</View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    // width: CARD_WIDTH,
    // height: 400,
    borderWidth: 0,
    borderColor: colors.red,
    borderRadius: 0,
    backgroundColor: colors.white,
    flexDirection: 'column',
  },
  cardView: {
    // width: '100%',
    width: screenWidth * 0.5,
    // height: '100%',
    justifyContent: 'space-evenly',
    alignSelf: 'center',
    borderWidth: 0,
    borderColor: colors.red,
    borderRadius: 0,
  },
  activityIndicatorView: {
    position: 'absolute',
    top: 12,
    right: -2,
  },
  addButton: {
    position: 'absolute',
    top: 8,
    right: 2,
    zIndex: 9,
  },
  itemOfferSubText: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  productImageView: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginVertical: 5,
    marginHorizontal: 5,
  },
  image: {
    width: 95,
    height: 100,
  },
  weeklyAdCardView: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: CARD_WIDTH,
  },
  eligibleItems: {
    flexDirection: 'row',
    justifyContent: 'center',
    top: 30,
  },
  locationWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(12),
    lineHeight: lineHeight(12),
    bottom: -40,
  },
  locationText: {
    paddingLeft: 5,
    textDecorationLine: 'underline',
  },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 0,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  container: {
    paddingVertical: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertBox: {
    bottom:
      screenHeight < 736 ? screenHeight * 0.155 : screenHeight > 812 ? screenHeight * 0.135 : screenHeight < 740 ? screenHeight * 0.14 : screenHeight * 0.15,
  },
  regularText: {
    paddingTop: 10,
  },
  noAvailTextWrapper: {
    width: screenWidth * 0.45,
    paddingHorizontal: 15,
  },
  noAvailText: {
    fontSize: scale(14),
    color: colors.text,
    lineHeight: scale(20),
    fontFamily: FontFamily.LarsseitLight,
    marginLeft: Platform.select({ android: -8 }),
  },
  cartLink: {
    fontSize: scale(14),
    color: colors.red,
    width: '120%',
    top: Platform.select({
      ios: screenHeight > 667 ? -19 : -17,
      android: screenHeight > 667 ? -21 : -20,
    }),
    left: Platform.select({
      ios: screenHeight > 667 ? 75 : 70,
      android: screenHeight > 667 ? 68 : 70,
    }),
    textAlign: 'auto',
    fontFamily: FontFamily.LarsseitLight,
    textDecorationLine: 'underline',
  },
  itemDiscountText: {
    fontSize: scale(20),
    color: 'red',
    fontFamily: 'Larsseit-Bold',
    marginBottom: Platform.select({ android: scale(-30), ios: scale(-15) }),
  },
  discounted: {
    fontSize: scale(15),
    color: colors.darkText,
    fontFamily: 'Larsseit',
    textDecorationLine: 'line-through',
  },
  itemPriceText: {
    fontSize: scale(17),
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
    marginBottom: Platform.select({
      ios: scale(-30),
      android: scale(-40),
    }),
  },
  itemPriceTextUnit: {
    fontSize: scale(17),
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
  },
  priceView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: CARD_WIDTH,
  },
});

export default WeeklyAdsCard;

import Barcode from '@components/Barcode';
import Text from '@components/Text';
import colors from '@config/colors';
import { AccountStackRoutes, AppStackRoutes, RewardsStackRoutes, RootTabRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useShopStore, useUserProfileStore } from '@store';
import { appStyles } from '@styles';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
const UPCA_NUM_LENGTH = 11;

type JumpMenuScreenProps = {
  toggleTombstone: () => void;
  setCurrentPage: Dispatch<SetStateAction<string>>;
};

const JumpMenuScreen: FC<JumpMenuScreenProps> = ({ setCurrentPage }): JSX.Element => {
  const { selectedShopType, setTempShopType } = useShopStore();
  const { t } = useTranslation('barcodeModal');
  const { userProfile } = useUserProfileStore();
  const navigation = useNavigation();
  const getBarcodeValue = () => {
    if (userProfile?.amsCardId) {
      return String(userProfile.amsCardId).padStart(UPCA_NUM_LENGTH, '0');
    }

    return '';
  };

  const isValid = userProfile?.amsCardId && getBarcodeValue().length === UPCA_NUM_LENGTH;

  const changeStore = () => {
    setTempShopType(selectedShopType);
    setCurrentPage('FindAStore');
  };

  const navToOffers = () => {
    navigation.dispatch(
      StackActions.push(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Rewards,
        params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Personalized' } },
      }),
    );
  };

  const navToLists = () => {
    navigation.dispatch(
      StackActions.push(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: { screen: AccountStackRoutes.ManageShoppingList, params: {} },
      }),
    );
  };

  return (
    <ScrollView style={{ flexGrow: 1, marginTop: 8 }} contentContainerStyle={styles.initialOptionsWrapper}>
      <TouchableOpacity
        testID="inStoreChangeStoreClick"
        onPress={() => {
          changeStore();
        }}>
        <Text style={styles.changeStoreText} testID="inStoreChangeStoreText">
          {'Change Store'}
        </Text>
      </TouchableOpacity>

      <View style={styles.whitebox}>
        <View style={[styles.barcodeContainerStyle]}>
          <View style={styles.barcodeImageView}>
            {isValid && (
              <Barcode
                value={getBarcodeValue()}
                text={userProfile?.loyaltyId ?? ''}
                height={65}
                maxWidth={screenWidth * 0.65}
                textStyle={[appStyles.bodyLargeCenter, { marginTop: 16 }]}
              />
            )}
          </View>
          <Text style={[appStyles.bodySmallRedLink, { textDecorationLine: 'none', marginVertical: 8 }]} testID="barcodeModalTitle">
            {t('barcodeModalTitle')}
          </Text>
          <Text style={[appStyles.bodySmallLight, styles.textCenter]} testID="redeemText">
            Present this barcode during in-store checkout to earn points and redeem clipped offers
          </Text>
        </View>
      </View>

      <TouchableOpacity onPress={navToLists} style={styles.whitebox}>
        <View style={styles.innerContent}>
          <View style={styles.iconContainer}>
            <Image source={require('../../assets/images/manage-list-icon.png')} style={styles.contentIcon} testID="manageListImage" />
          </View>
          <View style={styles.textContainer}>
            <Text testID="myLists" style={[appStyles.bodySmallRedLink, { textDecorationLine: 'none', marginTop: 8, textAlign: 'left' }]}>
              My Lists
            </Text>
            <Text testID="manageListText" style={[appStyles.bodySmallLight, { marginVertical: 4 }]}>
              Create or edit handy shopping lists to stay organized
            </Text>
          </View>
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={navToOffers} style={styles.whitebox}>
        <View style={styles.innerContent}>
          <View style={styles.iconContainer}>
            <Image testID="seiconcircle" source={require('../../assets/images/se-icon-circle.png')} style={styles.contentIcon} />
          </View>
          <View style={styles.textContainer}>
            <Text testID="somethingExtraOffers" style={[appStyles.bodySmallRedLink, { textDecorationLine: 'none', marginTop: 8, textAlign: 'left' }]}>
              View Something Extra Offers
            </Text>
            <Text testID="accesspersonalizedoffers" style={[appStyles.bodySmallLight, { marginVertical: 4 }]}>
              Access personalized offers and digital coupons
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  initialOptionsWrapper: {
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 50,
    // backgroundColor: 'red',
  },
  changeStoreText: {
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(16),
    lineHeight: 18,
    textDecorationLine: 'underline',
    textAlign: 'center',
    marginTop: Platform.OS === 'ios' ? 10 : 8,
    marginBottom: screenHeight * 0.03,
  },
  whitebox: {
    width: '90%',
    backgroundColor: colors.white,
    flexGrow: 1,
    marginBottom: 12,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 16,
    borderRadius: 10,
  },
  innerContent: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  iconContainer: {
    width: '20%',
    // backgroundColor: 'red',
  },
  contentIcon: {
    width: 55,
    height: 55,
    marginBottom: 8,
    marginLeft: -4,
  },
  textContainer: {
    width: '80%',
    paddingLeft: 4,
    // backgroundColor: 'green',
  },
  barcodeContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  barcodeImageView: {
    width: '100%',
    height: Platform.select({
      android: 100,
    }),
    marginTop: 0,
    alignSelf: 'center',
  },
  barcodeContainerStyle: {
    paddingVertical: 8,
    paddingHorizontal: 30,
    width: '100%',
    backgroundColor: colors.white,
  },
  textCenter: {
    textAlign: 'center',
    width: '90%',
    alignSelf: 'center',
  },
});

export default JumpMenuScreen;

import { navigationRef } from '@navigation/routes';
import useDeeplinkStore from '@store/deeplinkStore';
import { useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';

export const useDeepLinks = () => {
  const [hookRoute, setHookRoute] = useState<string>();
  const [currentRoute, setCurrentRoute] = useState<string>();
  const { deeplinks, addDeepLink, removeDeepLink } = useDeeplinkStore();

  useEffect(() => {
    const task = InteractionManager.runAfterInteractions(() => {
      const route = navigationRef.current?.getCurrentRoute();
      if (!hookRoute) {
        setHookRoute(route?.name);
      }
    });

    const handleNavigationStateChange = () => {
      setCurrentRoute(navigationRef.current?.getCurrentRoute()?.name);
    };

    navigationRef.current?.addListener('state', handleNavigationStateChange);

    return () => {
      task.cancel();
      navigationRef.current && navigationRef.current?.removeListener('state', handleNavigationStateChange);
    };
  }, [hookRoute]);

  useEffect(() => {
    (async () => {
      if (hookRoute !== currentRoute) {
        return;
      }

      if (!deeplinks.length) {
        return;
      }

      const currentLink = deeplinks[0];
      await currentLink.action();
      removeDeepLink(currentLink.id);
    })();
  }, [hookRoute, currentRoute, deeplinks, removeDeepLink]);

  return { addDeepLink };
};

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { lineHeight, scale } from '@styles/constants';
import React, { FC } from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Icon, { IconProps } from './Icon';

interface IconButtonProps {
  header: string;
  headerStyle?: StyleProp<TextStyle>;
  highlightedText?: string;
  text?: string;
  iconProps: IconProps;
  onPress: ((event: any) => void) | undefined;
  buttonStyle?: StyleProp<ViewStyle>;
  loading?: boolean;
  disabled?: boolean;
}

const IconButton: FC<IconButtonProps> = ({ header, highlightedText, text, iconProps, onPress, buttonStyle, headerStyle, loading, disabled }): JSX.Element => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.iconButton, buttonStyle]} disabled={loading || disabled}>
      <Icon {...iconProps} />
      <View style={{ marginLeft: 10 }}>
        <Text style={[styles.iconButtonText, headerStyle]} testID={`btnText-${header}`} numberOfLines={1}>
          {header}
        </Text>
        {highlightedText || text ? (
          <Text style={{ fontSize: scale(14), color: colors.text, marginLeft: 5 }} testID="text">
            {highlightedText ? <Text style={{ fontFamily: FontFamily.LarsseitBold, color: colors.text }}>{highlightedText}</Text> : null}
            {text}
          </Text>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  //STYLES FOR INITIAL SHOP OPTIONS
  iconButton: {
    flexDirection: 'row',
    minWidth: 300,
    maxWidth: 350,
    width: '100%',
    height: 56,
    backgroundColor: colors.darkCream,
    borderRadius: 28,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
    marginTop: 20,
    elevation: 5,
  },
  iconButtonText: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    color: colors.text,
    marginLeft: 5,
    lineHeight: lineHeight(16),
  },
});

export default IconButton;

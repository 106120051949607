import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Document } from '@bloomreach/spa-sdk';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import HtmlView from '../HtmlView';

const RichTextComponent = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document: Document = documentRef && page.getContent(documentRef);

  if (!document) {
    return page.isPreview() && Platform.OS === 'web' ? (
      <View style={styles.editContainer}>
        <BrManageContentButton content={document} documentTemplateQuery="new-banner-document" folderTemplateQuery="new-banner-folder" parameter="document" />
      </View>
    ) : null;
  }

  const { contentHtml } = document.getData();
  const source = { html: contentHtml?.value };

  return (
    <View style={[styles.mainContainer]}>
      <HtmlView contentWidth={100} text={source.html ?? ''} lineHeight={21} textAlign="left" />
    </View>
  );
};

const styles = StyleSheet.create({
  editContainer: {
    minHeight: 100,
  },
  mainContainer: {
    paddingRight: 5,
    paddingLeft: 5,
  },
});

export default RichTextComponent;

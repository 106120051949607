import Cache from '@utils/cache';
import { useEffect, useReducer } from 'react';
import useIsMounted from './useIsMounted';

interface State<T> {
  status: 'refetching' | 'loading' | 'success' | 'error';
  data?: T;
  error?: Error;
}

interface StateActions<T> {
  refetch: () => Promise<T | undefined>;
}
interface StateHelpers {
  isLoading: boolean;
  isRefetching: boolean;
}

// discriminated union type
type Action<T> = { type: 'loading' | 'refetching' } | { type: 'fetched' | 'refetched'; payload: T | undefined } | { type: 'error'; payload: Error };

export function useCache<T = unknown>(key: string, callback: () => Promise<T>, timeoutInMinutes: number = 30): State<T> & StateActions<T> & StateHelpers {
  // Used to prevent state update if the component is unmounted
  const isMounted = useIsMounted();
  const initialState: Omit<State<T>, 'refetch'> = {
    status: 'loading',
    error: undefined,
    data: undefined,
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading':
      case 'refetching':
        return { ...initialState, status: action.type };
      case 'fetched':
      case 'refetched':
        return { ...initialState, status: 'success', data: action.payload };
      case 'error':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    // Do nothing if the url is not given
    if (!key) {
      return;
    }
    if (isMounted()) {
      dispatch({ type: 'loading' });
      Cache.getItem(key, callback, timeoutInMinutes)
        .then((data) => {
          dispatch({ type: 'fetched', payload: data });
        })
        .catch((error) => {
          dispatch({ type: 'error', payload: error });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const refetch = () => {
    dispatch({ type: 'refetching' });
    return Cache.getItem(key, callback, timeoutInMinutes, true)
      .then((data) => {
        dispatch({ type: 'refetched', payload: data });
        return data;
      })
      .catch((error) => {
        dispatch({ type: 'error', payload: error });
        return undefined;
      });
  };

  return { ...state, refetch, isLoading: state.status === 'loading', isRefetching: state.status === 'refetching' };
}

import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { offerService } from '@services/brandywine';
import Cache from '@utils/cache';
import logger from './logger';

export type ProductPromotionsResponse = { AvailablePromotions: Promotion[]; AcceptedPromotions: Promotion[] };
export type ProductCouponsResponse = { AvailableCoupons: Promotion[]; AcceptedCoupons: Promotion[] };
export type OfferType = 'SomethingExtra' | 'WeeklyExclusive' | 'DigitalCoupons';

const PROMOTION_PRODUCT_MAP_CACHE_KEY = 'promotion_products';

const getProductPromotions = async (selectedStore: string, skus: string[]): Promise<Record<string, ProductPromotionsResponse>> => {
  const offers = await offerService.getOffersByProductList('app', selectedStore, skus).catch((ex) => {
    logger.error(ex);
    return undefined;
  });
  const coupons = await offerService.getCouponsByProductList('app', selectedStore, skus).catch((ex) => {
    logger.error(ex);
    return undefined;
  });

  let result: Record<string, { AvailablePromotions: Promotion[]; AcceptedPromotions: Promotion[] }> = {};

  skus.forEach((sku) => {
    if (sku) {
      let accepted = [...(offers?.AcceptedPromotions ?? []), ...(coupons?.AcceptedCoupons ?? [])].filter(
        (promo) => (promo.ProductList ?? []).indexOf(sku) >= 0,
      );
      let available = [...(offers?.AvailablePromotions ?? []), ...(coupons?.AvailableCoupons ?? [])].filter(
        (promo) => (promo.ProductList ?? []).indexOf(sku) >= 0,
      );
      result[sku] = { AvailablePromotions: available, AcceptedPromotions: accepted };
    }
  });

  let promoMap: Record<string, string[]> = [
    ...(offers?.AcceptedPromotions ?? []),
    ...(coupons?.AcceptedCoupons ?? []),
    ...(offers?.AvailablePromotions ?? []),
    ...(coupons?.AvailableCoupons ?? []),
  ].reduce(
    (record, x) => {
      if (x.ProductList && x.ProductList.length) {
        record[x.ExtPromotionId] = x.ProductList;
      }
      return record;
    },
    {} as Record<string, string[]>,
  );
  Cache.hashSet(PROMOTION_PRODUCT_MAP_CACHE_KEY, promoMap, 240).catch((ex) => {
    logger.error(ex);
  });

  return result;
};

export const getOffersAndCouponsByProductList = async (storeNumber: string, productList: string[]): Promise<ProductPromotionsResponse> => {
  if (productList.length <= 0) {
    return {
      AvailablePromotions: [],
      AcceptedPromotions: [],
    };
  }
  //Check if SKU-Prmotions exists in Cache
  var cachedData =
    (await Cache.hashGet<ProductPromotionsResponse>(
      `product_promotions_${storeNumber}`,
      productList,
      async () => getProductPromotions(storeNumber, productList),
      30,
    )) ?? {};

  let missing = productList?.filter((s) => !(cachedData[s] && (cachedData[s].AvailablePromotions || cachedData[s].AcceptedPromotions)));
  let missingData: Record<string, ProductPromotionsResponse> = {};
  if ((missing?.length ?? 0) > 0) {
    missingData = await getProductPromotions(storeNumber, missing);
    Cache.hashSet(`product_promotions_${storeNumber}`, missingData, 30);
  }

  return {
    AvailablePromotions: Object.values({ ...cachedData, ...missingData }).flatMap((v) => v.AvailablePromotions),
    AcceptedPromotions: Object.values({ ...cachedData, ...missingData }).flatMap((v) => v.AcceptedPromotions),
  };
};

export const evaluateProduct = async (
  storeNumber: string,
  shippingMethod: 'pickup' | 'delivery',
  product: { ExtProductId: string; Quantity: number; Price?: number; RegularPrice?: number; EstimatedTotalWeight?: number },
  cart?: { ExtProductId: string; Quantity: number; Price?: number; RegularPrice?: number; EstimatedTotalWeight?: number }[],
): Promise<Promotion[]> => {
  return await offerService.evaluateProduct('app', storeNumber, shippingMethod, product, cart);
};

export const evaluateCart = async (
  storeNumber: string,
  shippingMethod: 'pickup' | 'delivery',
  cart: { LineItemId: string; ExtProductId: string; Quantity: number; Price?: number; RegularPrice?: number; EstimatedTotalWeight?: number }[],
): Promise<Promotion[]> => {
  return await offerService.evaluateCart('app', storeNumber, shippingMethod, cart ?? []);
};

export const clearPromotionCache = async (promotionIds: string[]) => {
  var productMap = await Cache.hashGet<string[]>(
    PROMOTION_PRODUCT_MAP_CACHE_KEY,
    promotionIds.map((p) => String(p)),
  );
  let items: string[] = [];
  if (productMap) {
    items = Object.values(productMap).flat(1);
  }
  if (items && items.length) {
    var cachKeys = await Cache.keys('product_promotions_');

    cachKeys.forEach((key) => {
      let hashKey = key.substring(0, key.indexOf(':'));
      Cache.hashRemove(hashKey, items).catch((ex) => {
        logger.error(ex);
      });
    });
  }
  promotionIds.forEach((id) => Cache.removeItem(`promotion:${id}`));
};

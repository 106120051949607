import { appStyles, utilityStyles } from '@styles';
import { useField } from 'formik';
import React, { forwardRef, useEffect } from 'react';
import { StyleProp, StyleSheet, TextInput, TextStyle, View, ViewStyle } from 'react-native';
import { hapticNotify } from '../../utils/helpers';
import { IconProps } from '../Icon';
import IconTextInput, { IconTextInputProps } from '../IconTextInput';
import Text from '../Text';
import ErrorMessage from './ErrorMessage';

interface FormFieldProps extends Omit<IconTextInputProps, 'onChangeText' | 'onBlur'> {
  name: string;
  label?: string | JSX.Element[];
  hideLabel?: boolean;
  icon?: IconProps;
  iconPress?: any;
  reserveSpace?: boolean;
  topRight?: string | JSX.Element[];
  topRightStyle?: StyleProp<TextStyle>;
  bottomRight?: string | JSX.Element[];
  bottomRightStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  hidden?: boolean;
  formatter?: (text: string | undefined) => string | undefined;
  labelStyle?: StyleProp<TextStyle>;
}

const FormField = forwardRef<TextInput, FormFieldProps>(
  (
    {
      name,
      label,
      reserveSpace = false,
      topRight,
      topRightStyle,
      bottomRight,
      bottomRightStyle,
      hideLabel = false,
      containerStyle,
      value,
      hidden = false,
      formatter,
      labelStyle,
      ...props
    },
    ref,
  ) => {
    const [field, meta, helpers] = useField<any>(name);

    useEffect(() => {
      if (meta.error && meta.touched) {
        hapticNotify('notificationError');
      }
    }, [meta.error, meta.touched]);

    const capitalizeText = (text: string) => {
      return text.replace(/\b(\w)/g, (s) => s.toUpperCase());
    };

    useEffect(() => {
      helpers.setValue(value || field.value, false);
      helpers.setTouched(false, false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <View style={[utilityStyles.mt3, bottomRight ? { marginBottom: 10 } : {}, containerStyle, { display: hidden ? 'none' : 'flex' }]}>
        {hideLabel ? (
          <></>
        ) : (
          <Text style={[appStyles.formLabels, labelStyle]} testID="labelText">
            {label || capitalizeText(name)}
          </Text>
        )}
        <View style={bottomRight ? { marginBottom: 10 } : null}>
          {topRight && Array.isArray(topRight) ? (
            topRight
          ) : (
            <Text style={[styles.rTop, appStyles.formLabelsTopRight, topRightStyle]} testID="topRightText">
              {topRight}
            </Text>
          )}
          {bottomRight && Array.isArray(bottomRight) ? (
            bottomRight
          ) : (
            <Text style={[styles.rBottom, appStyles.formLabelsBottomRight, bottomRightStyle]} testID="bottomRightText">
              {bottomRight}
            </Text>
          )}
          <IconTextInput
            errorStyle={meta.touched && meta.error ? appStyles.formFieldError : {}}
            onBlur={() => helpers.setTouched(true)}
            onChangeText={(text) => (formatter ? helpers.setValue(formatter(text), true) : helpers.setValue(text, true))}
            value={field.value ? String(field.value) : ''}
            {...props}
            ref={ref}
          />
        </View>
        {meta.touched && meta.error ? <ErrorMessage reserveSpace={reserveSpace} error={meta.error} visible={meta.touched} /> : <></>}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  rTop: {
    padding: 5,
    right: 0,
    position: 'absolute',
    top: -22,
    height: 30,
  },
  rBottom: {
    padding: 5,
    right: 0,
    bottom: 0,
    marginBottom: -18,
    position: 'absolute',
  },
});

export default FormField;

import { HeaderTitle, ListCard, LoadingScreen, NavigationHeader, Screen } from '@components';
import CartButton from '@components/CartButton';
import colors from '@config/colors';
import { AccountStackRoutes, AccountStackScreenProps } from '@navigation/routes';
import { useShoppingListsStore } from '@store';
import { utilityStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import React, { useCallback } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

type ManageListsScreenProps = AccountStackScreenProps<AccountStackRoutes.ManageShoppingList>;

const ManageListsScreen = ({ route: _route }: ManageListsScreenProps) => {
  const { shoppingLists: lists, status } = useShoppingListsStore();

  const ItemSeparator = useCallback(() => {
    return <View style={styles.separator} testID="itemSeparator" />;
  }, []);

  return (
    <Screen>
      <>
        <NavigationHeader subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }} next={<CartButton />}>
          <View style={[styles.headerContainer, utilityStyles.mt2]}>
            <HeaderTitle testID="myLists">My Lists</HeaderTitle>
          </View>
        </NavigationHeader>
        {status === 'loading' ? (
          <LoadingScreen />
        ) : (
          <FlatList
            style={styles.flatList}
            contentContainerStyle={styles.flatlistContainer}
            data={
              lists.sort((a, b) => {
                return (a.name ?? '') > (b.name ?? '') ? 1 : (b.name ?? '') > (a.name ?? '') ? -1 : 0;
              }) ?? []
            }
            renderItem={({ item }) => {
              return (
                <ListCard iconName={item.name === 'Favorites' ? 'heart-icon' : undefined} showValue={item.name === 'Favorites' ? false : true} list={item} />
              );
            }}
            ItemSeparatorComponent={ItemSeparator}
          />
        )}
      </>
    </Screen>
  );
};

const styles = StyleSheet.create({
  separator: {
    backgroundColor: colors.darkCream,
    height: 1,
    width: screenWidth * 0.9,
  },
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flatList: {
    width: screenWidth,
  },
  flatlistContainer: {
    alignItems: 'center',
    paddingTop: 16,
    width: screenWidth,
  },
});

export default ManageListsScreen;

import Text from '@components/Text';
import { assets } from '@config/assets';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useCancelToken } from '@hooks';
import { AppStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useOffersStore } from '@store';
import { appStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import Icon from '../Icon';
import { RedeemProgressBar } from '../index';

const CARD_WIDTH = 168;

type PDPSavingsCardProps = {
  offer: Promotion;
  index?: number;
  showMaximumSaving?: boolean;
};

const PDPSavingsCard: FC<PDPSavingsCardProps> = ({ offer, index, showMaximumSaving }): JSX.Element => {
  const { t } = useTranslation(['savingsReminder', 'promotions']);
  const OFFER_IMG = offer.QualifiedImageUrl ?? '';
  const date = `Expires ${dayjs(offer.EndDate).format('MM/DD/YY')}`;

  const { acceptOffer, unacceptOffer, acceptCoupon, acceptedSomethingExtraOffers, acceptedWeeklyExclusiveOffers, acceptedDigitalCoupons, fetchDigitalCoupons } =
    useOffersStore();
  const [clipping, setClipping] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigation = useNavigation();

  const { showAlertModal } = useCustomModalContext();

  const acceptedOffers = acceptedSomethingExtraOffers.concat(acceptedWeeklyExclusiveOffers).concat(acceptedDigitalCoupons);

  useEffect(() => {
    setClipping(false);
  }, [offer.IsAccepted]);

  const handleShowEligibleItems = () => {
    navigation.navigate(AppStackRoutes.OfferDetails, {
      offerId: Number(offer.ExtPromotionId),
      percentComplete: offer.PercentComplete ?? 0,
      percentCompleteMessage: offer.PercentCompleteMessage ?? '',
      isClipped: offer.IsAccepted === true, // acceptedOffers?.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId),
      calledFrom: 'SavingReminder',
    });
  };

  const showOfferUnavailableModal = () => {
    showAlertModal({
      title: t('offerUnavailableTitle', { ns: 'promotions' }),
      message: t('offerUnavailableMessage', { ns: 'promotions' }),
    });
  };

  const cancelToken = useCancelToken();
  const handleClip = async () => {
    setClipping(true);
    setDisabled(true);

    const isDigitalCoupon = (offer.PromotionCode ?? '').toUpperCase().startsWith('INMAR');
    if (acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId)) {
      if (!isNotAllowUnclip) {
        if (!isDigitalCoupon) {
          await unacceptOffer(+offer.ExtPromotionId);
        }
      }
    } else {
      if (isDigitalCoupon) {
        await acceptCoupon(+offer.ExtPromotionId)
          .catch((ex: any) => {
            ex.message.toLowerCase().includes('no longer valid') && showOfferUnavailableModal();
          })
          .finally(() => fetchDigitalCoupons(cancelToken));
      } else {
        await acceptOffer(+offer.ExtPromotionId, cancelToken);
      }
    }
    setClipping(false);
    setDisabled(false || (isDigitalCoupon && acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId)));
  };

  const isNotAllowUnclip = offer.ExtPromotionTypeCode === 'DFEE' || offer.ExtPromotionTypeCode === 'SFEE';
  const showAsAccepted = (p: Promotion) => {
    if (p.AutoApply || (p.IsPromoCodePromo && p.IsAccepted)) {
      return true;
    }
    return false;
  };

  const fallbackImage = assets.imageComingSoon;
  const getImageSource = () => {
    let url;
    if (OFFER_IMG && OFFER_IMG) {
      url = { cache: 'default', uri: `${OFFER_IMG.indexOf('//') === 0 ? 'https:' + OFFER_IMG : OFFER_IMG}` };
    } else {
      url = fallbackImage;
    }
    return url;
  };

  const renderSubView = () => {
    return (
      <>
        <View
          style={[
            styles.cardContentItem,
            {
              width: !showMaximumSaving
                ? Platform.select({
                    ios: screenWidth * 0.85,
                    android: screenWidth * 0.85,
                  })
                : 0,
            },
          ]}>
          <View style={[styles.productImageView]}>
            <TouchableOpacity key={'image_key_index_' + index} activeOpacity={1} onPress={handleShowEligibleItems} testID={`offerImage-${index}-Button`}>
              {<Image testID={`offerImage-${index}`} resizeMode={'contain'} source={getImageSource()} style={styles.image} />}
            </TouchableOpacity>
          </View>
          <View style={[styles.maximumSavingCardView]}>
            <View style={[styles.addItemInfo]}>
              <Text testID={`offerHeadline-${index}`} numberOfLines={2} style={styles.itemOfferSubText}>
                {offer.Headline}
              </Text>
              {offer.SubHeadline && (
                <Text testID={`offerSubHeadline-${index}`} numberOfLines={2} style={[styles.productDetailText]}>
                  {offer.SubHeadline} {'\n'}
                </Text>
              )}
              {/* {offer.SubHeadline && offer.Limit !== 0 && (
                <Text testID="consumerDetails" numberOfLines={2} style={[styles.productDetailText]}>
                  {offer.SubHeadline} {'\n'}
                  Limit {offer.Limit} per {offer.LimitType}
                </Text>
              )} */}
              <Text style={[styles.itemDateText, { textAlign: 'left' }]}>{date}</Text>
            </View>
          </View>
        </View>
        <View style={styles.cardBottomView}>
          <View style={styles.cardProgressBarView}>
            {Number(offer.PercentComplete ?? 0) >= 100 ? (
              <RedeemProgressBar progress={100} maxValue={100}>
                <Text testID="completed" style={[appStyles.fontMobileBodyXS, styles.font12, { textAlign: 'center' }]}>
                  {offer.PercentCompleteMessage ?? 'Add more items to Complete!'}
                </Text>
              </RedeemProgressBar>
            ) : (
              <RedeemProgressBar progress={Number(offer.PercentComplete ?? 0)} maxValue={100}>
                <Text testID="add1MoreText" style={[appStyles.fontMobileBodyXS, styles.font12]} numberOfLines={1}>
                  {offer.PercentCompleteMessage ?? 'Add more items to Complete!'}
                </Text>
              </RedeemProgressBar>
            )}
          </View>
          <TouchableOpacity onPress={handleShowEligibleItems} testID={`showEligibleItems-${index}-Button`}>
            <Text testID={`showEligibleItems-${index}`} style={[styles.itemEligibleText, styles.fontLine12]}>
              {t('showEligibleItems')}
            </Text>
          </TouchableOpacity>
        </View>
      </>
    );
  };

  return (
    <TouchableOpacity
      testID={`pdpSavingCard-${index}`}
      onPress={handleShowEligibleItems}
      style={[styles.addItemsContainer, !showMaximumSaving && styles.availableOffersContainer]}
      key={`offer${index}`}>
      <View style={styles.cardView}>
        {(isNotAllowUnclip && offer.IsAccepted) || showAsAccepted(offer) ? (
          <View style={styles.clipped} />
        ) : /* {acceptedOffers?.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? ( */
        acceptedOffers.some((o) => +o.ExtPromotionId === +offer.ExtPromotionId) ? (
          <>
            {clipping ? (
              <View style={[styles.activityIndicatorView, { top: -30 }]}>
                <ActivityIndicator />
              </View>
            ) : (
              <TouchableOpacity disabled={disabled} style={styles.clipped} onPress={handleClip} testID={`circle-check-${index}-Button`}>
                <View style={styles.clippedWrapper}>
                  <Text testID="clipped" style={[appStyles.fontMobileTextGreenRight, { textAlign: 'center', lineHeight: lineHeight(20) }]}>
                    {t('clipped')}
                  </Text>
                  <Icon testID="circleCheck" name={'circle-check'} fill={colors.green} stroke={colors.white} strokeSecondary={colors.white} />
                </View>
              </TouchableOpacity>
            )}
          </>
        ) : (
          <>
            {clipping ? (
              <View style={styles.activityIndicatorView}>
                <ActivityIndicator />
              </View>
            ) : (
              <TouchableOpacity onPress={handleClip} style={styles.addButton} testID={`circle-scissor-${index}-Button`}>
                <Icon name={'circle-scissor'} stroke="none" size={44} />
              </TouchableOpacity>
            )}
          </>
        )}
        {renderSubView()}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  availableOffersContainer: {
    borderWidth: 1.5,
    borderColor: colors.offerBorder,
    borderRadius: 10,
    borderStyle: 'dashed',
  },
  addItemsContainer: {
    width: Platform.select({
      ios: screenWidth * 0.92,
      android: screenWidth * 0.93,
    }),
    height: Platform.select({
      ios: screenWidth * 0.62,
      android: screenHeight * 0.3,
    }),
    borderWidth: 0.5,
    borderColor: colors.greyBorder,
    borderRadius: 10,
    backgroundColor: colors.white,
    marginTop: scale(20),
    flexDirection: 'row',
  },
  cardView: {
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: scale(10),
  },
  activityIndicatorView: {
    position: 'absolute',
    top: -25,
    right: 20,
  },
  clippedWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  clipped: {
    position: 'absolute',
    top: '-14%',
    paddingLeft: screenWidth / 2,
    zIndex: 9,
    width: containerWidth,
    justifyContent: 'center',
    paddingRight: scale(10),
  },
  itemDateText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(12),
    textAlign: 'center',
    lineHeight: lineHeight(15),
  },
  itemEligibleText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.red,
    fontSize: scale(14),
    lineHeight: scale(14),
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  addButton: {
    marginTop: '-9%',
    alignItems: 'flex-end',
    width: containerWidth,
    paddingRight: scale(8),
  },
  addItemInfo: {
    height: scale(120),
    width: scale(180),
    justifyContent: 'space-between',
  },
  itemOfferSubText: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.primary,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'left',
  },
  productImageView: {
    width: scale(120),
    height: scale(120),
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 100 - 5,
    height: 100,
  },
  maximumSavingCardView: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: scale(120),
  },
  cardBottomView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    top: 20,
    width: Platform.select({
      ios: screenWidth * 0.92,
      android: screenWidth * 0.93,
    }),
    alignContent: 'center',
    paddingHorizontal: 16,
  },
  cardProgressBarView: {
    width: screenWidth * 0.4,
    height: screenHeight * 0.032,
  },
  font12: {
    width: screenWidth * 0.48,
    height: scale(24),
    marginTop: 5,
  },
  fontLine12: {
    fontSize: scale(12),
    lineHeight: lineHeight(12),
  },
  clipOfferView: {
    width: CARD_WIDTH * 0.845,
    top: scale(280),
    position: 'absolute',
    marginLeft: Platform.select({
      ios: 10,
    }),
    justifyContent: 'center',
    alignContent: 'center',
  },
  productDetailText: {
    textAlign: 'left',
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
    fontSize: scale(12),
    lineHeight: lineHeight(15),
  },
  cardContentItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginTop: scale(10),
    marginHorizontal: scale(15),
    width: containerWidth,
  },
});

export default PDPSavingsCard;

import logger from '@utils/logger';
import * as ExLocation from 'expo-location';
import { useState } from 'react';
import useEffectOnce from './useEffectOnce';

const requestPermissions = async () => {
  const { status: existingStatus } = await ExLocation.getForegroundPermissionsAsync();
  let finalStatus = existingStatus;
  // No existing permisson, ask for it
  if (existingStatus !== 'granted') {
    const { status } = await ExLocation.requestForegroundPermissionsAsync();
    finalStatus = status;
  }
  return finalStatus === 'granted';
};

const canRequestPermissions = async () => {
  const response = await ExLocation.getForegroundPermissionsAsync();
  return response.status === ExLocation.PermissionStatus.UNDETERMINED || response.canAskAgain;
};

const hasPermissions = async () => {
  const { status: existingStatus } = await ExLocation.getForegroundPermissionsAsync();

  return existingStatus === ExLocation.PermissionStatus.GRANTED;
};

const useLocation = () => {
  const [location, setLocation] = useState<ExLocation.LocationObject>();

  useEffectOnce(() => {
    const getLocation = async (options?: ExLocation.LocationOptions) => {
      try {
        if (!(await hasPermissions())) {
          return;
        }
        var lastLocation = await ExLocation.getCurrentPositionAsync(options);
        setLocation(lastLocation || undefined);
      } catch (ex) {
        logger.error(ex);
      }
    };
    getLocation();
  });
  return location;
};

const getLastKnownLocation = async (options?: ExLocation.LocationLastKnownOptions) => {
  try {
    if (!(await hasPermissions())) {
      return;
    }
    var currLocation = await ExLocation.getLastKnownPositionAsync(options);
    return currLocation || undefined;
  } catch (ex) {
    logger.error(ex);
  }
};

const isDeviceLocationEnabled = async () => {
  const response = await ExLocation.hasServicesEnabledAsync();
  return response;
};

export { canRequestPermissions, getLastKnownLocation, hasPermissions, isDeviceLocationEnabled, requestPermissions, useLocation };

import { TextInput } from '@components';
import CustomizeFooter from '@components/CustomizeFooter';
import Text from '@components/Text';
import colors from '@config/colors';
import { CustomizeStackRoutes, CustomizeStackScreenProps } from '@navigation/routes';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform, ScrollView, StyleSheet, View } from 'react-native';
const { height } = Dimensions.get('window');

interface NotesScreenProps extends CustomizeStackScreenProps<CustomizeStackRoutes.Notes> {}
const NotesScreen: FC<NotesScreenProps> = ({ navigation }): JSX.Element => {
  const { notes, setNotes, currStep } = useCustomizeStore();
  const { t } = useTranslation('customization');

  useEffect(() => {
    navigation.setParams({ forwardNavEnabled: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currStep]);

  return (
    <View style={styles.container}>
      <ScrollView bounces={true} style={styles.body}>
        <Text testID="addItemNotes" style={[styles.selectText, appStyles.customizeH2]}>
          Add item notes
          <Text testID="optionalText" style={[styles.pickLimitText, appStyles.bodySmallLight]}>
            {'    '}Optional
          </Text>
        </Text>
        <TextInput
          testID="noteInput"
          autoCapitalize={'sentences'}
          onChangeText={(text) => setNotes(text.replace(/(\r\n|\n|\r)/gm, ''))}
          value={notes}
          style={styles.noteInput}
          maxLength={250}
          multiline
          numberOfLines={10}
          blurOnSubmit={true}
          returnKeyType={'done'}
          underlineColorAndroid="transparent"
          placeholder={t('defaultNoteText')}
          placeholderTextColor="rgba(0,0,0,0.4)"
        />
        <Text testID="characterLimit" style={styles.limitText}>
          250 Character Limit
        </Text>
      </ScrollView>
      <View style={styles.footerWrapper}>
        <CustomizeFooter showTotal={false} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: screenWidth,
    flex: 1,
    padding: 16,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
  },
  body: {
    height: '30%',
    width: screenWidth,
    paddingHorizontal: 20,
    paddingTop: Platform.OS === 'ios' ? (height > 740 ? height * 0.01 : height * 0.01) : height > 640 ? height * 0.01 : height * 0.01,
    // overflow: 'scroll',
    // paddingBottom: 30,
    // backgroundColor: 'blue',
  },
  itemText: {
    fontSize: scale(14),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 15,
  },
  selectText: {
    fontSize: scale(20.5),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
    marginBottom: 18,
    paddingTop: Platform.select({
      android: 15,
    }),
  },
  pickLimitText: {
    fontSize: scale(13),
    color: 'rgba(0,0,0,0.4)',
  },
  noteInput: {
    backgroundColor: colors.white,
    width: '100%',
    height: height > 640 ? screenHeight * 0.3 : '30%',
    borderRadius: 28,
    paddingHorizontal: 20,
    paddingTop: 20,
    lineHeight: 20,
    textAlignVertical: 'top',
    color: Platform.select({
      android: colors.black,
    }),
  },
  limitText: {
    color: 'rgba(0,0,0,0.4)',
    fontSize: scale(13),
    alignSelf: 'flex-end',
    marginTop: 5,
  },
  footerWrapper: {
    // backgroundColor: 'gold',
    // paddingTop: 45,
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.5,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 32,
    marginBottom: -60,
  },
  // footerWrapper: {
  //   // backgroundColor: 'gold',
  //   width: '90%',
  //   paddingTop: height > 740 ? 50 : 50,
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   alignSelf: 'center',
  //   // paddingBottom: 50,
  // },
});

export default NotesScreen;

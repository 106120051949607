import { HtmlView, Icon, Text } from '@components';
import { IconName } from '@components/Icon';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { TourKey } from '@fieldera-raleys/client-common';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { HomeScreen } from '@screens';
import { WalletScreen } from '@screens/account';
import { useUserSettingsStore } from '@store';
import useTutorialStore from '@store/tutorialStore';
import { scale } from '@styles/constants';
import Airship, { EventType, Subscription } from '@ua/react-native-airship';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { useTourGuideController } from 'rn-tourguide';
import { useCustomModalContext } from '../contexts';
import AccountStackNavigator from './AccountNavigator';
import RewardsStackNavigator from './RewardsNavigator';
import ShopStackNavigator from './ShopNavigator';
import { AccountStackRoutes, AppStackRoutes, MessageCenterStackRoutes, RewardsStackRoutes, RootTabParamList, RootTabRoutes, ShopStackRoutes } from './routes';

const INACTIVE_TINT = 'transparent';
const TabNavigator = createBottomTabNavigator<RootTabParamList>();

const RootTabNavigator = () => {
  const { userSettings, updateSetting: updateUserSetting } = useUserSettingsStore();
  const { setVisitCount, getVisitCount } = useTutorialStore();
  const navigationRef = useNavigation();
  const { showAMSCard } = useCustomModalContext();
  const { t } = useTranslation('tourGuide');

  useEffect(() => {
    if (Platform.OS !== 'web') {
      let subscriptions: Subscription[] = [];

      subscriptions.push(
        Airship.addListener(EventType.DisplayMessageCenter, (event) => {
          if (event.messageId != null) {
            navigationRef.navigate(AppStackRoutes.RootTabs, {
              screen: RootTabRoutes.Account,
              params: {
                screen: AccountStackRoutes.MessageCenter,
                params: { screen: MessageCenterStackRoutes.MessageDetails, params: { messageId: event.messageId, title: '' } },
              },
            });
          } else {
            navigationRef.navigate(AppStackRoutes.RootTabs, {
              screen: RootTabRoutes.Account,
              params: {
                screen: AccountStackRoutes.MessageCenter,
                params: { screen: MessageCenterStackRoutes.Inbox },
              },
            });
          }
        }),
      );

      return () => {
        if (subscriptions.length) {
          subscriptions.forEach((s) => s.remove());
          subscriptions = [];
        }
      };
    }
  }, [navigationRef, updateUserSetting]);

  const renderTabBarLabel = useCallback(
    (name: string, focused: boolean, color: string): JSX.Element => (
      <View style={[styles.labelContantiner, { borderBottomColor: focused ? color : INACTIVE_TINT }]}>
        <Text style={[styles.label, { color }]} allowFontScaling={false} testID={name}>
          {name}
        </Text>
      </View>
    ),
    [],
  );

  const renderTabBarIcon = useCallback((name: IconName, color: string): JSX.Element => {
    return <Icon name={name} stroke={color} size={30} style={styles.icon} testID={name} />;
  }, []);

  const { TourGuideZone } = useTourGuideController(TourKey.HomeScreen);

  const renderTabBarIconWithTourGuide = useCallback(
    (name: IconName, tooltip: any, zone: number, color: string): JSX.Element => {
      return (
        <TourGuideZone
          text={tooltip}
          zone={zone}
          shape={'circle'}
          style={styles.tourguideTabbarIcon}
          maskOffset={Platform.select({ android: -10, default: 0 })}
          isTourGuide={getVisitCount('Home') === 1}>
          <Icon name={name} stroke={color} size={30} style={styles.icon} testID={name} />
        </TourGuideZone>
      );
    },
    [TourGuideZone, getVisitCount],
  );

  return (
    <>
      <TabNavigator.Navigator
        backBehavior={'history'}
        id={'RootTabNavigator'}
        screenOptions={{
          headerShown: false,
          tabBarActiveTintColor: colors.secondary,
          tabBarInactiveTintColor: colors.primary,
          tabBarAllowFontScaling: false,
          tabBarLabelPosition: 'below-icon',
        }}>
        <TabNavigator.Screen
          name={RootTabRoutes.Home}
          component={HomeScreen}
          options={{
            headerBackgroundContainerStyle: { backgroundColor: colors.primary },
            tabBarLabel: ({ focused, color }) => renderTabBarLabel('Home', focused, color),
            tabBarIcon: ({ color }) => renderTabBarIcon('home-icon', color),
          }}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              setVisitCount('Home');
              e.preventDefault();
              navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
            },
          })}
        />
        <TabNavigator.Screen
          name={RootTabRoutes.Shop}
          component={ShopStackNavigator}
          options={{
            tabBarLabel: ({ focused, color }) => renderTabBarLabel('Shop', focused, color),
            tabBarIcon: ({ color }) => renderTabBarIconWithTourGuide('shop-icon', t('tgTabBarShopOptionTxt'), 2, color),
          }}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              e.preventDefault();
              navigation.navigate(RootTabRoutes.Shop, { screen: ShopStackRoutes.ShopLanding, params: { reload: true } });
            },
          })}
        />
        <TabNavigator.Screen
          name={RootTabRoutes.Rewards}
          listeners={({ navigation }) => ({
            tabLongPress: showAMSCard,
            tabPress: (e) => {
              setVisitCount('Rewards');
              e.preventDefault();
              navigation.navigate(RootTabRoutes.Rewards, { screen: RewardsStackRoutes.SEHome, params: { reload: true } });
            },
          })}
          component={RewardsStackNavigator}
          options={{
            tabBarLabel: ({ focused, color }) => renderTabBarLabel('Rewards', focused, color),
            tabBarIcon: ({ color }) =>
              renderTabBarIconWithTourGuide(
                'rewards-icon',
                <HtmlView
                  contentWidth={100}
                  text={`${Platform.select({
                    ios: '<br />',
                    default: '',
                  })}
                  <span style="text-align: center; font-family: 'Larsseit Light'; font-size: 17px; color: #4B5358;">
                  <b>Welcome to our App!</b>
                  <br /><br />
                  Offers and coupons <br /> are located in Rewards tab.</span>`}
                />,
                1,
                color,
              ),
          }}
        />
        <TabNavigator.Screen
          name={RootTabRoutes.Wallet}
          component={WalletScreen}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              e.preventDefault();
              navigation.navigate(RootTabRoutes.Wallet, { showBack: false });
            },
          })}
          options={{
            tabBarLabel: ({ focused, color }) => renderTabBarLabel('Wallet', focused, color),
            tabBarIcon: ({ color }) => renderTabBarIcon('wallet-icon', color),
          }}
          initialParams={{ showBack: false }}
        />
        <TabNavigator.Screen
          name={RootTabRoutes.Account}
          component={AccountStackNavigator}
          options={{
            tabBarBadge: (userSettings?.unreadMessageCount ?? 0) > 0 ? '' : undefined,
            tabBarBadgeStyle: {
              backgroundColor: colors.secondary,
              left: 2.5,
              top: -1,
              transform: [{ scale: 0.4 }],
            },
            tabBarLabel: ({ focused, color }) => renderTabBarLabel('Account', focused, color),
            tabBarIcon: ({ color }) => renderTabBarIcon('account-icon', color),
          }}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              setVisitCount('Account');
              e.preventDefault();
              navigation.navigate(RootTabRoutes.Account, { screen: AccountStackRoutes.AccountHome, params: { reload: true } });
            },
          })}
        />
      </TabNavigator.Navigator>
    </>
  );
};

const styles = StyleSheet.create({
  label: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(11),
    textAlign: 'center',
  },
  labelContantiner: {
    borderBottomWidth: 2,
    width: '70%',
  },
  icon: {
    width: 30,
    height: 30,
  },
  tourguideTabbarIcon: {
    width: 75,
    height: 75,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
});

export default RootTabNavigator;

//ref: https://github.com/dev-yakuza/react-native-image-modal/
import dayjs from 'dayjs';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Dimensions, Platform, StatusBar, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { FastImageProps, ImageStyle, ResizeMode } from 'react-native-fast-image';

import { assets } from '@config/assets';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import { Product, ProductVariant } from '@fieldera-raleys/client-commercetools/schema';
import { useShopStore } from '@store';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import { resizeImage } from '@utils/helpers';
import { ProductPromotion, getfulfillmentLeadTimeMinutes } from '@utils/productHelper';
import { isNumber } from 'lodash';
import Config from 'react-native-config';
import Icon, { IconName } from './Icon';
import Text from './Text';
import { Image, ImageDetailModal } from './image';

interface ProductImageState {
  isOpen: boolean;
  origin: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

interface ProductImageProps extends Omit<FastImageProps, 'source' | 'style'> {
  product?: Product | ProductVariant;
  productLeadTime?: number;
  imageUrl: string;
  imageStyle?: StyleProp<ViewStyle>;
  imageSize?: 'small' | 'medium' | 'large';
  renderToHardwareTextureAndroid?: boolean;
  isRTL?: boolean;
  isTranslucent?: boolean;
  swipeToDismiss?: boolean;
  imageBackgroundColor?: string;
  overlayBackgroundColor?: string;
  hideCloseButton?: boolean;
  disabled?: boolean;
  modalImageStyle?: ImageStyle;
  modalImageResizeMode?: ResizeMode;
  onLongPressOriginImage?: () => void;
  showBadge?: boolean;
  productPromotion?: ProductPromotion;
}

const ProductImage = ({
  product,
  imageUrl,
  imageStyle,
  productLeadTime,
  imageSize = 'medium',
  renderToHardwareTextureAndroid,
  isTranslucent,
  isRTL,
  swipeToDismiss,
  imageBackgroundColor,
  overlayBackgroundColor,
  hideCloseButton,
  disabled,
  modalImageStyle,
  modalImageResizeMode,
  resizeMode,
  onLongPressOriginImage,
  showBadge = true,
  productPromotion,
  ...fastImageProps
}: ProductImageProps) => {
  const { selectedStore } = useShopStore();
  var leadTime = productLeadTime ?? getfulfillmentLeadTimeMinutes(product);
  let masterVariant: ProductVariant | undefined;
  let previouslyPurchased;
  let promoIndicator;
  let priceCustomValues;
  let priceMessage = '';

  if (product) {
    masterVariant = ('masterData' in product ? product?.masterData.current?.masterVariant : product) as ProductVariant;
    previouslyPurchased = masterVariant.attributesRaw && Boolean(getProductAttributeValue('previouslyPurchased', masterVariant.attributesRaw) ?? false);
    promoIndicator = masterVariant.attributesRaw && getProductAttributeValue('promotionIndicators', masterVariant.attributesRaw);
    priceCustomValues = masterVariant.price?.custom?.customFieldsRaw ?? [];
    priceMessage =
      priceCustomValues.length &&
      priceCustomValues.find((pcv) => pcv.name === 'PriceMessage')?.value &&
      priceCustomValues.find((pcv) => pcv.name === 'PriceMessage')?.value;

    priceMessage = isNumber(priceMessage) ? '' : priceMessage;
  }
  var currDate = dayjs().format('YYYY-MM-DD');
  const modalRef = useRef<ImageDetailModal>(null);
  const [state, setState] = useState<ProductImageState>({ isOpen: false, origin: { x: 0, y: 0, width: 0, height: 0 } });
  const originImageOpacity = new Animated.Value(1);
  const rootViewRef = createRef<View>();

  const setOrigin = useCallback(() => {
    if (rootViewRef.current) {
      rootViewRef.current.measureInWindow((x: number, y: number, width: number, height: number) => {
        let newY: number = y;
        if (isTranslucent) {
          newY += StatusBar.currentHeight ? StatusBar.currentHeight : 0;
          StatusBar.setHidden(true);
        }
        let newX: number = x;
        if (isRTL) {
          newX = Dimensions.get('window').width - width - x;
        }
        setState((prev) => ({
          ...prev,
          origin: {
            width,
            height,
            x: newX,
            y: newY,
          },
        }));
      });
    }
  }, [isRTL, isTranslucent, rootViewRef]);

  useEffect(() => {
    if (Platform.OS === 'android' && isTranslucent) {
      StatusBar.setTranslucent(isTranslucent);
    }
    const changeListner = Dimensions.addEventListener('change', () => {
      setTimeout(() => {
        setOrigin();
      }, 100);
    });
    return () => {
      changeListner.remove();
    };
  }, [isTranslucent, setOrigin]);

  const handleOpen = (): void => {
    rootViewRef.current && originImageOpacity.setValue(0);
    setOrigin();
    setState((prev) => ({ ...prev, isOpen: true }));
  };

  const handleClose = () => {
    rootViewRef.current && originImageOpacity.setValue(1);
    setState((prev) => ({ ...prev, isOpen: false }));
  };

  const fallbackImage = assets.imageComingSoon;

  imageUrl = imageSize === 'medium' ? resizeImage(imageUrl, 350, 350) : imageSize === 'small' ? resizeImage(imageUrl, 150, 150) : imageUrl;

  const getImageSource = () => {
    let url;

    if (imageUrl) {
      url = { cache: 'web', uri: `${imageUrl.indexOf('//') === 0 ? 'https:' + imageUrl : imageUrl}` };
    } else {
      url = fallbackImage;
    }

    return url;
  };

  const displayBadge = () => {
    let badgeType;
    if (productPromotion?.badgeType) {
      badgeType = productPromotion?.badgeType.toLowerCase();
    }
    if (badgeType) {
      return (
        <View style={styles.badgeWrapper}>
          {productPromotion?.isAccepted ? (
            <Icon testID={`${badgeType}-clipped`} name={`${badgeType}-clipped` as IconName} style={[styles.clippedIcon]} stroke={'none'} />
          ) : (
            <Icon testID={`${badgeType}-unclipped`} name={`${badgeType}-unclipped` as IconName} stroke={'none'} style={[styles.unclippedIcon]} />
          )}
        </View>
      );
    } else {
      return null;
    }
  };

  const displayFlags = () => {
    return productPromotion?.autoApply && productPromotion.flagHeadline && productPromotion.extFlagTypeCode ? (
      <View style={styles.priceMessageBar}>
        <Text testID="flagMessage" style={styles.priceMessageText}>
          {productPromotion.flagHeadline}
        </Text>
      </View>
    ) : null;
  };

  return (
    <>
      <View style={[{ backgroundColor: imageBackgroundColor }]} collapsable={false} ref={rootViewRef}>
        <Animated.View renderToHardwareTextureAndroid={renderToHardwareTextureAndroid === false ? false : true} style={{ opacity: originImageOpacity }}>
          <TouchableOpacity
            disabled={disabled}
            activeOpacity={1}
            style={[styles.squareImage, imageStyle, { alignSelf: 'baseline' }]}
            onPress={handleOpen}
            onLongPress={onLongPressOriginImage}>
            <Image resizeMode={resizeMode ?? 'contain'} source={getImageSource()} style={styles.image} {...fastImageProps} />
            {previouslyPurchased && (
              <View style={styles.previouslyPurchasedWrapper}>
                <Icon testID="clockIcon" name="clock-icon" size={20} />
                {imageSize === 'large' && <Text style={styles.prevPurchasedText}>Previously Purchased</Text>}
              </View>
            )}
            {promoIndicator && promoIndicator?.includes('SE Offer') ? (
              <View style={styles.extraBar}>
                <Icon
                  testID="somethingExtra"
                  name="something-extra"
                  fill={colors.seRed}
                  size={imageSize === 'large' ? 50 : 26}
                  stroke={'transparent'}
                  strokeSecondary={colors.white}
                />
              </View>
            ) : null}

            {productPromotion && productPromotion.sku && productPromotion.flagHeadline ? (
              displayFlags()
            ) : priceMessage ? (
              <View style={styles.priceMessageBar}>
                <Text testID="priceMessage" style={styles.priceMessageText}>
                  {priceMessage}
                </Text>
              </View>
            ) : masterVariant &&
              getProductAttributeValue('preorderDate', masterVariant.attributesRaw) <= currDate &&
              currDate < getProductAttributeValue('startAvailableOrderDate', masterVariant.attributesRaw) ? (
              <View style={styles.leadTimeBar}>
                <Text testID="preOrderNow" style={styles.leadTimeText}>
                  Pre-Order Now
                </Text>
              </View>
            ) : leadTime > +(selectedStore?.fulfillmentLeadTimeMinutes ?? Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES) ? (
              <View style={styles.leadTimeBar}>
                <Text testID="hourLeadTime" style={styles.leadTimeText}>
                  {Math.ceil(leadTime / 60)}-Hour Lead Time
                </Text>
              </View>
            ) : leadTime > +(Config.LEAD_TIME_MAX_HOURS_TOSHOW ?? 48) * 60 ? (
              <View style={styles.leadTimeBar}>
                <Text testID="dayLeadTime" style={styles.leadTimeText}>
                  {Math.ceil(leadTime / 60 / 24)}-Day Lead Time
                </Text>
              </View>
            ) : null}
          </TouchableOpacity>
        </Animated.View>
      </View>
      <View style={{ top: '-100%', right: '-9%' }}>{showBadge && displayBadge()}</View>
      {!disabled && (
        <ImageDetailModal
          ref={modalRef}
          renderToHardwareTextureAndroid={renderToHardwareTextureAndroid}
          isTranslucent={isTranslucent}
          isOpen={state.isOpen}
          origin={state.origin}
          source={{ cache: 'web', uri: `${imageUrl.indexOf('//') === 0 ? 'https:' + imageUrl : imageUrl}` }}
          resizeMode={modalImageResizeMode || resizeMode}
          backgroundColor={overlayBackgroundColor}
          swipeToDismiss={swipeToDismiss}
          hideCloseButton={hideCloseButton}
          imageStyle={modalImageStyle}
          onClose={handleClose}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%',
  },
  squareImage: {
    width: screenWidth * 0.45,
    aspectRatio: 1,
    borderWidth: 1,
    borderColor: colors.productBorder,
    borderStyle: 'solid',
  },
  previouslyPurchasedWrapper: {
    backgroundColor: colors.white,
    opacity: 0.85,
    position: 'absolute',
    flexDirection: 'row',
    left: 8,
    top: 8,
    borderRadius: 50,
    alignItems: 'center',
    padding: scale(1),
  },
  prevPurchasedText: {
    fontFamily: FontFamily.LarsseitThin,
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    color: colors.darkText,
    paddingHorizontal: 8,
    paddingTop: 1,
    paddingBottom: 1,
    position: 'relative',
    top: scale(1),
    right: 4,
  },
  extraBar: {
    position: 'absolute',
    top: -2,
    right: 0,
    alignItems: 'center',
  },
  extraText: {
    color: colors.white,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
  },
  promoBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '75%',
    borderTopRightRadius: 15,
    backgroundColor: colors.gold,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 7,
  },
  promoText: {
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(13),
  },
  leadTimeBar: {
    backgroundColor: colors.teal,
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderTopRightRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  leadTimeText: {
    color: colors.white,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(13),
  },
  priceMessageBar: {
    backgroundColor: colors.gold,
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderTopRightRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  priceMessageText: {
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(13),
  },
  badgeWrapper: {
    position: 'absolute',
    left: scale(110),
  },
  clippedIcon: {
    width: scale(60),
    height: scale(35),
    marginTop: -8,
    marginLeft: -25,
  },
  unclippedIcon: {
    width: scale(55),
    height: scale(35),
    marginTop: -7,
    marginLeft: -25,
  },
});

export default ProductImage;

import { ClientSourceInfo, LineItem, Product, ProductVariant, ShoppingListLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { OrderType, ShippingMethodType } from '@fieldera-raleys/client-common';
import { PaymentProfile } from '@fieldera-raleys/client-common/services/brandywine/types';
import dayjs from 'dayjs';
import { Platform, PlatformAndroidStatic, PlatformIOSStatic, PlatformMacOSStatic, PlatformWindowsOSStatic, Vibration } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import ReactNativeHapticFeedback, { HapticFeedbackTypes } from 'react-native-haptic-feedback';
import UAParser from 'ua-parser-js';
import logger from './logger';

const ORDER_SOURCE_SEPARATOR: string = ' ';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const formatValue = (value: number | string | undefined): string => {
  const val = parseFloat(('' + value ?? '').replace(/[^0-9\.]/g, ''));
  if (isNaN(val)) {
    return '';
  }
  return currencyFormatter.format(val);
};

export const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'medium',
});

export const formatDate = (value: number | Date | undefined): string => {
  return dateFormatter.format(value);
};

export const formatName = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/[^a-zA-Z0-9 \,\'\.\-\_\`\!\&]/g, '') : defaultValue.replace(/\\/g, '');
};

export const formatEmoji = (value?: string, defaultValue: string = ''): string | undefined => {
  const emojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  return value ? value.replace(emojiRegex, '') : defaultValue.replace(emojiRegex, '');
};

export const formatPhone = (value?: string, defaultValue: string = ''): string | undefined => {
  let pn = (value ?? defaultValue)
    .replace(/[^0-9]*/g, '')
    .slice(0, 11) // include area code ( for outside us (+1) needs extra logic
    .slice(-10); // throw away the area code if any
  return pn.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const unformatPhone = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/[^\d\+]/g, '') : defaultValue;
};

export const formatCardExpiryDate = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/\D+/g, '').replace(/(\d{2})(\d{2})/, '$1/$2') : defaultValue;
};

export const formatDOB = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/\D+/g, '').replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3') : defaultValue;
};

export const formatInstruction = (value?: string, defaultValue: string = ''): string | undefined => {
  return value ? value.replace(/"/g, '"') : defaultValue;
};

export const hapticNotify = (type: HapticFeedbackTypes) => {
  if (Platform.OS !== 'web') {
    ReactNativeHapticFeedback.trigger(type, {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
  }
};

type VibrateFeedbackTypes = 'OneSecond' | 'Buzz';

const VibrateFeedbackPattern: { [key in VibrateFeedbackTypes]: number | number[] } = {
  OneSecond: 1000,
  Buzz: [100, 200, 100, 200],
};

export const vibrateNotify = (type: VibrateFeedbackTypes) => {
  if (Platform.OS !== 'web') {
    Vibration.vibrate(VibrateFeedbackPattern[type]);
  }
};

export const filterUnavailableProducts = (products: Product[]) => {
  return products.filter((p) => p?.masterData?.current?.masterVariant.price !== null && p?.masterData.published);
};

export const filterUnavailableVariants = (variants: ProductVariant[]) => {
  return variants.filter((p) => p?.price !== null);
};

export const sortProducts = (products: Product[], catId: string) => {
  return products?.sort(
    (a, b) =>
      +(a?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catId[0])?.orderHint || 0.8) -
      +(b?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catId[0])?.orderHint || 0.8),
  );
};

export const withRetry = async <T>(action: () => Promise<T>, retries = 3): Promise<T> => {
  try {
    return await action();
  } catch (ex: any) {
    if (retries > 0) {
      return withRetry(action, retries - 1);
    } else {
      throw ex;
    }
  }
};

export const decimalFormat = (num: number): number => {
  return parseFloat(num.toFixed(2).replace(/\.00$/, ''));
};

export const sumProductPriceList = (productList: Product[] | LineItem[] | ShoppingListLineItem[]) => {
  if (!productList) {
    return;
  }
  var value: number = 0;
  if ((<LineItem[]>(<unknown>productList))[0] && (<LineItem[]>(<unknown>productList))[0].variant) {
    (<LineItem[]>(<unknown>productList)).forEach((li) => {
      value += (li?.variant?.price?.value.centAmount ?? 0) / 10 ** (li?.variant?.price?.value?.fractionDigits ?? 2);
    });
    return `$${value.toFixed(2)}`;
  }
};

export function getShippingMethods() {
  var keys = Object.keys(ShippingMethodType);
  return keys.slice(keys.length / 2) as (keyof typeof ShippingMethodType)[];
}

export function getOrderTypes() {
  var keys = Object.keys(OrderType);
  return keys.slice(keys.length / 2) as (keyof typeof OrderType)[];
}

export const toQueryString = (params: Record<string, string | number | undefined>) => {
  return Object.keys(params)
    .map((key) => params[key] && `${key}=${params[key]}`)
    .filter((x) => !!x)
    .join('&');
};

export default Object.freeze({
  SpecialCharacter: /[*|\":<>[\]{}`\\();$@?!^+_%#=~`]/,
  PASSWORD_REGEX: /(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/,
  PHONE_NUMBER_REGEX: /^(\+\d{1,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/gm,
  EXPIRATION_DATE_REGEX: /([0-9]{2})\/([0-9]{2})/,
  BIRTH_DATE_REGEX: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])$/,
  EMAIL_REGEX:
    // eslint-disable-next-line no-control-regex
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,
  SENUMBER_REGEX: /^\d{10,12}$/,
  CVV_REGEX: /^[0-9]{3,4}$/,
  DOB_REGEX: /^\d{2}\/\d{2}\/\d{4}$/,
  POSTAL_CODE_REGEX: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
});

export const resizeImage = (imageUrl: string, width: number, height: number): string => {
  var parts = imageUrl.split('/');
  if (parts.length < 9) {
    return imageUrl;
  } else {
    parts[6] = `${width}`;
    parts[7] = `${height}`;
    return parts.join('/');
  }
};

export const isCardExpired = (item: PaymentProfile): boolean => {
  return dayjs(item.CardExpirationDate).add(1, 'month').isBefore(dayjs());
};

export const slug = (url: string) => {
  const newURL = new URL(url);
  return newURL.pathname + newURL.search;
};

export const getHtmlAttribute = (name: string, htmlText: string) => {
  var re = new RegExp(`${name}="([^"]*)`);
  var result = re.exec(htmlText ?? '');

  return result !== null ? result[1] : '';
};

export const findTag = (text: string, tag: string) => {
  var re = new RegExp('tags:([^]*)', 'i');
  var result = re.exec(text ?? '');

  const tags = result !== null ? result[1] : '';
  const tagsArr = tags ? tags.split(',') : null;

  return tagsArr ? tagsArr.find((x) => x.toLowerCase() === `${tag}`) : null;
};

export function linktoNavigation(url: string, linkto: any, failOnError?: boolean | undefined): any {
  if (url?.length) {
    let path = url.replace(/(^\w+:|^)\/\//, '/');
    path = path.includes('search') ? (path.includes('query') ? path : path.includes('?') ? path + '&query=*' : path + '?query=*') : path;
    path = path.startsWith('/') ? path : '/' + path;
    try {
      return linkto(path);
    } catch (error) {
      if (failOnError) {
        throw error;
      }

      logger.error(error, { path });
      path = path.includes('search')
        ? '/search?query=*'
        : path.includes('category') || path.includes('product')
          ? '/categories'
          : path.includes('collection')
            ? '/collections'
            : path.includes('shelf-guide')
              ? '/shelf-guide-list'
              : '/home';
      path = '/search?query=*';
      path = '/categories';
      path = '/collections';
      path = '/home';
      return linkto(path);
    }
  } else {
    return null;
  }
}

export const getClientSourceInfo = async (): Promise<ClientSourceInfo> => {
  let staticPlatform;
  // start the asyn usrAgent load for orderSourceClientInfo
  const userAgent = new UAParser(await DeviceInfo.getUserAgent());
  const browser = userAgent.getBrowser();
  const os = userAgent.getOS();
  const device = userAgent.getDevice();
  return {
    orderSourceClientType: 'App', // defaults
    orderSourceClientDevice: [DeviceInfo.getDeviceType(), DeviceInfo.getBrand(), DeviceInfo.getModel()].join(ORDER_SOURCE_SEPARATOR),
    orderSourceAppVersion: DeviceInfo.getVersion(),
    orderSourceClientBrowser: [browser.name, browser.version].join(ORDER_SOURCE_SEPARATOR),
    ...Platform.select({
      ios: {
        orderSourceClientOS: [(staticPlatform = Platform as PlatformIOSStatic).constants.systemName, staticPlatform.constants.osVersion].join(
          ORDER_SOURCE_SEPARATOR,
        ),
      },
      android: {
        orderSourceClientOS: [DeviceInfo.getSystemName(), (staticPlatform = Platform as PlatformAndroidStatic).constants.Version].join(ORDER_SOURCE_SEPARATOR), // improved
      },
      macos: {
        orderSourceClientOS: [DeviceInfo.getSystemName(), (staticPlatform = Platform as PlatformMacOSStatic).constants.osVersion].join(ORDER_SOURCE_SEPARATOR),
      },
      windows: {
        orderSourceClientOS: [DeviceInfo.getSystemName(), (staticPlatform = Platform as PlatformWindowsOSStatic).constants.osVersion].join(
          ORDER_SOURCE_SEPARATOR,
        ),
      },
      default: {
        // web
        orderSourceClientOS: [os.name, os.version].join(ORDER_SOURCE_SEPARATOR),
        orderSourceClientDevice: [device.type, device.vendor, device.model].join(ORDER_SOURCE_SEPARATOR),
      },
    }),
  };
};

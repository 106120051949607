import { BottomBar, Button, Carousel, HeaderTitle, LoadingScreen, Modal, NavigationHeader, Screen } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import Text from '@components/Text';
import OfferCard from '@components/somethingExtra/OfferCard';
import OfferCardH from '@components/somethingExtra/OfferCardH';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { StackActions, useIsFocused, useNavigation } from '@react-navigation/native';
import { storeService as bwStoreService } from '@services/brandywine';
import { useCartStore, useCommerceToolsStore, useOffersStore, useShopStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenWidth } from '@styles/constants';
import { evaluateCart } from '@utils/cartHelper';
import logger from '@utils/logger';
import { getFulfilmentStore, isDelivery } from '@utils/orderHelpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

const SLIDE_GAP = 15;
const CARD_WIDTH = 165;

const SavingsReminderScreen = () => {
  const { defaultStore } = useCommerceToolsStore();
  const navigation = useNavigation();
  const { acceptOffer } = useOffersStore();
  const [availableOffers, setAvailableOffers] = useState<Promotion[]>([]);
  const [acceptedOffers, setAcceptedOffers] = useState<Promotion[]>([]);
  const [offersProcessed, setOffersProcessed] = useState<boolean>(false);
  const { acceptedSomethingExtraOffers, acceptedWeeklyExclusiveOffers, acceptedDigitalCoupons } = useOffersStore();
  const [modalData, setModalData] = useState<{ [key: string]: any }>({ showModal: false });
  const { getAgeVerificationRequired, cart, setPromotions } = useCartStore();
  const { selectedShopType, selectedStore } = useShopStore();
  const isFocused = useIsFocused();
  const { t } = useTranslation('savingsReminder');
  const carouselConfig = {
    slidesPerPage: 1,
    nextSlidePartial: CARD_WIDTH * 0.44,
    slideWidth: CARD_WIDTH,
    sgPerLine: 1,
  };

  useEffect(() => {
    if (modalData && !modalData.showModal && modalData.onClose) {
      if (modalData.onClose) {
        modalData.onClose();
        setModalData({ ...modalData, onClose: undefined });
      }
    }
  }, [modalData]);

  useEffect(() => {
    if (cart) {
      const loadPromotions = async () => {
        evaluateCart(cart, selectedStore ? selectedStore.number : defaultStore?.key ?? '', selectedShopType === 'Pickup' ? 'pickup' : 'delivery')
          .then((result) => {
            setAvailableOffers(result.filter((x) => okToShow(x) && !(x.AutoApply || x.IsAccepted)));
            setAcceptedOffers(result.filter((x) => okToShow(x) && (x.AutoApply === true || x.IsAccepted) && (x.PercentComplete ?? 0) < 100));
            setPromotions(JSON.stringify(result));
          })
          .catch((ex) => {
            logger.error(ex, { method: 'loadPromotions' });
            setAvailableOffers([]);
            setAcceptedOffers([]);
          })
          .finally(() => {
            setOffersProcessed(true);
          });
      };

      //Added this check as the cart changes used to execute these lines of code even when the screen was not on top of stack
      if (isFocused) {
        loadPromotions();
      }

      return () => {
        setOffersProcessed(false);
      };
    }
    //Removed unnecessary dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedDigitalCoupons, acceptedSomethingExtraOffers, acceptedWeeklyExclusiveOffers, cart, defaultStore, selectedShopType, selectedStore]);

  const okToShow = (p: Promotion) => {
    if (p.IsPromoCodePromo && !p.IsAccepted) {
      return false;
    }
    return true;
  };

  const onClipAllClick = async () => {
    if (availableOffers) {
      await acceptOffer(availableOffers.map((item) => +item.ExtPromotionId));
    }
  };

  const showAllClippedView = () => {
    return (
      <View style={[styles.carouselWrapper, styles.itemCenter]}>
        <Text testID="greatJob" style={styles.greatJobText}>
          {t('greatJob')}
        </Text>
        <Text numberOfLines={2} style={(appStyles.smallCenterRegular, styles.clipOfferMsg)}>
          {t('allClippedMsg')}
        </Text>
      </View>
    );
  };

  const renderMaximumSavingView = () => {
    return (
      acceptedOffers.length > 0 && (
        <View style={{ backgroundColor: colors.white, flexDirection: 'column' }}>
          <View style={[styles.clipOfferView]}>
            <Text style={appStyles.bodySmallLeft} testID="addMaximizeSavingsTitle">
              {t('addMaximizeSavingsTitle')}
            </Text>
          </View>

          {acceptedOffers.map((item, index) => {
            return (
              <View style={styles.listCardViewMargin} key={`item${index}`}>
                <OfferCardH item={item} index={index} calledFrom="SavingReminder" />
              </View>
            );
          })}
          <View style={{ height: 70, width: screenWidth, backgroundColor: colors.white }} />
        </View>
      )
    );
  };

  const onCheckoutClick = () => {
    getAgeVerificationRequired().then(async (ages) => {
      if (ages && ages.length > 0) {
        let postalCode, isDel;
        let allowAlcohol = true;
        const fStore = getFulfilmentStore(cart);
        if ((isDel = isDelivery(cart))) {
          postalCode = cart?.shippingAddress?.postalCode;
        } else {
          postalCode = fStore?.address?.postalCode;
        }
        const goBackToCart = () => {
          navigation.dispatch(
            StackActions.replace(AppStackRoutes.CartAndCheckout, {
              screen: CheckoutStackRoutes.CartLanding,
              params: { storeNumber: undefined },
            }),
          );
        };

        if (!postalCode) {
          setModalData({
            location: 'top',
            title: 'Update Selected Store',
            description: 'Please check your Shopping Method and Currently Selected Store.',
            showModal: true,
            onClose: goBackToCart,
          });
        } else {
          let bwSearchedStore;
          bwSearchedStore = await bwStoreService.searchStores(0, undefined, { shippingMethod: isDel ? 'delivery' : 'pickup', postalCode: postalCode });
          if (bwSearchedStore && bwSearchedStore.data.length) {
            const fulStore = bwSearchedStore.data.find((st) => st.number === fStore?.number) ?? bwSearchedStore.data[0];
            if (!fulStore.canSellAlcohol) {
              allowAlcohol = false;
            }

            if (isDel && !fulStore.canDeliverAlcohol) {
              allowAlcohol = false;
            }
          }
        }

        if (allowAlcohol) {
          navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.AgeRequirementScreen, params: { age: ages[0] } });
        } else {
          setModalData({
            location: 'top',
            title: 'Restricted Items',
            description: 'Please check your cart products and substitution selections for alcohol containing items.',
            showModal: true,
            onClose: goBackToCart,
          });
        }
      } else {
        navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.Checkout, params: {} });
      }
    });
  };

  const renderAvailableOfferView = () => {
    return availableOffers && availableOffers.length > 0 ? (
      <View style={styles.carouselWrapper}>
        <Carousel
          windwoSize={16}
          snapToInterval={carouselConfig.slideWidth + SLIDE_GAP}
          contentContainerStyle={{
            ...styles.carouselContainer,
            width: (availableOffers?.length ? availableOffers?.length : 0) * (carouselConfig.slideWidth + SLIDE_GAP) + SLIDE_GAP,
          }}
          entities={availableOffers}
          renderItem={({ item, index }) => {
            return (
              <View style={styles.carouselView}>
                <OfferCard item={item} index={index} calledFrom="SavingReminder" />
              </View>
            );
          }}
          pagerStyle={styles.pagerStyle}
          pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
          pagerInactiveItemStyle={appStyles.carouselDotsSmall}
          showPager={false}
          removeClippedSubviews={false}
        />
      </View>
    ) : null;
  };

  return (
    <>
      <Screen>
        <NavigationHeader subStyle={[utilityStyles.pt2, { alignItems: 'flex-start' }]} backIconstyle={{ marginLeft: scale(-6) }}>
          <HeaderTitle style={styles.headerTitleStyle} testID="navigationHeaderTitle">
            {t('navigationHeaderTitle')}
          </HeaderTitle>
        </NavigationHeader>
        {!offersProcessed ? (
          <LoadingScreen />
        ) : (
          <ScrollView
            contentContainerStyle={[{ height: 'auto' }, Platform.OS === 'web' ? {} : {}]}
            nestedScrollEnabled={true}
            showsVerticalScrollIndicator={false}>
            <CartInfoBanner onTitlePress={() => null} />
            {availableOffers.length > 0 ? (
              <View style={styles.clipOfferView}>
                <Text style={appStyles.bodySmallLeft} testID="clipOfferCardTitle">
                  {t('clipOfferCardTitle')}
                </Text>
                <TouchableOpacity onPress={onClipAllClick} activeOpacity={1} testID="clipAllButton">
                  <Text style={[appStyles.tinylightredlink]} testID="clipAll">
                    {t('clipAll')}
                  </Text>
                </TouchableOpacity>
              </View>
            ) : (
              !availableOffers.length && (
                <View style={styles.clipOfferView}>
                  <Text style={appStyles.bodySmallLeft} testID="clipOfferCardTitle">
                    {t('clipOfferCardTitle')}
                  </Text>
                  <Text style={[appStyles.tinyLight]} testID="allClipped">
                    {t('allClipped')}
                  </Text>
                </View>
              )
            )}
            {!availableOffers?.length ? showAllClippedView() : renderAvailableOfferView()}
            <View>{renderMaximumSavingView()}</View>
          </ScrollView>
        )}
        <Modal
          location={modalData.location ?? 'top'}
          title={modalData.title}
          visible={modalData.showModal}
          cancelButtonOnPress={() => setModalData({ ...modalData, showModal: false })}
          children={
            modalData.description ? (
              <Text testID="modalDescription" style={[appStyles.fontMobileBodySmallRegular]}>
                {modalData.description}
              </Text>
            ) : (
              <Text testID="featureText">This feature is planned for a future version and is not yet available.</Text>
            )
          }
        />
      </Screen>
      <BottomBar>
        <View style={utilityStyles.mt2}>
          <Button size={'small'} buttonStyle={[appStyles.cartBottomButton]} onPress={onCheckoutClick} title={'Checkout'} testID="checkout" />
        </View>
      </BottomBar>
    </>
  );
};

const styles = StyleSheet.create({
  headerTitleStyle: {
    fontSize: scale(27),
  },
  pagerStyle: {
    marginTop: 20,
    marginBottom: 10,
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  carouselWrapper: {
    height: 350,
    width: screenWidth,
    alignSelf: 'center',
  },
  carouselView: {
    marginBottom: 16,
    marginLeft: 12,
  },
  container: {
    flex: 1,
    width: screenWidth,
    height: '100%',
    alignSelf: 'center',
  },
  clipOfferView: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  carouselContainer: {
    borderWidth: 0,
    overflow: 'visible',
    marginRight: 10,
  },
  listCardViewMargin: { marginLeft: 12, marginBottom: 10, flexDirection: 'column' },
  greatJobText: {
    color: colors.red,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(18),
    textAlign: 'left',
    paddingLeft: 5,
  },
  itemCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30,
    borderWidth: 0.23,
    borderRadius: 12,
    width: screenWidth * 0.9,
    height: 200,
    paddingHorizontal: 30,
    backgroundColor: colors.white,
    borderColor: colors.greyBorder,
    paddingVertical: 20,
  },
  clipOfferMsg: {
    margin: 7,
    textAlign: 'center',
    width: screenWidth * 0.5,
    lineHeight: lineHeight(16),
  },
});

export default SavingsReminderScreen;

import { assets } from '@config/assets';
import colors from '@config/colors';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { PagedArray, RecomendationResult, SearchAssistType, Widget } from '@fieldera-raleys/client-common';
import { useEffectOnce } from '@hooks';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation, useRoute } from '@react-navigation/native';
import { brsmService } from '@services';
import { useAppConfigStore } from '@store';
import useSearchStore from '@store/searchStore';
import appStyles from '@styles/appStyles';
import { aspectRatio, lineHeight, scale, screenWidth } from '@styles/constants';
import logger from '@utils/logger';
import React, { FC, useEffect, useState } from 'react';
import { ActivityIndicator, ListRenderItemInfo, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getProductsfromCommerceTools } from '../../utils/productHelper';
import DropShadow from '../DropShadow';
import Icon from '../Icon';
import Text from '../Text';
import { Image } from '../image';
import { List, ListData, ListItemSingleRow } from '../lists';

type Departments = {
  name: string;
  id: string;
};

type QuickSerchProps = {
  onSelect: (searchText: string) => void;
  showRecentOnly?: boolean;
};

enum ItemsTypeEnum {
  FeaturedItems = 'Featured Items',
  RecentlyViewedIems = 'Recently Viewed Items',
}

const SearchAssist: FC<QuickSerchProps> = ({ onSelect, showRecentOnly = false }): JSX.Element => {
  const insets = useSafeAreaInsets();
  const route = useRoute();
  const [loading, setLoading] = useState(false);
  const [loadingFeaturedItems, setLoadingFeaturedItems] = useState(false);
  const pageConfig = useAppConfigStore().getConfigGroup('Search');
  const { recentlyViewed, recentlySearched, removeRecentlySearched } = useSearchStore();
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<Product[]>([]);
  const [featuredItemsProducts, setFeaturedItemsProducts] = useState<Product[]>([]);
  const [departments, setDepartments] = useState<Departments[]>([]);
  const [searchAssistData, setSearchAssistData] = useState<SearchAssistType>({
    popularItems: [],
    featuredItems: [],
    recentlyViewedItems: [],
    recentlySearched: recentlySearched,
  });
  const navigation = useNavigation();
  const { data: categoryData, status, isLoading: loadingDepartments } = brsmService.useCategoriesQuery();

  const fallbackImage = assets.imageComingSoon;

  useEffect(() => {
    const getRecentlyViewed = async () => {
      var products = await getProductsfromCommerceTools(recentlyViewed);
      const sortOrder = recentlyViewed.reduce((list, x, idx) => list.set(x, idx), new Map<string, number>());
      const sortedProductList = products.sort((a, b) => {
        const aSku = a.masterData.current?.masterVariant.sku ?? '';
        const bSku = b.masterData.current?.masterVariant.sku ?? '';
        return (sortOrder.get(aSku) ?? -1) - (sortOrder.get(bSku) ?? -1);
      });

      setRecentlyViewedProducts(sortedProductList);
    };
    getRecentlyViewed();
  }, [recentlyViewed]);

  useEffect(() => {
    if (status === 'success') {
      const departmentsArr = (categoryData ?? []).map((category) => {
        return {
          name: category.name,
          id: category.id,
        };
      });

      setDepartments(departmentsArr);
    }
  }, [status, categoryData]);

  useEffectOnce(() => {
    setLoading(true);

    const getSearchAssistData = async () => {
      const searchAssist = await brsmService.getSearchAssistData();
      setSearchAssistData({ ...searchAssistData, ...searchAssist });
      setLoading(false);
    };

    getSearchAssistData();
  });

  useEffectOnce(() => {
    setLoadingFeaturedItems(true);

    const getFeaturedItems = async () => {
      if (pageConfig) {
        const featuredItems = await brsmService
          .getWidgetData('Bestseller', pageConfig.FeaturedItems)
          .then((result) => {
            return result;
          })
          .catch((error) => {
            logger.warn(error);
            return {
              products: { data: [], offset: 0, limit: 0, total: 0 } as PagedArray<Product>,
              widgetData: {} as Widget,
            } as RecomendationResult;
          });

        featuredItems && featuredItems.products.data.length > 0 && setFeaturedItemsProducts(featuredItems.products.data);

        setLoadingFeaturedItems(false);
      }
    };

    getFeaturedItems();
  });

  const navigateToProductDetailsPage = (productKey: string) => {
    navigation.navigate(AppStackRoutes.ProductDetails, {
      productKey: productKey,
    });
  };

  const navigateFromDepartments = (categoryId: string) => {
    if (route.name === ShopStackRoutes.Category) {
      navigation.dispatch(
        StackActions.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: { screen: ShopStackRoutes.Category, params: { categoryId: categoryId } },
        }),
      );
    } else {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Shop,
        params: { screen: ShopStackRoutes.Category, params: { categoryId: categoryId, isCustomBack: true } },
      });
    }
  };

  const renderListItem = (item: string, showDeleteIcon: boolean, testID: string, title: string, catId: string, action?: (searchText: string) => void) => {
    return (
      <ListItemSingleRow title={item} onPress={() => (title === 'Departments' ? navigateFromDepartments(catId) : onSelect(item))} key={item} testID={testID}>
        {showDeleteIcon && (
          <TouchableOpacity onPress={() => action && action(item)} style={styles.icon} testID={`clearIcon-${testID}`}>
            <Icon fill="#999" stroke="none" name="clear-icon" size={20} style={appStyles.icon} />
          </TouchableOpacity>
        )}
      </ListItemSingleRow>
    );
  };

  const renderList = (title: string, data: string[] | Departments[], isloading: boolean, showDeleteIcon: boolean, action?: (item: string) => void) => (
    <View style={{ marginBottom: title === 'Departments' ? scale(180) : 10 }}>
      <View style={styles.header}>
        <Text style={[appStyles.bodyLeftBold, { marginTop: 10 }]}>{title}</Text>
      </View>
      {isloading ? (
        <ActivityIndicator color={appStyles.primaryButton.color} size={'large'} />
      ) : (
        data.map((item, index) => {
          const testID = `${title.toLowerCase().replace(' ', '-')}-${index}`;
          let name: string = '';
          let catId: string = '';
          if (title === 'Departments') {
            name = (item as Departments).name;
            catId = (item as Departments).id;
          } else {
            name = item as string;
          }
          return renderListItem(name, showDeleteIcon, testID, title, catId, action);
        })
      )}
    </View>
  );

  const renderItems = ({ item }: ListRenderItemInfo<ListData>) => {
    let imageSource;

    if (item.imageUrl) {
      imageSource = { cache: 'web', uri: `${item.imageUrl.indexOf('//') === 0 ? 'https:' + item.imageUrl : item.imageUrl}` };
    } else {
      imageSource = fallbackImage;
    }

    return (
      <TouchableOpacity onPress={() => item.onPress && item.onPress(item)} style={[item.itemStyle, { marginRight: 8 }]}>
        <View style={styles.contentBox}>
          <View style={styles.squareImage}>
            <Image resizeMode={'contain'} source={imageSource} style={[styles.image, item.imageStyle]} />
          </View>
          {item.params === ItemsTypeEnum.FeaturedItems && (
            <Text style={[appStyles.tinyLight, styles.textStyle, item.titleStyle]} numberOfLines={2}>
              {item.title}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={{ height: '100%' }} testID="searchAssist">
      <DropShadow style={styles.dropShadow}>
        <View style={styles.dropDownShadowView} />
      </DropShadow>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{ alignContent: 'center' }}
        style={{ paddingBottom: 60 + insets.bottom, flex: 1 }}
        nestedScrollEnabled={true}
        showsVerticalScrollIndicator={false}>
        {renderList('Recently Searched', recentlySearched.slice(0, showRecentOnly ? 10 : 5), loading, true, removeRecentlySearched)}
        {!showRecentOnly && renderList('Popular Items', searchAssistData.popularItems.slice(0, 5), loading, false)}
        {!showRecentOnly && (
          <List
            contentContainerStyle={{ width: screenWidth * 2.5 }}
            style={styles.transparentBG}
            headerStyle={styles.listHeading}
            heading={ItemsTypeEnum.FeaturedItems}
            data={featuredItemsProducts.map(
              (x) =>
                ({
                  id: x.key,
                  title: x.masterData.current?.name,
                  imageUrl: x.masterData.current?.masterVariant.images[0]?.url,
                  params: ItemsTypeEnum.FeaturedItems,
                  onPress: () => {
                    navigateToProductDetailsPage(x.key as string);
                  },
                }) as ListData,
            )}
            isLoading={loadingFeaturedItems}
            orientation={'horizontal'}
            renderItem={renderItems}
          />
        )}
        {!showRecentOnly && (
          <List
            contentContainerStyle={styles.horizantalListContainer}
            style={styles.transparentBG}
            headerStyle={styles.listHeading}
            heading={ItemsTypeEnum.RecentlyViewedIems}
            data={recentlyViewedProducts.map(
              (x) =>
                ({
                  id: x.key,
                  title: x.masterData.current?.name,
                  imageUrl: x?.masterData?.current?.masterVariant?.images[0]?.url,
                  params: ItemsTypeEnum.RecentlyViewedIems,
                  onPress: () => {
                    navigateToProductDetailsPage(x.key as string);
                  },
                }) as ListData,
            )}
            isLoading={loading}
            orientation={'horizontal'}
            renderItem={renderItems}
          />
        )}
        {!showRecentOnly && renderList('Departments', departments, loadingDepartments, false)}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    overflow: 'hidden',
  },
  header: {
    marginTop: 22,
    paddingBottom: 10,
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  dropDownShadowView: {
    borderBottomColor: colors.cream,
    marginLeft: -30,
    ...Platform.select({
      ios: {
        width: screenWidth,
        borderBottomWidth: 2,
      },
      android: {
        width: 0,
      },
      default: {
        width: screenWidth,
        borderBottomWidth: 2,
      },
    }),
  },
  dropShadow: {
    width: screenWidth,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.cream,
    borderBottomWidth: 0,
    marginLeft: -20,
    marginTop: 0,
    ...Platform.select({
      ios: {
        height: 2,
      },
      android: {
        height: 0,
      },
      default: {
        height: 2,
      },
    }),
  },
  listHeading: {
    marginTop: 15,
    marginBottom: -20,
  },
  horizantalListContainer: {
    width: screenWidth * 1.41,
  },
  contentBox: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    width: 85,
  },
  squareImage: {
    alignItems: 'center',
    width: 75,
    aspectRatio: aspectRatio.square,
    justifyContent: 'center',
  },
  image: {
    height: '100%',
    width: '100%',
    borderWidth: 1,
    borderColor: '#eaddd3',
  },
  textStyle: {
    color: colors.primary,
    lineHeight: lineHeight(10),
    textAlign: 'center',
    marginTop: 10,
  },
  transparentBG: {
    backgroundColor: Platform.select({
      android: 'transparent',
    }),
  },
});

export default SearchAssist;

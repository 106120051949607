import Text from '@components/Text';
import { AccountStackRoutes, AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { appStyles } from '@styles';
import React, { FC } from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity } from 'react-native';

interface HelpLinkProps {
  style?: StyleProp<TextStyle>;
  onPress?: (event: any) => void;
  testID?: string;
}

const HelpLink: FC<HelpLinkProps> = ({ style, onPress, testID }) => {
  const navigation = useNavigation();
  const openHelp = () => {
    navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Account, params: { screen: AccountStackRoutes.ContactCustomerService, params: {} } });
  };

  return (
    <TouchableOpacity testID={testID} onPress={onPress ?? openHelp}>
      <Text style={[appStyles.bodySmall, styles.helpText, style]} testID="help">
        Help
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  helpText: {
    textDecorationLine: 'underline',
  },
});

export default HelpLink;

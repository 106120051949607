import { Store } from '@fieldera-raleys/client-commercetools/schema';
import storeService from '@services/commerceTools/store';
import Config from 'react-native-config';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import asyncStorage from './asyncStorage';
import { CommerceToolsDefaultsStore } from './storeTypes';

const useCommerceToolsStore = create<CommerceToolsDefaultsStore>()(
  persist(
    (set, get) => ({
      store: undefined,
      defaultStore: undefined,
      initialize: async (): Promise<Store> => {
        let store = get().defaultStore;
        if (store) {
          return store;
        }
        store = await storeService.getStore(Config.CT_DEFAULT_STORE || '01');
        if (store) {
          set((state) => ({ ...state, defaultStore: store }));
          return store;
        }
        throw new Error('useCommerceToolsStore: initialize => failed to load default store');
      },
      loadStore: async (storeNumber: string | undefined): Promise<Store | undefined> => {
        if (!storeNumber) {
          set((state) => ({ ...state, store: undefined }));
          return undefined;
        }
        let store = get().store;
        if (store && store.key === storeNumber) {
          return store;
        }
        store = await storeService.getStore(storeNumber);
        if (store) {
          set((state) => ({ ...state, store }));
          return store;
        }
        throw new Error(`useCommerceToolsStore: loadSelectedStore => invalid storeNumber ${storeNumber}`);
      },
    }),
    {
      name: 'uc-ct-store',
      storage: createJSONStorage<CommerceToolsDefaultsStore>(() => asyncStorage),
    },
  ),
);

export default useCommerceToolsStore;

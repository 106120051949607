import { BottomBar, Button, CloseButton, NavigationHeader, RadioButton, Screen } from '@components';
import Text from '@components/Text';
import colors from '@config/colors';
import { Filter, FilterItem, Indexable } from '@fieldera-raleys/client-common/types';
import { useNavigation } from '@react-navigation/native';
import { useShoppingListsStore } from '@store';
import { appStyles } from '@styles';
import { containerWidth, scale, screenWidth } from '@styles/constants';
import React, { useCallback, useState } from 'react';
import { LayoutChangeEvent, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

interface ListFilter extends Indexable<Filter> {
  filters: Filter;
}

var filters: ListFilter = {
  filters: {
    id: 'filters',
    title: '',
    children: [
      {
        id: 'f1',
        title: 'Category',
        value: 'category',
        selected: true,
        default: true,
      },
      {
        id: 'f2',
        title: 'Product Location',
        value: 'location',
        selected: false,
        default: false,
      },
    ],
  },
};

const ListFilterScreen = () => {
  const { setGroupBy } = useShoppingListsStore();
  const navigation = useNavigation();
  const [allFilters, setAllFilters] = useState<ListFilter>(filters);
  const [scrollViewBottomPadding, setScrollViewBottomPadding] = useState<number>(0);

  const onBottomBarLayout = (le: LayoutChangeEvent) => {
    setScrollViewBottomPadding(le.nativeEvent.layout.height);
  };

  const renderOnlineArray: string[] = ['filters'];

  const toggleFilterValue = (item: FilterItem, filterData: Filter) => {
    if (item.selected) {
      return;
    }
    const updatedSubFilter = filterData.children.map((filterItem) => {
      if (filterItem.id === item.id) {
        filterItem.selected = !filterItem.selected;
      } else {
        filterItem.selected = false;
      }
      return filterItem;
    });

    setAllFilters({
      ...allFilters,
      [filterData.id]: {
        ...filterData,
        children: updatedSubFilter,
      },
    });
  };

  const resetFilters = useCallback(() => {
    for (var key in allFilters) {
      allFilters[key].children.forEach((c) => {
        c.selected = c.default;
      });
    }
    setAllFilters((prev) => ({ ...prev, ...allFilters }));
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFilters]);

  const renderOnlineFilter = (filter: Filter) => {
    return (
      <View style={styles.subFilterContainer} key={filter.id} testID={filter.id}>
        <View style={styles.filterTitle}>
          <Text style={appStyles.bodySmallLeft} testID={filter.title}>
            {filter.title}
          </Text>
        </View>
        <View style={styles.list}>
          {filter.children.map((filterItem) => (
            <View testID="radioBtnView" style={[styles.itemUnderline, filterItem.id === 'f3' ? styles.overlay : null]} key={filterItem.id}>
              <RadioButton
                textStyle={appStyles.bodySmallLeft}
                text={filterItem.title}
                value={filterItem.selected}
                id={filterItem.id}
                toggle={() => toggleFilterValue(filterItem, filter)}
                key={filterItem.id}
                disabled={filterItem.id === 'f3'}
              />
            </View>
          ))}
        </View>
      </View>
    );
  };

  const renderAllFilters = () => {
    return renderOnlineArray.map((item) => {
      return renderOnlineFilter(allFilters[item]);
    });
  };

  const applyFilters = () => {
    const type = allFilters.filters.children.find((child) => child.selected === true) ?? {
      id: 'f1',
      title: 'Category',
      value: 'category',
      selected: true,
    };
    setGroupBy((type.value ?? 'category') === 'category' ? 'category' : 'location');
    navigation.goBack();
  };

  return (
    <>
      <Screen style={styles.screenBackground}>
        <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(100)} style={{ ...styles.container }}>
          <NavigationHeader
            testID="filterHeader"
            style={[styles.chekoutHeaderStyle]}
            subStyle={{ backgroundColor: colors.cream, justifyContent: 'center', flexDirection: 'row' }}
            titleStyle={[appStyles.fontMobileH6, { alignSelf: 'center' }]}
            title={'Sort'}
            icon={<CloseButton />}
          />

          <ScrollView contentContainerStyle={[styles.listContainer, { paddingBottom: scrollViewBottomPadding }]} showsVerticalScrollIndicator={true}>
            {allFilters && renderAllFilters()}
          </ScrollView>
        </Animated.View>
      </Screen>
      <BottomBar onLayout={onBottomBarLayout} useInsets={false} testID="bottomBar">
        <TouchableOpacity activeOpacity={0.7} onPress={() => resetFilters()} testID="clearFilterButton">
          <Text style={[appStyles.smallLink, styles.resetLink]} testID="resetText">
            Reset
          </Text>
        </TouchableOpacity>
        <Button
          testID="applyBtn"
          size={'small'}
          buttonStyle={[appStyles.primaryButtonSmall, styles.button]}
          textStyle={[appStyles.primaryButtonSmallText]}
          onPress={applyFilters}
          title={'Apply'}
        />
      </BottomBar>
    </>
  );
};

const styles = StyleSheet.create({
  screenBackground: {
    backgroundColor: colors.cream,
  },
  container: {
    backgroundColor: colors.cream,
    alignItems: 'center',
    flex: 1,
  },
  listContainer: {
    width: containerWidth,
    paddingHorizontal: 0,
  },
  list: {
    paddingHorizontal: 8,
  },
  filterTitle: {
    width: screenWidth,
    // marginBottom: 12,
    borderColor: colors.navBorder,
  },
  subFilterContainer: {
    marginVertical: 0,
  },
  chekoutHeaderStyle: {
    borderBottomColor: '#B4B4B4',
  },
  locationContainer: {
    width: '100%',
    height: 75,
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  editLink: {
    textDecorationLine: 'underline',
    fontSize: scale(18),
    marginTop: -3,
  },
  resetLink: {
    color: colors.text,
  },
  button: {
    minWidth: '65%',
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconWrapper: {
    position: 'absolute',
    left: 0,
  },
  itemUnderline: {
    borderBottomWidth: 1,
    borderColor: colors.darkCream,
    marginVertical: 8,
  },
  overlay: {
    backgroundColor: colors.cream,
    opacity: 0.5,
  },
});

export default ListFilterScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import { Product, ProductData, ProductVariant } from '@fieldera-raleys/client-commercetools/schema';
import { getDisplayPriceBySellType, getDisplayPriceBySellTypeAndPriceMessage } from '@fieldera-raleys/client-common/utils';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { isNumber } from 'lodash';
import React, { FC } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { getFormattedPrice, getQuantityFromPriceMessage, getStartsAtPrice } from '../../utils/productHelper';
import Button from '../Button';
import Icon from '../Icon';
import ProductImage from '../ProductImage';

const CARD_WIDTH = 168;

export type CollectionInfo = {
  productCollectionId: number;
  itemCount: number;
  collectionName: string;
  promotionalImage: string;
  product?: Product | undefined;
};

interface WeeklyAdsCollectionCardProps {
  collection: CollectionInfo;
  index: number;
}

const WeeklyAdsCollectionCard: FC<WeeklyAdsCollectionCardProps> = ({ collection, index }): JSX.Element => {
  const product = collection.product!;
  const productData: ProductData = collection.product?.masterData.current as ProductData;
  const masterVariant: ProductVariant = productData.masterVariant as ProductVariant;
  const productImage = masterVariant.images.length > 0 ? masterVariant.images[0].url ?? '' : '';
  const navigation = useNavigation();

  const navigateToWeeklAdsCollectionPage = () => {
    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Shop,
      params: {
        screen: ShopStackRoutes.WeeklyAdsCollection,
        params: {
          productCollectionId: collection.productCollectionId,
        },
      },
    });
  };

  const sellType = getProductAttributeValue('unitSellType', masterVariant.attributesRaw);
  // const unitBuyIncrement = getProductAttributeValue('unitBuyIncrement', masterVariant.attributesRaw);
  // const unitBuyMinimum = getProductAttributeValue('unitBuyMinimum', masterVariant.attributesRaw);
  // const unitBuyMaximum = getProductAttributeValue('unitBuyMaximum', masterVariant.attributesRaw);
  const unitAverageBuyWeight = getProductAttributeValue('unitAverageBuyWeight', masterVariant.attributesRaw);

  const unitsPerPackage = getProductAttributeValue('unitsPerPackage', masterVariant.attributesRaw);
  const unitOfMeasure = getProductAttributeValue('unitOfMeasure', masterVariant.attributesRaw);
  const price = masterVariant.price?.value && getFormattedPrice(masterVariant.price?.value);
  const salePrice = masterVariant.price?.discounted?.value && getFormattedPrice(masterVariant.price?.discounted?.value);
  const priceCustomValues = masterVariant.price?.custom?.customFieldsRaw ?? [];
  // let regularPrice = priceCustomValues.length && priceCustomValues[0].value && getFormattedPrice(priceCustomValues[0].value);
  // regularPrice = regularPrice === price ? 0 : +regularPrice;

  let regularPrice =
    priceCustomValues.length &&
    priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value &&
    getFormattedPrice(priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value);
  // regularPrice = regularPrice === price ? 0 : +regularPrice;

  // let pricePerUnit = masterVariant.price?.value && getFormattedPrice(masterVariant.price?.value, unitsPerPackage);

  let onSale =
    (masterVariant.price?.value.centAmount ?? 0) < (priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount ?? 0) ? true : false;

  let priceMessage = '';
  priceMessage =
    (priceCustomValues.length &&
      priceCustomValues.find((pcv) => pcv.name === 'PriceMessage')?.value &&
      priceCustomValues.find((pcv) => pcv.name === 'PriceMessage')?.value) ??
    '';

  priceMessage = isNumber(priceMessage) ? '' : priceMessage;
  const priceMessageQty = getQuantityFromPriceMessage(priceMessage);

  const displayPrice = onSale
    ? priceMessage.length && priceMessage.includes(' for $')
      ? priceMessage
      : priceMessageQty > 1
        ? getDisplayPriceBySellTypeAndPriceMessage(sellType.key, priceMessage, masterVariant.price?.value, unitAverageBuyWeight)
        : getDisplayPriceBySellType(sellType?.key, masterVariant.price?.value, unitAverageBuyWeight)
    : priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value &&
        priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value.centAmount &&
        product.productType?.name !== 'configurable'
      ? getDisplayPriceBySellType(sellType?.key, priceCustomValues.find((pcv) => pcv.name === 'regularPrice')?.value, unitAverageBuyWeight)
      : product.productType?.name === 'configurable'
        ? getStartsAtPrice(product)
        : getDisplayPriceBySellType(sellType?.key, masterVariant.price?.value, unitAverageBuyWeight);

  return (
    <TouchableOpacity onPress={navigateToWeeklAdsCollectionPage} style={[styles.cardContainer]} key={`offer${index}`}>
      <View style={[styles.cardView]}>
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}>
          <View style={[styles.productImageView]}>
            <TouchableOpacity key={'image_key_index_' + index} activeOpacity={1} onPress={navigateToWeeklAdsCollectionPage}>
              <ProductImage imageUrl={productImage} disabled={true} product={product} />
            </TouchableOpacity>
          </View>
          <Button
            onPress={navigateToWeeklAdsCollectionPage}
            type={'primary'}
            rDecore={
              <Icon name={'arrow-right-icon'} size={10} style={{ transform: [{ translateX: 5 }, { translateY: Platform.select({ ios: 7, default: 10 }) }] }} />
            }
            title={`View ${collection.itemCount} Items`}
            size={'small'}
            buttonStyle={[styles.cartAddQty]}
          />
          {(salePrice ?? price) && (
            <View style={[styles.priceView]}>
              <TouchableOpacity onPress={() => navigateToWeeklAdsCollectionPage()}>
                <Text style={[onSale ? styles.itemDiscountText : unitOfMeasure !== 'Each' ? styles.itemPriceText : styles.itemPriceTextUnit]}>
                  {collection.product?.productType?.name !== 'standard' && masterVariant.price ? 'Starts at ' : ''}
                  {(salePrice ?? price) && sellType.key === 'weightByEach' ? (
                    <Text testID="approxText" style={styles.approxText}>
                      {'approx '}
                    </Text>
                  ) : null}
                  {priceMessage && priceMessage.length > 0 && priceMessage.includes(' for $') ? priceMessage : displayPrice}
                  {/* {salePrice ?? price} */}
                  {/* {(salePrice ?? price) && <Text style={appStyles.tinyLight}> ea {'\n'}</Text>} */}
                  {displayPrice && (
                    <Text testID="priceUnit" style={[appStyles.tinyLight]}>
                      {' '}
                      {!priceMessage.includes(' for $') && (sellType.key === 'byWeight' ? `/ ${unitOfMeasure} ` : 'ea ')}
                      {sellType.key === 'weightByEach' ? (
                        <Text style={[styles.itemUnitText, Platform.select({ android: styles.avgPriceText })]}>
                          {'  '}Avg. {unitAverageBuyWeight} {unitOfMeasure}
                        </Text>
                      ) : (
                        <Text testID="regularPrice" style={styles.regularText}>
                          {'   '}
                          {onSale && (
                            <Text testID="discountedPrice" style={[styles.discounted]}>
                              {regularPrice} {sellType.key === 'byWeight' ? `/ ${unitOfMeasure} ` : 'ea'}
                            </Text>
                          )}
                        </Text>
                      )}
                    </Text>
                  )}
                  {salePrice && regularPrice` /${unitsPerPackage}`}
                </Text>
                {/* <Text style={styles.regularText}>{regularPrice !== 0 && <Text style={[styles.discounted]}>{regularPrice} ea</Text>}</Text> */}
              </TouchableOpacity>
            </View>
          )}
          <View style={[styles.weeklyAdCardView, { marginTop: salePrice ?? price ? 20 : 15 }]}>
            <Text numberOfLines={2} style={[styles.itemOfferSubText]}>
              {collection.collectionName}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    // width: CARD_WIDTH,
    // height: 350,
    borderWidth: 0,
    borderColor: colors.red,
    borderRadius: 0,
    backgroundColor: colors.white,
    flexDirection: 'column',
  },
  cardView: {
    //width: '100%',
    width: screenWidth * 0.5,
    // height: '100%',
    justifyContent: 'space-evenly',
    alignSelf: 'center',
    borderWidth: 0,
    borderColor: colors.red,
    borderRadius: 0,
  },
  activityIndicatorView: {
    position: 'absolute',
    top: 12,
    right: -2,
  },
  itemEligibleText: {
    fontFamily: FontFamily.Larsseit,
    color: colors.red,
    fontSize: scale(14),
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  addButton: {
    position: 'absolute',
    top: 8,
    right: 2,
    zIndex: 9,
  },
  itemOfferSubText: {
    justifyContent: screenHeight > 740 ? undefined : 'flex-start',
    color: colors.darkText,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(15),
    lineHeight: lineHeight(17),
    // textAlign: 'center',
    // paddingHorizontal: 10,
    paddingHorizontal: 5,
    marginLeft: 5,
  },
  productImageView: {
    justifyContent: 'space-evenly',
    // alignItems: 'center',
    marginTop: 20,
    marginBottom: 10.5,
    marginHorizontal: 8,
  },
  image: {
    width: 95,
    height: 100,
  },
  weeklyAdCardView: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginTop: Platform.select({ android: -7 }),
    width: CARD_WIDTH,
  },
  eligibleItems: {
    flexDirection: 'row',
    justifyContent: 'center',
    top: 10,
  },
  locationWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(12),
    lineHeight: lineHeight(12),
    bottom: -40,
  },
  locationText: {
    paddingLeft: 5,
    textDecorationLine: 'underline',
  },
  expiryDate: {
    fontSize: scale(12),
    lineHeight: lineHeight(12),
  },
  expView: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 15,
  },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 0,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  container: {
    paddingVertical: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemsCountContainer: {
    borderRadius: Math.round(61 + 61) / 2,
    left: 90,
    bottom: -45,
    width: 61,
    height: 61,
    backgroundColor: colors.doveGray,
    justifyContent: 'center',
  },
  itemsCount: {
    textAlign: 'center',
    color: colors.white,
  },
  itemPriceText: {
    fontSize: scale(17),
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
    marginBottom: Platform.select({
      ios: scale(-30),
      android: scale(-40),
    }),
  },
  itemPriceTextUnit: {
    fontSize: scale(17),
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
  },
  priceView: {
    // justifyContent: 'center',
    justifyContent: screenHeight > 740 ? undefined : 'flex-start',
    // alignItems: 'center',
    paddingHorizontal: 5,
    marginTop: Platform.select({ ios: 15, android: 12 }),
    marginLeft: 5,
    // width: CARD_WIDTH,
  },
  regularText: {
    paddingTop: 10,
  },
  noAvailTextWrapper: {
    width: screenWidth * 0.45,
    paddingLeft: Platform.select({ android: 8 }),
  },
  noAvailText: {
    fontSize: scale(14),
    color: colors.text,
    lineHeight: scale(20),
    fontFamily: FontFamily.LarsseitLight,
    marginLeft: Platform.select({ android: -8 }),
  },
  cartLink: {
    fontSize: scale(14),
    color: colors.red,
    width: '120%',
    top: Platform.select({
      ios: screenHeight > 667 ? -19 : -17,
      android: screenHeight > 667 ? -21 : -20,
    }),
    left: Platform.select({
      ios: screenHeight > 667 ? 60 : 55,
      android: screenHeight > 667 ? 53 : 55,
    }),
    textAlign: 'auto',
    fontFamily: FontFamily.LarsseitLight,
    textDecorationLine: 'underline',
  },
  itemDiscountText: {
    fontSize: scale(17),
    color: 'red',
    fontFamily: 'Larsseit-Bold',
    marginBottom: Platform.select({ android: scale(-30), ios: scale(-15) }),
    lineHeight: lineHeight(17),
  },
  discounted: {
    fontSize: scale(12),
    color: colors.darkText,
    fontFamily: 'Larsseit',
    textDecorationLine: 'line-through',
  },
  cartAddQty: {
    height: 36,
    width: 'auto',
    alignSelf: 'flex-start',
  },
  approxText: {
    ...appStyles.fontMobileBodyXS,
    lineHeight: lineHeight(14),
  },
  itemUnitText: {
    fontSize: scale(12),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.darkText,
    // marginBottom: -5,
  },
  avgPriceText: {
    fontSize: scale(11),
  },
});

export default WeeklyAdsCollectionCard;

import { CacheProvider } from '@fieldera-raleys/client-common/types';
import AsyncStorage from '@react-native-async-storage/async-storage';
import dayjs from 'dayjs';
import logger from './logger';

const CACHE_PREFIX = 'cache-';

type CacheItem = {
  value: any;
  timestamp: number;
  expiresInMinutes: number;
};

const NoCache: CacheProvider = (function () {
  return {
    setItem: async (_key: string, _value: any, _expiresInMinutes = 5) => {
      await Promise.resolve();
    },

    getItem: async <T>(_key: string, callback?: () => Promise<T>, _expiresInMinutes: number = 5, _forceRelaod: boolean = false): Promise<T | undefined> => {
      if (typeof callback === 'function') {
        return await callback();
      }
    },

    removeItem: async (_key: string): Promise<void> => {
      await Promise.resolve();
    },

    flush: async () => {
      await Promise.resolve();
    },

    flushExpired: async () => {
      await Promise.resolve();
    },

    hashGet: async <T>(
      _hashKey: string,
      _keys: string[],
      callback?: (() => Promise<Record<string, T>>) | undefined,
      _expiresInMinutes?: number | undefined,
    ) => {
      if (typeof callback === 'function') {
        return await callback();
      }
    },

    hashRemove: async (_hashKey: string, _keys: string[]) => {
      await Promise.resolve();
    },

    hashSet: async <T>(_hashKey: string, _values: Record<string, T>, _expiresInMinutes?: number | undefined) => {
      await Promise.resolve();
    },

    keys: async (_pattern: string) => {
      return Promise.resolve([]);
    },
  };
})();

const Cache: CacheProvider = (function () {
  const _isExpired = (item: CacheItem) => {
    if (item.expiresInMinutes === -1) {
      return false;
    }

    const now = dayjs();
    const storedTime = dayjs(item.timestamp);
    return now.diff(storedTime, 'minute') > item.expiresInMinutes;
  };

  const _getItem = async (key: string) => {
    const value = await AsyncStorage.getItem(`${CACHE_PREFIX}${key}`);
    const item = value?.length ? (JSON.parse(value) as CacheItem) : undefined;
    let expired = false;
    if (!item || (expired = _isExpired(item) === true)) {
      if (expired) {
        await _removeItem(key);
      }
      return undefined;
    }
    return item;
  };

  const _setItem = async <T>(key: string, value: T, expiresInMinutes = 5, reset: boolean = false) => {
    if (!(expiresInMinutes === -1 || expiresInMinutes > 0)) {
      throw `Key: ${key} - invalid value for expiresInMinutes`;
    }
    let current = !reset ? await _getItem(key) : undefined;
    if (current) {
      current.value = value;
      return AsyncStorage.setItem(`${CACHE_PREFIX}${key}`, JSON.stringify(current));
    } else {
      const item = {
        value,
        timestamp: Date.now(),
        expiresInMinutes,
      } as CacheItem;
      return AsyncStorage.setItem(`${CACHE_PREFIX}${key}`, JSON.stringify(item));
    }
  };

  const _removeItem = async (key: string): Promise<void> => {
    return AsyncStorage.removeItem(`${CACHE_PREFIX}${key}`);
  };

  const _multiGet = async (hashkey: string, keys: string[]) => {
    const values = await AsyncStorage.multiGet(keys.map((k) => `${CACHE_PREFIX}${hashkey}:${k}`));
    const cachedData = values?.length
      ? values.reduce(
          (data, [key, value]) => {
            let expired = false;
            let item = (value ? JSON.parse(value) : undefined) as CacheItem;
            if (!item || (expired = _isExpired(item) === true)) {
              if (expired) {
                _removeItem(key).catch(() => {});
              }
              return data;
            }
            key = key.slice(key.lastIndexOf(':') + 1);
            data[key] = item as CacheItem;
            return data;
          },
          {} as Record<string, CacheItem>,
        )
      : undefined;
    if (!cachedData) {
      return undefined;
    }
    return cachedData;
  };

  const _multiSet = async <T>(hashKey: string, data: Record<string, T>, expiresInMinutes = 5) => {
    if (!(expiresInMinutes === -1 || expiresInMinutes > 0)) {
      throw 'invalid value for expiresInMinutes';
    }

    let update: [string, string][] = Object.entries(data).map(([key, item]) => {
      let cacheItem = { value: item, expiresInMinutes, timestamp: Date.now() } as CacheItem;
      return [`${CACHE_PREFIX}${hashKey}:${key}`, JSON.stringify(cacheItem)];
    });
    if (update && update.length) {
      return AsyncStorage.multiSet(update);
    }
  };

  const _multiRemove = async (hashkey: string, keys: string[]) => {
    return AsyncStorage.multiRemove(keys.map((k) => `${CACHE_PREFIX}${hashkey}:${k}`));
  };

  return {
    setItem: async (key: string, value: any, expiresInMinutes = 5) => {
      try {
        await _setItem(key, value, expiresInMinutes);
      } catch (ex) {
        logger.error(ex);
      }
    },

    getItem: async <T>(key: string, callback?: () => Promise<T>, expiresInMinutes: number = 5, forceRelaod: boolean = false): Promise<T | undefined> => {
      try {
        if (forceRelaod && typeof callback === 'function') {
          const data = await callback();
          await _setItem(key, data, expiresInMinutes, true);
          return data;
        } else {
          const item = await _getItem(key);
          if (item) {
            return item.value;
          } else if (typeof callback === 'function') {
            const data = await callback();
            _setItem(key, data, expiresInMinutes);
            return data;
          }
          return undefined;
        }
      } catch (ex) {
        logger.error(ex);
      }
    },

    removeItem: async (key: string): Promise<void> => {
      try {
        return _removeItem(key);
      } catch (ex) {
        logger.error(ex);
      }
    },

    flush: async () => {
      const keys = (await AsyncStorage.getAllKeys()).filter((key) => key.startsWith(CACHE_PREFIX));
      var cacheItems = await AsyncStorage.multiGet(keys);
      cacheItems.forEach(async (item) => {
        try {
          const key = item[0];
          await AsyncStorage.removeItem(key);
        } catch (ex) {
          logger.error(ex);
        }
      });
    },

    flushExpired: async () => {
      const keys = (await AsyncStorage.getAllKeys()).filter((key) => key.startsWith(CACHE_PREFIX));
      try {
        var cacheItems = await AsyncStorage.multiGet(keys);
        var expired = cacheItems.reduce((collector, [key, value]) => {
          if (value == null || _isExpired(JSON.parse(value))) {
            collector.push(key);
          }
          return collector;
        }, [] as string[]);
        await AsyncStorage.multiRemove(expired);
      } catch (ex) {
        logger.error(ex);
      }
    },

    hashGet: async <T>(hashKey: string, keys: string[], callback?: (() => Promise<Record<string, T>>) | undefined, expiresInMinutes?: number | undefined) => {
      try {
        const cachedData = await _multiGet(hashKey, keys);
        if (cachedData && Object.keys(cachedData).length) {
          return Object.entries(cachedData).reduce(
            (data, [key, item]) => {
              data[key] = item.value;
              return data;
            },
            {} as Record<string, T>,
          );
        } else if (typeof callback === 'function') {
          let values = await callback();
          await _multiSet(hashKey, values, expiresInMinutes);
          return values;
        }
        return undefined;
      } catch (ex) {
        logger.error(ex, { method: 'hashGet' });
      }
    },

    hashRemove: async (hashKey: string, keys: string[]) => {
      try {
        return _multiRemove(hashKey, keys);
      } catch (ex) {
        logger.error(ex, { method: 'hashRemove' });
      }
    },

    hashSet: async <T>(hashKey: string, values: Record<string, T>, expiresInMinutes?: number | undefined) => {
      try {
        await _multiSet(hashKey, values, expiresInMinutes);
      } catch (ex) {
        logger.error(ex, { method: 'hashSet' });
      }
    },

    keys: async (pattern: string) => {
      const regex = new RegExp(`^${CACHE_PREFIX}${pattern}`, 'g');
      return AsyncStorage.getAllKeys().then((keys) => {
        return keys
          .filter((key) => key.startsWith(CACHE_PREFIX))
          .filter((key) => {
            return key.match(regex);
          })
          .map((key) => key.substring(CACHE_PREFIX.length));
      });
    },
  };
})();

export default Cache;
export { NoCache };
export const UUID_CACHE_KEY = 'uuid';
export const CATEGORIES_CACHE_KEY = 'categories';
export const ALL_FILTERS_CACHE_KEY = 'all_filters';
export const SELECTED_FILTER_CACHE_KEY = 'selected_filters';
export const POINTS_BALANCE_CACHE_KEY = 'points_balance';

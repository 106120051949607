import colors from '@config/colors';
import { appStyles } from '@styles';
import React from 'react';
import { OpaqueColorValue, StyleProp, View, ViewStyle } from 'react-native';

export type IconName =
  | 'account-icon'
  | 'action-up'
  | 'arrow-circle-down'
  | 'arrow-circle-up'
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'belair'
  | 'office-icon'
  | 'cart-icon'
  | 'categories-icon'
  | 'caution-icon'
  | 'checked-circle'
  | 'circle-down-arrow-shadow'
  | 'circle-check'
  | 'clear-icon'
  | 'clock-icon'
  | 'circle-plus'
  | 'deal-banner-prodList'
  | 'deals-icon'
  | 'delete-icon'
  | 'ellipsis-icon'
  | 'eye-hide'
  | 'eye-open'
  | 'empty-cart'
  | 'heart-icon'
  | 'home-icon'
  | 'house-icon'
  | 'info-icon'
  | 'linkout-icon'
  | 'list-icon'
  | 'location-arrow'
  | 'location-icon'
  | 'location-pin'
  | 'map-hallow'
  | 'mic-icon'
  | 'nobhillfoods'
  | 'pickup-icon'
  | 'pin-questionmark-solid'
  | 'question-circle'
  | 'raleys'
  | 'raleysonemarket'
  | 'scan-icon'
  | 'search-icon'
  | 'search-large'
  | 'shop-icon'
  | 'something-extra'
  | 'sort-icon'
  | 'star-icon'
  | 'triangle-up'
  | 'triangle-down'
  | 'star-outline-large'
  | 'unchecked-circle'
  | 'wallet-icon'
  | 'x-close'
  | 'search-missing-icon'
  | 'favorite-large'
  | 'rewards-icon'
  | 'confetti-group-1'
  | 'confetti-group-2'
  | 'gas-pump'
  | 'health-icon'
  | 'list-page'
  | 'my-store-icon'
  | 'offers-icon'
  | 'se-barcode-icon'
  | 'se-dollars-wallet'
  | 'rewards-icon-red'
  | 'trash'
  | 'flag-red-icon'
  // | 'manage-list-icon'
  // | 'se-icon-circle'
  | 'birthday-hat'
  | 'delivery-icon'
  | 'map-list-icon'
  | 'bookmark-icon'
  | 'download-file-icon'
  | 'order-location'
  | 'cancel-modify-icon'
  | 'se-icon-tiny-red'
  | 'contact-icon'
  | 'chat'
  | 'phone'
  | 'email'
  | 'warning-circle'
  | 'android-biometric'
  | 'face-biometric'
  | 'finger-biometric'
  | 'android-face-biometric'
  | 'earn'
  | 'featured-collections'
  | 'gather-rooms'
  | 'generic-grocery'
  | 'previously-purchased'
  | 'redeem'
  | 'shelf-guide'
  | 'weekly-ad'
  | 'categories'
  | 'se-offers'
  | 'se-badge'
  | 'arrow-right-icon'
  | 'amex'
  | 'discover'
  | 'mastercard'
  | 'visa'
  | 'somethingextra-clipped'
  | 'somethingextra-unclipped'
  | 'weeklyexclusive-unclipped'
  | 'weeklyexclusive-clipped'
  | 'digitalcoupon-clipped'
  | 'digitalcoupon-unclipped'
  | 'deal-clipped'
  | 'deal-unclipped'
  | 'mfg-clipped'
  | 'mfg-unclipped'
  | 'scissor'
  | 'circle-scissor'
  | 'white-scissor'
  | 'se-dollars-in-wallet'
  | 'quarter-circle';

const IconMap: { [key in IconName]: any } = {
  'account-icon': require('../assets/icons/account-icon.svg').default,
  'action-up': require('../assets/icons/action-up.svg').default,
  'arrow-circle-down': require('../assets/icons/arrow-circle-down.svg').default,
  'arrow-circle-up': require('../assets/icons/arrow-circle-up.svg').default,
  'arrow-up': require('../assets/icons/arrow-up.svg').default,
  'arrow-down': require('../assets/icons/arrow-down.svg').default,
  'arrow-left': require('../assets/icons/arrow-left.svg').default,
  'arrow-right': require('../assets/icons/arrow-right.svg').default,
  'belair': require('../assets/icons/bel-air-logo.svg').default,
  'office-icon': require('../assets/icons/office-icon.svg').default,
  'cart-icon': require('../assets/icons/cart-icon.svg').default,
  'caution-icon': require('../assets/icons/caution-icon.svg').default,
  'checked-circle': require('../assets/icons/checked-circle.svg').default,
  'circle-check': require('../assets/icons/circle-check.svg').default,
  'clear-icon': require('../assets/icons/clear-icon.svg').default,
  'clock-icon': require('../assets/icons/clock-icon.svg').default,
  'circle-down-arrow-shadow': require('../assets/icons/circle-down-arrow-shadow.svg').default,
  'circle-plus': require('../assets/icons/circle-plus.svg').default,
  'deals-icon': require('../assets/icons/deals-icon.svg').default,
  'delete-icon': require('../assets/icons/delete-icon.svg').default,
  'ellipsis-icon': require('../assets/icons/ellipsis-icon.svg').default,
  'eye-hide': require('../assets/icons/eye-closed.svg').default,
  'eye-open': require('../assets/icons/eye-open.svg').default,
  'empty-cart': require('../assets/icons/empty-cart.svg').default,
  'heart-icon': require('../assets/icons/heart-icon.svg').default,
  'home-icon': require('../assets/icons/home-icon.svg').default,
  'house-icon': require('../assets/icons/house-icon.svg').default,
  'info-icon': require('../assets/icons/info-icon.svg').default,
  'list-icon': require('../assets/icons/list-icon.svg').default,
  'location-arrow': require('../assets/icons/location-arrow.svg').default,
  'location-icon': require('../assets/icons/location-icon.svg').default,
  'map-hallow': require('../assets/icons/map-hallow.svg').default,
  'mic-icon': require('../assets/icons/mic-icon.svg').default,
  'nobhillfoods': require('../assets/icons/nob-hill.svg').default,
  'pickup-icon': require('../assets/icons/pickup-icon.svg').default,
  'pin-questionmark-solid': require('../assets/icons/pin-questionmark-solid.svg').default,
  'location-pin': require('../assets/icons/location-pin.svg').default,
  'question-circle': require('../assets/icons/question-circle.svg').default,
  'raleys': require('../assets/icons/raleys-logo.svg').default,
  'raleysonemarket': require('../assets/icons/raleys-one-logo.svg').default,
  'scan-icon': require('../assets/icons/scan-icon.svg').default,
  'search-icon': require('../assets/icons/search-icon.svg').default,
  'search-large': require('../assets/icons/search-large.svg').default,
  'shop-icon': require('../assets/icons/shop-icon.svg').default,
  'linkout-icon': require('../assets/icons/linkout-icon.svg').default,
  'something-extra': require('../assets/icons/something-extra.svg').default,
  'sort-icon': require('../assets/icons/sort-icon.svg').default,
  'star-icon': require('../assets/icons/star-icon.svg').default,
  'star-outline-large': require('../assets/icons/star-outline-large.svg').default,
  'triangle-up': require('../assets/icons/triangle-up.svg').default,
  'triangle-down': require('../assets/icons/triangle-down.svg').default,
  'unchecked-circle': require('../assets/icons/unchecked-circle.svg').default,
  'wallet-icon': require('../assets/icons/wallet-icon.svg').default,
  'x-close': require('../assets/icons/x-close.svg').default,
  'deal-banner-prodList': require('../assets/icons/deal-banner-prodList.svg').default,
  'categories-icon': require('../assets/icons/categories-icon.svg').default,
  'search-missing-icon': require('../assets/icons/search-missing-icon.svg').default,
  'favorite-large': require('../assets/icons/favorite-large.svg').default,
  'rewards-icon': require('../assets/icons/rewards-icon.svg').default,
  'confetti-group-1': require('../assets/icons/confetti-group-1.svg').default,
  'confetti-group-2': require('../assets/icons/confetti-group-2.svg').default,
  'gas-pump': require('../assets/icons/gas-pump.svg').default,
  'health-icon': require('../assets/icons/health-icon.svg').default,
  'list-page': require('../assets/icons/list-page.svg').default,
  'my-store-icon': require('../assets/icons/my-store-icon.svg').default,
  'offers-icon': require('../assets/icons/offers-icon.svg').default,
  'se-barcode-icon': require('../assets/icons/se-barcode-icon.svg').default,
  'se-dollars-wallet': require('../assets/icons/se-dollars-wallet.svg').default,
  'trash': require('../assets/icons/trash.svg').default,
  'rewards-icon-red': require('../assets/icons/rewards-icon-red.svg').default,
  'birthday-hat': require('../assets/icons/birthday-hat.svg').default,
  'flag-red-icon': require('../assets/icons/flag-red-icon.svg').default,
  // 'manage-list-icon': require('../assets/icons/manage-list-icon.png').default,
  // 'se-icon-circle': require('../assets/icons/se-icon-circle.png').default,
  'delivery-icon': require('../assets/icons/delivery-icon.svg').default,
  'map-list-icon': require('../assets/icons/map-list-icon.svg').default,
  'bookmark-icon': require('../assets/icons/bookmark-icon.svg').default,
  'download-file-icon': require('../assets/icons/download-file-icon.svg').default,
  'order-location': require('../assets/icons/order-location.svg').default,
  'cancel-modify-icon': require('../assets/icons/cancel-modify-icon.svg').default,
  'se-icon-tiny-red': require('../assets/icons/se-icon-tiny-red.svg').default,
  'contact-icon': require('../assets/icons/contact-icon.svg').default,
  'chat': require('../assets/icons/chat.svg').default,
  'phone': require('../assets/icons/phone.svg').default,
  'email': require('../assets/icons/email.svg').default,
  'warning-circle': require('../assets/icons/warning-circle.svg').default,
  'android-biometric': require('../assets/icons/android-biometric.svg').default,
  'face-biometric': require('../assets/icons/face-biometric.svg').default,
  'finger-biometric': require('../assets/icons/finger-biometric.svg').default,
  'android-face-biometric': require('../assets/icons/android-face-biometric.svg').default,
  'earn': require('../assets/icons/earn.svg').default,
  'featured-collections': require('../assets/icons/featured-collections.svg').default,
  'gather-rooms': require('../assets/icons/gather-rooms.svg').default,
  'generic-grocery': require('../assets/icons/generic-grocery.svg').default,
  'previously-purchased': require('../assets/icons/previously-purchased.svg').default,
  'redeem': require('../assets/icons/redeem.svg').default,
  'shelf-guide': require('../assets/icons/shelf-guide.svg').default,
  'weekly-ad': require('../assets/icons/weekly-ad.svg').default,
  'categories': require('../assets/icons/categories.svg').default,
  'se-offers': require('../assets/icons/se-offers.svg').default,
  'se-badge': require('../assets/icons/se-badge.svg').default,
  'arrow-right-icon': require('../assets/icons/arrow-right-icon.svg').default,

  'amex': require('../assets/icons/amex.svg').default,
  'discover': require('../assets/icons/discover.svg').default,
  'mastercard': require('../assets/icons/mastercard.svg').default,
  'visa': require('../assets/icons/visa.svg').default,
  'somethingextra-clipped': require('../assets/icons/somethingextra-clipped.svg').default,
  'somethingextra-unclipped': require('../assets/icons/somethingextra-unclipped.svg').default,
  'weeklyexclusive-unclipped': require('../assets/icons/weeklyexclusive-unclipped.svg').default,
  'weeklyexclusive-clipped': require('../assets/icons/weeklyexclusive-clipped.svg').default,
  'digitalcoupon-clipped': require('../assets/icons/digitalcoupon-clipped.svg').default,
  'digitalcoupon-unclipped': require('../assets/icons/digitalcoupon-unclipped.svg').default,
  'deal-clipped': require('../assets/icons/deal-clipped.svg').default,
  'deal-unclipped': require('../assets/icons/deal-unclipped.svg').default,
  'mfg-clipped': require('../assets/icons/mfg-clipped.svg').default,
  'mfg-unclipped': require('../assets/icons/mfg-unclipped.svg').default,
  'scissor': require('../assets/icons/scissor.svg').default,
  'circle-scissor': require('../assets/icons/circle-scissor.svg').default,
  'white-scissor': require('../assets/icons/white-scissor.svg').default,
  'quarter-circle': require('../assets/icons/quarter-circle.svg').default,
  'se-dollars-in-wallet': require('../assets/icons/se-dollars-in-wallet.svg').default,
};
export type IconProps = {
  name: IconName;
  style?: StyleProp<ViewStyle>;
  stroke?: string | OpaqueColorValue;
  fill?: string | OpaqueColorValue;
  fillSecondary?: string | OpaqueColorValue;
  fillTrinary?: string | OpaqueColorValue;
  strokeSecondary?: string | OpaqueColorValue;
  backgroundColor?: string | OpaqueColorValue;
  size?: number | undefined;
  testID?: string | undefined;
};
const Icon = (props: IconProps): JSX.Element => {
  const CustomIcon = IconMap[props.name];
  return CustomIcon ? (
    <View style={[appStyles.icon, props.style ?? { width: props.size || 25, height: props.size || 25 }]} testID={props.testID}>
      <CustomIcon
        size={props.size || 25}
        fill={props.fill || 'none'}
        fillSecondary={props.fillSecondary || colors.white}
        stroke={props.stroke || '#777'}
        strokeSecondary={props.strokeSecondary || '#999'}
      />
    </View>
  ) : (
    <></>
  );
};

export default Icon;

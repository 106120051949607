import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon, { IconName } from '../Icon';

export type Link = {
  iconName?: IconName;
  title: string;
  onPress?: (item: Link) => void;
  showBadge?: boolean;
};

type QuickAccessLinksProps = {
  links: Link[];
};

const QuickAccessLinks: FC<QuickAccessLinksProps> = ({ links }) => {
  const renderItem = ({ item, index }: { item: Link; index: number }) => {
    return (
      <TouchableOpacity
        onPress={() => item.onPress && item.onPress(item)}
        style={[styles.itemContainer, { width: screenWidth / links.length }]}
        testID={`qaLink-${item.title}-${index}-Button`}>
        {item.showBadge ? <View style={styles.badgeStyle} /> : null}
        <Icon stroke={'none'} style={[styles.iconStyle]} name={item.iconName || 'info-icon'} />
        <Text style={styles.itemTitle} testID={`qaLinkTitle-${item.title}-${index}`}>
          {item.title}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <View>
      <FlatList
        keyExtractor={(_, i) => i.toString()}
        contentContainerStyle={styles.contentContainer}
        data={links || []}
        renderItem={renderItem}
        horizontal
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    alignItems: 'center',
  },
  contentContainer: {
    paddingTop: 4,
  },
  iconStyle: {
    width: 50,
    height: 50,
  },
  itemTitle: {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    textAlign: 'center',
    lineHeight: lineHeight(15),
    fontSize: scale(15),
    width: '100%',
    marginVertical: 5,
  },
  badgeStyle: {
    backgroundColor: colors.red,
    position: 'absolute',
    zIndex: 99,
    right: 18,
    top: -7,
    width: 9,
    height: 9,
    borderRadius: 4.5,
  },
});

export default QuickAccessLinks;

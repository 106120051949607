import { createStackNavigator } from '@react-navigation/stack';
import { OrderDetailScreen, OrderHistoryScreen, PurchaseFilterScreen } from '@screens/account';
import ReorderScreen from '@screens/account/orderHistory/ReorderScreen';
import React from 'react';
import { cardStyleInterpolator } from './helpers';
import { OrderHistoryStackParamList, OrderHistoryStackRoutes } from './routes';

const OrderHistoryStack = createStackNavigator<OrderHistoryStackParamList>();

const OrderHistoryStackNavigator = () => {
  return (
    <OrderHistoryStack.Navigator
      initialRouteName={OrderHistoryStackRoutes.RecentOrders}
      screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
      <OrderHistoryStack.Screen name={OrderHistoryStackRoutes.RecentOrders} component={OrderHistoryScreen} />
      <OrderHistoryStack.Screen name={OrderHistoryStackRoutes.OrderDetails} component={OrderDetailScreen} />
      <OrderHistoryStack.Screen
        name={OrderHistoryStackRoutes.PurchaseFilter}
        component={PurchaseFilterScreen}
        options={{ cardStyleInterpolator: cardStyleInterpolator }}
      />
      <OrderHistoryStack.Screen name={OrderHistoryStackRoutes.Reorder} component={ReorderScreen} options={{ presentation: 'modal' }} />
    </OrderHistoryStack.Navigator>
  );
};

export default OrderHistoryStackNavigator;

import { HelpLink, Icon, KeyboardAvoidingScreen, LinkButton, LoadingScreen, Modal, NavigationHeader, Screen, WebBrowserLink } from '@components';
import HeaderTitle from '@components/HeaderTitle';
import Text from '@components/Text';
import { ErrorMessage, Form, FormField, SubmitButton } from '@components/forms';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AuthToken, CustomerAlternateContact, UserProfile } from '@fieldera-raleys/client-common';
import { validateEmail } from '@fieldera-raleys/client-common/utils';
import { useRefetchOnFocus } from '@hooks';
import routes, { AccountStackRoutes, AccountStackScreenProps, AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { userService } from '@services/brandywine';
import { useAuthStore, useUserProfileStore } from '@store';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import logger from '@utils/logger';
import { queryClient } from '@utils/reactQuery';
import React, { useMemo, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { useCustomModalContext } from '../../contexts';
import helpers, { formatPhone } from '../../utils/helpers';

const HeaderComponent = () => {
  let navigation = useNavigation();
  const { t } = useTranslation('editProfileScreen');

  const navigateToChangePassword = () => {
    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Account,
      params: {
        screen: AccountStackRoutes.ChangePassword,
        params: {},
      },
    });
  };

  const navigateToAddressBook = () => {
    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Account,
      params: {
        screen: AccountStackRoutes.AddressBook,
        params: {},
      },
    });
  };

  return (
    <View>
      <View style={styles.header}>
        <View style={styles.titleWrapper}>
          <LinkButton onPress={navigateToAddressBook} style={appStyles.bodySmallLightLinkCenter} testID="addressBookLinkButton">
            {t('addressBookLinkButton')}
          </LinkButton>
        </View>
        <View style={styles.titleWrapper}>
          <LinkButton onPress={navigateToChangePassword} style={appStyles.bodySmallLightLinkCenter} testID="changePasswordLinkButton">
            {t('changePasswordLinkButton')}
          </LinkButton>
        </View>
      </View>
    </View>
  );
};

const AlternateContactComponent = ({ navigation }: any) => {
  const { userProfile } = useUserProfileStore();
  const { data, isLoading, refetch, isRefetching } = useQuery(['alternateContacts'], async () => await userService.getAlternateContacts());
  const handleError = useErrorHandler();
  const [showRemoveAlternateContactModal, setShowRemoveAlternateContactModal] = useState(false);

  const alternateContactRef = useRef<CustomerAlternateContact | undefined>(undefined);

  const { t } = useTranslation('editProfileScreen');

  useRefetchOnFocus(refetch);

  const canAddAlternateContact = (): boolean => {
    return (data?.length ?? 0) >= 6;
  };
  const addAlternateContact = () => {
    navigation.navigate(AccountStackRoutes.AddBirthdayClubMember);
  };

  const confirmDeleteAlterContact = (member: CustomerAlternateContact) => {
    alternateContactRef.current = member;
    setShowRemoveAlternateContactModal(true);
  };

  const deleteAlternateContact = () => {
    setShowRemoveAlternateContactModal(false);
    queryClient.executeMutation({
      mutationKey: ['alternateContacts'],
      mutationFn: async () =>
        userProfile &&
        alternateContactRef.current &&
        (await userService.deleteAlternateContact(alternateContactRef.current.alternateContactId, userProfile.sepiNumber)),
      onError: (ex: any) => {
        if ((ex as Error).message.indexOf('already exists') >= 0) {
          handleError(ex);
        }
      },
      onSuccess: async () => {
        await refetch();
      },
      onSettled: () => {
        alternateContactRef.current = undefined;
      },
    });
  };

  const renderListItem = (item: CustomerAlternateContact, key: number) => {
    const formattedBirthDay = item.birthMonth + '/' + item.birthDay;
    const name = item.firstName + ' ' + item.lastName;

    return (
      <View key={`${formattedBirthDay}-${key}`} style={[styles.birthdayClubMember]}>
        <Text style={[appStyles.fontMobileSmallLeftRegular, { flex: 2 }]} numberOfLines={1} testID="name">
          {name}
        </Text>
        <Text style={[appStyles.fontMobileSmallLeftRegular, { flex: 1 }]} numberOfLines={1} testID="birthDay">
          {formattedBirthDay}
        </Text>
        <TouchableOpacity onPress={() => confirmDeleteAlterContact(item)} style={{ width: 15, height: 15 }} testID="clearIconClick">
          <Icon fill={colors.dustyGray} stroke="none" name="clear-icon" size={15} style={appStyles.icon} testID="clearIcon" />
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View>
      <View style={utilityStyles.pt5} />
      <View style={styles.birthdayClubWrapper}>
        <View style={[styles.form, { paddingHorizontal: 30 }]}>
          <View style={styles.birthdayClubTitle}>
            <Text style={[appStyles.fontMobileBodySmall]} testID="birthdayClubTitle">
              {t('birthdayClubTitle')}
            </Text>
            <Text style={[appStyles.fontMobileTinyLight]}>{t('optional')}</Text>
          </View>
          <View>
            <Text style={[appStyles.fontMobileBodySmallLeftRegular]} testID="specialSurpriseMessage" allowFontScaling={true} maxFontSizeMultiplier={4}>
              {t('specialSurpriseMessage')}
            </Text>
            <Text style={[appStyles.fontMobileBodySmallLeftRegular]} testID="birthdayClubMemberLimitMessage" allowFontScaling={true} maxFontSizeMultiplier={4}>
              {t('birthdayClubMemberLimitMessage')}
            </Text>
          </View>
          {isLoading || isRefetching ? (
            <LoadingScreen />
          ) : (
            <>
              <View>
                <View style={utilityStyles.pt5} />
                {data?.map((item, index) => {
                  return renderListItem(item, index);
                })}
              </View>
              <TouchableOpacity
                testID="addAlternateContactClick"
                style={[styles.addBDClubMember, { opacity: canAddAlternateContact() ? 0.4 : 1 }]}
                onPress={addAlternateContact}
                disabled={canAddAlternateContact()}>
                <Icon name={'circle-plus'} stroke={colors.red} strokeSecondary={colors.darkCream} size={32} />
                <Text style={styles.addBDClubMemberText} testID="addBDClubMemberButtonTitle">
                  {t('addBDClubMemberButtonTitle')}
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>

      {showRemoveAlternateContactModal && (
        <Modal
          visible={showRemoveAlternateContactModal}
          title={t('removeConfirmationMessage')}
          location="top"
          cancelButtonOnPress={() => setShowRemoveAlternateContactModal(false)}
          okButtonOnPress={deleteAlternateContact}
          cancelButtonText={t('back')}
          okButtonText={t('yes')}>
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="removeConfirmationInfo">
            {t('removeConfirmationInfo')}
          </Text>
        </Modal>
      )}
    </View>
  );
};

type EditProfileScreenProps = AccountStackScreenProps<AccountStackRoutes.EditProfile>;

const EditProfileScreen = ({ navigation }: EditProfileScreenProps) => {
  const [loadingButton, setLoadingButton] = useState(false);
  // TODO To be implemented later
  // const [isMobileNumber, setIsMobileNumber] = useState(true);
  const { showAlertModal } = useCustomModalContext();
  const { userProfile, setUserProfile: setProfile } = useUserProfileStore();
  const { setAuthToken } = useAuthStore();

  const firstNameRef = React.createRef<TextInput>();
  const lastNameRef = React.createRef<TextInput>();
  const emailRef = React.createRef<TextInput>();
  const phoneRef = React.createRef<TextInput>();
  const loyaltyIDRef = React.createRef<TextInput>();
  const errorMessageRef = React.useRef<string>();

  const { t } = useTranslation('editProfileScreen');

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required().label('First Name'),
    lastName: Yup.string().trim().required().label('Last Name'),
    email: Yup.string()
      .required()
      .email(t('emailValidationMessage'))
      .test('email', function (value) {
        if (value) {
          return validateEmail(value);
        }
        return false;
      })
      .label('Email Address'),
    phone: Yup.string().required().matches(helpers.PHONE_NUMBER_REGEX, t('phoneValidationMessage')).label('Phone'),
    loyaltyId: Yup.number()
      .required()
      .typeError(t('loyaltyTypeValidationMessage'))
      .integer()
      .positive()
      .test('lenShort', t('shortLengthValidationMessage'), (val) => (val ? val.toString().length >= 10 : false))
      .test('lenLong', t('longLengthValidationMessage'), (val) => (val ? val?.toString().length <= 13 : false))
      .label('Something Extra Loyalty Number'),
  });

  const navigateToHelpCenter = useMemo(
    () => (
      <View style={styles.flexrow}>
        <HelpLink testID={'editProfileHelp'} style={{ marginTop: 2 }} />
      </View>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigation],
  );

  const showSENumberInfo = () => {
    showAlertModal({
      title: t('seNumberModalTitle'),
      titleStyle: { width: screenWidth },
      message: t('seNumberModalMessage'),
      closeButtonText: t('done'),
    });
  };

  const onSubmit = async ({ firstName, lastName, email, phone, loyaltyId }: any) => {
    // Check for change in any one form field value to updated
    if (
      !userProfile ||
      (firstName === userProfile.firstName &&
        lastName === userProfile.lastName &&
        email === userProfile.email &&
        phone === userProfile.phone &&
        loyaltyId === userProfile.loyaltyId)
    ) {
      return;
    }

    errorMessageRef.current = '';
    setLoadingButton(true);

    try {
      const response = await userService.updateProfile({ ...userProfile, firstName, lastName, email, phone, loyaltyId });
      if (response) {
        const updatedAuthToken: AuthToken = { email, jwtToken: response.UserToken, refreshToken: response.RefreshToken, passwordExpired: false };
        setAuthToken(email, updatedAuthToken);

        const updatedProfile: UserProfile = { ...userProfile, firstName, lastName, email, phone, loyaltyId };
        setProfile(updatedProfile);
        showAlertModal({
          closeButtonText: t('okText'),
          title: t('success'),
          titleStyle: [styles.modalHeaderText],
          message: t('successMessage'),
        });

        Keyboard.dismiss();
      }
    } catch (error) {
      logger.error(error);
      errorMessageRef.current = t('genericErrorMessage');
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Screen style={utilityStyles.pb0}>
      <NavigationHeader subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]} next={navigateToHelpCenter} backIconstyle={{ marginLeft: scale(-6) }}>
        <HeaderTitle testID="editProfileTitle">{t('editProfile')}</HeaderTitle>
      </NavigationHeader>
      <KeyboardAvoidingScreen>
        <Form
          initialValues={{
            firstName: userProfile?.firstName,
            lastName: userProfile?.lastName,
            email: userProfile?.email,
            phone: formatPhone(userProfile?.phone),
            loyaltyId: userProfile?.loyaltyId ?? 0,
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          <ScrollView>
            <View style={styles.form}>
              <HeaderComponent />
              <ErrorMessage error={errorMessageRef.current} visible={!!errorMessageRef.current} style={styles.errorText} testID="editProfileError" />
              <FormField
                testID="firstName"
                autoCapitalize="words"
                autoCorrect={false}
                name="firstName"
                label="First Name"
                textContentType="givenName"
                ref={firstNameRef}
                returnKeyType="next"
                onSubmitEditing={() => lastNameRef?.current?.focus()}
              />
              <FormField
                testID="lastName"
                autoCapitalize="words"
                autoCorrect={false}
                name="lastName"
                label="Last Name"
                textContentType="familyName"
                ref={lastNameRef}
                returnKeyType="next"
                onSubmitEditing={() => emailRef?.current?.focus()}
              />
              <FormField
                testID="email"
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="email-address"
                name="email"
                label="Email Address"
                textContentType="emailAddress"
                ref={emailRef}
                returnKeyType="next"
                onSubmitEditing={() => phoneRef?.current?.focus()}
              />
              <FormField
                testID="phone"
                label="Phone Number"
                autoCapitalize="none"
                autoCorrect={false}
                editable={true}
                keyboardType="phone-pad"
                name="phone"
                placeholder=""
                textContentType="telephoneNumber"
                returnKeyType="next"
                ref={phoneRef}
                formatter={formatPhone}
                onSubmitEditing={() => loyaltyIDRef?.current?.focus()}
              />
              {/* TODO To be implemented later */}
              {/* <View style={styles.switchWrapper}>
                <Text style={[appStyles.formLabels, { textAlign: 'right', marginTop: 5, marginRight: 8 }]}>{t('isMobileNumber')}</Text>
                <Switch style={{ flex: 0 }} value={isMobileNumber} onValueChange={(value) => setIsMobileNumber(value)} />
              </View> */}
              <FormField
                testID="loyaltyId"
                label={[
                  <Text key={'seLabel'} testID="seNumberLabel">
                    {t('seNumberLabel')}
                  </Text>,
                  <TouchableOpacity
                    testID="seInfoClick"
                    key={'seInfo'}
                    style={styles.touchableIcon}
                    onPress={showSENumberInfo}
                    hitSlop={{ bottom: 5, top: 5, left: 5, right: 55 }}>
                    <Icon name="info-icon" size={15} style={[appStyles.smallIcon]} />
                  </TouchableOpacity>,
                ]}
                autoCapitalize="none"
                autoCorrect={false}
                editable={false}
                name="loyaltyId"
                placeholder=""
                ref={loyaltyIDRef}
                returnKeyType="done"
              />
            </View>
            <AlternateContactComponent
              {...{
                navigation,
              }}
            />
            <WebBrowserLink
              testID="deleteAccountRequestLinkButton"
              style={[appStyles.fontSmallLink, styles.accountLink]}
              href={`${routes.DELETE_ACCOUNT_REQUEST}`}>
              {t('deleteAccountRequestLinkButton')}
            </WebBrowserLink>
            {/* <TouchableOpacity onPress={() => showAlertModal()}>
              <Text style={[appStyles.fontSmallLink, styles.accountLink]}>{t('deleteAccountRequestLinkButton')}</Text>
            </TouchableOpacity> */}
          </ScrollView>
          <View style={styles.updateBar}>
            <SubmitButton testID="editProfileUpdateBtn" title="Update" buttonStyle={styles.updateButton} isButtonLoading={loadingButton} />
          </View>
        </Form>
      </KeyboardAvoidingScreen>
    </Screen>
  );
};

const styles = StyleSheet.create({
  errorText: {
    textAlign: 'center',
  },
  flexrow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textmargin: { margin: 5 },
  iconPadding: {
    paddingTop: 5,
  },
  form: {
    width: '100%',
    paddingHorizontal: 20,
    alignSelf: 'center',
  },
  modalHeaderText: {
    fontFamily: FontFamily.AleoBold,
    fontSize: scale(28),
    color: colors.text,
    textAlign: 'center',
    lineHeight: lineHeight(28),
    width: screenWidth * 0.8,
  },
  accountLink: {
    textAlign: 'center',
    paddingVertical: 20,
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 32,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 15,
  },
  titleWrapper: {
    paddingHorizontal: 10,
  },
  title: {
    textAlign: 'left',
  },
  helpLinkButton: {
    textDecorationLine: 'underline',
  },
  switchWrapper: {
    marginTop: 16,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  updateBar: {
    width: screenWidth,
    bottom: 0,
    alignSelf: 'center',
    height: scale(56),
    backgroundColor: colors.white,
    borderTopWidth: 1,
    borderColor: colors.grey44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  touchableIcon: {
    paddingLeft: 15,
    alignContent: 'center',
  },
  birthdayClubWrapper: {
    ...utilityStyles.py5,
    backgroundColor: colors.white,
  },
  birthdayClubMember: {
    flexDirection: 'row',
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: colors.westar,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 5,
    height: 40,
  },
  birthdayClubTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addBDClubMember: {
    ...utilityStyles.pt5,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  addBDClubMemberText: {
    ...appStyles.fontMobileSmallLeftRegular,
    textAlign: 'center',
    paddingLeft: 10,
  },
  smallLink: {
    ...appStyles.smallLink,
    fontSize: scale(14),
  },
  updateButton: {
    width: screenWidth * 0.4,
    height: '70%',
    backgroundColor: colors.darkCream,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    padding: 0,
  },
});

export default EditProfileScreen;

import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { scale } from '@styles/constants';
import React from 'react';
import { Image, ImageStyle, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

type SponsoredIndicatorProps = {
  imageStyle?: StyleProp<ImageStyle>;
  barStyle?: StyleProp<ViewStyle>;
};

const SponsoredIndicator = ({ barStyle, imageStyle }: SponsoredIndicatorProps): JSX.Element => {
  return (
    <>
      <View style={[styles.sponsoredBar, barStyle]}>
        <Image resizeMode={'contain'} source={require('../assets/images/sponsored-tag.png')} style={[styles.image, imageStyle]} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  sponsoredBar: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  image: {
    width: 130,
    height: 50,
    aspectRatio: 2,
  },
  sponsoredText: {
    fontSize: scale(13),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
  },
});

export default SponsoredIndicator;

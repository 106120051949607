import {
  Accordion,
  BottomBar,
  Button,
  DropShadow,
  Icon,
  Image,
  LinkButton,
  Modal,
  NavigationHeader,
  Screen,
  Text,
  WalletItem,
  WebBrowserLink,
} from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import { IconName } from '@components/Icon';
import KeyboardCloseButton from '@components/KeyboardCloseButton';
import CartViewOptions from '@components/cart/CartViewOptions';
import Invoice from '@components/cart/Invoice';
import { ErrorMessage, Form, FormField, FormLabel, FormSwitch, SubmitButton } from '@components/forms';
import SEDollars from '@components/somethingExtra/SEDollars';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { defaultMoney, getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import {
  Cart,
  ClientSourceInfo,
  CustomerContact,
  FulfillmentStore,
  Money,
  MyCartUpdateAction,
  OfflinePaymentType,
} from '@fieldera-raleys/client-commercetools/schema';
import { Address, PaymentTypeValue, ShopType } from '@fieldera-raleys/client-common';
import { PaymentProfile } from '@fieldera-raleys/client-common/services/brandywine/types';
import { buildUpdateActionCustomField, getLineItemsToEvaluateCart, isOrderOkToModify } from '@fieldera-raleys/client-common/utils';
import { useAnalytics, useEffectOnce } from '@hooks';
import routes, { AppStackRoutes, CheckoutStackRoutes, CheckoutStackScreenProps } from '@navigation/routes';
import { StackActions, useFocusEffect } from '@react-navigation/native';
import { storeService as bwStoreService, offerService, orderService, storeService, userService } from '@services/brandywine';
import { useAppConfigStore, useCartStore, useCommerceToolsStore, useOffersStore, useShopStore, useUserProfileStore } from '@store';
import { appStyles } from '@styles';
import { containerWidth, defaultFontSize, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import { evaluateCart, getCustomerContact } from '@utils/cartHelper';
import logger from '@utils/logger';
import { queryClient } from '@utils/reactQuery';
import { AxiosError, isAxiosError } from 'axios';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, Linking, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Config } from 'react-native-config';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { useCustomModalContext } from '../../contexts';
import helpers, { formatEmoji, formatName, formatPhone, formatValue, getClientSourceInfo, isCardExpired, vibrateNotify } from '../../utils/helpers';
import {
  getAddressType,
  getBWOrderStoreNumber,
  getCartCustomerContact,
  getCartEndAvailabilityDate,
  getCartLeadTimeMinutes,
  getDeliveryTipValue,
  getDonationAmountMoney,
  getDonationAmountValue,
  getFormattedTimeSlot,
  getFulfillmentInstructions,
  getFulfilmentStore,
  getLogoColors,
  getOfflinePayment,
  getOriginalOrderNumber,
  getPickupPersonName,
  getPromotions,
  getSelectedPayment,
  getTimeSlot,
  isDelivery,
  moneyValue,
  preAuthMonayValue,
  preAuthValue,
  taxedGrossValue,
} from '../../utils/orderHelpers';

type CheckoutScreenProps = CheckoutStackScreenProps<CheckoutStackRoutes.Checkout>;

const CheckoutScreen = ({ navigation }: CheckoutScreenProps) => {
  const tz = 'America/Los_Angeles';
  const [loading, setLoading] = useState<boolean>(true);
  const { userProfile } = useUserProfileStore();
  const {
    transitCart,
    setDonationAmount,
    cart,
    initialize,
    tombSet,
    setPickupPerson,
    setContactFirstName,
    setContactLastName,
    setContactPhone,
    setContactAllowText,
    setFulfillmentInstructions,
    createOrderFromCart,
    getPromoCodeList,
    setPaymentProfile,
    validateCart,
    setPromotions,
    setDeliveryTip,
    executeCartUpdateActions,
  } = useCartStore();
  const {
    availableSomethingExtraOffers,
    acceptedSomethingExtraOffers,
    availableWeeklyExclusiveOffers,
    acceptedWeeklyExclusiveOffers,
    availableDigitalCoupons,
    acceptedDigitalCoupons,
    availableVouchers,
    acceptedVouchers,
    redeemedVouchers,
    nonTargetedOffers,
    offersState,
    vouchersState,
    refreshingVouchers,
    digitalCouponsState,
  } = useOffersStore();
  const { trackConversionEvent } = useAnalytics();
  const { showAlertModal, showAlertPopup, showModal, hideModal } = useCustomModalContext();
  const [canPayAtPickup, setCanPayAtPickup] = useState<boolean>(true);
  const [modalData, setModalData] = useState<{ [key: string]: any }>({ showModal: false });
  const { storePrevious, selectedShopType, deliveryAddress, selectedStore, setSelectedTimeSlot } = useShopStore();
  const [showMoreText, setShowMoreText] = useState<boolean>(false);
  const [isOrdering, setOrdering] = useState<boolean>(false);
  const insets = useSafeAreaInsets();
  const [cartOptionsData, setCartOptionsData] = useState<JSX.Element | null>(null);
  const [lastEvalQuery, setLastEvalQuery] = useState<string>('');
  const [lastCartPromoSet, setLastCartPromoSet] = useState<string>('');
  const formRef = useRef<FormikProps<any>>(null);

  const {
    data: paymentProfiles,
    status: paymentProfileLoadStatus,
    refetch: refetchPayments,
    error,
  } = useQuery<PaymentProfile[]>('paymentProfiles', async () => await userService.getPaymentProfiles());
  const [fffMore, setFffMore] = useState<boolean>(false);
  const [dropDownState, setDropDownState] = useState<{ contact: boolean; payment: boolean; pickup: boolean; sedollars: boolean }>({
    contact: true,
    payment: true,
    pickup: true,
    sedollars: true,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const paymentProfileRef = useRef<PaymentProfile | undefined>(undefined);
  const handleError = useErrorHandler(error);
  const [isBusy, setIsBusy] = useState(false);
  const [showSetDefaultModal, setShowSetDefaultModal] = useState(false);

  const fffJpgUrl = [Config.BRXM_IMAGE_URL, 'consumer-app/cart-screen/fff.png'].join('/');
  const deliveryTipDefault = +(Config.DEFAULT_DELIVERY_TIP_PERCENT ?? 2);
  const PAY_AT_PICKUP = 'payAtPickup';
  const { getConfigValue } = useAppConfigStore();
  const { t } = useTranslation(['cart', 'checkout']);
  const paymentDisclaimerMore = t('paymentDisclaimerMore');
  const fffDescriptionMore = t('fffDescriptionMore');
  const { defaultStore } = useCommerceToolsStore();

  const debounceDelay: number = 500;
  const _setPickupPerson = useMemo(() => _.debounce(setPickupPerson, debounceDelay), [setPickupPerson]);
  const _setFulfillmentInstructions = useMemo(() => _.debounce(setFulfillmentInstructions, debounceDelay), [setFulfillmentInstructions]);
  const _setContactFirstName = useMemo(() => _.debounce(setContactFirstName, debounceDelay), [setContactFirstName]);
  const _setContactLastName = useMemo(() => _.debounce(setContactLastName, debounceDelay), [setContactLastName]);
  const _setContactPhone = useMemo(() => _.debounce(setContactPhone, debounceDelay), [setContactPhone]);
  const _setContactAllowText = useMemo(() => _.debounce(setContactAllowText, debounceDelay), [setContactAllowText]);
  const _setDeliveryTip = useMemo(() => _.debounce(setDeliveryTip, debounceDelay), [setDeliveryTip]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label(t('firstName')),
    lastName: Yup.string().required().label(t('lastName')),
    phone: Yup.string().required().matches(helpers.PHONE_NUMBER_REGEX, t('invalidPhone')).label(t('phone')),
  });

  const promoSavings = cart?.customLineItems?.filter((p) => (p.slug ?? '').split('-')[0] === 'promo') ?? [];
  const promos = promoSavings.reduce(
    (acc, pe) => {
      const promoValue = pe.custom?.customFieldsRaw?.find((f) => f.name === 'promotionSavings')?.value ?? defaultMoney;
      acc.push({
        description: pe.name ?? 'Savings',
        value: promoValue,
      });

      return acc;
    },
    [] as { description: string; value: Money }[],
  );
  const savingsOf = promos.reduce((sum, p) => (sum += p.value.centAmount), 0);

  const flushDebounces = async () => {
    // flush calls if any will get serialized by cart Mutex, waiting is ok, rebuild to use single debounced updateAction[] to cut on calls to CT
    await _setPickupPerson.flush()?.catch();
    await _setFulfillmentInstructions.flush()?.catch();
    await _setContactLastName.flush()?.catch();
    await _setContactFirstName.flush()?.catch();
    await _setContactPhone.flush()?.catch();
    await _setContactAllowText.flush()?.catch();
  };

  const [contentBottom, setContentBottom] = useState<{ paddingBottom: number }>({ paddingBottom: insets.bottom });
  const [infoBox, setInfoBox] = useState<{ bottom: number }>({ bottom: insets.bottom });
  const onButtonLayout = (le: LayoutChangeEvent) => {
    setContentBottom({ paddingBottom: le.nativeEvent.layout.height });
    setInfoBox({ bottom: le.nativeEvent.layout.height });
  };

  const handleAddPaynmentMethod = useCallback(() => {
    navigation.navigate(CheckoutStackRoutes.AddPaymentMethod, {
      calledFrom: CheckoutStackRoutes.Checkout,
    });
    setNeedRefresh(true);
  }, [navigation]);

  const handleEditPaymentMethod = useCallback(
    (item: PaymentProfile) => {
      navigation.navigate(CheckoutStackRoutes.AddPaymentMethod, {
        paymentMethodId: item.CustomerPaymentMethodId,
        calledFrom: CheckoutStackRoutes.Checkout,
      });
      setNeedRefresh(true);
    },
    [navigation],
  );

  const handleRemove = (item: PaymentProfile) => {
    paymentProfileRef.current = item;
    setShowDeleteModal(true);
  };
  const handleRemovePaymentMethod = async (): Promise<void> => {
    if (paymentProfileRef.current) {
      setIsBusy(true);
      queryClient.executeMutation({
        mutationKey: ['payment-method', paymentProfileRef.current.CustomerPaymentMethodId],
        mutationFn: async () => userService.deletePaymentProfile({ ...paymentProfileRef.current }),
        onError: (e) => {
          const message = (e as Error).message;
          if (message.indexOf('unbilled order') >= 0) {
            hideModal();
            showModal({
              title: 'Error!',
              children: <Text style={appStyles.bodyLargeCenter}>{message}</Text>,
              cancelButtonOnPress: hideModal,
              cancelButtonText: 'OK',
              location: 'top',
              showCloseIcon: false,
            });
          } else {
            handleError(e);
          }
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries('payment-methods');
          await refetchPayments();
        },
        onSettled: () => {
          setIsBusy(false);
        },
      });
    }
    setShowDeleteModal(false);
  };

  const handleSetDefault = (item: PaymentProfile) => {
    paymentProfileRef.current = item;
    setShowSetDefaultModal(true);
  };
  let customerContact = getCartCustomerContact(cart);

  const handleSetDefaultPayment = () => {
    if (paymentProfileRef.current) {
      setIsBusy(true);
      queryClient.executeMutation({
        mutationKey: ['payment-method', paymentProfileRef.current.CustomerPaymentMethodId],
        mutationFn: async () => userService.updatePaymentProfile({ ...paymentProfileRef.current, IsDefault: true }),
        onSuccess: async () => {
          await queryClient.invalidateQueries('payment-methods');
          await refetchPayments();
        },
        onError: (e) => {
          handleError(e);
        },
        onSettled: () => {
          setIsBusy(false);
        },
      });
    }
    setShowSetDefaultModal(false);
  };

  const onSubmit = async () => {
    return false;
  };

  const [needRefetch, setNeedRefresh] = useState(false);
  useFocusEffect(() => {
    if (needRefetch) {
      setNeedRefresh(false);
      refetchPayments();
      setTimeout(() => {
        try {
          refetchPayments();
        } catch (e) {
          /* empty */
        }
      }, 2500);
    }
  });

  const [promoCodeList, setPromoCodeList] = useState<JSX.Element | JSX.Element[]>(<></>);
  const unacceptPromoCode = useCallback(
    async (promoCode?: string): Promise<void> => {
      if (promoCode) {
        let message: string = 'Invalid Promo Code';
        try {
          const result = await offerService.unAcceptPromo(promoCode);
          if (!result || result.HasException) {
            throw new Error(result?.ReturnMessage ?? 'unAcceptPromo no result');
          }
          message = `Promo Code "${promoCode}" successfully removed.`;
        } catch (ex) {
          logger.error(ex, { method: 'unacceptPromoCode' });
        }
        showAlertPopup({
          message,
          containerStyle: [styles.alertBox, infoBox],
          ellipsizeMode: 'head',
        });
        setLastCartPromoSet(''); // force refresh, PromoCodes are returnedonly through evaluatecart
      }
    },
    [infoBox, showAlertPopup],
  );

  const [cartPromoSet, setCartPromoSet] = useState<string>('');
  const doFetchPromos = useCallback(() => {
    if (!isOrdering && cart && cartPromoSet) {
      const cartEvalQuery = JSON.stringify(getLineItemsToEvaluateCart(cart.lineItems ?? []));
      if (cartEvalQuery !== lastEvalQuery || cartPromoSet !== lastCartPromoSet) {
        evaluateCart(cart, selectedStore ? selectedStore.number : defaultStore?.key ?? '', selectedShopType === 'Pickup' ? 'pickup' : 'delivery')
          .then((result) => {
            if (cart) {
              setLastCartPromoSet(cartPromoSet);
              setLastEvalQuery(cartEvalQuery);
              const newPromos = JSON.stringify(result);
              if (newPromos !== getPromotions(cart)) {
                setPromotions(newPromos);
              }
              const promoCodes = result.filter((p) => p.IsPromoCodePromo && p.IsAccepted);
              setPromoCodeList([
                ...promoCodes.map((p, indx) => (
                  <View key={`promoCode_${indx}`} style={[styles.promoCodePad, utilityStyles.my1]}>
                    <View key={`promoCode2_${indx}`} style={[{ flexDirection: 'row', flex: 1 }, utilityStyles.mt1]}>
                      <Text style={[appStyles.bodySmallBold, { color: colors.red }]}>{p.PromotionCode?.toUpperCase() ?? p.Headline ?? 'PROMO'}</Text>
                      <TouchableOpacity onPress={() => unacceptPromoCode(p.PromotionCode)} style={{ marginLeft: 'auto' }}>
                        <Text testID="buyTwoGetOneValue" style={[styles.promoCodeX]}>
                          +
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {0 ? (
                      <View key={`promoCodeSub_${indx}`} style={[{ flexDirection: 'row', width: '100%' }, utilityStyles.my1]}>
                        <Text style={[appStyles.bodySmallLeftRegular, { color: colors.red, flex: 1 }]}>
                          {p.Headline ?? p.PromotionCode?.toUpperCase() ?? 'PROMO'}
                        </Text>
                        {p.PercentComplete && p.PercentComplete < 100 ? (
                          <Text testID="buyTwoGetOneValue" style={[appStyles.bodySmallLeftRegular, { marginLeft: 'auto', color: colors.red }]}>
                            Progress: {p.PercentComplete.toFixed(2)}%
                          </Text>
                        ) : (
                          <Text testID="buyTwoGetOneValue" style={[appStyles.bodySmallLeftRegular, { marginLeft: 'auto', color: colors.red }]}>
                            &minus;&nbsp;{moneyValue({ ...defaultMoney, centAmount: Math.round((p.Rewards?.[0]?.RewardDiscount ?? 0) * 100) })}
                          </Text>
                        )}
                        <Text testID="buyTwoGetOneValue" style={[appStyles.bodySmallLeftRegular, { marginLeft: 'auto', color: colors.red }]} />
                      </View>
                    ) : (
                      <></>
                    )}
                    <Text testID="buyTwoGetOneText" ellipsizeMode={'tail'} numberOfLines={4} style={[appStyles.bodySmallLeftRegular]}>
                      {p.Description}
                    </Text>
                  </View>
                )),
              ]);
            }
          })
          .catch((reason) => {
            logger.log(reason);
          })
          .finally(() => {
            /* empty */
          });
      }
    }
    //Removed unecessary dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, cartPromoSet, defaultStore?.key, isOrdering, lastCartPromoSet, lastEvalQuery, selectedShopType, selectedStore]);
  const _doFetchPromos = useMemo(() => _.debounce(doFetchPromos, debounceDelay), [doFetchPromos]);
  const handleOptionsCancelPress = () => {
    setShowCartOptions('');
  };

  useEffect(() => {
    let tmpSet = JSON.stringify([]);
    if (cart) {
      const promoSourceSet = [
        offersState,
        availableWeeklyExclusiveOffers,
        acceptedWeeklyExclusiveOffers,
        availableSomethingExtraOffers,
        acceptedSomethingExtraOffers,
        availableDigitalCoupons,
        acceptedDigitalCoupons,
        availableVouchers,
        acceptedVouchers,
        redeemedVouchers,
        nonTargetedOffers,
        vouchersState,
        refreshingVouchers,
        digitalCouponsState,
      ];
      tmpSet = JSON.stringify(
        promoSourceSet.reduce((acc, pset) => {
          acc.push(JSON.stringify(pset));
          return acc;
        }, [] as string[]),
      );
    }

    if (cartPromoSet !== tmpSet) {
      setCartPromoSet(tmpSet);
    }
    _doFetchPromos();
    //Removed unecessary dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cart,
    cartPromoSet,
    availableWeeklyExclusiveOffers,
    acceptedWeeklyExclusiveOffers,
    availableSomethingExtraOffers,
    acceptedSomethingExtraOffers,
    availableDigitalCoupons,
    acceptedDigitalCoupons,
    availableVouchers,
    acceptedVouchers,
    redeemedVouchers,
    nonTargetedOffers,
    _doFetchPromos,
  ]);

  const cartViewOptionRef = React.useRef<React.ElementRef<typeof CartViewOptions>>(null);
  const handleOptionsApplyPress = () => {
    if (cartViewOptionRef.current) {
      cartViewOptionRef.current.doApply();
      setCartPromoSet('-'); // force refresh on adding promocodes
      _doFetchPromos();
    }
    setShowCartOptions('');
  };
  const [showCartOptions, setShowCartOptions] = useState<string>('');
  const doOptionsPress = useCallback(
    (opType: 'tip' | 'fff' | 'ptype' | 'promo') => {
      setCartOptionsData(<CartViewOptions optionType={opType} ref={cartViewOptionRef} infoBox={infoBox} />);
      setShowCartOptions(opType);
    },
    [infoBox],
  );

  useEffectOnce(() => {
    // pre-validate checkout screen
    const isDel = isDelivery(cart);
    if (
      cart &&
      (!(cart.lineItems?.length ?? 0) ||
        undefined === isDel ||
        !cart.shippingAddress ||
        !cart.shippingAddress?.streetName ||
        !cart.shippingAddress?.postalCode ||
        !cart.shippingAddress?.city ||
        !getFulfilmentStore(cart) ||
        !getTimeSlot(cart))
    ) {
      navigation.navigate(CheckoutStackRoutes.CartLanding, { storeNumber: undefined });
    }

    return () => {
      // flush all debounces
      flushDebounces();
    };
  });

  const callANumber = (number?: string) => {
    Linking.openURL(`tel:${number}`);
  };

  useEffect(() => {
    if (paymentProfileLoadStatus === 'success' && cart && !isOrdering) {
      setLoading(false);
    }
  }, [paymentProfileLoadStatus, cart, isOrdering]);

  useEffect(() => {
    if (modalData && !modalData.showModal && modalData.onClose) {
      if (modalData.onClose) {
        modalData.onClose();
        setModalData({ ...modalData, onClose: undefined });
      }
    }
  }, [modalData]);

  const canPlaceOrder = async (latestCart: Cart | undefined) => {
    if (!latestCart) {
      return false;
    }
    let errorTitle = t('errorCheckoutTitle');
    let onClose: any;
    const todo = [];
    if ((latestCart.lineItems?.length ?? 0) === 0) {
      todo.push(t('errorEmptyCart'));
    } else {
      const isDel = isDelivery(latestCart);
      if (isDel === undefined) {
        todo.push(t('errorShoppingMethod'));
        onClose = () => changeShoppingOptions('InitialShopOptions');
      } else if (selectedShopType !== ShopType.PICKUP && selectedShopType !== ShopType.DELIVERY) {
        todo.push(t('errorNotSupportedShoppingMethod', { selectedShopType }));
        onClose = () => changeShoppingOptions('InitialShopOptions');
      } else {
        let fStore = getFulfilmentStore(latestCart);
        if (
          !latestCart.shippingAddress ||
          !latestCart.shippingAddress?.streetName ||
          !latestCart.shippingAddress?.postalCode ||
          !latestCart.shippingAddress?.city ||
          !fStore?.number
        ) {
          todo.push(isDel ? t('errorDeliveryAddress') : t('errorPickupStore'));
          onClose = () => changeShoppingOptions(isDel ? 'Delivery' : 'FindAStore');
        } else {
          const ts = getTimeSlot(latestCart);
          let startHour;
          if (
            !ts ||
            !ts.timeSlotDate ||
            !ts.timeSlots ||
            ts.timeSlots.length < 1 ||
            ts.timeSlotDate?.length < 10 ||
            !dayjs.tz(ts.timeSlotDate, tz).isValid() ||
            isNaN((startHour = ts.timeSlots[0].timeSlotHourStart)) ||
            Number(startHour) < 0 ||
            Number(startHour) > 23
          ) {
            todo.push(isDel ? t('errorDeliveryTimeslot') : t('errorPickupTimeslot'));
            onClose = () => changeShoppingOptions('TimeSlot');
          } else {
            if (isDel) {
              // check the delivery address is still serviced by the store
              const bwSearchedStore = await bwStoreService
                .searchStores(0, undefined, {
                  shippingMethod: 'delivery',
                  postalCode: latestCart.shippingAddress.postalCode,
                })
                .catch((reason) => {
                  logger.log('bwStoreService.searchStores call failed:', reason);
                  return undefined;
                });
              if (!bwSearchedStore || !bwSearchedStore.data?.length || !bwSearchedStore.data.some((d) => d.number === (fStore?.number ?? '1'))) {
                todo.push(
                  'Unable to verify delivery information placing your order.  Please review your delivery address and confirm your selections before placing your order. ',
                );
                onClose = () => changeShoppingOptions('Delivery');
              }
            }

            if (!todo.length) {
              // with no errors, check timeslot availability with the fulfilment store
              let seedTime = dayjs(ts.timeSlotDate).format('YYYYMMDDHHmmss');
              let shippingId = isDel ? appConstants.DELIVERY_SHIPPING_ID : appConstants.PICKUP_SHIPPING_ID;
              const dates = await storeService.getTimeSlotsByStore(fStore.number, seedTime, ts.timeSlots[0]?.timeSlotHourStart, shippingId);
              // TODO: don't fail on empty timeslot if able to reuse one from original order, requires Back End update first
              if (
                !dates?.length ||
                !dates.some((d) => {
                  return d.timeSlots.some((tt) => {
                    return (
                      (tt.timeSlotAvailableQty &&
                        d.timeSlotDate === ts.timeSlotDate &&
                        ts.timeSlots[0].timeSlotId === 'no-id' &&
                        ts.timeSlots[0].timeSlotHourStart === tt.timeSlotHourStart) ||
                      tt.timeSlotId === ts.timeSlots[0].timeSlotId
                    );
                  });
                })
              ) {
                todo.push(
                  <Text>
                    Currently selected time slot is no longer available to place the order. Please select a different time slot and try again.{'\n'}
                    <Text style={{ lineHeight: 14 }}> </Text>
                  </Text>,
                );
                onClose = () => changeShoppingOptions('TimeSlot');
              } else {
                let then = dayjs.tz(ts.timeSlotDate, tz);
                const now = dayjs();
                const leadTime = getCartLeadTimeMinutes(cart);
                if (ts.timeSlots[0]?.timeSlotHourStart) {
                  then = then.set('hours', ts.timeSlots[0].timeSlotHourStart);
                }
                if (then.diff(now.add(leadTime, 'minutes'), 'minutes') <= 0) {
                  // too late for the timeslot
                  const leadMessage =
                    leadTime > +Config.LEAD_TIME_MAX_HOURS_TOSHOW * 60 ? `${(leadTime / 60 / 24 + 1) | 0} day` : `${(leadTime / 60) | 0}-hour`;

                  todo.push(<Text>{t('selectFutureTimeslot', { leadTime: leadMessage })}</Text>);
                  onClose = () => changeShoppingOptions('TimeSlot');
                } else {
                  // also check original order to validate lead time for previouse store and products if it has changed
                  const originalOrderNumber = getOriginalOrderNumber(cart);
                  if (originalOrderNumber) {
                    let serviceContact = appConstants.CUSTOMER_SERVICE_PHONE ?? '8009259989';
                    let canMod = false;
                    const originalOrder = await orderService
                      .getOrder(originalOrderNumber)
                      .then((res) => res)
                      .catch((e: unknown) => {
                        logger.error(
                          `await orderService.getOrder(originalOrderNumber) in CheckoutScreen/canPlaceOrder: ${isAxiosError(e) ? (e as AxiosError).message : JSON.stringify(e)}`,
                        );
                      });
                    if (originalOrder) {
                      const originalStoreNumber = getBWOrderStoreNumber(originalOrder);
                      if (originalStoreNumber && selectedStore) {
                        // NOTE: selectedStore may not be set ( treat as dynamic business rule , eg. keep logic for both incase rules checkout rules change )
                        // should use cart's fStore for valid order data, but need selectedStore (UI) for defaultLeadTime
                        // Should errors appear in logs, add logic to re-sync cart to UI ( watch for error loop as CT calls are omitted if cart thinks its unchanged )
                        // move towards start of the method when fStore is fetched, asume selectedStore may not be set
                        try {
                          if (fStore.number !== selectedStore?.number) {
                            logger.error(
                              new Error(
                                'The selectedStore and fulfilmentStore are out of sync in CheckoutScreen/CanPlaceOrder. If here, add sync code (check for loop logic: CT has no-change-no-calls for many functions.',
                              ),
                              { originalStoreNumber, fStoreNumber: fStore.number, selectedStoreNumber: selectedStore.number },
                            );
                          }
                          canMod = isOrderOkToModify(
                            originalOrder,
                            originalStoreNumber,
                            selectedStore?.fulfillmentLeadTimeMinutes ?? +Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES ?? 120,
                            0,
                          );
                        } catch (e: unknown) {
                          logger.error(
                            `Exception while calling isOrderOkToModify in CheckoutScreen/canPlaceOrder. ${isAxiosError(e) ? (e as AxiosError).message : JSON.stringify(e)}-=ds`,
                          );
                        }
                        if (!canMod) {
                          await storeService
                            .getStore(originalStoreNumber)
                            .then((res) => {
                              const onLineDepartment = (res.departments ?? []).find((x) => x && x.departmentId === +(Config?.ONLINE_DEPARTMENT_ID ?? 11));
                              serviceContact = onLineDepartment && onLineDepartment.phoneNumber ? onLineDepartment.phoneNumber : res.phone ?? serviceContact;
                            })
                            .catch((e: unknown) => {
                              logger.error(
                                `Exception while getStore(originalStoreNumber)=>onlineDepartment=>phone, defaulting to fStore or global number ${isAxiosError(e) ? (e as AxiosError).message : JSON.stringify(e)} -=ds`,
                              );
                            });
                        }
                      }
                    }

                    if (!originalOrder || !canMod) {
                      // can not locate original in BW
                      errorTitle = t('modNoLongerAvailableTitle');
                      todo.push(
                        <Text>
                          <Text>
                            The original order will be processed and no pending changes will be applied. If needed, call or text your Personal Shopper at{'\n'}
                          </Text>
                          <Text
                            key={'canMptModKey'}
                            onPress={() => callANumber(serviceContact)}
                            style={[
                              appStyles.bodyCenterBold,
                              { lineHeight: lineHeight(appStyles.bodyCenterBold.lineHeight ?? lineHeight(scale(defaultFontSize))) },
                            ]}
                            testID="showModificationText">
                            {formatPhone(serviceContact)}
                          </Text>
                        </Text>,
                      );
                      // we reset the cart modification due to prior bugs and stale order
                      // now have validations in place, customer can cancel manually and will also be tracked by new time
                      // this is one place in the App where a Pre-Authorized cart can get deleted (also can't get back to Modal with Customer Support number if missed first time)
                      // removing for now and staying in CheckOut.
                      // resetMyCart();
                      // onClose = () => {
                      //  navigation.dispatch(StackActions.replace(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home, params: {} }));
                      // };
                    }
                  }
                }
                if (!todo.length) {
                  if (then.diff(now.add(leadTime, 'minutes'), 'minutes') <= 0) {
                    // too late for the timeslot
                    const leadMessage =
                      leadTime > +Config.LEAD_TIME_MAX_HOURS_TOSHOW * 60 ? `${(leadTime / 60 / 24 + 1) | 0} day` : `${(leadTime / 60) | 0}-hour`;

                    todo.push(<Text>{t('selectFutureTimeslot', { leadTime: leadMessage })}</Text>);
                    onClose = () => changeShoppingOptions('TimeSlot');
                  } else {
                    const endTime: dayjs.Dayjs | undefined = getCartEndAvailabilityDate(cart);
                    if (endTime) {
                      // then = dayjs(ts.timeSlotDate);  // no CT support for seconds, allowing through 23:59:01
                      // if (ts.timeSlots[0]?.timeSlotHourStart) {
                      //   then = then.add(ts.timeSlots[0].timeSlotHourStart, 'hours');
                      // }
                      if (endTime.diff(ts.timeSlotDate, 'minutes') < 0) {
                        todo.push(
                          <Text>
                            Some items in your cart are not available during the selected time slot. Please select a different time slot or edit the items in
                            your cart.{'\n'}
                            <Text style={{ lineHeight: 14 }}> </Text>
                          </Text>,
                        );
                        onClose = () => changeShoppingOptions('TimeSlot');
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (todo.length) {
      setModalData({
        location: 'top',
        title: errorTitle,
        description: <Text>{todo[0]}</Text>,
        showModal: true,
        onClose: onClose,
      });
      return false;
    }

    return true;
  };

  const [mainRef, setMainRef] = useState<ScrollView | null>(null);
  const [mainScrollViewY, setMainScrollViewY] = useState(0);
  const [paymentMarkerY, setPaymentMarkerY] = useState(0);
  const scrollToPayment = () => {
    if (mainRef) {
      mainRef.scrollTo({ x: 0, y: mainScrollViewY + paymentMarkerY, animated: true });
    }
  };

  // order functions
  const placeOrder = async () => {
    setOrdering(true);
    await flushDebounces();
    let latestCart = await initialize();
    let cartUpdateActions: MyCartUpdateAction[] = [];
    const selectedPaymentMethodId = getSelectedPayment(latestCart);

    let cardExpired: boolean = false;
    if (selectedPaymentMethodId) {
      const pp = paymentProfiles?.find((p) => p.CustomerPaymentMethodId === selectedPaymentMethodId && isCardExpired(p));
      if (pp && isCardExpired(pp)) {
        cardExpired = true;
      }
    }

    if ((!canPayAtPickup && !selectedPaymentMethodId) || cardExpired) {
      setModalData({
        location: 'top',
        title: t('errorCheckoutTitle'),
        description: <Text>Please Select a Valid Payment Method</Text>,
        showModal: true,
        onClose: scrollToPayment,
      });

      setOrdering(false);
      return;
    }

    try {
      try {
        const validationResult = await validateCart();
        if (!validationResult.isValid) {
          setOrdering(false);
          return navigation.dispatch(
            StackActions.replace(AppStackRoutes.CartAndCheckout, {
              screen: CheckoutStackRoutes.CartLanding,
              params: {
                storeNumber: undefined,
                shopType: selectedShopType ?? ShopType.PICKUP,
                deliveryAddress:
                  selectedShopType && selectedShopType === ShopType.PICKUP
                    ? selectedStore?.address
                    : ({ state: deliveryAddress?.state ?? 'CA', postalCode: deliveryAddress?.postalCode ?? '' } as Address),
              },
            }),
          );
        }
      } catch (vError) {
        throw vError;
      }

      await flushDebounces();
      latestCart = await initialize();
      if (!(await canPlaceOrder(latestCart))) {
        setOrdering(false);
        return;
      }

      try {
        let currCust: CustomerContact | undefined; // check customer contact fields
        try {
          currCust = getCartCustomerContact(latestCart);
          if (!currCust?.sepiNumber) {
            throw 'Fetch the Customer contact'; // jump to failover block
          }
        } catch {
          currCust = getCustomerContact();
          if (!currCust?.sepiNumber) {
            currCust = getCustomerContact(
              await useUserProfileStore
                .getState()
                .loadUserProfile()
                .catch(() => {
                  throw new Error('AppError:NoSepi:FetchProfile');
                }),
            );
            if (!currCust?.sepiNumber) {
              throw new Error('AppError:NoSepi:BadProfileData');
            }
          }
          cartUpdateActions.push(...buildUpdateActionCustomField(latestCart, 'customerContact', JSON.stringify(currCust)));
        }
      } catch (contactError) {
        throw contactError; // propagate
      }
    } catch (reason) {
      logger.log('Failed to load Customer Contact from Profile', reason);
      setOrdering(false);
      setModalData({
        location: 'top',
        title: t('errorCheckoutTitle'),
        description: <Text>{`Something went wrong. ${'\n'}Please try your action again${'\n'} or a little later.`}</Text>,
        showModal: true,
        onClose: scrollToPayment,
      });
      return;
    }

    if (!selectedPaymentMethodId && canPayAtPickup) {
      // validate pickupAtStore method, and make sure payment type is correctly set
      const offLinePayment = getOfflinePayment(latestCart);
      let paymentMethod = offLinePayment;
      if (
        ![PaymentTypeValue.CC_ON_PICKUP, PaymentTypeValue.CASH_ON_PICKUP, PaymentTypeValue.EBT_SNAP_ON_PICKUP].some(
          (pm: OfflinePaymentType) => pm === offLinePayment,
        )
      ) {
        paymentMethod = PaymentTypeValue.CC_ON_PICKUP;
      }
      if (selectedPaymentMethodId !== 0 || offLinePayment !== paymentMethod) {
        cartUpdateActions.push(
          ...buildUpdateActionCustomField(latestCart, 'customerPaymentMethodId', '0'),
          ...buildUpdateActionCustomField(latestCart, 'offlinePaymentMethodType', PaymentTypeValue.CC_ON_PICKUP),
        );
      }
    }

    try {
      const preAuth = preAuthMonayValue(latestCart, getConfigValue<number>('OrderAuthAmountMultiplier') ?? 1.15, savingsOf);
      const authAmount = +(preAuth.centAmount / 10 ** (latestCart.taxedPrice?.totalGross?.fractionDigits ?? 2)).toFixed(2);
      if (isNaN(authAmount)) {
        throw new Error('AppError:Auth:InvalidAuthAmount');
      }

      if (authAmount > 0) {
        if (!selectedPaymentMethodId) {
          if (!canPayAtPickup) {
            if (cartUpdateActions.length) {
              // save outstanding fixes/changes to customer cart before erroing
              await executeCartUpdateActions(cartUpdateActions).catch(() => {
                throw new Error('AppError:Auth:NoPayAtPickup:UpdateActions');
              });
            }
            throw new Error('AppError:Auth:NoPayAtPickup');
          }
        } else {
          let result;
          try {
            result = await userService.authorizePayment(selectedPaymentMethodId, authAmount).catch((auCallError) => {
              throw auCallError;
            });
          } catch (ee) {
            logger.log(ee);
            if (cartUpdateActions.length) {
              // save outstanding fixes/changes to customer cart before erroing
              await executeCartUpdateActions(cartUpdateActions).catch(() => {
                throw new Error('AppError:Auth:Generic:1:UpdateActios');
              });
            }
            throw new Error('AppError:Auth:Generic:2');
          }
          const authResponse: {
            AuthCode: string;
            TransactionId: string;
          } = result;

          if (authResponse.AuthCode?.length > 0 && authResponse.TransactionId?.length > 0) {
            let authorizationCode = `${authResponse.AuthCode}|${authAmount}|${authResponse.TransactionId}`;
            cartUpdateActions.push(...buildUpdateActionCustomField(latestCart, 'customerPaymentMethodAuthCode', authorizationCode));
          } else {
            if (cartUpdateActions.length) {
              // save outstanding fixes/changes to customer cart before erroing
              await executeCartUpdateActions(cartUpdateActions).catch(() => {
                throw new Error('AppError:Auth:Generic:3:UpdateActios');
              });
            }
            throw new Error('AppError:Auth:Generic:4');
          }
        }
      }

      const clientSourceInfo = await getClientSourceInfo();

      if (clientSourceInfo) {
        cartUpdateActions.push(
          ...Object.keys(clientSourceInfo).flatMap((clientSourceKey) =>
            buildUpdateActionCustomField(latestCart, clientSourceKey, clientSourceInfo[clientSourceKey as keyof ClientSourceInfo] ?? ''),
          ),
        );
      }
      if (cartUpdateActions.length) {
        // save outstanding fixes/changes to customer cart before placing an order
        await executeCartUpdateActions(cartUpdateActions).catch(() => {
          throw new Error('AppError:Auth:Generic:5:UpdateActions:');
        });
      }

      const order = await createOrderFromCart();
      vibrateNotify('Buzz');
      if (selectedStore) {
        storePrevious(selectedStore);
      }
      trackConversionEvent(order).catch();
      const payAtPickup = order?.custom?.customFieldsRaw?.find((x) => x.name === 'offlinePaymentMethodType') ?? '';
      setSelectedTimeSlot(undefined);
      navigation.navigate(CheckoutStackRoutes.OrderComplete, { orderId: order.id, offlinePaymentMethod: payAtPickup !== '' ? payAtPickup.value : '' });
    } catch (reason) {
      logger.error(reason);
      setOrdering(false);
      setModalData({
        location: 'top',
        title: t('errorCheckoutTitle'),
        description: (
          <Text>{`We are unable to process your payment at this time. Please review your selected payment options and try again, or try using a different payment method.${'\n'}If you continue to receive this error, please contact the Raley’s Service Center at (800) 925-9989.`}</Text>
        ),
        showModal: true,
        onClose: scrollToPayment,
      });
    }
  };

  let checkoutFailed = false;
  const ContactSection = useCallback(
    (): JSX.Element => (
      <View key={'mainScrollViewViewContact'} style={styles.contentPad}>
        <View style={[{ paddingTop: 13, paddingBottom: 13 }]}>
          <FormField
            testID="firstName"
            label={t('firstName')}
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="ascii-capable"
            name="firstName"
            onChange={(e) => _setContactFirstName(e.nativeEvent.text)}
            textContentType="givenName"
            formatter={formatName}
            returnKeyType="next"
          />
          <FormField
            testID="lastName"
            label={t('lastName')}
            autoCapitalize="none"
            textContentType="familyName"
            autoCorrect={false}
            name="lastName"
            onChange={(e) => _setContactLastName(e.nativeEvent.text)}
            formatter={formatName}
            returnKeyType="next"
          />
          <FormField
            testID="phone"
            label="Mobile Phone Number"
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="phone-pad"
            name="phone"
            onChange={(e) => _setContactPhone(formatPhone(e.nativeEvent.text) ?? '')}
            placeholder=""
            textContentType="telephoneNumber"
            returnKeyType="done"
            formatter={formatPhone}
          />
          <View style={[styles.flexrow, { width: containerWidth }]}>
            <FormSwitch
              testID="allowText"
              labelStyle={{ width: screenWidth * 0.7 }}
              name={'allowText'}
              label={t('textOrderUpdates')}
              onChange={() => _setContactAllowText(!customerContact?.allowText ?? false)}
            />
            <TouchableOpacity
              style={[styles.biggerHitArea, { paddingTop: 24 }]}
              onPress={() =>
                setModalData({
                  location: 'top',
                  title: t('allowTextUpdatesInfoTitle'),
                  description: [
                    <Text testID="messageText" style={[appStyles.bodyLargeCenter, { lineHeight: lineHeight(22) }]}>
                      Message and data rates may apply.
                      {'\n'}Message frequency varies.
                      {'\n'}See{' '}
                      <WebBrowserLink
                        href={routes.SMS_TERMS_OF_USE}
                        style={[appStyles.footerLink]}
                        onPress={() => setModalData((prev) => ({ ...prev, showModal: false }))}>
                        SMS Terms
                      </WebBrowserLink>{' '}
                      for more info.
                    </Text>,
                  ],
                  showModal: true,
                })
              }>
              <Icon testID="questionCircleIcon" name="question-circle" size={20} style={appStyles.mediumIcon} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    ),
    [_setContactAllowText, _setContactFirstName, _setContactLastName, _setContactPhone, customerContact?.allowText, t],
  );

  const renderLocation = useCallback(() => {
    const formatedTs = getFormattedTimeSlot(cart, 'Unavailable');
    let obj = cart;
    const dateSlot = getTimeSlot(obj)?.timeSlotDate;
    const a = obj?.shippingAddress;
    let slotDate = t('unavailable');
    if (dateSlot) {
      try {
        const dateTime = dayjs(dateSlot);
        const day = dateTime.format('ddd');
        const month = dateTime.format('MMM');
        const dt = dateTime.date();
        slotDate = `${day}. ${month} ${dt}`;
      } catch (e) {
        /* */
      }
    }
    const storeInfo: FulfillmentStore | undefined = getFulfilmentStore(cart);
    if (storeInfo?.logo.includes('one')) {
      storeInfo.logo = 'one';
    }
    const logoColors = getLogoColors(storeInfo?.logo === 'one' ? 'raleysonemarket' : storeInfo?.logo);
    const addressType = getAddressType(obj);
    return [
      <View key={'oc_timeSlot_View1'} style={[]}>
        <Text testID="slotDateText" style={[appStyles.h5, styles.textAlignCenter]}>
          {slotDate}
        </Text>
        <Text testID="timeSlotText" style={[appStyles.h5, styles.textAlignCenter]}>
          {formatedTs}
        </Text>
      </View>,
      <View key={'oc_timeSlot_View2'} style={[styles.infoPadSeparator, { margin: 10 }]} />,
      <View key={'oc_timeSlot_View3'} style={[styles.location]}>
        <View style={styles.iconWrapper}>
          {isDelivery(obj) ? (
            <Icon
              testID="addressTypeIcon"
              name={addressType === 'home' ? 'home-icon' : 'office-icon'}
              size={50}
              fill={colors.darkCream}
              stroke={colors.darkCream}
              style={styles.homeIcon}
            />
          ) : (
            <Icon
              testID="storeInfoIcon"
              name={storeInfo?.logo === 'one' ? ('raleysonemarket' as IconName) : (`${storeInfo?.logo}` as IconName)}
              size={70}
              fill={logoColors?.fill || 'none'}
              fillSecondary={logoColors?.fillSecondary || 'none'}
              stroke={logoColors?.stroke || 'none'}
              style={styles.homeIcon}
            />
          )}
        </View>
        <View style={styles.locationInfo}>
          {isDelivery(obj) && (
            <Text testID="deliveryNameText" numberOfLines={1} style={styles.locationText}>
              {a?.firstName || a?.lastName ? `${a?.firstName ?? ''} ${a?.lastName ?? ''}` : t('unavailable')}
            </Text>
          )}
          {addressType === 'business' && a?.company ? (
            <Text testID="companyNameText" numberOfLines={1} style={styles.locationText}>
              {a.company}
            </Text>
          ) : null}
          <Text testID="streetNameText" numberOfLines={1} style={styles.locationText}>
            {a?.streetName ?? ''}
          </Text>
          <Text testID="cityText" numberOfLines={1} style={styles.locationText}>
            {a?.city && a?.state && a.postalCode ? [a.city, ', ', a.state, ' ', a.postalCode].join('') : ''}
          </Text>
          <Text testID="phoneText" numberOfLines={1} style={[styles.locationText, { fontFamily: FontFamily.LarsseitLight }]}>
            {a?.phone ?? ''}
          </Text>
        </View>
      </View>,
    ];
  }, [cart, t]);

  const changeShoppingOptions = useCallback(
    (page?: string) => {
      tombSet(page);
      navigation.dispatch(
        StackActions.replace(AppStackRoutes.CartAndCheckout, {
          screen: CheckoutStackRoutes.CartLanding,
          params: { storeNumber: undefined },
        }),
      );
    },
    [navigation, tombSet],
  );

  const TipSection = useCallback(
    (): JSX.Element => (
      <DropShadow key={'checkoutScreenDeliveryContainerKey'} style={[{ borderBottomWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 }]}>
        <View style={[styles.contentPad, utilityStyles.my3]}>
          <Text testID="deliveryTip" style={[appStyles.bodyBoldLarge]}>
            {t('deliveryTip')}
          </Text>
          <TouchableOpacity onPress={() => doOptionsPress('tip')}>
            <View style={[styles.flexrow, { alignItems: 'center' }]}>
              <FormField
                testID="checkoutScreenDeliveryTipKey"
                pointerEvents="none"
                onPressIn={() => true}
                key={'checkoutScreenDeliveryTipKey'}
                containerStyle={[styles.flexGrow]}
                hideLabel
                autoCapitalize="none"
                autoCorrect={false}
                value={getDeliveryTipValue(cart)}
                keyboardType="decimal-pad"
                name="deliveryTip"
                textContentType="none"
                returnKeyType="next"
                editable={false}
                formatter={formatValue}
              />
              <Text testID="editText" style={[appStyles.smallLink, utilityStyles.pl3]}>
                {t('edit')}
              </Text>
            </View>
          </TouchableOpacity>
          <Text style={[appStyles.bodySmallLeftRegular]}>{t('deliveryTipDescription', { tipDecimal: deliveryTipDefault })}</Text>
        </View>
      </DropShadow>
    ),
    [cart, deliveryTipDefault, doOptionsPress, t],
  );

  const DeliverySection = useCallback(
    (): JSX.Element => (
      <View key={'mainScrollViewViewDelivery'} style={styles.contentPad}>
        <View style={[utilityStyles.py3]}>
          <View style={[styles.flexcolumn]}>
            <FormField
              testID="deliveryInstructions"
              label={t('deliveryInstructions')}
              bottomRight={t('wordLimit')}
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="ascii-capable"
              name="delivery_instructions"
              onChange={(e) => _setFulfillmentInstructions(e.nativeEvent.text)}
              textContentType="name"
              multiline={true}
              fieldStyleEx={[{ height: scale(defaultFontSize * 6) }]}
              maxLength={250}
              formatter={formatEmoji}
            />

            <TouchableOpacity
              style={[styles.biggerHitArea]}
              onPress={() =>
                setModalData({
                  location: 'top',
                  title: t('deliveryInstructions'),
                  description: <Text>{t('deliveryInstructionsDescription')}</Text>,
                  showModal: true,
                })
              }>
              <Icon testID="mainScrollViewQuestionCircleIcon" name="question-circle" size={20} style={appStyles.mediumIcon} />
            </TouchableOpacity>
          </View>
          <KeyboardCloseButton />
          <View key={`OrderNoteSave_${cart?.id}`} style={[{ flexDirection: 'row', height: 45 }, utilityStyles.my1]}>
            <SubmitButton
              type={'secondary'}
              size={'small'}
              buttonStyle={{ position: 'absolute', top: -20 }}
              title={t('saveDeliveryInstructions')}
              testID="OrderNoteSaveSubmit"
            />
          </View>
          {/* Delivery Location */}
          <View style={[styles.flexrow, { paddingTop: 13 }]}>
            <FormLabel style={[]}>{t('deliveryDateTime')}</FormLabel>
          </View>
          <View style={[styles.infoPad, { paddingTop: 13 }]}>{renderLocation()}</View>
          <LinkButton style={[appStyles.smallLink, utilityStyles.pt1, { alignSelf: 'flex-end' }]} onPress={() => changeShoppingOptions('TimeSlot')}>
            {t('edit')}
          </LinkButton>
        </View>
      </View>
    ),
    [_setFulfillmentInstructions, cart?.id, changeShoppingOptions, renderLocation, t],
  );

  const PickupSection = useCallback(
    (): JSX.Element => (
      <View key={'renderLocation_pickup1'} style={[styles.contentPad]}>
        <View style={[utilityStyles.py3]}>
          <View style={[styles.flexcolumn, {}]}>
            <FormField
              testID="designatedPickupPerson"
              label={t('designatedPickupPerson')}
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="ascii-capable"
              name="pickup_person"
              onChange={(e) => _setPickupPerson(e.nativeEvent.text)}
              textContentType="name"
              returnKeyType="done"
              formatter={formatName}
            />
            <TouchableOpacity
              style={[styles.biggerHitArea]}
              onPress={() =>
                showAlertModal({
                  title: t('designatedPickupPerson'),
                  message: t('designatedPickupPersonInfo'),
                })
              }>
              <Icon testID="renderLocationQuestionCircle" name="question-circle" size={20} style={appStyles.mediumIcon} />
            </TouchableOpacity>
          </View>

          <View style={[styles.flexrow, { paddingTop: 13 }]}>
            <FormLabel testID="pickupDateTime" style={[]}>
              {t('pickupDateTime')}
            </FormLabel>
          </View>
          <View style={[styles.infoPad, { paddingTop: 13 }]}>{renderLocation()}</View>
          <LinkButton style={[appStyles.smallLink, utilityStyles.pt1, { alignSelf: 'flex-end' }]} onPress={() => changeShoppingOptions('TimeSlot')}>
            {t('edit')}
          </LinkButton>
        </View>
      </View>
    ),
    [_setPickupPerson, changeShoppingOptions, renderLocation, showAlertModal, t],
  );

  useEffect(() => {
    if (cart) {
      _setDeliveryTip();
    }
  }, [cart, _setDeliveryTip]);

  useEffect(() => {
    if (cart) {
      // Choose the default paymment profile
      let selectedPaymentMethodId = getSelectedPayment(cart);
      let setPp: number = selectedPaymentMethodId ? selectedPaymentMethodId : 0;
      let pp: PaymentProfile | undefined;
      if (typeof selectedPaymentMethodId !== 'undefined' && selectedPaymentMethodId !== 0) {
        const selectedPaymentMethod = paymentProfiles?.find((p) => p.CustomerPaymentMethodId === selectedPaymentMethodId);
        if (!selectedPaymentMethod) {
          selectedPaymentMethodId = undefined;
        } else {
          if (selectedPaymentMethod && isCardExpired(selectedPaymentMethod)) {
            pp = paymentProfiles?.find((p) => !isCardExpired(p));
            if (pp) {
              setPp = +(pp.CustomerPaymentMethodId ?? 0);
            }
          }
        }
      }
      if (typeof selectedPaymentMethodId === 'undefined') {
        pp = paymentProfiles?.find((p) => p.IsDefault === true);
        if (pp && isCardExpired(pp)) {
          pp = paymentProfiles?.find((p) => !isCardExpired(p));
        }
        if (pp) {
          setPp = +(pp.CustomerPaymentMethodId ?? 0);
        }
      }

      const isDel = isDelivery(cart);
      let canPp = !isDel;
      if (!canPp || cart?.lineItems.some((item) => Boolean(getProductAttributeValue('isCateringManagerItem', item.variant?.attributesRaw ?? []) ?? false))) {
        // In case of categring item on cart, Pay at Pickup is not availale so switch back to default payment profile.
        canPp = false;
        if (setPp === 0) {
          pp = paymentProfiles?.find((p) => p.IsDefault === true);
          if (pp && isCardExpired(pp)) {
            pp = paymentProfiles?.find((p) => !isCardExpired(p));
          }
          if (pp) {
            setPp = +(pp.CustomerPaymentMethodId ?? 0);
          } else {
            setPp = 0;
          }
        }
      }
      setCanPayAtPickup(canPp);
      setPaymentProfile(setPp, getOfflinePayment(cart));
    }
  }, [cart, paymentProfiles, setPaymentProfile]);

  // payment section
  const PaymentSection = useCallback((): JSX.Element => {
    // payment functions
    const handlePaymentSelect = async (item: PaymentProfile) => {
      setOrdering(true);
      if (item.CustomerPaymentMethodId && !isCardExpired(item)) {
        setPaymentProfile(+item.CustomerPaymentMethodId);
      }
      setOrdering(false);
    };

    const payAtPickup = cart?.custom?.customFieldsRaw?.find((x) => x.name === 'offlinePaymentMethodType') ?? '';
    const payAtPickupDescription = payAtPickup ? t(payAtPickup.value) : t('payAtPickupDescription');

    const selectedPaymentMethodId = getSelectedPayment(cart);
    return (
      <View key={'renderpayment_1'} style={[styles.contentPad, utilityStyles.my1]}>
        <View style={utilityStyles.mt3}>
          {!isDelivery(cart) || paymentProfiles?.length ? (
            <>
              {paymentProfiles?.map((i, index) => {
                const item = { ...i };
                return (
                  <WalletItem
                    onPress={handlePaymentSelect}
                    item={item}
                    key={item.CustomerPaymentMethodId}
                    onEdit={handleEditPaymentMethod}
                    index={index + 1}
                    onRemove={handleRemove}
                    onSetDefault={!item.IsDefault ? handleSetDefault : undefined}
                    checkoutPaymentMethod={selectedPaymentMethodId === item.CustomerPaymentMethodId}
                  />
                );
              })}
              {isDelivery(cart) ? null : (
                <>
                  <WalletItem
                    readOnly={true}
                    index={0}
                    checkoutPaymentMethod={canPayAtPickup && selectedPaymentMethodId === 0}
                    onPress={() => canPayAtPickup && doOptionsPress('ptype')}
                    item={
                      {
                        id: 'payAtPickup',
                        CardNumber: t('payAtPickup'),
                        CustomerPaymentMethodId: 0,
                        CardType: PAY_AT_PICKUP,
                        CardExpirationDate: !canPayAtPickup ? t('noPayAtPickup', { ns: 'checkout' }) : payAtPickupDescription,
                        EmailAddress: userProfile?.email,
                        PaymentMethodId: 0,
                      } as PaymentProfile
                    }
                    key={'payAtPickupKey'}
                  />
                </>
              )}
            </>
          ) : (
            <Text testID="noPaymentMethod" style={[appStyles.bodyLeftBold, utilityStyles.mt5, utilityStyles.p5]}>
              You have no saved payment methods on your account.
            </Text>
          )}
          {/* Add New */}
          <View style={[utilityStyles.my0]}>
            <TouchableOpacity style={[styles.flexrow, { height: 50, alignSelf: 'center' }]} onPress={handleAddPaynmentMethod}>
              <Icon
                testID="addPaymentMethod"
                name={'circle-plus'}
                size={35}
                stroke={colors.red}
                strokeSecondary={colors.sectionBorder}
                fill={colors.white}
                style={[appStyles.largeIcon, { alignItems: 'flex-end' }]}
              />
              <FormLabel style={{ flex: 0, padding: 10 }}>Add Payment Method</FormLabel>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }, [canPayAtPickup, cart, doOptionsPress, handleAddPaynmentMethod, handleEditPaymentMethod, paymentProfiles, setPaymentProfile, t, userProfile?.email]);

  // payment section
  const SEDollarsSection = useCallback(
    (): JSX.Element => (
      <View key={'rendersedollars_1'} style={[styles.contentPad, utilityStyles.my1]}>
        <View style={utilityStyles.mt3}>
          <SEDollars activatedText={'Activated'} activatedTextStyle={appStyles.bodyXsBoldMediumGrey} noRewardMessageType={'cart'} />
        </View>
      </View>
    ),
    [],
  );

  let fffValue = 'Other'; // default display value for Other amount
  let fffSelected = ''; // which is the selected option, '' is none
  const fffAttr = getDonationAmountMoney(cart);
  if (fffAttr) {
    // if we have a value, try to match it
    switch (fffAttr.centAmount) {
      case 100:
        fffSelected = '1';
        break;
      case 500:
        fffSelected = '5';
        break;
      case 1000:
        fffSelected = '10';
        break;
      case 0:
        break;
      default:
        fffValue = getDonationAmountValue(cart);
        fffSelected = 'other';
    }
  }
  // maintainVisibleContentPosition={{ autoscrollToTopThreshold:1, minIndexForVisible: 0 }}
  let itemCount = <></>;
  return (
    <Screen>
      <NavigationHeader titleStyle={{ top: 5 }} title={t('checkOut')} next={itemCount}>
        <TouchableOpacity
          onPress={() => navigation.navigate(CheckoutStackRoutes.OrderSummary)}
          style={[{ flexDirection: 'row', alignSelf: 'center' }, utilityStyles.my3]}>
          <Text testID="viewOrderDetailsText" style={[appStyles.body, { textDecorationLine: 'underline' }]}>
            {t('viewOrderDetails')}
          </Text>
          <Text testID="taxedGrossValueText" numberOfLines={1} style={[appStyles.body, utilityStyles.px2, { alignSelf: 'flex-end' }]}>
            {taxedGrossValue(cart) ?? ''}
          </Text>

          <Icon style={[{ alignSelf: 'flex-end' }]} name="arrow-right" size={12} stroke={colors.darkText} />
        </TouchableOpacity>
      </NavigationHeader>

      <View style={[styles.mainContainer]} key={'checkoutScreenMainContainerKey'}>
        <ScrollView ref={(ref) => setMainRef(ref)} key={'checkoutScrollViewKey'} contentContainerStyle={[styles.scrollViewContent, contentBottom]}>
          <CartInfoBanner onTitlePress={() => null} />
          <View key={'mainScrollViewView'} onLayout={(e) => setMainScrollViewY(e.nativeEvent.layout.y)} style={{}}>
            <Form
              innerRef={formRef}
              key={'checkoutScreenFormKey'}
              enableReinitialize={true}
              initialValues={{
                firstName: formatName(customerContact?.firstName ?? ''),
                lastName: formatName(customerContact?.lastName ?? ''),
                pickup_person: formatName(getPickupPersonName(cart, '') ?? ''),
                promoCode: getPromoCodeList()?.join(', '),
                phone: formatPhone(customerContact?.phone),
                allowText: customerContact?.allowText ?? true,
                delivery_instructions: formatName(getFulfillmentInstructions(cart, '') ?? ''),
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}>
              <ErrorMessage error="Please complete all fields." visible={checkoutFailed} />
              <View key={'checkoutScreenFormContentKey'} style={[]}>
                {/* contact */}
                <Accordion
                  key={'checkoutScreenContactContainerKey'}
                  iconStyle={[appStyles.largeIcon]}
                  setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, contact: isOpen })}
                  title={t('contactTabName')}
                  isOpen={dropDownState.contact}>
                  {ContactSection()}
                </Accordion>

                {/* location */}
                <Accordion
                  key={'checkoutScreenLocationContainerKey'}
                  iconStyle={[appStyles.largeIcon]}
                  setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, pickup: isOpen })}
                  title={isDelivery(cart) ? t('deliveryTab') : t('pickupTab')}
                  isOpen={dropDownState.pickup}>
                  {isDelivery(cart) ? DeliverySection() : PickupSection()}
                </Accordion>

                {/* payment */}
                <View key={'postPaymentMarker'} onLayout={(e) => setPaymentMarkerY(e.nativeEvent.layout.y)} />
                <Accordion
                  key={'checkoutScreenPaymentContainerKey'}
                  style={{}}
                  iconStyle={[appStyles.largeIcon]}
                  setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, payment: isOpen })}
                  title={'Payment'}
                  isOpen={dropDownState.payment}>
                  {PaymentSection()}
                </Accordion>

                {/* SE Dollars  */}
                {availableVouchers?.length || acceptedVouchers?.length ? (
                  <Accordion
                    key={'checkoutScreenSEDollarsContainerKey'}
                    style={{}}
                    iconStyle={[appStyles.largeIcon]}
                    setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, sedollars: isOpen })}
                    title={'SE Dollars'}
                    isOpen={dropDownState.sedollars}>
                    {SEDollarsSection()}
                  </Accordion>
                ) : (
                  <></>
                )}

                {/* Promo Code Section */}
                <View
                  key={'checkoutScreenPromoContainerKey'}
                  style={{ borderBottomWidth: 1, borderTopWidth: dropDownState?.payment ? 1 : 0, borderColor: colors.sectionBorder }}>
                  <View style={[styles.contentPad, utilityStyles.my3]}>
                    <Text testID="promoCodeText" style={[appStyles.fontMobileH5, utilityStyles.my3]}>
                      {t('promoCode')}
                    </Text>
                    {promoCodeList}
                    <LinkButton style={[appStyles.smallLink, utilityStyles.pt2]} onPress={() => doOptionsPress('promo')}>
                      {t('addPromoCode')}
                    </LinkButton>
                  </View>
                </View>

                {/* tip */}
                {isDelivery(cart) && TipSection()}

                {/* fff Section */}
                <View key={'checkoutScreenFFFContainerKey'} style={[{ borderBottomWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 }]}>
                  <View style={[styles.contentPad, utilityStyles.mt1, { flexDirection: 'row' }]}>
                    <Image
                      testID="checkoutScreenImage"
                      resizeMode="contain"
                      style={[styles.fffImage]}
                      source={{
                        uri: fffJpgUrl,
                      }}
                    />
                    <Text testID="checkoutScreenText" style={[appStyles.bodyBoldLarge, styles.dontationText]}>
                      {t('fffHeader')}
                    </Text>
                  </View>
                  <View style={[styles.contentPad, utilityStyles.mb2]}>
                    <Text testID="authSeeMoreText" style={[appStyles.bodySmallLight, utilityStyles.my1]}>
                      {t('fffDescription')}&nbsp;&nbsp;
                      {!fffMore && (
                        <LinkButton style={[appStyles.smallLink]} onPress={() => setFffMore(!fffMore)}>
                          {t('authSeeMore')}
                        </LinkButton>
                      )}
                    </Text>
                    {fffDescriptionMore && fffMore && (
                      <Text testID="authSeeLessText" style={[appStyles.bodySmallLight, utilityStyles.my1]}>
                        {fffDescriptionMore}&nbsp;&nbsp;
                        <LinkButton style={[appStyles.smallLink]} onPress={() => setFffMore(!fffMore)}>
                          {t('authSeeLess')}
                        </LinkButton>
                      </Text>
                    )}
                    <View style={[styles.contentPad, styles.flexrow, utilityStyles.my1, { justifyContent: 'space-evenly' }]}>
                      <Button
                        testID="setDonation1"
                        type={'secondary'}
                        size={'small'}
                        title={'$1'}
                        buttonStyle={[styles.dollarButton, fffSelected === '1' ? styles.fffSelected : {}]}
                        textStyle={[fffSelected === '1' ? styles.fffSelected : {}]}
                        onPress={() => (fffSelected === '1' ? setDonationAmount('0') : setDonationAmount('1.00'))}
                      />
                      <Button
                        testID="setDonation5"
                        type={'secondary'}
                        size={'small'}
                        title={'$5'}
                        buttonStyle={[styles.dollarButton, fffSelected === '5' ? styles.fffSelected : {}]}
                        textStyle={[fffSelected === '5' ? styles.fffSelected : {}]}
                        onPress={() => (fffSelected === '5' ? setDonationAmount('0') : setDonationAmount('5.00'))}
                      />
                      <Button
                        testID="setDonation10"
                        type={'secondary'}
                        size={'small'}
                        title={'$10'}
                        buttonStyle={[styles.dollarButton, fffSelected === '10' ? styles.fffSelected : {}]}
                        textStyle={[fffSelected === '10' ? styles.fffSelected : {}]}
                        onPress={() => (fffSelected === '10' ? setDonationAmount('0') : setDonationAmount('10.00'))}
                      />

                      <View style={[styles.flexrow, { justifyContent: 'space-evenly', alignContent: 'center' }]}>
                        <TouchableOpacity onPress={() => doOptionsPress('fff')} style={{ flexDirection: 'row' }}>
                          <Button
                            testID="appllyOptionsScreenButton"
                            type={'secondary'}
                            size={'small'}
                            title={fffValue}
                            buttonStyle={[styles.dollarButton, fffSelected === 'other' ? styles.fffSelected : {}]}
                            textStyle={[fffSelected === 'other' ? styles.fffSelected : {}]}
                            onPress={() => (fffSelected === 'other' ? setDonationAmount('0') : doOptionsPress('fff'))}
                          />
                          <Text style={[appStyles.smallLink, utilityStyles.pl2, { alignSelf: 'center' }]}>{t('edit')}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>

                {/* Payment Disclaimer and Invoice */}
                <DropShadow key={'checkout_Disclaimer_section'} style={[{ borderBottomWidth: 1, borderColor: colors.sectionBorder, shadowOpacity: 0 }]}>
                  <View style={[styles.contentPad, utilityStyles.mt3]}>
                    <Text testID="payDsiclaimerAuthMore" style={[appStyles.bodySmallLight, utilityStyles.my1]}>
                      {t('paymentDisclaimer', {
                        preauth_amount: preAuthValue(cart, getConfigValue<number>('OrderAuthAmountMultiplier') ?? 1.15, savingsOf, '0.00'),
                      })}
                      &nbsp;&nbsp;
                      {!showMoreText && (
                        <LinkButton style={[appStyles.smallLink]} onPress={() => setShowMoreText(!showMoreText)}>
                          {t('authSeeMore')}
                        </LinkButton>
                      )}
                    </Text>
                    {paymentDisclaimerMore && showMoreText && (
                      <Text testID="paymentDisclaimerAuthLess" style={[appStyles.bodySmallLight, utilityStyles.my1]}>
                        {paymentDisclaimerMore}&nbsp;&nbsp;
                        <LinkButton style={[appStyles.smallLink]} onPress={() => setShowMoreText(!showMoreText)}>
                          {t('authSeeLess')}
                        </LinkButton>
                      </Text>
                    )}
                  </View>
                  <Invoice obj={cart} backgroundColor={colors.cream} />
                  <TouchableOpacity
                    onPress={() => navigation.navigate(CheckoutStackRoutes.OrderSummary)}
                    style={[{ flexDirection: 'row', alignSelf: 'center' }, utilityStyles.mb3]}>
                    <Text testID="viewOrderDetails" style={[appStyles.body, { textDecorationLine: 'underline' }]}>
                      {t('viewOrderDetails')}
                    </Text>
                    <Text testID="taxedGrossValue" numberOfLines={1} style={[appStyles.body, utilityStyles.px2, { maxWidth: '30%', alignSelf: 'flex-end' }]}>
                      {taxedGrossValue(cart) ?? ''}
                    </Text>
                    <Icon testID="viewOrderArrowIcon" style={[{ alignSelf: 'flex-end' }]} name="arrow-right" size={12} stroke={colors.darkText} />
                  </TouchableOpacity>
                </DropShadow>

                {/* Terms of Use */}
                <DropShadow key={'checkout_TOU_section'} style={[{ shadowOpacity: 0 }]}>
                  <View key={'mainScrollViewView2'} style={[styles.termsSection, utilityStyles.py2]}>
                    <Text testID="checkoutTOUText" style={[appStyles.bodySmallLight]}>
                      By placing your order, you agree to our
                    </Text>
                    <View style={[styles.flexrow, { alignItems: 'center' }]}>
                      <View>
                        <WebBrowserLink href={routes.TERMS_OF_USE} style={[appStyles.footerLink]}>
                          Terms of Use
                        </WebBrowserLink>
                      </View>
                      <Text testID="andText" style={[appStyles.bodySmallLight, utilityStyles.mx1]}>
                        and
                      </Text>
                      <View>
                        <WebBrowserLink href={routes.PRIVACY_POLICY} style={[appStyles.footerLink]}>
                          Privacy Policy
                        </WebBrowserLink>
                      </View>
                    </View>
                  </View>
                </DropShadow>
              </View>
            </Form>
          </View>
        </ScrollView>
      </View>
      <BottomBar onLayout={onButtonLayout}>
        <Button
          testID="placeOrderButton"
          size={'small'}
          buttonStyle={[appStyles.cartBottomButton]}
          textStyle={cart?.lineItems?.length ?? 0 ? {} : { opacity: 0.3 }}
          onPress={() => placeOrder()}
          disabled={loading || isOrdering || isBusy || !(cart?.lineItems?.length ?? 0) || !formRef?.current?.isValid}
          title={`${t('placeOrder')}`}
          isButtonLoading={(!!transitCart ?? false) || loading || isOrdering || /*vouchersState === 'loading' ||*/ isBusy}
        />
      </BottomBar>
      <Modal
        location={modalData.location ?? 'top'}
        title={modalData.title}
        visible={modalData.showModal}
        cancelButtonOnPress={() => setModalData({ ...modalData, showModal: false })}>
        {modalData.description ? (
          <Text style={[appStyles.fontMobileBodySmallRegular]}>{modalData.description}</Text>
        ) : (
          <Text>This feature is planned for a future version and is not yet available.</Text>
        )}
      </Modal>
      {/* Cart Options Modal */}
      <Modal
        visible={showCartOptions !== ''}
        title={
          showCartOptions === 'tip'
            ? 'Delivery Tip'
            : showCartOptions === 'ptype'
              ? 'Pay at Pickup Options'
              : showCartOptions === 'promo'
                ? 'Add Promo Code'
                : 'Donation Options'
        }
        titleStyle={{}}
        showCancel={false}
        showCloseIcon={false}
        cancelButtonOnPress={handleOptionsCancelPress}
        buttonContainerStyle={{ marginTop: 0, marginBottom: 0, borderTopWidth: 1, backgroundColor: colors.white, borderColor: colors.navBorder }}
        cancelButtonType="secondary"
        cancelButtonText=""
        okButtonText="Apply"
        okButtonType="primary"
        okButtonStyle={[appStyles.cartBottomButton]}
        okButtonOnPress={handleOptionsApplyPress}
        style={[showCartOptions === 'tip' ? styles.tipOptionsModalHeight : styles.paymentOptionsModalHeight, { backgroundColor: colors.cream }]}
        subTextViewStyle={{}}>
        {cartOptionsData}
      </Modal>

      {showDeleteModal && (
        <Modal
          visible={showDeleteModal}
          title={'Remove Payment Method?'}
          location="top"
          cancelButtonOnPress={() => setShowDeleteModal(false)}
          okButtonOnPress={handleRemovePaymentMethod}
          cancelButtonText="Go back"
          okButtonText="Remove">
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="removeMsg">
            Please confirm you'd like to remove this Payment Method from your Wallet.
          </Text>
        </Modal>
      )}
      {showSetDefaultModal && (
        <Modal
          visible={showSetDefaultModal}
          title={'Set as Default Payment Method?'}
          location="top"
          cancelButtonOnPress={() => setShowSetDefaultModal(false)}
          okButtonOnPress={handleSetDefaultPayment}
          cancelButtonText="Go back"
          okButtonText="Update">
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="setDefaultMsg">
            Please confirm you'd like to set this Payment Method as default.
          </Text>
        </Modal>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  modalButton: {
    width: 150,
  },
  paymentOptionsModalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.4 : screenHeight * 0.5) : screenHeight > 640 ? screenHeight * 0.4 : screenHeight * 0.5,
  },
  tipOptionsModalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.6 : screenHeight * 0.65) : screenHeight > 640 ? screenHeight * 0.55 : screenHeight * 0.65,
  },
  screen: {
    flex: 1,
    flexDirection: 'column',
    width: screenWidth,
    height: screenHeight,
  },
  mainContainer: {
    paddingTop: 0,
    flex: 1,
  },
  scrollViewContent: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.cream,
  },
  biggerHitArea: {
    width: 32,
    paddingTop: 12,
    paddingLeft: 18,
    alignItems: 'center',
    position: 'absolute',
    right: 0,
  },
  buttonView: {
    backgroundColor: colors.white,
    width: '100%',
    borderTopWidth: 1,
    borderTopColor: colors.sectionBorder,
    paddingTop: 8,
  },
  helpLinks: {},
  contentPad: {
    flexDirection: 'column',
    width: containerWidth,
    alignSelf: 'center',
    paddingBottom: 0,
  },
  fffImage: {
    minWidth: 100,
    flex: 1,
    aspectRatio: 1,
    alignSelf: 'center',
  },
  textAlignCenter: { textAlign: 'center' },
  dontationText: { textAlign: 'center', alignSelf: 'center', width: '75%' },
  flex1: { flex: 1 },
  flex0: { flex: 0 },
  flexGrow: { flexGrow: 1 },
  flexrow: { flexDirection: 'row' },
  flexcolumn: { flexDirection: 'column' },
  marginLeftAuto: { marginLeft: 'auto' },
  marginTopAuto: { marginTop: 'auto' },
  infoPadEnabled: { borderColor: colors.enabledOutline },
  infoPadSelected: { borderColor: colors.selectedOutline, borderWidth: 2 },
  infoPad: {
    backgroundColor: colors.white,
    padding: scale(18),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    minHeight: scale(90),
  },
  termsSection: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  promoCodePad: {
    backgroundColor: colors.white,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    borderRadius: 10,
  },

  infoPadLight: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
  },
  infoPadSeparator: {
    height: 1,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'stretch',
    margin: scale(3),
  },
  helpLinkSeparators: {
    backgroundColor: colors.sectionBorder,
    alignSelf: 'stretch',
    width: 1,
    flex: 0,
  },
  productImage: {
    width: 72,
    height: 72,
    borderWidth: 1,
    borderColor: colors.medium,
  },
  headSeparator: {
    flex: 1,
    height: 1,
    backgroundColor: colors.medium,
    marginTop: 15,
    marginBottom: 2,
  },
  dollarButton: {
    minWidth: 65,
  },
  dollarButtonOther: {
    minWidth: 85,
    paddingBottom: 4,
  },
  location: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  locationAddress: {
    alignItems: 'flex-start',
    flex: 1,
  },
  locationInfo: {
    paddingRight: 6,
    width: '60%',
  },
  locationText: {
    fontSize: scale(16),
    color: colors.darkText,
    lineHeight: lineHeight(18),
    fontFamily: FontFamily.LarsseitBold,
  },
  promoCodeX: {
    transform: [{ rotate: '45deg' }],
    marginLeft: 'auto',
    paddingLeft: 10,
    fontSize: scale(28),
    color: colors.darkText,
    lineHeight: lineHeight(28),
    fontFamily: FontFamily.Larsseit,
    marginTop: -10,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    paddingRight: 10,
  },
  homeIcon: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentInfo: {
    ...utilityStyles.mt4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 0,
    marginBottom: Platform.OS === 'android' ? 60 : 0,
  },
  title: {
    alignSelf: 'flex-start',
    marginLeft: 20,
    marginBottom: 15,
    marginTop: 25,
  },
  fffSelected: {
    backgroundColor: colors.red,
    color: colors.white,
  },
  modalContentStyle: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(25),
    textAlign: 'center',
    width: screenWidth * 0.6,
  },
  radioRow: {
    width: '100%',
    flexDirection: 'row',
    height: lineHeight(24),
    alignItems: 'center',
  },
  alertBox: {
    bottom:
      screenHeight < 736
        ? screenHeight * 0.225
        : screenHeight > 812
          ? screenHeight > 896
            ? screenHeight * 0.21
            : screenHeight * 0.215
          : screenHeight < 740
            ? screenHeight * 0.225
            : screenHeight * 0.225,
  },
});

export default CheckoutScreen;

import { LoadingScreen, Switch, TextInput } from '@components';
import CustomizeFooter from '@components/CustomizeFooter';
import Text from '@components/Text';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { CustomizeStackRoutes, CustomizeStackScreenProps } from '@navigation/routes';
import { productService } from '@services/commerceTools';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import { filterUnavailableProducts } from '@utils/helpers';
import React, { FC, useEffect, useState } from 'react';
import { Dimensions, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
const { height } = Dimensions.get('window');
const COLOR_SIZE = 30;
interface MessageScreenProps extends CustomizeStackScreenProps<CustomizeStackRoutes.Message> {}

const MessageScreen: FC<MessageScreenProps> = ({ route, navigation }): JSX.Element => {
  const { catIds } = route.params;
  const [noMessage, setNoMessage] = useState(false);
  const [color, setColor] = useState('White');
  const { cakeMessage, setCakeMessage, currStep, selectedSize, pageItem } = useCustomizeStore();
  const MAX_LENGTH = selectedSize.itemName.includes('Full') ? 120 : selectedSize.itemName.includes('Half') ? 80 : 45;
  const [charsLeft, setCharsLeft] = useState(0);
  // const [choices, setChoices] = useState<Product[]>([]);
  const {
    data: choices,
    status,
    refetch,
  } = useQuery('message-selections-list', () => productService.getProducts({ catId: catIds }), {
    staleTime: 0,
    cacheTime: 0,
    initialData: undefined,
    select: (d) => {
      d?.sort(
        (a, b) =>
          +(a?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catIds[0])?.orderHint || 0.8) -
          +(b?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === catIds[0])?.orderHint || 0.8),
      );
      return filterUnavailableProducts(d);
    },
  });
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  useEffect(() => {
    if (status === 'success') {
      setIsLoadingProducts(false);
    } else {
      setIsLoadingProducts(true);
    }
  }, [choices, status]);

  const colorOptions = [...Array(choices?.length ?? 0)].map((x, i) => {
    return choices[i]?.masterData?.current?.name;
  });
  // colorOptions.pop();
  const nmIndex = colorOptions.findIndex((c) => c === 'No Message');
  colorOptions.splice(nmIndex, 1);
  // colorOptions.shift();

  useEffect(() => {
    navigation.setParams({ forwardNavEnabled: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currStep]);

  const setMessageColor = (option: string) => {
    // console.log(JSON.stringify(choices[i + 1], null, 3));
    const colorData = choices?.find((c) => c?.masterData?.current?.name?.trim() === option);
    setColor(option);
    setCakeMessage({
      ...cakeMessage,
      color: option,
      id: colorData?.id ?? '',
      cost:
        colorData?.masterData?.current?.masterVariant?.price?.value.centAmount /
        10 ** (colorData?.masterData?.current?.masterVariant?.price?.value?.fractionDigits ?? 0),
      sku: colorData?.key ?? '',
      sortOrderHint: `${currStep}|Message`,
      product: colorData,
    });
  };

  useEffect(() => {
    if (noMessage) {
      setCakeMessage({ message: '', color: '', id: '', cost: '', sku: '', sortOrderHint: `${currStep}|Message`, product: undefined });
    } else {
      if (!cakeMessage?.sku) {
        setMessageColor('White');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noMessage]);

  useEffect(() => {
    refetch();
    setSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageItem]);

  const setSelected = () => {
    if (cakeMessage?.sku) {
      let msg = '';
      if (selectedSize.itemName.includes('Full')) {
        msg = cakeMessage?.message;
      } else if (selectedSize.itemName.includes('Half')) {
        msg = cakeMessage?.message.slice(0, 80);
      } else {
        msg = cakeMessage?.message.slice(0, 45);
      }
      setMessageColor(cakeMessage?.color);
      setCakeMessage({ ...cakeMessage, message: msg });
      setCharsLeft(msg.length);
    } else {
      setMessageColor('White');
      setCharsLeft(0);
    }
  };

  const ColorPicker = () => {
    return (
      <View style={styles.colorPickerContainer}>
        <Text testID="selectText" style={[styles.selectText, { fontFamily: FontFamily.LarsseitBold }]}>
          {color.replace(color[0], color[0].toUpperCase())}
        </Text>
        <View style={styles.colorOptionsWrapper}>
          {colorOptions.map((c, indx) => {
            return (
              <View key={indx.toString()} style={[styles.colorHighlight, color !== c ? { borderWidth: 0 } : null]}>
                <TouchableOpacity
                  onPress={() => setMessageColor(c)}
                  style={[styles.colorOption, { backgroundColor: appConstants.PICKER_COLORS.find((col) => col.name === c)?.color ?? colors.white }]}
                />
              </View>
            );
          })}
        </View>
        <Text testID="colorWarningTxt" style={[appStyles.bodySmallLight]}>
          Color may vary from what is shown on screen
        </Text>
      </View>
    );
  };

  return isLoadingProducts ? (
    <LoadingScreen />
  ) : (
    <View style={styles.container}>
      <ScrollView bounces={true} style={styles.body}>
        <View style={styles.bodySection}>
          <Text testID="addMessageOnCakeTxt" style={styles.selectText}>
            Add A Message On Your Cake
          </Text>
          <TextInput
            testID="cakeMessageTxtInput"
            onChangeText={(text) => {
              var msg = text.replace(/(\r\n|\n|\r)/gm, '');
              setNoMessage(false);
              setCakeMessage({ ...cakeMessage, message: msg.slice(0, MAX_LENGTH), product: cakeMessage?.product });
              setCharsLeft(msg.length);
            }}
            value={cakeMessage?.message?.slice(0, MAX_LENGTH)}
            style={styles.noteInput}
            maxLength={MAX_LENGTH}
            multiline
            numberOfLines={10}
            blurOnSubmit={true}
            returnKeyType={'done'}
            underlineColorAndroid="transparent"
            placeholder={'Enter message here (Example: Happy Birthday)'}
            placeholderTextColor="rgba(0,0,0,0.4)"
          />
          <Text testID="limitText" style={styles.limitText}>
            {charsLeft} / {MAX_LENGTH}
          </Text>
          <View style={styles.switchWrapper}>
            <Switch style={{ flex: 0 }} onChange={() => setNoMessage(!noMessage)} value={noMessage} />
            <Text
              testID="noMessage"
              style={[
                appStyles.h5L,
                { fontSize: scale(18), marginTop: 8, marginLeft: 10, fontFamily: noMessage ? FontFamily.LarsseitBold : FontFamily.Larsseit },
              ]}>
              No Message
            </Text>
          </View>
          {!noMessage && <ColorPicker />}
        </View>
      </ScrollView>
      <View style={styles.footerWrapper}>
        <CustomizeFooter disabled={!cakeMessage?.message?.length && !noMessage} showTotal={true} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: screenWidth,
    flex: 1,
    padding: 16,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
  },
  body: {
    height: '20%',
    width: screenWidth,
    paddingHorizontal: 20,
    paddingTop: Platform.OS === 'ios' ? (height > 740 ? height * 0.01 : height * 0.01) : height > 640 ? height * 0.01 : height * 0.01,
  },
  bodySection: {
    backgroundColor: colors.white,
    padding: 16,
    paddingTop: 32,
    borderRadius: 20,
    marginBottom: 50,
  },
  switchWrapper: {
    flexDirection: 'row',
    marginVertical: 32,
  },
  radioButton: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemText: {
    fontSize: scale(14),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 15,
  },
  selectText: {
    fontSize: scale(20.5),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
    marginBottom: 20,
  },
  pickLimitText: {
    fontSize: scale(13),
    color: 'rgba(0,0,0,0.4)',
  },
  noteInput: {
    backgroundColor: colors.white,
    width: '100%',
    borderRadius: 28,
    paddingHorizontal: 20,
    paddingTop: 14,
    paddingBottom: 20,
    lineHeight: 20,
    textAlignVertical: 'top',
    borderWidth: 1,
    borderColor: colors.darkCream,
    color: Platform.select({
      android: colors.black,
    }), //Note- Added for samsung android device is not showing text
  },
  limitText: {
    color: 'rgba(0,0,0,0.4)',
    fontSize: scale(13),
    alignSelf: 'flex-end',
    marginTop: 5,
  },
  colorPickerContainer: {
    width: '110%',
    alignSelf: 'center',
    height: 200,
    alignItems: 'center',
  },
  colorOptionsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  colorOption: {
    width: COLOR_SIZE,
    height: COLOR_SIZE,
    borderRadius: COLOR_SIZE / 2,
    marginHorizontal: 16,
    marginBottom: 32,
    borderWidth: 0.5,
    borderColor: colors.greyBorder,
  },
  colorHighlight: {
    width: COLOR_SIZE * 1.35,
    height: COLOR_SIZE * 1.35,
    borderRadius: (COLOR_SIZE * 1.35) / 2,
    marginHorizontal: 12,
    marginBottom: 32,
    borderWidth: 1.5,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 3.5,
    paddingRight: 0,
  },
  footerWrapper: {
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.5,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 32,
    marginBottom: -45,
  },
});

export default MessageScreen;

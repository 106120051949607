import colors from '@config/colors';
import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

const DropShadow: React.FC<ViewProps> = ({ children, style }) => {
  return <View style={[styles.shadow, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  shadow: {
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 1.5 },
    shadowOpacity: 0.2,
    shadowRadius: 1.5,
    elevation: 3,
    zIndex: 100,
    borderWidth: 0,
    backgroundColor: colors.cream,
  },
});
export default DropShadow;

import appConstants from '@config/appConstants';
import { getProductAttributeValue } from '@fieldera-raleys/client-commercetools';
import {
  BaseMoney,
  Category,
  CateringStatus,
  InventoryMode,
  LineItem,
  Maybe,
  Product,
  ProductCatalogData,
  ProductData,
  ProductSearch,
  ProductVariant,
  ProductVariantAvailability,
  RawCustomField,
  RawProductAttribute,
  ShoppingListLineItem,
} from '@fieldera-raleys/client-commercetools/schema';
import { ProductType } from '@fieldera-raleys/client-common';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { getCustomFieldValue } from '@fieldera-raleys/client-common/utils';
import { brsmService } from '@services';
import { productService } from '@services/commerceTools';
import useShopStore from '@store/shopStore';
import dayjs, { Dayjs } from 'dayjs';
import Config from 'react-native-config';
import { formatValue } from './helpers';
import logger from './logger';

const DEFAULT_QUANTITY: number = 999;

export type AvailablityStatus = 'OutOfStock' | 'Unavailable' | 'LowStock' | 'Available' | 'Discontinued';
export type AvailablityResult = {
  availability: AvailablityStatus;
  quantity?: number;
  availabilityDate?: dayjs.Dayjs;
  inventoryMode?: InventoryMode;
  productSku?: string;
};
type JsonObject = { [Key in string]?: JsonValue };
type JsonArray = JsonValue[];
type JsonPrimitive = string | number | boolean | null;
export type JsonValue = JsonPrimitive | JsonObject | JsonArray;

export type ProductPromotion = {
  flagHeadline: string;
  badgeType: string;
  autoApply: boolean;
  isAccepted: boolean;
  sku: string;
  extFlagTypeCode: string;
};

export interface ProductList {
  sku: string;
  product: Product;
  promotion: ProductPromotion;
}

export const getRootCategory = (categories?: Category[]) => {
  if (!categories || !categories.length) {
    return undefined;
  }
  const root = categories[0].ancestors.find((a) => !a.parent);
  return root ? categories[0].ancestors.find((r) => r.parent?.key === root?.key) : root;
};

export const getProductSellType = async (product?: Product): Promise<JsonValue | undefined> => {
  const defaultValue = { key: 'byEach', label: 'By Each' };
  return product?.masterData.current?.masterVariant.attributesRaw
    ? getProductAttributeValue('unitSellType', product?.masterData.current?.masterVariant.attributesRaw) ?? defaultValue
    : defaultValue;
};

export const getPriceBySellType = async (product: Product, sku?: string | null, qty?: number): Promise<number | undefined> => {
  let variant = product.masterData.current?.variants.find((x) => x.sku === sku);
  if (!variant) {
    variant = product.masterData.current?.masterVariant;
  }
  if (variant) {
    const price = variant.price?.value.centAmount / 10 ** (variant.price?.value.fractionDigits ?? 2);
    const unitsPerPackage = getProductAttributeValue('unitsPerPackage', variant.attributesRaw);
    const unitAvgBuyWeight = getProductAttributeValue('unitAverageBuyWeight', variant.attributesRaw);
    const sellType = getProductAttributeValue('unitSellType', variant.attributesRaw);
    // const unitBuyMinimum = getProductAttributeValue('unitBuyMinimum', variant.attributesRaw);
    // const unitBuyMaximum = getProductAttributeValue('unitBuyMaximum', variant.attributesRaw);
    if (sellType) {
      if (!qty) {
        throw 'Quantity Needed';
      }
      switch (sellType?.key) {
        case 'weightByEach':
          return price * unitAvgBuyWeight * (qty ?? 1);
        case 'byWeight':
          return price * (qty ?? +unitsPerPackage);
        default:
          return price * (qty ?? 1);
      }
    }
  }
  return product.masterData.current?.masterVariant.price?.value.centAmount / 10 ** (product.masterData.current?.masterVariant.price?.value.fractionDigits ?? 2);
};

export const getQuantityFromPriceMessage = (priceMessage: string): number => {
  let match = /(you\s)?save\s\$\d*\.\d{2}\s(on|for)\s\d+$/i.exec(priceMessage);

  if (match) {
    const messages = priceMessage.split(' ');
    const qty = Number(messages[messages.length - 1]);

    return qty;
  }

  return 1;
};

const getAvailablityStatus = (availableQuantity: number, cateringStatus?: CateringStatus): AvailablityStatus => {
  if (!cateringStatus) {
    return availableQuantity <= 0 ? 'OutOfStock' : availableQuantity >= +(Config.LOW_INVENTORY_THRESHOLD ?? 25) ? 'Available' : 'LowStock';
  }

  switch (cateringStatus) {
    case 'retired':
      return 'Unavailable';
    case 'closed':
    case 'pending':
      return 'OutOfStock';
    default:
      return availableQuantity <= 0 ? 'OutOfStock' : availableQuantity >= +(Config.LOW_INVENTORY_THRESHOLD ?? 25) ? 'Available' : 'LowStock';
  }
};

export const isAvailablePastDate = (product?: Product | LineItem | ShoppingListLineItem | ProductVariant, orderDate?: dayjs.Dayjs): boolean => {
  if (!product || !orderDate) {
    return false;
  }

  let endAvailableDate: dayjs.Dayjs | undefined;
  let val: string | number = 0;
  const variant =
    product.__typename === 'Product'
      ? product?.masterData?.current?.masterVariant
      : product.__typename === 'LineItem' || product.__typename === 'ShoppingListLineItem'
        ? (product.variant as ProductVariant)
        : (product as ProductVariant);

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'endAvailableOrderDate')?.value;
    if (!val) {
      // no end date
      return true;
    }
  }

  try {
    endAvailableDate = dayjs(val);
  } catch (e) {
    logger.error(['Failed to parse endAvailableOrderDate attribute:', val].join(' '));
  }

  if (endAvailableDate) {
    const diff = endAvailableDate.diff(orderDate, 'minutes');
    if (diff > 0) {
      return true;
    }
  }

  return false;
};

export const getEndAvailabilityDate = (product?: Product | LineItem | ShoppingListLineItem | ProductVariant): Dayjs | undefined => {
  if (!product) {
    return undefined;
  }
  let endDate: dayjs.Dayjs | undefined;
  let val: string | number = 0;
  const variant =
    product.__typename === 'Product'
      ? product.masterData?.current?.masterVariant
      : product.__typename === 'LineItem' || product.__typename === 'ShoppingListLineItem'
        ? (product.variant as ProductVariant)
        : (product as ProductVariant);

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'endAvailableOrderDate')?.value;
  }

  if (val) {
    try {
      endDate = dayjs(val);
    } catch (e) {
      logger.error(['Failed to parse startAvailableOrderDate attribute:', val].join(' '));
    }
  }

  return endDate;
};

export const getfulfillmentLeadTimeMinutes = (product?: Product | LineItem | ShoppingListLineItem | ProductVariant, storeLeadTime?: number): number => {
  const selectedStore = useShopStore.getState().selectedStore;
  if (!product) {
    return storeLeadTime ? storeLeadTime : +(selectedStore?.fulfillmentLeadTimeMinutes ?? Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES);
  }

  const now = dayjs();
  let availableDate: dayjs.Dayjs | undefined;
  let val: string | number = 0;
  const variant =
    product.__typename === 'Product'
      ? product?.masterData?.current?.masterVariant
      : product.__typename === 'LineItem' || product.__typename === 'ShoppingListLineItem'
        ? (product.variant as ProductVariant)
        : (product as ProductVariant);

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'fulfillmentLeadTimeMinutes')?.value;
  }

  let leadTime: number =
    (('productType' in product && product?.productType?.name) ?? ProductType.STANDARD) === ProductType.CONFIGURABLE
      ? Math.max(
          storeLeadTime ?? +(selectedStore?.fulfillmentLeadTimeMinutes ?? Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES),
          +(Config.DEFAULT_FULFILLMENT_CUSTOMIZABLE_LEAD_TIME_MINUTES ?? 1440),
        )
      : storeLeadTime ?? +(selectedStore?.fulfillmentLeadTimeMinutes ?? Config.DEFAULT_FULFILLMENT_LEAD_TIME_MINUTES);

  if (val) {
    try {
      leadTime = Number(val);
    } catch (e) {
      /* empty */
    }
  }

  if (variant) {
    val = variant.attributesRaw?.find((x) => x.name === 'startAvailableOrderDate')?.value;
  }

  if (val) {
    try {
      availableDate = dayjs(val);
    } catch (e) {
      logger.error(['Failed to parse startAvailableOrderDate attribute:', val].join(' '));
    }
  }
  if (availableDate) {
    const diff = availableDate.diff(now, 'minutes');
    if (diff > 0) {
      leadTime = diff;
    }
  }

  return leadTime;
};

export const getFormattedPrice = (price: BaseMoney, qty: number = 1): string => {
  // return formatValue(price.centAmount / (10 ** price.fractionDigits || 1) / qty);
  return price && formatValue(Math.round(price.centAmount / qty) / (10 ** price.fractionDigits || 1));
};

export const getFormattedUnitPerPackage = (upp: string, uom: string) => {
  return Number(upp).toFixed(2) + ' ' + uom;
};

export const getProductNameWithUPP = (productName: Maybe<string> | undefined, unitP: string, unitM: string) => {
  const maxlimit = 25;
  let pname = productName ?? '';
  if (pname.length > maxlimit) {
    return pname.substring(0, maxlimit - 3) + '...  ' + parseFloat(unitP) + ' ' + unitM;
  } else {
    return pname + ' ' + parseFloat(unitP) + ' ' + unitM;
  }
};

export const getProductsfromCommerceTools = async (skus: string[]): Promise<Product[]> => {
  skus = skus.filter((s) => s !== undefined);
  if (!skus.length) {
    return Promise.resolve([]);
  }
  const promiseArray: Promise<Product[]>[] = [];
  const pageSize = 50;
  for (var x = 0; x < skus.length; x += pageSize) {
    const search: ProductSearch = { skus: skus.slice(x, x + pageSize), limit: pageSize };
    promiseArray.push(productService.getProducts(search));
  }
  return (await Promise.all(promiseArray)).flat(1);
};

export const getProductsfromCommerceToolsWithHistory = async (skus: string[]): Promise<Product[]> => {
  skus = skus.filter((s) => s !== undefined);
  if (!skus.length) {
    return Promise.resolve([]);
  }
  const promiseArrayProducts: Promise<Product[]>[] = [];
  const promiseArrayPrevPurchased: Promise<String[]>[] = [];

  const pageSize = 50;

  for (var x = 0; x < skus.length; x += pageSize) {
    const search: ProductSearch = { skus: skus.slice(x, x + pageSize), limit: pageSize };
    promiseArrayProducts.push(productService.getProducts(search));
    promiseArrayPrevPurchased.push(brsmService.getPreviouslyPurchasedProducts(skus.slice(x, x + pageSize)).catch((ex) => ex));
  }

  let products = (await Promise.all(promiseArrayProducts)).flat(1);
  const previouslyPurchasedFlags = (await Promise.all(promiseArrayPrevPurchased)).flat(1);

  if (products) {
    products = products.reduce((result, p) => {
      if (p.masterData.current) {
        const previouslyPurchasedFlag = previouslyPurchasedFlags.includes(p.masterData.current.masterVariant?.sku ?? '');

        if (previouslyPurchasedFlag) {
          const attributesRaw = [...p.masterData.current.masterVariant.attributesRaw] ?? [];

          attributesRaw.push({
            name: 'previouslyPurchased',
            value: previouslyPurchasedFlag,
            referencedResourceSet: [],
          });

          p.masterData.current.masterVariant.attributesRaw = attributesRaw;
        }

        result.push(p);
      }
      return result;
    }, [] as Product[]);
  }

  return products;
};

export const getProductAisleBayBin = (product: ProductData | LineItem | ShoppingListLineItem) => {
  var pricingAttributes: RawCustomField[] | undefined;
  if ((<ProductData>(<unknown>product)).masterVariant) {
    pricingAttributes = (<ProductData>(<unknown>product)).masterVariant?.price?.custom?.customFieldsRaw ?? undefined;
  }
  if ((<LineItem>(<unknown>product)).variant) {
    pricingAttributes = (<LineItem>(<unknown>product))?.variant?.price?.custom?.customFieldsRaw ?? undefined;
  }
  return pricingAttributes?.find((f) => f.name === 'areaBayShelfBin')?.value ?? undefined;
};

const findProductVariant = (sku: string, product?: ProductCatalogData): ProductVariant | undefined => {
  if (!product) {
    return undefined;
  }

  if (product.current?.masterVariant.sku === sku) {
    return product.current?.masterVariant;
  } else {
    return product.current?.variants.find((p) => p.sku === sku);
  }
};

export const getProductAvailablity = (product?: ProductCatalogData | null | ShoppingListLineItem, sku?: string): AvailablityResult => {
  var result: AvailablityResult = {
    availability: 'Unavailable',
    productSku: sku,
  };

  if (!product) {
    return result;
  }
  if ('current' in product && !product.published) {
    return result;
  }

  var inventoryMode: InventoryMode = InventoryMode.None;
  var discontinued: boolean = false;
  var availability: ProductVariantAvailability | undefined;
  var isCateringManagerItem: boolean | undefined;
  var cateringStatus: CateringStatus = 'open';
  var hasPriceChannel: boolean = false;
  var priceCustomFields: RawCustomField[] = [];
  var priceChannelCustomFields: RawCustomField[] = [];
  var productAttributes: RawProductAttribute[] = [];

  if ('current' in product) {
    const variant = sku ? findProductVariant(sku, product) : product.current?.masterVariant;
    hasPriceChannel = !!variant?.price?.channel ?? false;
    priceCustomFields = variant?.price?.custom?.customFieldsRaw ?? [];
    priceChannelCustomFields = variant?.price?.channel?.custom?.customFieldsRaw ?? [];
    productAttributes = variant?.attributesRaw ?? [];
    availability =
      (variant?.availability?.channels?.results?.length ?? 0) > 0 ? variant?.availability?.channels.results[0].availability ?? undefined : undefined;
  } else if ('variant' in product) {
    hasPriceChannel = !!product.variant?.price?.channel ?? false;
    priceCustomFields = product.variant?.price?.custom?.customFieldsRaw ?? [];
    priceChannelCustomFields = product.variant?.price?.channel?.custom?.customFieldsRaw ?? [];
    productAttributes = product.variant?.attributesRaw ?? [];
    availability =
      (product.variant?.availability?.channels?.results?.length ?? 0) > 0
        ? product.variant?.availability?.channels.results[0].availability ?? undefined
        : undefined;
  }
  isCateringManagerItem = Boolean(getProductAttributeValue('isCateringManagerItem', productAttributes) ?? false);
  cateringStatus = getCustomFieldValue('cateringStatus', priceChannelCustomFields);
  inventoryMode = getCustomFieldValue('inventoryMode', priceCustomFields) ?? InventoryMode.None;
  discontinued = Boolean(getCustomFieldValue('discontinued', priceCustomFields) ?? false);

  result.inventoryMode = inventoryMode;

  if (!hasPriceChannel || discontinued || (inventoryMode === InventoryMode.ReserveOnOrder && !availability)) {
    result.availability =
      hasPriceChannel && discontinued
        ? 'Discontinued'
        : hasPriceChannel && inventoryMode === InventoryMode.ReserveOnOrder && !availability
          ? 'OutOfStock'
          : 'Unavailable';
    return result;
  }

  const now = dayjs();
  // check if there is a Pre Order restriction
  var val = getProductAttributeValue('preorderDate', productAttributes);
  if (val) {
    try {
      var preOrder = dayjs(val);
      if (preOrder.diff(now, 'minutes') >= 0) {
        return { ...result, availabilityDate: preOrder };
      }
    } catch (e) {
      logger.error(['Failed to parse preorderDate attribute:', val].join(' '));
      return result;
    }
  }

  // skip start available day restrictions, treating it as additional lead time of in future
  val = getProductAttributeValue('endAvailableOrderDate', productAttributes);
  if (val) {
    try {
      var endAvailability = dayjs(val);
      if (endAvailability.diff(now, 'minutes') <= 0) {
        return result;
      }
    } catch (e) {
      logger.error(['Failed to parse endAvailableOrderDate attribute:', val].join(' '));
      return result;
    }
  }

  var availabilityStatus: AvailablityStatus | undefined = inventoryMode !== InventoryMode.ReserveOnOrder ? 'Available' : undefined;
  if (isCateringManagerItem) {
    availabilityStatus = getAvailablityStatus(
      inventoryMode === InventoryMode.ReserveOnOrder ? availability?.availableQuantity ?? 0 : DEFAULT_QUANTITY,
      cateringStatus,
    );
  } else if (!availabilityStatus) {
    availabilityStatus = getAvailablityStatus(availability?.availableQuantity ?? 0);
  }
  // else just check the quantity
  return {
    availability: availabilityStatus,
    quantity: inventoryMode === InventoryMode.ReserveOnOrder ? availability?.availableQuantity : DEFAULT_QUANTITY,
    inventoryMode,
    productSku: sku,
  };
};

export const getProductPromotions = (allProductPromotion: { AvailablePromotions: Promotion[]; AcceptedPromotions: Promotion[] }, productlist: Product[]) => {
  const upl: ProductList[] = [];

  productlist?.map((lp) => {
    let productPromotion: ProductPromotion = {
      flagHeadline: '',
      badgeType: '',
      autoApply: false,
      isAccepted: false,
      sku: '',
      extFlagTypeCode: '',
    };

    const sku = lp.masterData.current?.masterVariant.sku ?? '';

    const avp = allProductPromotion?.AvailablePromotions?.filter((p) => p?.ProductList?.includes(sku)) ?? [];
    const acp = allProductPromotion?.AcceptedPromotions?.filter((p) => p?.ProductList?.includes(sku)) ?? [];
    const allPromotions = { AvailablePromotions: avp, AcceptedPromotions: acp };

    const flagsPromotions = allPromotions?.AvailablePromotions.filter((p) => p.AutoApply && p.ExtFlagTypeCode).sort(function (p1, p2) {
      return (
        appConstants.PRODUCT_FLAG_TYPES.indexOf(p1.ExtFlagTypeCode?.toUpperCase() ?? '') -
        appConstants.PRODUCT_FLAG_TYPES.indexOf(p2.ExtFlagTypeCode?.toUpperCase() ?? '')
      );
    });

    const availableBadgePromotions = allPromotions?.AvailablePromotions.filter((p) => p.AutoApply !== true && p.ExtBadgeTypeCode).sort(function (p1, p2) {
      return (
        appConstants.PRODUCT_BADGE_TYPES.indexOf(p1?.ExtBadgeTypeCode?.toLowerCase() ?? '') -
        appConstants.PRODUCT_BADGE_TYPES.indexOf(p2?.ExtBadgeTypeCode?.toLowerCase() ?? '')
      );
    });

    if (flagsPromotions.length > 0) {
      productPromotion.sku = lp.masterData.current?.masterVariant.sku ?? '';
      productPromotion.autoApply = flagsPromotions[0].AutoApply ?? true;
      productPromotion.extFlagTypeCode = flagsPromotions[0].ExtFlagTypeCode ?? '';
      productPromotion.flagHeadline = flagsPromotions[0].Headline ?? '';
      productPromotion.isAccepted = false;
    }

    if (availableBadgePromotions.length > 0) {
      productPromotion.sku = lp.masterData.current?.masterVariant.sku ?? '';
      productPromotion.badgeType = availableBadgePromotions[0].ExtBadgeTypeCode ?? '';
      productPromotion.isAccepted = false;
    }

    if ((productPromotion.badgeType ?? '').trim().length === 0) {
      const acceptedBadgePromotions = allPromotions?.AcceptedPromotions.filter((p) => p.ExtBadgeTypeCode).sort(function (p1, p2) {
        return (
          appConstants.PRODUCT_BADGE_TYPES.indexOf(p1.ExtBadgeTypeCode?.toLowerCase() ?? '') -
          appConstants.PRODUCT_BADGE_TYPES.indexOf(p2.ExtBadgeTypeCode?.toLowerCase() ?? '')
        );
      });

      if (acceptedBadgePromotions.length > 0) {
        productPromotion.sku = lp.masterData.current?.masterVariant.sku ?? '';
        productPromotion.badgeType = acceptedBadgePromotions[0].ExtBadgeTypeCode ?? '';
        productPromotion.isAccepted = true;
      }
    }

    upl.push({ sku: sku, product: lp, promotion: productPromotion });
  });

  return upl;
};

export const getMaxProductQuantity = (sku: string, product: Product, reserved: number = 0): number => {
  if (!product || !product.masterData.current?.masterVariant) {
    return +(Config.MAX_LINE_ITEM_QUANTITY ?? '0');
  }

  const availability = getProductAvailablity(product.masterData);
  const availableQuantity = (availability?.quantity ?? 0) + reserved;

  let variant = product.masterData.current.variants.find((x) => x.sku === sku);
  if (!variant) {
    variant = product.masterData.current.masterVariant;
  }
  if (variant) {
    const maxQtyAttr = getProductAttributeValue('maxCartQty', variant.attributesRaw);
    const unitsPerPackage = getProductAttributeValue('unitsPerPackage', variant.attributesRaw);
    const sellType = getProductAttributeValue('unitSellType', variant.attributesRaw);
    const unitBuyMaximum = getProductAttributeValue('unitBuyMaximum', variant.attributesRaw);
    if (sellType) {
      switch (sellType?.key) {
        case 'weightByEach':
          // logger.log('AQ:', availableQuantity, 'UBM:', unitBuyMaximum, 'CFM:', Config.MAX_LINE_ITEM_QUANTITY);
          // logger.log(Math.min(availableQuantity, unitBuyMaximum ? +unitBuyMaximum : +Config.MAX_LINE_ITEM_QUANTITY));
          return Math.min(availableQuantity, unitBuyMaximum ? +unitBuyMaximum : +(Config.MAX_LINE_ITEM_QUANTITY ?? '0'));
        case 'byWeight':
          // logger.log(Math.min(availableQuantity, +unitBuyMaximum ?? +Config.MAX_LINE_ITEM_QUANTITY * +unitsPerPackage));
          return Math.min(availableQuantity, unitBuyMaximum ? +unitBuyMaximum : +(Config.MAX_LINE_ITEM_QUANTITY ?? '0') * +unitsPerPackage);
        default:
          return Math.min(availableQuantity, unitBuyMaximum ? +unitBuyMaximum : +(Config.MAX_LINE_ITEM_QUANTITY ?? '0'));
      }
    }
    if (unitBuyMaximum) {
      return Math.min(+unitBuyMaximum, availableQuantity);
    }
    if (maxQtyAttr) {
      return Math.min(+maxQtyAttr, availableQuantity);
    }
  }
  return Math.min(availableQuantity, +(Config.MAX_LINE_ITEM_QUANTITY ?? '0'));
};

export const getStartsAtPrice = (productData: Product) => {
  const masterVariant = productData.masterData.current?.masterVariant;

  // Check if masterVariant and its price are valid
  if (!masterVariant || masterVariant.price === null) {
    return;
  }

  let masterPrice =
    productData.masterData.current?.masterVariant.price?.value.centAmount /
    10 ** (productData.masterData.current?.masterVariant.price?.value.fractionDigits ?? 2);
  let variants = productData.masterData.current?.variants;
  let startingPrice = masterPrice;

  variants?.forEach((v) => {
    let vPrice = v.price?.value.centAmount / 10 ** (v.price?.value.fractionDigits ?? 2);
    if (+vPrice < +startingPrice) {
      startingPrice = vPrice;
    }
  });
  return `$${startingPrice.toFixed(2)}`;
};

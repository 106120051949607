import { createStackNavigator } from '@react-navigation/stack';
import {
  AccountScreen,
  AddBirthdayClubMemberScreen,
  AddEditAddressScreen,
  AddEditPaymentMethodScreen,
  AddressBookScreen,
  AppSettingsScreen,
  ChangePasswordScreen,
  CommunicationPreferencesScreen,
  ContactCustomerScreen,
  CustomizeNeededScreen,
  EditProfileScreen,
  ListFilterScreen,
  ListToCartScreen,
  ManageListsScreen,
  PaymentMethodsScreen,
  ViewListScreen,
} from '@screens/account';
import React from 'react';
import HelpCenterStackNavigator from './HelpCenterNavigator';
import MessageCenterStackNavigator from './MessageCenterNavigator';
import OrderHistoryStackNavigator from './OrderHistoryNavigator';
import StoreFinderStackNavigator from './StoreFinderNavigator';
import { cardStyleInterpolator } from './helpers';
import { AccountStackParamList, AccountStackRoutes } from './routes';

const AccountStack = createStackNavigator<AccountStackParamList>();

const AccountStackNavigator = () => {
  return (
    <AccountStack.Navigator
      initialRouteName={AccountStackRoutes.AccountHome}
      screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'card' }}>
      <AccountStack.Screen name={AccountStackRoutes.AccountHome} component={AccountScreen} initialParams={{ reload: false }} />
      <AccountStack.Screen name={AccountStackRoutes.CommunicationPreferences} component={CommunicationPreferencesScreen} />
      <AccountStack.Screen name={AccountStackRoutes.AppSettings} component={AppSettingsScreen} />
      <AccountStack.Screen name={AccountStackRoutes.OrderHistory} component={OrderHistoryStackNavigator} />
      <AccountStack.Screen name={AccountStackRoutes.ChangePassword} component={ChangePasswordScreen} />
      <AccountStack.Screen name={AccountStackRoutes.EditProfile} component={EditProfileScreen} />
      <AccountStack.Screen
        name={AccountStackRoutes.AddBirthdayClubMember}
        component={AddBirthdayClubMemberScreen}
        options={{
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
        }}
      />
      <AccountStack.Screen name={AccountStackRoutes.ViewList} component={ViewListScreen} />
      <AccountStack.Screen name={AccountStackRoutes.ListFilter} component={ListFilterScreen} options={{ cardStyleInterpolator }} />
      <AccountStack.Screen name={AccountStackRoutes.ListToCart} component={ListToCartScreen} options={{ cardStyleInterpolator }} />
      <AccountStack.Screen name={AccountStackRoutes.CustomizeNeeded} component={CustomizeNeededScreen} options={{ cardStyleInterpolator }} />
      <AccountStack.Screen name={AccountStackRoutes.ManageShoppingList} component={ManageListsScreen} />
      <AccountStack.Screen name={AccountStackRoutes.PaymentMethods} component={PaymentMethodsScreen} initialParams={{ showBack: true }} />
      <AccountStack.Screen name={AccountStackRoutes.AddEditPaymentMethod} component={AddEditPaymentMethodScreen} initialParams={{}} />
      <AccountStack.Screen name={AccountStackRoutes.ContactCustomerService} component={ContactCustomerScreen} options={{ cardStyleInterpolator }} />
      <AccountStack.Screen name={AccountStackRoutes.AddressBook} component={AddressBookScreen} />
      <AccountStack.Screen name={AccountStackRoutes.AddEditAddress} component={AddEditAddressScreen} initialParams={{}} />
      <AccountStack.Screen name={AccountStackRoutes.StoreFinder} component={StoreFinderStackNavigator} />
      <AccountStack.Screen name={AccountStackRoutes.MessageCenter} component={MessageCenterStackNavigator} />
      <AccountStack.Screen name={AccountStackRoutes.HelpCenter} component={HelpCenterStackNavigator} />
    </AccountStack.Navigator>
  );
};

export default AccountStackNavigator;

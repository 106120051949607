import { Modal } from '@components';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { useUserSettingsStore } from '@store';
import { lineHeight, scale, screenHeight } from '@styles/constants';
import logger from '@utils/logger';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, StyleSheet, Text, View } from 'react-native';
import Config from 'react-native-config';

type UpgradeAppModalProps = {
  shoModal: boolean;
  onDismiss: () => void;
};

const UpgradeAppModal = ({ shoModal, onDismiss }: UpgradeAppModalProps) => {
  const { t } = useTranslation('maintenanceScreen');
  const appStoreLink = 'itms-apps://apps.apple.com/id/app/raleys/id582172238?l=id';
  const playStoreLink = 'https://play.google.com/store/apps/details?id=com.raleys.app.android';
  const { updateSetting } = useUserSettingsStore();

  const handleUpgradeClick = () => {
    const appLink = Platform.select({
      ios: Config.APP_STORE_APP_LINK ?? appStoreLink,
      android: Config.PLAY_STORE_APP_LINK ?? playStoreLink,
    });
    if (appLink) {
      Linking.canOpenURL(appLink).then(
        (supported) => {
          supported && Linking.openURL(appLink);
          onDismiss();
        },
        (err) => logger.log(err),
      );
    }
  };

  const handleCancelPress = () => {
    updateSetting('lastCkecked', dayjs().toDate());
    onDismiss();
  };

  return (
    <Modal
      location={'top'}
      style={{ minHeight: screenHeight / 3 }}
      buttonContainerStyle={{ marginTop: scale(25) }}
      title={t('updateRecommendedTitle')}
      visible={shoModal}
      okButtonText={t('updateBtnText')}
      okButtonStyle={styles.helpModalButton}
      okButtonOnPress={handleUpgradeClick}
      dismisable={false}
      children={
        <View style={[styles.messageBodyView, { marginTop: scale(5) }]}>
          <Text style={styles.messageBody}>{t('updateRecommendedMessage')}</Text>
        </View>
      }
      showLinkButton={true}
      linkButtonText={t('skipText')}
      cancelButtonOnPress={handleCancelPress}
      showCancel={false}
    />
  );
};

const styles = StyleSheet.create({
  messageBody: {
    color: colors.primary,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(20),
    textAlign: 'center',
  },
  messageBodyView: {
    marginTop: scale(24),
    justifyContent: 'center',
    alignItems: 'center',
  },
  helpModalButton: {
    width: 150,
  },
});

export default UpgradeAppModal;

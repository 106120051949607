import { Icon, Modal } from '@components';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useCartStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { getOriginalOrderNumber } from '@utils/orderHelpers';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GestureResponderEvent, Platform, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useCustomModalContext } from '../contexts';

export type CartInfoBannerProps = {
  linkText?: string;
  linkAction?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  onTitlePress?: (e: GestureResponderEvent) => void;
};

export enum ModifyCartFlags {
  WARN_5MIN_SEEN = 'Warn_5Min_Seen',
}

const CartInfoBanner: FC<CartInfoBannerProps> = ({ linkText, linkAction, containerStyle, textStyle, onTitlePress }) => {
  const { cart, resetMyCart, modifyFlags, setModifyFlags } = useCartStore();
  const { showModal, hideModal } = useCustomModalContext();
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [timer, setTimer] = useState<string>('');
  const { t } = useTranslation('cart');
  const navigation = useNavigation();
  useEffect(() => {
    setShowBanner(getOriginalOrderNumber(cart) ?? false);
  }, [cart]);

  const [showConfirm, setShowConfirm] = useState(false);
  const resetCart = () => {
    setShowConfirm(true);
  };

  const titlePress = (e: GestureResponderEvent) => {
    if (onTitlePress) {
      onTitlePress(e);
    } else {
      navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.CartLanding, params: {} });
    }
  };

  const _resetCart = async () => {
    hideModal();
    await resetMyCart();
  };

  useEffect(() => {
    const getTime = () => {
      if (!showBanner) {
        return;
      }
      // Retrieve time difference (in milliseconds)
      let timeDiff = dayjs(cart?.createdAt).add(15, 'minute').diff(dayjs());
      // Calculate minutes:seconds to display
      const minutes = Math.floor(timeDiff / 60000);
      const seconds = ((timeDiff % 60000) / 1000).toFixed(0);
      let formattedTime = seconds === '60' ? `${minutes + 1}:00` : `${minutes}:${+seconds < 10 ? '0' : ''}${seconds}`;

      if (timeDiff < 5 * 60 * 1000) {
        if (!(modifyFlags?.[ModifyCartFlags.WARN_5MIN_SEEN] ?? false) && timeDiff > 0) {
          setModifyFlags({ [ModifyCartFlags.WARN_5MIN_SEEN]: true });
          showModal({
            header: (
              <View style={{ alignItems: 'center', marginTop: 50 }}>
                <Icon name="warning-circle" size={50} fill={'#777'} />
                <Text style={styles.headerText}>You have 5 Minutes Left To Checkout With Your Changes</Text>
              </View>
            ),
            children: (
              <Text style={[styles.textInfo]}>
                Changes not applied within this timeframe will not be applied and the previous version of this order will be used.
              </Text>
            ),
            subTextViewStyle: styles.subTextStyle,
            location: 'top',
            showCloseIcon: false,
            cancelButtonText: 'OK',
            cancelButtonType: 'secondary',
            cancelButtonOnPress: () => hideModal(),
            style: {},
          });
        }
      } else {
        if (modifyFlags?.[ModifyCartFlags.WARN_5MIN_SEEN] ?? false) {
          setModifyFlags({ [ModifyCartFlags.WARN_5MIN_SEEN]: null });
        }
      }

      if (timeDiff <= 0) {
        setShowBanner(false);
        resetMyCart();
        showModal({
          header: (
            <View style={{ alignItems: 'center', marginTop: 50 }}>
              <Icon name="warning-circle" size={50} fill={'#777'} />
              <Text style={styles.headerText}>Order Modification Session Expired</Text>
            </View>
          ),
          children: (
            <Text style={[styles.textInfo]}>If the store has not yet begun gathering your groceries you may be able to try modifying your order again.</Text>
          ),
          subTextViewStyle: styles.subTextStyle,
          location: 'top',
          showCloseIcon: false,
          cancelButtonText: 'OK',
          cancelButtonType: 'secondary',
          cancelButtonOnPress: () => hideModal(),
          style: {},
        });
      } else {
        setTimer(formattedTime);
      }
    };

    getTime();
    const interval = setInterval(() => getTime(), 500);

    return () => clearInterval(interval);
  }, [cart?.createdAt, hideModal, modifyFlags, resetMyCart, setModifyFlags, showBanner, showModal]);

  return showBanner ? (
    <>
      <View style={[styles.container, containerStyle]}>
        <Text style={[appStyles.actionNotice, styles.bannerText, styles.timer]}>{timer}</Text>
        <TouchableOpacity onPress={(e) => titlePress(e)} style={styles.textWidth}>
          <Text numberOfLines={1} style={[appStyles.actionNotice, styles.bannerText, textStyle]} testID="message">
            {t('checkoutToApplyText')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          testID="linkClick"
          style={styles.link}
          onPress={() => {
            linkAction ? linkAction() : resetCart();
          }}>
          <Text style={[appStyles.actionNoticeLink]} testID="linkText">
            {linkText ? linkText : t('discardModificationsLink')}
          </Text>
        </TouchableOpacity>
      </View>
      <Modal
        visible={showConfirm}
        headerStyle={styles.showModificationHeader}
        title={'Exit Order Modification Mode'}
        cancelButtonOnPress={() => _resetCart()}
        cancelButtonType="secondary"
        cancelButtonText={'Exit'}
        okButtonText={'Edit Order'}
        okButtonOnPress={() => setShowConfirm(false)}
        location="top"
        style={styles.modalHeight}
        titleStyle={{}}
        subTextViewStyle={styles.subTextStyle}>
        <Text style={[styles.textInfo]} testID="showModificationText">
          Any changes made will not be applied.
        </Text>
      </Modal>
    </>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 18,
    paddingHorizontal: 16,
    width: screenWidth,
    backgroundColor: colors.alertBoxGreen,
    // height: 50,
    zIndex: 900,
  },
  bannerText: {
    fontSize: scale(14),
  },
  timer: {
    // width: '12%',
    lineHeight: lineHeight(16),
  },
  textWidth: {
    // width: '68%',
  },
  link: {
    // width: '20%',
    alignItems: 'center',
  },
  textInfo: {
    fontSize: scale(18),
    color: colors.primary,
    textAlign: 'center',
    lineHeight: lineHeight(20),
    fontFamily: FontFamily.LarsseitLight,
  },
  headerText: {
    fontFamily: FontFamily.AleoBold,
    fontSize: scale(28),
    color: colors.text,
    textAlign: 'center',
    lineHeight: lineHeight(28),
    width: screenWidth * 0.8,
    marginVertical: 20,
  },
  subTextStyle: {
    marginVertical: 0,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  touchableIcon: {
    paddingLeft: 7.5,
    alignContent: 'center',
  },
  showModificationHeader: {},
  modalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.3 : screenHeight * 0.34) : screenHeight > 640 ? screenHeight * 0.33 : screenHeight * 0.48,
  },
  comfirmationModalTitle: {
    fontSize: scale(26),
  },
});

export default CartInfoBanner;

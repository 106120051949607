import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { CloseButton, LoadingScreen, NavigationHeader, Screen } from '@components';
import { mapping } from '@components/bloomreach';
import colors from '@config/colors';
import { useBrxmPage } from '@hooks';
import { ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import React, { useState } from 'react';
import { RefreshControl, ScrollView, StyleSheet, View } from 'react-native';

type ShelfGuideCategoryDetailsScreenProps = ShopStackScreenProps<ShopStackRoutes.ShelfGuideCategoryDetails>;

const ShelfGuideCategoryDetailsScreen = ({ route: _route }: ShelfGuideCategoryDetailsScreenProps) => {
  const { brxmConfiguration, brxmPage, reloadPageAsync } = useBrxmPage();
  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = async () => {
    setRefreshing(true);
    await reloadPageAsync();
    setRefreshing(false);
  };

  return (
    <>
      <Screen headerVisible={true}>
        <NavigationHeader style={[styles.chekoutHeaderStyle]} subStyle={[{}]} titleStyle={[{ margin: 10 }]} icon={<CloseButton />} />
        {!brxmPage || !brxmConfiguration ? (
          <LoadingScreen />
        ) : (
          //@ts-ignore
          <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
            <View style={styles.container}>
              <ScrollView
                style={{}}
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={false}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}>
                <BrComponent path="hero" />
                <BrComponent path="main" />
                <BrComponent path="body" />
                <BrComponent path="bottom" />
              </ScrollView>
            </View>
          </BrPage>
        )}
      </Screen>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chekoutHeaderStyle: {
    borderBottomColor: '#B4B4B4',
    backgroundColor: colors.sectionHeader,
    shadowOpacity: 0,
  },
});

export default ShelfGuideCategoryDetailsScreen;

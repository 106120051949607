import colors from '@config/colors';
import { AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import Icon, { IconProps } from './Icon';

type BackButtonProps = {
  style?: StyleProp<ViewStyle>;
  iconProps?: Omit<IconProps, 'stroke' | 'size' | 'name'>;
  size?: number;
  onPress?: (event: any) => void;
};

const BackButton = ({ onPress, style, iconProps, size = 25 }: BackButtonProps) => {
  const navigation = useNavigation();

  const handleClick = () => {
    // Traverse parent stack until we can go back
    let parent = navigation;
    while (parent.getState()?.index === 0 && parent.getParent()) {
      parent = parent.getParent();
    }
    if (parent?.canGoBack()) {
      parent?.goBack();
    } else {
      navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home });
    }
  };

  return (
    <TouchableOpacity
      testID="backButton"
      onPress={onPress ?? handleClick}
      style={[styles.backButton, style]}
      hitSlop={{ bottom: 8, top: 8, left: 8, right: 8 }}>
      <Icon name="arrow-left" size={size} stroke={colors.primary} {...iconProps} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  backButton: {
    margin: 8,
    alignItems: 'center',
  },
});
export default BackButton;

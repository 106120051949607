import { CloseButton, KeyboardAvoidingScreen, KeyboardAvoidingScrollView, LinkButton, Modal, Text } from '@components';
import { ErrorMessage, Form, FormField, SubmitButton } from '@components/forms';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { useCardAnimation } from '@react-navigation/stack';
import { validationService } from '@services/brandywine';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Linking, ScrollView, StyleSheet, TextInput, View } from 'react-native';
import * as Yup from 'yup';

type VerifyOTCProp = AuthStackScreenProps<AuthStackRoutes.VerifyOTC>;

const VerifyOTCScreen = ({ navigation, route }: VerifyOTCProp) => {
  const { current } = useCardAnimation();
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [otcExpired, setOTCExpired] = useState(false);
  const [haveNotReceivedEmail, setHaveNotReceivedEmail] = useState(false);

  const otcRef = React.createRef<TextInput>();

  const { t } = useTranslation('verifyOTC');

  const validationSchema = Yup.object().shape({
    otc: Yup.string().required().label('One-Time Code'),
  });

  const onSubmit = async ({ otc }: any) => {
    setIsButtonLoading(true);

    try {
      const result = await validationService.validateResetPasswordToken(route.params.email!, otc);
      if (result === true) {
        otcRef.current?.clear();

        navigateToResetPassword(route.params.email!, otc);
      }
    } catch (error) {
      setOTCExpired(true);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const navigateToResetPassword = (email: string | undefined, code: string | undefined) => {
    navigation.replace(AuthStackRoutes.ResetPassword, { email: email, code: code });
  };

  const callCustomerService = () => {
    Linking.openURL(`tel:${appConstants.CUSTOMER_SERVICE_PHONE}`);

    setTimeout(() => {
      setHaveNotReceivedEmail(false);
    }, 250);
  };

  const renderVerifyOTC = () => {
    return (
      <>
        <View style={styles.textWrapper} testID={'otcView'}>
          <Text style={styles.h3BoldCenter} testID={'otcTitleText'}>
            {t('otcTitleText')}
          </Text>
          <Text style={styles.bodyLargeCenter} testID={'otcMessage'}>
            {t('otcMessage')}
          </Text>
        </View>
        <View style={styles.form} testID={'otcForm'}>
          <Form initialValues={{ otc: '' }} onSubmit={onSubmit} validationSchema={validationSchema}>
            <FormField
              testID="otc"
              autoCapitalize="none"
              autoCorrect={false}
              name="otc"
              label={t('otcLabelText')}
              textContentType="oneTimeCode"
              ref={otcRef}
              returnKeyType="go"
            />
            <View style={styles.buttonsContainer}>
              <ErrorMessage error={t('otcExpiredMessage')} visible={otcExpired} style={styles.errorMessage} testID={'otcExpiredMessage'} />
              <SubmitButton testID="verifyOTCSubmit" title={t('verifyOTCText')} isButtonLoading={isButtonLoading} />
            </View>
            <View style={[styles.textWrapper, utilityStyles.mt4]} testID={'didNotReceivedEmailView'}>
              <LinkButton testID="didNotReceivedEmail" onPress={() => setHaveNotReceivedEmail(true)} style={appStyles.boldLinkLargeCenter}>
                {t('didNotReceivedEmail')}
              </LinkButton>
            </View>
          </Form>
        </View>
      </>
    );
  };

  const showHaveNotReceivedEmailModal = () => {
    return (
      <Modal
        style={{ minHeight: screenHeight / 2 }}
        buttonContainerStyle={{ bottom: screenHeight > 740 ? 10 : 0 }}
        headerStyle={{ justifyContent: 'center' }}
        contentStyle={{ width: screenWidth, paddingHorizontal: 15 }}
        visible={haveNotReceivedEmail}
        title={t('haveNotReceivedEmail')}
        location="top"
        cancelButtonOnPress={() => {
          setHaveNotReceivedEmail(false);
        }}
        cancelButtonText={t('okText')}>
        <Text style={styles.bodyMediumLightCenter} testID={'haveNotReceivedEmailMessage'}>
          {t('haveNotReceivedEmailMessage')}
        </Text>
        <View style={[utilityStyles.mt4, styles.customerSupportWrapper]}>
          <Text style={{ textAlign: 'center' }} testID={'modalText'}>
            <Text style={styles.bodyMediumLightCenter} testID={'furtherAssistance'}>
              {t('furtherAssistance')}
            </Text>
            <LinkButton testID="phone" onPress={callCustomerService} style={[appStyles.boldLinkLargeCenter, { flexWrap: 'nowrap' }]}>
              {t('phone')}
            </LinkButton>
            <Text style={styles.bodyMediumLightCenter} testID={'customerService'}>
              {t('customerService')}
            </Text>
          </Text>
        </View>
      </Modal>
    );
  };

  return (
    <KeyboardAvoidingScreen keyboardVerticalOffset={-100} style={{ backgroundColor: 'transparent' }}>
      <Animated.View
        style={[
          styles.container,
          {
            transform: [
              {
                translateY: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [screenHeight * 0.65, 0],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}>
        <View style={styles.modal}>
          <View style={styles.header} testID={'closeButton'}>
            <CloseButton style={styles.closeButton} />
          </View>
          <ScrollView style={styles.form}>
            <KeyboardAvoidingScrollView scrollEnabled={true} extraKeyboardOffset={240} contentContainerStyle={appStyles.container} bounces={false}>
              {renderVerifyOTC()}
            </KeyboardAvoidingScrollView>
          </ScrollView>
        </View>
      </Animated.View>
      {haveNotReceivedEmail && showHaveNotReceivedEmailModal()}
    </KeyboardAvoidingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: screenHeight * 0.85,
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  modal: {
    flex: 1,
    alignContent: 'space-between',
  },
  header: {
    height: 50,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    textAlign: 'center',
  },
  closeButton: {
    left: 16,
    top: 16,
  },
  textWrapper: {
    paddingHorizontal: 0,
    paddingBottom: 25,
  },
  bodyLargeCenter: {
    ...appStyles.bodyLargeCenter,
    marginTop: 15,
  },
  form: {
    width: '100%',
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  errorMessage: {
    ...appStyles.bodySmallRed,
    textAlign: 'center',
    paddingBottom: 15,
  },
  errorWrapper: {
    width: 330,
    height: 25,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: scale(16),
    fontFamily: 'Larsseit-Light',
  },
  errorBorder: {
    borderColor: colors.red,
  },
  buttonsContainer: {
    marginTop: 20,
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
  },
  textInfo: {
    fontSize: scale(18),
    textAlign: 'center',
    lineHeight: lineHeight(18),
    marginBottom: 45,
    fontFamily: FontFamily.LarsseitLight,
    width: screenWidth * 0.8,
    alignSelf: 'center',
  },
  customerSupportWrapper: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  bodyMediumLightCenter: {
    ...appStyles.bodyMediumLight,
    textAlign: 'center',
  },
});

export default VerifyOTCScreen;

import { CancelToken } from 'apisauce';
import { CancelTokenSource } from 'axios';
import { useRef } from 'react';
import useEffectOnce from './useEffectOnce';

function useCancelToken(componentName?: string) {
  const cancelTokenSource = useRef<CancelTokenSource>(CancelToken.source());

  useEffectOnce(() => {
    return () => {
      cancelTokenSource.current.cancel(`${componentName ?? ''} unmounted`);
    };
  });

  return cancelTokenSource.current.token;
}

export default useCancelToken;

import { BottomBar, Button, Icon, LinkButton, NavigationHeader, Screen } from '@components';
import { IconName } from '@components/Icon';
import Text from '@components/Text';
import ItemizedCart from '@components/cart/ItemizedCart';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Address, ShopType, Store } from '@fieldera-raleys/client-common';
import { CartValidationResult } from '@fieldera-raleys/client-common/types/cart';
import { useEffectOnce } from '@hooks';
import { AppStackRoutes, CheckoutStackRoutes, RootTabRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { storeService } from '@services/brandywine';
import { useCartStore } from '@store/cartStore';
import useShopStore from '@store/shopStore';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, ScrollView, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getLogoColors } from '../../utils/orderHelpers';

type CartValidaionScreenProps = {
  validationResult: CartValidationResult;
  storeNumber?: string;
  shopType?: ShopType;
  deliveryAddress?: Address;
};

const CartValidaionScreen = ({ validationResult, ...other }: CartValidaionScreenProps) => {
  const { cart, initialize, applyValidation, tombSet } = useCartStore();
  const navigation = useNavigation();
  const [store, setStore] = useState<Store | undefined>();
  const [shopType, setShopType] = useState<string>();
  const scrollViewRef = useRef<ScrollView>(null);
  const [loading, setIsLoading] = useState(false);
  const { selectedStore, deliveryAddress, setSelectedStore, setSelectedTimeSlot, setSelectedShopType, setDeliveryAddress, selectedShopType } = useShopStore();
  const insets = useSafeAreaInsets();
  const [contentBottom, setContentBottom] = useState<{ paddingBottom: number }>({ paddingBottom: insets.bottom });

  const { t } = useTranslation('cart');

  const onButtonLayout = (le: LayoutChangeEvent) => {
    setContentBottom({ paddingBottom: le.nativeEvent.layout.height });
  };

  const cancelChanges = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Home, params: {} });
    }

    return false;
  };

  const applyChanges = async () => {
    if (!cart) {
      return;
    }

    setIsLoading(true);
    try {
      if (other.storeNumber && other.shopType) {
        //setSelectedStore(other);
        setSelectedStore(await storeService.getStore(other.storeNumber));
        setSelectedShopType(other.shopType);
        setDeliveryAddress(other.deliveryAddress);
        setSelectedTimeSlot(undefined);
      }
      await applyValidation(validationResult);
    } catch (e) {
      await initialize();
      setIsLoading(false);
      throw e;
    }

    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      tombSet('TimeSlot');
      navigation.dispatch(StackActions.replace(CheckoutStackRoutes.CartLanding, { ...other }));
    }

    setIsLoading(false);
  };

  const itemizedRef = React.useRef<React.ElementRef<typeof ItemizedCart>>(null);
  useEffect(() => {
    const current = itemizedRef.current;
    return () => {
      current?.flush();
    };
  });

  // const cItems =
  //   cart?.lineItems.filter((li) => li.productType?.name === ProductType.STANDARD || li.productType?.name === ProductType.CONFIGURABLE) || [];
  // const lItems = !validationResult ? cItems : cItems.filter((li) => validationResult.errors.findIndex((xi) => xi.id === li.id) !== -1);
  const warn = t('cartValidationWarning');

  //const storeInfo: FulfillmentStore | undefined = getFulfilmentStore(cart);
  //const a = other?.deliveryAddress ?? storeInfo?.address;

  useEffectOnce(() => {
    const doGetStore = async () => {
      setStore(await storeService.getStore(other.storeNumber!));
    };
    if (other.shopType) {
      setShopType(other.shopType);
    } else {
      setShopType(selectedShopType);
    }
    if (other.shopType === ShopType.PICKUP) {
      if (!other.storeNumber) {
        setStore(selectedStore);
      } else {
        doGetStore();
      }
    } else {
      selectedStore?.number && setStore(selectedStore);
    }
  });
  const a = shopType === ShopType.DELIVERY ? other?.deliveryAddress ?? deliveryAddress : { address1: store?.address.street };
  const iconName: IconName = store?.brand.logo.includes('one') ? ('raleysonemarket' as IconName) : (store?.brand.logo as IconName) ?? 'raleys';
  const logoColors = getLogoColors(iconName); //store?.brand.logo

  return (
    <Screen>
      <NavigationHeader iconProps={{ name: 'x-close' }} title="Cart Changes" />
      <View style={[styles.mainContainer]}>
        <ScrollView
          maintainVisibleContentPosition={{ autoscrollToTopThreshold: 1, minIndexForVisible: 0 }}
          contentContainerStyle={[styles.scrollViewContent, contentBottom]}
          nestedScrollEnabled={true}
          bounces={true}
          showsVerticalScrollIndicator={false}
          ref={scrollViewRef}>
          <View style={[styles.warningPad, utilityStyles.mt3]}>
            <Icon name={'warning-circle'} fill={colors.black} style={[utilityStyles.mx3, utilityStyles.my1]} />

            <Text style={[appStyles.bodySmallBold, { textAlign: 'left', width: '80%' }]} testID="cartValidationWarning">
              {warn}
            </Text>
          </View>

          <View key={'validateLocation_View3'} style={[styles.location]}>
            <View style={styles.iconWrapper}>
              {shopType === ShopType.DELIVERY ? (
                <Icon name={'home-icon'} size={50} fill={colors.darkCream} stroke={colors.darkCream} style={styles.homeIcon} />
              ) : (
                <Icon name={iconName} size={70} fill={logoColors?.fill} stroke={logoColors?.stroke} style={styles.homeIcon} />
              )}
            </View>
            <View style={[styles.locationInfo]}>
              <Text numberOfLines={1} style={styles.locationText} testID="shippingType">
                {shopType === ShopType.DELIVERY ? 'Delivery' : 'Pickup'}
              </Text>

              <Text numberOfLines={1} style={[styles.locationText, { fontFamily: FontFamily.Larsseit }]} testID="address">
                {a?.address1 ?? a.postalCode ?? ''}
              </Text>
            </View>
          </View>

          <ItemizedCart ref={itemizedRef} validationResult={validationResult} />
        </ScrollView>
      </View>
      <BottomBar onLayout={onButtonLayout}>
        <LinkButton onPress={() => cancelChanges()} style={[appStyles.smallLink, { textAlign: 'center' }]} testID="cancelBtn">
          Close
        </LinkButton>
        <Button
          testID="continueAnywayBtn"
          size={'small'}
          buttonStyle={[appStyles.valdiationButton]}
          onPress={() => applyChanges()}
          disabled={!(cart?.lineItems?.length ?? 0)}
          title={'Continue Anyway'}
          isButtonLoading={!cart || loading}
        />
      </BottomBar>
    </Screen>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    flexDirection: 'column',
    width: screenWidth,
    height: screenHeight,
  },
  mainContainer: {
    paddingTop: 0,
    flex: 1,
  },
  warningPad: {
    alignSelf: 'center',
    width: containerWidth,
    flexDirection: 'row',
  },
  scrollViewContent: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.cream,
  },
  locationInfo: {
    paddingRight: 6,
    width: '60%',
  },
  location: {
    alignSelf: 'center',
    width: containerWidth,
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: colors.darkCream,
    borderWidth: 1,
    borderRadius: 7,
    padding: scale(8),
    ...utilityStyles.my3,
  },
  locationText: {
    fontSize: scale(16),
    color: colors.darkText,
    lineHeight: lineHeight(18),
    fontFamily: FontFamily.LarsseitBold,
    textAlign: 'center',
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    paddingRight: 10,
  },
  homeIcon: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CartValidaionScreen;

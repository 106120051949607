import { DropShadow, Icon, Screen } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import Tabbar, { TabItem } from '@components/Tabbar';
import Text from '@components/Text';
import { ProductCarousel } from '@components/brsm';
import LinkList from '@components/somethingExtra/LinkList';
import QuickAccessLinks, { Link } from '@components/somethingExtra/QuickAccessLinks';
import SEDollarsAlert from '@components/somethingExtra/SEDollarsAlert';
import SEOffers from '@components/somethingExtra/SEOffers';
import SEPointsBalance from '@components/somethingExtra/SEPointsBalance';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { TourKey } from '@fieldera-raleys/client-common';
import routes, {
  AccountStackRoutes,
  AppStackRoutes,
  HelpCenterStackRoutes,
  RewardsStackRoutes,
  RewardsStackScreenProps,
  RootTabRoutes,
  ShopStackRoutes,
} from '@navigation/routes';
import { useAppConfigStore, useOffersStore, useUserSettingsStore } from '@store';
import useTutorialStore from '@store/tutorialStore';
import { utilityStyles } from '@styles';
import { scale, screenHeight } from '@styles/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, LayoutRectangle, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTourGuideController } from 'rn-tourguide';
import { useCustomModalContext } from '../../contexts';

type SEHomeScreenProps = RewardsStackScreenProps<RewardsStackRoutes.SEHome>;

const SEHomeScreen = ({ navigation, route }: SEHomeScreenProps) => {
  const { showAMSCard } = useCustomModalContext();
  const pageConfig = useAppConfigStore().getConfigGroup('Rewards');
  const { t } = useTranslation(['topDeals', 'tourGuide']);

  const testLFSLinks = [
    {
      title: 'My Lists',
      action: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Account,
          params: { screen: AccountStackRoutes.ManageShoppingList, params: {} },
        });
      },
    },
    {
      title: 'Wallet',
      action: () => {
        navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Wallet, params: { showBack: true } });
      },
    },
    {
      title: 'Help Center',
      action: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Account,
          params: { screen: AccountStackRoutes.HelpCenter, params: { screen: HelpCenterStackRoutes.HelpCenterScreen } },
        });
      },
      //AccountStackRoutes.HelpCenter
    },
  ];

  const QALinks: Link[] = [
    {
      iconName: 'se-offers',
      title: 'Offers',
      showBadge: false,
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Rewards,
          params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Personalized' } },
        });
      },
    },
    {
      iconName: 'redeem',
      title: 'SE Dollars',
      showBadge: false,
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Wallet, params: { showBack: true, toDollarsTab: true } });
      },
    },
    {
      iconName: 'weekly-ad',
      title: 'Weekly Ad',
      showBadge: false,
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: {
            screen: ShopStackRoutes.WeeklyAds,
          },
        });
      },
    },
    {
      iconName: 'gas-pump',
      title: 'Fuel',
      showBadge: false,
      onPress: () => navigation.navigate(AppStackRoutes.WebBrowser, { url: routes.PUMP_REWARDS, title: 'Fuel' }),
    },
    {
      iconName: 'list-page',
      title: 'Lists',
      showBadge: false,
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Account,
          params: { screen: AccountStackRoutes.ManageShoppingList, params: {} },
        });
      },
    },
  ];

  const { availableVouchers } = useOffersStore();
  const { getVisitCount } = useTutorialStore();
  const [dollarsAlertVisible, setDollarsAlertVisible] = useState(availableVouchers?.length ? true : false);

  const insets = useSafeAreaInsets();

  const navigationItems: TabItem[] = [
    {
      name: 'se-offers',
      displayName: 'Offers',
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Rewards,
          params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Personalized' } },
        });
      },
    },
    {
      name: 'redeem',
      displayName: 'SE Dollars',
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Wallet, params: { showBack: true, toDollarsTab: true } });
      },
    },
    {
      name: 'weekly-ad',
      displayName: 'Weekly Ad',
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: {
            screen: ShopStackRoutes.WeeklyAds,
          },
        });
      },
    },
    {
      name: 'gas-pump',
      displayName: 'Fuel',
      onPress: () => navigation.navigate(AppStackRoutes.WebBrowser, { url: routes.PUMP_REWARDS, title: 'Fuel' }),
    },
    {
      name: 'list-page',
      displayName: 'Lists',
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Account,
          params: { screen: AccountStackRoutes.ManageShoppingList, params: {} },
        });
      },
    },
  ];

  const scrollViewRef = useRef<ScrollView>(null);
  const { reload } = route.params;

  const { userSettings } = useUserSettingsStore();
  const {
    canStart, // a boolean indicate if you can start tour guide
    start, // a function to start the tourguide
    stop, // a function  to stopping it
    TourGuideZone,
  } = useTourGuideController(TourKey.SEHomeScreen);

  useEffect(() => {
    if (reload) {
      scrollViewRef.current?.scrollTo({ x: 0, y: 0 });
      navigation.setParams({ reload: false });
    }
  }, [navigation, reload]);

  const [headerLinks, setHeaderLinks] = useState<LayoutRectangle | null>(null);
  const scrollY = useRef(new Animated.Value(0)).current;
  const headerTop =
    headerLinks &&
    (headerLinks?.y > 330
      ? headerLinks?.y - screenHeight - (screenHeight > 740 ? 250 : 450)
      : headerLinks?.y - screenHeight + insets.top + (screenHeight > 740 ? headerLinks?.height : -headerLinks?.height) + 60);
  const inputRange = headerTop ? [-1, 0, -headerTop - 15, -headerTop, -headerTop + 1] : [-1, 0, 0, 0, 0];

  const handleNav = (page: string) => {
    if (!page) {
      return;
    }
    switch (page) {
      case 'benefits':
        navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Rewards, params: { screen: RewardsStackRoutes.SEBenefits, params: {} } });
        break;
    }
  };

  useEffect(() => {
    if (availableVouchers?.length) {
      setDollarsAlertVisible(true);
    }
  }, [availableVouchers]);

  const startTourGuide = useCallback(() => {
    if (canStart && (userSettings?.showTourGuide ?? false) && getVisitCount('Rewards') === 1) {
      start();
    }
  }, [canStart, getVisitCount, start, userSettings?.showTourGuide]);

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', async () => {
      scrollViewRef.current?.scrollTo({ x: 0, y: 0 });
      startTourGuide();
    });
    const unsubscribeBlur = navigation.addListener('blur', async () => {
      stop();
    });
    return () => {
      unsubscribeFocus();
      unsubscribeBlur();
    };
  }, [navigation, startTourGuide, canStart, stop]);

  const onScrollingEnd = () => {
    stop();
  };

  return (
    <>
      <Screen style={utilityStyles.pb0}>
        <CartInfoBanner />
        {dollarsAlertVisible && (
          <DropShadow>
            <SEDollarsAlert closeAction={() => setDollarsAlertVisible(false)} />
          </DropShadow>
        )}
        <Animated.ScrollView
          ref={scrollViewRef}
          scrollEventThrottle={16}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], { useNativeDriver: true })}
          onScrollEndDrag={onScrollingEnd}
          bounces={false}>
          <View style={styles.pointsSection}>
            <View style={styles.psHeader}>
              <Icon style={styles.seIcon} size={60} fill={colors.white} stroke={'transparent'} strokeSecondary={colors.seRed} name={'something-extra'} />
              <TouchableOpacity style={[styles.benefitButton]} onPress={() => handleNav('benefits')} testID="benefitsButton">
                <Text style={{ fontFamily: FontFamily.LarsseitBold, color: colors.white }} testID="benefitsTitle">
                  Benefits
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={showAMSCard} style={[styles.seBarCode]} testID="se-barcode-icon-Button">
                <TourGuideZone text={t('tgRewardBarcodeTxt', { ns: 'tourGuide' })} zone={2} shape={'circle'}>
                  <Icon style={styles.barcodeIcon} stroke={colors.white} name={'se-barcode-icon'} testID="se-barcode-icon" />
                </TourGuideZone>
              </TouchableOpacity>
            </View>
            <View style={styles.psTitle}>
              <Text style={{ fontFamily: FontFamily.AleoBold, color: colors.white, fontSize: scale(24) }} testID="somethingExtraTitle">
                Something Extra Rewards
              </Text>
            </View>
            <View style={styles.sePointBalance} testID="pointsBalanceView">
              <SEPointsBalance homeExtensionVisible={false} isRegHome={false} />
            </View>
          </View>

          <Animated.View
            onLayout={(e) => {
              setHeaderLinks(e.nativeEvent.layout);
            }}
            style={[
              styles.darkSection,
              styles.quickLinks,
              {
                opacity: scrollY.interpolate({
                  inputRange,
                  outputRange: [1, 1, 0.8, 0.5, 0],
                }),
              },
            ]}>
            <QuickAccessLinks links={QALinks} />
          </Animated.View>

          <View style={[styles.lightSection, styles.seOffers]}>
            <SEOffers title={'Personalized Offers'} offerType="SomethingExtra" />
            <SEOffers title={'Member Deals'} offerType="WeeklyExclusive" />
            <SEOffers title={'Digital Coupons'} offerType="DigitalCoupons" />
          </View>

          <View style={[styles.darkSection, styles.productCarousel]}>
            {pageConfig?.TopDeals && (
              <ProductCarousel
                title="Top Deals for You"
                widgetId={pageConfig.TopDeals}
                widgetType={'TopDeals'}
                contentContainerStyle={styles.contentContainer}
                addCartButtonStyle={styles.addCartButtonStyle}
                emptyListMsg={t('emptyListMsg')}
              />
            )}
          </View>

          <View style={[styles.lightSection, styles.linkList]}>
            <LinkList header="Looking For Something?" links={testLFSLinks} />
          </View>

          <View style={[styles.darkSection, styles.quickTipSection]}>
            <Text style={styles.qtHeader} allowFontScaling={true} maxFontSizeMultiplier={4} testID="quickTipTitle">
              Quick Tip
            </Text>
            <Text style={styles.qtContent} allowFontScaling={true} maxFontSizeMultiplier={4} testID="quickTip">
              PRESS and HOLD the Rewards icon below as a shortcut to launch the SE Barcode. Try it!
            </Text>
          </View>
        </Animated.ScrollView>
      </Screen>
      {headerLinks && (
        <Animated.View
          style={[
            styles.darkSection,
            styles.tabBar,
            {
              top: 0 + insets.top + (dollarsAlertVisible ? 60 : 0),
              transform: [
                {
                  translateY: scrollY.interpolate({
                    inputRange,
                    outputRange: [-100, -100, 0, 0, 0],
                  }),
                },
              ],
              opacity: scrollY.interpolate({
                inputRange,
                outputRange: [0, 0, 0, 1, 1],
              }),
            },
          ]}>
          <Tabbar tabs={navigationItems} selectedTabName={'offers'} />
        </Animated.View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  darkSection: {
    backgroundColor: colors.sectionPad,
  },
  lightSection: {
    backgroundColor: colors.cream,
  },
  pointsSection: {
    backgroundColor: colors.seRed,
    paddingHorizontal: 12,
    paddingBottom: 22,
  },
  quickTipSection: {
    paddingHorizontal: 18,
    paddingVertical: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  psHeader: {
    flexDirection: 'row',
    height: 60,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingVertical: 8,
  },
  psTitle: {
    height: 50,
    justifyContent: 'center',
  },
  seIcon: {
    position: 'absolute',
    top: -5,
    left: 0,
  },
  barcodeIcon: {
    marginLeft: 0,
    marginRight: 20,
    marginBottom: 12,
    width: 40,
    height: 40,
    transform: [{ translateX: -3 }, { translateY: 8 }],
  },
  qtHeader: {
    fontFamily: FontFamily.AleoBold,
    color: colors.darkText,
    fontSize: scale(24),
  },
  qtContent: {
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    fontSize: scale(14),
    lineHeight: scale(18),
    textAlign: 'center',
    marginTop: scale(12),
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  addCartButtonStyle: {
    backgroundColor: colors.darkCream,
    maxWidth: 400,
  },
  benefitButton: {
    height: 50,
    marginRight: 40,
    justifyContent: 'center',
  },
  seBarCode: {
    width: 40,
  },
  sePointBalance: {
    alignItems: 'center',
  },
  linkList: {
    padding: 12,
    paddingTop: 32,
    paddingBottom: 16,
  },
  productCarousel: {
    paddingVertical: 24,
  },
  seOffers: {
    paddingVertical: 32,
  },
  quickLinks: {
    height: 85,
  },
  tabBar: {
    backgroundColor: colors.white,
    height: 32,
    position: 'absolute',
  },
});

export default SEHomeScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import { AddLineItem, ShoppingList } from '@fieldera-raleys/client-commercetools/schema';
import { AccountStackRoutes, AppStackRoutes, CheckoutStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useCartStore, useShoppingListsStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import React, { FC, useCallback } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import Icon from '../Icon';

interface AddToListOptionsProps {
  productSku: string | AddLineItem[];
  searchText: string;
  action?: 'Move to List' | 'Copy to List';
  containerStyle?: StyleProp<ViewStyle>;
  navigation?: any;
}

const AddToListOptions: FC<AddToListOptionsProps> = ({ productSku, searchText, action = 'Move to List', containerStyle, navigation }) => {
  const { shoppingLists, addItemToList, addTextLineItemToList, addItemsToList } = useShoppingListsStore();
  const { hideModal, showAlertPopup } = useCustomModalContext();
  const { emptyCart } = useCartStore();

  const onCloseClick = useCallback(() => {
    navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.CartLanding, params: {} });
  }, [navigation]);

  const doEmptyCart = useCallback(async () => {
    await emptyCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewList = useCallback(
    (list: ShoppingList) => {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: { screen: AccountStackRoutes.ViewList, params: { title: list?.name ?? '', listName: list?.name ?? '', listId: list?.id ?? '' } },
      });
    },
    [navigation],
  );

  const handlePress = useCallback(
    async (li: ShoppingList) => {
      if (productSku && typeof productSku === 'string') {
        await addItemToList(li.id, productSku);
      } else if (productSku && Array.isArray(productSku)) {
        await addItemsToList(
          li.id,
          productSku.map((s) => {
            return {
              sku: s.sku,
              quantity: s.quantity,
            };
          }),
          [],
        );

        hideModal();

        action === 'Move to List' && (await doEmptyCart());

        onCloseClick();

        if (productSku.length) {
          showAlertPopup({
            message: action === 'Move to List' ? `Item(s) Moved to "${li?.name}"` : `Item(s) Copied to "${li?.name}"`,
            containerStyle: containerStyle,
            linkText: 'View',
            linkAction: () => viewList(li),
          });
        }
        return;
      } else if (searchText) {
        await addTextLineItemToList(li.id, searchText);
      }
      hideModal();
      showAlertPopup({ message: `Item Added to "${li.name}"`, containerStyle: containerStyle, linkText: 'View', linkAction: () => viewList(li) });
    },
    [
      productSku,
      searchText,
      hideModal,
      showAlertPopup,
      containerStyle,
      addItemToList,
      addItemsToList,
      action,
      doEmptyCart,
      onCloseClick,
      viewList,
      addTextLineItemToList,
    ],
  );

  return (
    <>
      {shoppingLists
        ?.filter((l) => l.name !== 'Saved For Later')
        ?.map((list: ShoppingList) => {
          return (
            <View style={[styles.linkContainer]} key={list.id}>
              <TouchableOpacity
                onPress={() => {
                  handlePress(list);
                }}
                style={[styles.link, { flexDirection: 'row' }]}>
                {list.name === 'Favorites' ? <Icon name={'heart-icon'} fill={colors.darkText} stroke={colors.darkText} size={15} /> : null}
                <Text testID={list.name ?? 'listName'} style={[appStyles.bodySmallLeft, utilityStyles.ml3]}>
                  {list.name ?? ''}
                </Text>
              </TouchableOpacity>
            </View>
          );
        })}
    </>
  );
};

const styles = StyleSheet.create({
  linkContainer: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: colors.lineSeparator,
    justifyContent: 'center',
  },
  link: {
    width: '100%',
    alignItems: 'center',
    // backgroundColor: 'red',
  },
});

export default AddToListOptions;

import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { Icon, LoadingScreen, Text } from '@components';
import { OpenTombstoneRequest } from '@components/Tombstone';
import { mapping } from '@components/bloomreach';
import withShopNavigator from '@components/hoc/withShopNavigator';
import WeeklyAdsCard from '@components/somethingExtra/WeeklyAdsCard';
import WeeklyAdsCollectionCard, { CollectionInfo } from '@components/somethingExtra/WeeklyAdsCollectionCard';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { FacetType, Filter, PagedArray } from '@fieldera-raleys/client-common';
import { ProductCollection, ProductCollectionItem } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useBrxmPage, useEffectOnce } from '@hooks';
import { AppStackRoutes, ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { useIsFocused } from '@react-navigation/native';
import { brsmService } from '@services';
import { productService } from '@services/brandywine';
import { useAnalyticsStore, useSearchStore, useShopStore, useUserProfileStore } from '@store';
import { defaultFilters } from '@store/searchStore';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight } from '@styles/constants';
import { ProductList, getProductPromotions } from '@utils/productHelper';
import { getOffersAndCouponsByProductList } from '@utils/promotionHelper';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, ListRenderItemInfo, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { queryClient } from '../../utils/reactQuery';

type WeeklyAdsScreenProps = ShopStackScreenProps<ShopStackRoutes.WeeklyAds> & { onChangeStore: (reqest: OpenTombstoneRequest) => void };

const WeeklyAdsScreen = withShopNavigator(
  ({ route, navigation, onChangeStore }: WeeklyAdsScreenProps) => {
    const { t } = useTranslation('weeklyAdsScreen');
    const { userProfile } = useUserProfileStore();
    const { selectedStore } = useShopStore();
    const { getSelectedFilters, getSelectedFiltersCount, sortQuery, setAvailableFilters, resetFilters } = useSearchStore();
    const { previousRoute } = useAnalyticsStore();
    const { brxmConfiguration, brxmPage } = useBrxmPage();
    const [weeklyAd, setWeeklyAd] = useState<{
      isLoading: boolean;
      data?: ProductCollection;
      pdfDownloadUrl?: string;
      bannerImageUrl?: string;
      offset: number;
      total: number;
      filters: Filter[];
    }>({ isLoading: true, offset: 0, total: 0, filters: [...defaultFilters] });
    const [data, setData] = useState<{
      isLoading: boolean;
      filtered: boolean;
      products: PagedArray<ProductList>;
    }>({
      isLoading: true,
      filtered: false,
      products: {
        data: [],
        offset: 0,
        limit: 30,
        total: 0,
      },
    });
    const isInFocus = useIsFocused();

    const getSkuList = useCallback(
      (collection: ProductCollection, start: number, end: number) =>
        collection
          ? collection.ProductCollectionItems.map(
              (product) =>
                product.ExtProductId ??
                (product.ItemProductCollectionItem?.ProductCollectionItems && product.ItemProductCollectionItem?.ProductCollectionItems.length > 0
                  ? product.ItemProductCollectionItem?.ProductCollectionItems[0].ExtProductId
                  : null),
            )
              .filter((x) => !!x)
              .slice(start, end)
          : [],
      [],
    );

    const loadProducts = useCallback(
      async (productIDs: string[], offset: number = 0) => {
        const filters = [
          {
            id: 'pid',
            children: [
              {
                default: false,
                id: 'productList',
                selected: false,
                title: FacetType.Favorites,
                controlType: 'radio',
                value: productIDs,
              },
            ],
          },
        ] as Filter[];

        const res = await brsmService.getProductsByCollectionId(weeklyAd.data!.ExtProductCollectionId, 0, productIDs.length, {
          pathname: route.name,
          ref_url: previousRoute?.name,
          selectedFilters: filters,
          sortBy: sortQuery,
          storeKey: Config.BRSM_DEFAULT_STORE,
        });
        const sortedProductData = res.docs.data.sort(
          (a, b) => productIDs.indexOf(a.masterData?.current?.masterVariant.sku ?? '') - productIDs.indexOf(b.masterData?.current?.masterVariant.sku ?? ''),
        );

        let updatedProductList: ProductList[] = [];
        if (res.docs.data.length > 0) {
          const skus = res.docs.data.map((p) => p.masterData.current?.masterVariant.sku ?? '') ?? [];
          const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE ?? '1', skus);
          updatedProductList = getProductPromotions(promotions, sortedProductData);
        }

        setData((prev) => ({
          ...prev,
          isLoading: false,
          filtered: false,
          products: { ...res.docs, offset, total: weeklyAd.total, data: [...prev.products.data, ...(updatedProductList ?? [])] },
        }));
      },

      [previousRoute?.name, route.name, selectedStore?.number, sortQuery, weeklyAd.data, weeklyAd.total],
    );

    const searchProducts = useCallback(
      async (offset: number = 0) => {
        setData((prev) => ({ ...prev, isLoading: true }));
        const res = await brsmService.getProductsByCollectionId(weeklyAd.data!.ExtProductCollectionId, offset, appConstants.PAGE_SIZE, {
          pathname: route.name,
          ref_url: previousRoute?.name,
          selectedFilters: getSelectedFilters(),
          sortBy: sortQuery,
          storeKey: Config.BRSM_DEFAULT_STORE,
        });

        res.docs.data = res.docs.data.filter((x) =>
          weeklyAd.data?.ProductCollectionItems.some((p) => (p.ExtProductId ?? p.ItemProductCollectionItem?.ProductCollectionItems[0].ExtProductId) === x.key),
        );

        let updatedProductList: ProductList[] = [];
        if (res.docs.data.length > 0) {
          const skus = res.docs.data.map((p) => p.masterData.current?.masterVariant.sku ?? '') ?? [];
          const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE ?? '1', skus);
          updatedProductList = getProductPromotions(promotions, res?.docs.data);
        }

        setAvailableFilters(res.facetFilters);
        setData((prev) => ({
          ...prev,
          isLoading: false,
          filtered: true,
          products: { ...res.docs, limit: appConstants.PAGE_SIZE, data: [...prev.products.data, ...(updatedProductList ?? [])] },
        }));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [previousRoute?.name, route.name, selectedStore?.number, sortQuery, weeklyAd.data],
    );

    const loadWeeklyAd = useCallback(async () => {
      const result = await queryClient.fetchQuery({
        queryKey: ['weekly-Ad', userProfile!.sepiNumber, selectedStore?.number],
        queryFn: async () =>
          await productService.getProductCollections([4], selectedStore?.number ?? Config.BRSM_DEFAULT_STORE, String(userProfile!.sepiNumber)),
      });

      if (!result.length) {
        setWeeklyAd((prev) => ({
          ...prev,
          isLoading: false,
          data: undefined,
        }));
        return;
      }

      const collection = result[0];

      collection?.ProductCollectionItems?.sort(
        (a, b) => (a.PageNumber ?? 999) - (b.PageNumber ?? 999) || (a.PageBlock ?? 999) - (b.PageBlock ?? 999) || (a.SortOrder ?? 999) || (b.SortOrder ?? 999),
      );

      var pdfDownloadUrl = collection?.ProductCollectionFiles.find((f) => f.FileUrl.endsWith('.pdf'))?.FileUrl;
      pdfDownloadUrl = pdfDownloadUrl?.indexOf('//') === 0 ? `https:${pdfDownloadUrl}` : pdfDownloadUrl;

      let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif)$/gi);
      var bannerImageUrl = collection?.ProductCollectionFiles.find((f) => regex.test(f.FileUrl))?.FileUrl;
      bannerImageUrl = bannerImageUrl?.indexOf('//') === 0 ? `https:${bannerImageUrl}` : bannerImageUrl;

      const facetResponse = await brsmService.getProductsByCollectionId(collection.ExtProductCollectionId, 0, 0, {
        pathname: route.name,
        ref_url: previousRoute?.name,
      });

      const categoryFilter = facetResponse.facetFilters.find((x) => x.id === 'category');
      if (categoryFilter) {
        categoryFilter.children = categoryFilter.children.filter((c) => !c.parentId);
      }
      setAvailableFilters(facetResponse.facetFilters);

      setWeeklyAd((prev) => ({
        ...prev,
        isLoading: false,
        data: collection,
        bannerImageUrl,
        pdfDownloadUrl,
        filters: facetResponse.facetFilters,
        total: collection.ProductCollectionItems.length,
      }));
    }, [userProfile, selectedStore?.number, route.name, previousRoute?.name, setAvailableFilters]);

    useEffect(() => {
      loadWeeklyAd();
    }, [loadWeeklyAd]);

    useEffect(() => {
      if (weeklyAd.data) {
        if (getSelectedFilters().length) {
          searchProducts();
        } else {
          const productIds = getSkuList(weeklyAd.data, 0, appConstants.PAGE_SIZE * 2);
          loadProducts(productIds);
        }
      }
    }, [isInFocus, searchProducts, weeklyAd, getSelectedFilters, getSkuList, loadProducts]);

    useEffectOnce(() => {
      resetFilters();
    });

    const refetchWeeklyAds = async () => {
      setWeeklyAd((prev) => ({ ...prev, isLoading: true }));
      await loadWeeklyAd();
      if (weeklyAd) {
        resetFilters();
      }
    };

    const handleLoadMore = async () => {
      if (weeklyAd.data && data.products.offset + appConstants.PAGE_SIZE < data.products.total) {
        if (data.filtered) {
          searchProducts(data.products.offset + appConstants.PAGE_SIZE);
        } else {
          const start = data.products.offset + appConstants.PAGE_SIZE;
          const skuList = getSkuList(weeklyAd.data, start, start + appConstants.PAGE_SIZE * 2);
          loadProducts(skuList, start);
        }
      }
    };

    const clearFilter = async () => {
      resetFilters();
      if (weeklyAd.data) {
        const skuList = getSkuList(weeklyAd.data, 0, appConstants.PAGE_SIZE * 2);
        loadProducts(skuList);
      }
    };

    const getTitle = () => {
      const startDate = (weeklyAd?.data?.StartDate && dayjs(weeklyAd?.data.StartDate).format('MMM DD')) ?? '';
      const endDate = (weeklyAd?.data?.EndDate && dayjs(weeklyAd?.data.EndDate).format('MMM DD')) ?? '';
      const hypen = startDate && endDate && ' - ';
      const title = weeklyAd?.data?.DisplayName ?? '';

      return weeklyAd?.data ? `${startDate}${hypen}${endDate}${' '}${title}` : '';
    };

    const renderListHeaderComponent = () => {
      return weeklyAd?.data ? (
        <>
          <View style={styles.flatListHeader}>
            <View style={styles.flatListHeaderTitleWrapper}>
              <Text testID="weeklyAdsTitle" style={[appStyles.smLightCenter, styles.title]}>
                {getTitle()}
              </Text>
            </View>
            <View style={styles.flatListHeaderButtonWrapper}>
              {getSelectedFiltersCount() <= 0 ? (
                <></>
              ) : (
                <TouchableOpacity onPress={clearFilter} testID="clearFilterButton">
                  <Text style={[appStyles.smallLink, styles.resetLink]}>{t('resetFilter')}</Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate(AppStackRoutes.SearchFilter, {
                    title: 'Filter & Sort',
                    calledFrom: 'WeeklyAds',
                    text: 'Sweet',
                  });
                }}
                style={[styles.downloadBtn]}
                testID="filter-sort-btn">
                <Icon style={[appStyles.smallIcon, styles.smallIcon]} name="sort-icon" size={28} />
                {getSelectedFiltersCount() ? (
                  <View style={[styles.badge]}>
                    <Text testID="filterBadgeCount" style={styles.badgeText}>
                      {getSelectedFiltersCount()}
                    </Text>
                  </View>
                ) : null}
              </TouchableOpacity>
            </View>
          </View>
          <BrComponent path="main" />
        </>
      ) : (
        <BrComponent path="main" />
      );
    };

    const renderEmptyListComponent = () => (
      <View style={styles.emptyListContainer}>
        <Text testID="noData" style={styles.emptyListText}>
          {t('noData')}
        </Text>
      </View>
    );

    const mapCollection = (item: ProductCollectionItem, product: Product): CollectionInfo => {
      return {
        collectionName: item.ItemProductCollectionItem?.DisplayName ?? '',
        itemCount: item.ItemCount ?? 0,
        productCollectionId: item.ItemProductCollectionId ?? 0,
        promotionalImage: item.MainImageUrl ?? '',
        product,
      };
    };

    const renderItem = ({ item, index }: ListRenderItemInfo<ProductList>) => {
      const subCollection = weeklyAd?.data?.ProductCollectionItems.find(
        (pci) =>
          (pci.ExtProductId === item.product.masterData.current?.masterVariant.sku ||
            item.product.masterData.current?.variants.some((v) => v.sku === pci.ExtProductId)) &&
          !!pci.ItemProductCollectionId,
      );

      if (!subCollection) {
        return <WeeklyAdsCard product={item} index={index} changeStore={onChangeStore} />;
      } else {
        return <WeeklyAdsCollectionCard collection={mapCollection(subCollection, item.product)} index={index} />;
      }
    };

    return weeklyAd.isLoading || !brxmPage || !brxmConfiguration ? (
      <LoadingScreen />
    ) : (
      //@ts-ignore
      <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
        <View style={[styles.weeklyAdsWrapper]}>
          <FlatList
            keyExtractor={(_, i) => i.toString()}
            numColumns={2}
            data={data.products.data}
            ListEmptyComponent={renderEmptyListComponent()}
            ListHeaderComponent={renderListHeaderComponent()}
            style={[styles.flatListContentContainer]}
            renderItem={renderItem}
            onEndReached={handleLoadMore}
            onEndReachedThreshold={0.5}
            refreshing={weeklyAd.isLoading}
            onRefresh={refetchWeeklyAds}
            ListFooterComponent={data.isLoading ? <ActivityIndicator /> : <></>}
            contentContainerStyle={{ paddingBottom: screenHeight * 0.1 }}
          />
        </View>
      </BrPage>
    );
  },
  { screenName: 'weekly-ad', showTombstone: true },
);

const styles = StyleSheet.create({
  weeklyAdsWrapper: {
    flex: 1,
    flexGrow: 1,
    minHeight: 100,
  },
  flatListContentContainer: {
    paddingBottom: Platform.select({
      ios: screenHeight * 0.12,
      android: screenHeight * 0.15,
    }),
    backgroundColor: colors.white,
  },
  flatListHeader: {
    width: '100%',
    flexDirection: 'row',
    backgroundColor: colors.white,
    justifyContent: 'flex-end',
    paddingVertical: 10,
    paddingLeft: 20,
    paddingRight: 15,
  },
  flatListHeaderTitleWrapper: {
    flex: 0.7,
    paddingLeft: 5,
  },
  flatListHeaderButtonWrapper: {
    flex: 0.3,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  downloadBtn: {
    paddingLeft: 15,
  },
  title: {
    textAlign: 'left',
  },
  smallIcon: {
    width: 20,
    height: 20,
  },
  resetLink: {
    color: colors.darkText,
    fontFamily: FontFamily.AleoBold,
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '80%',
    paddingTop: 30,
  },
  emptyListText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: 15,
    width: '75%',
    textAlign: 'center',
    paddingBottom: 20,
  },
  badge: {
    borderRadius: 10,
    height: 12,
    width: 12,
    top: scale(0),
    right: scale(0),
    backgroundColor: colors.secondary,
    position: 'absolute',
  },
  badgeText: {
    fontSize: scale(10),
    lineHeight: lineHeight(10),
    color: colors.white,
    textAlign: 'center',
    fontFamily: FontFamily.LarsseitBold,
  },
});

export default WeeklyAdsScreen;

import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { scale } from '@styles/constants';
import React, { FC } from 'react';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import Icon from '../Icon';

type link = {
  title?: string;
  action?: any;
};

type LinkListProps = {
  header: string;
  links?: link[];
};

const LinkList: FC<LinkListProps> = ({ header, links }) => {
  const { showAlertModal } = useCustomModalContext();
  const renderItem = ({ item, index }: { item: link; index: number }) => {
    const nav = () => {
      if (item.action) {
        item.action();
      } else {
        showAlertModal();
      }
    };

    return (
      <View style={styles.itemContainer}>
        <TouchableOpacity onPress={nav} style={styles.itemButton} testID={`${item.title}-${index}-Button`}>
          <Text style={styles.itemTitle} testID={`${item.title}-${index}`}>
            {item.title}
          </Text>
          <Icon size={16} name={'arrow-right'} />
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.header} testID={`${header}`}>
        {header}
      </Text>
      <FlatList keyExtractor={(_, i) => i.toString()} data={links || []} renderItem={renderItem} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  header: {
    fontFamily: FontFamily.LarsseitBold,
    color: colors.darkText,
    fontSize: scale(16),
    marginBottom: 12,
  },
  itemContainer: {
    borderTopWidth: 1,
    borderTopColor: colors.darkCream,
    height: 50,
    justifyContent: 'center',
  },
  itemTitle: {
    fontFamily: FontFamily.Larsseit,
    color: colors.text,
  },
  itemButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    alignItems: 'center',
    height: '100%',
  },
});

export default LinkList;

import { NavigationHeader, Screen } from '@components';
import HeaderTitle from '@components/HeaderTitle';
import PaymentMethods from '@components/PaymentMethods';
import { AccountStackRoutes, AccountStackScreenProps } from '@navigation/routes';
import { utilityStyles } from '@styles';
import { scale } from '@styles/constants';
import React from 'react';

type PaymentMethodsScreenProps = AccountStackScreenProps<AccountStackRoutes.PaymentMethods>;

const PaymentMethodsScreen = ({ navigation, route }: PaymentMethodsScreenProps) => {
  const { showBack } = route.params;

  return (
    <Screen testID="PaymentMethodsScreen" style={utilityStyles.pb0}>
      <NavigationHeader subStyle={[{ alignItems: 'flex-start' }, utilityStyles.pt2]} backIconstyle={{ marginLeft: scale(-6) }}>
        {showBack && <HeaderTitle testID="PaymentMethods">Payment Methods</HeaderTitle>}
      </NavigationHeader>
      <PaymentMethods
        {...{
          navigation,
          route,
        }}
      />
    </Screen>
  );
};

export default PaymentMethodsScreen;

import { QueryCache, QueryClient } from 'react-query';
import logger from './logger';

const queryCache = !__DEV__
  ? new QueryCache({
      onError: (error, query) => {
        __DEV__ && logger.warn('QueryCache error:', { ex: JSON.stringify(error), queryKey: JSON.stringify(query.queryKey) });
      },
      onSuccess: (_, query) => {
        __DEV__ && logger.debug('QueryCache success:', { queryKey: JSON.stringify(query.queryKey) });
      },
    })
  : undefined;

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: { queries: { useErrorBoundary: true, retry: 3 } },
});

import { Carousel, LoadingScreen, ProductCard } from '@components';
import Text from '@components/Text';
import withShopNavigator from '@components/hoc/withShopNavigator';
import { Image } from '@components/image';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { PagedArray } from '@fieldera-raleys/client-common';
import { FileType, ProductCollection } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useCancelToken } from '@hooks';
import { useCache } from '@hooks/useCache';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { productService } from '@services/brandywine';
import { useShopStore } from '@store';
import { appStyles } from '@styles';
import { containerWidth, screenHeight, screenWidth } from '@styles/constants';
import { ProductList, getProductPromotions, getProductsfromCommerceToolsWithHistory } from '@utils/productHelper';
import { getOffersAndCouponsByProductList } from '@utils/promotionHelper';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, ListRenderItemInfo, Platform, RefreshControl, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';

type CollectionListingScreenProps = ShopStackScreenProps<ShopStackRoutes.CollectionListing>;

type Collection = {
  id: number;
  itemCount: number;
  imageUrl: string;
  products?: Product[];
};

type State = {
  skuList: string[];
  subCollections: Collection[];
  bannerUrl: string;
};

const ProductCollectionCarousel = ({ collection }: { collection: Collection }): JSX.Element => {
  const [state, setState] = useState<{ isLoading: boolean; total: number; productList: ProductList[] }>({
    isLoading: true,
    total: 0,
    productList: [],
  });
  const { selectedStore } = useShopStore();
  const cancelToken = useCancelToken('ProductCollectionCarousel');
  const { isLoading, data } = useCache(`product_collection_${collection.id}`, () => productService.getProductCollectionById(collection.id, cancelToken), 60);
  const navigation = useNavigation();

  const SLIDE_GAP = 12;
  const CARD_WIDTH = screenWidth * 0.45;

  const carouselConfig = {
    slidesPerPage: 1,
    nextSlidePartial: CARD_WIDTH * 0.44,
    slideWidth: CARD_WIDTH,
    sgPerLine: 1,
  };

  useEffect(() => {
    const loadProductList = async (productCollection: ProductCollection) => {
      let skuList = productCollection.ProductCollectionItems.reduce((list, p) => {
        if (!p.ItemProductCollectionId && p.ExtProductId) {
          list.push(p.ExtProductId);
        }
        return list;
      }, [] as string[]);

      if (!skuList.length) {
        return;
      }

      const products = await getProductsfromCommerceToolsWithHistory(skuList.slice(0, 10));
      const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE ?? '1', skuList);
      const productList = getProductPromotions(promotions, products);

      productList.sort((a, b) => {
        return (
          (skuList.indexOf(a.product.masterData.current?.masterVariant.sku ?? '') ?? -1) -
          (skuList.indexOf(b.product.masterData.current?.masterVariant.sku ?? '') ?? -1)
        );
      });

      setState((prev) => ({ ...prev, isLoading: false, total: skuList.length, productList }));
    };

    if (!isLoading && data?.ProductCollectionId) {
      loadProductList(data);
    }
  }, [selectedStore?.number, data, isLoading]);

  const handleSeeAllOnPress = (id: number) => {
    navigation.dispatch(
      StackActions.push(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Shop,
        params: {
          screen: ShopStackRoutes.CollectionListing,
          params: {
            productCollectionId: id,
          },
        },
      }),
    );
  };

  const renderItem = useCallback(({ item, index }: ListRenderItemInfo<ProductList>) => {
    return (
      <ProductCard
        product={item.product}
        productCategory={{ parentId: 'collectionlisting', id: '', name: '', crumb: [], imageName: '', children: [], orderHint: 0.8 }}
        index={index}
        productPromotion={item.promotion}
      />
    );
  }, []);

  return isLoading || state.isLoading ? (
    <LoadingScreen />
  ) : data && data.ProductCollectionId ? (
    <>
      <View style={[styles.header]}>
        <View style={styles.offerHeader}>
          <Text testID="collectionDisplayName" style={[styles.collectionName]} numberOfLines={1}>
            {data.DisplayName}
          </Text>
          <TouchableOpacity
            style={styles.seeAllLinkWrapper}
            onPress={() => {
              handleSeeAllOnPress(data.ProductCollectionId);
            }}>
            <Text testID="seeAll" style={[appStyles.fontMobileBoldLinkLargeRight]}>
              See All {state.total < 100 ? state.total : ''}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <Carousel
        windwoSize={10}
        snapToInterval={carouselConfig.slideWidth + SLIDE_GAP}
        carouselstyle={styles.carouselstyle}
        contentContainerStyle={styles.contentContainerStyle}
        entities={state.productList || []}
        renderItem={renderItem}
        pagerStyle={styles.pagerStyle}
        pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
        pagerInactiveItemStyle={appStyles.carouselDotsSmall}
        showPager={true}
      />
    </>
  ) : (
    <></>
  );
};

const ListHeaderComponent = ({ banner, title, collections }: { banner: string; title: string; collections?: Collection[] }) => {
  return (
    <>
      <>
        {banner ? (
          <View style={[styles.collectionCard]}>
            <Image
              testID="bannerImage"
              style={[styles.collectionImage]}
              resizeMode={'contain'}
              source={{ cache: 'web', uri: banner.indexOf('//') === 0 ? 'https:' + banner : banner }}
            />
          </View>
        ) : (
          <View style={[styles.titleHeader]}>
            <View style={styles.offerHeader}>
              <Text testID="headerTitle" style={[styles.collectionName]} numberOfLines={1}>
                {title}
              </Text>
            </View>
          </View>
        )}
      </>
      {collections && collections.length > 0 && (
        <View>
          <FlatList
            keyExtractor={(_, i) => i.toString()}
            data={collections}
            renderItem={({ item }) => {
              return <ProductCollectionCarousel collection={item} />;
            }}
          />
          <View style={styles.emptyView} />
        </View>
      )}
    </>
  );
};

const CollectionListingScreen = withShopNavigator(
  ({ navigation, route }: CollectionListingScreenProps) => {
    const { productCollectionId } = route.params;
    const [state, setState] = useState<State>({ skuList: [], subCollections: [], bannerUrl: '' });
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [productList, setProductList] = useState<PagedArray<ProductList>>({ data: [], limit: appConstants.PAGE_SIZE, offset: 0, total: 0 });
    const { selectedStore } = useShopStore();
    const { isLoading, data, refetch, isRefetching } = useCache(
      `product_collection_${productCollectionId}`,
      () => productService.getProductCollectionById(productCollectionId),
      240,
    );

    const loadProductData = useCallback(
      async (page: number = 1) => {
        const start = (page - 1) * appConstants.PAGE_SIZE;
        const end = page * appConstants.PAGE_SIZE;
        const skuList = state.skuList.slice(start, end);
        const productData = await getProductsfromCommerceToolsWithHistory(skuList);
        const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE ?? '1', skuList);
        const productListWithPromotions = getProductPromotions(promotions, productData);

        productListWithPromotions.sort((a, b) => {
          return skuList.indexOf(a.sku) - skuList.indexOf(b.sku);
        });

        setProductList((prev) => ({
          ...prev,
          offset: end,
          data: [...(page === 1 ? [] : prev.data), ...productListWithPromotions],
        }));
      },
      [selectedStore?.number, state.skuList],
    );

    useEffect(() => {
      const parseCollectionData = async (productCollection: ProductCollection) => {
        productCollection.ProductCollectionItems.sort(
          (a, b) => (a.PageNumber ?? 999) - (b.PageNumber ?? 999) || (a.PageBlock ?? 999) - (b.PageBlock ?? 999) || a.SortOrder - b.SortOrder,
        );
        const bannerUrl = data?.ProductCollectionFiles?.find((f) => Number(f.FileType.Id) === FileType.MAIN_DETAIL_IMAGE)?.FileUrl.replace('/75/', '/0/') ?? '';
        const skuList =
          productCollection.ProductCollectionItems.reduce((list, p) => {
            if (!p.ItemProductCollectionId && p.ExtProductId) {
              list.push(p.ExtProductId);
            }
            return list;
          }, [] as string[]) ?? [];

        const subCollections = productCollection?.ProductCollectionItems?.reduce((list, item) => {
          if (item.ItemProductCollectionId && item.ItemCount) {
            list.push({ id: item.ItemProductCollectionId, itemCount: item.ItemCount, imageUrl: item.MainImageUrl ?? '' });
          }
          return list;
        }, [] as Collection[]);
        setProductList((prev) => ({ ...prev, offset: 0, total: skuList.length, data: [] }));
        setState((prev) => ({ ...prev, bannerUrl, subCollections, skuList }));
      };

      if (!isLoading && !isRefetching) {
        if (data && data.ProductCollectionId) {
          parseCollectionData(data);
        } else {
          navigation.pop();
          navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.CollectionsLanding } });
        }
      }
    }, [data, isLoading, isRefetching, navigation, selectedStore?.number]);

    useEffect(() => {
      if (state.skuList.length) {
        setIsDataLoading(true);
        loadProductData().finally(() => setIsDataLoading(false));
      }
    }, [loadProductData, state.skuList]);

    const renderItem = useCallback(({ item, index }: ListRenderItemInfo<ProductList>) => {
      return (
        <ProductCard
          product={item.product}
          productCategory={{ parentId: 'collectionlisting', id: '', name: '', crumb: [], imageName: '', children: [], orderHint: 0.8 }}
          index={index}
          productPromotion={item.promotion}
        />
      );
    }, []);

    const handleRefresh = async () => {
      if (!isRefetching) {
        setState((prev) => ({ ...prev, skuList: [], subCollections: [], bannerUrl: '' }));
        await refetch();
      }
    };

    const handleLoadMore = async () => {
      if (productList.offset > productList.total) {
        return;
      }
      if (!isDataLoading) {
        setIsDataLoading(true);
        const pageNumber = Math.floor(productList.offset / appConstants.PAGE_SIZE) + 1;
        loadProductData(pageNumber).finally(() => setIsDataLoading(false));
      }
    };

    return isLoading ? (
      <LoadingScreen />
    ) : (
      <View style={[styles.collectionWrapper]}>
        <FlatList
          contentContainerStyle={[styles.flatListContentContainer]}
          keyExtractor={(_, i) => i.toString()}
          data={productList.data}
          ListHeaderComponent={<ListHeaderComponent banner={state.bannerUrl} title={data?.DisplayName ?? ''} collections={state.subCollections} />}
          renderItem={renderItem}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.5}
          refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={handleRefresh} />}
          ListFooterComponent={isDataLoading ? <ActivityIndicator /> : <></>}
          numColumns={2}
        />
      </View>
    );
  },
  { screenName: 'collections', showTombstone: false },
);

const styles = StyleSheet.create({
  collectionWrapper: {
    width: screenWidth,
    flexGrow: 1,
    minHeight: 100,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: colors.sectionPad,
  },
  flatListContentContainer: {
    paddingBottom: Platform.select({
      ios: screenHeight * 0.12,
      android: screenHeight * 0.15,
    }),
  },
  collectionCard: {
    width: screenWidth,
    height: 177,
    justifyContent: 'center',
    alignItems: 'center',
  },
  collectionImage: {
    width: '100%',
    height: '100%',
  },
  pagerStyle: {
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    marginTop: 20,
    // marginBottom: 12,
  },
  titleHeader: {
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    marginTop: 10,
    // marginBottom: 12,
  },
  offerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  seeAllLinkWrapper: {
    marginLeft: 16,
    alignSelf: 'center',
  },
  carouselstyle: {
    marginTop: -5,
  },
  contentContainerStyle: {
    paddingVertical: 0,
  },
  emptyView: {
    height: 20,
  },
  collectionName: {
    ...appStyles.customizeH2,
    flex: 1,
    paddingTop: 12,
  },
});

export default CollectionListingScreen;

import { LoadingScreen } from '@components';
import Text from '@components/Text';
import OfferCard from '@components/somethingExtra/OfferCard';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import useCancelToken from '@hooks/useCancelToken';
import { useOffersStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { FC, useState } from 'react';
import { FlatList, Platform, RefreshControl, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

interface DigitalCouponsScreenProps {
  style?: StyleProp<ViewStyle>;
  currTab?: string;
}

const DigitalCouponsScreen: FC<DigitalCouponsScreenProps> = ({ style, currTab }): JSX.Element => {
  const { availableDigitalCoupons, acceptedDigitalCoupons, fetchDigitalCoupons, digitalCouponsState } = useOffersStore();
  const allOffers = availableDigitalCoupons.concat(acceptedDigitalCoupons);

  const [currToggle, setCurrToggle] = useState(currTab ?? 'All');
  const cancelToken = useCancelToken();
  const toggleButtons = [
    {
      name: 'All',
    },
    {
      name: 'Clipped',
    },
  ];

  const refetchCoupons = () => {
    fetchDigitalCoupons(cancelToken);
  };
  const getTabNameWithCount = (tabName: string) => {
    const totalOffers =
      tabName === 'Clipped'
        ? acceptedDigitalCoupons.length > 0
          ? `(${acceptedDigitalCoupons.length ?? 0})`
          : ''
        : allOffers.length > 0
          ? `(${allOffers.length ?? 0})`
          : '';
    return tabName + ' ' + totalOffers;
  };

  const renderListHeaderComponent = () => {
    return (
      <>
        <View style={styles.toggleBar}>
          <View style={styles.toggleWrapper}>
            {toggleButtons.map((tab) => {
              return (
                <TouchableOpacity
                  testID={`digitalCoupons-${tab.name}-Button`}
                  key={tab.name}
                  onPress={() => setCurrToggle(tab.name)}
                  style={[styles.toggle, { backgroundColor: currToggle === tab.name ? colors.white : colors.darkCream }]}>
                  <Text style={styles.tabTitle}>{getTabNameWithCount(tab.name)}</Text>
                </TouchableOpacity>
              );
            })}
          </View>
        </View>
      </>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const EmptyListComponent = (): JSX.Element => {
    return allOffers ? (
      <View style={styles.noItemFoundView}>
        <View style={[styles.emptyListContainer]} testID="noSearchResults">
          <Text testID="noItemsFound" numberOfLines={2} style={[appStyles.bodySmallRegular, styles.emptyList]}>
            There are currently no SE Digital Coupons available.
          </Text>
        </View>
      </View>
    ) : (
      <></>
    );
  };

  return digitalCouponsState === 'loading' ? (
    <LoadingScreen />
  ) : allOffers.length > 0 ? (
    <>
      <View style={[styles.offersWrapper, style]}>
        <FlatList
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          style={styles.listView}
          ListHeaderComponent={renderListHeaderComponent()}
          refreshControl={<RefreshControl refreshing={false} onRefresh={refetchCoupons} />}
          keyExtractor={(_, i) => i.toString()}
          data={currToggle === 'All' ? allOffers : acceptedDigitalCoupons}
          renderItem={({ item, index }) => {
            return (
              <View style={styles.offerWrapper}>
                <OfferCard item={item} index={index} calledFrom={'DigitalCoupons'} />
              </View>
            );
          }}
          numColumns={2}
          directionalLockEnabled
          contentContainerStyle={[utilityStyles.pb5, styles.contentContainer]}
          ListEmptyComponent={<EmptyListComponent />}
        />
      </View>
    </>
  ) : (
    <>
      <View style={styles.whitebox}>
        <Text
          testID="offersText"
          style={[appStyles.bodyLargeCenter, { fontFamily: FontFamily.LarsseitBold, width: digitalCouponsState !== 'error' ? '70%' : '85%' }]}>
          {digitalCouponsState === 'error' ? "We're sorry, there seems to be a technical problem." : 'There are currently no SE Digital Coupons available.'}
        </Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  offersWrapper: {
    width: screenWidth,
    flexGrow: 1,
    minHeight: 100,
    marginTop: 10,
    paddingBottom: Platform.select({ android: 50, ios: 50 }),
    alignSelf: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    alignItems: 'center',
  },
  whitebox: {
    padding: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    flex: 1,
    width: screenWidth,
    height: '100%',
    alignSelf: 'center',
  },
  listView: {
    marginLeft: -12,
  },
  toggleBar: {
    alignSelf: 'center',
    width: 240,
    height: 30,
    borderRadius: 5,
    backgroundColor: colors.darkCream,
    flexDirection: 'row',
    padding: 1,
    marginBottom: 30,
    borderColor: colors.darkCream,
    borderWidth: 0.2,
  },
  toggleWrapper: {
    flexDirection: 'row',
  },
  toggle: {
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  tabTitle: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'center',
    opacity: 1,
    color: colors.darkText,
  },
  offerWrapper: {
    marginBottom: 16,
    marginLeft: 12,
  },
  searchHeaderWrapper: {
    width: screenWidth - 72,
    marginLeft: 12,
    flexDirection: 'row',
  },
  searchBarWrapper: {
    flex: 1,
    alignContent: 'center',
    marginTop: 30,
    paddingLeft: 12,
    marginBottom: 30,
    backgroundColor: 'white',
    flexDirection: 'row',
    borderRadius: 12.5,
    borderWidth: 1,
    borderColor: colors.lineSeparator,
    borderStyle: 'solid',
    height: 48,
  },
  searchTextInput: {
    alignSelf: 'center',
    padding: 5,
    width: '70%',
    fontSize: scale(15),
    fontFamily: FontFamily.LarsseitLight,
  },
  iconWrapper: {
    padding: 5,
    paddingBottom: 5,
    alignSelf: 'center',
  },
  sortIconWrapper: {
    alignSelf: 'center',
    padding: 5,
    paddingLeft: 10,
  },
  noItemFoundView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: 45,
  },
  emptyList: {
    textAlign: 'center',
    width: '70%',
    lineHeight: lineHeight(25),
  },
});

export default DigitalCouponsScreen;

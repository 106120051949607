import AsyncStorage from '@react-native-async-storage/async-storage';
import { StateStorage } from 'zustand/middleware';

const asyncStorage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => AsyncStorage.getItem(name),
  setItem: async (name: string, value: string): Promise<void> => AsyncStorage.setItem(name, value),
  removeItem: async (name: string): Promise<void> => AsyncStorage.removeItem(name),
};

export default asyncStorage;

import { CloseButton, KeyboardAvoidingScreen, Text } from '@components';
import { ErrorMessage, Form, FormField, SubmitButton } from '@components/forms';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AlternateContactTypes, BirthdayClubMember } from '@fieldera-raleys/client-common';
import { formatBirthDay } from '@fieldera-raleys/client-common/utils';
import { AccountStackRoutes, AccountStackScreenProps, AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useCardAnimation } from '@react-navigation/stack';
import { userService } from '@services/brandywine';
import { useUserProfileStore } from '@store';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Keyboard, Platform, ScrollView, StyleSheet, TextInput, View } from 'react-native';
import * as Yup from 'yup';
import helpers from '../../utils/helpers';

type AddBirthdayClubMemberScreenPrrops = AccountStackScreenProps<AccountStackRoutes.AddBirthdayClubMember>;

const AddBirthdayClubMemberScreen = ({ navigation }: AddBirthdayClubMemberScreenPrrops) => {
  const initialValue = useRef<BirthdayClubMember>({
    name: '',
    birthday: '',
  });

  const { t } = useTranslation('editProfileScreen');
  const { current } = useCardAnimation();

  const [loadingButton, setLoadingButton] = useState(false);
  const { userProfile } = useUserProfileStore();

  const nameRef = useRef<TextInput>(null);
  const birthdayRef = useRef<TextInput>(null);
  const errorMessageRef = useRef<string>();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    birthday: Yup.string()
      .required()
      .matches(helpers.BIRTH_DATE_REGEX, t('birthdayValidationMessage'))
      .label('Birthday')
      .test('Birthday', t('birthdayValidationMessage'), (value) => {
        const birthMonth = value && value.length === 5 && value.split('/')[0].trim();
        const birthDate = value && value.length === 5 && value.split('/')[1].trim();

        if (birthMonth === '02' && birthDate === '29') {
          return true;
        }

        return value ? dayjs(value, 'MM/DD', true).isValid() : false;
      }),
  });

  const onSubmit = async ({ name, birthday }: BirthdayClubMember, actions: { resetForm: () => void }) => {
    errorMessageRef.current = '';
    setLoadingButton(true);

    Keyboard.dismiss();

    const nameArray = name.replace(/\s+/g, '~').split('~');
    const firstName = nameArray[0].trim();

    let lastName;
    if (nameArray.length > 1) {
      lastName = nameArray[1].trim();
    }

    const birthMonth = birthday.split('/')[0].trim();
    const birthDate = birthday.split('/')[1].trim();

    userProfile &&
      userService
        .addAlternateContact(
          {
            firstName: firstName,
            lastName: lastName ?? '',
            birthMonth: birthMonth,
            birthDay: birthDate,
            alternateContactId: -1,
            emailAddress: userProfile.email,
            phoneNumber: userProfile.phone,
            faxNumber: userProfile.phone,
            alternateContactTypeID: AlternateContactTypes.Other.toString(),
            alternateContactTypeValue: AlternateContactTypes[AlternateContactTypes.Other],
            alternateContactTypeIsActive: true,
          },
          userProfile.sepiNumber,
        )
        .then(() => {
          setLoadingButton(false);
          actions.resetForm();

          navigation.canGoBack()
            ? navigation.goBack()
            : navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Account, params: { screen: AccountStackRoutes.EditProfile } });
        })
        .catch(() => {
          errorMessageRef.current = 'Member could not be added at this time';
          setLoadingButton(false);
        });
  };

  return (
    <>
      <Animated.View
        style={[
          styles.container,
          {
            transform: [
              {
                translateY: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [screenHeight * 0.65, 0],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}>
        <View style={styles.modal}>
          <View style={styles.header}>
            <CloseButton style={styles.closeButton} />
          </View>
          <View style={styles.textWrapper}>
            <Text style={styles.h3BoldCenter} testID="AddBDClubMemberTitle">
              {t('AddBDClubMemberTitle')}
            </Text>
          </View>
          <Form initialValues={initialValue.current} onSubmit={onSubmit} validationSchema={validationSchema}>
            <KeyboardAvoidingScreen>
              <ScrollView bounces={false} contentContainerStyle={[appStyles.container, styles.scrollView]} keyboardShouldPersistTaps={'handled'}>
                <View style={[styles.form, { backgroundColor: colors.cream }]}>
                  <ErrorMessage error={errorMessageRef.current} visible={!!errorMessageRef.current} style={styles.errorText} testID={'errorMessage'} />
                  <FormField
                    testID="name"
                    autoCapitalize="words"
                    autoFocus={true}
                    autoCorrect={false}
                    name="name"
                    label="Name"
                    textContentType="givenName"
                    ref={nameRef}
                    returnKeyType="next"
                    onSubmitEditing={() => birthdayRef?.current?.focus()}
                  />
                  <FormField
                    testID="birthday"
                    label="Birthday"
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="number-pad"
                    maxLength={5}
                    name="birthday"
                    placeholder="MM/DD"
                    textContentType="none"
                    returnKeyType="done"
                    ref={birthdayRef}
                    formatter={formatBirthDay}
                    topRight={t('giftLimitMessage')}
                    topRightStyle={[styles.fontMobileBodySmBoldRed]}
                  />
                </View>
                <View style={[styles.addMemberBar]}>
                  <SubmitButton title="Add Member" buttonStyle={styles.updateButton} isButtonLoading={loadingButton} testID="addMemberBtn" />
                </View>
              </ScrollView>
            </KeyboardAvoidingScreen>
          </Form>
        </View>
      </Animated.View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: Platform.select({
      ios: screenHeight * 0.75,
      android: screenHeight * 0.75,
    }),
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  modal: {
    flex: 1,
    alignContent: 'space-between',
  },
  header: {
    height: 50,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    textAlign: 'center',
  },
  closeButton: {
    left: 16,
    top: 16,
  },
  textWrapper: {
    paddingHorizontal: 25,
    paddingBottom: 8,
  },
  bodyLargeCenter: {
    ...appStyles.bodyLargeCenter,
    marginTop: 15,
    fontSize: scale(16),
  },
  form: {
    width: '100%',
    paddingHorizontal: 20,
    alignSelf: 'center',
    marginTop: 0,
  },
  errorWrapper: {
    width: 330,
    height: 25,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: scale(16),
    fontFamily: 'Larsseit-Light',
  },
  errorBorder: {
    borderColor: colors.red,
  },
  buttonsContainer: {
    marginTop: 8,
    marginBottom: 25,
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
  },
  boldLinkLargeCenter: {
    ...appStyles.boldLinkLargeCenter,
    marginTop: 10,
  },
  needHelpText: {
    fontSize: scale(15),
    fontFamily: FontFamily.LarsseitLight,
    lineHeight: lineHeight(15),
  },
  modalContentStyle: {
    width: screenWidth,
    paddingHorizontal: 16,
    paddingBottom: 8,
  },
  modalHeaderStyle: {
    justifyContent: 'center',
  },
  scrollView: {
    paddingHorizontal: 20,
    height: 390,
  },
  updateButton: {
    width: screenWidth * 0.4,
    height: '70%',
    backgroundColor: colors.darkCream,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    padding: 0,
  },
  addMemberBar: {
    width: screenWidth,
    top: 25,
    bottom: 0,
    alignSelf: 'center',
    height: scale(56),
    alignItems: 'center',
    justifyContent: 'center',
  },
  fontMobileBodySmBoldRed: {
    ...appStyles.fontMobileBodySmBoldRed,
    top: -29,
  },
});

export default AddBirthdayClubMemberScreen;

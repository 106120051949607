import { Accordion, Icon, Image, LoadingScreen, Modal, RadioButton, ZoomBox } from '@components';
import CustomizeFooter from '@components/CustomizeFooter';
import Text from '@components/Text';
import { assets } from '@config/assets';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Category, ChosenItem, Product } from '@fieldera-raleys/client-commercetools/schema';
import { CustomizeStackRoutes, CustomizeStackScreenProps } from '@navigation/routes';
import { commerceService } from '@services';
import { productService } from '@services/commerceTools';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { lineHeight, locale, scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import { filterUnavailableProducts } from '@utils/helpers';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FlatList, LogBox, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';

interface ThemeScreenProps extends CustomizeStackScreenProps<CustomizeStackRoutes.Theme> {
  sizes?: ChosenItem[];
}

interface ThemeItemProps {
  item: Product;
  limit: number;
  index?: number | string;
  selectedItems: ChosenItem[];
  itemCount: number;
  setSelectedItems: Dispatch<SetStateAction<ChosenItem[]>>;
  setItemCount: Dispatch<SetStateAction<number>>;
}

const ThemeItem: FC<ThemeItemProps> = ({ item, selectedItems, setSelectedItems, itemCount, setItemCount }): JSX.Element => {
  const [showZoom, setShowZoom] = useState(false);
  const [zoomInfo, setZoomInfo] = useState({ image: undefined, title: '' });
  const toggleZoom = () => {
    var info = {
      image: getImageSource(),
      title: item?.masterData?.current?.name ?? '',
    };
    setZoomInfo(info);
    setShowZoom(true);
  };
  var price: number;
  var priceDigits: number;
  if (item?.masterData?.current?.masterVariant?.price) {
    price = item?.masterData?.current?.masterVariant?.price?.value.centAmount ?? 0;
    priceDigits = item?.masterData?.current?.masterVariant?.price?.value.fractionDigits ?? 0;
  }

  const setSelection = () => {
    if (selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name)) {
      setSelectedItems(selectedItems.filter((selection) => selection.itemName !== item?.masterData?.current?.name));
      setItemCount(itemCount - 1);
    } else {
      setSelectedItems([
        {
          id: item?.id,
          sku: item?.masterData?.current?.masterVariant?.sku ?? '',
          itemName: item?.masterData?.current?.name ?? '',
          cost: price ? price / 10 ** priceDigits ?? 0 : 0,
          image: `${
            item?.masterData?.current?.masterVariant?.images[0]?.url.indexOf('//') === 0
              ? 'https:' + item?.masterData?.current?.masterVariant?.images[0]?.url
              : item?.masterData?.current?.masterVariant?.images[0]?.url
          }`,
          // image: item?.masterData?.current?.masterVariant?.images[0]?.url,
          product: item,
        },
      ]);
      setItemCount(itemCount + 1);
    }
  };

  const fallbackImage = assets.imageComingSoon;
  const getImageSource = () => {
    let url;
    if (item?.masterData?.current?.masterVariant?.images[0]?.url) {
      url = {
        cache: 'web',
        uri: `${
          item?.masterData?.current?.masterVariant?.images[0]?.url.indexOf('//') === 0
            ? 'https:' + item?.masterData?.current?.masterVariant?.images[0]?.url
            : item?.masterData?.current?.masterVariant?.images[0]?.url
        }`,
      };
    } else {
      url = fallbackImage;
    }

    return url;
  };

  return (
    <>
      <Modal
        style={{ height: screenHeight * 0.9, padding: 0, backgroundColor: colors.cream }}
        contentStyle={styles.modalContent}
        title={''}
        headerStyle={{ height: 50, alignItems: 'center', justifyContent: 'center' }}
        visible={showZoom}
        okButtonStyle={{ width: 150 }}
        cancelButtonType={'secondary'}
        cancelButtonOnPress={() => setShowZoom(false)}
        cancelButtonText={'Close'}
        showCancel={false}>
        <View style={styles.zoomedImgContainer}>
          <ZoomBox>
            <Image testID="zomInfoImage" resizeMode={'contain'} source={zoomInfo.image} style={styles.image} />
          </ZoomBox>
        </View>
        <View style={{ alignItems: 'center', width: screenWidth, marginVertical: 12, justifyContent: 'center' }}>
          <Text testID="itemLabel" numberOfLines={2} style={[styles.itemLabel, { textAlign: 'center', alignSelf: 'center' }]}>
            {zoomInfo?.title || 'unavailable'}
          </Text>
        </View>
      </Modal>
      <TouchableOpacity activeOpacity={1} onPress={setSelection} style={styles.listItem}>
        <TouchableOpacity onPress={toggleZoom} style={styles.itemImageWrapper}>
          <Image resizeMode={'contain'} source={getImageSource()} style={styles.image} />
        </TouchableOpacity>
        <View style={styles.themeItemAction}>
          <View style={styles.checkButton}>
            <RadioButton
              toggle={setSelection}
              containerStyle={{ width: 25 }}
              buttonSize={25}
              buttonStyle={[appStyles.mediumIcon]}
              value={selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name)}
            />
          </View>
          <Text
            // numberOfLines={2}
            testID="itemName"
            style={[
              styles.itemLabel,
              appStyles.customizeStepLabel,
              {
                fontFamily: selectedItems?.some((selection) => selection.itemName === item?.masterData?.current?.name) ? 'Larsseit-Bold' : 'Larsseit-Light',
              },
            ]}>
            {item?.masterData?.current?.name}
          </Text>
        </View>
      </TouchableOpacity>
    </>
  );
};

const ThemeScreen: FC<ThemeScreenProps> = ({ route, navigation }): JSX.Element => {
  const { pageItem = '', limit, catIds } = route.params;
  const { selectedIngredients, setSelectedIngredients, calculateSubtotal, currStep } = useCustomizeStore();
  const [selectedItems, setSelectedItems] = useState<ChosenItem[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  useEffect(() => {
    refetch();
    setSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageItem]);

  const fetchProducts = async () => {
    var selectionProducts = [];
    const themeCats = await commerceService.getSubcategoryByParentId(catIds);
    themeCats?.results?.sort((a: Category, b: Category) => +a?.orderHint - +b?.orderHint);

    for (let i = 0; i < themeCats.results.length; i++) {
      var themeProductsUnfiltered = await productService.getProducts({ catId: themeCats.results[i].id });
      var themeProducts = filterUnavailableProducts(themeProductsUnfiltered);
      themeProducts?.sort(
        (a, b) =>
          +(a?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === themeCats.results[i].id)?.orderHint || 0.8) -
          +(b?.masterData?.current?.categoryOrderHints?.find((coh) => coh.categoryId === themeCats.results[i].id)?.orderHint || 0.8),
      );
      var theme = {
        name: themeCats.results[i].name[`${locale}`],
        choices: themeProducts,
      };
      selectionProducts.push(theme);
    }
    return selectionProducts;
  };
  const { data: choices, status, refetch } = useQuery('theme-selections-list', fetchProducts, { staleTime: 0, cacheTime: 0, initialData: undefined });
  const [accordionStates, setAccordionStates] = useState<boolean[]>(Array(choices?.length ?? 0).fill(false));

  useEffect(() => {
    if (status === 'success') {
      setSelected();
      setIsLoadingProducts(false);
    } else {
      setIsLoadingProducts(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices, status]);

  useEffect(() => {
    if (selectedIngredients.some((ing) => ing.ingredientName === pageItem)) {
      var tempArr = selectedIngredients;
      var ingredientIndex = selectedIngredients?.findIndex((ing) => ing.ingredientName === pageItem);

      tempArr[ingredientIndex] = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients(tempArr);
      // console.log('set tsx.239 selectionscreen: ', JSON.stringify(selectedIngredients));
    } else if (selectedItems.length) {
      var newIngredient = {
        ingredientName: pageItem,
        sortOrderHint: `${currStep}|${pageItem}`,
        selections: selectedItems,
      };
      setSelectedIngredients([...selectedIngredients, newIngredient]);
    } else if (selectedItems.length === 0) {
      var tempArr = selectedIngredients;
      var filteredArr = tempArr.filter((ingr) => ingr.ingredientName !== pageItem);

      setSelectedIngredients(filteredArr);
    }
    calculateSubtotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    var forwardEnabled = !selectedItems[0] || itemCount < (limit ?? 1) ? false : true;
    navigation.setParams({ forwardNavEnabled: forwardEnabled });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCount, pageItem, currStep]);

  const setSelected = () => {
    if (selectedIngredients.length > 0) {
      if (selectedIngredients.some((ingr) => ingr.ingredientName === pageItem)) {
        var tempArr = selectedIngredients;
        var ingredientIndex = selectedIngredients?.findIndex((ing) => ing.ingredientName === pageItem);
        const ingred = tempArr[ingredientIndex].selections[0];
        var ingredCat = choices?.find((c) => c.choices.find((ch) => ch.key === tempArr[ingredientIndex].selections[0].sku));
        // var ingredImg = ingredCat?.choices?.find((i) => i.key === tempArr[ingredientIndex]?.selections[0]?.sku)?.masterData?.current?.masterVariant?.images[0]
        //   ?.url;
        var ingredImg = `${
          ingredCat?.choices
            ?.find((i) => i.key === tempArr[ingredientIndex]?.selections[0]?.sku)
            ?.masterData?.current?.masterVariant?.images[0]?.url.indexOf('//') === 0
            ? 'https:' +
              ingredCat?.choices?.find((i) => i.key === tempArr[ingredientIndex]?.selections[0]?.sku)?.masterData?.current?.masterVariant?.images[0]?.url
            : ingredCat?.choices?.find((i) => i.key === tempArr[ingredientIndex]?.selections[0]?.sku)?.masterData?.current?.masterVariant?.images[0]?.url
        }`;
        setSelectedItems([
          {
            ...ingred,
            image: ingredImg,
          },
        ]);

        setItemCount(tempArr[ingredientIndex].selections.length);
      }
    }
  };

  const setAccordion = (i: number) => {
    var curr = [...accordionStates];
    curr[i] = !curr[i];
    setAccordionStates(curr);
  };

  useEffect(() => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  }, []);

  return isLoadingProducts ? (
    <LoadingScreen />
  ) : (
    <View style={styles.container}>
      <ScrollView bounces={true} style={styles.body}>
        <Text testID="selectTheme" style={[styles.selectText, utilityStyles.px3]}>
          Select Theme
        </Text>

        {/* TODO Map categories to accordions */}
        <View style={{ paddingBottom: 100 }}>
          {choices?.map((choice, index) => {
            return (
              <Accordion
                key={`${choice.name}-a${index}`}
                setNextState={() => setAccordion(index)}
                style={styles.accordionWrapper}
                shadowEnabled={false}
                headerStyle={[styles.accordion, [styles.bottomBorder, { borderColor: colors.greyBorder }]]}
                title={choice.name}
                textStyle={appStyles.bodySmallLeft}
                isOpen={accordionStates[index]}
                icon={<Icon name={!accordionStates[index] ? 'arrow-down' : 'arrow-up'} size={20} style={{ marginTop: 10, marginRight: 8 }} />}>
                <FlatList
                  style={styles.listContainer}
                  contentContainerStyle={[styles.contentContainer, utilityStyles.px3]}
                  numColumns={2}
                  bounces={false}
                  data={choice.choices}
                  extraData={choice.choices}
                  keyExtractor={(_, i) => i.toString()}
                  renderItem={({ item, index }) => {
                    return (
                      <ThemeItem
                        key={index.toString()}
                        item={item}
                        index={index}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        limit={limit ? +limit : 1}
                        itemCount={itemCount}
                        setItemCount={setItemCount}
                      />
                    );
                  }}
                />
              </Accordion>
            );
          })}
        </View>
      </ScrollView>
      <View style={styles.footerWrapper}>
        <CustomizeFooter disabled={!selectedItems[0] || itemCount < (limit ?? 1) ? true : false} />
        {selectedItems[0]?.itemName && (
          <View style={styles.hoveringSelection}>
            <View style={styles.currentSelectionImage}>
              <Image testID="selectedImteImage" resizeMode={'contain'} source={{ cache: 'web', uri: `${selectedItems[0]?.image}` }} style={styles.image} />
            </View>
            <View style={{ width: '65%' }}>
              <Text testID="currentTheme" style={[appStyles.bodySmallLight, { textAlign: 'left' }]}>
                Current Theme Selection:
              </Text>
              <Text testID="selectedItemName" numberOfLines={1} style={[appStyles.bodySmallRed, { textAlign: 'left' }]}>
                {selectedItems[0].itemName}
              </Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: screenWidth,
    flexGrow: 1,
    paddingVertical: 16,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 12,
  },
  modalContent: {
    backgroundColor: colors.cream,
    alignItems: 'center',
    justifyContent: 'center',
    width: screenWidth,
    marginTop: -20,
    height: '100%',
  },
  zoomedImgContainer: {
    overflow: 'hidden',
    width: screenWidth,
    height: screenWidth,
    alignSelf: 'center',
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.darkCream,
  },
  body: {
    flex: 1,
    width: screenWidth,
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  hoveringSelection: {
    position: 'absolute',
    top: screenHeight < 740 ? -100 : -80,
    alignSelf: 'center',
    backgroundColor: colors.white,
    width: '100%',
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingHorizontal: 10,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 1.5 },
    shadowOpacity: 0.2,
    shadowRadius: 1.5,
    elevation: 3,
    zIndex: 100,
    borderWidth: 0,
  },
  currentSelectionImage: {
    width: 60,
    height: 60,
    backgroundColor: colors.white,
  },
  pickLimitText: {
    fontSize: scale(13),
    fontFamily: 'Aleo-Bold',
    color: 'rgba(0,0,0,0.4)',
  },
  itemText: {
    fontSize: scale(20),
    fontFamily: FontFamily.AleoBold,
    color: colors.darkText,
    marginBottom: 15,
  },
  selectText: {
    fontSize: scale(20.5),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
    marginBottom: 20,
  },
  listContainer: {
    width: '100%',
    // height: '100%',
  },
  contentContainer: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingBottom: 0,
  },
  listItem: {
    width: (screenWidth - 32) * 0.4,
    minHeight: screenWidth * 0.45,
    alignItems: 'center',
    margin: 15,
  },
  themeItemAction: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 15,
    width: '100%',
    minHeight: 50,
    // backgroundColor: 'gold',
  },
  itemImageWrapper: {
    backgroundColor: colors.white,
    width: screenWidth * 0.37,
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.16 : screenHeight * 0.2) : screenHeight > 640 ? screenHeight * 0.19 : screenHeight * 0.21,
    // flex: 0.85,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    shadowColor: colors.black,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2.22,

    elevation: 3,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  actionWrapper: {
    paddingTop: 5,
    flex: 1,
    // height: ,
    width: '100%',
    justifyContent: 'space-between',
  },
  checkBoxWrapper: {
    flexDirection: 'row',
    alignContent: 'center',
  },
  checkButton: {
    marginRight: 8,
    justifyContent: 'center',
    marginLeft: -8,
    marginTop: -8,
  },
  checkBoxTitle: {
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: lineHeight(15),
    alignSelf: 'center',
    paddingTop: 4,
  },
  itemLabel: {
    fontSize: scale(15),
    lineHeight: lineHeight(16),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 10,
    marginTop: 3,
    textAlign: 'left',
  },
  quantityContainer: {
    height: '100%',
    justifyContent: 'space-between',
  },
  quantityBoxWrapper: {
    flexDirection: 'row',
    width: '100%',
    height: 30,
    justifyContent: 'center',
    backgroundColor: colors.white,
    padding: 0,
    marginBottom: 5,
    borderRadius: 100,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1,
  },
  decQuantityButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  incQuantityButton: {
    justifyContent: 'center',
    alignItems: 'center',
    transform: [{ translateY: 2 }],
    width: '30%',
  },
  quantityButtonText: {
    fontSize: scale(22),
    fontFamily: 'Larsseit',
    color: colors.darkText,
  },
  quantityCounterTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: '#c69480',
  },
  countText: {
    fontSize: scale(15),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
  },
  // footerWrapper: {
  //   // backgroundColor: 'gold',
  //   paddingVertical: 0,
  //   // marginTop: -75,
  //   // marginBottom: -140,
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flex: 1,
  //   width: screenWidth,
  //   alignSelf: 'center',
  //   paddingHorizontal: 32,
  // },
  footerWrapper: {
    paddingTop: 0,
    alignItems: 'center',
    // justifyContent: 'center',
    flex: 0.5,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 32,
    marginBottom: -100,
  },
  accordionContainer: {
    flexGrow: 1,
    width: screenWidth,
    alignSelf: 'center',
    // backgroundColor: 'gold',
    marginBottom: 40,
    paddingHorizontal: 16,
    paddingTop: 40,
  },
  accordionWrapper: {
    width: screenWidth,
    alignSelf: 'center',
  },
  accordionContentContainer: {
    flexGrow: 1,
    backgroundColor: colors.white,
    padding: 16,
    paddingTop: 0,
  },
  accordionContent: {
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingBottom: 32,
  },
  accordion: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '92%',
    backgroundColor: colors.cream,
    paddingHorizontal: 0,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderColor: colors.darkCream,
  },
});

export default ThemeScreen;

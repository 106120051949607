import { LoadingScreen } from '@components';
import Text from '@components/Text';
import withShopNavigator from '@components/hoc/withShopNavigator';
import { Image } from '@components/image';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { FileType } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useCancelToken } from '@hooks';
import { useCache } from '@hooks/useCache';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes, ShopStackScreenProps } from '@navigation/routes';
import productService from '@services/brandywine/productService';
import { useShopStore, useUserProfileStore } from '@store';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo, RefreshControl, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';

export type ProductCollectionItem = {
  productCollectionID: number;
  fileURL: string;
  productsCount: number;
};

type CollectionsLandingScreenProps = ShopStackScreenProps<ShopStackRoutes.CollectionsLanding>;

const CollectionsLandingScreen = withShopNavigator(
  ({ navigation }: CollectionsLandingScreenProps) => {
    const { t } = useTranslation('productCollection');
    const canceltoken = useCancelToken('CollectionsLandingScreen');
    const { userProfile } = useUserProfileStore();
    const { selectedStore } = useShopStore();
    const { isLoading, data, refetch, isRefetching } = useCache(
      `product_collections_${selectedStore?.number ?? Config.BRSM_DEFAULT_STORE}`,
      async () => {
        const productCollection = await productService.getProductCollections(
          [3],
          selectedStore?.number ?? Config.BRSM_DEFAULT_STORE,
          String(userProfile?.sepiNumber),
          canceltoken,
        );
        return productCollection
          .sort((p1, p2) => (p1.SortOrder < p2.SortOrder ? -1 : 0))
          .map((p) => {
            let productCollectionID = p.ProductCollectionId;

            let bannerURL = p.ProductCollectionFiles.find((f) => Number(f.FileType.Id) === FileType.MAIN_LIST_IMAGE)?.FileUrl.replace('/75/', '/0/') ?? '';
            let productsCount = p.ProductCollectionItems.length;

            return {
              productCollectionID: productCollectionID,
              fileURL: bannerURL,
              productsCount: productsCount,
            } as ProductCollectionItem;
          })
          .flatMap((url) => url)
          .filter((prod) => prod.fileURL.length > 0 && prod.productsCount > 0);
      },
      240,
    );

    const renderEmptyListComponent = () =>
      !isLoading ? (
        <View style={styles.emptyListContainer}>
          <Text testID="noData" style={styles.emptyListText}>
            {t('noData')}
          </Text>
        </View>
      ) : (
        <></>
      );

    const navigateToDetailScreen = (item: ProductCollectionItem) => {
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Shop,
        params: {
          screen: ShopStackRoutes.CollectionListing,
          params: {
            productCollectionId: item.productCollectionID,
          },
        },
      });
    };

    const renderItem = ({ item }: ListRenderItemInfo<ProductCollectionItem>) => {
      return (
        <TouchableOpacity
          style={[styles.collectionCardWrapper]}
          onPress={() => {
            navigateToDetailScreen(item);
          }}>
          <View style={[styles.collectionCard]}>
            {item.fileURL && item.fileURL.length > 0 && (
              <Image
                testID="collectionImage"
                style={[styles.collectionImage]}
                resizeMode={'contain'}
                source={{ cache: 'web', uri: item.fileURL.indexOf('//') === 0 ? 'https:' + item.fileURL : item.fileURL }}
              />
            )}
          </View>
        </TouchableOpacity>
      );
    };

    return isLoading ? (
      <LoadingScreen />
    ) : (
      <View style={[styles.container]}>
        <FlatList
          keyExtractor={(item) => String(item.productCollectionID)}
          data={data}
          ListEmptyComponent={renderEmptyListComponent()}
          renderItem={renderItem}
          contentContainerStyle={{ paddingBottom: screenHeight * 0.26 }}
          refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
          refreshing={isRefetching}
        />
      </View>
    );
  },
  { screenName: 'collections', showTombstone: false },
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: screenHeight,
  },
  collectionCardWrapper: {
    width: screenWidth,
  },
  collectionCard: {
    marginBottom: 10,
  },
  collectionImage: {
    aspectRatio: 1.99,
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '80%',
    paddingTop: 30,
  },
  emptyListText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: 15,
    width: '75%',
    textAlign: 'center',
    paddingBottom: 20,
  },
});

export default CollectionsLandingScreen;

/* eslint-disable react-hooks/exhaustive-deps */

import { Icon, Image, LoadingScreen } from '@components';
import CustomizeFooter from '@components/CustomizeFooter';
import Text from '@components/Text';
import colors from '@config/colors';
import { ChosenItem, Product } from '@fieldera-raleys/client-commercetools/schema';
import { CustomizeStackRoutes, CustomizeStackScreenProps } from '@navigation/routes';
import { productService } from '@services/commerceTools';
import useCustomizeStore from '@store/customizeStore';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import utilityStyles from '@styles/utilityStyles';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Dimensions, FlatList, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { filterUnavailableProducts } from '../../utils/helpers';
const { height } = Dimensions.get('window');

interface ContainerScreenProps extends CustomizeStackScreenProps<CustomizeStackRoutes.Container> {
  sizes?: ChosenItem[];
}

interface IListItemProps {
  item: Product;
  index?: number;
  selectedContainer?: ChosenItem;
  setSelectedContainer: (container: ChosenItem) => void;
  calculateSubtotal: () => void;
}

interface IActionBoxProps {
  item: Product;
  selectedContainer?: ChosenItem;
  setSelectedContainer: (container: ChosenItem) => void;
  calculateSubtotal: () => void;
}

const CheckBox: FC<IActionBoxProps> = ({ item, selectedContainer, setSelectedContainer, calculateSubtotal }): JSX.Element => {
  var price: number | null = null;
  var priceDigits: number | null = null;
  if (item?.masterData?.current?.masterVariant?.price) {
    price = item?.masterData?.current?.masterVariant?.price?.value.centAmount;
    priceDigits = item?.masterData?.current?.masterVariant?.price?.value.fractionDigits;
  }

  return (
    <View style={styles.checkBoxWrapper}>
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.checkButton}
        onPress={() => {
          setSelectedContainer({
            id: item?.id,
            sku: item?.masterData?.current?.masterVariant?.sku ?? '',
            itemName: item?.masterData?.current?.name ?? '',
            cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
            image: item?.masterData?.current?.masterVariant?.images[0]?.url,
          });
          calculateSubtotal();
        }}>
        {item?.masterData?.current?.name === selectedContainer?.itemName ? (
          <Icon
            testID="checkedCircleIcon"
            name="checked-circle"
            fill={colors.secondary}
            stroke={'none'}
            strokeSecondary={colors.white}
            fillSecondary={'none'}
            style={appStyles.mediumIcon}
            size={25}
          />
        ) : (
          <Icon testID="uncheckedCircleIcon" name="unchecked-circle" fill={colors.white} stroke={colors.darkText} style={appStyles.mediumIcon} size={25} />
        )}
      </TouchableOpacity>
      <Text
        testID="checkBoxTitle"
        style={[
          styles.checkBoxTitle,
          appStyles.customizeLabelSm,
          {
            fontFamily: selectedContainer?.itemName === item?.masterData?.current?.name ? 'Larsseit-Bold' : 'Larsseit-Light',
          },
        ]}>
        {item?.masterData?.current?.name} {price === 0 && '(included)'}
        {price && price !== 0 ? `(+${price / 10 ** (priceDigits ?? 0)} extra)` : null}
      </Text>
    </View>
  );
};

const ListItem: FC<IListItemProps> = ({ item, selectedContainer, setSelectedContainer, calculateSubtotal }): JSX.Element => {
  var price: number | null = null;
  var priceDigits: number | null = null;
  if (item?.masterData?.current?.masterVariant?.price) {
    price = item?.masterData?.current?.masterVariant?.price?.value.centAmount;
    priceDigits = item?.masterData?.current?.masterVariant?.price?.value.fractionDigits;
  }

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => {
        setSelectedContainer({
          id: item?.id,
          sku: item?.masterData?.current?.masterVariant?.sku ?? '',
          itemName: item?.masterData?.current?.name ?? '',
          cost: (price ?? 0) / 10 ** (priceDigits ?? 0) ?? 0,
          image: item?.masterData?.current?.masterVariant?.images[0]?.url,
        });
        setTimeout(() => {
          calculateSubtotal();
        }, 20);
      }}
      style={styles.listItem}>
      <View style={styles.itemImageWrapper}>
        <Image
          testID="itemImage"
          resizeMode={'contain'}
          source={{ cache: 'web', uri: `${item?.masterData?.current?.masterVariant?.images[0]?.url}` }}
          style={styles.image}
        />
      </View>
      <View testID="itemCheckBox" style={styles.actionWrapper}>
        <CheckBox item={item} selectedContainer={selectedContainer} setSelectedContainer={setSelectedContainer} calculateSubtotal={calculateSubtotal} />
      </View>
    </TouchableOpacity>
  );
};

const ContainerScreen: FC<ContainerScreenProps> = ({ route, navigation }): JSX.Element => {
  const queryClient = useQueryClient();
  const { pageItem = '', catIds } = route.params;
  const { setSelectedContainer, selectedContainer, calculateSubtotal, currStep } = useCustomizeStore();
  const listRef = useRef(null);
  // const [choices, setChoices] = useState<Product[]>([]);
  const {
    data: choices,
    status,
    refetch,
  } = useQuery('container-selections-list', () => productService.getProducts({ catId: catIds }), {
    cacheTime: 0,
    select: (d) => {
      d?.sort(
        (a, b) =>
          +(a?.masterData?.current?.categoryOrderHints?.find((coh) => catIds?.length && coh.categoryId === catIds[0])?.orderHint || 0.8) -
          +(b?.masterData?.current?.categoryOrderHints?.find((coh) => catIds?.length && coh.categoryId === catIds[0])?.orderHint || 0.8),
      );
      return filterUnavailableProducts(d);
    },
  });
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  useEffect(() => {
    if (status === 'success') {
      setIsLoadingProducts(false);
    } else {
      setIsLoadingProducts(true);
    }
  }, [choices, status]);

  useEffect(() => {
    setIsLoadingProducts(true);
    queryClient.invalidateQueries([
      'basic-selections-list',
      'filling-selections-list',
      'message-selections-list',
      'theme-selections-list',
      'container-selections-list',
    ]);
    refetch();
  }, [pageItem]);

  useEffect(() => {
    var forwardEnabled = selectedContainer?.itemName ? true : false;
    navigation.setParams({ forwardNavEnabled: forwardEnabled });
  }, [selectedContainer?.itemName, currStep]);

  const renderHeader = () => {
    return (
      <Text testID="selectContainer" style={[styles.selectText, utilityStyles.px3, appStyles.customizeH2]}>
        Select container
      </Text>
    );
  };

  return isLoadingProducts ? (
    <LoadingScreen />
  ) : (
    <View style={styles.container}>
      <View style={styles.body}>
        {/* <Text style={[styles.itemText, utilityStyles.px3]}>{pageItem.toUpperCase()}</Text> */}
        <FlatList
          ref={listRef}
          style={styles.listContainer}
          contentContainerStyle={[styles.contentContainer, utilityStyles.px3]}
          bounces={false}
          numColumns={1}
          data={choices}
          extraData={choices}
          keyExtractor={(_, i) => i.toString()}
          ListHeaderComponent={renderHeader}
          ListHeaderComponentStyle={{ marginLeft: -16 }}
          renderItem={({ item, index }) => {
            return (
              <ListItem
                item={item}
                index={index}
                selectedContainer={selectedContainer}
                setSelectedContainer={setSelectedContainer}
                calculateSubtotal={calculateSubtotal}
              />
            );
          }}
          // ListFooterComponent={() => <CustomizeFooter disabled={selectedContainer.itemName ? false : true} />}
          // ListFooterComponentStyle={styles.footerWrapper}
        />
      </View>
      <View style={styles.footerWrapper}>
        <CustomizeFooter />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: screenWidth,
    flex: 1,
    paddingVertical: 16,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
  },
  body: {
    flex: 1,
    width: screenWidth,
    paddingHorizontal: 0,
    paddingTop: Platform.OS === 'ios' ? (height > 740 ? height * 0.01 : height * 0.01) : height > 640 ? height * 0.01 : height * 0.01,
  },
  itemText: {
    fontSize: scale(14),
    fontFamily: 'Larsseit-Bold',
    color: colors.darkText,
    marginBottom: 15,
  },
  selectText: {
    fontSize: scale(20.5),
    fontFamily: 'Aleo-Bold',
    color: colors.darkText,
    marginBottom: 20,
  },
  listContainer: {
    width: '100%',
    height: '100%',
    // backgroundColor: 'red',
  },
  contentContainer: {
    justifyContent: 'space-between',
  },
  listItem: {
    // backgroundColor: 'orange',
    height: screenHeight * 0.43,
  },
  itemImageWrapper: {
    backgroundColor: colors.white,
    width: screenHeight * 0.39,
    flex: 0.8,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    // overflow: 'hidden',
    shadowColor: colors.black,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2.22,

    elevation: 3,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  actionWrapper: {
    flex: 0.15,
    justifyContent: 'center',
    width: screenHeight * 0.39,
    alignSelf: 'center',
    marginTop: 5,
  },
  checkBoxWrapper: {
    flexDirection: 'row',
    alignContent: 'center',
  },
  checkButton: {
    marginRight: 8,
    justifyContent: 'center',
    marginLeft: -8,
  },
  checkBoxTitle: {
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: lineHeight(15),
    alignSelf: 'center',
    paddingTop: 4,
  },
  // footerWrapper: {
  //   // backgroundColor: 'gold',
  //   paddingTop: Platform.OS === 'ios' ? (height > 740 ? 60 : 75) : height > 640 ? 60 : 75,
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   flex: 1,
  //   alignSelf: 'center',
  //   paddingHorizontal: 32,
  // },
  footerWrapper: {
    // backgroundColor: 'gold',
    // paddingTop: 45,
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.5,
    width: screenWidth,
    alignSelf: 'center',
    paddingHorizontal: 32,
    marginBottom: -60,
  },
});

export default ContainerScreen;

import Carousel from '@components/Carousel';
import Text from '@components/Text';
import OfferCard from '@components/somethingExtra/OfferCard';
import colors from '@config/colors';
import { defaultMoney } from '@fieldera-raleys/client-commercetools/utils';
import { Promotion } from '@fieldera-raleys/client-common/services/brandywine/types';
import { useCartStore } from '@store';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { containerWidth, screenWidth } from '@styles/constants';
import React, { FC, useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { moneyValue } from '../../utils/orderHelpers';

export type ItemSavingsProps = {
  itemId?: string;
  callDismiss?: () => void;
};

const CARD_WIDTH = 168;
const SLIDE_GAP = 12;
const carouselConfig = {
  slidesPerPage: 1,
  nextSlidePartial: CARD_WIDTH * 0.44,
  slideWidth: CARD_WIDTH,
  sgPerLine: 1,
};

const ItemSavings: FC<ItemSavingsProps> = ({ itemId, callDismiss }): JSX.Element => {
  const { cart } = useCartStore();

  const showEligibleCalled = () => {
    if (callDismiss) {
      callDismiss();
    }
  };

  const [promotion, setPromotion] = useState<null | Promotion[]>(null);
  const [savings, setSavings] = useState<{ applied: number; available: number }>({ applied: 0, available: 0 });
  useEffect(() => {
    let pSet: Promotion[] = [];
    let cpSet: Promotion[] = [];
    if (cart) {
      const lineItem = cart.lineItems.find((li) => li.id === itemId);
      const cartPromos = JSON.parse(cart?.custom?.customFieldsRaw?.find((f) => f.name === 'promotions')?.value ?? '[]') as Promotion[];
      if (lineItem) {
        cpSet = cartPromos.filter(
          (p) =>
            (p.ExtPromotionTypeCode ?? '').toUpperCase() !== 'BCS' &&
            (p.ExtPromotionTypeCode ?? '').toUpperCase() !== 'DPT' &&
            (p.Rewards ?? []).some((o) => o.LineItemId === lineItem.id),
        );
        pSet = cpSet; // testing reduced dependency on offerStore, the CartScreen is a parent for this and handles cart update for this to be triggered
      }
      setSavings(
        cpSet.reduce(
          (acc, p) => {
            acc.applied += p.AutoApply || p.IsAccepted ? p.Rewards?.reduce((sum, r) => (sum += r.RewardDiscount ?? 0), 0) ?? 0 : 0;
            acc.available += !(p.AutoApply || p.IsAccepted) ? p.AvailableSavings ?? 0 : 0;
            return acc;
          },
          { applied: 0, available: 0 } as { applied: number; available: number },
        ),
      );
      setPromotion(pSet);
    }
  }, [cart, itemId]);

  // console.log(JSON.stringify(promotion, null, 2));
  // console.log(cart?.id ?? '');
  return !promotion ? (
    <>
      <ActivityIndicator color={colors.primary} />
    </>
  ) : !promotion.length ? (
    <>
      <Text>No Promotions</Text>
    </>
  ) : (
    <>
      <View style={[styles.cartCarouselWrapper, styles.carousaelWrapperExtra]}>
        <View style={[styles.carouselMainView, utilityStyles.my2]}>
          {promotion.length ? (
            <Carousel
              windwoSize={promotion.length}
              snapToInterval={carouselConfig.slideWidth + SLIDE_GAP}
              contentContainerStyle={{
                ...styles.carouselContainer,
                width: (promotion.length ? promotion.length : 0) * (carouselConfig.slideWidth + SLIDE_GAP) + SLIDE_GAP,
              }}
              entities={promotion || []}
              renderItem={({ item: io, index }) => {
                return (
                  <View style={styles.carouselView}>
                    <OfferCard item={io} index={index} onShowEligibleItems={showEligibleCalled} />
                  </View>
                );
              }}
              pagerStyle={styles.pagerStyle}
              pagerActiveItemStyle={appStyles.carouselDotsSmallActive}
              pagerInactiveItemStyle={appStyles.carouselDotsSmall}
              showPager={false}
              removeClippedSubviews={false}
            />
          ) : (
            <Text>...</Text>
          )}
        </View>

        <View style={styles.savingsParent}>
          {savings.available !== 0 && (
            <View key={'available-savings-row'} style={[styles.savingsBox, utilityStyles.mt3]}>
              <Text testID="applieditemSaving" style={appStyles.fontMobileBodySmallBald}>
                Available Item Savings*
              </Text>
              <Text testID="applieditemSaving" style={[appStyles.fontMobileBodySmallBald, { color: colors.alertBoxGreen, marginLeft: 'auto' }]}>
                {moneyValue({ ...defaultMoney, centAmount: Math.round(savings.available * 100.0) })}
              </Text>
            </View>
          )}
          {savings.applied !== 0 && (
            <View key={'applied-savings-row'} style={[styles.savingsBox, utilityStyles.mt3]}>
              <Text testID="applieditemSaving" style={appStyles.fontMobileBodySmallBald}>
                Applied Item Savings*
              </Text>
              <Text testID="applieditemSaving" style={[appStyles.fontMobileBodySmallBald, { color: colors.alertBoxGreen, marginLeft: 'auto' }]}>
                {moneyValue({ ...defaultMoney, centAmount: Math.round(savings.applied * 100.0) })}
              </Text>
            </View>
          )}
          <View style={[styles.savingsBox, styles.totalText]}>
            <Text testID="totalSavingText" style={[appStyles.fontMobileTinyLight, { textAlign: 'center' }]}>
              *Total savings may differ at checkout based on other discounts
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  carouselContainer: {
    borderWidth: 0,
    overflow: 'visible',
    marginRight: 0,
  },
  carouselView: {
    marginBottom: 16,
    marginLeft: SLIDE_GAP,
  },
  cartCarouselWrapper: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: colors.promoCream,
  },
  pagerStyle: {
    marginTop: 20,
    marginBottom: 10,
    width: containerWidth,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  savingsParent: {
    borderTopWidth: 1,
    borderTopColor: colors.sectionBorder,
    backgroundColor: colors.cream,
    width: screenWidth,
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    borderBottomEndRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomWidth: 0,
  },
  savingsBox: {
    width: containerWidth,
    backgroundColor: colors.cream,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  totalText: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginVertical: 6,
    width: screenWidth * 0.9,
  },
  carousaelWrapperExtra: {
    flexDirection: 'column',
    borderBottomEndRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  carouselMainView: {
    width: screenWidth,
    backgroundColor: colors.promoCream,
  },
});

export default ItemSavings;

import { CloseButton, DropShadow, Icon, KeyboardAvoidingScreen, LinkButton, Modal, Text, WebBrowserLink } from '@components';
import { ErrorMessage, Form, FormField, FormSwitch, SubmitButton } from '@components/forms';
import FormCheckbox from '@components/forms/FormCheckBox';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AuthToken, LoyaltyValidationResult } from '@fieldera-raleys/client-common';
import { validateEmail } from '@fieldera-raleys/client-common/utils';
import routes, { AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { useCardAnimation } from '@react-navigation/stack';
import { authService } from '@services/brandywine';
import { useAuthStore, useDeviceInfoStore, useUserSettingsStore } from '@store';
import { appStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import helpers, { formatPhone, hapticNotify } from '@utils/helpers';
import { FormikProps } from 'formik';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Animated, NativeSyntheticEvent, Platform, ScrollView, StyleSheet, TextInput, TextInputChangeEventData, View } from 'react-native';
import * as Yup from 'yup';

type CreateAccountFormType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  extLoyaltyId: string;
  autoLogin: boolean;
  sendEmails: boolean;
  sendTexts: boolean;
  acceptTerms: boolean;
};
type CreateAccountScreenProps = AuthStackScreenProps<AuthStackRoutes.CreateAccount>;

const CreateAccountScreen = ({ navigation, route }: CreateAccountScreenProps) => {
  const { willComplete } = route.params;
  const initialValue = useRef<CreateAccountFormType>({
    firstName: '',
    lastName: route.params.lastName || '',
    email: route.params.email || '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    extLoyaltyId: route.params.seNumber || '',
    autoLogin: true,
    sendEmails: false,
    sendTexts: false,
    acceptTerms: false,
  });
  const { t } = useTranslation('createAccount');

  const { deviceInfo, getBiometricType } = useDeviceInfoStore();
  const { current } = useCardAnimation();
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [signUpFailed, setSignUpFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAutoLogin, setIsAutoLogin] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [signUpFailedMsg, setSignUpFailedMsg] = useState<string>('');
  const [loginState, setLoginState] = useState<{ email: string; loginToken: AuthToken; supportBiometric: boolean }>({
    email: '',
    loginToken: { email: '', refreshToken: '', jwtToken: '' } as AuthToken,
    supportBiometric: false,
  });
  const { setAuthToken } = useAuthStore();
  const { updateSetting: updateUserSetting } = useUserSettingsStore();
  const firstnameInputRef = createRef<TextInput>();
  const lastnameInputRef = createRef<TextInput>();
  const emailAddressRef = createRef<TextInput>();
  const passwordRef = createRef<TextInput>();
  const confirmPasswordRef = createRef<TextInput>();
  const phoneInputRef = createRef<TextInput>();
  const scrollViewRef = createRef<ScrollView>();
  const loyaltyIdRef = createRef<TextInput>();
  const formRef = createRef<FormikProps<any>>();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required()
      .label(t('validationFirstNameLabel'))
      .test('no-special-character', t('nameSpecialCharacterValidation') ?? '', function (value) {
        if (value) {
          const specialCharacterRegex = helpers.SpecialCharacter;
          let isValidName = !specialCharacterRegex.test(value);
          return isValidName;
        }
        return false;
      }),
    lastName: Yup.string()
      .trim()
      .required()
      .label(t('validationLastNameLabel'))
      .test('no-special-character', t('nameSpecialCharacterValidation') ?? '', function (value) {
        if (value) {
          const specialCharacterRegex = helpers.SpecialCharacter;
          let isValidName = !specialCharacterRegex.test(value);
          return isValidName;
        }
        return false;
      }),
    phoneNumber: Yup.string().matches(helpers.PHONE_NUMBER_REGEX, t('invalidPhoneValidationMsg')).required().label(t('validationPhoneNumberLabel')),
    isAutoLogin: Yup.boolean(),
    acceptTerms: Yup.boolean().oneOf([true], t('validationTermsAndConditionLabel')),
    email: Yup.string()
      .trim()
      .required()
      .test('email', function (value) {
        if (value) {
          return validateEmail(value);
        }
        return false;
      })
      .label(t('validationEmailLabel')),
    password: Yup.string()
      .required()
      .label(t('validationPasswordLabel'))
      .min(8, t('passwordLengthValidation'))
      .matches(helpers.PASSWORD_REGEX, t('passwordNumCharValidation')),
    confirmPassword: Yup.string().test('passwords-match', 'Passwords must match', function (val) {
      return this.parent.password === val;
    }),
    extLoyaltyId: Yup.string().required().label('Something Extra Number').matches(helpers.SENUMBER_REGEX, t('loyaltyIdValidation')),
  });

  const handleSignIn = async () => {
    updateUserSetting('localAuthentication', loginState.supportBiometric);
    setAuthToken(loginState.email, loginState.loginToken);
  };

  const handleAutoLogin = async (isAutoSignup: boolean, emailText: string, token: AuthToken, isSupportBiometric: boolean) => {
    setLoginState({
      email: emailText,
      loginToken: token,
      supportBiometric: isSupportBiometric,
    });
    setShowSuccessMsg(true);
  };

  useEffect(() => {
    if (signUpFailed && !isLoading) {
      scrollViewRef.current?.scrollTo({
        y: 0,
        animated: true,
      });
    }
  }, [signUpFailed, scrollViewRef, isLoading]);

  const submitCreateAccountForm = async (formData: CreateAccountFormType) => {
    setIsLoading(true);
    setSignUpFailed(false);
    const { firstName, lastName, email, password, phoneNumber, extLoyaltyId, autoLogin, sendEmails, sendTexts, acceptTerms } = formData;
    try {
      // Check if SE Number and Last Name has account already
      const loyaltyValidation: LoyaltyValidationResult = (await authService.validateLoyaltyInformation(lastName, extLoyaltyId)) ?? {
        validationStatus: 3,
        extLoyaltyId: '',
        statusDescription: '',
      };
      if (loyaltyValidation.validationStatus === 2) {
        navigation.navigate(AuthStackRoutes.CreateAccountInitial, {
          errorMsg: t('seNumberMatchedInUse'),
        });
      }

      const loginToken = await authService.signUp({
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        extLoyaltyId,
        autoLogin,
        sendEmails,
        sendTexts: sendTexts,
        acceptTerms,
      });

      if (!loginToken) {
        setIsLoading(false);
        setSignUpFailed(true);
        hapticNotify('notificationError');
      } else {
        setIsLoading(false);
        setSignUpFailed(false);
        if (deviceInfo && deviceInfo.supportsBiometrics && deviceInfo.biometricsType?.length) {
          Alert.alert(`Login with ${getBiometricType()}`, 'Would you like to save your credentials?', [
            {
              text: 'Yes',
              onPress: async () => {
                handleAutoLogin(autoLogin, email, loginToken, true);
              },
            },
            {
              text: 'Never',
              onPress: async () => {
                handleAutoLogin(autoLogin, email, loginToken, false);
              },
            },
          ]);
        } else {
          setIsLoading(false);
          setSignUpFailed(false);
          handleAutoLogin(autoLogin, email, loginToken, false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setSignUpFailed(true);
      let message = (error as Error).message;
      if (message.toLowerCase().indexOf('loyalty') >= 0) {
        message = message.replace(/loyaltyid/gi, 'Something Extra Number');
      }
      setSignUpFailedMsg(message);
    } finally {
      setIsLoading(false);
    }
  };
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowPasswordConfirm = () => setShowConfirmPassword(!showConfirmPassword);

  const handleChange = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    if (formRef.current && !route.params.seNumber) {
      formRef.current.setFieldValue('extLoyaltyId', formatPhone(e.nativeEvent.text)?.replace(/[^0-9]/g, ''), true);

      setTimeout(
        (form) => {
          form.setFieldTouched('extLoyaltyId');
        },
        100,
        formRef.current,
      );
    }
  };

  const renderCreateAccount = () => {
    return (
      <Form innerRef={formRef} initialValues={initialValue.current} onSubmit={submitCreateAccountForm} validationSchema={validationSchema}>
        <KeyboardAvoidingScreen behavior="padding" keyboardVerticalOffset={50}>
          <ScrollView bounces={false} contentContainerStyle={[appStyles.container, styles.paddingHorizantal]} ref={scrollViewRef}>
            {willComplete ? (
              <View>
                <Text style={[appStyles.fontMobileBodyLeftRegular, styles.completeAccountInfo]}>
                  {t('yourNumber', { type: route.params.seType === 'card' ? 'Card ' : '' })}:{' '}
                  <Text style={[styles.completeAccountInfoText]}>{route.params.seValidationNumber}</Text>
                </Text>
                <Text style={[appStyles.fontMobileBodyLeftRegular, styles.completeAccountInfo]}>
                  {t('yourLastName')}: <Text style={[styles.completeAccountInfoText]}>{route.params.lastName}</Text>
                </Text>
              </View>
            ) : null}
            <ErrorMessage error={signUpFailedMsg} visible={signUpFailed} style={{ textAlign: 'center' }} testID="signUpFailedMsg" />
            <FormField
              testID="firstName"
              label={t('firstNameLabel')}
              name={'firstName'}
              ref={firstnameInputRef}
              autoCapitalize="words"
              autoCorrect={false}
              keyboardType="ascii-capable"
              textContentType="givenName"
              returnKeyType="next"
              blurOnSubmit={false}
              onSubmitEditing={() => {
                lastnameInputRef.current?.focus();
              }}
            />
            {!willComplete && (
              <FormField
                testID="lastName"
                label={t('lastNameLabel')}
                autoCapitalize="none"
                textContentType="familyName"
                autoCorrect={false}
                name="lastName"
                returnKeyType="next"
                ref={lastnameInputRef}
                onSubmitEditing={() => emailAddressRef?.current?.focus()}
                hidden={willComplete}
              />
            )}
            <FormField
              testID="email"
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="email-address"
              name="email"
              label={t('emailLabel')}
              textContentType="emailAddress"
              ref={emailAddressRef}
              returnKeyType="next"
              onSubmitEditing={() => phoneInputRef?.current?.focus()}
            />
            <FormField
              testID="phoneLabel"
              ref={phoneInputRef}
              label={t('phoneLabel')}
              maxLength={14}
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="phone-pad"
              name="phoneNumber"
              textContentType="telephoneNumber"
              returnKeyType="done"
              formatter={formatPhone}
              onSubmitEditing={() => (willComplete ? passwordRef?.current?.focus() : loyaltyIdRef?.current?.focus())}
              onChange={(e) => handleChange(e)}
            />
            <FormField
              testID="extLoyaltyId"
              autoCapitalize="none"
              autoCorrect={false}
              label={[
                <View style={{ width: screenWidth - 60 }}>
                  <Text style={[appStyles.formLabels, Platform.OS === 'android' ? { paddingLeft: 0 } : null]} testID="loyaltyIdLabel">
                    {t('loyaltyIdLabel')}
                  </Text>
                  <Text style={[appStyles.formLabels, styles.formLabelFix]} testID="loyaltyIdDesc">
                    {t('loyaltyIdDesc')}
                  </Text>
                </View>,
              ]}
              labelStyle={{ marginBottom: 0 }}
              name="extLoyaltyId"
              keyboardType="number-pad"
              returnKeyType="done"
              ref={loyaltyIdRef}
              maxLength={12}
              onSubmitEditing={() => passwordRef?.current?.focus()}
              editable={!willComplete}
            />
            <FormField
              testID="password"
              autoCapitalize="none"
              autoCorrect={false}
              name="password"
              label={[
                <View style={{ width: screenWidth - 60 }}>
                  <Text style={[appStyles.formLabels, Platform.OS === 'android' ? { paddingLeft: 0 } : null]} testID="passwordLabel">
                    {t('passwordLabel')}
                  </Text>
                  <Text style={[appStyles.formLabels, styles.formLabelFix]} testID="passwordDescription">
                    {t('passwordDescription')}
                  </Text>
                </View>,
              ]}
              secureTextEntry={!showPassword}
              textContentType="password"
              ref={passwordRef}
              icon={{
                testID: showPassword ? 'passwordEyehide' : 'passwordEyeopen',
                name: showPassword ? 'eye-hide' : 'eye-open',
                fill: 'black',
                size: 20,
                style: { ...appStyles.icon, marginVertical: 5 },
              }}
              iconPress={toggleShowPassword}
              onSubmitEditing={() => confirmPasswordRef?.current?.focus()}
              keyboardType={Platform.select({ android: 'default' })}
              returnKeyType="next"
            />
            <FormField
              testID="confirmPasswordLabel"
              autoCapitalize="none"
              autoCorrect={false}
              name="confirmPassword"
              label={t('confirmPasswordLabel')}
              secureTextEntry={!showConfirmPassword}
              textContentType="password"
              ref={confirmPasswordRef}
              icon={{
                testID: showConfirmPassword ? 'confirmPasswordEyeHide' : 'confirmPasswordEyeOpen',
                name: showConfirmPassword ? 'eye-hide' : 'eye-open',
                fill: 'black',
                size: 20,
                style: { ...appStyles.icon, marginVertical: 5 },
              }}
              iconPress={toggleShowPasswordConfirm}
              keyboardType={Platform.select({ android: 'default' })}
              returnKeyType="done"
            />
            <FormCheckbox testID="acceptTerms" name={'acceptTerms'} checkboxWrapperStyle={{ alignItems: 'flex-start' }} errorStyle={styles.checkBoxError}>
              <View style={styles.acceptTermsView} testID="termsAndConditionView">
                <Text style={[styles.checkBoxInfoText, styles.labelText]} numberOfLines={5} testID="termsAndConditionLabel">
                  {t('termsAndConditionLabel')}
                  <WebBrowserLink style={[appStyles.footerLink, styles.labelText]} href={routes.TERMS_AND_CONDITIONS} testID={'termsAndConditionsText'}>
                    {t('termsAndConditionsText')}
                  </WebBrowserLink>
                  ,{' '}
                  <WebBrowserLink style={[appStyles.footerLink, styles.labelText]} href={routes.TERMS_OF_USE} testID={'termsOfUseText'}>
                    {t('termsOfUseText')}
                  </WebBrowserLink>
                  , and{' '}
                  <WebBrowserLink style={[appStyles.footerLink, styles.labelText]} href={routes.PRIVACY_POLICY} testID={'privacyPolicyText'}>
                    {t('privacyPolicyText')}
                  </WebBrowserLink>
                  . {t('termsAndConditionLabel2')}
                </Text>
              </View>
            </FormCheckbox>
            <FormSwitch
              testID="autoLogin"
              name={'autoLogin'}
              label={'Auto Login'}
              containerStyle={styles.autoLoginView}
              onChange={() => setIsAutoLogin(!isAutoLogin)}
              hidden={true}
            />

            <View style={styles.buttonsContainer}>
              <SubmitButton testID="Submit" title="Submit" isButtonLoading={isLoading} />
              <LinkButton testID="alreadyAccountText" onPress={() => navigation.navigate(AuthStackRoutes.SignIn)} style={styles.boldLinkLargeCenter}>
                {t('alreadyAccountText')}
              </LinkButton>
            </View>
          </ScrollView>
        </KeyboardAvoidingScreen>
      </Form>
    );
  };

  return (
    <>
      <Animated.View
        style={[
          styles.container,
          {
            transform: [
              {
                translateY: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [screenHeight * 0.65, 0],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}>
        <View style={styles.modal}>
          <DropShadow style={styles.dropshadowContainer}>
            <View style={styles.header}>
              <CloseButton style={styles.closeButton} />
            </View>
            <Text style={styles.h3BoldCenter} testID="title">
              {willComplete ? t('headerTitleComplete') : t('headerTitle')}
            </Text>
          </DropShadow>
          {renderCreateAccount()}
        </View>
      </Animated.View>
      <Modal
        visible={showSuccessMsg}
        showCancel={false}
        showCloseIcon={false}
        okButtonText={t('modalText')}
        okButtonStyle={styles.helpModalButton}
        okButtonOnPress={() => handleSignIn()}
        location="top"
        style={styles.modalHeight}
        subTextViewStyle={styles.subTextStyle}
        header={
          <Icon
            testID="checked-circle"
            name="checked-circle"
            size={70}
            style={styles.modalIcon}
            stroke={colors.alertBoxGreen}
            strokeSecondary={colors.alertBoxGreen}
          />
        }>
        <Text style={[styles.textInfo, styles.comfirmationModalTextInfo]} testID="successModalMessage">
          {t('successModalMessage')}
        </Text>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: Platform.select({
      ios: screenHeight * 0.95,
      android: screenHeight * 0.9,
    }),
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  dropshadowContainer: {
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  autoLoginView: { alignSelf: 'center' },
  modal: {
    flex: 1,
    alignContent: 'space-between',
    paddingBottom: 20,
  },
  header: {
    height: 50,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    textAlign: 'center',
  },
  closeButton: {
    left: 16,
    top: 16,
  },
  paddingHorizantal: { paddingHorizontal: 20 },
  needHelpText: {
    fontSize: scale(15),
    fontFamily: FontFamily.LarsseitLight,
    lineHeight: lineHeight(15),
  },
  buttonsContainer: {
    marginTop: 8,
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
  },
  boldLinkLargeCenter: {
    ...appStyles.boldLinkLargeCenter,
    marginTop: 10,
  },
  checkBoxInfoText: {
    flex: 1,
    fontFamily: FontFamily.Larsseit,
    color: colors.darkText,
    fontSize: scale(15),
    lineHeight: lineHeight(14),
    maxWidth: screenWidth - 40 - 24,
  },
  helpModalButton: {
    width: 150,
  },
  modalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.32 : screenHeight * 0.36) : screenHeight > 640 ? screenHeight * 0.3 : screenHeight * 0.4,
  },
  comfirmationModalTextInfo: {
    fontSize: scale(16),
    // top: -14,
  },
  comfirmationModalTitle: {
    fontSize: scale(26),
  },
  paddingBottom: { paddingBottom: -10 },
  textInfo: {
    fontSize: scale(18),
    color: colors.primary,
    textAlign: 'center',
    lineHeight: lineHeight(20),
    fontFamily: FontFamily.LarsseitLight,
  },
  subTextStyle: {
    marginVertical: 0,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  acceptTermsView: {
    width: Platform.select({ android: containerWidth * 0.87 }),
  },
  completeAccountInfo: {
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(15),
  },
  completeAccountInfoText: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(15),
  },
  noDisplay: {
    display: 'none',
  },
  labelStyle: {
    width: screenWidth - 86,
  },
  labelText: {
    fontSize: scale(11),
    lineHeight: lineHeight(12),
    fontFamily: FontFamily.LarsseitLight,
  },
  formLabelFix: {
    fontSize: scale(11),
    lineHeight: lineHeight(12),
    paddingLeft: Platform.OS === 'android' ? 0 : 10,
  },
  checkBoxError: {
    fontSize: scale(14),
    lineHeight: lineHeight(15),
    width: screenWidth - 86,
    textAlign: 'left',
    alignSelf: 'flex-start',
    marginLeft: 46,
  },
  modalIcon: {
    alignSelf: 'center',
    width: 70,
    height: 70,
    marginTop: 10,
  },
  bottomRight: {
    bottom: -14,
  },
});

export default CreateAccountScreen;

import { StackCardInterpolationProps } from '@react-navigation/stack';

export const cardStyleInterpolator = ({ current, layouts }: StackCardInterpolationProps) => {
  return {
    cardStyle: {
      transform: [
        {
          translateY: current.progress.interpolate({
            inputRange: [0, 1],
            outputRange: [-layouts.screen.height, 0],
          }),
        },
      ],
    },
  };
};

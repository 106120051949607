import CartInfoBanner from '@components/CartInfoBanner';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { View } from 'react-native';
import DropShadow from '../DropShadow';
import Screen from '../Screen';
import SearchHeader from '../SearchHeader';
import Tabbar, { TabItem } from '../Tabbar';
import Tombstone, { OpenTombstoneRequest } from '../Tombstone';

type WithShopNavigatorProps = {
  screenName: 'categories' | 'shelf-guide' | 'weekly-ad' | 'collections';
  showTombstone?: boolean;
};

const withShopNavigation =
  <P extends {}>(Component: React.ComponentType<P>, data: WithShopNavigatorProps) =>
  ({ ...props }: P): JSX.Element => {
    const [tsRequest, setTsRequest] = useState<OpenTombstoneRequest | undefined>();
    const tabBarHeight = useBottomTabBarHeight();
    const { screenName, showTombstone } = data;
    const navigation = useNavigation();

    const handleChangeStore = (value: OpenTombstoneRequest) => {
      setTsRequest(value);
    };

    const tabItems: TabItem[] = [
      {
        name: 'categories',
        displayName: 'Categories',
        onPress:
          screenName === 'categories'
            ? undefined
            : () => {
                navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.Categories } });
              },
      },
      {
        name: 'shelf-guide',
        displayName: 'Shelf Guide',
        onPress:
          screenName === 'shelf-guide'
            ? undefined
            : () => {
                navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.ShelfGuide } });
              },
      },
      {
        name: 'weekly-ad',
        displayName: 'Weekly Ad',
        onPress:
          screenName === 'weekly-ad'
            ? undefined
            : () => {
                navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.WeeklyAds } });
              },
      },
      {
        name: 'collections',
        displayName: 'Collections',
        onPress:
          screenName === 'collections'
            ? undefined
            : () => {
                navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Shop, params: { screen: ShopStackRoutes.CollectionsLanding } });
              },
      },
    ];

    return (
      <Screen>
        <View style={{ flex: 1 }}>
          <CartInfoBanner />
          <SearchHeader style={{ marginBottom: 0 }} tabBarHeight={tabBarHeight} showBackButton={true} />
          <DropShadow>
            <Tabbar tabs={tabItems} selectedTabName={screenName} />
          </DropShadow>
          <Component {...({ ...props, onChangeStore: handleChangeStore } as P)} />
        </View>
        {showTombstone && <Tombstone openRequest={tsRequest} />}
      </Screen>
    );
  };

export default withShopNavigation;

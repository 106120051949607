import { Accordion, Button, DropShadow, HeaderTitle, HelpLink, Icon, LoadingScreen, Modal, NavigationHeader, Screen } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import { IconName } from '@components/Icon';
import Text from '@components/Text';
import InvoiceOrder from '@components/cart/InvoiceOrder';
import ItemizedOrder from '@components/cart/ItemizedOrder';
import { ErrorMessage, Form, FormField, FormSwitch } from '@components/forms';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Money } from '@fieldera-raleys/client-commercetools/schema';
import { Store } from '@fieldera-raleys/client-common';
import { Order, OrderStatusType } from '@fieldera-raleys/client-common/types/order';
import { isOrderOkToModify, minuteKey } from '@fieldera-raleys/client-common/utils';
import {
  AccountStackRoutes,
  AppStackRoutes,
  CheckoutStackRoutes,
  OrderHistoryStackRoutes,
  OrderHistoryStackScreenProps,
  RootTabRoutes,
} from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { orderService, storeService } from '@services/brandywine';
import { useCartStore } from '@store';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import helpers, { formatPhone } from '@utils/helpers';
import logger from '@utils/logger';
import { getBWOrderStoreNumber, getLogoColors, getOriginalOrderNumber, pointsValue } from '@utils/orderHelpers';
import { queryClient } from '@utils/reactQuery';
import dayjs from 'dayjs';
import * as Sharing from 'expo-sharing';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { captureRef, releaseCapture } from 'react-native-view-shot';
import * as Yup from 'yup';

type PurchaseDetailScreenProps = OrderHistoryStackScreenProps<OrderHistoryStackRoutes.OrderDetails>;

const StoreIcon = ({ storeInfo }: { storeInfo: string }) => {
  const logoColors = getLogoColors(storeInfo);
  return (
    <Icon
      testID="storeIcon"
      fill={logoColors?.fill || 'none'}
      fillSecondary={logoColors?.fillSecondary || 'none'}
      stroke={logoColors?.stroke || 'none'}
      size={75}
      name={`${storeInfo?.includes('one') ? 'raleysonemarket' : storeInfo}` as IconName}
      style={styles.storeIconStyle}
    />
  );
};

const PurchaseDetailScreen = ({ route }: PurchaseDetailScreenProps) => {
  const { orderId } = route.params;
  const { t } = useTranslation(['cart', 'purchaseHistory']);

  const { cart, modifyOrder, resetMyCart } = useCartStore();
  const orderViewRef = useRef<View>(null);
  const [dropDownState, setDropDownState] = useState<{ items: boolean; invoice: boolean; shippingMethod: boolean; orderContact: boolean }>({
    items: false,
    invoice: false,
    shippingMethod: true,
    orderContact: false,
  });
  const [isDelivery, setDelivery] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [canReOrder, setCanReOrder] = useState<boolean>(false);
  const [shouldRefetchOrderHistory, setShouldRefetchOrderHistory] = useState<boolean>(false);
  const handleError = useErrorHandler();
  const navigation = useNavigation();

  //const [showCancelingWindow, setCancelingWindow] = useState<boolean>(false); comment out for now
  const [showCancelVerificationModal, setShowCancelVerificationModal] = useState<boolean>(false);
  const [showCancelComfirmationModal, setShowCancelComirmationModal] = useState<boolean>(false);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const { data: orderDetails, isLoading, refetch } = orderService.useGetOrder(orderId);
  const storeInfo = (orderDetails?.deliverySite?.facility.customProperties?.FacilityGroupName ?? '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s/g, '')
    .toLowerCase();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label(t('firstName')),
    lastName: Yup.string().required().label(t('lastName')),
    phone: Yup.string().required().matches(helpers.PHONE_NUMBER_REGEX, t('invalidPhone')).label(t('phone')),
  });

  let shippingMethod = 'Pickup';
  if (orderDetails && orderDetails.shippingMethod) {
    shippingMethod = orderDetails.shippingMethod;
  }

  let pickupPerson = '';
  if (orderDetails && orderDetails.shippingAddress) {
    const fn = orderDetails.shippingAddress.firstName;
    const ln = orderDetails.shippingAddress.lastName;
    pickupPerson = [...(fn ? [fn] : []), ...(ln ? [ln] : [])].join(' ');
  }

  const getStateAbbrevation = (name: string) => {
    const state = appConstants.STATES_LIST.find((s) => s.name === name);
    return state?.abbreviation ?? '';
  };

  let nextIcons = (
    <View style={styles.nextIconContainer}>
      <HelpLink testID={'orderDetailHelp'} style={styles.helpLinkStyle} />
      <TouchableOpacity testID="orderDetailShare" onPress={() => !isLoading && onShareOrder()}>
        <Icon size={25} style={[appStyles.mediumIcon, utilityStyles.ml5]} fill={colors.dark} name={'action-up'} testID="actionUP" />
      </TouchableOpacity>
    </View>
  );

  const onShareOrder = async () => {
    try {
      if (orderViewRef?.current) {
        captureRef(orderViewRef).then(
          async (val) => {
            let url = Platform.select({ default: 'file://' + val, web: val })!;

            if (!(await Sharing.isAvailableAsync())) {
              logger.warn('Your device does not support sharing');
              return;
            }

            const shareOptions = {
              mimeType: 'image/png',
              UTI: 'public.png',
            };

            await Sharing.shareAsync(url, shareOptions);
            logger.log('Successfully Shared');

            releaseCapture(val);
          },
          (error) => logger.warn('Snapshot failed', error),
        );
      }
    } catch (e) {
      logger.warn(`Share exception ${e}`);
    }
  };

  const getFormattedTimeSlot = (obj: Order, defaultValue?: string) => {
    if (!obj.deliveryBeginTime || !obj.deliveryEndTime) {
      return defaultValue;
    }

    const timeSlotStartTime = dayjs('1-1-1 ' + obj.deliveryBeginTime).format('h:mm A');
    const timeSlotEndTime = dayjs('1-1-1 ' + obj.deliveryEndTime).format('h:mm A');

    if (!isOnline) {
      return timeSlotStartTime ? `${timeSlotStartTime}` : defaultValue;
    }

    return timeSlotStartTime ? `${timeSlotStartTime} - ${timeSlotEndTime}` : defaultValue;
  };

  const canCancelOrder = () => {
    const statues = ['NEW', 'ACTIVE', 'CANCELED'];

    const isOrderActive = orderDetails?.orderStatus.value?.toUpperCase() === 'NEW';

    const isOrderItemActive = orderDetails?.orderItems.every((oi) => statues.includes(oi.orderItemStatus.value?.toUpperCase() ?? ''));

    return isOrderActive && isOrderItemActive && !getOriginalOrderNumber(cart);
  };

  const getTotalNet = () => {
    const currencyCode = 'USD';
    const fractionDigits = 2;
    const productAmount = orderDetails?.productAmount || 0;
    const serviceFeeAmount = orderDetails?.serviceFeeAmount || 0;

    const centAmount = Math.round((productAmount + serviceFeeAmount) * 100);

    let money: Money = {
      type: 'centPrecision',
      currencyCode: currencyCode,
      centAmount: centAmount,
      fractionDigits: fractionDigits,
    };

    return money;
  };

  const getQuantityMessage = () => {
    let qty = 0;
    orderDetails?.orderItems.forEach((item) => {
      qty += item.qty;
    });

    const quantityMessage = `(${qty} item${qty !== 1 ? 's' : ''})`;

    return quantityMessage;
  };

  // Location
  const renderLocation = (): JSX.Element[] | null => {
    if (!orderDetails) {
      return null;
    }

    let res: JSX.Element[] = [];

    if (orderDetails?.shippingAddress) {
      const shippingAddress =
        orderDetails?.shippingAddress.addressType.toUpperCase() === 'DELIVERY SITE' ? orderDetails.deliverySite : orderDetails.shippingAddress;
      // var monthOptions: Intl.DateTimeFormatOptions = { month: 'short' };
      // var dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' };
      let deliveryDate = t('unavailable');

      let date = orderDetails?.estimatedDeliveryDates.find((dt) => dt !== undefined);
      if (date) {
        // const dateTime = new Date(date);
        // const day = new Intl.DateTimeFormat('en-US', dayOptions).format(dateTime.date());
        // const month = new Intl.DateTimeFormat('en-US', monthOptions).format(dateTime.date());
        // const dt = dateTime.getDate();

        const dateTime = dayjs(date);
        const day = dateTime.format('ddd');
        const month = dateTime.format('MMM');
        const dt = dateTime.date();
        deliveryDate = `${day}. ${month} ${dt}`;
      }

      const tsHours = getFormattedTimeSlot(orderDetails, t('dataNotAvailable', { ns: 'purchaseHistory' }));

      res.push(
        ...[
          <View key={'renderLocation_1'} style={[styles.flex1, styles.justifyContentSpaceBetween]} testID="renderLocation">
            <Text style={[appStyles.h5, styles.textAlignCenter]} testID="deliveryDate">
              {deliveryDate}
            </Text>
            <Text style={[appStyles.h5, styles.textAlignCenter]} testID="tsHours">
              {tsHours}
            </Text>
          </View>,
          <View key={'renderLocation_2'} style={[styles.infoPadSeparator, styles.margin10]} testID="renderLocation_2" />,
          <View key={'renderLocation_3'} style={[styles.location, styles.flex1]} testID="renderLocation_3">
            <View style={styles.locationLogo}>
              <StoreIcon storeInfo={storeInfo} />
            </View>
            <View style={styles.locationAddress}>
              <Text numberOfLines={1} style={[styles.locationText, styles.locationAddressLeft]} testID="locationAddress1">
                {shippingAddress && shippingAddress.address1}
              </Text>
              <Text numberOfLines={1} style={[styles.locationText, styles.locationAddressLeft]} testID="locationAddressCity">
                {shippingAddress && [shippingAddress.city, getStateAbbrevation(shippingAddress.state), shippingAddress.postalCode].join(' ')}
              </Text>
              <Text numberOfLines={1} style={[appStyles.bodySmallRegular, styles.locationAddressLeft]} testID="phone">
                {formatPhone(orderDetails?.shippingAddress.phone)}
              </Text>
            </View>
          </View>,
        ],
      );
    }
    return res;
  };

  const renderDeliveryLocation = () => {
    const shippingAddress = isOnline ? orderDetails?.shippingAddress : orderDetails?.deliverySite;
    // var monthOptions: Intl.DateTimeFormatOptions = { month: 'short' };
    // var dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' };
    let deliveryDate = t('unavailable');

    let addressType = '';
    if (orderDetails && orderDetails.addressType && orderDetails.addressType.value) {
      addressType = orderDetails.addressType.value.toLowerCase();
    }

    let date = orderDetails?.estimatedDeliveryDates.find((dt) => dt !== undefined);
    if (date) {
      // const dateTime = new Date(date);
      // const day = new Intl.DateTimeFormat('en-US', dayOptions).format(dateTime);
      // const month = new Intl.DateTimeFormat('en-US', monthOptions).format(dateTime);
      // const dt = dateTime.getDate();

      const dateTime = dayjs(date);
      const day = dateTime.format('ddd');
      const month = dateTime.format('MMM');
      const dt = dateTime.date();
      deliveryDate = `${day}. ${month} ${dt}`;
    }

    const tsHours = getFormattedTimeSlot(orderDetails!, t('dataNotAvailable', { ns: 'purchaseHistory' }));

    return (
      <View style={styles.flex1}>
        <View style={[styles.flex1, styles.justifyContentSpaceBetween]}>
          <Text style={[appStyles.h5, styles.textAlignCenter]} testID="deliveryDate">
            {deliveryDate}
          </Text>
          <Text style={[appStyles.h5, styles.textAlignCenter]} testID="tsHours">
            {tsHours}
          </Text>
        </View>
        <View style={[styles.infoPadSeparator, styles.margin10]} />
        <View style={[styles.location, styles.flex1]}>
          <View style={styles.iconWrapper}>
            <Icon
              testID="homeIcon"
              name={addressType === 'home' ? 'home-icon' : 'office-icon'}
              size={60}
              fill={colors.darkCream}
              stroke={colors.darkCream}
              style={styles.homeIcon}
            />
          </View>
          <View style={styles.locationInfo}>
            <Text numberOfLines={1} style={styles.locationText} testID="shippingAddressName">
              {shippingAddress && `${shippingAddress.firstName} ${shippingAddress.lastName}`}
            </Text>
            {addressType === 'business' && shippingAddress?.companyName ? (
              <Text numberOfLines={1} style={styles.locationText} testID="companyName">
                {shippingAddress && shippingAddress.companyName}
              </Text>
            ) : null}
            <Text numberOfLines={1} style={styles.locationText} testID="address1">
              {shippingAddress && shippingAddress.address1}
            </Text>
            <Text numberOfLines={1} style={styles.locationText} testID="shippingAddressCity">
              {shippingAddress && `${shippingAddress.city}, ${getStateAbbrevation(shippingAddress.state)} ${shippingAddress.postalCode}`}
            </Text>
            <Text numberOfLines={1} style={[styles.locationText, { fontFamily: FontFamily.LarsseitLight }]} testID="phone">
              {shippingAddress && shippingAddress.phone}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const [modifying, setModifying] = useState<boolean>(false);
  const [serviceContact, setServiceContact] = useState(appConstants.CUSTOMER_SERVICE_PHONE);
  const [orderStore, setOrderStore] = useState<Store | undefined>(undefined);
  const okToModify = useCallback(
    (defaultLead: number = 2 * 60, modifyTimer: number = 15) => {
      return isOrderOkToModify(orderDetails, orderStore, defaultLead, modifyTimer);
    },
    [orderDetails, orderStore],
  );
  const okToModifyMem = useMemo(
    () =>
      (_now: number | string = minuteKey(), ...rest: [number?, number?]) =>
        okToModify(...rest),
    [okToModify],
  );

  const refIntervalId = useRef<NodeJS.Timeout | number>(0);
  const [showModificationModal, setShowModificationModal] = useState<boolean>(false);
  const [showModify, setShowModify] = useState<boolean>(false);
  const [canModify, setCanModify] = useState<boolean>(false);
  useEffect(() => {
    const doUpdate = () => {
      const originalOrder = getOriginalOrderNumber(cart);
      const show = (cart ?? false) && !originalOrder && okToModifyMem(); // limit recalculations to 1/minute
      setShowModify(show);
    };
    doUpdate();
    setDelivery(shippingMethod?.toUpperCase() === 'DELIVERY' ?? false);
    setIsOnline(orderDetails?.orderType.id === '1');
    setCanReOrder(orderDetails?.orderStatus.id === '10' || orderDetails?.orderStatus.id === '11' || orderDetails?.orderStatus.id === '12');

    if (!refIntervalId.current) {
      refIntervalId.current = setInterval(doUpdate, 60 * 1000);
    }

    return () => {
      if (refIntervalId.current) {
        clearInterval(refIntervalId.current);
        refIntervalId.current = 0;
      }
    };
  }, [cart, okToModifyMem, orderDetails, shippingMethod]);

  const handleMakeChangesRequest = (order?: Order) => {
    // check time if can modify
    if (!order || !orderStore) {
      return;
    }
    const canMod = okToModifyMem(); // re-checks when clicked if on new minute
    setCanModify(canMod);
    setShowModificationModal(true);
  };

  const handleOrderModification = async (order?: Order) => {
    if (order) {
      try {
        setModifying(true);

        // check again (user time and fetch time in between) that there is still not already a pending modify order in queue
        const canMod = okToModifyMem();
        if (!canMod) {
          setCanModify(false);
          setModifying(false);
          // keep setShowModificationModal(false) up with new can't modify message
          return;
        }
        // configired to reload the cart, everything should be already set
        await modifyOrder(order);
        // if still here, clean up UI and Navigate to Cart, as the app reinitializes the cart
        setModifying(false);
        setShowModificationModal(false);
        navigation.navigate(AppStackRoutes.CartAndCheckout, {
          screen: CheckoutStackRoutes.Cart,
        });
      } catch (_e) {
        setCanModify(false);
        setModifying(false);
        // setShowModificationModal(false);  // leave dialog up, it will convert to error when canModify false state propagates
        try {
          await resetMyCart();
        } catch (_ee) {
          /* empty */
        }
      }
    }
  };

  const handleVerificationCancel = () => {
    // setCancelingWindow(false);
    setShowCancelVerificationModal(!showCancelVerificationModal);
  };

  const handleOrderVerification = async (orderNumber: string) => {
    // call cancel order API here
    queryClient.executeMutation({
      mutationKey: ['order', orderId],
      mutationFn: async () => orderService.cancelOrder(orderNumber),
      onError: (ex) => {
        handleError(ex);
      },
      onSuccess: async () => {
        setShowCancelVerificationModal(false);
        setShowCancelComirmationModal(true);
        setShouldRefetchOrderHistory(true);

        refetch();
      },
    });
  };

  const onSubmit = async () => {
    return false;
  };

  const handleOrderConfirmation = () => {
    setShowCancelComirmationModal(false);
  };

  const handleInfoIconPress = () => {
    setShowHelpModal(!showHelpModal);
  };

  const callANumber = (number?: string) => {
    Linking.openURL(`tel:${number}`);
  };

  useEffect(() => {
    const doGetStore = async (sn: string) => {
      return await storeService.getStore(sn);
    };
    if (orderDetails) {
      const storeNumber = getBWOrderStoreNumber(orderDetails);
      const store: Promise<Store> = doGetStore(storeNumber);
      store.then((s: Store) => {
        setOrderStore(s);
        const departmentPhone = (s.departments ?? []).find((x) => x && x.departmentId === +(Config.ONLINE_DEPARTMENT_ID ?? '11'))?.phoneNumber;
        const phone = departmentPhone ? departmentPhone : s?.phone;
        phone && setServiceContact(formatPhone(phone) ?? appConstants.CUSTOMER_SERVICE_PHONE);
      });
    }
  }, [orderDetails, setServiceContact]);

  const modalInfo = canModify ? (
    <Text key={'canModKey'} style={[styles.textInfo, styles.helpModalTextInfo, utilityStyles.my1]} testID="showModificationText">
      Your selected time slot will be held for <Text style={appStyles.bodyCenterBold}> 15 minutes </Text>while you modify your order. When finished, checkout to
      apply any changes made.
    </Text>
  ) : (
    [
      +(orderDetails?.orderStatus.id ?? '0') === OrderStatusType.Packing ? (
        <Text key={'canMptModKey1'} style={[styles.textInfo, styles.helpModalTextInfo, utilityStyles.my2]} testID="showModificationText">
          We are already already gathering your groceries. To request changes, call or text your Personal Shopper at:
        </Text>
      ) : (
        <Text key={'canMptModKey2'} style={[styles.textInfo, styles.helpModalTextInfo, utilityStyles.my2]} testID="showModificationText">
          We are about to start gathering your groceries. To request changes, call or text your Personal Shopper at:
        </Text>
      ),
      <Text
        key={'canMptModKey'}
        onPress={() => callANumber(serviceContact)}
        style={[appStyles.bodyCenterBold, utilityStyles.my1]}
        testID="showModificationText">
        {serviceContact}
      </Text>,
    ]
  );

  const handleReorder = () => {
    if (orderDetails) {
      let filteredOrderItems = orderDetails.orderItems.filter((oi) => !oi.excludeForReorder);
      let filteredOrderDetails: Order = { ...orderDetails, orderItems: filteredOrderItems };

      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Account,
        params: {
          screen: AccountStackRoutes.OrderHistory,
          params: {
            screen: OrderHistoryStackRoutes.Reorder,
            params: {
              orderDetails: filteredOrderDetails,
            },
          },
        },
      });
    }
  };

  const gotoOrderHistory = () => {
    navigation.navigate(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Account,
      params: {
        screen: AccountStackRoutes.OrderHistory,
        params: {
          screen: OrderHistoryStackRoutes.RecentOrders,
          params: { clearFilter: false, useRefetch: true },
        },
      },
    });
  };

  const handleGoBack = () => {
    if (shouldRefetchOrderHistory) {
      gotoOrderHistory();
    } else {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        gotoOrderHistory();
      }
    }
  };

  const navHeaderStyle = [!isLoading ? (canReOrder ? styles.noShadow : null) : null, { borderBottomWidth: 0 }];
  return (
    <Screen style={utilityStyles.pb0}>
      <NavigationHeader
        next={nextIcons}
        style={navHeaderStyle}
        subStyle={[styles.alignItemsFlexStart]}
        backIconstyle={{ marginLeft: scale(-6) }}
        onPress={handleGoBack}>
        <HeaderTitle style={[{ marginLeft: scale(-3) }, utilityStyles.pt2]} testID="purchaseDetailsHeader">
          {t('purchaseDetailsHeader', { ns: 'purchaseHistory' })}
        </HeaderTitle>
        {showModify && (
          <View style={[styles.modifyOrderPad]}>
            <Text numberOfLines={1} adjustsFontSizeToFit={true} style={[appStyles.bodySmallBold, utilityStyles.my3]} testID="makeChangesLabel">
              {t('makeChangesLabel', { ns: 'purchaseHistory' })}
            </Text>
            <Button
              testID="makeChangesButton"
              type="secondary"
              title={t('makeChangesButton', { ns: 'purchaseHistory' })}
              size="small"
              buttonStyle={[appStyles.secondaryButtonXsmall, styles.modifyOrderButton]}
              onPress={() => handleMakeChangesRequest(orderDetails)}
            />
          </View>
        )}
      </NavigationHeader>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <View style={[styles.flex1]}>
          {canReOrder ? (
            <DropShadow>
              <View style={styles.buttonContainer}>
                <Button
                  testID="reorder"
                  title="Reorder"
                  type="secondary"
                  size="small"
                  buttonStyle={[appStyles.secondaryButtonXsmall, styles.reorderButton]}
                  onPress={() => handleReorder()}
                />
                <Text style={[appStyles.fontMobileBodyLeftRegular, styles.reorderText]} testID="addAllInstruction">
                  Add any or all items from this order into your cart
                </Text>
              </View>
            </DropShadow>
          ) : null}
          <ScrollView style={styles.scrollView}>
            <CartInfoBanner />
            <View ref={orderViewRef} style={styles.captureView}>
              {/* TODO: Temporarily Commented  */}
              {/* {orderDetails?.orderStatus.value?.toUpperCase() !== 'COMPLETED' &&
                orderDetails?.orderStatus.value?.toUpperCase() !== 'DELIVERED' &&
                orderDetails?.orderStatus.value?.toUpperCase() !== 'CANCELED' && (
                  <>
                    <View style={[styles.vPadding, utilityStyles.mt2]} />
                    <View style={[styles.modifyOrderFrame, utilityStyles.pt4, utilityStyles.pb4]}>
                      <View style={[styles.flexrow]}>
                        <Text style={[appStyles.bodySmall]}>{t('needHelpMsg', { ns: 'purchaseHistory' })}</Text>
                        <LinkButton
                          style={styles.modifyOrderLinkButton}
                          onPress={() => {
                            showAlertModal();
                          }}>
                          <Text style={[appStyles.bodySmallLeft, { fontSize: scale(15) }]}>{t('modifyOrderLink', { ns: 'purchaseHistory' })}</Text>
                        </LinkButton>
                      </View>
                      <View style={[styles.vPadding, utilityStyles.mt1]} />
                      <View style={[styles.flexrow]}>
                        <Text style={[appStyles.bodySmall]}>
                          Available until 12:00 PM, {orderDetails ? dayjs(orderDetails?.estimatedDeliveryDates[0]).format('MMM DD') : 'Unavailable'}
                        </Text>
                      </View>
                    </View>
                  </>
                )} */}
              <View style={[styles.vPadding, utilityStyles.mt2]} />
              <View style={[styles.flexrow, styles.itemFrame, utilityStyles.mt3]}>
                <Text style={[appStyles.bodySmall, styles.font16]} testID="orderNumberLabel">
                  {isOnline ? t('orderNumberLabel', { ns: 'purchaseHistory' }) : t('inStoreTransactionLabel', { ns: 'purchaseHistory' })}
                </Text>
                {isOnline ? (
                  <Text testID="orderDateLabel" style={[appStyles.bodySmall, styles.font16, styles.marginLeftAuto]}>
                    {t('orderDateLabel', { ns: 'purchaseHistory' })}
                  </Text>
                ) : null}
              </View>
              <View style={[styles.flexrow, styles.itemFrame, utilityStyles.mt1, utilityStyles.mb3]}>
                <Text style={[appStyles.bodySmallLeft]} testID="extOrderId">
                  {orderDetails?.extOrderId || 'Unavailable'}
                </Text>
                {isOnline ? (
                  <Text style={[appStyles.bodySmall, styles.font16, styles.marginLeftAuto]} testID="dateOptions">
                    {/* {orderDetails ? new Date(orderDetails?.createdDate).toLocaleDateString('en-US', dateOptions).toUpperCase() : 'Unavailable'} */}
                    {orderDetails ? dayjs(orderDetails?.createdDate).format('MMM DD, YYYY').toUpperCase() : 'Unavailable'}
                  </Text>
                ) : null}
              </View>
              {isOnline ? (
                <View>
                  <Text style={[utilityStyles.ml3, utilityStyles.mt4, appStyles.bodySmallRegular]} testID="orderStatusLabel">
                    {t('orderStatusLabel', { ns: 'purchaseHistory' })}
                  </Text>
                  <View style={styles.statusContainer}>
                    <View style={[styles.infoPadLightForStatus, { borderColor: colors.red }]}>
                      <View style={[styles.flex1, styles.alignItemsFlexStart]} testID="orderStatusView">
                        <Text style={[appStyles.h5Red, utilityStyles.ml2]} testID="orderStatus">
                          {orderDetails?.orderStatus.value}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.shippingMethod}>
                      <View style={styles.iconView}>
                        {shippingMethod.toUpperCase() === 'PICKUP' && (
                          <Icon testID="pickupIcon" stroke={'none'} size={30} name="pickup-icon" fill={colors.sectionBorder} style={styles.center} />
                        )}
                        {shippingMethod.toUpperCase() === 'DELIVERY' && (
                          <Icon
                            testID="deliveryIcon"
                            stroke={'none'}
                            size={30}
                            name="delivery-icon"
                            fill={colors.sectionBorder}
                            strokeSecondary={colors.sectionBorder}
                            style={[styles.center, { paddingBottom: scale(8) }]}
                          />
                        )}
                        {shippingMethod.toUpperCase() === 'IN-STORE' && (
                          <Icon
                            testID="list-icon"
                            stroke={'none'}
                            size={22}
                            name="list-icon"
                            fill={colors.darkText}
                            style={[styles.center, styles.iconPadding]}
                          />
                        )}
                      </View>
                      <Text style={[styles.methodText]} testID="Method">
                        Method
                      </Text>
                      <Text style={[styles.methodText]} testID="shippingMethod">
                        {shippingMethod}
                      </Text>
                    </View>
                  </View>
                  {canCancelOrder() && (
                    <View style={styles.cancelOrderTextWrapper}>
                      <TouchableOpacity onPress={handleVerificationCancel} testID="handleVerificationCancel">
                        <Text style={styles.cancelOrderText} testID="cancelOrderLabel">
                          {t('cancelOrderLabel', { ns: 'purchaseHistory' })}
                        </Text>
                      </TouchableOpacity>
                      {/* <TouchableOpacity style={[styles.touchableIcon, { paddingLeft: 20, paddingTop: 5 }]} onPress={handleInfoIconPress}>
                  <Icon name="info-icon" size={15} style={[appStyles.smallIcon]} />
                </TouchableOpacity> */}
                    </View>
                  )}
                  {orderDetails?.orderStatus.value?.toUpperCase() === 'CANCELED' && (
                    <Text testID="canceledMsg" style={styles.cancelledStatusStyle}>
                      {t('canceledMsg', { ns: 'purchaseHistory' })}
                    </Text>
                  )}
                </View>
              ) : null}
              {!isOnline ? (
                <View style={[styles.vPadding, styles.earnedPoints]}>
                  <Text style={[appStyles.bodySmallRegular, styles.earnedPointsText]} testID="pointsValue">
                    You Earned <Text style={styles.earnedPointsBold}>{pointsValue(getTotalNet())}</Text> Something Extra Points on this Purchase!
                  </Text>
                </View>
              ) : null}
              <TouchableOpacity activeOpacity={1}>
                <View style={[styles.vPadding, utilityStyles.mt5]} />
                {isOnline ? (
                  <Accordion
                    testID="orderDetailShippingMethod"
                    iconStyle={appStyles.largeIcon}
                    setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, shippingMethod: isOpen })}
                    title={isDelivery ? 'Delivery' : 'Pickup'}
                    isOpen={dropDownState.shippingMethod}>
                    <View style={[styles.vPadding, utilityStyles.mt4]} />
                    <Text style={[utilityStyles.ml3, appStyles.bodySmallRegular]} testID="timeLocation">
                      {isOnline ? (isDelivery ? 'Delivery ' : 'Pickup ') : null}Time & Location
                    </Text>
                    {isDelivery ? (
                      <View style={[styles.infoPad, styles.locationBox]}>{renderDeliveryLocation()}</View>
                    ) : (
                      <View style={[styles.infoPad, styles.locationBox]}>{renderLocation()}</View>
                    )}

                    {!isDelivery &&
                      orderDetails?.orderStatus.value?.toUpperCase() !== 'COMPLETED' &&
                      orderDetails?.orderStatus.value?.toUpperCase() !== 'DELIVERED' &&
                      pickupPerson && (
                        <>
                          <View style={[styles.vPadding, utilityStyles.m2, utilityStyles.my3]} />
                          <Text style={[appStyles.bodySmallRegular, styles.itemFrame]} testID="designatedText">
                            {t('designatedPickupPerson')}
                          </Text>
                          <View style={[styles.infoPadLight]}>
                            <Text style={[appStyles.bodySmallRegular]} testID="pickupPerson">
                              {pickupPerson}
                            </Text>
                          </View>
                        </>
                      )}

                    {orderDetails?.orderStatus.value?.toUpperCase() !== 'COMPLETED' && orderDetails?.orderStatus.value?.toUpperCase() !== 'DELIVERED' && (
                      <>
                        <View style={[styles.vPadding, utilityStyles.m2, utilityStyles.my3]} />
                        <Text style={[appStyles.bodySmallRegular, styles.itemFrame]} testID="instructions">
                          {isDelivery ? t('deliveryInstructions') : t('pickupInstructions')}
                        </Text>
                        <View style={[styles.infoPadLight]}>
                          <Text style={[appStyles.bodySmallRegular]} testID="shippingNote">
                            {orderDetails?.shippingNote ?? ''}
                          </Text>
                        </View>
                      </>
                    )}
                    <View style={[styles.vPadding, utilityStyles.m2]} />
                  </Accordion>
                ) : (
                  <View style={[styles.vPadding, utilityStyles.mt0, styles.bMargin]}>
                    <Text style={[appStyles.bodySmallRegular]} testID="timetext">
                      Time & Location
                    </Text>
                    {isDelivery ? (
                      <View style={[styles.infoPad, styles.locationBox]}>{renderDeliveryLocation()}</View>
                    ) : (
                      <View style={[styles.infoPad, styles.locationBox]}>{renderLocation()}</View>
                    )}

                    {!isDelivery &&
                      orderDetails?.orderStatus.value?.toUpperCase() !== 'COMPLETED' &&
                      orderDetails?.orderStatus.value?.toUpperCase() !== 'DELIVERED' && (
                        <>
                          <View style={[styles.vPadding, utilityStyles.m2, utilityStyles.my3]} />
                          <Text style={[appStyles.bodySmallRegular, styles.itemFrame]} testID="designatedPickupPerson">
                            Designated Pickup Person
                          </Text>
                          <View style={[styles.infoPadLight]}>
                            <Text style={[appStyles.bodySmallRegular]} testID="pickupPerson">
                              {pickupPerson}
                            </Text>
                          </View>
                        </>
                      )}

                    {orderDetails?.orderStatus.value?.toUpperCase() !== 'COMPLETED' && orderDetails?.orderStatus.value?.toUpperCase() !== 'DELIVERED' && (
                      <>
                        <View style={[styles.vPadding, utilityStyles.m2, utilityStyles.my3]} />
                        <Text style={[appStyles.bodySmallRegular, styles.itemFrame]} testID="deliveryInstructions">
                          {isDelivery ? t('deliveryInstructions') : t('pickupInstructions')}
                        </Text>
                        <View style={[styles.infoPadLight]}>
                          <Text style={[appStyles.bodySmallRegular]} testID="shippingNoteDelivery">
                            {orderDetails?.shippingNote ?? ''}
                          </Text>
                        </View>
                        <View style={[styles.vPadding, utilityStyles.m2]} />
                      </>
                    )}
                  </View>
                )}
                {isOnline ? (
                  <Accordion
                    testID="orderDetailContact"
                    iconStyle={appStyles.largeIcon}
                    setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, orderContact: isOpen })}
                    title={t('orderContact', { ns: 'purchaseHistory' })}
                    isOpen={dropDownState.orderContact}>
                    <View style={[styles.vPadding, utilityStyles.mt1]} />
                    <Form
                      key={'checkoutScreenFormKey'}
                      initialValues={{
                        firstName: orderDetails?.alternateContactFirstName ?? orderDetails?.customer.firstName,
                        lastName: orderDetails?.alternateContactLastName ?? orderDetails?.customer.lastName,
                        phone: orderDetails?.alternateContactPhoneNumber ?? orderDetails?.customer.phone,
                        allowText: orderDetails?.alternateContactAllowText ?? false,
                      }}
                      onSubmit={onSubmit}
                      validationSchema={validationSchema}>
                      <ErrorMessage error={t('errorMsg', { ns: 'purchaseHistory' })} visible={false} testID="errorMsg" />
                      <View style={styles.contactContainer}>
                        <FormField
                          testID="firstName"
                          label={t('firstName')}
                          autoCapitalize="none"
                          autoCorrect={false}
                          keyboardType="ascii-capable"
                          name="firstName"
                          textContentType="givenName"
                          returnKeyType="next"
                          editable={false}
                          fieldStyleEx={styles.fieldStyleEx}
                        />
                        <FormField
                          testID="lastName"
                          label={t('lastName')}
                          autoCapitalize="none"
                          textContentType="familyName"
                          autoCorrect={false}
                          name="lastName"
                          returnKeyType="next"
                          editable={false}
                          fieldStyleEx={styles.fieldStyleEx}
                        />
                        <FormField
                          testID="phone"
                          label={t('phone', { ns: 'purchaseHistory' })}
                          autoCapitalize="none"
                          autoCorrect={false}
                          keyboardType="phone-pad"
                          name="phone"
                          placeholder=""
                          textContentType="telephoneNumber"
                          returnKeyType="next"
                          formatter={formatPhone}
                          editable={false}
                          fieldStyleEx={styles.fieldStyleEx}
                        />
                        <FormSwitch
                          testID="allowText"
                          name={'allowText'}
                          label={t('allowText', { ns: 'purchaseHistory' })}
                          containerStyle={styles.allowTextCheckBox}
                          disabled={true}
                        />
                      </View>
                    </Form>
                  </Accordion>
                ) : null}
                <Accordion
                  testID="orderDetailCart"
                  iconStyle={appStyles.largeIcon}
                  setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, items: isOpen })}
                  title={t('invoiceItems')}
                  isOpen={dropDownState.items}
                  customMsg={getQuantityMessage()}>
                  <View key={'separatorLine'} style={[styles.vPadding, utilityStyles.mt1]} />
                  <ItemizedOrder order={orderDetails} />
                </Accordion>
                <Accordion
                  testID="orderDetailInvoice"
                  iconStyle={appStyles.largeIcon}
                  setNextState={(isOpen: boolean) => setDropDownState({ ...dropDownState, invoice: isOpen })}
                  title={t('invoiceTotals')}
                  isOpen={dropDownState.invoice}>
                  {/* <InvoiceOrder obj={orderDetails} title={'Subtotal'} /> */}
                  <InvoiceOrder obj={orderDetails} isDelivery={isDelivery} isOnline={isOnline} />
                </Accordion>
                <View style={styles.orderNoteContainer}>
                  <Text style={[appStyles.bodySmallRegular, styles.itemFrame]} testID="orderNote">
                    Order Note
                  </Text>
                  <View style={[styles.infoPadLight]}>
                    <Text style={[appStyles.bodySmallRegular]} testID="orderNoteText">
                      {orderDetails?.orderNote ?? ''}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          </ScrollView>
          {showCancelVerificationModal && (
            <Modal
              visible={showCancelVerificationModal}
              headerStyle={styles.cancelVerificationHeader}
              title="Are You Sure You Want to Cancel?"
              cancelButtonOnPress={handleVerificationCancel}
              cancelButtonType="secondary"
              cancelButtonText="Back"
              okButtonText="Yes"
              okButtonOnPress={() => handleOrderVerification(orderDetails?.extOrderId as string)}
              location="top"
              style={styles.modalHeight}
              titleStyle={styles.paddingBottom}
              subTextViewStyle={styles.subTextStyle}
            />
          )}
          {showCancelComfirmationModal && (
            <Modal
              visible={showCancelComfirmationModal}
              showCancel={false}
              showCloseIcon={false}
              title="Cancellation Confirmed"
              okButtonText="OK"
              okButtonStyle={styles.helpModalButton}
              okButtonOnPress={handleOrderConfirmation}
              location="top"
              style={styles.modalHeight}
              titleStyle={[styles.paddingBottom, styles.comfirmationModalTitle]}
              subTextViewStyle={styles.subTextStyle}>
              <Text style={[styles.textInfo, styles.comfirmationModalTextInfo]} testID="showCancelComfirmationText">
                Your order has been canceled. For orders paid by credit or debit card, the refund will post to your account within 3-5 business days.{' '}
              </Text>
            </Modal>
          )}
          {showHelpModal && (
            <Modal
              visible={showHelpModal}
              title="Not Available To Cancel"
              titleStyle={styles.HelpModalTitle}
              showCancel={false}
              showCloseIcon={false}
              cancelButtonOnPress={handleInfoIconPress}
              cancelButtonType="secondary"
              cancelButtonText="Back"
              okButtonText="OK"
              okButtonStyle={styles.helpModalButton}
              okButtonOnPress={handleInfoIconPress}
              location="top"
              style={styles.comfirmationModalHeight}
              subTextViewStyle={styles.subTextStyle}>
              <Text style={[styles.textInfo, styles.helpModalTextInfo]} testID="showHelpText">
                Your order is already in progress or delivered and can no longer be canceled. We apologize for this inconvenience. If you have any questions or
                need assistance, please contact the Raley's Service Center at 1-800-925-9989
              </Text>
            </Modal>
          )}
          {showModificationModal && (
            <Modal
              visible={showModificationModal}
              title={canModify ? `Want to modify${'\n'}your order?` : t('canNotmakeChangesModalTitle', { ns: 'purchaseHistory' })}
              okButtonOnPress={() => setShowModificationModal(false)}
              onBlur={() => setShowModificationModal(false)}
              okButtonText={canModify ? t('noButtonText', { ns: 'purchaseHistory' }) : undefined}
              cancelButtonText={canModify ? t('yesButtonText', { ns: 'purchaseHistory' }) : t('okButtonText', { ns: 'purchaseHistory' })}
              cancelButtonStyle={styles.borderWidth25}
              okButtonStyle={styles.borderWidth25}
              cancelButtonOnPress={canModify ? () => handleOrderModification(orderDetails) : () => setShowModificationModal(false)}
              location="top"
              cancelLoading={modifying}
              style={canModify ? styles.canModifyModalHeight : styles.canNotModifyModalHeight}
              titleStyle={styles.paddingBottom}>
              {modalInfo}
            </Modal>
          )}
        </View>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  location: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '65%',
    marginHorizontal: 25,
  },
  nextIconContainer: {
    flexDirection: 'row',
  },
  locationLogo: {
    width: scale(100),
  },
  locationAddress: {
    alignSelf: 'center',
    flex: 1,
  },
  vPadding: {
    marginTop: 7,
    alignSelf: 'center',
    width: containerWidth,
  },
  bMargin: {
    marginBottom: scale(60),
  },
  flex1: { flex: 1 },
  flexrow: { flexDirection: 'row' },
  margin10: { margin: 10 },
  marginLeftAuto: { marginLeft: 'auto' },
  scrollView: {},
  captureView: { backgroundColor: colors.cream, opacity: 1 },
  textAlignCenter: { textAlign: 'center' },
  itemFrame: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    width: containerWidth,
    alignSelf: 'center',
  },
  noShadow: {
    shadowOpacity: 0,
  },
  buttonContainer: {
    flexDirection: 'row',
    paddingVertical: 5,
    backgroundColor: colors.sectionPad,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modifyOrderButton: {
    paddingVertical: 0,
  },
  reorderButton: {
    paddingVertical: 0,
    flex: 0.1,
  },
  reorderText: {
    marginLeft: 20,
    flex: 0.8,
    lineHeight: lineHeight(16),
  },
  earnedPoints: {
    borderRadius: 8,
    borderColor: colors.sectionBorder,
    borderWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 32,
  },
  earnedPointsText: {
    textAlign: 'center',
    fontSize: scale(18),
    lineHeight: lineHeight(22),
  },
  earnedPointsBold: {
    fontFamily: FontFamily.LarsseitBold,
    textDecorationLine: 'underline',
  },
  modifyOrderPad: {
    backgroundColor: colors.sectionPad,
    width: screenWidth,
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  infoPad: {
    backgroundColor: colors.white,
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    borderRadius: 7,
    alignSelf: 'center',
    flex: 1,
  },
  infoPadLight: {
    backgroundColor: colors.cream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.darkCream,
    borderRadius: 7,
    alignSelf: 'center',
  },
  infoPadLightForStatus: {
    backgroundColor: colors.cream,
    padding: scale(12),
    left: 15,
    width: containerWidth - 60,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.darkCream,
    borderRadius: 7,
    alignSelf: 'center',
  },
  infoPadSeparator: {
    height: 1,
    backgroundColor: colors.sectionBorder,
    alignSelf: 'stretch',
    margin: 20,
  },
  locationInfo: {
    paddingRight: 16,
    justifyContent: 'space-between',
    width: '60%',
  },
  locationText: {
    fontSize: scale(16),
    color: colors.darkText,
    lineHeight: lineHeight(18),
    fontFamily: FontFamily.LarsseitBold,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    height: '100%',
    paddingRight: 32,
  },
  homeIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 100,
  },
  center: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  statusContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelOrderTextWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    left: 0,
    paddingTop: 0,
  },
  shippingMethod: {
    marginRight: scale(16),
    paddingHorizontal: 8,
    paddingVertical: 5,
  },
  cancelOrderText: {
    color: colors.red,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: 14,
    lineHeight: 18,
    textAlign: 'left',
    textDecorationLine: 'underline',
    textDecorationColor: colors.red,
    textDecorationStyle: 'solid',
    paddingTop: 5,
    left: 15,
  },
  methodText: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    lineHeight: 11,
    textAlign: 'center',
    fontSize: 10,
  },
  textInfo: {
    fontSize: scale(18),
    color: colors.primary,
    textAlign: 'center',
    lineHeight: lineHeight(20),
    fontFamily: FontFamily.LarsseitLight,
  },
  subTextStyle: {
    marginVertical: 0,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  paddingBottom: {},
  modalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.32 : screenHeight * 0.36) : screenHeight > 640 ? screenHeight * 0.3 : screenHeight * 0.4,
  },
  comfirmationModalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.5 : screenHeight * 0.56) : screenHeight > 640 ? screenHeight * 0.5 : screenHeight * 0.6,
  },
  canModifyModalHeight: {
    height:
      Platform.OS === 'ios'
        ? screenHeight > 740
          ? screenHeight * 0.455
          : screenHeight * 0.515
        : screenHeight > 640
          ? screenHeight * 0.47
          : screenHeight * 0.56,
  },
  canNotModifyModalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.44 : screenHeight * 0.48) : screenHeight > 640 ? screenHeight * 0.44 : screenHeight * 0.48,
  },
  comfirmationModalTitle: {
    fontSize: scale(26),
  },
  cancelVerificationHeader: {
    top: 35,
  },
  HelpModalTitle: {
    fontSize: scale(26),
  },
  comfirmationModalTextInfo: {
    fontSize: scale(16),
  },
  helpModalTextInfo: {
    lineHeight: lineHeight(22),
  },
  helpModalButton: {
    width: 150,
  },
  fieldStyleEx: {
    backgroundColor: 'transparent',
  },
  font16: {
    fontSize: scale(16),
  },
  iconView: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  justifyContentSpaceBetween: { justifyContent: 'space-between' },
  alignItemsFlexStart: { alignItems: 'flex-start' },
  orderNoteContainer: { backgroundColor: colors.cream, paddingTop: 25, paddingBottom: 25 },
  allowTextCheckBox: { width: '89%' },
  contactContainer: { ...utilityStyles.ml3, ...utilityStyles.mr3, paddingTop: 13, paddingBottom: 13 },
  locationBox: { padding: 13, borderColor: colors.enabledOutline },
  cancelledStatusStyle: {
    fontFamily: FontFamily.LarsseitLight,
    fontSize: 16,
    lineHeight: 18,
    left: 15,
    maxWidth: containerWidth - 10,
    paddingTop: 5,
  },
  iconPadding: { paddingBottom: 4 },
  locationAddressLeft: { textAlign: 'left', marginVertical: 2 },
  borderWidth25: { borderWidth: 2.5 },
  helpLinkStyle: { marginTop: 5 },
  storeIconStyle: { width: '100%', justifyContent: 'center' },
});

export default PurchaseDetailScreen;

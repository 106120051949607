import { AddressItem, Icon, IconTextInput, Modal, SaveBar } from '@components';
import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Address, ShopType, Store } from '@fieldera-raleys/client-common';
import { useRefetchOnFocus } from '@hooks';
import { getLastKnownLocation } from '@hooks/useLocation';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { storeService, userService } from '@services/brandywine';
import { useCartStore } from '@store';
import useShopStore from '@store/shopStore';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  FlatList,
  Keyboard,
  ListRenderItemInfo,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useQuery } from 'react-query';
import { queryClient } from '../../utils/reactQuery';

interface DeliveryScreenProps {
  toggleTombstone: () => void;
  setCurrentPage: Dispatch<SetStateAction<string>>;
}

const DeliveryScreen: FC<DeliveryScreenProps> = ({ setCurrentPage }): JSX.Element => {
  const navigation = useNavigation();
  const { t } = useTranslation('tombstone');
  const handleError = useErrorHandler();
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(undefined);
  const [emptyList, setEmptyList] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    selectedStore,
    setEditAddressInfo,
    setSelectedStore,
    setDeliveryAddress,
    deliveryAddress,
    setSelectedTimeSlot,
    selectedTimeSlot,
    previousStore,
    previousDeliveryAddress,
    previousTimeSlot,
    previousShopType,
    setSelectedShopType,
    setPreviousShopType,
  } = useShopStore();
  const { data: addressList, isLoading, refetch, isRefetching } = useQuery('address-list', async () => await userService.getAddressList());
  const [cartChangeStore, setCartChangeStore] = useState<Store | undefined>(undefined);
  const [showCartChangeModal, setShowCartChangeModal] = useState(false);
  const [searchPostal, setSearchPostal] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAddressId, setDeleteAddressId] = useState<string | number | undefined>();
  // const [storeInfo, setStoreInfo] = useState<Store | undefined>(undefined);
  const { validateCart } = useCartStore();

  useRefetchOnFocus(refetch);

  useEffect(() => {
    setLoading(isLoading || isRefetching);
  }, [isLoading, isRefetching]);

  const checkDeliveryArea = async (postalCode: string): Promise<void> => {
    setSearchPostal(postalCode);
    setLoading(true);

    var locationData = await getLastKnownLocation();
    try {
      const searchResults = await storeService.searchStores(0, 1, {
        shippingMethod: 'delivery',
        postalCode,
        latitude: locationData?.coords.latitude,
        longitude: locationData?.coords.longitude,
      });

      if (searchResults.data.length === 0) {
        // TODO: no delivery area found show appropriate message
        setLoading(false);
        setEmptyList(true);
        return;
      }
      const store = searchResults.data[0];
      const validationResult = await validateCart(store.number).catch((err) => handleError(err));
      if (!validationResult?.isValid) {
        // setStoreInfo(store);
        setCartChangeStore(store);
        setLoading(false);
        setEmptyList(false);
        setShowCartChangeModal(true);
        return;
      }

      setEmptyList(false);
      setPreviousShopType(ShopType.DELIVERY);
      setSelectedShopType(ShopType.DELIVERY);
      setSelectedTimeSlot(undefined);
      setSelectedStore(store);
      setDeliveryAddress(selectedAddress ? selectedAddress : ({ state: store.address.state, postalCode: postalCode } as Address));
      setCurrentPage('TimeSlot');
    } catch (ex) {
      handleError(ex);
    }
    setLoading(false);
    setSearchPostal('');
  };

  const handleRemove = (address: Address) => {
    setDeleteAddressId(address.id);
    setShowDeleteModal(true);
  };

  const handleRemoveAddress = async (): Promise<void> => {
    if (deleteAddressId) {
      setLoading(true);
      setShowDeleteModal(false);
      queryClient.executeMutation({
        mutationKey: ['address', deleteAddressId],
        mutationFn: async () => userService.deleteAddress(deleteAddressId),
        onError: (ex) => {
          handleError(ex);
        },
        onSuccess: async () => {
          setDeleteAddressId(undefined);
          await queryClient.invalidateQueries('address-list');
          await refetch();
        },
        onSettled: () => {
          setLoading(false);
        },
      });
    }
  };

  const handleCartChange = () => {
    const zip = searchPostal ? searchPostal : cartChangeStore?.address?.zip;
    setShowCartChangeModal(false);
    navigation.dispatch(
      StackActions.push(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.CartLanding,
        params: {
          storeNumber: cartChangeStore?.number,
          shopType: ShopType.DELIVERY,
          deliveryAddress: selectedAddress ? selectedAddress : ({ state: cartChangeStore?.address?.state ?? 'CA', postalCode: zip ?? '' } as Address),
        },
      }),
    );
    setCartChangeStore(undefined);
    setSearchPostal('');
    // setStoreInfo(undefined);
  };

  const handleCancelValidation = () => {
    if (previousShopType === ShopType.DELIVERY) {
      if (previousStore) {
        setSelectedShopType(ShopType.DELIVERY);
        setSelectedStore(previousStore);
        setDeliveryAddress(previousDeliveryAddress);
        setSelectedTimeSlot(previousTimeSlot);
      }
      setCartChangeStore(undefined);
      setShowCartChangeModal(false);
      return;
    } else if (previousShopType === ShopType.PICKUP) {
      setSelectedShopType(ShopType.PICKUP);
      setSelectedStore(previousStore || selectedStore);
      setSelectedTimeSlot(previousTimeSlot || selectedTimeSlot);
      setCurrentPage('FindAStore');
      return;
    }
    setCartChangeStore(undefined);
    setShowCartChangeModal(false);
  };

  const saveLocation = async () => {
    if (selectedAddress?.postalCode) {
      await checkDeliveryArea(selectedAddress.postalCode);
    }
  };
  const handleEdit = (address: Address) => {
    setEditAddressInfo(address);
    setCurrentPage('AddressBook');
  };

  const renderAddress = ({ item, index }: ListRenderItemInfo<Address>) => {
    return (
      <AddressItem
        item={item}
        isSelected={item.id === selectedAddress?.id}
        onPress={setSelectedAddress}
        onRemove={handleRemove}
        onEdit={handleEdit}
        index={index}
      />
    );
  };

  const renderEmptyList = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Text style={[styles.boldOrText, { fontSize: scale(15) }]} testID="deliveryNoAddresses">
          {t('deliveryNoAddresses')}
        </Text>
      </View>
    );
  };

  const renderFooter = () => {
    const newAddress: Address = {
      firstName: '',
      lastName: '',
      address1: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
      phone: '',
      email: '',
      addressType: 'home',
      isDefaultShipping: false,
      isDefaultBilling: false,
      isValidated: false,
    };

    return (
      <TouchableOpacity
        testID="addAddressClick"
        activeOpacity={0.7}
        style={styles.footerContainer}
        onPress={() => {
          setEditAddressInfo(newAddress);
          setCurrentPage('AddressBook');
        }}>
        <Icon name={'circle-plus'} fill={colors.white} stroke={colors.red} strokeSecondary={colors.darkCream} size={35} testID="circleplus" />
        <Text style={styles.addText} testID="addAddressText">
          Add Address
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      {!selectedStore?.address?.street ? (
        <>
          <Text style={styles.deliveryText} testID="delivery">
            Delivery
          </Text>
          <TouchableOpacity style={styles.backArrow} onPress={() => setCurrentPage('InitialShopOptions')} testID="arrowLeftButton">
            <Icon name={'arrow-left'} testID="arrowLeft" />
          </TouchableOpacity>
        </>
      ) : null}
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()} testID="dismissKeyboardButton">
        <>
          <View style={styles.instructionsWrapper}>
            {emptyList ? (
              <View style={{ flexDirection: 'row' }}>
                <Icon
                  testID="pin-questionmark-solid"
                  name={'pin-questionmark-solid'}
                  fill={colors.white}
                  size={68}
                  stroke={colors.darkCream}
                  style={styles.emptyIcon}
                />
                <Text style={[styles.instructionsText, { width: '75%', textAlign: 'left', marginLeft: 10 }]} testID="deliveryError">
                  {t('deliveryError')}
                </Text>
              </View>
            ) : (
              <Text style={styles.instructionsText} testID="deliveryInstructions">
                {t('deliveryInstructions')}
              </Text>
            )}
          </View>
          <View style={styles.searchWrapper}>
            <IconTextInput
              testID="zipInput"
              keyboardType={'number-pad'}
              width={'85%'}
              clearButtonMode="while-editing"
              placeholder="Enter your zip code"
              returnKeyType="done"
              onSubmitEditing={({ nativeEvent: { text } }) => {
                if (!text) {
                  return;
                }
                checkDeliveryArea(text);
              }}
            />
          </View>
        </>
      </TouchableWithoutFeedback>
      {loading ? (
        <ActivityIndicator style={styles.activityIndicator} />
      ) : (
        <>
          {addressList && addressList.length > 0 && (
            <View style={styles.additionalInstructionsWrapper}>
              <Text style={styles.boldOrText}>OR</Text>
              <Text style={[styles.instructionsText, { marginBottom: 5 }]} testID="SelectAddressBook">
                Select from your Address Book:
              </Text>
            </View>
          )}
          <FlatList
            testID="deliveryAddressList"
            data={addressList}
            keyExtractor={(_, i) => i.toString()}
            renderItem={renderAddress}
            ListEmptyComponent={renderEmptyList}
            ListFooterComponent={renderFooter}
            refreshing={isRefetching}
            onRefresh={refetch}
          />
          {selectedAddress && selectedAddress.id ? (
            <View style={!deliveryAddress?.id ? styles.saveBar : styles.saveBarSet} pointerEvents={'box-none'}>
              <SaveBar isButtonLoading={loading} onPress={saveLocation} testID="saveBar" />
            </View>
          ) : null}
          {showDeleteModal && (
            <Modal
              style={{ minHeight: screenHeight / 3 }}
              buttonContainerStyle={{ bottom: screenHeight > 740 ? 30 : 15 }}
              headerStyle={{ justifyContent: 'center' }}
              contentStyle={{ width: screenWidth, paddingHorizontal: 16 }}
              visible={showDeleteModal}
              title={'Remove Address?'}
              location="top"
              cancelButtonOnPress={() => setShowDeleteModal(false)}
              okButtonOnPress={handleRemoveAddress}
              cancelButtonText="Go back"
              okButtonText="Remove">
              <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="deleteModalText">
                Please confirm you'd like to remove this address from your Address Book.
              </Text>
            </Modal>
          )}
          {showCartChangeModal && (
            <Modal
              style={{ minHeight: screenHeight / 3 }}
              buttonContainerStyle={{ bottom: screenHeight > 740 ? 30 : 15 }}
              headerStyle={{ justifyContent: 'center' }}
              contentStyle={{ width: screenWidth, paddingHorizontal: 16 }}
              visible={showCartChangeModal}
              title={'Changes to Your Cart'}
              location="top"
              cancelButtonOnPress={handleCancelValidation}
              okButtonOnPress={handleCartChange}
              cancelButtonText="Cancel"
              okButtonText="Continue">
              <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} allowFontScaling={false} testID="cartchangeModalText">
                Some items in your cart may be affected. Would you like to continue?
              </Text>
            </Modal>
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '90%',
  },
  activityWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
  },
  activityIndicator: {
    alignSelf: 'center',
    width: screenWidth,
    marginBottom: 10,
    marginTop: 100,
  },
  backArrow: {
    position: 'absolute',
    left: 0,
    top: Platform.OS === 'web' ? -20 : -45,
    paddingLeft: 10,
    paddingTop: 9,
    width: '20%',
    height: 50,
  },
  deliveryText: {
    fontFamily: 'Aleo-Bold',
    fontSize: 25,
    lineHeight: 30,
    color: colors.text,
    textAlign: 'center',
    width: '62%',
    position: 'absolute',
    top: Platform.OS === 'web' ? -20 : -35,
    zIndex: 0,
    alignSelf: 'center',
  },
  instructionsWrapper: {
    marginTop: 20,
    paddingHorizontal: 30,
    width: '100%',
  },
  instructionsText: {
    textAlign: 'center',
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: 26,
  },
  searchWrapper: {
    width: '100%',
    marginTop: 15,
    alignItems: 'center',
  },
  additionalInstructionsWrapper: {
    alignItems: 'center',
  },
  emptyIcon: {
    width: '25%',
    height: '100%',
    alignItems: 'center',
  },
  boldOrText: {
    marginVertical: 10,
    color: colors.darkText,
    fontSize: scale(16),
    fontFamily: FontFamily.LarsseitBold,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: 5,
    marginBottom: 85,
    paddingBottom: 15,
  },
  addText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(20),
    color: colors.darkText,
    marginLeft: 10,
    lineHeight: lineHeight(20),
  },
  saveBar: {
    bottom: screenHeight < 812 ? -screenHeight * 0.065 : -screenHeight * 0.057,
    left: screenHeight < 813 ? -9 : -10.5,
  },
  saveBarSet: {
    bottom: screenHeight < 812 ? -screenHeight * 0.06 : -screenHeight * 0.055,
    left: screenHeight < 813 ? -9 : -10.5,
  },
});

export default DeliveryScreen;

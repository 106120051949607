import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AutoQueryResult, AutoSuggestResult, Filter, FilterItem, QuerySuggestion, TourKey } from '@fieldera-raleys/client-common';
import { AccountStackRoutes, AppStackRoutes, CheckoutStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { StackActions, useFocusEffect, useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { brsmService } from '@services';
import { useDeviceInfoStore, useSearchStore } from '@store';
import { appStyles } from '@styles';
import { scale, screenHeight, screenWidth } from '@styles/constants';
import { hapticNotify } from '@utils/helpers';
import logger from '@utils/logger';
import { BarCodeScanner, PermissionStatus } from 'expo-barcode-scanner';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation, Linking, Platform, StyleProp, StyleSheet, TextInput, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useTourGuideController } from 'rn-tourguide';
import { useCustomModalContext } from '../contexts';
import BarcodeScanner from './BarcodeScanner';
import Icon from './Icon';
import AutoSuggest from './search/AutoSuggest';
import SearchAssist from './search/SearchAssist';

type SearchBarProps = {
  containerStyles?: StyleProp<ViewStyle>;
  inputStyles?: StyleProp<ViewStyle>;
  barStyles?: StyleProp<ViewStyle>;
  showAutoSuggest?: boolean;
  onAutoSuggestSelect?: (suggestion: QuerySuggestion) => void;
  searchText?: string;
  onSearchSubmit?: (searchText: string, category?: string) => void;
  hasBackButton?: boolean;
  setShowPointsHeader?: (showHeader: boolean) => void;
  isFromSearchHeader?: boolean;
  placeHolder?: string;
  showAddToList?: boolean;
  shoppingListId?: string;
  shoppingListName?: string;
  showClose?: boolean;
  showRecentOnly?: boolean;
  tabBarHeight?: number;
};

type SearchBarState = {
  showClearIcon: boolean;
  showSearchAssist: boolean;
  showBarcodeScanner: boolean;
  searchText: string;
  autoSuggestResults: AutoSuggestResult[];
};

const SearchBar: FC<SearchBarProps> = ({
  containerStyles,
  barStyles,
  inputStyles,
  searchText,
  onSearchSubmit,
  hasBackButton,
  setShowPointsHeader,
  isFromSearchHeader,
  placeHolder,
  showAddToList = false,
  shoppingListId,
  shoppingListName,
  showRecentOnly = false,
  tabBarHeight,
}): JSX.Element => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const route = useRoute();
  const textInputRef = useRef<TextInput>(null);
  const [state, setState] = useState<SearchBarState>({
    showClearIcon: false,
    showSearchAssist: false,
    searchText: '',
    showBarcodeScanner: false,
    autoSuggestResults: [],
  });
  const [prevSearch, setPrevSearch] = useState('');
  const { deviceInfo } = useDeviceInfoStore();
  const { showModal: showPermissionsModal } = useCustomModalContext();
  const { t } = useTranslation(['searchBarComponent', 'tourGuide']);
  const { resetFilters, setAvailableFilters } = useSearchStore();
  const { TourGuideZone } = useTourGuideController(TourKey.HomeScreen);

  useFocusEffect(
    useCallback(() => {
      if (
        (!!searchText && route.name === ShopStackRoutes.Categories) ||
        route.name === ShopStackRoutes.Category ||
        route.name === ShopStackRoutes.Categories ||
        route.name === ShopStackRoutes.ProductList ||
        route.name === ShopStackRoutes.ShopLanding ||
        route.name === RootTabRoutes.Home
      ) {
        setState((prev) => ({ ...prev, searchText: '' }));
      }
    }, [route.name, searchText]),
  );

  useEffect(() => {
    setState((prev) => ({ ...prev, showClearIcon: false, showSearchAssist: false, showBarcodeScanner: false }));
    if ((route.name === CheckoutStackRoutes.BackupScreen || route.name === ShopStackRoutes.SearchLanding) && searchText?.length) {
      setShowPointsHeader && setShowPointsHeader(true);
      if (searchText === '*') {
        setState((prev) => ({ ...prev, searchText: '', showClearIcon: false }));
      } else {
        setState((prev) => ({ ...prev, searchText: searchText, showClearIcon: false }));
      }
    }
    if (route.name === AccountStackRoutes.ViewList && searchText?.length && searchText !== prevSearch) {
      setPrevSearch(searchText);
      setState((prev) => ({ ...prev, searchText: searchText, showClearIcon: true }));
      triggerProductSearch(searchText);
    }
    setTimeout(() => {
      textInputRef && textInputRef.current && textInputRef.current?.blur();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, searchText]);

  const toggleAssistContainer = () => {
    LayoutAnimation.easeInEaseOut();
    setShowPointsHeader && setShowPointsHeader(state.showSearchAssist);
    state.showSearchAssist && textInputRef?.current?.blur();
  };

  const clearSearchText = () => {
    setState((prev) => ({ ...prev, searchText: '', showClearIcon: false, autoSuggestResults: [] }));
  };

  const triggerProductSearch = (searchTriggTxt?: string) => {
    let finalSearchText;
    if (searchTriggTxt) {
      finalSearchText = searchTriggTxt;
    } else {
      finalSearchText = state.searchText;
    }
    if (route.name !== AccountStackRoutes.ViewList) {
      textInputRef?.current?.blur();
    }

    setState((prev) => ({ ...prev, showSearchAssist: false, showClearIcon: false, autoSuggestResults: [] }));
    resetFilters();
    if (finalSearchText && finalSearchText.trim().length) {
      if (onSearchSubmit && (route.name === ShopStackRoutes.SearchLanding || route.name === CheckoutStackRoutes.BackupScreen)) {
        onSearchSubmit(finalSearchText);
        setShowPointsHeader && setShowPointsHeader(true);
      } else {
        navigation.dispatch(
          StackActions.push(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Shop,
            params: {
              screen: ShopStackRoutes.SearchLanding,
              params: { query: finalSearchText, prevRoute: route.name, showAddToList: showAddToList, shoppingListId, shoppingListName },
            },
          }),
        );
      }
    } else {
      toggleAssistContainer();
    }
  };

  const triggerProductSearchFromAssist = (txt: string, category?: string) => {
    textInputRef?.current?.blur();
    // toggleAssistContainer();
    setState((prev) => ({ ...prev, showSearchAssist: false, searchText: txt, autoSuggestResults: [] }));
    resetFilters();
    category &&
      setAvailableFilters([
        {
          id: 'category',
          title: 'Category',
          children: state.autoSuggestResults
            .filter((x, idx, self) => x.attributeType === 'category' && !!x.value && self.findIndex((y) => y.value === x.value) === idx)
            .map(
              (y) =>
                ({
                  id: y.value,
                  default: false,
                  selected: y.value === category,
                  title: `${y.name}`,
                  value: y.value,
                  controlType: 'switch',
                }) as FilterItem,
            ),
        } as Filter,
      ]);
    if (route.name === CheckoutStackRoutes.BackupScreen || route.name === ShopStackRoutes.SearchLanding) {
      onSearchSubmit && onSearchSubmit(txt, category);
    } else {
      navigation.dispatch(
        StackActions.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: {
            screen: ShopStackRoutes.SearchLanding,
            params: { query: txt, prevRoute: route.name, showAddToList: showAddToList, shoppingListId, shoppingListName, category: category },
          },
        }),
      );
    }
  };

  const parseAutoSuggest = (autoSuggest: AutoQueryResult) => {
    const result: AutoSuggestResult[] = [];
    const { querySuggestions, attributeSuggestions } = autoSuggest;

    for (let queryIndex = 0; queryIndex < querySuggestions.length; queryIndex++) {
      const allDepts = {
        name: 'All Departments',
        value: '',
        attributeType: 'category',
      };
      result.push({ ...querySuggestions[queryIndex], ...allDepts });
    }

    if (querySuggestions.length > 0) {
      for (let attributeIndex = 0; attributeIndex < attributeSuggestions.length; attributeIndex++) {
        result.push({ ...querySuggestions[0], ...attributeSuggestions[attributeIndex] });
      }
    }

    setState((prev) => ({ ...prev, autoSuggestResults: result }));
  };

  const onSearchInputChange = async (search: string) => {
    const searchTxt = search.replace(/^ +/gm, '');
    setState((prev) => ({ ...prev, searchText: searchTxt, showClearIcon: searchTxt.length > 0, autoSuggestResults: [] }));
    if (searchTxt && searchTxt.length > 2) {
      const suggestions = await brsmService.getAutoSuggest(searchTxt);
      if (suggestions && suggestions?.querySuggestions?.length > 0) {
        parseAutoSuggest(suggestions);
      }
    }
  };

  const getSearchBarBarWidth = () => {
    var width = screenWidth - 16; // padding
    width -= hasBackButton ? 40 : 0; // back button
    width -= isFromSearchHeader && !state.showSearchAssist ? 56 : 0; // cart || close
    width -= state.showSearchAssist ? 64 : 0; // cancel button
    return width;
  };

  const handleBarcodeScanClick = async () => {
    try {
      if (deviceInfo && !deviceInfo.hasCameraPermission && (await BarCodeScanner.getPermissionsAsync())) {
        const status = await BarCodeScanner.requestPermissionsAsync();
        deviceInfo.hasCameraPermission = status.status === PermissionStatus.GRANTED;
      }
    } catch (ex) {
      logger.error(ex);
    }
    if (deviceInfo.hasCameraPermission === false) {
      showPermissionsModal({
        style: { minHeight: screenHeight / 3.5 },
        buttonContainerStyle: { bottom: 15 },
        headerStyle: { justifyContent: 'center' },
        contentStyle: { width: screenWidth, paddingHorizontal: 15, paddingVertical: scale(25) },
        title: t('cameraPermissionDeniedTitle'),
        location: 'top',
        okButtonText: t('okText'),
        cancelButtonOnPress: () => {
          navigateToSettings();
        },
        cancelButtonText: t('settingsText'),
        children: (
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} testID="cameraPermissionDeniedMessage">
            {t('cameraPermissionDeniedMessage')}
          </Text>
        ),
      });
    } else {
      LayoutAnimation.easeInEaseOut();
      setShowPointsHeader && setShowPointsHeader(false);
      setState((prev) => ({ ...prev, showBarcodeScanner: !prev.showBarcodeScanner }));
    }
  };

  const handleDataAfterBarcodeScan = (data: string, categoryType?: string) => {
    setShowPointsHeader && setShowPointsHeader(true);
    setState((prev) => ({ ...prev, searchText: data, showBarcodeScanner: false }));
    hapticNotify('notificationSuccess');
    if (categoryType) {
      triggerProductSearchFromAssist(data, categoryType);
    } else {
      triggerProductSearch(data);
    }
  };

  const handleCloseScanner = (hideScanner: boolean) => {
    LayoutAnimation.easeInEaseOut();
    setShowPointsHeader && setShowPointsHeader(true);
    setState((prev) => ({ ...prev, showBarcodeScanner: hideScanner }));
  };

  const navigateToSettings = () => {
    Platform.select({
      ios: Linking.openURL('app-settings:'),
      android: Linking.openSettings(),
    });
  };

  return (
    <View>
      <View style={[containerStyles, { flexDirection: state.showSearchAssist ? 'row' : 'column' }]}>
        <View
          style={[
            styles.searchBar,
            barStyles,
            {
              width: getSearchBarBarWidth(),
            },
          ]}>
          <TouchableOpacity
            onPress={() => {
              toggleAssistContainer();
              setState((prev) => ({ ...prev, showSearchAssist: !prev.showSearchAssist, showClearIcon: !!(searchText?.length ?? 0) }));
            }}
            style={{ padding: 5, marginLeft: 5 }}
            testID="searchiconClick">
            <Icon name="search-icon" size={20} style={appStyles.icon} />
          </TouchableOpacity>
          <TextInput
            testID="searchInput"
            ref={textInputRef}
            style={[styles.searchTextInput, appStyles.formFieldText, { width: state.showClearIcon ? '80%' : '70%', flex: 1, color: colors.text }, inputStyles]}
            placeholderTextColor={colors.lineSeparator}
            placeholder={placeHolder ? placeHolder : 'Search'}
            onChangeText={onSearchInputChange}
            onSubmitEditing={() => triggerProductSearch()}
            value={state.searchText}
            onFocus={() => {
              toggleAssistContainer();
              setState((prev) => ({ ...prev, showSearchAssist: !prev.showSearchAssist, showClearIcon: !!(searchText?.length ?? 0) }));
            }}
            onBlur={() => {
              setState((prev) => ({ ...prev, showClearIcon: !!(searchText?.length ?? 0) }));
            }}
          />
          <View style={{ flexDirection: 'row', marginRight: 5 }}>
            {state.showClearIcon && (
              <TouchableOpacity onPress={clearSearchText} style={styles.clearIcon} testID="clearIconClick">
                <Icon fill="#999" stroke="none" name="clear-icon" size={20} style={appStyles.icon} testID="clear-icon" />
              </TouchableOpacity>
            )}
            {!state.showSearchAssist && (
              <>
                <TourGuideZone text={t('tgSearchBarBarcodeTxt', { ns: 'tourGuide' })} zone={4} shape={'circle'} isTourGuide={route.name === RootTabRoutes.Home}>
                  <TouchableOpacity onPress={handleBarcodeScanClick} style={{ padding: 5 }} testID="scan-icon-click">
                    <Icon name="scan-icon" size={20} style={appStyles.icon} testID="scan-icon" />
                  </TouchableOpacity>
                </TourGuideZone>
                {/* <TouchableOpacity onPress={handleIconClick} style={{ padding: 5 }}> */}
                {/* <Icon name="mic-icon" size={20} style={appStyles.icon} /> */}
                {/* </TouchableOpacity> */}
              </>
            )}
          </View>
        </View>
        {state.showSearchAssist && (
          <TouchableOpacity
            style={[styles.cancelBtnView]}
            onPress={() => {
              toggleAssistContainer();
              setState((prev) => ({
                ...prev,
                showSearchAssist: false,
                searchText: '',
                showClearIcon: false,
                showBarcodeScanner: false,
                autoSuggestResults: [],
              }));
            }}
            testID="arrow-btn-dwn-click">
            <Text numberOfLines={1} style={[appStyles.bodySmallLeft, { textAlign: 'center', color: colors.secondary }]} testID="cancelText">
              Cancel
            </Text>
          </TouchableOpacity>
        )}
      </View>
      {state.showBarcodeScanner && (
        <View
          style={[
            styles.barcodeScanContainer,
            {
              marginLeft: hasBackButton ? -48 : -8,
            },
          ]}>
          <BarcodeScanner
            closeScanner={handleCloseScanner}
            setSearchText={(value) => setState((prev) => ({ ...prev, searchText: value }))}
            handleDataAfterBarcodeScan={handleDataAfterBarcodeScan}
          />
        </View>
      )}
      {state.showSearchAssist && (
        <View
          style={[
            styles.searchAssistContainer,
            {
              marginLeft: hasBackButton ? -48 : -8,
            },
          ]}>
          {state.autoSuggestResults.length > 0 && (
            <AutoSuggest tabBarHeight={tabBarHeight} data={state.autoSuggestResults} searchText={state.searchText} onSelect={triggerProductSearchFromAssist} />
          )}
          {<SearchAssist showRecentOnly={showRecentOnly} onSelect={triggerProductSearchFromAssist} />}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  searchBar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'row',
    borderRadius: 12.5,
    borderWidth: 1,
    borderColor: colors.lineSeparator,
    borderStyle: 'solid',
    height: 48,
  },
  searchTextInput: {
    padding: 5,
    height: 32,
    width: '70%',
    fontSize: scale(15),
    fontFamily: FontFamily.LarsseitLight,
  },
  searchAssistContainer: {
    position: 'absolute',
    width: screenWidth,
    height: screenHeight * 0.82,
    paddingHorizontal: 18,
    backgroundColor: colors.cream,
    marginTop: 55,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    zIndex: 150,
    elevation: 5,
  },
  cancelBtnView: {
    height: 48,
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  headerStyle: {
    justifyContent: 'center',
  },
  barcodeScanContainer: {
    position: 'absolute',
    width: screenWidth,
    height: screenHeight,
    paddingHorizontal: 18,
    backgroundColor: colors.cream,
    // marginTop: 55,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    zIndex: 150,
    elevation: 5,
  },
  boldLinkLargeCenter: {
    ...appStyles.boldLinkLargeCenter,
    marginTop: 25,
  },
  clearIcon: {
    paddingRight: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
export default SearchBar;

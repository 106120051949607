import { TourKey } from '@fieldera-raleys/client-common';
import { useFocusEffect } from '@react-navigation/native';
import { containerWidth, screenWidth } from '@styles/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, ListRenderItem, NativeScrollEvent, NativeSyntheticEvent, View, ViewStyle } from 'react-native';
import { useTourGuideController } from 'rn-tourguide';

type CarouselProps<T> = {
  entities: Array<T>;
  extraData?: object;
  contentContainerStyle?: ViewStyle;
  renderItem: ListRenderItem<T>;
  carouselstyle?: ViewStyle;
  showPager?: boolean;
  pagerStyle?: ViewStyle;
  pagerActiveItemStyle?: ViewStyle;
  pagerInactiveItemStyle?: ViewStyle;
  autoScroll?: boolean;
  scrollTimeout?: number;
  snapToInterval?: number;
  windwoSize?: number;
  removeClippedSubviews?: boolean;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement<any, string | React.JSXElementConstructor<any>> | null | undefined;
  tourKey?: TourKey;
};

interface PaginationProps {
  data: number;
  index: number;
  pagerStyle?: ViewStyle;
  activeItemStyle?: ViewStyle;
  inactiveItemStyle?: ViewStyle;
}

export const Pagination = ({ data, index, pagerStyle, activeItemStyle, inactiveItemStyle }: PaginationProps) => {
  let d = [];
  for (let x = 0; x < data; x++) {
    d.push(0);
  }
  return (
    <View key={index}>
      {d.length > 1 ? (
        <View style={pagerStyle} pointerEvents="none" key={index}>
          {d.map((_: any, i: React.Key | null | undefined) => {
            return <View key={i} style={index === i ? activeItemStyle : inactiveItemStyle} />;
          })}
        </View>
      ) : (
        <View style={pagerStyle} pointerEvents="none" key={index}>
          {d.map((_: any, i: React.Key | null | undefined) => {
            return <View key={i} style={{ margin: 15 }} />;
          })}
        </View>
      )}
    </View>
  );
};

const Carousel = <T,>({
  entities,
  renderItem,
  contentContainerStyle = { paddingVertical: 16 },
  extraData,
  carouselstyle,
  pagerStyle,
  pagerActiveItemStyle,
  pagerInactiveItemStyle,
  showPager = true,
  autoScroll = false,
  scrollTimeout = 5,
  snapToInterval = containerWidth,
  windwoSize = 5,
  removeClippedSubviews = true,
  ListEmptyComponent,
  tourKey = TourKey.SEHomeScreen,
}: CarouselProps<T>) => {
  const [index, setIndex] = useState(0);
  const indexRef = useRef(index);
  indexRef.current = index;
  const flatListRef = useRef<FlatList>(null);
  const viewabilityConfig = {
    viewAreaCoveragePercentThreshold: 50,
  };
  const [contentSize, setContentSize] = useState<{ width: number; pages: number }>({ width: screenWidth, pages: 1 });

  const { stop } = useTourGuideController(tourKey);

  const onScrollingEnd = () => {
    stop();
  };

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      // const slideSize = resizeSlideTo ? resizeSlideTo : event.nativeEvent.layoutMeasurement.width;
      // console.log(slideSize + " " + event.nativeEvent.contentOffset.x)
      // const scrollIndex = event.nativeEvent.contentOffset.x / slideSize;
      // const roundIndex = Math.round(scrollIndex);

      const slideSize = contentSize.width;
      const scrollIndex = event.nativeEvent.contentOffset.x / slideSize;
      let roundIndex = Math.ceil(scrollIndex);
      // logger.log('idx:', roundIndex);

      const distance = Math.abs(roundIndex - scrollIndex);
      // Prevent one pixel triggering setIndex in the middle
      // of the transition. With this we have to scroll a bit
      // more to trigger the index change.
      let isNoMansLand = distance > 0.4;

      //console.log(event.nativeEvent.contentSize.width - event.nativeEvent.contentOffset.x - slideSize < slideSize / 2);
      if (roundIndex !== indexRef.current && (!isNoMansLand || contentSize.pages === roundIndex + 1)) {
        setIndex(roundIndex);
      }
    },
    [contentSize],
  );

  if (autoScroll) {
    // if data has been loaded, call setInterval
    setInterval(() => {
      // scroll flatList to next index after every 3 sec.
      flatListRef.current?.scrollToIndex({ animated: true, index: indexRef.current > contentSize.pages - 1 ? 0 : indexRef.current + 1 });
    }, scrollTimeout * 1000);
  }

  useEffect(() => {
    indexRef.current = index;

    if (contentSize.width === 0) {
      contentSize.width = containerWidth;
    }

    let pages = Math.ceil(((entities.length || 0) * snapToInterval) / contentSize.width);
    setContentSize({ ...contentSize, pages: pages });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerWidth, index, entities.length]);

  useFocusEffect(
    useCallback(() => {
      flatListRef?.current?.scrollToOffset({ offset: 0, animated: false });
    }, []),
  );

  return (
    <View style={carouselstyle}>
      <>
        <FlatList
          ref={flatListRef}
          data={entities}
          extraData={extraData}
          snapToInterval={snapToInterval}
          renderItem={renderItem}
          pagingEnabled={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          bounces={false}
          onScroll={onScroll}
          onScrollEndDrag={onScrollingEnd}
          viewabilityConfig={viewabilityConfig}
          initialNumToRender={5}
          maxToRenderPerBatch={5}
          removeClippedSubviews={removeClippedSubviews}
          scrollEventThrottle={16}
          automaticallyAdjustContentInsets={false}
          contentContainerStyle={contentContainerStyle}
          windowSize={windwoSize}
          keyExtractor={useCallback((_: T, idx: number) => String(idx), [])}
          decelerationRate={'fast'}
          snapToAlignment={'start'}
          getItemLayout={useCallback(
            (_: T[] | null | undefined, idx: number) => ({
              index: idx,
              length: 375,
              offset: idx * 375,
            }),
            [],
          )}
          ListEmptyComponent={ListEmptyComponent}
        />
        {showPager ? (
          <Pagination
            data={contentSize.pages}
            index={index}
            activeItemStyle={pagerActiveItemStyle}
            inactiveItemStyle={pagerInactiveItemStyle}
            pagerStyle={pagerStyle}
          />
        ) : null}
      </>
    </View>
  );
};

export default Carousel;

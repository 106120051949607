import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import { decimalFormat, hapticNotify } from '@utils/helpers';
import _ from 'lodash';
import React, { FC, createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, ScrollView, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Config from 'react-native-config';
import { useCustomModalContext } from '../contexts';
import Icon from './Icon';

interface QuantityBoxProps {
  maxLimit?: Promise<number>;
  onPress?: (event: any) => void;
  initialValue?: number;
  onChange: (value: number) => void;
  showCartText?: boolean;
  showToggle?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  countStyle?: StyleProp<TextStyle>;
  cartStyle?: StyleProp<TextStyle>;
  borderStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  incrementBy?: number;
  minLimit?: number;
  qtyFormat?: (index: number) => string | undefined;
  sellType?: { key: string; label: string };
  isLoading?: boolean;
}
const ITEM_HEIGHT = 42;

type QuantityButton = 'increase' | 'decrease';

const QuantityBox: FC<QuantityBoxProps> = ({
  onChange,
  initialValue = 0,
  maxLimit,
  showToggle = true,
  showCartText = false,
  containerStyle,
  countStyle,
  cartStyle,
  borderStyle,
  disabled,
  incrementBy = 1,
  minLimit = 1,
  qtyFormat,
  isLoading = false,
}) => {
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [quantityArray, setQuantityArray] = useState<number[]>([]);
  const { showModal, hideModal } = useCustomModalContext();
  const contentRef = createRef<ScrollView>();

  useEffect(() => {
    if (maxLimit) {
      maxLimit?.then((L) => {
        if (L === undefined) {
          L = 1;
        }
        const count = (L - minLimit) / incrementBy;
        setQuantityArray(Array.from({ length: count + 2 }, (_, i) => i));
      });
    } else {
      const count = Math.floor((+(Config.MAX_LINE_ITEM_QUANTITY ?? 20) - minLimit) / incrementBy);
      setQuantityArray(Array.from({ length: count + 2 }, (_, i) => i));
    }
  }, [maxLimit, incrementBy, minLimit, qtyFormat]);

  const debounceDelay: number = 500;
  const _onChange = useCallback((nextValue: number) => onChange(nextValue), [onChange]);
  const _onChangeMem = useMemo(() => _.debounce(_onChange, debounceDelay), [_onChange]);

  const handleButtonClick = async (button: QuantityButton) => {
    hapticNotify('notificationSuccess');
    if (disabled) {
      return;
    }
    var currentValue = selectedValue;
    var triggerEvent = false;
    if (button === 'decrease') {
      if (currentValue > 0) {
        currentValue -= 1;
        triggerEvent = true;
      }
    } else {
      if (currentValue < quantityArray.length - 1) {
        if (quantityArray?.length - 1 === 0) {
          return;
        }
        currentValue += 1;
        triggerEvent = true;
      }
    }
    if (triggerEvent) {
      //logger.log('INCREASE QUANTITY:', 'CV', currentValue, 'incBy', incrementBy);
      setSelectedValue(currentValue);
      _onChangeMem(currentValue);
    }
  };

  // refs
  useEffect(() => {
    if (!isLoading) {
      setSelectedValue(initialValue);
    }
    const debounceFn = _onChangeMem;
    return () => debounceFn.flush();
  }, [isLoading, initialValue, _onChangeMem]);

  const handleSelect = useCallback(
    (item: any) => {
      hapticNotify('notificationSuccess');
      if (item < incrementBy) {
        setSelectedValue(0);
        onChange(0);
      } else {
        setSelectedValue(item);
        onChange(item);
      }
      hideModal();
    },
    [hideModal, incrementBy, onChange],
  );
  const handleOnLayout = () => {
    contentRef.current?.scrollTo({ y: ITEM_HEIGHT * (selectedValue - 1), animated: true });
  };

  const format = (item: number) => (qtyFormat ? qtyFormat(item) ?? '' : item);

  const showPickerModal = () => {
    showModal({
      style: styles.pickerHeight,
      contentRef: contentRef,
      header: (
        <View style={styles.handle}>
          <Text style={styles.headerTitle}>Select Quantity</Text>
          <TouchableOpacity onPress={hideModal} testID="closeBtn">
            <Icon name={'x-close'} size={20} testID="closeIcon" />
          </TouchableOpacity>
        </View>
      ),
      children: (
        <View style={{ marginTop: 9 }} onLayout={handleOnLayout}>
          {quantityArray?.map((item) => {
            return (
              <View key={`itemInfo_${item}`} style={styles.quantityItemContainer}>
                <TouchableOpacity onPress={() => handleSelect(item)}>
                  <Text style={[styles.option, selectedValue === item && styles.selectedOption]} testID="item">
                    {item <= 0 ? (
                      <Text testID="removeText" style={styles.removeText}>
                        Remove
                      </Text>
                    ) : (
                      format(item)
                    )}
                  </Text>
                </TouchableOpacity>
              </View>
            );
          })}
        </View>
      ),
      buttonContainerStyle: { display: 'none' },
      contentStyle: { width: screenWidth, marginTop: -16 },
    });
  };

  return (
    <View style={[showToggle ? styles.quantityBox : {}, containerStyle]}>
      {showToggle && (
        <TouchableOpacity activeOpacity={0.7} onPress={() => handleButtonClick('decrease')} style={styles.decQuantityButton} testID="decreaseClick">
          <Text testID="decreaseText" style={[styles.quantityToggleButton, { textAlign: 'right' }]}>
            -
          </Text>
        </TouchableOpacity>
      )}
      <View style={[styles.quantityTextWrapper, borderStyle]}>
        <TouchableOpacity onPress={showPickerModal} style={{ alignItems: 'center' }}>
          <Text adjustsFontSizeToFit={true} numberOfLines={1} style={[styles.quantityText, countStyle]} testID="selectedValueText">
            {qtyFormat ? qtyFormat(decimalFormat(selectedValue)) ?? '' : '' + decimalFormat(selectedValue)}
          </Text>
          {showCartText && (
            <Text style={[styles.quantityCartText, cartStyle]} testID="inCartText">
              In Cart
            </Text>
          )}
        </TouchableOpacity>
      </View>

      {showToggle && (
        <TouchableOpacity activeOpacity={0.7} onPress={() => handleButtonClick('increase')} style={styles.incQuantityButton} testID="increaseClick">
          <Text style={[styles.quantityToggleButton, { opacity: selectedValue === quantityArray.length - 1 ? 0.2 : 1.0 }]} testID="increase">
            +
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};
export default QuantityBox;

const styles = StyleSheet.create({
  quantityBox: {
    flexDirection: 'row',
    width: '100%',
    height: 50,
    justifyContent: 'center',
    backgroundColor: colors.white,
    padding: 0,
    marginBottom: 5,
    marginLeft: 7,
    borderRadius: 100,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
  },
  decQuantityButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  incQuantityButton: {
    justifyContent: 'center',
    alignItems: 'center',
    transform: [{ translateY: 2 }],
    width: '30%',
  },
  quantityToggleButton: {
    marginTop: Platform.select({ android: -7 }),
    fontSize: scale(28),
    fontFamily: 'Larsseit',
    color: colors.dark,
    width: '50%',
  },
  quantityTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '40%',
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,
    borderColor: '#c69480',
    height: 38,
    top: -5,
  },
  quantityText: {
    fontSize: scale(14),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
  },
  quantityCartText: {
    fontSize: scale(12),
    fontFamily: FontFamily.LarsseitBold,
    color: colors.dark,
  },
  pickerHeight: {
    height: screenHeight > 740 ? screenHeight * 0.47 : screenHeight * 0.5,
  },
  handle: {
    width: '100%',
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
  option: {
    fontSize: scale(20),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.dark,
    width: '100%',
    lineHeight: lineHeight(24),
    textAlign: 'center',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedOption: {
    fontFamily: FontFamily.LarsseitBold,
    backgroundColor: colors.cream,
  },
  quantityItemContainer: {
    width: '100%',
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
  headerTitle: {
    fontSize: scale(20),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.dark,
    flexGrow: 1,
    lineHeight: lineHeight(28),
    textAlign: 'center',
  },
  removeText: {
    fontFamily: FontFamily.LarsseitBold,
    lineHeight: lineHeight(28),
  },
});

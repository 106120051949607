import appConstants from '@config/appConstants';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { BRSMService, RecomendationResult, ShelfGuideData, Widget } from '@fieldera-raleys/client-common/services';
import {
  Category,
  CategoryWidgetParams,
  ItemWidgetParams,
  KeywordWidgetParams,
  PagedArray,
  PersonalizedWidgetParams,
  WidgetParams,
  WidgetType,
} from '@fieldera-raleys/client-common/types';
import useAppConfigStore from '@store/appConfigStore';
import { defaultFilters } from '@store/searchStore';
import useShopStore from '@store/shopStore';
import Cache from '@utils/cache';
import { queryClient } from '@utils/reactQuery';
import { CancelToken } from 'apisauce';
import dayjs from 'dayjs';
import Config from 'react-native-config';
import { useInfiniteQuery, useQuery } from 'react-query';
import logger from '../../utils/logger';
import { getJwtToken, getUniqueId } from '../authHelper';
import { getSelectedStoreKey, getUserSepiNumber } from '../helper';

const getTrackingCookie = async () => {
  const uid = await getUniqueId();
  return `uid=${uid}:v=app:ts=${dayjs().unix()}:hc=1`;
};

const brsmService = new BRSMService({
  accountId: Config.BRSM_ACCOUNT_ID,
  baseURL: `${Config.BRSM_BASE_URL}?source=app`,
  brsmRecomendationsUrl: Config.BRSM_RECOMENDATIONS_URL,
  brsmSearchUrl: Config.BRSM_SEARCH_URL,
  brsmSuggestUrl: Config.BRSM_SUGGEST_URL,
  cacheProvider: Cache,
  commerceToolsApiUrl: Config.CT_API_URL,
  defaultFilters: defaultFilters,
  defaultStoreId: Config.BRSM_DEFAULT_STORE,
  authToken: getJwtToken,
  selectedStoreKey: getSelectedStoreKey,
  rootCategoryKey: 'PMC1',
  uniqueId: getTrackingCookie,
  userId: getUserSepiNumber,
});

const useShelfGuidesQuery = (filter?: (data: ShelfGuideData[] | undefined) => ShelfGuideData[] | undefined, enabled: boolean = true) => {
  const storeId = useShopStore.getState().selectedStore?.number ?? Config.BRSM_DEFAULT_STORE;
  const sortOrder = useAppConfigStore.getState().shelfGuideSortOrder;
  return useQuery(['ShelfGuides', storeId], async () => await brsmService.getSelfGuides(storeId, sortOrder), { enabled: enabled, select: filter });
};

const sortCategoryTree = (nodes: Category[]) => {
  nodes.sort((a, b) => a.orderHint - b.orderHint);
  nodes.forEach(function (node) {
    if (node.children.length) {
      sortCategoryTree(node.children);
    }
  });
};

const sortShelfGuideArrayData = (nodes: any[] | undefined) => {
  try {
    const sortOrder = useAppConfigStore.getState().shelfGuideSortOrder;
    if (nodes && nodes.length) {
      nodes.sort((a, b) => sortOrder[a] - sortOrder[b]);
    }
  } catch (ex) {
    logger.error(ex);
  }
};

const useCategoriesQuery = (filter?: (data: Category[] | undefined) => Category[] | undefined, enabled: boolean = true) => {
  const storeId = useShopStore.getState().selectedStore?.number ?? Config.BRSM_DEFAULT_STORE;
  return useQuery(['categories', storeId], async () => await brsmService.getAllCategories(storeId), {
    enabled: enabled,
    select: (res) => {
      sortCategoryTree(res ?? []);
      if (filter) {
        return filter(res);
      }
      return res;
    },
    onError: (err) => {
      logger.log(err);
      queryClient.invalidateQueries('categories');
    },
  });
};

const useGetWidgetData = (
  widgetType: keyof typeof WidgetType,
  widgetId: string,
  widgetParams?: WidgetParams | ItemWidgetParams | KeywordWidgetParams | CategoryWidgetParams | PersonalizedWidgetParams,
  enabled: boolean = true,
  pageSize?: number,
) => {
  return useInfiniteQuery(
    ['products-by-widget-id', widgetType, widgetId, widgetParams],
    async ({ pageParam, signal }) => {
      const cts = CancelToken.source();
      signal?.addEventListener('abort', () => {
        cts.cancel('Query was cancelled by React Query');
      });
      return brsmService
        .getWidgetData(widgetType, widgetId, widgetParams, pageParam, pageSize ? pageSize : appConstants.PAGE_SIZE)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          logger.warn(error);
          return {
            products: { data: [], offset: 0, limit: 0, total: 0 } as PagedArray<Product>,
            widgetData: {} as Widget,
          } as RecomendationResult;
        });
    },
    {
      enabled: enabled,
      getNextPageParam: (lastPage) => {
        if (!pageSize && lastPage.products.total > lastPage.products.offset + appConstants.PAGE_SIZE) {
          return lastPage.products.offset + appConstants.PAGE_SIZE;
        }
      },
    },
  );
};

export default {
  useCategoriesQuery,
  useShelfGuidesQuery,
  useGetWidgetData,
  sortShelfGuideArrayData,
  ...brsmService,
};

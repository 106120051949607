import UpgradeAppModal from '@components/UpgradeModal';
import { getCustomFieldValue } from '@fieldera-raleys/client-common/utils';
import { useEffectOnce } from '@hooks';
import { LinkingOptions, NavigationContainer, Route, getActionFromState, getStateFromPath, useLinkBuilder } from '@react-navigation/native';
import { useAnalyticsStore, useAppConfigStore, useAuthStore, useCartStore, useUserSettingsStore } from '@store';
import useDeeplinkStore from '@store/deeplinkStore';
import Airship, { EventType, Subscription } from '@ua/react-native-airship';
import { appInsightsService } from '@utils/appInsightsService';
import logger from '@utils/logger';
import dayjs from 'dayjs';
import { parse } from 'expo-linking';
import { useEffect, useRef, useState } from 'react';
import { Linking, Platform } from 'react-native';
import Config from 'react-native-config';
import { CustomModalProvider } from '../contexts';
import AppStackNavigation from './AppStackNavigation';
import AuthStackNavigation from './AuthStackNavigation';
import { NavigationProps } from './declarations';
import { navigationRef } from './routes';

const cleanPathStr = (path: string) => {
  const queryVairablesIndex = path.indexOf('?');
  if (queryVairablesIndex === -1) {
    return path;
  }
  return path.substring(0, queryVairablesIndex);
};

const buildDeepLinkAction = (url: string) => {
  const extractedUrl = url.replace(/^(raleys:\/\/|app:\/\/)/gi, '/');
  const navigation = navigationRef.current;
  const state = navigation ? getStateFromPath(extractedUrl, linking.config) : undefined;

  if (navigation && state) {
    // Make sure that the routes in the state exist in the root navigator
    // Otherwise there's an error in the linking configuration
    try {
      const rootState = navigation.getRootState();
      logger.log('rootState', rootState, state);
      if (!rootState) {
        logger.warn('unable to get rootstate navigationRef');
        return;
      }
      const action = getActionFromState(state, linking.config);
      const linkPath = cleanPathStr(extractedUrl);
      return {
        action,
        linkPath,
      };
    } catch (ex) {
      logger.error(ex, { method: 'buildDeepLinkAction' });
      return undefined;
    }
  }
};

const handleDeepLink = (listener: (url: string) => void, deepLink: string) => {
  const { isAuthenticated } = useAuthStore.getState();
  if (isAuthenticated()) {
    setTimeout(() => {
      listener(deepLink);
    }, 200);
    return;
  }
  const { linkPath, action } = buildDeepLinkAction(deepLink) ?? {};
  if (action && linkPath) {
    setTimeout(
      () =>
        useDeeplinkStore.getState().addDeepLink({
          id: linkPath,
          type: 'NAVIGATION',
          action: () => navigationRef.current?.dispatch(action),
        }),
      200,
    );
  }
};

const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: ['raleys://', 'app://'],
  async getInitialURL() {
    // As a fallback, you may want to do the default deep link handling
    const url = await Linking.getInitialURL();
    return url;
  }, // Custom function to subscribe to incoming links
  subscribe(listener) {
    // Listen to incoming links from Airship Links
    const unsubscribeAirship = Airship.addListener<EventType.DeepLink>(EventType.DeepLink, ({ deepLink }) => handleDeepLink(listener, deepLink));
    // Listen to incoming deep linking
    const linkingSubscription = Linking.addEventListener('url', ({ url }) => handleDeepLink(listener, url));

    return () => {
      // Clean up the event listeners
      unsubscribeAirship.remove();
      linkingSubscription.remove();
    };
  },
  config: {
    screens: {
      AppBenefits: 'app-benefits',
      SignIn: 'sign-in',
      ForgotPassword: 'forgot-password',
      ResetPassword: {
        path: 'reset-password/:email/:code',
        parse: {
          email: String,
          code: String,
        },
      },
      CreateAccountInitial: 'create-account',
      RootTabs: {
        screens: {
          Home: 'home',
          Shop: {
            // path: 'shop',
            screens: {
              ShopLanding: 'shop',
              SearchLanding: 'search',
              Categories: 'categories',
              Category: {
                path: 'category/:categoryId',
                parse: {
                  categoryId: String,
                },
              },
              ProductList: {
                path: 'products/:categoryId',
                parse: {
                  categoryId: String,
                },
              },
              ShelfGuide: 'shelf-guide-list',
              ShelfGuideCategoryDetails: {
                path: 'shelf-guide/:shelfGuide',
                parse: {
                  shelfGuide: String,
                },
              },
              ShelfGuideHelp: 'shelf-guide-help',
              WeeklyAds: 'weekly-ads',
              WeeklyAdsCollection: 'weekly-ads-collection',
              CollectionsLanding: 'collections',
              CollectionListing: {
                path: 'collection/:productCollectionId/:collectionName',
                parse: {
                  productCollectionId: String,
                  collectionName: String,
                },
              },
            },
          },
          Wallet: 'account/something-extra-dollars',
          Rewards: {
            path: 'something-extra',
            screens: {
              SEOffers: 'offers-and-savings',
            },
          },
          Account: {
            screens: {
              AccountLanding: 'account/landing',
              Wallet: 'account/payment-methods',
              AddEditPaymentMethod: {
                path: 'account/payment-method/:paymentProfileId?',
                parse: {
                  paymentProfileId: String,
                },
              },
              ManageShoppingList: 'account/my-list',
              CommunicationPreferences: 'account/communication-preferences',
              MessageCenter: 'account/inbox',
              AppSettings: 'account/app-settings',
              AddressBook: 'account/address-book',
              AddEditAddress: {
                path: 'account/address/:addressId?',
                parse: {
                  addressId: String,
                },
              },
              EditProfile: 'account/profile',
              ChangePassword: 'account/change-password',
              OrderHistory: {
                screens: {
                  RecentOrders: 'account/recent-orders',
                  OrderDetails: {
                    path: 'account/order/:orderId',
                    parse: {
                      orderId: String,
                    },
                  },
                },
              },
              StoreFinder: 'stores',
              HelpCenter: {
                path: 'help-center',
                screens: {
                  HelpCenterScreen: 'landing',
                  ContactCustomerScreen: 'customer-service',
                  HelpTopicsListScreen: {
                    path: '/:topic',
                    parse: {
                      topic: String,
                    },
                  },
                },
              },
            },
          },
        },
      },
      Customize: {
        path: 'customize',
        screens: {
          Initial: 'initial',
          Size: 'size',
          Selection: 'selection',
          Container: 'container',
          Notes: 'notes',
          Review: 'review',
          BasicSelection: 'basic-selection',
          Theme: 'theme',
          Filling: 'filling',
          Message: 'message',
          Summary: 'summary',
        },
      },
      ProductDetails: {
        path: 'product/:productKey',
        parse: {
          productKey: String,
        },
      },
      ShelfGuideDefinitions: {
        path: 'shelf-guide-definition/:shelfGuide',
        parse: {
          shelfGuide: String,
        },
      },
      ProductCarouselList: 'carousel-product-list',
      CartAndCheckout: {
        screens: {
          CartLanding: 'cart',
          OrderComplete: {
            path: 'order/:orderId',
            parse: {
              orderId: String,
            },
          },
          AddPaymentMethod: {
            path: 'checkout/payment/:paymentId',
            parse: {
              paymentId: String,
            },
          },
          AddAddress: {
            path: 'checkout/address/:addressId',
            parse: {
              addressId: String,
            },
          },
          Checkout: 'checkout',
          SavingsReminder: 'checkout/savings-reminder',
          OrderSummary: 'order-Summary',
        },
      },
    },
  },
};

const RootNavigation = ({ theme }: NavigationProps) => {
  const previousRouteRef = useRef<Route<string, object | undefined>>();
  const { authToken, isAuthenticated } = useAuthStore();
  const { setPreviousRoute } = useAnalyticsStore();
  const { upgradeMode } = useAppConfigStore();
  const [showAppUpgradeModal, setShowAppUpgradeModal] = useState(false);
  const [queryParams, setQueryParams] = useState<Partial<object | undefined>>();
  const { userSettings, updateSetting } = useUserSettingsStore();
  const buildLink = useLinkBuilder();
  const { cart, setCalculateSalesTax } = useCartStore();

  useEffectOnce(() => {
    let subscriptionList: Subscription[] = [];
    if (Platform.OS !== 'web') {
      Airship.messageCenter.setAutoLaunchDefaultMessageCenter(false);
      Config.AIRSHIP_PREFERENCE_CENTER_ID && Airship.preferenceCenter.setAutoLaunchDefaultPreferenceCenter(Config.AIRSHIP_PREFERENCE_CENTER_ID, true);
      Airship.messageCenter.getUnreadCount().then((messageCount) => {
        updateSetting('unreadMessageCount', messageCount);
        if (Platform.OS === 'ios') {
          Airship.push.iOS.setBadgeNumber(messageCount);
        }
      });

      subscriptionList.push(
        Airship.addListener(EventType.MessageCenterUpdated, (event) => {
          updateSetting('unreadMessageCount', event.messageUnreadCount);
          if (Platform.OS === 'ios') {
            Airship.push.iOS.setBadgeNumber(event.messageUnreadCount);
          }
        }),
      );
    }

    Linking.getInitialURL().then((url) => {
      if (url) {
        setQueryParams(parse(url).queryParams ?? undefined);
      }
    });

    return () => {
      if (subscriptionList.length) {
        subscriptionList.forEach((x) => x.remove());
        subscriptionList = [];
      }
    };
  });

  useEffect(() => {
    if (
      !showAppUpgradeModal &&
      (upgradeMode ?? '') === 'SuggestUpgrade' &&
      (userSettings?.lastCkecked === undefined || dayjs().diff(userSettings.lastCkecked, 'days') > 0)
    ) {
      setShowAppUpgradeModal(true);
    } else if (showAppUpgradeModal && upgradeMode !== 'SuggestUpgrade') {
      setShowAppUpgradeModal(false);
    }
  }, [showAppUpgradeModal, updateSetting, upgradeMode, userSettings?.lastCkecked]);

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={theme}
      linking={linking}
      onReady={() => {
        previousRouteRef.current = navigationRef.current?.getCurrentRoute();
      }}
      onStateChange={() => {
        const currentRoute = navigationRef.current?.getCurrentRoute();
        if (previousRouteRef.current?.name !== currentRoute?.name) {
          setPreviousRoute(previousRouteRef.current);
        }
        // Save the current route name for later comparison
        previousRouteRef.current = currentRoute;
        appInsightsService.trackPageView(currentRoute?.name, buildLink(currentRoute?.name ?? '/', currentRoute?.params));
        Airship.analytics.trackScreen(currentRoute?.name);
        // Check route to update calculateSalesTax custom property in cart
        if (authToken && cart) {
          const calculateSalesTax = getCustomFieldValue('calculateSalesTax', cart.custom?.customFieldsRaw ?? []);
          if (calculateSalesTax !== (currentRoute?.name === 'Checkout')) {
            setCalculateSalesTax(currentRoute?.name === 'Checkout');
          }
        }
      }}>
      <CustomModalProvider>
        <>
          {authToken && isAuthenticated() ? (
            <AppStackNavigation queryParams={queryParams} theme={theme} />
          ) : (
            <AuthStackNavigation queryParams={queryParams} theme={theme} />
          )}
        </>
      </CustomModalProvider>
      {showAppUpgradeModal && <UpgradeAppModal shoModal={showAppUpgradeModal} onDismiss={() => setShowAppUpgradeModal(false)} />}
    </NavigationContainer>
  );
};

export default RootNavigation;

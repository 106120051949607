import { CloseButton, ErrorFallbackComponent, NavigationHeader, Screen, Text } from '@components';
import CardPaymentForm from '@components/forms/CardPaymentForm';
import { AddPaymentProfile } from '@components/forms/authorizenet';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, CheckoutStackRoutes, CheckoutStackScreenProps } from '@navigation/routes';
import { utilityStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenWidth } from '@styles/constants';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

type AddPaymentMethodScreenProps = CheckoutStackScreenProps<CheckoutStackRoutes.AddPaymentMethod>;

const AddPaymentMethodScreen = ({ navigation, route }: AddPaymentMethodScreenProps) => {
  const { paymentMethodId, addressId, calledFrom } = route.params;
  const handleClose = () => {
    navigation.goBack();
  };
  const handleAddAddress = () => {
    navigation.navigate(AppStackRoutes.CartAndCheckout, {
      screen: CheckoutStackRoutes.AddAddress,
      params: { calledFrom: CheckoutStackRoutes.AddPaymentMethod },
    });
  };
  const handleEditAddress = (addrId: string | number) => {
    navigation.navigate(AppStackRoutes.CartAndCheckout, {
      screen: CheckoutStackRoutes.AddAddress,
      params: { addressId: addrId, calledFrom: CheckoutStackRoutes.AddPaymentMethod },
    });
  };

  const handleAfterSubmit = (paymentId?: number) => {
    if (calledFrom) {
      navigation.navigate({
        name: AppStackRoutes.CartAndCheckout,
        params: { screen: calledFrom, params: { paymentMethodId: paymentId } },
        merge: true,
      });
    } else {
      navigation.goBack();
    }
  };

  return (
    <Screen key={'payment_key_screen'}>
      <NavigationHeader
        testID={`${paymentMethodId ? 'Edit' : 'Add'} Payment`}
        title={`${paymentMethodId ? 'Edit' : 'Add'} Payment`}
        subTitle={'Method'}
        icon={<CloseButton size={20} onPress={handleClose} />}
        titleStyle={{ lineHeight: lineHeight(24) }}
        subTitleStyle={{ marginTop: scale(-7), lineHeight: lineHeight(24) }}
      />
      {!!paymentMethodId && (
        <View key={'header1_key'} style={[styles.header]}>
          <View style={[styles.subHeader]}>
            <TouchableOpacity style={[styles.filterPills, styles.filterPillsActive]}>
              <Text style={[styles.filterPillsLabel, styles.filterPillsLabelActive]} testID="creditDebitText">
                Credit/Debit
              </Text>
            </TouchableOpacity>
            {/* TODO: Uncomment at the time of Gift Card implementation */}
            {/* <TouchableOpacity style={[styles.filterPills, styles.filterPillsDisabled]} onPress={() => setShowFutureFeatureModal(true)}>
            <Text style={[styles.filterPillsLabel]}>GiftCard</Text>
          </TouchableOpacity> */}
          </View>
        </View>
      )}
      <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
        {paymentMethodId ? (
          <CardPaymentForm
            onAddAddress={handleAddAddress}
            onAfterSubmit={handleAfterSubmit}
            addressId={addressId}
            paymentMethodId={paymentMethodId}
            onEditAddress={handleEditAddress}
          />
        ) : (
          <AddPaymentProfile onCancel={handleClose} onSuccessfulSave={handleAfterSubmit} />
        )}
      </ErrorBoundary>
    </Screen>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    width: screenWidth,
    backgroundColor: colors.sectionPad,
    zIndex: 1,
  },
  subHeader: {
    height: scale(56),
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    width: containerWidth,
    backgroundColor: colors.sectionPad,
    justifyContent: 'flex-start',
    zIndex: 1,
  },
  filterPillsLabelActive: {
    color: colors.white,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    textAlign: 'center',
  },
  filterPillsLabel: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    textAlign: 'center',
    marginTop: scale(3),
    ...utilityStyles.px2,
  },
  filterPills: {
    color: colors.text,
    backgroundColor: colors.white,
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#707070',
    height: scale(24),
    ...utilityStyles.mx2,
    justifyContent: 'center',
    alignContent: 'center',
  },
  filterPillsActive: {
    color: colors.white,
    backgroundColor: colors.secondary,
    borderColor: colors.white,
  },
  filterPillsDisabled: {
    opacity: 0.4,
  },
});

export default AddPaymentMethodScreen;

import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { Button, DropShadow, Icon, LinkButton, LoadingScreen, Modal, PointsHeader, SearchBar, Text } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import { mapping } from '@components/bloomreach';
import { ProductCarousel } from '@components/brsm';
import withTombstone from '@components/hoc/withTombstone';
import { BalanceCard, Feedback, OrderStatus } from '@components/home';
import { List, ListData } from '@components/lists';
import SEOffers from '@components/somethingExtra/SEOffers';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { PersonalizedWidgetParams, TourKey } from '@fieldera-raleys/client-common';
import { useAnalytics, useBrxmPage, useEffectOnce } from '@hooks';
import { AccountStackRoutes, AppStackRoutes, RewardsStackRoutes, RootTabRoutes, RootTabScreenProps, ShopStackRoutes } from '@navigation/routes';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { brsmService } from '@services';
import { userService } from '@services/brandywine';
import { useAnalyticsStore, useAppConfigStore, useUserProfileStore, useUserSettingsStore } from '@store';
import useTutorialStore from '@store/tutorialStore';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Linking, ListRenderItemInfo, Platform, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import Config from 'react-native-config';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTourGuideController } from 'rn-tourguide';
import { useCustomModalContext } from '../contexts';

type HomeScreenProps = RootTabScreenProps<RootTabRoutes.Home>;

const HomeScreen = withTombstone(({ navigation, route }: HomeScreenProps) => {
  const tabBarHeight = useBottomTabBarHeight();
  const [refreshing, setRefreshing] = useState(false);
  const [showPointsHeader, setShowPointsHeader] = useState(true);
  const { brxmConfiguration, brxmPage, reloadPageAsync } = useBrxmPage();
  const { isLoading, data: categoryList } = brsmService.useCategoriesQuery((x) => x?.filter((c) => !c.parentName));
  const baseImageUrl = `${Config.BRXM_IMAGE_URL}/consumer-app/category-image`;
  const insets = useSafeAreaInsets();
  const { trackPageViewEvent } = useAnalytics();
  const trackPageView = useRef<boolean>(true);
  const pageConfig = useAppConfigStore().getConfigGroup('Home');
  const { showAMSCard, showModal, hideModal, showAlertModal } = useCustomModalContext();
  const { userSettings, updateSetting } = useUserSettingsStore();
  const {
    canStart, // a boolean indicate if you can start tour guide
    start, // a function to start the tourguide
    stop, // a function  to stopping it
  } = useTourGuideController(TourKey.HomeScreen);
  const scrollViewRef = useRef<ScrollView>();
  const { userProfile } = useUserProfileStore();
  const { previousRoute } = useAnalyticsStore();
  const [showDigitalModal, setShowDigitalModal] = useState(false);
  const [showStartTourModal, setShowStartTourModal] = useState(false);

  const { t } = useTranslation(['homeScreen', 'tourGuide']);

  const { getVisitCount } = useTutorialStore();
  useEffectOnce(() => {
    if (trackPageView.current) {
      trackPageViewEvent();
      trackPageView.current = false;
    }

    // setShowStartTourModal(true);

    return () => {
      trackPageView.current = true;
    };
  });

  const quickLinks: ListData[] = [
    {
      id: 'weekly-ad',
      title: 'Weekly Ad',
      iconProps: { name: 'weekly-ad', style: styles.image, size: 49, stroke: 'none' },
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: {
            screen: ShopStackRoutes.WeeklyAds,
          },
        });
      },
    },
    {
      id: 'offers',
      title: 'Offers',
      iconProps: { name: 'se-offers', style: styles.image, size: 49, stroke: 'none' },
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Rewards,
          params: { screen: RewardsStackRoutes.SEOffers, params: { currTab: 'Personalized' } },
        });
      },
    },
    {
      id: 'categories',
      title: 'Categories',
      iconProps: { name: 'categories', style: styles.image, size: 49, stroke: 'none' },
      onPress: () => {
        navigation.navigate(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: {
            screen: ShopStackRoutes.Categories,
          },
        });
      },
    },
    {
      id: 'favorites',
      title: 'Lists',
      iconProps: { name: 'list-page', style: styles.image, size: 49, stroke: 'none' },
      onPress: () => {
        navigation.push(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Account,
          params: {
            screen: AccountStackRoutes.ManageShoppingList,
          },
        });
      },
    },
    {
      id: 'se-barcode',
      title: 'SE Barcode',
      iconProps: { name: 'se-barcode-icon', style: styles.image, size: 49, stroke: 'none' },
      onPress: showAMSCard,
    },
  ];

  const startTourGuide = useCallback(() => {
    setShowStartTourModal(false);
    if (canStart && (userSettings?.showTourGuide ?? false) && (getVisitCount('Home') === 1 || getVisitCount('Home') === 3)) {
      start();
    }
  }, [canStart, getVisitCount, start, userSettings?.showTourGuide]);

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', async () => {
      setShowPointsHeader(true);
      (getVisitCount('Home') === 3 || getVisitCount('Home') === 1) && startTourGuide();
    });
    const unsubscribeBlur = navigation.addListener('blur', async () => {
      stop();
    });
    const unsubscribeTabPress = navigation.addListener('tabPress', () => {
      scrollViewRef.current?.scrollTo({ x: 0, y: 0 });
    });

    return () => {
      unsubscribeFocus();
      unsubscribeBlur();
      unsubscribeTabPress();
    };
  }, [getVisitCount, navigation, startTourGuide, stop]);

  useEffect(() => {
    if (!userProfile) {
      return;
    }

    if (
      !(userSettings?.remindMeLater ?? false) &&
      (userProfile?.digitalLoyaltyAgreementDate === undefined || userProfile?.digitalLoyaltyAgreementDate === null)
    ) {
      setShowDigitalModal(true);
      return;
    } else {
      setShowStartTourModal(true);
    }
  }, [userSettings?.remindMeLater, userProfile]);

  const handleRefresh = async () => {
    setRefreshing(true);
    await reloadPageAsync();
    setRefreshing(false);
  };

  const renderQuickLinks = ({ item }: ListRenderItemInfo<ListData>) => {
    return (
      <TouchableOpacity onPress={() => item.onPress && item.onPress(item)} key={`quicklink_${item.id}`} testID="quicklinkClick">
        <View style={styles.contentBox}>
          <View style={styles.squareImage}>
            {item.iconProps ? (
              <Icon {...item.iconProps} testID="icon" />
            ) : (
              <Image source={{ cache: 'reload', uri: item.imageUrl }} style={styles.image} testID="image" />
            )}
          </View>
          <Text style={styles.imageLabels} testID={item.title}>
            {item.title}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const handleShowAllLink = () => {
    navigation.push(AppStackRoutes.RootTabs, {
      screen: RootTabRoutes.Shop,
      params: {
        screen: ShopStackRoutes.Categories,
      },
    });
  };

  const onEnrollNowBtnClick = async () => {
    setShowDigitalModal(false);
    try {
      const response = await userService.enrollCustomerDigitalTransition();
      if (response) {
        showAlertModal({
          title: t('digitalTranSuccessTitleMsg'),
          message: t('digitalTranSuccessMsg'),
          closeButtonText: t('doneBtnText'),
          onClose: () => {
            setShowStartTourModal(true);
          },
        });
      } else {
        showModal({
          location: 'top',
          title: t('digitalTranErrorTitleMsg'),
          buttonContainerStyle: { display: 'none' },
          children: (
            <View style={styles.digitalTranErrorModal}>
              <Text style={styles.msgText} testID="errorMsg">
                {t('digitalTranErrorMsg')}
              </Text>
              <LinkButton
                style={[appStyles.boldLinkLargeCenter, styles.calllinkBtn]}
                onPress={() => {
                  Linking.openURL(`tel:${appConstants.CUSTOMER_SERVICE_PHONE}`);
                }}
                testID="phoneBtn">
                {appConstants.CUSTOMER_SERVICE_PHONE}
              </LinkButton>
              <Button
                size={'small'}
                type="secondary"
                buttonStyle={styles.okBtn}
                title={t('okBtnText')}
                onPress={() => {
                  hideModal();

                  setShowStartTourModal(true);
                }}
                testID={'OkBtn'}
              />
            </View>
          ),
        });
      }
    } catch (error) {
      return (error as Error).message;
    }
  };

  const closeModal = () => {
    updateSetting('remindMeLater', true);
    setShowDigitalModal(false);
    setShowStartTourModal(true);
  };

  const showDigitalTranstionModal = () => {
    return (
      <Modal
        location={'top'}
        title={t('digitalTransitionTitle')}
        visible={showDigitalModal}
        okButtonText={t('enrollNowBtn')}
        okButtonStyle={styles.helpModalButton}
        okButtonOnPress={onEnrollNowBtnClick}
        children={
          <Text style={styles.msgText} testID="digitalModalText">
            {t('digitalTranModalText')}
          </Text>
        }
        showLinkButton={true}
        linkButtonText="Remind Me Later"
        cancelButtonOnPress={closeModal}
        showCancel={false}
      />
    );
  };

  const closeStartTourGuideModal = () => {
    updateSetting('showTourGuide', false);
    setShowStartTourModal(false);
  };

  const showStartTourGuideModal = () => {
    return (
      <Modal
        location={'top'}
        title={t('startTourGuideModalTitle', { ns: 'tourGuide' })}
        visible={showStartTourModal}
        okButtonText={t('startTGStartBtnTxt', { ns: 'tourGuide' })}
        cancelButtonText={t('startTGMaybeLaterBtnTxt', { ns: 'tourGuide' })}
        okButtonStyle={styles.helpModalButton}
        cancelButtonStyle={styles.helpModalButton}
        okButtonOnPress={startTourGuide}
        children={<Text style={styles.msgText}> {t('startTourGuideModalMsg', { ns: 'tourGuide' })}</Text>}
        cancelButtonOnPress={closeStartTourGuideModal}
        style={styles.modalStyle}
      />
    );
  };

  const renderCategories = ({ item }: ListRenderItemInfo<ListData>) => {
    return (
      <TouchableOpacity onPress={() => item.onPress && item.onPress(item)} key={`category_${item.id}`} testID="categoryClick">
        <View style={styles.shopContentBox}>
          <View style={styles.shopSquareImage}>
            <Image source={{ cache: 'reload', uri: item.imageUrl }} style={styles.shopImage} testID="categoryImage" />
          </View>
          <View style={styles.catTextView}>
            <Text style={styles.ShopImageLabels} testID="categoryTitle">
              {item.title}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const categoryOnPress = (item: ListData) => {
    item.params &&
      navigation.navigate(AppStackRoutes.RootTabs, {
        screen: RootTabRoutes.Shop,
        params: {
          screen: ShopStackRoutes.Category,
          params: item.params,
        },
      });
  };

  return (
    <>
      <DropShadow style={styles.dropShadow}>
        <CartInfoBanner />
        <View style={[appStyles.headerContainer, styles.headerContainer]}>
          {showPointsHeader && (
            <View style={{ width: '100%' }}>
              <PointsHeader />
            </View>
          )}
          <View style={{ width: '100%' }}>
            <SearchBar tabBarHeight={tabBarHeight} setShowPointsHeader={setShowPointsHeader} />
          </View>
        </View>
      </DropShadow>
      {!brxmPage || !brxmConfiguration ? (
        <LoadingScreen />
      ) : (
        // @ts-ignore
        <BrPage configuration={brxmConfiguration} page={brxmPage} mapping={mapping}>
          <ScrollView
            ref={(ref) => (scrollViewRef.current = ref ?? undefined)}
            style={Platform.OS === 'web' ? {} : { marginBottom: 65 - insets.bottom }}
            nestedScrollEnabled={true}
            showsVerticalScrollIndicator={false}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}>
            <BrComponent path="hero" />
            <List
              renderItem={renderQuickLinks}
              contentContainerStyle={utilityStyles.px2}
              isLoading={false}
              orientation={'horizontal'}
              data={quickLinks}
              showIndicator={false}
            />
            <BrComponent path="main" />
            <OrderStatus />
            <BalanceCard />
            <SEOffers />
            <BrComponent path="body" />
            {pageConfig?.ItemsYouMayLike && (
              <ProductCarousel
                title="Items You Might Like"
                contentContainerStyle={styles.contentContainer}
                widgetId={pageConfig.ItemsYouMayLike}
                widgetType={'ItemsYouMayLike'}
                addCartButtonStyle={styles.addCartButtonStyle}
                widgetParams={{ url: route.name, ref_url: previousRoute?.name ?? '/', type: 'personalized' } as PersonalizedWidgetParams}
              />
            )}
            <List
              renderItem={renderCategories}
              contentContainerStyle={utilityStyles.px2}
              heading={'Shop By Category'}
              headingStyle={[appStyles.dropShadowTitle, utilityStyles.pl3]}
              linkOnPress={handleShowAllLink}
              link={'See All'}
              linkStyle={styles.showAll}
              isLoading={isLoading}
              orientation={'horizontal'}
              data={(categoryList ?? []).map((x) => {
                return {
                  id: x.id,
                  params: { categoryId: x.id },
                  title: x.name,
                  imageUrl: `${baseImageUrl}/${x.imageName}`,
                  onPress: categoryOnPress,
                } as ListData;
              })}
            />
            <BrComponent path="bottom" />
            <Feedback />
            {showDigitalModal && showDigitalTranstionModal()}
            {showStartTourModal && !showDigitalModal && (userSettings?.showTourGuide ?? false) && getVisitCount('Home') === 1 && showStartTourGuideModal()}
          </ScrollView>
        </BrPage>
      )}
    </>
  );
}, {});

const styles = StyleSheet.create({
  headerContainer: {
    marginBottom: 0,
    height: 105,
    flexDirection: 'column',
    width: screenWidth,
  },
  dropShadow: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.cream,
    borderBottomWidth: 0,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  showAll: {
    left: -15,
    fontSize: scale(15),
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
    lineHeight: 20,
    textAlign: 'right',
  },
  contentBox: {
    alignItems: 'center',
    width: scale(73),
  },
  squareImage: {
    left: 10,
    width: 75,
    aspectRatio: 1,
  },
  image: {
    height: 50,
    width: 50,
  },
  imageLabels: {
    top: -18,
    left: -5,
    fontSize: scale(15),
    lineHeight: lineHeight(15),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.primary,
    textAlign: 'center',
  },

  ShopImageLabels: {
    top: -3,
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(16),
    lineHeight: lineHeight(16),
    textAlign: 'center',
  },
  shopContentBox: {
    left: -10,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    width: 85,
  },
  shopSquareImage: {
    alignItems: 'center',
    width: 75,
    aspectRatio: 1,
    justifyContent: 'center',
  },
  shopImage: {
    borderRadius: 100,
    height: 60,
    width: 60,
  },
  addCartButtonStyle: {
    backgroundColor: colors.darkCream,
    width: screenWidth / 2.8,
  },
  catTextView: {
    width: screenWidth > 400 ? 95 : 85,
    alignSelf: 'center',
    justifyContent: 'center',
    padding: 2,
  },
  helpModalButton: {
    width: 150,
  },
  msgText: {
    ...appStyles.bodyLargeCenter,
    paddingBottom: 5,
    width: screenWidth * 0.9,
    marginTop: 10,
    alignSelf: 'center',
  },
  digitalTranErrorModal: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  calllinkBtn: {
    marginVertical: 20,
    fontFamily: FontFamily.LarsseitLight,
  },
  okBtn: {
    width: '70%',
    marginTop: 20,
  },
  modalStyle: {
    minHeight: Platform.select({
      ios: screenHeight > 740 ? screenHeight * 0.31 : screenHeight * 0.35,
      android: screenHeight > 740 ? screenHeight * 0.28 : screenHeight * 0.32,
    }),
  },
});

export default HomeScreen;

import { DropShadow, Icon, NavigationHeader, Screen } from '@components';
import CartInfoBanner from '@components/CartInfoBanner';
import HeaderTitle from '@components/HeaderTitle';
import PaymentMethods from '@components/PaymentMethods';
import Tabbar, { TabItem } from '@components/Tabbar';
import ClippedSavings from '@components/somethingExtra/ClippedSavings';
import SEDollars from '@components/somethingExtra/SEDollars';
import { RootTabRoutes, RootTabScreenProps } from '@navigation/routes';
import { useOffersStore } from '@store';
import { utilityStyles } from '@styles';
import React, { useEffect, useState } from 'react';
import { useCustomModalContext } from '../../../contexts';

type WalletScreenProps = RootTabScreenProps<RootTabRoutes.Wallet>;

const WalletScreen = ({ navigation, route }: WalletScreenProps) => {
  const { availableVouchers, acceptedVouchers, vouchersState } = useOffersStore();
  const { showBack, link, linkParams, toDollarsTab } = route.params;
  const [currTab, setCurrTab] = useState<string>('');
  const { showAMSCard } = useCustomModalContext();
  const tabItems: TabItem[] = [
    {
      name: 'se-dollars',
      displayName: 'SE Dollars',
      onPress: () => setCurrTab('se-dollars'),
    },
    {
      name: 'clippedSavings',
      displayName: 'Clipped Savings',
      onPress: () => setCurrTab('clippedSavings'),
    },
    {
      name: 'payment-methods',
      displayName: 'Payment Methods',
      onPress: () => setCurrTab('payment-methods'),
    },
  ];

  useEffect(() => {
    if (vouchersState !== 'loading') {
      if (toDollarsTab) {
        setCurrTab('se-dollars');
        return;
      }
      if (!acceptedVouchers?.length && !availableVouchers?.length && !toDollarsTab) {
        setCurrTab('payment-methods');
      }
      if (!currTab && (acceptedVouchers?.length || availableVouchers?.length)) {
        setCurrTab('se-dollars');
      }
      if (currTab === 'se-dollars') {
        setCurrTab('se-dollars');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedVouchers, availableVouchers, vouchersState, toDollarsTab]);

  return (
    <Screen testID="WalletScreen" style={utilityStyles.pb0}>
      <CartInfoBanner />
      <NavigationHeader
        showShadow={false}
        style={{ shadowOpacity: 0 }}
        link={link}
        linkParams={linkParams}
        title={showBack ? undefined : 'Wallet'}
        icon={showBack ? undefined : <></>}
        next={<Icon name="se-barcode-icon" size={40} stroke={'rgba(0,0,0,0.1)'} />}
        onNext={showAMSCard}
        subStyle={[{ alignItems: showBack ? 'flex-start' : 'center' }, utilityStyles.pt2]}>
        {showBack && <HeaderTitle>Wallet</HeaderTitle>}
      </NavigationHeader>
      <>
        <DropShadow>
          <Tabbar tabs={tabItems} selectedTabName={currTab} />
        </DropShadow>
        {currTab === 'payment-methods' && (
          <PaymentMethods
            {...{
              navigation,
              route,
            }}
          />
        )}
        {currTab === 'se-dollars' && <SEDollars />}
        {currTab === 'clippedSavings' && <ClippedSavings style={{ paddingTop: 24 }} />}
      </>
    </Screen>
  );
};

export default WalletScreen;

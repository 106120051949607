import colors from '@config/colors';
import { appStyles, utilityStyles } from '@styles';
import { screenHeight } from '@styles/constants';
import { useField } from 'formik';
import React, { ForwardedRef, Ref, forwardRef, useEffect } from 'react';
import { Platform, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Picker, { PickerComponent, PickerProps } from '../Picker';
import Text from '../Text';
import ErrorMessage from './ErrorMessage';

interface FormPickerProps<T> extends Omit<PickerProps<T>, 'onValueChange' | 'onBlur'> {
  name: string;
  label?: string;
  containerStyle?: StyleProp<TextStyle>;
  reserveSpace?: boolean;
  topRight?: string | JSX.Element;
  bottomRight?: string | JSX.Element;
  labelStyle?: StyleProp<ViewStyle>;
}

const FormPicker = forwardRef(
  <T,>(
    { label, name, reserveSpace = false, topRight, bottomRight, containerStyle, initialValue, style: pickerStyle, labelStyle, ...rest }: FormPickerProps<T>,
    forwardedRef?: ForwardedRef<PickerComponent<T>>,
  ) => {
    const [field, meta, helpers] = useField<string>(name);

    if (Platform.OS !== 'web' && meta.touched && meta.error) {
      ReactNativeHapticFeedback.trigger('impactLight', {
        enableVibrateFallback: true,
        ignoreAndroidSystemSettings: false,
      });
    }
    useEffect(() => {
      helpers.setValue(initialValue?.id ? String(initialValue.id) : field.value);
      helpers.setTouched(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);
    return (
      <View style={[utilityStyles.mt3, bottomRight ? { marginBottom: 10 } : {}, containerStyle]}>
        <View style={styles.labelViewStyle}>
          <Text style={[appStyles.formLabels, labelStyle]} testID="label">
            {label}
          </Text>
          <View>
            {topRight && typeof topRight === 'string' ? (
              <Text style={[styles.rTop, appStyles.formLabelsTopRight]} testID="topRight">
                {topRight}
              </Text>
            ) : (
              topRight
            )}
            {bottomRight && (
              <Text style={[styles.rBottom, appStyles.formLabelsBottomRight]} testID="bottomRight">
                {bottomRight}
              </Text>
            )}
          </View>
        </View>
        <Picker
          testID="formPicker"
          containerStyle={styles.pickerHeight}
          ref={forwardedRef}
          initialValue={initialValue}
          onValueChange={(val) => helpers.setValue(String(val.id), true)}
          onBlur={() => helpers.setTouched(true, false)}
          style={[styles.formBorder, pickerStyle, { borderColor: !meta.value && meta.touched ? colors.red : colors.sectionBorder }]}
          {...rest}
        />
        {meta.touched && !meta.value ? <ErrorMessage testID="error" reserveSpace={reserveSpace} error={meta.error} visible={meta.touched} /> : <></>}
      </View>
    );
  },
) as <T>(p: FormPickerProps<T> & { ref?: Ref<PickerComponent<T>> }) => JSX.Element | null;

const styles = StyleSheet.create({
  rTop: {
    padding: 5,
    right: 0,
    position: 'absolute',
    top: -22,
    height: 30,
  },
  rBottom: {
    padding: 5,
    right: 0,
    bottom: 0,
    marginBottom: -18,
    position: 'absolute',
  },
  formBorder: {
    borderRadius: 15,
    borderWidth: 1,
    borderColor: colors.sectionBorder,
  },
  pickerHeight: {
    height: screenHeight > 740 ? screenHeight * 0.47 : screenHeight * 0.5,
  },
  labelViewStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
export default FormPicker;

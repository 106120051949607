/* eslint-disable react-hooks/exhaustive-deps */

import { Icon, IconTextInput, Modal } from '@components';
import KeyboardCloseButton from '@components/KeyboardCloseButton';
import SaveBar from '@components/SaveBar';
import Text from '@components/Text';
import StoreLocationItem from '@components/store/StoreLocationItem';
import colors from '@config/colors';
import { ShopType, Store } from '@fieldera-raleys/client-common';
import { getLastKnownLocation, hasPermissions as hasLocationPermissions } from '@hooks/useLocation';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { storeService } from '@services/brandywine';
import { useCartStore } from '@store';
import useShopStore from '@store/shopStore';
import { appStyles } from '@styles';
import { containerWidth, scale, screenHeight, screenWidth } from '@styles/constants';
import React, { Dispatch, FC, SetStateAction, createRef, useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ActivityIndicator, Dimensions, FlatList, Linking, Modal as NativeModal, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';

const { height } = Dimensions.get('window');

interface IFindAStoreProps {
  toggleTombstone: () => void;
  setCurrentPage: Dispatch<SetStateAction<string>>;
}

interface ISearchPageProps {
  setCurrentPage: Dispatch<SetStateAction<string>>;
}
type EmptyListComponentProps = { isEmptyList: boolean; currentTab: string };
const EmptyListComponent = ({ isEmptyList = false, currentTab }: EmptyListComponentProps) => {
  return (
    <View style={styles.emptyListContainer}>
      {isEmptyList ? (
        <>
          <View style={[styles.emptyListIcon, { paddingTop: 14, paddingLeft: 22 }]}>
            <Icon name={'pin-questionmark-solid'} fill={colors.white} size={68} stroke={colors.darkCream} testID="pinQuestionmarkSolid" />
          </View>
          <Text style={styles.emptyListText} testID="emptyList">
            Unfortunately, we don't yet support this area. Please try finding a different city or zip.
          </Text>
        </>
      ) : (
        <>
          {currentTab === 'Favorites' ? (
            <>
              <View style={[styles.emptyListIcon, { transform: [{ translateY: -5 }] }]}>
                <Icon name={'star-outline-large'} size={70} testID="starOutlineLarge" />
              </View>
              <Text style={styles.emptyListText} testID="favoriteText">
                Tap the star icon next to the address of your favorite location to add it to this list.
              </Text>
            </>
          ) : (
            <>
              <View style={styles.emptyListIcon}>
                <Icon name={'search-large'} size={63} testID="searchLarge" />
              </View>
              <Text style={styles.emptyListText} testID="searchText">
                To begin, search by street, city or zip code. To use your current location, tap the arrow.
              </Text>
            </>
          )}
        </>
      )}
    </View>
  );
};

const SearchPage: FC<ISearchPageProps> = ({ setCurrentPage }): JSX.Element => {
  const navigation = useNavigation();
  const handleError = useErrorHandler();
  const [loading, setLoading] = useState(false);
  const {
    setSelectedStore: setSelectedLocation,
    selectedStore: selectedLocation,
    setSelectedTimeSlot,
    favorites,
    setFavorite,
    previous,
    searchText,
    setSearchText,
    setSearchResult,
    searchResult,
    setCurrentPageTab,
    previousDeliveryAddress,
    setDeliveryAddress,
    previousTimeSlot,
    previousStore,
    setSelectedShopType,
    previousShopType,
    setPreviousShopType,
    setSelectedStore,
    deliveryAddress,
    selectedTimeSlot,
    tempShopType,
  } = useShopStore();
  const { validateCart } = useCartStore();
  const [isEmptyList, setIsEmptyList] = useState(false);
  const [currentTab, setCurrentTab] = useState('Nearby');
  const [currentSelectedLocation, setCurrentSelectedLocation] = useState<Store>();
  const [locations, setLocations] = useState<Store[] | undefined>(searchResult);
  const [nearby, setNearby] = useState<Store[] | undefined>(searchResult);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [cartChangeStore, setCartChangeStore] = useState<Store | undefined>(undefined);
  const [showCartChangeModal, setShowCartChangeModal] = useState(false);
  const cartChangeModalRef = createRef<NativeModal>();
  // const [favorites, setFavorites] = useState<Location[] | undefined>([]);
  const { showAlertModal } = useCustomModalContext();
  const TABS = ['Nearby', 'Previous', 'Favorites'];

  const selectLocation = (location: Store) => {
    setCurrentSelectedLocation(location);
  };

  const searchForLocations = async (text: string) => {
    //API Call for location search will be here
    // console.log(text);
    setSearchResult([]);
    setLocations([]);
    setLoading(true);
    setSearchText(text);
    var locationData = await getLastKnownLocation();
    try {
      const searchResults = await storeService.searchStores(0, 20, {
        shippingMethod: 'pickup',
        searchString: text,
        latitude: locationData?.coords.latitude,
        longitude: locationData?.coords.longitude,
      });
      if (!searchResults || searchResults.data?.length < 1) {
        setIsEmptyList(true);
      }

      setSearchResult(searchResults.data || []);
      setLocations(searchResults.data);
      setNearby(searchResults.data);
      setCurrentTab('Nearby');
      setCurrentPageTab('Nearby');
    } catch (ex) {
      handleError(ex);
    } finally {
      setLoading(false);
    }
  };

  const saveLocation = async () => {
    //Function will save address to redux/context
    //TODO: Check for items in cart and validate availability
    setButtonLoading(true);
    const validationResult = await validateCart(currentSelectedLocation?.number).catch((err) => handleError(err));
    if (!validationResult?.isValid) {
      setButtonLoading(false);
      setCartChangeStore(currentSelectedLocation);
      setShowCartChangeModal(true);
      // logger.log('Invalid cart...', JSON.stringify(validationResult));
      return;
    }
    setSelectedLocation(currentSelectedLocation);
    if (tempShopType && tempShopType === ShopType.IN_STORE) {
      setPreviousShopType(ShopType.IN_STORE);
      setSelectedShopType(ShopType.IN_STORE);
    } else {
      setPreviousShopType(ShopType.PICKUP);
      setSelectedShopType(ShopType.PICKUP);
    }
    setDeliveryAddress(undefined);
    setSelectedTimeSlot(undefined);
    setButtonLoading(false);
    if (tempShopType && tempShopType === ShopType.IN_STORE) {
      setCurrentPage('In-Store');
    } else {
      setCurrentPage('TimeSlot');
    }
  };

  const displayMap = () => {
    setCurrentPageTab(currentTab);
    setCurrentPage('Map');
  };

  const searchCurrentLocation = async () => {
    const permission = await hasLocationPermissions();
    if (!permission) {
      showAlertModal({
        title: 'Permissions Required',
        message: 'This feature requires location permissions to work properly.',
        onClose: () => {
          Platform.select({
            ios: Linking.openURL('app-settings:'),
            android: Linking.openSettings(),
          });
        },
      });
      return;
    }
    setLocations([]);
    setLoading(true);
    var locationData = await getLastKnownLocation();
    var lat = locationData?.coords.latitude || 0;
    var lon = locationData?.coords.longitude || 0;
    var res = await storeService.searchStores(0, 25, { shippingMethod: 'pickup', latitude: lat, longitude: lon });
    if (!res || res?.data.length < 1) {
      setIsEmptyList(true);
    }
    setSearchResult(res.data || []);
    setLocations(res.data);
    setNearby(res.data);
    setCurrentTab('Nearby');
    setCurrentPageTab('Nearby');
    setLoading(false);
  };

  const handleCartChange = () => {
    setShowCartChangeModal(false);
    navigation.dispatch(
      StackActions.push(AppStackRoutes.CartAndCheckout, {
        screen: CheckoutStackRoutes.CartLanding,
        params: { storeNumber: cartChangeStore?.number, shopType: ShopType.PICKUP, deliveryAddress: undefined },
      }),
    );
    setCartChangeStore(undefined);
  };

  const handleCancelValidation = () => {
    if (previousShopType === ShopType.PICKUP) {
      if (previousStore) {
        setSelectedShopType(ShopType.PICKUP);
        setSelectedStore(previousStore);
        setSelectedTimeSlot(previousTimeSlot);
      }
      setCartChangeStore(undefined);
      setShowCartChangeModal(false);
      return;
    } else if (previousShopType === ShopType.DELIVERY) {
      setSelectedShopType(ShopType.DELIVERY);
      setSelectedLocation(selectedLocation || previousStore);
      setDeliveryAddress(deliveryAddress || previousDeliveryAddress);
      setSelectedTimeSlot(selectedTimeSlot || previousTimeSlot);
      setCurrentPage('Delivery');
      return;
    }
    setCartChangeStore(undefined);
    setShowCartChangeModal(false);
  };

  const setTabData = async () => {
    setCurrentSelectedLocation(undefined);
    if (currentTab === 'Nearby') {
      if (nearby?.length) {
        setLocations(nearby);
        setSearchResult(nearby);
        return;
      } else {
        const permission = await hasLocationPermissions();
        if (permission) {
          searchCurrentLocation();
          return;
        }
      }
      setLocations([]);
      setIsEmptyList(false);
    } else if (currentTab === 'Previous') {
      setLocations(previous);
      setIsEmptyList(false);
    } else if (currentTab === 'Favorites') {
      setLocations(favorites);
    }
  };

  useEffect(() => {
    setTabData();
  }, [currentTab]);

  const renderEmptyListComponent = useCallback(() => {
    return loading ? null : <EmptyListComponent isEmptyList={isEmptyList} currentTab={currentTab} />;
  }, [isEmptyList, loading]);

  return (
    <View
      style={{
        ...styles.initialOptionsWrapper,
        height: '100%',
        transform: [
          {
            translateY: -height * 0.01,
          },
        ],
      }}>
      {!selectedLocation?.address?.street ? (
        <Text style={styles.findAStoreText} testID="findAStore">
          Find a Store
        </Text>
      ) : null}
      {!selectedLocation?.address?.street ? (
        <TouchableOpacity style={styles.backArrow} onPress={() => setCurrentPage('InitialShopOptions')} testID="initialShopOptionsClick">
          <Icon name={'arrow-left'} testID="arrowLeft" />
        </TouchableOpacity>
      ) : null}
      <View style={styles.searchWrapper}>
        <IconTextInput
          testID="findAStoreInput"
          width={'75%'}
          clearButtonMode="while-editing"
          placeholder="Search by street, city or zip code"
          onSubmitEditing={({ nativeEvent: { text } }) => {
            setSearchText(text.trim());

            if (!text.trim()) {
              searchCurrentLocation();
            } else {
              searchForLocations(text);
            }
          }}
          onChangeText={setSearchText}
          value={searchText}
          inputAccessoryViewID="Close"
        />
        <TouchableOpacity onPress={searchCurrentLocation} style={styles.locationIcon}>
          <Icon testID="locationArrow" name="location-arrow" fill={colors.white} stroke={colors.darkText} style={styles.arrowIcon} />
        </TouchableOpacity>
      </View>
      <KeyboardCloseButton />
      {locations?.length ? (
        <TouchableOpacity onPress={displayMap} style={styles.showMapWrapper} testID="mapClick">
          <Icon name="map-hallow" style={appStyles.mediumIcon} testID="map-hallow" />
          <Text style={styles.showMapText} testID="showMapText">
            Show Map
          </Text>
        </TouchableOpacity>
      ) : (
        <View />
      )}
      <View style={styles.tabWrapper}>
        {TABS.map((tab, index) => {
          return (
            <View key={index} style={styles.tabContainer}>
              <TouchableOpacity
                testID={`${tab}`}
                onPress={() => {
                  setCurrentTab(tab);
                  setCurrentPageTab(tab);
                }}
                style={styles.tab}>
                <Text
                  testID={tab}
                  style={{
                    ...styles.tabText,
                    color: currentTab === tab ? colors.red : colors.black,
                    opacity: currentTab === tab ? 1 : 0.5,
                    fontWeight: '700',
                  }}>
                  {tab}
                </Text>
                {currentTab === tab && <View style={styles.selectedTab} />}
              </TouchableOpacity>
            </View>
          );
        })}
      </View>
      {loading ? <ActivityIndicator style={styles.activityIndicator} /> : null}
      <FlatList
        style={styles.locationList}
        contentContainerStyle={[styles.locationListContent, currentSelectedLocation?.address?.street ? styles.padBottom : null]}
        data={locations}
        extraData={locations}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item, index }: { item: Store; index: number }) => {
          return (
            <StoreLocationItem
              currentSelectedLocation={currentSelectedLocation}
              selectLocation={selectLocation}
              favorites={favorites}
              setFavorite={(loc) => setFavorite(loc)}
              store={item}
              index={index}
            />
          );
        }}
        ListEmptyComponent={renderEmptyListComponent}
      />
      {currentSelectedLocation?.address?.street ? (
        <View style={[!selectedLocation?.address?.street ? styles.saveBar : styles.saveBarSet]} pointerEvents={'box-none'}>
          <SaveBar onPress={saveLocation} isButtonLoading={buttonLoading} />
        </View>
      ) : null}
      {showCartChangeModal && (
        <Modal
          style={{ minHeight: screenHeight / 3 }}
          buttonContainerStyle={{ bottom: screenHeight > 740 ? 30 : 15 }}
          headerStyle={{ justifyContent: 'center' }}
          contentStyle={{ width: screenWidth, paddingHorizontal: 16 }}
          ref={cartChangeModalRef}
          visible={showCartChangeModal}
          title={'Changes to Your Cart'}
          location="top"
          cancelButtonOnPress={handleCancelValidation}
          okButtonOnPress={handleCartChange}
          cancelButtonText="Cancel"
          okButtonText="Continue">
          <Text style={[appStyles.bodyMediumLight, { textAlign: 'center' }]} allowFontScaling={false} testID="showCartChangeModalText">
            Some items in your cart may be affected. Would you like to continue?
          </Text>
        </Modal>
      )}
    </View>
  );
};

const FindAStore: FC<IFindAStoreProps> = ({ setCurrentPage }): JSX.Element => {
  return <SearchPage setCurrentPage={setCurrentPage} />;
};

const styles = StyleSheet.create({
  initialOptionsWrapper: {
    alignItems: 'center',
    height: '83%',
  },
  activityIndicator: {
    alignSelf: 'center',
    width: screenWidth,
    marginBottom: 10,
    marginTop: 100,
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '80%',
    paddingTop: 30,
  },
  emptyListIcon: {
    width: 100,
    height: 100,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 13,
    paddingTop: 17,
  },
  emptyListText: {
    fontFamily: 'Larsseit',
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: 26,
    width: '75%',
  },
  backArrow: {
    position: 'absolute',
    left: 0,
    top: Platform.OS === 'web' ? -20 : -40,
    paddingLeft: 10,
    paddingTop: 9,
    width: '20%',
    height: 50,
  },
  findAStoreText: {
    fontFamily: 'Aleo-Bold',
    fontSize: 25,
    lineHeight: 30,
    color: colors.text,
    textAlign: 'center',
    width: '62%',
    position: 'absolute',
    top: Platform.OS === 'web' ? -20 : -30,
    zIndex: 0,
    alignSelf: 'center',
  },
  locationSearchBar: {
    width: '75%',
    height: 45,
    borderWidth: 1,
    borderRadius: 15,
    backgroundColor: 'white',
    borderColor: '#ecdcd1',
    paddingVertical: 0,
    paddingHorizontal: 10,
    fontSize: scale(15),
    fontFamily: 'Larsseit-Bold',
    marginRight: Platform.OS === 'web' ? 0 : 7,
    color: colors.darkText,
    alignSelf: 'center',
  },
  locationIcon: {
    justifyContent: 'center',
    marginLeft: 15,
    alignSelf: 'center',
  },
  searchWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: Platform.OS === 'ios' ? height * 0.035 : height * 0.03,
  },
  showMapWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: screenHeight * 0.03,
  },
  arrowIcon: {
    width: 25,
    height: 25,
  },
  showMapText: {
    fontFamily: 'Aleo-Bold',
    fontSize: scale(15),
    color: colors.darkText,
    textDecorationLine: 'underline',
    marginLeft: 8,
  },
  tabContainer: {
    flex: 1,
  },
  tabWrapper: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginTop: screenHeight * 0.03,
    paddingBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.darkGray,
  },
  tab: {
    paddingTop: 10,
    alignItems: 'center',
  },
  tabText: {
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(15),
    lineHeight: 18,
    textAlign: 'center',
  },
  selectedTab: {
    width: '80%',
    height: 3,
    backgroundColor: '#af3c3f',
    marginTop: 5,
  },
  locationList: {
    width: '100%',
    marginTop: 10,
  },
  locationListContent: {
    paddingBottom: Platform.OS === 'ios' ? screenHeight * 0.1 : screenHeight < 640 ? screenHeight * 0.115 : screenHeight * 0.1,
  },
  padBottom: {
    paddingBottom: screenHeight * 0.1 + screenHeight * 0.09,
  },
  saveBar: {
    position: 'absolute',
    width: screenWidth,
    height: screenHeight,
    bottom:
      Platform.OS === 'web'
        ? -screenHeight * 0.255
        : Platform.OS === 'ios'
          ? screenHeight < 740
            ? screenHeight * 0.005
            : 0
          : screenHeight < 641
            ? screenHeight * 0.0095
            : screenHeight * 0.015,
    left: -9,
  },
  saveBarSet: {
    position: 'absolute',
    width: screenWidth,
    bottom:
      Platform.OS === 'web'
        ? -screenHeight * 0.255
        : Platform.OS === 'ios'
          ? screenHeight < 740
            ? screenHeight * 0.008
            : 0
          : screenHeight < 641
            ? screenHeight * 0.0205
            : screenHeight * 0.0145,
    left: -11,
  },
  accessory: {
    width: containerWidth,
    height: 48,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    paddingHorizontal: 8,
  },
});

export default FindAStore;

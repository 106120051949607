import Text from '@components/Text';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AppStackRoutes, RootTabRoutes } from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { lineHeight, scale, screenWidth } from '@styles/constants';
import React, { FC } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Icon } from '../';

type SEDollarsAlertProps = {
  closeAction: () => void;
};

const SEDollarsAlert: FC<SEDollarsAlertProps> = ({ closeAction }) => {
  const navigation = useNavigation();
  const viewWallet = () => {
    navigation.navigate(AppStackRoutes.RootTabs, { screen: RootTabRoutes.Wallet, params: { showBack: false } });
  };

  return (
    <View style={styles.containerBackground}>
      <View style={styles.container}>
        <View style={styles.confettiLeft}>
          <Icon style={styles.cornerDots} size={550} stroke={colors.alertOrange} name={'quarter-circle'} />
        </View>
        <View style={styles.iconWrapper}>
          <Icon size={46} name={'se-dollars-in-wallet'} stroke={colors.alertRed} />
        </View>
        <View style={styles.textWrapper}>
          <Text style={styles.mainText}>You Have Something{'  '}</Text>
          <Text style={styles.mainText}>Extra Dollars to Spend!</Text>
        </View>
        <Button onPress={viewWallet} title="View Wallet" type="secondary" size="small" textStyle={styles.walletButtonText} buttonStyle={styles.walletButton}>
          {/* <Text style={[styles.mainText, styles.walletText]}>
            View Wallet
          </Text> */}
        </Button>
      </View>
      <View style={styles.leftBottomBorder} />
      <View style={styles.rightBottomBorder} />
      <View style={styles.closeWrapper}>
        <TouchableOpacity style={styles.closeButton} onPress={closeAction}>
          <Icon name={'x-close'} size={17} style={styles.closeIcon} stroke={colors.alertCloseGray} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerBackground: {
    backgroundColor: colors.alertOrange,
  },
  container: {
    width: screenWidth,
    height: 70,
    backgroundColor: colors.alertYellow,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderTopRightRadius: 40,
  },
  walletButtonText: {
    color: colors.white,
    borderColor: 'transparent',
    fontFamily: FontFamily.LarsseitExtraBold,
    fontSize: scale(11),
  },
  walletButton: {
    backgroundColor: colors.alertRed,
    minWidth: 40,
    maxWidth: 110,
    height: 32,
    margin: 0,
    marginRight: 20,
    shadowColor: 'transparent',
    borderColor: 'transparent',
  },
  rightArrow: {
    alignSelf: 'center',
    transform: [{ translateY: Platform.OS === 'ios' ? 9 : 12 }, { translateX: 2 }],
  },
  walletLink: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeWrapper: {
    position: 'absolute',
    right: 8,
    top: 6,
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 40,
    // backgroundColor: 'red',
  },
  closeButton: {
    width: 40,
    height: 40,
    paddingTop: 8,
    paddingLeft: 16,
  },
  closeIcon: {
    // position: 'absolute',
    // right: 8,
    // top: 8,
  },
  iconWrapper: {
    width: 50,
    height: '100%',
    paddingTop: 9,
    marginLeft: 10,
    // backgroundColor: 'gold',
  },
  textWrapper: {
    marginLeft: 0,
    marginRight: 12,
  },
  cornerDots: {
    height: '100%',
    paddingTop: 16,
    left: -245,
  },
  confettiLeft: {
    alignItems: 'flex-start',
    width: '100%',
    position: 'absolute',
  },
  confettiRight: {
    position: 'absolute',
    right: 35,
    top: -2,
    height: 50,
    width: 50,
  },
  leftBottomBorder: {
    position: 'absolute',
    bottom: -2,
    left: 0,
    height: 2,
    backgroundColor: colors.red,
    width: screenWidth * 0.04,
  },
  rightBottomBorder: {
    position: 'absolute',
    bottom: -2,
    right: 0,
    height: 2,
    backgroundColor: colors.red,
    width: screenWidth * 0.8,
  },
  mainText: {
    fontFamily: FontFamily.LarsseitExtraBold,
    color: colors.alertRed,
    fontSize: scale(14),
    lineHeight: lineHeight(16),
  },
  walletText: {
    color: 'orange',
    fontSize: scale(14),
  },
});

export default SEDollarsAlert;

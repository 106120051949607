import { CloseButton, NavigationHeader, ProductCard, Screen, Text } from '@components';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Product } from '@fieldera-raleys/client-commercetools/schema';
import { PagedArray } from '@fieldera-raleys/client-common';
import { ProductList } from '@fieldera-raleys/client-common/utils';
import { useRefetchOnFocus } from '@hooks';
import { AppStackRoutes, AppStackScreenProps } from '@navigation/routes';
import brsmService from '@services/brsm/brsmService';
import { useShopStore } from '@store';
import { appStyles } from '@styles';
import { scale, screenWidth } from '@styles/constants';
import { getProductPromotions } from '@utils/productHelper';
import { getOffersAndCouponsByProductList } from '@utils/promotionHelper';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, ListRenderItemInfo, RefreshControl, StyleSheet, View } from 'react-native';
import Config from 'react-native-config';

type ProductCarouselScreenProps = AppStackScreenProps<AppStackRoutes.ProductCarouselList>;

const ProductCarouselListScreen = ({ route }: ProductCarouselScreenProps) => {
  const { widgetType, widgetId, widgetParams, actionType = 'cart', itemId } = route.params;
  const { selectedStore } = useShopStore();
  const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch, isRefetching } = brsmService.useGetWidgetData(
    widgetType,
    widgetId,
    widgetParams,
    true,
    100,
  );
  const [productList, setProductList] = useState<ProductList[]>([]);

  useEffect(() => {
    const getProdPromos = async () => {
      let products: Product[] = [];
      let updatedProductList: ProductList[] = [];

      if (data && data.pages && data.pages.length > 0) {
        const skus = data?.pages[data.pages.length - 1].products.data.flatMap((p) => {
          products.push(p);
          return p.masterData.current?.masterVariant.sku ?? '';
        });

        const promotions = await getOffersAndCouponsByProductList(selectedStore?.number ?? Config.BRSM_DEFAULT_STORE, skus);
        updatedProductList = getProductPromotions(promotions, products);

        setProductList([...productList, ...updatedProductList]);
      }
    };

    getProdPromos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedStore]);

  const { t } = useTranslation('itemsYouMayLike');

  useRefetchOnFocus(refetch);
  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<ProductList>) => {
      return (
        <>
          <ProductCard product={item.product} productPromotion={item.promotion} actionType={actionType} itemId={itemId} index={index} />
        </>
      );
    },
    [actionType, itemId],
  );

  const renderItemSepatator = useCallback(() => <View style={styles.separator} />, []);
  const handleEndReached = async () => !isFetchingNextPage && fetchNextPage();
  let title,
    titleStyle = {};
  if (actionType === 'substitute') {
    title = 'Suggested Backup Items';
    titleStyle = { ...appStyles.h3B, paddingLeft: 20, paddingTop: 5 };
  }

  const renderEmptyListComponent = useCallback(
    () => (
      <View style={styles.emptyListContainer}>
        <Text testID="noData" style={styles.emptyListText}>
          {t('emptyListMsg')}
        </Text>
      </View>
    ),
    [t],
  );

  return (
    <>
      <Screen headerVisible={true}>
        <NavigationHeader
          style={[styles.chekoutHeaderStyle]}
          title={title}
          subStyle={[{}]}
          titleStyle={[{ margin: 10 }, titleStyle]}
          icon={<CloseButton style={{}} />}
        />

        <FlatList
          testID="productsList"
          data={productList}
          keyExtractor={(_, idx) => idx.toString()}
          renderItem={renderItem}
          numColumns={2}
          ItemSeparatorComponent={renderItemSepatator}
          refreshing={isRefetching}
          refreshControl={
            <RefreshControl refreshing={isLoading} onRefresh={() => refetch<PagedArray<Product>>({ refetchPage: (page) => page.offset === 0 })} />
          }
          onEndReached={handleEndReached}
          onEndReachedThreshold={0.5}
          ListEmptyComponent={renderEmptyListComponent}
          ListFooterComponent={<>{isFetchingNextPage && <ActivityIndicator />}</>}
        />
      </Screen>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chekoutHeaderStyle: {
    borderBottomColor: '#B4B4B4',
    backgroundColor: colors.sectionHeader,
    shadowOpacity: 0,
  },
  separator: {
    backgroundColor: colors.darkCream,
    height: 1.5,
    width: screenWidth * 0.92,
    transform: [{ translateX: (screenWidth * 0.08) / 2 }],
  },
  emptyListContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '80%',
    paddingTop: 30,
  },
  emptyListText: {
    fontFamily: FontFamily.Larsseit,
    fontSize: scale(15),
    color: colors.darkText,
    lineHeight: 15,
    width: '75%',
    textAlign: 'center',
    paddingBottom: 20,
  },
});

export default ProductCarouselListScreen;

import colors from '@config/colors';
import { fontSize } from '@config/typography';
import { appStyles } from '@styles';
import { aspectRatio } from '@styles/constants';
import React, { PropsWithChildren } from 'react';
import { Animated, StyleProp, StyleSheet, TextStyle, TouchableHighlight, View, ViewStyle } from 'react-native';
import { ImageStyle, Source } from 'react-native-fast-image';
import { Swipeable } from 'react-native-gesture-handler';
import Icon, { IconProps } from '../Icon';
import Text from '../Text';
import { Image } from '../image';

type ListItemProps = {
  title: string | JSX.Element;
  titleStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  subTitle?: string;
  itemStyle?: StyleProp<TextStyle>;
  image?: Source | number;
  imageStyle?: StyleProp<ImageStyle>;
  iconProps?: IconProps;
  testID?: string;
  subTitleStyle?: StyleProp<TextStyle>;
  subTextNumberOfLines?: number;
  onPress?: (event: any) => void;
  renderRightActions?: (
    progressAnimatedValue: Animated.AnimatedInterpolation<string | number>,
    dragAnimatedValue: Animated.AnimatedInterpolation<string | number>,
  ) => React.ReactNode;
  renderLeftActions?: (
    progressAnimatedValue: Animated.AnimatedInterpolation<string | number>,
    dragAnimatedValue: Animated.AnimatedInterpolation<string | number>,
  ) => React.ReactNode;
};

const ListItem = ({
  title,
  titleStyle,
  subTitle,
  itemStyle,
  image,
  imageStyle,
  iconProps,
  style,
  testID = '',
  subTitleStyle,
  onPress,
  renderRightActions,
  renderLeftActions,
  children,
  subTextNumberOfLines,
}: PropsWithChildren<ListItemProps>) => {
  return (
    <Swipeable
      testID="listSwipeable"
      renderRightActions={renderRightActions}
      renderLeftActions={renderLeftActions}
      friction={2}
      leftThreshold={80}
      enableTrackpadTwoFingerGesture
      rightThreshold={20}>
      <TouchableHighlight underlayColor={colors.light} onPress={onPress} testID={testID}>
        <View style={[styles.container, style]}>
          <View style={[styles.imageContainer]}>
            {iconProps && <Icon {...iconProps} />}
            {image && <Image style={[styles.image, imageStyle]} source={image} testID="listImage" />}
          </View>
          <View style={[styles.item, itemStyle]}>
            <Text style={[appStyles.bodyLeftBold, titleStyle]} numberOfLines={1} testID={testID ? testID : 'listTitleText'}>
              {title}
            </Text>
            {subTitle && (
              <Text
                testID="listSubTitleText"
                style={[appStyles.bodySmallLeftRegular, { fontSize: fontSize.TertiaryText }, subTitleStyle]}
                numberOfLines={subTextNumberOfLines ? 2 : subTextNumberOfLines}>
                {subTitle}
              </Text>
            )}
          </View>
          <View testID="children">{children}</View>
        </View>
      </TouchableHighlight>
    </Swipeable>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 10,
  },
  item: {
    flex: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  image: {
    width: 70,
    aspectRatio: aspectRatio.square,
    borderRadius: 35,
  },
  imageContainer: {
    height: 61,
    justifyContent: 'center',
  },
});

export default ListItem;

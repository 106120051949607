import { CloseButton, Icon, KeyboardAvoidingScreen, LinkButton, Modal, Text } from '@components';
import { ErrorMessage, Form, FormField, SubmitButton } from '@components/forms';
import appConstants from '@config/appConstants';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { useCardAnimation } from '@react-navigation/stack';
import { authService } from '@services/brandywine';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import helpers, { formatPhone, unformatPhone } from '@utils/helpers';
import logger from '@utils/logger';
import { FormikProps } from 'formik';
import React, { createRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Linking, Platform, ScrollView, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import * as Yup from 'yup';

type ForgotEmailScreenProps = AuthStackScreenProps<AuthStackRoutes.ForgotEmail>;

type ForgotEmailFormType = {
  somethingExtraNo: string;
  lastName: string;
  phoneNumber: string;
};

const ForgotEmailScreen = ({ navigation }: ForgotEmailScreenProps) => {
  const { t } = useTranslation('forgotEmailScreen');
  const formikRef = useRef<FormikProps<any>>(null);
  const somthingExtraNoRef = createRef<TextInput>();
  const lastNameRef = createRef<TextInput>();
  const phoneRef = createRef<TextInput>();
  const initialValue = useRef<ForgotEmailFormType>({
    somethingExtraNo: '',
    lastName: '',
    phoneNumber: '',
  });

  const { current } = useCardAnimation();
  const [forgotEmailFailed, setForgotEmailFailed] = useState(false);
  const [forgotEmailSuccess, setForgotEmailSuccess] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dropDownState, setDropDownState] = useState<{ phone: boolean; somethingExtraNo: boolean }>({
    phone: false,
    somethingExtraNo: false,
  });

  const validationSchema = Yup.object().shape(
    {
      lastName: Yup.string().required().label(t('lastName')),
      phoneNumber: Yup.string()
        .matches(helpers.PHONE_NUMBER_REGEX, t('invalidPhoneValidationMsg'))
        .label(t('phoneNumber'))
        .when('somethingExtraNo', {
          is: (somethingExtraNo: any) => {
            if (!dropDownState.phone && !dropDownState.somethingExtraNo) {
              setError(true);
              return true;
            }
            return !somethingExtraNo || somethingExtraNo.length === 0;
          },
          then: (schema) => schema.required(),
        }),
      somethingExtraNo: Yup.string()
        .label(t('loyaltyId'))
        .matches(helpers.SENUMBER_REGEX, t('loyaltyIdValidation'))
        .when('phoneNumber', {
          is: (phoneNumber: any) => {
            if (!dropDownState.phone && !dropDownState.somethingExtraNo) {
              setError(true);
              return true;
            }
            return !phoneNumber || phoneNumber.length === 0;
          },
          then: (schema) => schema.required(),
        }),
    },
    [['phoneNumber', 'somethingExtraNo']],
  );
  const onSubmit = async ({ somethingExtraNo, lastName, phoneNumber }: any) => {
    setIsButtonLoading(true);
    setForgotEmailFailed(false);

    try {
      let email = await authService.forgotEmail(somethingExtraNo, lastName, unformatPhone(phoneNumber) ?? '');
      email = email ?? '';
      if (email.length > 0 && helpers.EMAIL_REGEX.test(email)) {
        setUserEmail(email);
        somthingExtraNoRef.current?.clear();
        lastNameRef.current?.clear();
        phoneRef.current?.clear();
        setForgotEmailSuccess(true);
      }
    } catch (er) {
      logger.error(er);
      setForgotEmailFailed(true);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const renderResponseModal = () => {
    let header: string = t('error');
    let headerMsg: string = t('errorMessage');
    let headerSubMsg: string = t('tapOkText');
    let buttonText: string = t('okText');

    if (forgotEmailSuccess) {
      header = t('successModalHeader');
      headerMsg = `${t('emailUpdateMessage')}${userEmail}`;
      headerSubMsg = t('emailSuccessMsg');
      buttonText = t('backToLogin');
    }

    return (
      <Modal
        style={{ minHeight: screenHeight / 2 }}
        buttonContainerStyle={styles.modalButtonContainer}
        headerStyle={styles.modalHeaderStyle}
        contentStyle={styles.modalContentStyle}
        visible={forgotEmailFailed || forgotEmailSuccess}
        title={header}
        location="top"
        cancelButtonOnPress={() => {
          if (forgotEmailFailed) {
            setForgotEmailFailed(false);
          } else {
            navigation.navigate(AuthStackRoutes.SignIn);
          }
        }}
        cancelButtonText={buttonText}>
        <Text style={[appStyles.bodyMediumLight, styles.modalHeaderText]} testID={'modalMsg'}>
          <Text style={[appStyles.bodyMediumLight, styles.modalHeaderText, { color: forgotEmailFailed ? colors.red : colors.text }]} testID={'headerMsg'}>
            {headerMsg}
          </Text>
          {headerSubMsg}
          {t('needHelpModalText')}
          <LinkButton
            testID="FEErrorModalPhone"
            onPress={() => {
              setForgotEmailFailed(false);
              Linking.openURL(`tel:${appConstants.CUSTOMER_SERVICE_PHONE}`);
            }}
            style={styles.boldLinkLargeCenter}>
            {t('phone')}
          </LinkButton>
          {t('needHelpServiceText')}
        </Text>
      </Modal>
    );
  };
  return (
    <>
      <Animated.View
        testID={'forgotemailAnimatedView'}
        style={[
          styles.container,
          {
            transform: [
              {
                translateY: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [screenHeight * 0.65, 0],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}>
        <View style={styles.modal}>
          <View style={styles.header} testID={'closeBtnView'}>
            <CloseButton style={styles.closeButton} />
          </View>
          <KeyboardAvoidingScreen behavior="padding" keyboardVerticalOffset={50}>
            <ScrollView bounces={false} contentContainerStyle={[appStyles.container, styles.paddingHorizantal]}>
              <View style={styles.textWrapper}>
                <Text style={styles.h3BoldCenter} testID={'forgotEmailTitle'}>
                  {t('forgotEmailTitle')}
                </Text>
                <Text style={styles.bodyLargeCenter} testID={'forgotEmailHeaderMsg'}>
                  {t('forgotEmailHeaderMsg')}
                </Text>
                <Text style={[appStyles.bodyLargeLeft, styles.forgotEmailHeaderSubText]}>{t('forgotEmailSubTitle')}</Text>
              </View>
              <View style={[styles.accordiancontainer]}>
                <Form initialValues={initialValue.current} onSubmit={onSubmit} validationSchema={validationSchema} innerRef={formikRef}>
                  <View style={[styles.accordianMainView, { borderWidth: dropDownState.phone ? 2 : 1 }]}>
                    <View style={[styles.accordianheader]}>
                      <TouchableOpacity
                        testID={'phoneTextView'}
                        onPress={() => {
                          formikRef?.current?.resetForm();
                          setDropDownState({ somethingExtraNo: false, phone: true });
                          setError(false);
                        }}
                        hitSlop={{ bottom: 15, left: 15, right: 15, top: 15 }}>
                        <View style={{ flexDirection: 'row' }}>
                          <Icon
                            name={dropDownState.phone ? 'checked-circle' : 'unchecked-circle'}
                            stroke={dropDownState.phone ? colors.red : undefined}
                            strokeSecondary={colors.white}
                            fillSecondary={dropDownState.phone ? colors.red : undefined}
                            fill={dropDownState.phone ? colors.red : undefined}
                            size={24}
                            style={styles.checkIcon}
                            testID="phoneTextIcon"
                          />
                          <View style={[styles.loyaltyIdView, { paddingLeft: 10 }]}>
                            <Text style={[appStyles.bodyLeftBold, { fontSize: scale(16), lineHeight: lineHeight(16) }]} testID="phoneText">
                              {t('phoneText')}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    {dropDownState.phone && (
                      <View style={[styles.body]}>
                        <FormField
                          testID="phoneNumber"
                          ref={phoneRef}
                          label={t('phoneNumber')}
                          maxLength={14}
                          autoCapitalize="none"
                          autoCorrect={false}
                          keyboardType="phone-pad"
                          name="phoneNumber"
                          textContentType="telephoneNumber"
                          returnKeyType="done"
                          formatter={formatPhone}
                          hideLabel
                        />
                      </View>
                    )}
                  </View>
                  <View style={[styles.accordianMainView, styles.loyaltyId, { borderWidth: dropDownState.somethingExtraNo ? 2 : 1 }]}>
                    <View style={[styles.accordianheader, { height: scale(80) }]}>
                      <TouchableOpacity
                        testID="somethingExtra"
                        style={styles.loyaltyIdMainView}
                        onPress={() => {
                          formikRef?.current?.resetForm();
                          setDropDownState({ phone: false, somethingExtraNo: true });
                          setError(false);
                        }}
                        hitSlop={{ bottom: 15, left: 15, right: 15, top: 15 }}>
                        <View style={{ flexDirection: 'row' }}>
                          <Icon
                            name={dropDownState.somethingExtraNo ? 'checked-circle' : 'unchecked-circle'}
                            stroke={dropDownState.somethingExtraNo ? colors.red : undefined}
                            strokeSecondary={colors.white}
                            fillSecondary={dropDownState.somethingExtraNo ? colors.red : undefined}
                            fill={dropDownState.somethingExtraNo ? colors.red : undefined}
                            size={24}
                            style={[styles.checkIcon, { marginTop: 10 }]}
                            testID="somethingExtraNoIcon"
                          />
                          <View style={styles.loyaltyIdView}>
                            <Text
                              testID="somethingExtraText"
                              style={[appStyles.bodyLeftBold, { fontSize: scale(16), lineHeight: lineHeight(16) }]}
                              numberOfLines={1}>
                              {t('somethingExtraText')}
                            </Text>
                            <Text style={[appStyles.bodySmall, { textAlign: 'left' }]} testID="somethingExtraSubText">
                              {t('somethingExtraSubText')}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                    {dropDownState.somethingExtraNo && (
                      <View style={[styles.body]}>
                        <FormField
                          testID="somethingExtraNo"
                          autoCapitalize="none"
                          autoCorrect={false}
                          label={'somethingExtraNo'}
                          name={'somethingExtraNo'}
                          onSubmitEditing={() => {
                            lastNameRef.current?.focus();
                          }}
                          keyboardType="number-pad"
                          returnKeyType="done"
                          ref={somthingExtraNoRef}
                          maxLength={12}
                          hideLabel
                        />
                      </View>
                    )}
                  </View>
                  {error && <ErrorMessage error={t('errorMsg')} visible={!!error} style={{ marginTop: 5 }} testID={t('errorMsg')} />}
                  <FormField
                    testID="lastName"
                    label={t('lastName')}
                    autoCapitalize="none"
                    textContentType="familyName"
                    autoCorrect={false}
                    name="lastName"
                    returnKeyType="done"
                    ref={lastNameRef}
                  />
                  <View style={styles.buttonsContainer}>
                    <SubmitButton title="Submit" isButtonLoading={isButtonLoading} testID={'Submit'} />
                    <View style={styles.bottomBtnView}>
                      <LinkButton onPress={() => navigation.navigate(AuthStackRoutes.SignIn)} style={styles.boldLinkLargeCenter} testID="backToLogin">
                        {t('backToLogin')}
                      </LinkButton>
                      <LinkButton
                        testID="createNewAccountInitial"
                        onPress={() => navigation.navigate(AuthStackRoutes.CreateAccountInitial, {})}
                        style={styles.boldLinkLargeCenter}>
                        {t('createNewAccount')}
                      </LinkButton>
                    </View>
                  </View>
                </Form>
              </View>
            </ScrollView>
          </KeyboardAvoidingScreen>
        </View>
      </Animated.View>
      {forgotEmailFailed && renderResponseModal()}
      {forgotEmailSuccess && renderResponseModal()}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: Platform.select({
      ios: screenHeight * 0.95,
      android: screenHeight * 0.9,
    }),
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  modal: {
    flex: 1,
    alignContent: 'space-between',
  },
  header: {
    height: 50,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    textAlign: 'center',
  },
  closeButton: {
    left: 16,
    top: 16,
  },
  textWrapper: {
    paddingHorizontal: 20,
    paddingBottom: 8,
  },
  bodyLargeCenter: {
    ...appStyles.bodyLargeCenter,
    marginTop: 15,
    fontSize: scale(16),
  },
  modalButtonContainer: {
    bottom: screenHeight > 740 ? 18 : 10,
  },
  buttonsContainer: {
    marginTop: 8,
    marginBottom: 25,
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
  },
  boldLinkLargeCenter: {
    ...appStyles.boldLinkLargeCenter,
    marginTop: 10,
  },

  modalContentStyle: {
    width: screenWidth,
    paddingHorizontal: 16,
    paddingBottom: 8,
  },
  modalHeaderStyle: {
    justifyContent: 'center',
  },
  paddingHorizantal: { paddingHorizontal: 18 },
  accordiancontainer: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    top: 0,
  },
  accordianheader: {
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    paddingHorizontal: 5,
  },
  checkIcon: {
    height: 26,
    width: 26,
    justifyContent: 'center',
  },
  loyaltyIdView: {
    width: '90%',
    paddingLeft: 5,
    justifyContent: 'center',
  },
  accordianMainView: {
    borderRadius: 10,
    borderColor: colors.red,
  },
  modalHeaderText: { textAlign: 'center' },
  forgotEmailHeaderSubText: {
    marginTop: 20,
    fontFamily: FontFamily.LarsseitBold,
  },
  loyaltyIdMainView: {
    flex: 1,
    justifyContent: 'center',
  },
  bottomBtnView: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  loyaltyId: { marginTop: 20 },
});

export default ForgotEmailScreen;

import { StyleSheet } from 'react-native';
import { spacing } from './constants';
// spacer * .25 = 4, spacer * .5 = 8, spacer = 16 , spacer * 1.5 = 24 , spacer * 2 = 32

const utilityStyles = StyleSheet.create({
  m0: {
    margin: 0,
  },
  m1: {
    margin: spacing * 0.25,
  },
  m2: {
    margin: spacing * 0.5,
  },
  m3: {
    margin: spacing,
  },
  m4: {
    margin: spacing * 1.5,
  },
  m5: {
    margin: spacing * 2,
  },
  mx0: {
    marginHorizontal: 0,
  },
  mx1: {
    marginHorizontal: spacing * 0.25,
  },
  mx2: {
    marginHorizontal: spacing * 0.5,
  },
  mx3: {
    marginHorizontal: spacing,
  },
  mx4: {
    marginHorizontal: spacing * 1.5,
  },
  mx5: {
    marginHorizontal: spacing * 2,
  },
  my0: {
    marginVertical: 0,
  },
  my1: {
    marginVertical: spacing * 0.25,
  },
  my2: {
    marginVertical: spacing * 0.5,
  },
  my3: {
    marginVertical: spacing,
  },
  my4: {
    marginVertical: spacing * 1.5,
  },
  my5: {
    marginVertical: spacing * 2,
  },
  mt0: {
    marginTop: 0,
  },
  mt1: {
    marginTop: spacing * 0.25,
  },
  mt2: {
    marginTop: spacing * 0.5,
  },
  mt3: {
    marginTop: spacing,
  },
  mt4: {
    marginTop: spacing * 1.5,
  },
  mt5: {
    marginTop: spacing * 2,
  },
  mb0: {
    marginBottom: 0,
  },
  mb1: {
    marginBottom: spacing * 0.25,
  },
  mb2: {
    marginBottom: spacing * 0.5,
  },
  mb3: {
    marginBottom: spacing,
  },
  mb4: {
    marginBottom: spacing * 1.5,
  },
  mb5: {
    marginBottom: spacing * 2,
  },
  mla: {
    marginLeft: 'auto',
  },
  ml0: {
    marginLeft: 0,
  },
  ml1: {
    marginLeft: spacing * 0.25,
  },
  ml2: {
    marginLeft: spacing * 0.5,
  },
  ml3: {
    marginLeft: spacing,
  },
  ml4: {
    marginLeft: spacing * 1.5,
  },
  ml5: {
    marginLeft: spacing * 2,
  },
  mr0: {
    marginRight: 0,
  },
  mr1: {
    marginRight: spacing * 0.25,
  },
  mr2: {
    marginRight: spacing * 0.5,
  },
  mr3: {
    marginRight: spacing,
  },
  mr4: {
    marginRight: spacing * 1.5,
  },
  mr5: {
    marginRight: spacing * 2,
  },
  p0: {
    padding: 0,
  },
  p1: {
    padding: spacing * 0.25,
  },
  p2: {
    padding: spacing * 0.5,
  },
  p3: {
    padding: spacing,
  },
  p4: {
    padding: spacing * 1.5,
  },
  p5: {
    padding: spacing * 2,
  },
  px0: {
    paddingHorizontal: 0,
  },
  px1: {
    paddingHorizontal: spacing * 0.25,
  },
  px2: {
    paddingHorizontal: spacing * 0.5,
  },
  px3: {
    paddingHorizontal: spacing,
  },
  px4: {
    paddingHorizontal: spacing * 1.5,
  },
  px5: {
    paddingHorizontal: spacing * 2,
  },
  py0: {
    paddingVertical: 0,
  },
  py1: {
    paddingVertical: spacing * 0.25,
  },
  py2: {
    paddingVertical: spacing * 0.5,
  },
  py3: {
    paddingVertical: spacing,
  },
  py4: {
    paddingVertical: spacing * 1.5,
  },
  py5: {
    paddingVertical: spacing * 2,
  },
  pt0: {
    paddingTop: 0,
  },
  pt1: {
    paddingTop: spacing * 0.25,
  },
  pt2: {
    paddingTop: spacing * 0.5,
  },
  pt3: {
    paddingTop: spacing,
  },
  pt4: {
    paddingTop: spacing * 1.5,
  },
  pt5: {
    paddingTop: spacing * 2,
  },
  pb0: {
    paddingBottom: 0,
  },
  pb1: {
    paddingBottom: spacing * 0.25,
  },
  pb2: {
    paddingBottom: spacing * 0.5,
  },
  pb3: {
    paddingBottom: spacing,
  },
  pb4: {
    paddingBottom: spacing * 1.5,
  },
  pb5: {
    paddingBottom: spacing * 2,
  },
  pl0: {
    paddingLeft: 0,
  },
  pl1: {
    paddingLeft: spacing * 0.25,
  },
  pl2: {
    paddingLeft: spacing * 0.5,
  },
  pl3: {
    paddingLeft: spacing,
  },
  pl4: {
    paddingLeft: spacing * 1.5,
  },
  pl5: {
    paddingLeft: spacing * 2,
  },
  pr0: {
    paddingRight: 0,
  },
  pr1: {
    paddingRight: spacing * 0.25,
  },
  pr2: {
    paddingRight: spacing * 0.5,
  },
  pr3: {
    paddingRight: spacing,
  },
  pr4: {
    paddingRight: spacing * 1.5,
  },
  pr5: {
    paddingRight: spacing * 2,
  },
  bottomShadowWrapper: {
    paddingBottom: 3,
    overflow: 'hidden',
  },
});

export default utilityStyles;

import Text from '@components/Text';
import colors from '@config/colors';
import { Cart, Money, Order } from '@fieldera-raleys/client-commercetools/schema';
import { defaultMoney } from '@fieldera-raleys/client-commercetools/utils';
import { PaymentProfile } from '@fieldera-raleys/client-common/services/brandywine/types';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, scale, screenWidth } from '@styles/constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import {
  getCRVAmountMoney,
  getDeliveryFee,
  getDeliveryTipValue,
  getDonationAmountMoney,
  getDonationAmountValue,
  getQuantityTotal,
  getServiceFee,
  getSubTotalItemPrice,
  getTaxRate,
  getTaxTotal,
  isDelivery,
  moneyValue,
  taxedGrossValue,
} from '../../utils/orderHelpers';
import Icon from '../Icon';

const ITEM_SPACE = <></>;

interface InvoiceProps {
  obj: Cart | Order | undefined;
  backgroundColor?: string;
  title?: string;
  inTransit?: boolean;
  selectedPaymentMethod?: PaymentProfile;
  offlinePaymentMethod?: string;
}

const Invoice: React.FC<InvoiceProps> = (props) => {
  const { obj, title, inTransit = false, selectedPaymentMethod, offlinePaymentMethod } = props;
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTranslation('cart');
  let key = 0;
  const subLine = (txt: string | JSX.Element, val: string | number, style: StyleProp<TextStyle>) => (
    <View key={`subkey_${++key}`} style={[styles.row]}>
      <Text testID="sublineText" numberOfLines={1} adjustsFontSizeToFit={true} style={[style, styles.subtotalsUnit]}>
        {txt}
      </Text>
      <Text style={[style, styles.subtotalsDollar]}>{ITEM_SPACE}</Text>
      <Text testID="sublineValue" numberOfLines={1} adjustsFontSizeToFit={true} style={[style, styles.subtotalsPrice]}>
        {val}
      </Text>
    </View>
  );

  if (!obj) {
    return subLine('TOTAL', (0).toFixed(2), appStyles.bodySmall);
  }
  const isDel = isDelivery(obj);
  const taxRate = getTaxRate(obj);
  const deliveryTipValue = getDeliveryTipValue(obj);
  const donationValue = getDonationAmountValue(obj);
  const donationMoney = getDonationAmountMoney(obj);
  const crvMoney = getCRVAmountMoney(obj);

  const serviceFee = getServiceFee(obj);
  const deliveryFee = getDeliveryFee(obj);

  const promoSavings = obj?.customLineItems?.filter((p) => (p.slug ?? '').split('-')[0] === 'promo') ?? [];
  let cardFourDigit = selectedPaymentMethod?.CardNumber?.substring(selectedPaymentMethod?.CardNumber.lastIndexOf('-') + 1) ?? '';
  const promos = promoSavings.reduce(
    (acc, pe) => {
      const promoValue = pe.custom?.customFieldsRaw?.find((f) => f.name === 'promotionSavings')?.value ?? defaultMoney;
      acc.push({
        description: pe.name ?? 'Savings',
        value: promoValue,
      });

      return acc;
    },
    [] as { description: string; value: Money }[],
  );
  const savingsOf = promos.reduce((sum, p) => (sum += p.value.centAmount), 0);
  const mapPromos = (pset?: { description: string; value: Money }[]) => {
    if (pset && pset.length) {
      return pset.map((p, i) => (
        <View key={`pk${i}`} style={[{ flexDirection: 'row', width: containerWidth }, utilityStyles.my1]}>
          <Text style={[appStyles.bodySmallRegular, { color: colors.red }, utilityStyles.px2]} />
          <Text testID="buyTwoGetOneText" ellipsizeMode={'tail'} numberOfLines={1} style={[appStyles.bodySmallLeftRegular, { color: colors.red, flex: 0.9 }]}>
            {p.description}
          </Text>
          <Text testID="buyTwoGetOneValue" style={[appStyles.bodySmallLeftRegular, { marginLeft: 'auto', color: colors.red }]}>
            &minus;&nbsp;{moneyValue(p.value)}
          </Text>
        </View>
      ));
    }

    return [];
  };
  const taxTotal = getTaxTotal(obj) ?? 0;
  const itemsSubtotal = moneyValue(getSubTotalItemPrice(obj));
  const invoceItems: JSX.Element[] = [
    <View key={'invocieTotals_Root'} style={{ paddingTop: 20, paddingBottom: 20, flexDirection: 'column' }}>
      {subLine(title ? title : ['Items (', getQuantityTotal(obj), ')'].join(''), itemsSubtotal ? itemsSubtotal : '', appStyles.bodyLeftBold)}
      {taxRate
        ? subLine(`Estimated Tax ${Math.ceil(+taxRate) === 0 && +taxTotal > 0 ? '' : `(${taxRate}%)`}`, taxTotal ?? '', appStyles.bodySmallRegular)
        : null}
      {crvMoney && crvMoney.centAmount > 0 ? subLine('CRV', `${moneyValue(crvMoney)}`, appStyles.bodySmallRegular) : null}
      {serviceFee ? subLine('Service Fee', `${serviceFee}`, appStyles.bodySmallRegular) : null}
      {isDel && deliveryFee ? subLine('Delivery Fee', `${deliveryFee}`, appStyles.bodySmallRegular) : null}
      {isDel && deliveryTipValue && subLine(t('deliveryTip'), `${deliveryTipValue}`, appStyles.bodySmallRegular)}
      {donationValue && donationMoney && donationMoney.centAmount > 0 ? subLine(t('fffHeader'), `${donationValue}`, appStyles.bodySmallRegular) : null}
      {!inTransit && promoSavings.length > 0 && (
        <TouchableOpacity
          onPress={() => {
            setIsOpen(!isOpen);
          }}>
          <View style={[styles.row]}>
            <View style={[styles.row, { alignSelf: 'flex-start' }]}>
              <Text
                testID="savings"
                numberOfLines={2}
                adjustsFontSizeToFit={true}
                style={[appStyles.bodyLeftBold, styles.subtotalsUnit, { color: colors.red }]}>
                ESTIMATED SAVINGS
                <Icon
                  testID="savingsIcon"
                  name={isOpen ? 'triangle-up' : 'triangle-down'}
                  size={15}
                  stroke={colors.darkText}
                  fill={colors.darkText}
                  style={[appStyles.smallIcon, { paddingLeft: 5 }]}
                />
              </Text>
              <Text testID="estimatedSavingsValue" style={[appStyles.fontMobileBodySmallBald, { marginLeft: 'auto', color: colors.red }]}>
                &minus;&nbsp;{moneyValue({ ...defaultMoney, centAmount: savingsOf })}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      )}
      {isOpen ? mapPromos(promos) : <></>}

      {subLine(t('estimatedTotal'), taxedGrossValue(obj, '') ?? '', appStyles.bodyLeftBold)}
      {selectedPaymentMethod ? subLine('Payment Method', '', [appStyles.bodyLeftBold, utilityStyles.mt3]) : null}
      {selectedPaymentMethod
        ? subLine(
            <View style={{ width: containerWidth, flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text style={[appStyles.bodySmallRegular]}>Credit Card</Text>
              <Text style={[appStyles.bodySmallRegular]}>{`${(selectedPaymentMethod?.CardType ?? '').substring(0, 10)} ...${cardFourDigit}`} </Text>
            </View>,
            '',
            {},
          )
        : subLine(offlinePaymentMethod ?? '', '', [appStyles.bodySmallRegular, { marginTop: -8 }])}
    </View>,
  ];

  return (
    <View style={[styles.mainContainer, props.backgroundColor ? { backgroundColor: props.backgroundColor } : null]}>
      <View style={[styles.subContainer, props.backgroundColor ? { backgroundColor: props.backgroundColor } : null]}>{invoceItems}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'column',
    backgroundColor: colors.sectionPad,
    width: screenWidth,
  },
  subContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    width: containerWidth,
    backgroundColor: colors.sectionPad,
  },
  row: {
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 6,
    alignSelf: 'flex-start',
  },
  subtotalsPrice: {
    textAlign: 'right',
    flex: 0.15,
    minWidth: scale(40),
    alignSelf: 'flex-end',
  },
  subtotalsDollar: {
    marginLeft: 'auto',
    textAlign: 'right',

    alignSelf: 'flex-end',
  },
  subtotalsUnit: {
    flex: 1,
    alignSelf: 'flex-end',
  },
  unitSeparator: {
    width: 0.03,
    marginRight: 5,
    marginLeft: 8,
    backgroundColor: colors.cream,
    alignSelf: 'flex-end',
  },
  marginTopAuto: {
    marginTop: 'auto',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  notePad: {
    backgroundColor: colors.darkCream,
    padding: scale(12),
    width: containerWidth,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    alignSelf: 'center',
    marginBottom: 20,
  },
});

export default Invoice;

import { createStackNavigator } from '@react-navigation/stack';
import {
  CategoriesScreen,
  CategoryScreen,
  CollectionListingScreen,
  CollectionsLandingScreen,
  DigitalCouponsScreen,
  ProductListScreen,
  SearchLandingScreen,
  ShelfGuideCategoryDetailsScreen,
  ShelfGuideHelpScreen,
  ShelfGuideListScreen,
  ShopScreen,
  WeeklyAdsCollectionScreen,
  WeeklyAdsScreen,
} from '@screens/shop';
import React from 'react';
import { ShopStackParamList, ShopStackRoutes } from './routes';

const ShopStack = createStackNavigator<ShopStackParamList>();

const ShopStackNavigator = () => {
  return (
    <ShopStack.Navigator initialRouteName={ShopStackRoutes.ShopLanding} screenOptions={{ animationEnabled: false, headerShown: false, presentation: 'card' }}>
      <ShopStack.Screen name={ShopStackRoutes.Categories} component={CategoriesScreen} />
      <ShopStack.Screen name={ShopStackRoutes.ShopLanding} component={ShopScreen} initialParams={{ reload: false }} />
      <ShopStack.Screen name={ShopStackRoutes.Category} component={CategoryScreen} />
      <ShopStack.Screen name={ShopStackRoutes.CollectionsLanding} component={CollectionsLandingScreen} />
      <ShopStack.Screen name={ShopStackRoutes.CollectionListing} component={CollectionListingScreen} />
      <ShopStack.Screen name={ShopStackRoutes.ProductList} component={ProductListScreen} />
      <ShopStack.Screen name={ShopStackRoutes.SearchLanding} component={SearchLandingScreen} />
      <ShopStack.Screen name={ShopStackRoutes.ShelfGuide} component={ShelfGuideListScreen} />
      <ShopStack.Screen
        name={ShopStackRoutes.WeeklyAdsCollection}
        component={WeeklyAdsCollectionScreen}
        initialParams={{ productCollectionId: 0 }}
        options={{ presentation: 'modal', animationEnabled: true }}
      />
      <ShopStack.Screen name={ShopStackRoutes.WeeklyAds} component={WeeklyAdsScreen} />
      <ShopStack.Screen name={ShopStackRoutes.DigitalCoupons} component={DigitalCouponsScreen} />
      <ShopStack.Screen
        name={ShopStackRoutes.ShelfGuideCategoryDetails}
        component={ShelfGuideCategoryDetailsScreen}
        options={{ presentation: 'modal', animationEnabled: true }}
      />
      <ShopStack.Screen name={ShopStackRoutes.ShelfGuideHelp} component={ShelfGuideHelpScreen} options={{ presentation: 'modal', animationEnabled: true }} />
    </ShopStack.Navigator>
  );
};

export default ShopStackNavigator;

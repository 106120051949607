import { CloseButton, DropShadow, KeyboardAvoidingScreen, RadioButton } from '@components';
import KeyboardCloseButton from '@components/KeyboardCloseButton';
import Text from '@components/Text';
import { ErrorMessage, Form, FormField, FormSwitch, SubmitButton } from '@components/forms';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { LoyaltyValidationResult } from '@fieldera-raleys/client-common';
import { validateEmail } from '@fieldera-raleys/client-common/utils';
import { AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { useCardAnimation } from '@react-navigation/stack';
import { authService } from '@services/brandywine';
import { appStyles } from '@styles';
import { containerWidth, lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import helpers from '@utils/helpers';
import { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Platform, ScrollView, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import * as Yup from 'yup';

type CreateAccountInitialFormType = {
  email: string;
  haveSE: boolean;
  seNumberCard: string;
  seNumberRewards: string;
  lastName: string;
};

type CreateAccountInitialScreenProps = AuthStackScreenProps<AuthStackRoutes.CreateAccountInitial>;

const CreateAccountInitialScreen = ({ navigation, route }: CreateAccountInitialScreenProps) => {
  const { errorMsg } = route.params || '';
  const initialValue = useRef<CreateAccountInitialFormType>({
    email: '',
    haveSE: false,
    seNumberCard: '',
    seNumberRewards: '',
    lastName: '',
  });
  const { t } = useTranslation('createAccount');
  const { current } = useCardAnimation();
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasSE, setHasSE] = useState<boolean>(false);
  const [seType, setSeType] = useState<string>('');
  const emailRef = createRef<TextInput>();
  const lastNameRef = createRef<TextInput>();
  const scrollViewRef = createRef<ScrollView>();
  const [modalHeight, setModalHeight] = useState<number>(0.6);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required()
      .test('email', function (value) {
        if (value) {
          return validateEmail(value);
        }
        return false;
      })
      .label(t('validationEmailLabel')),
    seNumberCard: Yup.string().test('required-if-selected-card', t('cardNumberValidation'), function (value) {
      if (hasSE && seType === 'card' && !value) {
        return false;
      }
      return true;
    }),
    seNumberRewards: Yup.string().test('required-if-selected-rewards', t('rewardsNumberValidation'), function (value) {
      if (hasSE && seType === 'rewards' && !value) {
        return false;
      }
      return true;
    }),
    lastName: Yup.string()
      .test('required-if-se', t('lastNameValidation'), function (value) {
        if (hasSE && !value) {
          return false;
        }
        return true;
      })
      .test('no-special-character', t('nameSpecialCharacterValidation') ?? '', function (value) {
        if (!hasSE) {
          return true;
        }

        if (value) {
          const specialCharacterRegex = helpers.SpecialCharacter;
          let isValidName = !specialCharacterRegex.test(value);
          return isValidName;
        }
        return false;
      }),
  });

  const handleSubmit = async (formData: CreateAccountInitialFormType) => {
    setIsLoading(true);
    setHasError(false);
    setErrorMessage('');

    const { email, haveSE, seNumberRewards: seNumberRewards, seNumberCard: seNumberCard, lastName } = formData;

    try {
      const emailExists = await authService.checkEmailExists(email.trim());
      if (emailExists) {
        setErrorMessage(t('emailExistsValidation'));
        setHasError(true);
        return;
      }

      let loyaltyValidation: LoyaltyValidationResult = { validationStatus: 3, statusDescription: '', extLoyaltyId: '' };

      if (haveSE) {
        let loyaltyInfo =
          seType === 'rewards'
            ? {
                lastName: lastName.trim(),
                extLoyaltyId: seNumberRewards.trim(),
                extLoyaltyCardId: '',
              }
            : {
                lastName: lastName.trim(),
                extLoyaltyId: '',
                extLoyaltyCardId: seNumberCard.trim(),
              };
        loyaltyValidation = (await authService.validateLoyaltyInformation(loyaltyInfo.lastName, loyaltyInfo.extLoyaltyId, loyaltyInfo.extLoyaltyCardId)) ?? {
          validationStatus: 3,
          extLoyaltyId: '',
          statusDescription: '',
        };

        if (loyaltyValidation.validationStatus === 2) {
          // MatchedAlreadyInUse = 2
          setErrorMessage(t('seNumberMatchedInUse'));
          setHasError(true);
          return;
        } else if (loyaltyValidation.validationStatus === 3) {
          // NotMatched = 3
          setErrorMessage(t('seNumberNotMatched'));
          setHasError(true);
          return;
        }
      }

      // MatchedValidForNewAccount = 1

      navigation.navigate(AuthStackRoutes.CreateAccount, {
        willComplete: haveSE,
        email: email.trim(),
        seValidationNumber: seType === 'rewards' ? seNumberRewards : seType === 'card' ? seNumberCard : '',
        seNumber: loyaltyValidation.extLoyaltyId,
        seType: seType,
        lastName: lastName,
      });
    } catch (error) {
      let message = (error as Error).message;
      if (message.toLowerCase().indexOf('loyalty') >= 0) {
        message = message.replace(/loyaltyid/gi, 'Something Extra Number');
      }
      setErrorMessage(message);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hasError && !isLoading) {
      scrollViewRef.current?.scrollTo({
        y: 0,
        animated: true,
      });
    }
  }, [hasError, isLoading, scrollViewRef]);

  useEffect(() => {
    if (errorMsg) {
      setErrorMessage(errorMsg);
      setHasError(true);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (hasSE) {
      setModalHeight(0.8);
    } else {
      setModalHeight(screenHeight > 740 ? 0.68 : 0.7);
    }
  }, [hasSE]);

  const renderCreateAccountInitial = () => {
    return (
      <KeyboardAvoidingScreen
        keyboardVerticalOffset={
          screenHeight > 926 ? 185 : screenHeight > 844 ? 180 : screenHeight > 812 ? 170 : screenHeight > 736 ? 160 : screenHeight > 667 ? 145 : 130
        }
        behavior="padding"
        style={{ backgroundColor: 'transparent' }}>
        <ScrollView contentContainerStyle={[appStyles.container, styles.paddingHorizontal]} bounces={false} ref={scrollViewRef}>
          <Form initialValues={initialValue.current} onSubmit={handleSubmit} validationSchema={validationSchema}>
            <ErrorMessage error={errorMessage} visible={hasError} style={[styles.textCenter, styles.errorMsg]} testID="errorMessage" />
            <FormField
              testID="email"
              label={t('emailLabel')}
              name={'email'}
              ref={emailRef}
              autoCapitalize={'none'}
              autoCorrect={false}
              keyboardType="email-address"
              textContentType="emailAddress"
              inputAccessoryViewID="Close"
            />
            <KeyboardCloseButton />
            <View style={styles.switchView}>
              <FormSwitch name="haveSE" onChange={() => setHasSE(!hasSE)} testID="haveSE" />
              <Text style={styles.switchText} testID="alreadyHaveSEText">
                {t('alreadyHaveSE')}
              </Text>
            </View>
            {hasSE ? (
              <View>
                <Text style={[appStyles.fontMobileBodySmallLeft, styles.topSpacing]} testID="provideMsg">
                  {t('provideMsg')}
                </Text>
                <TouchableOpacity
                  testID="rewardsClick"
                  onPress={() => setSeType('rewards')}
                  style={[styles.choiceView, seType === 'rewards' ? styles.selectedBorder : null]}>
                  <View style={styles.flexRow}>
                    <RadioButton toggle={() => setSeType('rewards')} buttonSize={25} buttonStyle={[appStyles.mediumIcon]} value={seType === 'rewards'} />
                    <View>
                      <Text style={[appStyles.fontMobileBodySmallLeft, styles.choiceHeader]} testID="SEMsg">
                        {t('yourNumber', { type: 'Rewards ' })}
                      </Text>
                      <Text style={[styles.choiceText]} testID="yourNumberDescRewards">
                        {t('yourNumberDescRewards')}
                      </Text>
                    </View>
                  </View>
                  {seType === 'rewards' ? (
                    <FormField
                      testID="seNumberRewards"
                      name={'seNumberRewards'}
                      hideLabel={true}
                      autoCapitalize={'none'}
                      containerStyle={styles.paddingX}
                      keyboardType={'number-pad'}
                      returnKeyType={'done'}
                      onSubmitEditing={() => lastNameRef.current?.focus()}
                    />
                  ) : null}
                </TouchableOpacity>
                <TouchableOpacity
                  testID="cardClick"
                  onPress={() => setSeType('card')}
                  style={[styles.choiceView, seType === 'card' ? styles.selectedBorder : null]}>
                  <View style={styles.flexRow}>
                    <RadioButton
                      toggle={() => {
                        setSeType('card');
                      }}
                      buttonSize={25}
                      buttonStyle={[appStyles.mediumIcon]}
                      value={seType === 'card'}
                    />
                    <View>
                      <Text style={[appStyles.fontMobileBodySmallLeft, styles.choiceHeader]} testID="yourNumbertext">
                        {t('yourNumber', { type: 'Card ' })}
                      </Text>
                      <Text style={[styles.choiceText]} testID="yourNumberDescCardText">
                        {t('yourNumberDescCard')}
                      </Text>
                    </View>
                  </View>
                  {seType === 'card' ? (
                    <FormField
                      testID="seNumberCard"
                      name="seNumberCard"
                      hideLabel={true}
                      autoCapitalize={'none'}
                      containerStyle={styles.paddingX}
                      keyboardType={'number-pad'}
                      returnKeyType={'done'}
                      onSubmitEditing={() => lastNameRef.current?.focus()}
                    />
                  ) : null}
                </TouchableOpacity>
                <FormField name="lastName" label={'And Your Last Name'} ref={lastNameRef} labelStyle={styles.formLabelStyle} testID="lastName" />
              </View>
            ) : null}
            <SubmitButton title="Continue" isButtonLoading={isLoading} testID="continueBtn" />
          </Form>
        </ScrollView>
      </KeyboardAvoidingScreen>
    );
  };

  return (
    <>
      <Animated.View
        style={[
          styles.container,
          {
            height: Platform.select({
              ios: screenHeight * modalHeight,
              android: screenHeight * 0.68,
            }),
          },
          {
            transform: [
              {
                translateY: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [screenHeight * modalHeight, 0],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}>
        <View style={styles.modal}>
          <DropShadow style={[styles.dropshadowContainer]}>
            <View style={styles.header}>
              <CloseButton style={styles.closeButton} />
            </View>
            <Text style={styles.h3BoldCenter} testID="headerTitle">
              {t('headerTitle')}
            </Text>
          </DropShadow>
          {renderCreateAccountInitial()}
        </View>
      </Animated.View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    // height: Platform.select({
    //   ios: screenHeight * 0.8,
    //   android: screenHeight * 0.68,
    // }),
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  dropshadowContainer: {
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexFull: {
    flex: 1,
    flexWrap: 'wrap',
  },
  paddingX: {
    paddingHorizontal: 10,
  },
  textCenter: {
    textAlign: 'center',
  },
  errorMsg: {
    lineHeight: lineHeight(18),
  },
  switchView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  switchText: {
    flex: 1,
    paddingTop: 15,
    fontSize: scale(14),
  },
  modal: {
    flex: 1,
    alignContent: 'space-between',
    paddingBottom: 20,
  },
  header: {
    height: 50,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    textAlign: 'center',
  },
  closeButton: {
    left: 16,
    top: 16,
  },
  paddingHorizontal: { paddingHorizontal: 20 },
  needHelpText: {
    fontSize: scale(15),
    fontFamily: FontFamily.LarsseitLight,
    lineHeight: lineHeight(15),
  },
  buttonsContainer: {
    marginTop: 8,
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
  },
  boldLinkLargeCenter: {
    ...appStyles.boldLinkLargeCenter,
    marginTop: 10,
  },
  checkBoxInfoText: {
    flex: 1,
    marginTop: Platform.select({ ios: 10 }),
    fontFamily: FontFamily.Larsseit,
    color: colors.darkText,
    fontSize: scale(15),
    lineHeight: lineHeight(14),
  },
  helpModalButton: {
    width: 150,
  },
  modalHeight: {
    height:
      Platform.OS === 'ios' ? (screenHeight > 740 ? screenHeight * 0.32 : screenHeight * 0.36) : screenHeight > 640 ? screenHeight * 0.3 : screenHeight * 0.4,
  },
  comfirmationModalTextInfo: {
    fontSize: scale(16),
    top: -14,
  },
  comfirmationModalTitle: {
    fontSize: scale(26),
  },
  paddingBottom: { paddingBottom: -10 },
  textInfo: {
    fontSize: scale(18),
    color: colors.primary,
    textAlign: 'center',
    lineHeight: lineHeight(20),
    fontFamily: FontFamily.LarsseitLight,
  },
  subTextStyle: {
    marginVertical: 0,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  acceptTermsView: {
    width: Platform.select({ android: containerWidth * 0.87 }),
  },
  topSpacing: {
    marginTop: 30,
  },
  choiceView: {
    marginVertical: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.sectionBorder,
    borderRadius: 5,
  },
  selectedBorder: {
    borderColor: colors.red,
    borderWidth: 2,
  },
  choiceHeader: {
    fontSize: scale(14),
    lineHeight: lineHeight(16),
    color: colors.text,
  },
  choiceText: {
    maxWidth: scale(260),
    fontFamily: FontFamily.LarsseitLight,
    color: colors.text,
    fontSize: scale(12),
    lineHeight: lineHeight(14),
  },
  formLabelStyle: {
    color: colors.text,
    fontFamily: FontFamily.LarsseitBold,
    fontSize: scale(16),
    lineHeight: lineHeight(18),
  },
});

export default CreateAccountInitialScreen;

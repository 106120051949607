import { AddLineItem, LineItemCustomFieldName, ShoppingList, ShoppingListLineItem, TextLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { ProductType } from '@fieldera-raleys/client-common';
import { shoppingListService } from '@services/commerceTools/me';
import logger from '@utils/logger';
import { Mutex } from '@utils/semaphore';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import asyncStorage from './asyncStorage';
import { ShoppingListsStore } from './storeTypes';

export enum ShoppingListType {
  MSL = 'My Shopping List',
  MFL = 'My Favorites',
  SFL = 'Saved For Later',
}

const useShoppingListsStore = create<ShoppingListsStore>()(
  persist(
    (set, get) => ({
      shoppingLists: [] as ShoppingList[],
      saveForLaterList: undefined as ShoppingList | undefined,
      groupBy: 'category',
      status: 'loading',
      initialize: async (priceChannelId?: string, availablityChannelIds?: string[], signal?: AbortSignal): Promise<void> => {
        try {
          let shoppingLists = await shoppingListService.getShoppingLists({ priceChannelId, availablityChannelIds }, signal);

          if (shoppingLists.length) {
            shoppingLists = shoppingLists.filter((shl) => !!shl.name);

            if (!shoppingLists.some((l) => l.name === ShoppingListType.MSL)) {
              let added = await shoppingListService.createShoppingList({ listName: ShoppingListType.MSL }, signal);
              shoppingLists.push(added);
            }
            if (!shoppingLists.some((li) => li.name === ShoppingListType.MFL)) {
              let added = await shoppingListService.createShoppingList({ listName: ShoppingListType.MFL }, signal);
              shoppingLists.push(added);
            }
            if (!shoppingLists.some((li) => li.name === ShoppingListType.SFL)) {
              let added = await shoppingListService.createShoppingList({ listName: ShoppingListType.SFL }, signal);
              shoppingLists.push(added);
            }
          } else {
            let added: ShoppingList;
            added = await shoppingListService.createShoppingList({ listName: ShoppingListType.MFL }, signal);
            shoppingLists.push(added);
            added = await shoppingListService.createShoppingList({ listName: ShoppingListType.MSL }, signal);
            shoppingLists.push(added);
            added = await shoppingListService.createShoppingList({ listName: ShoppingListType.SFL }, signal);
            shoppingLists.push(added);
          }
          set(
            (state) => ({
              ...state,
              shoppingLists: shoppingLists.filter((l) => l.name !== ShoppingListType.SFL),
              saveForLaterList: shoppingLists.find((l) => l.name === ShoppingListType.SFL),
              status: 'success',
            }),
            true,
          );
        } catch (ex) {
          logger.error(ex, { shoppingList: 'initialize' });
          set((state) => ({ ...state, shoppingLists: [], status: 'error' }));
        }
      },
      isInList: (productSku: string) => {
        const lists = get().shoppingLists;
        if (lists) {
          return lists.some((list: ShoppingList) => list.lineItems.some((li) => li.variant?.sku === productSku));
        } else {
          return false;
        }
      },
      setGroupBy: (value) => {
        set((state) => ({ ...state, groupBy: value }));
      },
      deleteList: async (id: string, storeKey?: string, key?: string) => {
        return await Mutex.withMutex(async () => {
          if (!id) {
            return;
          }
          const current = get().shoppingLists;
          if (!current) {
            return;
          }
          let updated = current?.find((l) => l?.id === id);
          if (updated) {
            let newLists = current;
            await shoppingListService.deleteShoppingList(updated.version, id, storeKey, key);
            let indx = newLists.findIndex((li: ShoppingList) => li.id === id);
            newLists.splice(indx, 1);
            set((state) => ({ ...state, shoppingLists: newLists }), true);
          } else {
            logger.warn('Lists not initialized');
          }
        }, 'deleteList');
      },
      addTextLineItemToList: async (listId: string, title: string, description: string = '') => {
        if (!title) {
          return;
        }
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('addTextLineItemToList: List does not exist');
            }
            const lineitem = current?.textLineItems?.find((x: TextLineItem) => (x?.name ?? '') === title);
            if (lineitem) {
              return current;
            }
            const updated = await shoppingListService.addTextLineItemToShoppingList(current.id, current.version, title, description);
            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));

            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'addTextLineItemToList');
      },
      addItemToList: async (listId: string, productKey: string, quantity: number = 1) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('addItemToList: List does not exist');
            }
            const lineItem = current.lineItems.find((x: ShoppingListLineItem) => x.variant?.sku === productKey);
            if (lineItem) {
              throw new Error('lineItem exists');
            }
            const updated = await shoppingListService.addToShoppingList(current.id, current.version, productKey, quantity);
            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));

            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'addItemToList');
      },
      addCustomizableItemToList: async (listId: string, lineItems: AddLineItem[]) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('addCustomizableListLineItem: List does not exist');
            }
            //some crazy logic need to re-think
            // 1. add the parent to the cart
            // 2. get id of the added parent
            // 3. set parent-id on children
            // 4. save children
            // 5. update parent custom attrubute with pipe seperated child-ids -- forn now saving the child product keys to the parent on step 1
            // hope everything saved as its not wrapped in a transaction
            const parent = lineItems.find((x) => !x.parentLineItemId);
            if (!parent) {
              throw new Error('Failed to find parent sku');
            }

            var updated: ShoppingList | undefined;
            updated = await shoppingListService.addToShoppingList(current.id, current.version, parent.sku);

            // find the new item, operation is versioned so new items should be guaranteed
            const parentLineItem = (updated?.lineItems ?? []).find((x) => (current?.lineItems ?? []).findIndex((cc) => cc.id === x.id) === -1);
            if (!parentLineItem) {
              // this should never be the case on success, but checking to make sure
              throw new Error('addCustomizableListLineItem: Cannot idenitfy newly added item');
            }

            // NOTE: after this, need to come up with how to handle erros as it will break the custom product
            if ((parentLineItem?.productType?.name ?? ProductType.UNCKNOWN) === ProductType.CONFIGURABLE) {
              const children = lineItems.filter((x) => x.parentLineItemId && x.parentLineItemId === parent?.sku);
              children.forEach((x) => {
                x.parentLineItemId = parentLineItem!.id;
              });
              updated = await shoppingListService.addItemsToList(updated.id, updated.version, children, []);
              // find all children for given parent id
              const childLineItems = updated?.lineItems.filter((x) => {
                const customField = x.custom?.customFieldsRaw?.find((a) => a.name === 'parentLineItemId');
                return customField?.value === parentLineItem!.id;
              });
              if (updated) {
                updated = await shoppingListService.setListLineItemCustomField(updated.id, updated.version, parentLineItem!.id, [
                  { name: 'childLineItems', value: childLineItems?.map((ct) => ct.id) },
                ]);
              }
              if (!updated) {
                throw new Error('Failed to add Customizable ListLineItem');
              }

              set((state) => ({
                ...state,
                shoppingLists: updated!.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated!] : state.shoppingLists,
                saveForLaterList: updated!.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
              }));

              return parent.sku;
            } else {
              throw new Error('cannot idenitfy primary sku');
            }
          } catch (e: any) {
            //TODO: rollback logic
            logger.error(e);
            throw e;
          }
        }, 'addCustomizableListLineItem');
      },
      removeItemFromList: async (listId: string, lineItemId: string) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('setShoppingListLineItemQuantity: List does not exist');
            }

            const lineItem = current.lineItems.find((x: ShoppingListLineItem) => x.id === lineItemId);
            if (!lineItem) {
              throw new Error('no lineItem found');
            }

            const updated = await shoppingListService.removeFromShoppingList(current.id, current.version, lineItem.id);

            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));

            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'removeItemFromList');
      },
      removeTextItemFromList: async (listId: string, name: string) => {
        return await Mutex.withMutex(async () => {
          try {
            if (!name) {
              return;
            }
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('setShoppingListLineItemQuantity: List does not exist');
            }

            const lineItem = current.textLineItems.find((x: TextLineItem) => x.name === name);
            if (!lineItem) {
              throw new Error('no lineItem found');
            }
            const updated = await shoppingListService.removeTextItemFromShoppingList(current.id, current.version, lineItem.id);
            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));

            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'removeTextItemFromList');
      },
      setLineItemQuantity: async (listId: string, lineItemId: string, quantity: number) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('setShoppingListLineItemQuantity: List does not exist');
            }

            const lineItem: ShoppingListLineItem | undefined = current.lineItems.find((x) => x.id === lineItemId);
            if (!lineItem) {
              throw new Error('no lineItem found');
            }

            const updated = await shoppingListService.setListLineItemQuantity(current.id, current.version, lineItem.id, quantity, []); //fields
            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));

            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'setLineItemQuantity');
      },
      setTextLineItemQuantity: async (listId: string, lineItemId: string, quantity: number) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('setShoppingListLineItemQuantity: List does not exist');
            }

            const lineItem: TextLineItem | undefined = current.textLineItems.find((x) => x.id === lineItemId);

            if (!lineItem) {
              throw new Error('invalid productKey, either name or id is required');
            }

            const updated = await shoppingListService.setListTextLineItemQuantity(current.id, current.version, lineItem.id, quantity);
            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));

            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'setTextLineItemQuantity');
      },
      setLineItemCustomField: async (listId: string, lineItemId: string, field: { name: LineItemCustomFieldName; value: string | boolean }) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('setShoppingListLineItemChecked: List does not exist');
            }

            const lineItem: ShoppingListLineItem | undefined = current.lineItems.find((x) => x.id === lineItemId);
            if (!lineItem) {
              throw new Error('invalid lineItemId');
            }

            const setField = lineItem?.custom ? shoppingListService.setListLineItemCustomField : shoppingListService.setListLineItemCustomType;
            //setListLineItemCustomType

            const updated = await setField(current.id, current.version, lineItem.id, [field]);
            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));

            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'setLineItemCustomField');
      },
      setTextLineItemCustomField: async (listId: string, lineItemId: string, field: { name: LineItemCustomFieldName; value: string | boolean }) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('setShoppingListTextLineItemChecked: List does not exist');
            }

            const lineItem = current.textLineItems.find((x) => x.id === lineItemId);
            if (!lineItem) {
              throw new Error('invalid productKey, either lineItemId or sku is required');
            }

            const setField = lineItem?.custom ? shoppingListService.setListTextLineItemCustomField : shoppingListService.setListTextLineItemCustomType;
            const updated = await setField(current.id, current.version, lineItem.id, [field]);
            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));

            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'setTextLineItemCustomField');
      },
      unCheckAll: async (listId: string, lineItemIds: string[], textLineItemIds: string[]) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('setShoppingListLineItemChecked: List does not exist');
            }
            const updated = await shoppingListService.unCheckAllItems(current.id, current.version, lineItemIds, textLineItemIds);
            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));

            return updated;
          } catch (err) {
            logger.error(err);
            return undefined;
          }
        }, 'unCheckAll');
      },
      addItemsToList: async (listId: string, lineItems: AddLineItem[], textLineItemTitles: string[]) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('addItemsToList: List does not exist');
            }
            let updated: ShoppingList;
            if (lineItems.some((li) => li?.parentLineItemId)) {
              const parent = lineItems.find((x) => !x.parentLineItemId);
              if (parent) {
                // let updatedList: Cart | undefined;
                updated = await shoppingListService.addItemsToList(current.id, current.version, [parent], []);

                // find the new item, operation is versioned so new items should be guaranteed
                const parentLineItem = (updated?.lineItems ?? []).find((x) => (current?.lineItems ?? []).findIndex((cc) => cc.id === x.id) === -1);
                if (!parentLineItem) {
                  // this should never be the case on success, but checking to make sure
                  throw new Error('addLineItem: Cannot idenitfy newly added item');
                }
                // NOTE: after this, need to come up with how to handle erros as it will break the custom product
                if ((parentLineItem?.productType?.name ?? ProductType.UNCKNOWN) === ProductType.CONFIGURABLE) {
                  const children = lineItems.filter((x) => x.parentLineItemId && x.parentLineItemId === parent?.sku);
                  children.forEach((x) => {
                    x.parentLineItemId = parentLineItem!.id;
                  });
                  updated = await shoppingListService.addItemsToList(updated?.id, updated?.version, children, []);
                  // find all children for given parent id
                  const childLineItems = updated.lineItems.filter((x) => {
                    const customField = x.custom?.customFieldsRaw?.find((a) => a.name === 'parentLineItemId');
                    return customField?.value === parentLineItem!.id;
                  });

                  updated = await shoppingListService.setListLineItemCustomField(updated.id, updated.version, parentLineItem!.id, [
                    { name: 'childLineItems', value: childLineItems.map((ct) => ct.id) },
                  ]);
                }
                const standardItems = lineItems.filter((li) => !li.parentLineItemId && li.sku !== parentLineItem.variant?.sku);
                if (standardItems) {
                  updated = await shoppingListService.addItemsToList(updated?.id, updated?.version, standardItems, []);
                }
                // return parentLineItem.id;
              } else {
                throw new Error('cannot idenitfy primary sku');
              }
            } else {
              updated = await shoppingListService.addItemsToList(
                listId,
                current.version,
                lineItems.map((lis: AddLineItem) => {
                  return {
                    sku: lis.sku ?? '',
                    quantity: lis.quantity ?? 1,
                    parentLineItemId: lis.parentLineItemId,
                    childLineItems: lis.childLineItems,
                    customStepSort: lis.customStepSort,
                    itemNote: lis.itemNote,
                  };
                }),
                textLineItemTitles,
              );
            }

            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));
            return updated;
          } catch (err) {
            logger.error(err);
            return undefined;
          }
        }, 'addItemsToList');
      },
      removeItemsFromList: async (listId: string, lineItemIds: string[], textLineItemIds: string[]) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('removeItemsFromList: List does not exist');
            }

            if (current.lineItems?.some((li) => li.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId'))) {
              const selections = current.lineItems?.filter((li) => li.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId'));
              selections.forEach((sel) => {
                if (lineItemIds.includes(sel.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId')?.value)) {
                  lineItemIds.push(sel.id);
                }
              });
            }

            const updated = await shoppingListService.removeItemsFromList(listId, current.version, lineItemIds, textLineItemIds);

            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));
            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'removeItemsFromList');
      },
      clearList: async (listId: string) => {
        return await Mutex.withMutex(async () => {
          try {
            const current = get().saveForLaterList?.id === listId ? get().saveForLaterList : get().shoppingLists.find((li) => li.id === listId);
            if (!current) {
              throw new Error('clearList: List does not exist');
            }

            const updated = await shoppingListService.clearShoppingList(
              current.id,
              current.version,
              current.lineItems.map((li) => li.id),
              current.textLineItems.map((ti) => ti.id),
            );

            set((state) => ({
              ...state,
              shoppingLists: updated.name !== ShoppingListType.SFL ? [...state.shoppingLists.filter((l) => l.id !== listId), updated] : state.shoppingLists,
              saveForLaterList: updated.name === ShoppingListType.SFL ? updated : state.saveForLaterList,
            }));
            return updated;
          } catch (err) {
            logger.log(err);
            return undefined;
          }
        }, 'clearList');
      },
    }),
    {
      name: 'shopping-lists', // unique name
      storage: createJSONStorage<ShoppingListsStore>(() => asyncStorage), // (optional) by default the 'localStorage' is used
    },
  ),
);

export default useShoppingListsStore;

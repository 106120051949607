import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { Category } from '@fieldera-raleys/client-common/types/category';
import { toCamelCase } from '@fieldera-raleys/client-common/utils';
import { AppStackRoutes, RootTabRoutes, ShopStackRoutes } from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';
import { utilityStyles } from '@styles';
import appStyles from '@styles/appStyles';
import { lineHeight, scale } from '@styles/constants';
import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Icon, Text } from '../index';
import { ListItem } from '../lists';

interface CategoryProps {
  data?: Category[];
  isRootCategory: boolean;
  categoryId: string;
}
const CategoryList = ({ data, isRootCategory }: CategoryProps) => {
  const navigation = useNavigation();

  const handleListItemOnPress = useCallback(
    (item: Category) => {
      if (isRootCategory) {
        navigation.dispatch(
          StackActions.push(AppStackRoutes.RootTabs, {
            screen: RootTabRoutes.Shop,
            params: { screen: ShopStackRoutes.Category, params: { categoryId: item.id } },
          }),
        );
      } else {
        navigation.navigate(AppStackRoutes.RootTabs, {
          screen: RootTabRoutes.Shop,
          params: { screen: ShopStackRoutes.ProductList, params: { rootCategoryId: item.rootId, categoryId: item.id } },
        });
      }
    },
    [isRootCategory, navigation],
  );

  const renderItem = useCallback(
    ({ item, idx }: { item: Category; idx: number }) => {
      return (
        <ListItem
          style={[styles.listItem, utilityStyles.px3]}
          title={item.name}
          titleStyle={styles.title}
          itemStyle={styles.itemStyle}
          onPress={() => handleListItemOnPress(item)}
          key={`listItem-${toCamelCase(item.name)}_${idx}`}>
          <View key={`view-${toCamelCase(item.name)}_${idx}`}>
            <Icon name="arrow-right" size={20} key={`icon-${toCamelCase(item.name)}_${idx}`} />
          </View>
        </ListItem>
      );
    },
    [handleListItemOnPress],
  );
  return (
    <View style={styles.container}>
      <View style={styles.list} testID="CategoryList">
        {data !== undefined && data.length > 0 ? (
          data.map((item: Category, idx: number) => renderItem({ item, idx }))
        ) : (
          <View style={styles.infoBox}>
            <Text style={[styles.titleText, {}]} testID="CategoryListTitle">
              Product Category Coming Soon
            </Text>
            <Button
              title={'Ways to Shop'}
              type="secondary"
              onPress={() =>
                navigation.navigate(AppStackRoutes.RootTabs, {
                  screen: RootTabRoutes.Shop,
                  params: { screen: ShopStackRoutes.Categories },
                })
              }
              textStyle={[appStyles.secondaryButtonSmallText]}
              buttonStyle={[styles.button]}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  listItem: {
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
  },
  title: {
    paddingLeft: 0,
  },
  itemStyle: {
    marginLeft: 0,
  },
  container: {
    backgroundColor: colors.cream,
  },
  list: {
    flex: 1,
  },
  infoBox: {
    marginTop: 100,
    paddingHorizontal: 32,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  titleText: {
    color: colors.text,
    fontSize: scale(32),
    fontFamily: FontFamily.AleoBold,
    lineHeight: lineHeight(32),
    textAlign: 'center',
  },
  description: {
    marginTop: 20,
    color: colors.text,
    fontFamily: FontFamily.LarsseitLight,
    fontSize: scale(20),
    lineHeight: lineHeight(20),
    textAlign: 'center',
  },
  link: {
    fontFamily: FontFamily.AleoBold,
    marginTop: 50,
    textAlign: 'center',
  },
  linkText: {
    color: colors.text,
    fontSize: 16,
    fontFamily: FontFamily.LarsseitBold,
    textDecorationLine: 'underline',
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
  },
  button: {
    minWidth: '50%',
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
  },
});

export default CategoryList;

import { PagedArray } from '@fieldera-raleys/client-common';
import { Promotion, Voucher } from '@fieldera-raleys/client-common/services/brandywine/types';
import { offerService } from '@services/brandywine';
import Cache from '@utils/cache';
import logger from '@utils/logger';
import { clearPromotionCache } from '@utils/promotionHelper';
import { CancelToken } from 'axios';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import asyncStorage from './asyncStorage';
import { LoadingStatus, OffersStore } from './storeTypes';

const useOffersStore = create<OffersStore>()(
  persist(
    (set, get) => {
      return {
        availableSomethingExtraOffers: [] as Promotion[],
        acceptedSomethingExtraOffers: [] as Promotion[],
        availableWeeklyExclusiveOffers: [] as Promotion[],
        acceptedWeeklyExclusiveOffers: [] as Promotion[],
        availableDigitalCoupons: [] as Promotion[],
        acceptedDigitalCoupons: [] as Promotion[],
        availableVouchers: [] as Voucher[],
        acceptedVouchers: [] as Voucher[],
        redeemedVouchers: [] as Voucher[],
        nonTargetedOffers: [] as Promotion[],
        offersState: 'loading' as LoadingStatus,
        somethingExtraOffersState: 'loading' as LoadingStatus,
        nonTargetedOffersState: 'loading' as LoadingStatus,
        weeklyExclusiveOffersState: 'loading' as LoadingStatus,
        vouchersState: 'loading' as LoadingStatus,
        refreshingVouchers: false as boolean,
        digitalCouponsState: 'loading' as LoadingStatus,
        initialize: async (cts?: CancelToken) => {
          get().fetchSomethingExtraOffers(cts);
          get().fetchWeeklyExclusiveOffers(cts);
          get().fetchDigitalCoupons(cts);
          get().fetchVouchers(cts);
          get().fetchNonTargetedOffers(cts);
        },
        fetchOffers: async (cts?: CancelToken) => {
          get().fetchSomethingExtraOffers(cts);
          get().fetchWeeklyExclusiveOffers(cts);
          get().fetchDigitalCoupons(cts);
          get().fetchNonTargetedOffers(cts);
        },
        fetchOfferByOfferId: async (offerId: number) => {
          return Cache.getItem(`promotion:${offerId}`, () => offerService.getCustomerPromotionById(offerId), 30);
        },
        fetchNonTargetedOffers: async (cts?: CancelToken) => {
          return offerService
            .getNonTargeted('app', '', 0, 999, undefined, cts)
            .then((results) => {
              results.data.sort((a, b) => a.SortOrder - b.SortOrder);
              set((state) => ({ ...state, nonTargetedOffers: results.data, offersState: 'success', nonTargetedOffersState: 'success' }));
              return results.data;
            })
            .catch((ex) => {
              ex.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', ex);
              set((state) => ({
                ...state,
                nonTargetedOffers: [],
                offersState: ex.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
                nonTargetedOffersState: ex.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
              }));
              return [];
            });
        },
        fetchSomethingExtraOffers: async (cts?: CancelToken) => {
          return Promise.all<PagedArray<Promotion> | Error>([
            offerService.getTargeted('app', 'available', '', 0, 999, 'SomethingExtra', cts).catch((ex) => ex),
            offerService.getTargeted('app', 'accepted', '', 0, 999, 'SomethingExtra', cts).catch((ex) => ex),
          ])
            .then((results) => {
              let available = 'data' in results[0] ? results[0].data.sort((a, b) => a.SortOrder - b.SortOrder) : [],
                accepted = 'data' in results[1] ? results[1].data.sort((a, b) => a.SortOrder - b.SortOrder) : [];

              set((state) => ({
                ...state,
                availableSomethingExtraOffers: available,
                acceptedSomethingExtraOffers: accepted,
                offersState: results.every((x) => 'data' in x) ? 'success' : 'error',
                somethingExtraOffersState: results.every((x) => 'data' in x) ? 'success' : 'error',
              }));
              return { available, accepted };
            })
            .catch((error: any) => {
              error.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', error);
              set((state) => ({
                ...state,
                availableSomethingExtraOffers: [],
                acceptedSomethingExtraOffers: [],
                offersState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
                somethingExtraOffersState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
              }));

              return { available: [], accepted: [] };
            });
        },
        fetchWeeklyExclusiveOffers: async (cts?: CancelToken) => {
          return Promise.all<PagedArray<Promotion> | Error>([
            offerService.getTargeted('app', 'available', '', 0, 999, 'WeeklyExclusive', cts),
            offerService.getTargeted('app', 'accepted', '', 0, 999, 'WeeklyExclusive', cts),
          ])
            .then((results) => {
              let available = 'data' in results[0] ? results[0].data.sort((a, b) => a.SortOrder - b.SortOrder) : [],
                accepted = 'data' in results[1] ? results[1].data.sort((a, b) => a.SortOrder - b.SortOrder) : [];
              set((state) => ({
                ...state,
                availableWeeklyExclusiveOffers: available,
                acceptedWeeklyExclusiveOffers: accepted,
                offersState: results.every((x) => 'data' in x) ? 'success' : 'error',
                weeklyExclusiveOffersState: results.every((x) => 'data' in x) ? 'success' : 'error',
              }));
              return { available, accepted };
            })
            .catch((error) => {
              error.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', error);
              set((state) => ({
                ...state,
                availableWeeklyExclusiveOffers: [],
                acceptedWeeklyExclusiveOffers: [],
                offersState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
                weeklyExclusiveOffersState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
              }));
              return { available: [], accepted: [] };
            });
        },
        fetchDigitalCoupons: async (cts?: CancelToken) => {
          return Promise.all<PagedArray<Promotion> | Error>([
            offerService.getCoupons('app', 'available', '', 0, 999, undefined, cts).catch((ex) => ex),
            offerService.getCoupons('app', 'accepted', '', 0, 999, undefined, cts).catch((ex) => ex),
          ])
            .then((results) => {
              let available = 'data' in results[0] ? results[0].data.sort((a, b) => a.SortOrder - b.SortOrder) : [],
                accepted = 'data' in results[1] ? results[1].data.sort((a, b) => a.SortOrder - b.SortOrder) : [];
              set((state) => ({
                ...state,
                availableDigitalCoupons: available,
                acceptedDigitalCoupons: accepted,
                digitalCouponsState: results.every((x) => 'data' in x) ? 'success' : 'error',
              }));
              return { available, accepted };
            })
            .catch((error: any) => {
              error.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', error);
              set((state) => ({
                ...state,
                availableDigitalCoupons: [],
                acceptedDigitalCoupons: [],
                digitalCouponsState: error.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
              }));
              return { available: [], accepted: [] };
            });
        },
        fetchVouchers: async (cts?: CancelToken) => {
          return Promise.all<PagedArray<Voucher> | Error>([
            offerService.getVouchers('app', 'available', 0, 999, cts).catch((err) => err),
            offerService.getVouchers('app', 'accepted', 0, 999, cts).catch((err) => err),
            offerService.getVouchers('app', 'redeemed', 0, 999, cts).catch((err) => err),
          ])
            .then((results) => {
              let available = 'data' in results[0] ? results[0].data : [],
                accepted = 'data' in results[1] ? results[1].data : [],
                redeemed = 'data' in results[2] ? results[2].data : [];
              set((state) => ({
                ...state,
                availableVouchers: available,
                acceptedVouchers: accepted,
                redeemedVouchers: redeemed,
                vouchersState: results.every((x) => 'data' in x) ? 'success' : 'error',
              }));
              return { available, accepted, redeemed };
            })
            .catch((ex) => {
              ex.message !== 'CANCEL_ERROR' && logger.warn('failed to get vouchers', ex);
              set((state) => ({
                ...state,
                availableVouchers: [],
                acceptedVouchers: [],
                redeemedVouchers: [],
                vouchersState: ex.message !== 'CANCEL_ERROR' ? 'error' : 'loading',
              }));
              return { available: [], accepted: [], redeemed: [] };
            });
        },
        acceptOffer: async (offerId: number | number[], cts?: CancelToken) => {
          let acceptedIds = Array.isArray(offerId) ? offerId : [offerId];
          let available = [...get().availableSomethingExtraOffers, ...get().availableWeeklyExclusiveOffers];
          let offers = available.filter((x) => acceptedIds.includes(Number(x.ExtPromotionId)));
          if (!offers.length) {
            //refetch
            let somethingExtra = await get().fetchSomethingExtraOffers(cts);
            let weeklyExclusive = await get().fetchWeeklyExclusiveOffers(cts);
            available = [...somethingExtra.available, ...weeklyExclusive.available];
            offers = available.filter((x) => acceptedIds.includes(Number(x.ExtPromotionId)));
            if (!offers.length) {
              return;
            }
          }
          const result = await offerService.acceptOffers(acceptedIds);
          if (result) {
            let accepted = [...get().acceptedSomethingExtraOffers, ...get().acceptedWeeklyExclusiveOffers];
            accepted.unshift(...offers);

            set((state) => ({
              ...state,
              availableSomethingExtraOffers: available.filter(
                (x) => x.ExtBadgeTypeCode === 'SomethingExtra' && !acceptedIds.includes(Number(x.ExtPromotionId)),
              ),
              acceptedSomethingExtraOffers: accepted.filter((x) => x.ExtBadgeTypeCode === 'SomethingExtra'),
              availableWeeklyExclusiveOffers: available.filter(
                (x) => x.ExtBadgeTypeCode === 'WeeklyExclusive' && !acceptedIds.includes(Number(x.ExtPromotionId)),
              ),
              acceptedWeeklyExclusiveOffers: accepted.filter((x) => x.ExtBadgeTypeCode === 'WeeklyExclusive'),
            }));
            clearPromotionCache(acceptedIds.map(String)).catch((ex) => logger.error(ex));
          }
        },
        unacceptOffer: async (offerId: number) => {
          const accepted = [...get().acceptedSomethingExtraOffers, ...get().acceptedWeeklyExclusiveOffers];
          const offer = accepted.find((x) => Number(x.ExtPromotionId) === offerId);
          if (!offer) {
            return;
          }

          const result = await offerService.unacceptOffer(offerId);
          if (result) {
            const available = [...get().availableSomethingExtraOffers, ...get().availableWeeklyExclusiveOffers];
            available.unshift(offer);

            set((state) => ({
              ...state,
              availableSomethingExtraOffers: available.filter((x) => x.ExtBadgeTypeCode === 'SomethingExtra'),
              acceptedSomethingExtraOffers: accepted.filter((x) => x.ExtBadgeTypeCode === 'SomethingExtra' && offerId !== Number(x.ExtPromotionId)),
              availableWeeklyExclusiveOffers: available.filter((x) => x.ExtBadgeTypeCode === 'WeeklyExclusive'),
              acceptedWeeklyExclusiveOffers: accepted.filter((x) => x.ExtBadgeTypeCode === 'WeeklyExclusive' && offerId !== Number(x.ExtPromotionId)),
            }));
            clearPromotionCache([offerId].map(String)).catch((ex) => logger.error(ex));
          }
        },
        acceptVoucher: async (offerId: number | number[]) => {
          const acceptedIds = Array.isArray(offerId) ? offerId : [offerId];
          const available = get().availableVouchers;
          const accepted = get().acceptedVouchers;
          const voucher = available.filter((x) => acceptedIds.includes(Number(x.ExtPromotionId)));
          if (!voucher.length) {
            return;
          }
          set((state) => ({ ...state, refreshingVouchers: true }));
          const result = await offerService.acceptOffers(acceptedIds);
          if (result) {
            accepted.unshift(...voucher);
            set((state) => ({
              ...state,
              availableVouchers: available.filter((x) => !acceptedIds.includes(Number(x.ExtPromotionId))),
              acceptedVouchers: accepted,
            }));
          }
        },
        unacceptVoucher: async (offerId: number) => {
          const accepted = get().acceptedVouchers;
          const available = get().availableVouchers;
          const voucher = accepted.find((x) => +x.ExtPromotionId === offerId);
          if (!voucher) {
            return;
          }
          set((state) => ({ ...state, refreshingVouchers: true }));
          const result = await offerService.unacceptOffer(offerId);
          if (result) {
            available.unshift(voucher);
            set((state) => ({
              ...state,
              availableVouchers: available,
              acceptedVouchers: accepted.filter((x) => Number(x.ExtPromotionId) !== offerId),
            }));
          }
        },
        acceptCoupon: async (offerId: number | number[]) => {
          const acceptedIds = Array.isArray(offerId) ? offerId : [offerId];
          const available = get().availableDigitalCoupons;
          const offers = available.filter((x) => acceptedIds.includes(Number(x.ExtPromotionId)));
          if (!offers.length) {
            return;
          }
          try {
            const result = await offerService.acceptCoupon(acceptedIds);
            if (result) {
              const accepted = get().acceptedDigitalCoupons;
              accepted.unshift(...offers);

              set((state) => ({
                ...state,
                availableDigitalCoupons: available.filter((x) => !acceptedIds.includes(Number(x.ExtPromotionId))),
                acceptedDigitalCoupons: accepted,
              }));
              clearPromotionCache(acceptedIds.map(String)).catch((ex) => logger.error(ex));
            }
          } catch (ex: any) {
            ex.message !== 'CANCEL_ERROR' && logger.warn('failed to get offers', ex);
            if (ex.message !== 'CANCEL_ERROR') {
              throw ex;
            }
          }
        },
        acceptPromo: async (promoCode: string) => {
          offerService
            .acceptPromo(promoCode)
            .then(() => {
              return true;
            })
            .catch((ex: any) => {
              ex.message !== 'CANCEL_ERROR' && logger.warn(`failed to accept promo ${promoCode ?? 'undefined'}`, ex);
            });
        },
        unAcceptPromo: async (promoCode: string) => {
          return offerService
            .unAcceptPromo(promoCode)
            .then(() => {
              return true;
            })
            .catch((ex: any) => {
              ex.message !== 'CANCEL_ERROR' && logger.warn(`failed to unaccept promo ${promoCode ?? 'undefined'}`, ex);
              return false;
            });
        },
        clearOffersStore: () => {
          set((state) => ({
            ...state,
            availableSomethingExtraOffers: [] as Promotion[],
            acceptedSomethingExtraOffers: [] as Promotion[],
            availableWeeklyExclusiveOffers: [] as Promotion[],
            acceptedWeeklyExclusiveOffers: [] as Promotion[],
            availableDigitalCoupons: [] as Promotion[],
            acceptedDigitalCoupons: [] as Promotion[],
            availableVouchers: [] as Voucher[],
            acceptedVouchers: [] as Voucher[],
            redeemedVouchers: [] as Voucher[],
            offersState: 'loading',
            vouchersState: 'loading',
            digitalCouponsState: 'loading',
          }));
        },
      };
    },
    {
      name: 'uc-offers', // unique name
      storage: createJSONStorage<OffersStore>(() => asyncStorage), // (optional) by default the 'localStorage' is used
    },
  ),
);

export default useOffersStore;

import { useLinkTo } from '@react-navigation/native';
import { appStyles } from '@styles';
import { getHtmlAttribute, linktoNavigation, slug } from '@utils/helpers';
import logger from '@utils/logger';
import React from 'react';
import { GestureResponderEvent, Linking, TextProps } from 'react-native';
import Text from './Text';

interface LinkProps extends TextProps {
  route?: string;
  isLinkExternal?: boolean;
}

const LinkButton = ({ route, isLinkExternal = false, children, style, onPress: onClick, ...rest }: LinkProps) => {
  const linkto = useLinkTo();
  const handleOnPress = (event: GestureResponderEvent) => {
    if (onClick) {
      onClick(event);
    } else if (route) {
      let href = route.includes('href') ? getHtmlAttribute('href', route) : route;
      href = href.startsWith('http') && !isLinkExternal ? slug(href) : href;
      if (isLinkExternal) {
        Linking.openURL(href).catch((err) => logger.log(err));
      } else {
        linktoNavigation(href, linkto);
      }
    }
  };

  return (
    <Text testID="linkButton" style={[appStyles.link, style]} onPress={handleOnPress} {...rest}>
      {children}
    </Text>
  );
};

export default LinkButton;

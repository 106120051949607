import {
  Address,
  CategoryWidgetParams,
  ItemWidgetParams,
  KeywordWidgetParams,
  Order,
  PersonalizedWidgetParams,
  ShopType,
  WidgetParams,
  WidgetType,
} from '@fieldera-raleys/client-common';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps, NavigationContainerRef, NavigatorScreenParams } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { TabName } from '@screens/shop/SearchLandingScreen';
import { createRef } from 'react';
import Config from 'react-native-config';

export default Object.freeze({
  TERMS_AND_CONDITIONS: `${Config.BASE_WEB_URL}something-extra/terms-and-conditions/`,
  PRIVACY_POLICY: `${Config.BASE_WEB_URL}privacy-policy/`,
  TERMS_OF_USE: `${Config.BASE_WEB_URL}terms-of-use/`,
  CCPA: `${Config.BASE_WEB_URL}ccpa-california-consumer-privacy-act-your-rights/`,
  ABOUT: `${Config.BASE_WEB_URL}about/`,
  NOTICE_AT_COLLECTION: `${Config.BASE_WEB_URL}privacy-policy/#raley-s-notice-at-collection-for-customers`,
  FAQ: `${Config.BASE_WEB_URL}shop/how-to-shop-online/faq/`,
  STORE_FINDER: `${Config.BASE_WEB_URL}stores/`,
  PUMP_REWARDS: `${Config.BASE_WEB_URL}something-extra/pump-rewards/`,
  HEALTH: `${Config.BASE_WEB_URL}something-extra/community/health/`,
  DELETE_ACCOUNT_REQUEST: 'https://submit-irm.trustarc.com/services/validation/88fe8bf8-a775-4093-b153-a425c5757bef',
  SE_TERMS_OF_USE: `${Config.BASE_WEB_URL}something-extra/terms-and-conditions/`,
  GIVE_FEEDBACK: 'https://www.surveymonkey.com/r/HYWRPGC',
  SMS_TERMS_OF_USE: `${Config.BASE_WEB_URL}terms-of-use/#sms-terms`,
});

export enum AuthStackRoutes {
  AppBenefits = 'AppBenefits',
  SignIn = 'SignIn',
  ForgotPassword = 'ForgotPassword',
  VerifyOTC = 'VerifyOTC',
  ResetPassword = 'ResetPassword',
  CreateAccountInitial = 'CreateAccountInitial',
  CreateAccount = 'CreateAccount',
  ForgotEmail = 'ForgotEmail',
  WebBrowser = 'WebBrowser',
  SiteMaintenance = 'SiteMaintenance',
  UpdateMaintenance = 'UpdateMaintenance',
  UpdateExpiredPassword = 'UpdateExpiredPassword',
}

export type AuthStackParamList = { [key in AuthStackRoutes]: object | undefined } & {
  [AuthStackRoutes.VerifyOTC]: {
    email?: string;
  };
  [AuthStackRoutes.ResetPassword]: {
    email?: string;
    code?: string;
  };
  [AuthStackRoutes.CreateAccountInitial]: {
    errorMsg?: string;
  };
  [AuthStackRoutes.CreateAccount]: {
    willComplete?: boolean;
    email?: string;
    seValidationNumber?: string;
    seNumber?: string;
    seType?: string;
    lastName?: string;
  };
  [AppStackRoutes.WebBrowser]: {
    title: string;
    url: string;
  };
  [AuthStackRoutes.UpdateMaintenance]: {
    updateRecommended: boolean;
  };
  [AuthStackRoutes.UpdateExpiredPassword]: {
    password: string;
    authToken: string;
  };
};
export type AuthStackScreenProps<T extends keyof AuthStackParamList> = StackScreenProps<AuthStackParamList, T>;

export enum AppStackRoutes {
  RootTabs = 'RootTabs',
  Customize = 'Customize',
  ProductDetails = 'ProductDetails',
  CartAndCheckout = 'CartAndCheckout',
  AccordionScreen = 'Accordion',
  Permissions = 'Permissions',
  SearchFilter = 'SearchFilter',
  OfferDetails = 'OfferDetails',
  LiveAgent = 'LiveAgent',
  ShelfGuideDefinitions = 'ShelfGuideDefinitions',
  ProductCarouselList = 'ProductCarouselList',
  WebBrowser = 'WebBrowser',
}

export type AppStackParamList = { [key in AppStackRoutes]: object | undefined } & {
  [AppStackRoutes.RootTabs]: NavigatorScreenParams<RootTabParamList>;
  [AppStackRoutes.ProductDetails]: {
    productKey: string;
    root?: string;
    showMoreFlag?: boolean;
  };
  [AppStackRoutes.Customize]: NavigatorScreenParams<CustomizeStackParamList>;
  [AppStackRoutes.CartAndCheckout]: NavigatorScreenParams<CheckoutStackParamList>;
  [AppStackRoutes.AccordionScreen]: {
    title: string;
    categoryId?: string;
  };
  [AppStackRoutes.SearchFilter]: {
    title: string | undefined;
    text?: string | undefined;
    calledFrom?: 'WeeklyAds' | 'ProductListing' | 'TopDeals' | 'SearchLanding' | 'DigitalCoupons' | undefined;
    categoryId?: string | undefined;
    onFilterApply?: (() => void) | undefined;
  };
  [AppStackRoutes.OfferDetails]: {
    offerId: number;
    isClipped?: boolean;
    percentComplete?: number;
    percentCompleteMessage?: string;
    calledFrom?: string;
  };
  [AppStackRoutes.ShelfGuideDefinitions]: {
    shelfGuide: string;
    shelfGuideName?: string | undefined;
  };
  [AppStackRoutes.ProductCarouselList]: {
    widgetType: keyof typeof WidgetType;
    widgetId: string;
    widgetParams?: WidgetParams | ItemWidgetParams | KeywordWidgetParams | CategoryWidgetParams | PersonalizedWidgetParams;
    actionType?: string;
    itemId?: string;
  };
  [AppStackRoutes.WebBrowser]: {
    title: string;
    url: string;
  };
};

export type AppStackScreenProps<T extends keyof AppStackParamList> = StackScreenProps<AppStackParamList, T>;

export enum RootTabRoutes {
  Home = 'Home',
  Wallet = 'Wallet',
  Shop = 'Shop',
  Account = 'Account',
  Rewards = 'Rewards',
}

export type RootTabParamList = { [key in RootTabRoutes]: object | undefined } & {
  [RootTabRoutes.Wallet]: {
    showBack: boolean;
    link?: string;
    linkParams?: any;
    toDollarsTab?: boolean;
  };
  [RootTabRoutes.Account]: NavigatorScreenParams<AccountStackParamList>;
  [RootTabRoutes.Rewards]: NavigatorScreenParams<RewardsStackParamList>;
  [RootTabRoutes.Shop]: NavigatorScreenParams<ShopStackParamList>;
};

export type RootTabScreenProps<T extends keyof RootTabParamList> = CompositeScreenProps<
  BottomTabScreenProps<RootTabParamList, T>,
  AppStackScreenProps<keyof AppStackParamList>
>;

export enum AccountStackRoutes {
  AccountHome = 'AccountLanding',
  OrderHistory = 'OrderHistory',
  ManageShoppingList = 'ManageShoppingList',
  MessageCenter = 'MessageCenter',
  CommunicationPreferences = 'CommunicationPreferences',
  FavoriteStoreLocations = 'FavoriteStoreLocations',
  AppSettings = 'AppSettings',
  AddressBook = 'AddressBook',
  AddEditAddress = 'AddEditAddress',
  PaymentMethods = 'PaymentMethods',
  AddEditPaymentMethod = 'AddEditPaymentMethod',
  AddBirthdayClubMember = 'AddBirthdayClubMember',
  EditProfile = 'EditProfile',
  ChangePassword = 'ChangePassword',
  StoreFinder = 'StoreFinder',
  FAQs = 'FAQs',
  ContactCustomerService = 'ContactCustomerService',
  About = 'About',
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfUse = 'TermsOfUse',
  NoticeAtCollection = 'NoticeAtCollection',
  ViewList = 'ViewList',
  ListToCart = 'ListToCart',
  ListFilter = 'ListFilter',
  CustomizeNeeded = 'CustomizeNeeded',
  HelpCenter = 'HelpCenter',
}

export type AccountStackParamList = { [key in AccountStackRoutes]: object | undefined } & {
  [AccountStackRoutes.ContactCustomerService]: { calledFrom?: string };
  [AccountStackRoutes.AccountHome]: { reload?: boolean };
  [AccountStackRoutes.AddressBook]: { addressId?: string | number };
  [AccountStackRoutes.AddEditAddress]: {
    addressId?: string | number;
    mode?: 'shipping' | 'billing';
    calledFrom?: AccountStackRoutes.AddEditPaymentMethod | AccountStackRoutes.AddressBook;
  };
  [AccountStackRoutes.PaymentMethods]: {
    showBack: boolean;
    link?: string;
    linkParams?: any;
  };
  [AccountStackRoutes.AddEditPaymentMethod]: {
    paymentMethodId?: number;
    calledFrom?: 'Wallet' | 'PaymentMethods';
    addressId?: string | number;
  };
  [AccountStackRoutes.ViewList]: {
    title: string;
    listName: string;
    listId: string;
  };
  [AccountStackRoutes.ListToCart]: {
    listId?: number | string;
    listName?: string;
    action: string;
    toCart?: boolean;
  };
  [AccountStackRoutes.CustomizeNeeded]: {
    listId: string;
    listVersion: number;
    customItemIds: string[];
    listName?: string;
    refetchList?: boolean;
    standardItemSkus?: any[];
    lineItemIds?: string[];
    action?: string;
  };
  [AccountStackRoutes.OrderHistory]: NavigatorScreenParams<OrderHistoryStackParamList>;
  [AccountStackRoutes.MessageCenter]: NavigatorScreenParams<MessageCenterStackParamList>;
  [AccountStackRoutes.StoreFinder]: NavigatorScreenParams<StoreFinderStackParamList>;
  [AccountStackRoutes.HelpCenter]: NavigatorScreenParams<HelpCenterStackParamList>;
};

export type AccountStackScreenProps<T extends keyof AccountStackParamList> = CompositeScreenProps<
  StackScreenProps<AccountStackParamList, T>,
  RootTabScreenProps<keyof RootTabParamList>
>;

export enum StoreFinderStackRoutes {
  StoreFinderScreen = 'StoreFinderScreen',
  StoreFinderDetail = 'StoreFinderDetail',
  StoreFinderFilter = 'StoreFinderFilter',
}

export type StoreFinderStackParamList = { [key in StoreFinderStackRoutes]: object | undefined } & {
  [StoreFinderStackRoutes.StoreFinderScreen]: {
    calledFrom?: string;
  };
  [StoreFinderStackRoutes.StoreFinderDetail]: {
    storeNumber?: string;
  };
  [StoreFinderStackRoutes.StoreFinderFilter]: {
    calledFrom?: string;
  };
};

export type StoreFinderStackScreenProps<T extends keyof StoreFinderStackParamList> = CompositeScreenProps<
  StackScreenProps<StoreFinderStackParamList, T>,
  AccountStackScreenProps<keyof AccountStackParamList>
>;

export enum OrderHistoryStackRoutes {
  RecentOrders = 'RecentOrders',
  OrderDetails = 'OrderDetails',
  PurchaseFilter = 'PurchaseFilter',
  Reorder = 'Reorder',
}

export type OrderHistoryStackParamList = { [key in OrderHistoryStackRoutes]: object | undefined } & {
  [OrderHistoryStackRoutes.OrderDetails]: {
    orderId: string;
  };
  [OrderHistoryStackRoutes.RecentOrders]: {
    clearFilter?: boolean;
    useRefetch?: boolean;
  };
  [OrderHistoryStackRoutes.Reorder]: {
    orderDetails: Order;
  };
};

export type OrderHistoryStackScreenProps<T extends keyof OrderHistoryStackParamList> = CompositeScreenProps<
  StackScreenProps<OrderHistoryStackParamList, T>,
  AccountStackScreenProps<keyof AccountStackParamList>
>;

export enum MessageCenterStackRoutes {
  Inbox = 'Inbox',
  MessageDetails = 'MessageDetails',
}

export type MessageCenterStackParamList = { [key in MessageCenterStackRoutes]: object | undefined } & {
  [MessageCenterStackRoutes.MessageDetails]: {
    messageId: string;
    title?: string;
  };
};

export type MessageCenterStackScreenProps<T extends keyof MessageCenterStackParamList> = CompositeScreenProps<
  StackScreenProps<MessageCenterStackParamList, T>,
  AccountStackScreenProps<keyof AccountStackParamList>
>;

export enum HelpCenterStackRoutes {
  HelpCenterScreen = 'HelpCenterScreen',
  ContactCustomerScreen = 'ContactCustomerScreen',
  HelpTopicsList = 'HelpTopicsListScreen',
  HelpTopicsDetailScreen = 'HelpTopicsDetailScreen',
}

export type HelpCenterStackParamList = { [key in HelpCenterStackRoutes]: object | undefined } & {
  [HelpCenterStackRoutes.ContactCustomerScreen]: {
    calledFrom?: string;
  };
  [HelpCenterStackRoutes.HelpTopicsList]: {
    topic: string | undefined;
  };
  [HelpCenterStackRoutes.HelpTopicsDetailScreen]: {
    question?: string | undefined;
    answer?: string | undefined;
  };
};

export type HelpCenterStackScreenProps<T extends keyof HelpCenterStackParamList> = CompositeScreenProps<
  StackScreenProps<HelpCenterStackParamList, T>,
  AccountStackScreenProps<keyof AccountStackParamList>
>;
export enum CustomizeStackRoutes {
  Initial = 'Initial',
  Size = 'Size',
  Selection = 'Selection',
  Container = 'Container',
  Notes = 'Notes',
  Review = 'Review',
  BasicSelection = 'BasicSelection',
  Theme = 'Theme',
  Filling = 'Filling',
  Message = 'Message',
  Summary = 'Summary',
}

export type CustomizeStackParamList = { [key in CustomizeStackRoutes]: { forwardNavEnabled?: boolean; [key: string]: any } | undefined } & {
  [CustomizeStackRoutes.Initial]: {
    productKey: string;
    editCustomize: boolean;
    editSavedForLater: boolean;
    lineItemId?: string;
    prevPageData: any;
  };
  [CustomizeStackRoutes.Size]: {
    ItemId?: number | string;
    cartItemId?: number | string;
    nextScreen: { [key in CustomizeStackRoutes]: string };
    pageItem?: string;
    limit?: number | string;
    catIds?: string[];
    min?: number | string;
    selectionOption?: string;
  };
  [CustomizeStackRoutes.Selection]: {
    ItemId?: number | string;
    cartItemId?: number | string;
    nextScreen?: { [key in CustomizeStackRoutes]: string };
    pageItem?: string;
    limit?: number | string;
    catIds?: string[];
    min?: number | string;
    selectionOption?: string;
  };
  [CustomizeStackRoutes.Notes]: {
    ItemId?: number | string;
    cartItemId?: number | string;
    nextScreen?: { [key in CustomizeStackRoutes]: string };
    pageItem?: string;
    limit?: number | string;
    catIds?: string[];
    min?: number | string;
    selectionOption?: string;
  };
  [CustomizeStackRoutes.BasicSelection]: {
    ItemId?: number | string;
    cartItemId?: number | string;
    nextScreen?: { [key in CustomizeStackRoutes]: string };
    pageItem?: string;
    limit?: number | string;
    catIds?: string[];
    min?: number | string;
    selectionOption?: string;
  };
  [CustomizeStackRoutes.Theme]: {
    ItemId?: number | string;
    cartItemId?: number | string;
    nextScreen?: { [key in CustomizeStackRoutes]: string };
    pageItem?: string;
    limit?: number | string;
    catIds?: string[];
    min?: number | string;
    selectionOption?: string;
  };
  [CustomizeStackRoutes.Filling]: {
    ItemId?: number | string;
    cartItemId?: number | string;
    nextScreen?: { [key in CustomizeStackRoutes]: string };
    pageItem?: string;
    limit?: number | string;
    catIds?: string[];
    min?: number | string;
    selectionOption?: string;
  };
  [CustomizeStackRoutes.Message]: {
    ItemId?: number | string;
    cartItemId?: number | string;
    nextScreen?: { [key in CustomizeStackRoutes]: string };
    pageItem?: string;
    limit?: number | string;
    catIds?: string[];
    min?: number | string;
    selectionOption?: string;
  };
  [CustomizeStackRoutes.Container]: {
    ItemId?: number | string;
    cartItemId?: number | string;
    nextScreen?: { [key in CustomizeStackRoutes]: string };
    pageItem?: string;
    limit?: number | string;
    catIds?: string[];
    min?: number | string;
    selectionOption?: string;
  };
  [CustomizeStackRoutes.Review]: {
    ItemId?: number | string;
    cartItemId?: number | string;
    nextScreen?: { [key in CustomizeStackRoutes]: string };
    pageItem?: string;
    limit?: number | string;
    catIds?: string[];
    min?: number | string;
    selectionOption?: string;
  };
  [CustomizeStackRoutes.Summary]: {
    ItemId?: number | string;
    cartItemId?: string;
    nextScreen?: { [key in CustomizeStackRoutes]: string };
  };
};

export type CustomizeStackScreenProps<T extends keyof CustomizeStackParamList> = CompositeScreenProps<
  StackScreenProps<CustomizeStackParamList, T>,
  AppStackScreenProps<keyof AppStackParamList>
>;

export enum CheckoutStackRoutes {
  CartLanding = 'CartLanding',
  Cart = 'Cart',
  OrderComplete = 'OrderComplete',
  Checkout = 'Checkout',
  OrderSummary = 'OrderSummary',
  AddPaymentMethod = 'AddPaymentMethod',
  AddAddress = 'AddAddress',
  SavingsReminder = 'SavingsReminder',
  CartOptionsScreen = 'CartOptionsScreen',
  BackupScreen = 'BackupScreen',
  AgeRequirementScreen = 'AgeRequirementScreen',
}

export type CheckoutStackParamList = { [key in CheckoutStackRoutes]: object | undefined } & {
  [CheckoutStackRoutes.OrderComplete]: {
    orderId: string;
    offlinePaymentMethod?: string;
  };
  [CheckoutStackRoutes.CartLanding]: {
    storeNumber?: string;
    shopType?: ShopType;
    deliveryAddress?: Address;
  };
  [CheckoutStackRoutes.Checkout]: {
    paymentMethodId?: number;
  };
  [CheckoutStackRoutes.AddAddress]: {
    addressId?: string | number;
    calledFrom?: CheckoutStackRoutes.AddPaymentMethod;
  };
  [CheckoutStackRoutes.AddPaymentMethod]: {
    paymentMethodId?: number;
    addressId?: string | number;
    calledFrom?: CheckoutStackRoutes.Checkout;
  };
  [CheckoutStackRoutes.BackupScreen]: {
    itemId?: string;
  };
  [CheckoutStackRoutes.AgeRequirementScreen]: {
    age: number;
  };
};

export type CheckoutStackScreenProps<T extends keyof CheckoutStackParamList> = CompositeScreenProps<
  StackScreenProps<CheckoutStackParamList, T>,
  AppStackScreenProps<keyof AppStackParamList>
>;

export enum ShopStackRoutes {
  ShopLanding = 'ShopLanding',
  Categories = 'Categories',
  Category = 'Category',
  CollectionsLanding = 'CollectionsLanding',
  CollectionListing = 'CollectionListing',
  ProductList = 'ProductList',
  SearchLanding = 'SearchLanding',
  ShelfGuide = 'ShelfGuide',
  WeeklyAds = 'WeeklyAds',
  WeeklyAdsCollection = 'WeeklyAdsCollection',
  DigitalCoupons = 'DigitalCoupons',
  ShelfGuideCategoryDetails = 'ShelfGuideCategoryDetails',
  ShelfGuideHelp = 'ShelfGuideHelp',
}

export type ShopStackParamList = { [key in ShopStackRoutes]: object | undefined } & {
  [ShopStackRoutes.Category]: {
    categoryId: string;
    isCustomBack?: boolean;
  };
  [ShopStackRoutes.ShopLanding]: {
    reload?: boolean;
  };
  [ShopStackRoutes.ProductList]: {
    categoryId: string;
    rootCategoryId?: string;
  };
  [ShopStackRoutes.SearchLanding]: {
    query?: string;
    prevRoute?: string;
    showAddToList?: boolean;
    shelfGuideId?: string;
    tabName?: TabName;
    shoppingListId?: string;
    shoppingListName?: string;
    category?: string;
  };
  [ShopStackRoutes.CollectionListing]: {
    productCollectionId: number;
  };
  [ShopStackRoutes.WeeklyAdsCollection]: {
    productCollectionId: number;
  };
  [ShopStackRoutes.ShelfGuideCategoryDetails]: {
    shelfGuide: string;
  };
};

export type ShopStackScreenProps<T extends keyof ShopStackParamList> = CompositeScreenProps<
  StackScreenProps<ShopStackParamList, T>,
  RootTabScreenProps<keyof RootTabParamList>
>;

export enum RewardsStackRoutes {
  SEHome = 'SEHome',
  SEBenefits = 'SEBenefits',
  SEOffers = 'SEOffers',
  SEOfferDetails = 'SEOfferDetails',
  TopDeals = 'TopDeals',
}

export type RewardsStackParamList = { [key in RewardsStackRoutes]: object | undefined } & {
  [RewardsStackRoutes.SEHome]: {
    currTab?: string;
    reload?: boolean;
  };
  [RewardsStackRoutes.SEBenefits]: {
    currTab?: string;
  };
  [RewardsStackRoutes.SEOffers]: {
    currTab?: string;
  };
  [RewardsStackRoutes.SEOfferDetails]: {
    offerId?: number;
    isClipped?: boolean;
    calledFrom?: string;
  };
  [RewardsStackRoutes.TopDeals]: {
    title?: string | undefined;
  };
};

export type RewardsStackScreenProps<T extends keyof RewardsStackParamList> = CompositeScreenProps<
  StackScreenProps<RewardsStackParamList, T>,
  RootTabScreenProps<keyof RootTabParamList>
>;

export const navigationRef = createRef<NavigationContainerRef<ReactNavigation.RootParamList>>();

declare global {
  namespace ReactNavigation {
    interface RootParamList extends AuthStackParamList, AppStackParamList {}
  }
}

import { AddToListOptions, Icon, LoadingScreen, Modal, Screen } from '@components';
import Text from '@components/Text';
import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useCustomModalContext } from '../../contexts';

import CartInfoBanner from '@components/CartInfoBanner';
import colors from '@config/colors';
import { AddLineItem } from '@fieldera-raleys/client-commercetools/schema';
import { getCustomFieldValue } from '@fieldera-raleys/client-common/utils';
import { AppStackRoutes, CheckoutStackRoutes } from '@navigation/routes';
import { useCartStore } from '@store/cartStore';
import { appStyles, utilityStyles } from '@styles';
import { containerWidth, screenHeight, screenWidth } from '@styles/constants';
import { getItemAttributeValue, getProductVariantQuantityIndex } from '@utils/orderHelpers';

const CustomHeader = () => {
  const { hideModal } = useCustomModalContext();

  return (
    <View style={styles.handle}>
      <TouchableOpacity style={styles.closeButton} onPress={hideModal}>
        <Icon name={'x-close'} size={20} />
      </TouchableOpacity>
      <Text style={appStyles.h6}>Add to List</Text>
      <Text style={[appStyles.bodyLargeCenter, utilityStyles.my3]}>Which list would you like to use?</Text>
    </View>
  );
};

const CartOptionsScreen = () => {
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const [showLocalModal, setShowLocalModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { emptyCart, cart, setCartData } = useCartStore();
  const { showModal } = useCustomModalContext();

  const onCloseClick = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(AppStackRoutes.CartAndCheckout, { screen: CheckoutStackRoutes.CartLanding, params: {} });
    }
  };

  const doCartPrint = async () => {
    setShowLocalModal(false);
    setLoading(true);
    try {
      setCartData({ shareCart: true });
    } catch (e) {
      setLoading(false);
      throw e;
    }
    setLoading(false);
    onCloseClick();
  };

  const doEmptyCart = async () => {
    setShowLocalModal(false);
    setLoading(true);
    try {
      await emptyCart();
    } catch (e) {
      setLoading(false);
      throw e;
    }
    setLoading(false);
    onCloseClick();
  };

  const showShoppingListModal = (action: 'Move to List' | 'Copy to List' = 'Move to List') => {
    const skus = cart?.lineItems
      .filter((li) => !li.custom?.customFieldsRaw?.find((cf) => cf.name === 'parentLineItemId')?.value)
      .map((li) => {
        const estimatedTotalWeight = getItemAttributeValue('estimatedTotalWeight', li.custom?.customFieldsRaw ?? []);
        return {
          sku: 'variant' in li && li.variant?.sku,
          quantity: li.variant ? getProductVariantQuantityIndex(li.variant, li.quantity, estimatedTotalWeight) : li.quantity,
          itemNote: getCustomFieldValue('itemNote', li.custom?.customFieldsRaw ?? []),
        };
      })
      .filter((mapped) => mapped !== undefined) as AddLineItem[];

    showModal({
      style: { backgroundColor: colors.cream },
      header: <CustomHeader />,
      children: <AddToListOptions productSku={skus} searchText={''} containerStyle={styles.alertBox} navigation={navigation} action={action} />,
      buttonContainerStyle: { display: 'none' },
      contentStyle: { width: screenWidth, ...utilityStyles.px3 },
    });
  };

  return (
    <>
      <Screen>
        <ScrollView
          style={Platform.OS === 'web' ? {} : { marginBottom: 65 - insets.bottom }}
          stickyHeaderIndices={[0]}
          nestedScrollEnabled={true}
          showsVerticalScrollIndicator={false}>
          <CartInfoBanner />
          <View style={[styles.mainContainer]}>
            <View style={[styles.optionContainer, utilityStyles.px1, utilityStyles.py1]}>
              <TouchableOpacity onPress={onCloseClick} style={[styles.actionClose]}>
                <Icon name={'x-close'} size={25} />
              </TouchableOpacity>
              <View style={[styles.actionContainer, utilityStyles.py3]}>
                <Text style={[appStyles.bodyBoldLarge]}>Actions</Text>
              </View>
            </View>
          </View>
          <View style={[styles.mainContainer]}>
            <View>
              {loading ? (
                <LoadingScreen />
              ) : (
                <>
                  <View style={[styles.bodyContainer, utilityStyles.py3, utilityStyles.px2]}>
                    <TouchableOpacity onPress={() => showShoppingListModal('Copy to List')} activeOpacity={0.7}>
                      <Text style={appStyles.bodySmallRegular}>Copy to List</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.bodyContainer, utilityStyles.py3, utilityStyles.px2]}>
                    <TouchableOpacity onPress={() => showShoppingListModal()} activeOpacity={0.7}>
                      <Text style={appStyles.bodySmallRegular}>Move to List</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.bodyContainer, utilityStyles.py3, utilityStyles.px2]}>
                    <TouchableOpacity onPress={() => doCartPrint()} activeOpacity={0.7}>
                      <Text style={appStyles.bodySmallRegular}>Share / Print</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.bodyContainer, utilityStyles.py3, utilityStyles.px2]}>
                    <TouchableOpacity onPress={() => setShowLocalModal(true)} activeOpacity={0.7}>
                      <Text style={appStyles.bodySmallRegular}>Empty Cart</Text>
                    </TouchableOpacity>
                  </View>
                </>
              )}
            </View>
          </View>
          <Modal
            location={'top'}
            title={'Empty Cart?'}
            titleStyle={{ width: screenWidth }}
            visible={showLocalModal}
            cancelButtonOnPress={() => setShowLocalModal(false)}
            children={
              <Text style={[appStyles.bodySmallRegular, styles.message]}>You are about to remove all items in your cart. Would you like to continue?</Text>
            }
            showCancel={true}
            okButtonText={'Yes'}
            okButtonOnPress={() => doEmptyCart()}
            cancelButtonText={'Back'}
          />
        </ScrollView>
      </Screen>
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: screenWidth,
  },
  alertBox: {
    bottom:
      screenHeight < 736 ? screenHeight * 0.17 : screenHeight > 812 ? screenHeight * 0.165 : screenHeight < 740 ? screenHeight * 0.165 : screenHeight * 0.175,
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: containerWidth,
  },
  actionContainer: {
    borderBottomWidth: 1,
    borderBottomColor: colors.sectionBorder,
  },
  bodyContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.cream,
    alignSelf: 'center',
    width: containerWidth,
    borderBottomWidth: 1,
    borderBottomColor: colors.sectionBorder,
  },
  optionContainer: {
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'center',
    width: containerWidth,
  },
  message: { textAlign: 'center' },
  handle: {
    width: screenWidth * 0.9,
    alignSelf: 'center',
    // height: 50,
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderColor: colors.sectionBorder,
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 16,
  },
  actionClose: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25,
  },
});

export default CartOptionsScreen;

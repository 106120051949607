import IconButton from '@components/IconButton';
import Text from '@components/Text';
import colors from '@config/colors';
import { ShopType } from '@fieldera-raleys/client-common';
import useShopStore from '@store/shopStore';
import { lineHeight, scale, screenHeight } from '@styles/constants';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useCustomModalContext } from '../../contexts';
import AddDeliveryAddressScreen from './AddDeliveryAddressScreen';
import DeliveryScreen from './DeliveryScreen';
import FindAStore from './FindAStore';
import JumpMenuScreen from './JumpMenuScreen';
import MapScreen from './MapScreen';
import TimeSlotScreen from './TimeSlotScreen';

interface IInitialShopOptionsProps {
  toggleTombstone: () => void;
  currentPage: string;
  setCurrentPage: Dispatch<SetStateAction<string>>;
}

interface IOptionButtonsProps {
  toggleTombstone: () => void;
  setCurrentPage: Dispatch<SetStateAction<string>>;
}

//Container holding the initial shop type buttons
const OptionButtons: FC<IOptionButtonsProps> = ({ setCurrentPage }) => {
  const { selectedShopType, setSelectedShopType, selectedStore: selectedLocation, setCurrPage, setTempShopType, deliveryAddress } = useShopStore();
  const { showAlertModal } = useCustomModalContext();
  const navigateToPage = async (page: ShopType) => {
    setCurrPage('');
    if (selectedLocation?.address && selectedShopType === page && selectedShopType !== ShopType.IN_STORE) {
      setCurrentPage('TimeSlot');
      return;
    }
    if (selectedLocation?.address && page === ShopType.IN_STORE && selectedShopType !== ShopType.DELIVERY) {
      setSelectedShopType(ShopType.IN_STORE);
      setCurrentPage('In-Store');
      return;
    }
    // setDeliveryAddress(undefined);
    // setSelectedTimeSlot(undefined);
    // setSelectedLocation(undefined);
    // setSelectedShopType(page);
    switch (page) {
      case ShopType.PICKUP:
        setTempShopType(ShopType.PICKUP);
        setCurrentPage('FindAStore');
        break;
      case ShopType.DELIVERY:
        setTempShopType(ShopType.DELIVERY);
        setCurrentPage('Delivery');
        break;
      case ShopType.IN_STORE:
        setTempShopType(ShopType.IN_STORE);
        setCurrentPage('FindAStore');
        break;
      default:
        showAlertModal();
    }
  };

  const changeStore = () => {
    if (selectedShopType === ShopType.PICKUP || selectedShopType === ShopType.IN_STORE) {
      setTempShopType(selectedShopType);
      setCurrentPage('FindAStore');
    } else if (selectedShopType === ShopType.DELIVERY) {
      setCurrentPage('Delivery');
    }
  };
  const pickupOnPress = () => {
    navigateToPage(ShopType.PICKUP);
  };
  const deliveryOnPress = () => {
    navigateToPage(ShopType.DELIVERY);
  };

  const inStoreOnPress = () => {
    navigateToPage(ShopType.IN_STORE);
  };

  return (
    <View style={styles.initialOptionsWrapper}>
      {selectedLocation?.address ? (
        <TouchableOpacity
          testID="changeStoreClick"
          onPress={() => {
            changeStore();
          }}>
          <Text style={styles.changeStoreText} testID={deliveryAddress?.postalCode ? 'changeDeliveryLocation' : 'changeStore'}>
            {deliveryAddress?.postalCode ? 'Change Delivery Location' : 'Change Store'}
          </Text>
        </TouchableOpacity>
      ) : (
        <Text style={styles.header} testID="initialMessage">
          How Would You Like To Shop?
        </Text>
      )}
      <IconButton
        buttonStyle={{ elevation: 0 }}
        header={'Pickup'}
        // highlightedText={'Today*'}
        iconProps={{ name: 'pickup-icon', size: 40, fill: colors.red, stroke: 'none', style: styles.buttonIcon }}
        onPress={pickupOnPress}
      />
      <IconButton
        buttonStyle={{ elevation: 0 }}
        header={'Delivery'}
        // highlightedText={'Within hours*'}
        // text={'Only $9.95'}
        iconProps={{ name: 'delivery-icon', size: 40, fill: colors.red, stroke: 'none', strokeSecondary: colors.red, style: styles.buttonIcon }}
        onPress={deliveryOnPress}
      />
      {/* <Text style={styles.inStoreText}>Build A Shopping List</Text> */}
      <IconButton
        buttonStyle={{ marginTop: 50, elevation: 0 }}
        header={'In-Store'}
        text={'Shop in-store or build a list'}
        iconProps={{ name: 'list-icon', size: 40, fill: colors.red, stroke: 'none', style: styles.buttonIcon }}
        onPress={inStoreOnPress}
      />
      {/* <Text style={styles.cateringText}>{t('leadTimeText')}</Text> */}
      <Text style={styles.feesText} testID="feesmayapply">
        Fees may apply
      </Text>
    </View>
  );
};

//Main View and starting point to the shop context
const InitialShopOptions: FC<IInitialShopOptionsProps> = ({ toggleTombstone, setCurrentPage, currentPage }): JSX.Element => {
  //Function serves different views within the modal dependent on currentPage prop value
  const conditionalRender = () => {
    switch (currentPage) {
      case 'TimeSlot':
        return <TimeSlotScreen setCurrentPage={setCurrentPage} toggleTombstone={toggleTombstone} />;
      case 'FindAStore':
        return <FindAStore setCurrentPage={setCurrentPage} toggleTombstone={toggleTombstone} />;
      case 'Delivery':
        return <DeliveryScreen setCurrentPage={setCurrentPage} toggleTombstone={toggleTombstone} />;
      case 'In-Store':
        return <JumpMenuScreen setCurrentPage={setCurrentPage} toggleTombstone={toggleTombstone} />;
      case 'AddressBook':
        return <AddDeliveryAddressScreen setCurrentPage={setCurrentPage} toggleTombstone={toggleTombstone} />;
      case 'Map':
        return <MapScreen setCurrentPage={setCurrentPage} toggleTombstone={toggleTombstone} />;
      default:
        return <OptionButtons setCurrentPage={setCurrentPage} toggleTombstone={toggleTombstone} />;
    }
  };

  return <>{conditionalRender()}</>;
};

const styles = StyleSheet.create({
  buttonIcon: {
    width: '15%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  initialOptionsWrapper: {
    alignItems: 'center',
    height: '85%',
  },
  header: {
    color: colors.darkText,
    fontFamily: 'Aleo-Bold',
    // fontSize: responsiveFontSize(3.1),
    fontSize: scale(25),
    lineHeight: 30,
    textAlign: 'center',
    width: '62%',
    marginBottom: screenHeight * 0.01,
  },
  changeStoreText: {
    color: colors.darkText,
    fontFamily: 'Larsseit-Bold',
    fontSize: scale(16),
    lineHeight: 18,
    textDecorationLine: 'underline',
    textAlign: 'center',
    marginTop: Platform.OS === 'ios' ? 20 : 10,
    marginBottom: screenHeight * 0.03,
  },
  inStoreText: {
    color: colors.darkText,
    fontSize: scale(18),
    fontFamily: 'Larsseit-Light',
    lineHeight: lineHeight(18),
    marginTop:
      Platform.OS === 'web'
        ? screenHeight * 0.05
        : Platform.OS === 'ios'
          ? screenHeight < 740
            ? screenHeight * 0.03
            : screenHeight * 0.05
          : screenHeight < 740
            ? screenHeight * 0.01
            : screenHeight * 0.04,
    // marginBottom: Platform.OS === 'web' ? 0 : screenHeight < 740 ? -15 : 0,
  },
  cateringText: {
    position: 'absolute',
    bottom: Platform.OS === 'ios' ? 32 : 70,
    color: colors.darkText,
    fontFamily: 'Larsseit-Light',
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    textAlign: 'left',
  },
  feesText: {
    position: 'absolute',
    bottom: Platform.OS === 'ios' ? 8 : 40,
    color: colors.darkText,
    fontFamily: 'Larsseit-Light',
    fontSize: scale(14),
    lineHeight: lineHeight(14),
    textAlign: 'left',
  },
});

export default InitialShopOptions;

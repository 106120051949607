import { CloseButton, KeyboardAvoidingScreen, Modal } from '@components';
import { ErrorMessage, Form, FormField, SubmitButton } from '@components/forms';
import colors from '@config/colors';
import { FontFamily } from '@config/fonts';
import { ChangePassword, UserService } from '@fieldera-raleys/client-common';
import { AuthStackRoutes, AuthStackScreenProps } from '@navigation/routes';
import { useCardAnimation } from '@react-navigation/stack';
import { appStyles } from '@styles';
import { lineHeight, scale, screenHeight, screenWidth } from '@styles/constants';
import Cache from '@utils/cache';
import helpers from '@utils/helpers';
import { createRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Platform, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native';
import Config from 'react-native-config';
import * as Yup from 'yup';

type UpdateExpiredPasswordFormType = {
  newPassword: string;
  confirmPassword: string;
};

type UpdateExpiredPasswordScreenProps = AuthStackScreenProps<AuthStackRoutes.UpdateExpiredPassword>;

const UpdateExpiredPasswordScreen = ({ navigation, route }: UpdateExpiredPasswordScreenProps) => {
  const { password, authToken } = route.params;
  const initialValue = useRef<UpdateExpiredPasswordFormType>({
    newPassword: '',
    confirmPassword: '',
  });
  const { t } = useTranslation(['createAccount', 'changePassword']);
  const [changePasswordFailed, setChangePasswordFailed] = useState<boolean>(false);
  const [showChangePasswordSuccessfulModal, setShowChangePasswordSuccessfulModal] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { current } = useCardAnimation();
  const errorMessageRef = useRef<string>();

  const newPasswordRef = createRef<TextInput>();
  const confirmPasswordRef = createRef<TextInput>();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required()
      .label(t('validationPasswordLabel', { ns: 'createAccount' }))
      .min(8, t('passwordLengthValidation', { ns: 'createAccount' }))
      .matches(helpers.PASSWORD_REGEX, t('passwordNumCharValidation', { ns: 'createAccount' })),
    confirmPassword: Yup.string().test('passwords-match', 'Passwords must match', function (val) {
      return this.parent.newPassword === val;
    }),
  });

  const handleSubmit = async ({ newPassword, confirmPassword }: ChangePassword) => {
    setChangePasswordFailed(false);
    setIsLoading(true);
    try {
      const userService = new UserService({
        apiUrl: Config.BW_API_URL,
        authKey: Config.BW_AUTH_KEY,
        authUrl: Config.BW_AUTH_URL,
        authToken: async () => authToken,
        cache: Cache,
      });
      const response = await userService.changePassword(password, newPassword, confirmPassword);
      if (response) {
        setChangePasswordFailed(true);
      } else {
        setShowChangePasswordSuccessfulModal(true);
      }
    } catch (error) {
      if ((error as Error).message.indexOf('Incorrect Credentials') >= 0) {
        errorMessageRef.current = t('currentPasswordError', { ns: 'changePassword' });
      } else {
        errorMessageRef.current = t('apiErrorText', { ns: 'changePassword' });
      }
      setChangePasswordFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleShowNewPassword = () => setShowNewPassword((prev) => !prev);
  const toggleShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  const showChangePasswordSuccessModal = () => {
    return (
      <Modal
        visible={showChangePasswordSuccessfulModal}
        title={'Update Password'}
        location="top"
        cancelButtonOnPress={() => navigation.navigate(AuthStackRoutes.SignIn)}
        cancelButtonText={t('okText', { ns: 'changePassword' })}>
        <Text style={[appStyles.bodyMediumLight, styles.textAlignCenter]} testID="successMessage">
          {t('successMessage', { ns: 'changePassword' })}
        </Text>
      </Modal>
    );
  };

  const renderComponent = () => {
    return (
      <KeyboardAvoidingScreen keyboardVerticalOffset={50} behavior="padding" style={{ backgroundColor: 'transparent' }}>
        <ScrollView>
          <Text style={[appStyles.formLabels, { textAlign: 'center', marginTop: 15 }]}>Your account requires a password change.</Text>
          <View style={[styles.formContainer, { marginTop: 20 }]}>
            <Form initialValues={initialValue} onSubmit={handleSubmit} validationSchema={validationSchema}>
              <ErrorMessage style={styles.textAlignCenter} error={errorMessageRef.current} visible={changePasswordFailed} />
              <FormField
                label={[
                  <View style={{ width: screenWidth - 60 }}>
                    <Text style={[appStyles.formLabels, Platform.OS === 'android' ? { paddingLeft: 0 } : null]}>Create New Password</Text>
                    <Text style={[appStyles.formLabels, styles.formLabelFix]}>Passwords must include 8 or more characters, with at least one number.</Text>
                  </View>,
                ]}
                name={'newPassword'}
                ref={newPasswordRef}
                autoCapitalize="none"
                autoCorrect={false}
                secureTextEntry={!showNewPassword}
                textContentType="password"
                icon={{
                  testID: showNewPassword ? 'passwordEyehide' : 'passwordEyeopen',
                  name: showNewPassword ? 'eye-hide' : 'eye-open',
                  fill: 'black',
                  size: 20,
                  style: { ...appStyles.icon, marginVertical: 5 },
                }}
                iconPress={toggleShowNewPassword}
                onSubmitEditing={() => confirmPasswordRef.current?.focus()}
                returnKeyType="next"
              />
              <FormField
                label={'Confirm Password'}
                name={'confirmPassword'}
                ref={confirmPasswordRef}
                autoCapitalize="none"
                autoCorrect={false}
                secureTextEntry={!showConfirmPassword}
                textContentType="password"
                icon={{
                  testID: showConfirmPassword ? 'passwordEyehide' : 'passwordEyeopen',
                  name: showConfirmPassword ? 'eye-hide' : 'eye-open',
                  fill: 'black',
                  size: 20,
                  style: { ...appStyles.icon, marginVertical: 5 },
                }}
                iconPress={toggleShowConfirmPassword}
                returnKeyType="done"
              />
              <SubmitButton title="Update Password" isButtonLoading={isLoading} buttonStyle={{ marginTop: 25 }} />
            </Form>
          </View>
        </ScrollView>
      </KeyboardAvoidingScreen>
    );
  };

  return (
    <>
      <Animated.View
        style={[
          styles.container,
          {
            height: screenHeight * 0.8,
          },
          {
            transform: [
              {
                translateY: current.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [screenHeight * 0.8, 0],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}>
        <View style={styles.modal}>
          <View style={styles.header}>
            <CloseButton style={styles.closeButton} />
          </View>
          <Text style={styles.h3BoldCenter}>Update Password</Text>
          {renderComponent()}
          {showChangePasswordSuccessfulModal && showChangePasswordSuccessModal()}
        </View>
      </Animated.View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: screenWidth,
    height: screenHeight * 0.85,
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  formContainer: {
    paddingHorizontal: 30,
  },
  modal: {
    flex: 1,
    alignContent: 'space-between',
  },
  dropshadowContainer: {
    backgroundColor: colors.cream,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  header: {
    height: 50,
  },
  h3BoldCenter: {
    ...appStyles.h3B,
    textAlign: 'center',
  },
  textAlignCenter: { textAlign: 'center' },
  closeButton: {
    left: 16,
    top: 16,
  },
  textWrapper: {
    paddingHorizontal: 0,
    paddingBottom: 25,
  },
  bodyLargeCenter: {
    ...appStyles.bodyLargeCenter,
    marginTop: 15,
  },
  form: {
    width: '100%',
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  errorMessage: {
    ...appStyles.bodySmallRed,
    textAlign: 'center',
    paddingBottom: 15,
  },
  errorWrapper: {
    width: 330,
    height: 25,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: scale(16),
    fontFamily: 'Larsseit-Light',
  },
  errorBorder: {
    borderColor: colors.red,
  },
  buttonsContainer: {
    marginTop: 20,
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
  },
  boldLinkLargeCenter: {
    ...appStyles.boldLinkLargeCenter,
    marginTop: 25,
  },
  textInfo: {
    fontSize: scale(18),
    textAlign: 'center',
    lineHeight: lineHeight(18),
    marginBottom: 45,
    fontFamily: FontFamily.LarsseitLight,
    width: screenWidth * 0.8,
    alignSelf: 'center',
  },
  formLabelFix: {
    fontSize: scale(11),
    lineHeight: lineHeight(12),
    paddingLeft: Platform.OS === 'android' ? 0 : 10,
  },
});

export default UpdateExpiredPasswordScreen;
